import { Button, Col, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { Predicted } from "../../../data/ai-prediction/Predicted";
import { CSVLink } from "react-csv";
import { DownloadOutlined } from "@ant-design/icons";
import { Junction } from "../../../data/junction/Junction";
import { useAiPrediction } from "../../../contexts/junction/AiPredictionContext";

interface Props {
  selectedJunction?: Junction;
}

function AiPredictionCard(props: Props) {
  const { t } = useTranslation();
  const { selectedJunction } = props;
  const [dataSource, setDataSource] = useState<Predicted[]>([]);
  const socketValue = useAiPrediction();

  const columns: ColumnsType<Predicted> = [
    {
      title: t("date"),
      dataIndex: "ts",
      key: "ts",
      align: "center",
    },
    {
      title: `${t("detector_no")}`,
      dataIndex: "detectorId",
      key: "detectorId",
      align: "center",
    },
    {
      title: `${t("past")+"10 "+t("minutes")}`,
      dataIndex: "past10min",
      key: "past10min",
      align: "center",
    },
    {
      title: `${t("past")+"30 "+t("minutes")}`,
      dataIndex: "past30min",
      key: "past30min",
      align: "center",
    },
    {
      title: `${t("past")+"45 "+t("minutes")}`,
      dataIndex: "past45min",
      key: "past45min",
      align: "center",
    },
    {
      title: `${t("past")+"60 "+t("minutes")}`,
      dataIndex: "past60min",
      key: "past60min",
      align: "center",
    },
    {
      title: `${"10 "+t("minutes")}`,
      dataIndex: "10min",
      key: "10min",
      align: "center",
    },
    {
      title: `${"30 "+t("minutes")}`,
      dataIndex: "30min",
      key: "30min",
      align: "center",
    },
    {
      title: `${"45 "+t("minutes")}`,
      dataIndex: "45min",
      key: "45min",
      align: "center",
    },
    {
      title: `${"60 "+t("minutes")}`,
      dataIndex: "60min",
      key: "60min",
      align: "center",
    },
  ];

  const headers = [
    { label: `${t("date")}`, key: "ts" },
    { label: `${t("detector_no")}`, key: "detectorId" },
    { label: `${t("past")+"10 "+ t("minutes")}`, key: "past10min" },
    { label: `${t("past")+"30 "+ t("minutes")}`, key: "past30min" },
    { label: `${t("past")+"45 "+ t("minutes")}`, key: "past45min" },
    { label: `${t("past")+"60 "+ t("minutes")}`, key: "past60min" },
    { label: `${"10 "+ t("minutes")}`, key: "10min" },
    { label: `${"30 "+ t("minutes")}`, key: "30min" },
    { label: `${"45 "+ t("minutes")}`, key: "45min" },
    { label: `${"60 "+ t("minutes")}`, key: "60min" }
  ];

  useEffect(() => {
    if (socketValue?.aiPrediction) {
      console.log(socketValue?.aiPrediction)
      for (
        let index = 0;
        index < socketValue?.aiPrediction.predictedCounts.length!;
        index++
      ) {
        let predicted: Predicted | any= {
          detectorId: socketValue?.aiPrediction.predictedCounts[index].detectorId!,
          "past10min": socketValue?.aiPrediction.predictedCounts[index]["past10min"]!,
          "past30min":socketValue?.aiPrediction.predictedCounts[index]["past30min"]!,
          "past45min":socketValue?.aiPrediction.predictedCounts[index]["past45min"]!,
          "past60min":socketValue?.aiPrediction.predictedCounts[index]["past60min"]!,
          "10min": socketValue?.aiPrediction.predictedCounts[index]["10min"]!,
          "30min":socketValue?.aiPrediction.predictedCounts[index]["30min"]!,
          "45min":socketValue?.aiPrediction.predictedCounts[index]["45min"]!,
          "60min":socketValue?.aiPrediction.predictedCounts[index]["60min"]!,

          ts: moment
            .unix(socketValue?.aiPrediction.ts)
            .utcOffset(localStorage.getItem("timezone")!)
            .format("DD/MM/YYYY HH:mm:ss"),
        };
        predicted["key"] = socketValue?.aiPrediction.ts + index ;
        setDataSource((prev) => [...prev, predicted]);
      }
    }
  }, [socketValue?.aiPrediction]);
  
  return (
    <>
      <Row>
        <Col span={8} offset={16}>
          <Button
            form="aiReporting"
            disabled={dataSource.length > 0 ? false : true}
            style={{
              color: "white",
              background: "#808080",
              width: "100%",
              marginBottom: "10px",
            }}
            icon={<DownloadOutlined />}
          >
            <CSVLink
              filename={selectedJunction?.name+"_"+t("ai_prediction")}
              data={dataSource}
              headers={headers}
              style={{ color: "white", background: "#808080", width: "100%" }}
            >
              {t("download_csv_data")}
            </CSVLink>
          </Button>
        </Col>
      </Row>
      <Row>
        <Table
          rowKey={"key"}
          style={{ height: "100%" }}
          dataSource={dataSource}
          columns={columns}
          size="small"
          tableLayout="fixed"
        />
      </Row>
    </>
  );
}

export default AiPredictionCard;
