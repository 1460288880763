import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";
import internal from "stream";
import { duration } from "moment";

export interface phaseDataModel {
  detectorDatas: [];
  dismissed: boolean;
  duration: number;
  greenTime: number;
  isUrgent: boolean;
  junctionId: string;
  newPhaseId: number;
  oldPhaseId: number;
  smartControl: number;
  ts: number;
  type: string;
  device_id: number;
  id: number;
  max: number;
  mean: number;
  min: number;
  name: string;
  vehicleCount: number;
  is_last: boolean;
}

export interface durationDataModel {
  actual:number;
  mean:number;
  phaseId:number;
  junctionId: string;
}

interface PhaseDataProps {
  phaseData: phaseDataModel[];
  durationData?: durationDataModel;
}

interface DurationDataProps {
  durationData: durationDataModel;
}

const PhaseDataContext = createContext<PhaseDataProps | undefined>(undefined);

export function PhaseDataProvider({ children }: PropsWithChildren<{}>) {
  const [phaseData, setPhaseData] = useState<phaseDataModel[]>([]);
  const [durationData, setDurationData] = useState<durationDataModel>();

  useEffect(() => {
    socket.on("phaseData", (data: any) => {
      setPhaseData((prev) => {
        if (prev.length > 100) prev.splice(0, 1);
        return [...prev, data];
      });
    });

    socket.on("actualDuration", (data: any) => {
      setDurationData(data);
    });

    return () => {
      socket.off("phaseData");
      socket.off("actualDuration");
    };
  }, []);

  return (
    <PhaseDataContext.Provider
      value={{
        phaseData: phaseData,
        durationData:durationData
      }}
    >
      {children}
    </PhaseDataContext.Provider>
  );
}

export function usePhaseData() {
  const context = useContext(PhaseDataContext);
  if (context === undefined) {
    return {
      phaseData: [],
      durationData:undefined
    };
    //throw new Error("usePhaseData must be used within a PhaseDataContext");
  }
  return context;
}
