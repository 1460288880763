import moment, { Moment } from "moment";
export function dateTimeConvertTimestamp(date: Moment, time?: Moment): number {
  let convertedDate = moment(date)
    .utcOffset(localStorage.getItem("timezone")!)
    .set({
      hour: time ? moment(time).get("hour") : 0,
      minute: time ? moment(time).get("minute") : 0,
      second: time ? moment(time).get("second") : 0,
    })
    .unix();
  return convertedDate;
}
