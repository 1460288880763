import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";

export interface deviceCommandStateModel {
  guid: number;
  commandState: number;
  ts: number;
}

interface DeviceStateDataProps {
  deviceStateData: deviceCommandStateModel[];
}
const DeviceStateContext = createContext<DeviceStateDataProps | undefined>(
  undefined
);

export function DeviceStateProvider({ children }: PropsWithChildren<{}>) {
  const [deviceCommandState, setDeviceCommandState] = useState<
    deviceCommandStateModel[]
  >([]);

  useEffect(() => {
    socket.on("deviceCommandState", (data: any) => {
      setDeviceCommandState((deviceCommandState) => [
        ...deviceCommandState,
        data,
      ]);
    });

    return () => {
      socket.off("deviceCommandState");
    };
  }, []);

  return (
    <DeviceStateContext.Provider
      value={{
        deviceStateData: deviceCommandState,
      }}
    >
      {children}
    </DeviceStateContext.Provider>
  );
}

export function useDeviceState() {
  const context = useContext(DeviceStateContext);
  if (context === undefined) {
    return {
      deviceStateData: [],
    };
    //throw new Error("useDeviceState must be used within a DeviceStateContext");
  }
  return context;
}
