import http from "../http.common";
import { JunctionGroup } from "./JunctionGroup";

const get = function (junctionId: number) {
  return http.post<JunctionGroup[]>(`/web/junction/group/list`,{junction_id:junctionId});
};
const add = function (data: JunctionGroup) {
  return http.post<JunctionGroup>(`/web/junction/group/add`, data);
};
const update = function (data:JunctionGroup) {
  return http.post<JunctionGroup>(`/web/junction/group/update`, data);
};

const deleteGroup = function (data:JunctionGroup) {
  return http.post<JunctionGroup>(`/web/junction/group/delete`, data);
}



const JunctionGroupService = {
  get,
  add,
  update,
  deleteGroup
};

export default JunctionGroupService;
