import { Col, Form, Input, message, Row, Select, Spin } from "antd";
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

// context
import { MultiModePlanContext } from "../../context/MultiModePlanContext";

import "./styles.css";

import { postSignalPlanToJunction } from "../../../../../data/mm-signal-plan/SignalPlanService";
import { useTranslation } from "react-i18next";
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;

interface Props {
  selectedJunction: any;
  manageSignalPlanRequests: any;
}

const FormWhenLocked: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={[50, 0]}>
      <Col span={12}>
        <Form.Item
          label={t("weekly_plan_name")}
          name="name"
          labelCol={{ span: 24 }}
          className="form-item-custom"
        >
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t("weekly_plan_version")}
          name="version"
          labelCol={{ span: 24 }}
          className="form-item-custom"
        >
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t("upload_date")}
          name="date"
          labelCol={{ span: 24 }}
          className="form-item-custom"
        >
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t("uploader")}
          name="uploader"
          labelCol={{ span: 24 }}
          className="form-item-custom"
        >
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t("change_note")}
          name="note"
          labelCol={{ span: 24 }}
          className="form-item-custom"
        >
          <TextArea disabled rows={3} bordered />
        </Form.Item>
      </Col>
    </Row>
  );
};

const FormWhenUnlocked: React.FC<any> = ({
  isNameFieldDisabled,
  setIsNameFieldDisabled,
}) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[50, 0]}>
      <Col span={12}>
        <Form.Item
          label={t("how_to_save")}
          name="howToSave"
          labelCol={{ span: 24 }}
          className="form-item-custom"
        >
          <Select
            onChange={(val: "update" | "create") => {
              if (val === "create") {
                setIsNameFieldDisabled(false);
              } else {
                setIsNameFieldDisabled(true);
              }
            }}
          >
            <Option key="update">{ t("update_existing") }</Option>
            <Option key="create">{ t("create_new") }</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t("weekly_plan_name")}
          name="name"
          labelCol={{ span: 24 }}
          className="form-item-custom"
        >
          <Input
            placeholder={t("enter_weekly_plan_name")}
            disabled={isNameFieldDisabled}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t("change_note")}
          name="note"
          labelCol={{ span: 24 }}
          className="form-item-custom"
        >
          <TextArea rows={3} placeholder={t("enter_note")} bordered />
        </Form.Item>
      </Col>
    </Row>
  );
};

const SignalPlanForm = React.forwardRef(
  ({ selectedJunction, manageSignalPlanRequests }: Props, ref) => {
    const { t } = useTranslation();
    const planContextValues = useContext(MultiModePlanContext);
    const [contextSignalPlan, setContextSignalPlan] =
      planContextValues.signalPlan;
    const [locked] = planContextValues.locked;

    const [formInitials, setFormInitials] = useState<any>(null);
    const [formReturnDefaultWatcher, setFormReturnDefaultWatcher] =
      useState<boolean>(true);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const triggerFormReturnDefaultWatcher = () => {
      setFormReturnDefaultWatcher((prev: boolean) => !prev);
    };

    const watchContextSignalPlanId = useMemo(
      () => contextSignalPlan?.id,
      [contextSignalPlan]
    );

    const getInitialsWhenLocked = () => {
      const date = moment(contextSignalPlan?.ts).utcOffset(localStorage.getItem("timezone")!).format('DD/MM/YYYY HH:mm')

      return {
        name: contextSignalPlan?.name,
        version: contextSignalPlan?.version,
        date: date,
        uploader: contextSignalPlan?.user,
        note: contextSignalPlan?.note,
      };
    };

    const getInitialsWhenUnlocked = () => {
      return {
        howToSave: "update",
        name: contextSignalPlan?.name,
        note: "",
      };
    };

    const [isNameFieldDisabled, setIsNameFieldDisabled] =
      useState<boolean>(true);

    useEffect(() => {
      if (locked) {
        setFormInitials(getInitialsWhenLocked());
      }
    }, [contextSignalPlan, locked]);

    // get default if signal plan is saved or or switch to another plan
    useEffect(() => {
      if (!locked) {
        setFormInitials(getInitialsWhenUnlocked());
        setIsNameFieldDisabled(true);
      }
    }, [formReturnDefaultWatcher, watchContextSignalPlanId, locked]);

    useEffect(() => {
      form.resetFields();
    }, [formInitials]);

    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
      save: () => form.submit(),
    }));

    const saveAndSendSignalPlan = async (formValues: any) => {
      const selectedJunctionId = +selectedJunction?.id;
      const selectedJunctionModelId = +selectedJunction?.junction_model_id;
      const signalPlanId = contextSignalPlan?.id;
      const signalPlanName = formValues?.name;
      const referenceId =
        formValues?.howToSave === "update" ? signalPlanId : null;

      const { howToSave, ...formPostValues } = formValues;

      const signalPlanPostData = {
        junction_id: selectedJunctionId,
        junction_model_id: selectedJunctionModelId,
        reference_id: referenceId,
        plan: contextSignalPlan,
        name: signalPlanName,
        ...formPostValues,
      };

      postSignalPlanToJunction(signalPlanPostData)
        .then(async (res: any) => {
          setIsLoading(false);
          if (res.status === 200 || res.status === 201) {
            message.success(t("signal_plan_saved"));
            await manageSignalPlanRequests();
            if (locked) {
              setFormInitials(getInitialsWhenLocked());
            } else {
              setFormInitials(getInitialsWhenUnlocked());
            }
          } else {
            message.error(t("failed_to_save_signal_plan"));
          }
        })
        .catch((_error) => {
          setIsLoading(false);
          if(_error.statusCode!=405)
            message.error(t("failed_to_save_signal_plan"));
        });
    };

    return (
      <>
        <Form
          form={form}
          initialValues={formInitials}
          className="signal-plan-form"
          onFinish={(formValues: any) => {
            saveAndSendSignalPlan(formValues);
            triggerFormReturnDefaultWatcher();
            setIsLoading(true);
          }}
        >
          {formInitials &&
            (!locked ? (
              <FormWhenUnlocked
                isNameFieldDisabled={isNameFieldDisabled}
                setIsNameFieldDisabled={setIsNameFieldDisabled}
              />
            ) : (
              <FormWhenLocked />
            ))}
        </Form>

        {/* isloading */}
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#333",
              opacity: "0.3",
              zIndex: 5,
            }}
          >
            <div
              style={{
                display: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              <Spin
                style={{
                  position: "fixed",
                  top: "50vh",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#fff",
                  zIndex: 6,
                }}
                tip="Sinyal planı kaydediliyor..."
                size="large"
              />
            </div>
          </div>
        )}
      </>
    );
  }
);

export default SignalPlanForm;
