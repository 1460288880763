import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";
import { JunctionQueueLength } from "../../data/fcd/AllDirectionQueueLength";

interface QueueLengthDataProps {
  queueLengthData: JunctionQueueLength[] | undefined;
}

const QueueLengthDataContext = createContext<QueueLengthDataProps | undefined>(
  undefined
);

export function QueueLengthDataProvider({ children }: PropsWithChildren<{}>) {
  const [queueLengthData, setQueueLengthData] =
    useState<JunctionQueueLength[]>([]);

  useEffect(() => {
    socket.on("fcdQueueLength", (data: JunctionQueueLength[]) => {
      setQueueLengthData(data);
    });

    return () => {
      socket.off("fcdQueueLength");
    };
  }, []);

  return (
    <QueueLengthDataContext.Provider
      value={{
        queueLengthData: queueLengthData,
      }}
    >
      {children}
    </QueueLengthDataContext.Provider>
  );
}

export function useQueueLengthData() {
  const context = useContext(QueueLengthDataContext);
  if (context === undefined) {
    return {
      queueLengthData: undefined,
    };
    // throw new Error(
    //   "useQueueLengthData must be used within a QueueLengthDataContext"
    // );
  }
  return context;
}
