import {
  CarOutlined,
  DatabaseOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { Col, Radio, RadioChangeEvent, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StatisticContext } from "../../context/StatisticContext";

const gutterStlye = { xs: 8, sm: 16, md: 24, lg: 32 };
const colSpanValue = 10;
const iconStyle = { marginRight: "5px" };

// will be deleted after language support
const headerSubNameDict: any = {
  daily: "daily",
  pastRecord: "past_record",
  phaseData: "phase_data",
  ssmData: "ssm_data",
  weekly: "weekly",
  monthly: "monthly",
  aiPredictionData: "ai_prediction_data",
  queueLengthData: "queue_length",
  statusData: "status_data"
};

function Headers() {
  const { t } = useTranslation();
  const statisticContextValue = useContext(StatisticContext);

  const onChange = (e: RadioChangeEvent) => {
    let splitValue = e.target.value.split(".");
    //splitValue[0] = epic, splitValue[1]=sub
    statisticContextValue?.updater(splitValue[0], splitValue[1]);
  };

  return (
    <>
      <Row gutter={gutterStlye}>
        <Col className="gutter-row" span={4}>
          <Title level={5}>
            <ProfileOutlined style={iconStyle} />
            {t("summary")}
          </Title>
        </Col>
        <Col className="gutter-row" span={colSpanValue}>
          <Title level={5}>
            <DatabaseOutlined style={iconStyle} />
            {t("archive")}
          </Title>
        </Col>
        <Col className="gutter-row" span={colSpanValue}>
          <Title level={5}>
            <CarOutlined style={iconStyle} />
            {t("vehicle_density")}
          </Title>
        </Col>
      </Row>
      <Radio.Group
        style={{ display: "inherit" }}
        buttonStyle="solid"
        onChange={onChange}
        defaultValue="summary.daily"
      >
        <Row gutter={gutterStlye}>
          {
            Object.entries(statisticContextValue!.headerDirector).map(([epic,prop]:any,index:number)=>{
              return (
                <Col key={epic} className="gutter-row" span={index == 0 ? 4 : colSpanValue}>
                  {
                    Object.entries(prop).map(([sub,val]:any)=>{
                      return (
                        <Radio.Button key={sub} value={`${epic}.${sub}`}>
                          {/* {sub} */}
                          {t(headerSubNameDict?.[sub])}
                          {/* {headerSubNameDict?.[sub]} */}
                        </Radio.Button>
                      )
                    })
                  }
                </Col>
              )
            })
          }    
        </Row>
      </Radio.Group>
    </>
  );
}
export default Headers;
