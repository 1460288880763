import { BarChartOutlined, DatabaseOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import { t } from "i18next";
import { latLng } from "leaflet";
import { useContext, useState } from "react";
import { Junction } from "../../../data/junction/Junction";
import AiPredictionCard from "./AiPredictionCard";
import JunctionStatusInformation from "./JunctionStatusInformation";
import MapViews from "./MapViews";
import PhasePlot from "./PhasePlot";
import PhaseTable from "./PhaseTable";
import SsmPlot from "./SSM/index";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";
import { PhaseDataProvider } from "../../../contexts/junction/PhaseDataContext";
import { SsmDataProvider } from "../../../contexts/junction/SsmDataContext";
import { AiPredictionProvider } from "../../../contexts/junction/AiPredictionContext";
import { JunctionEventProvider } from "../../../contexts/junction/JunctionEventContext";
import { JunctionStatusDataProvider } from "../../../contexts/junction/JunctionStatusDataContext";
import { QueueLengthDataProvider } from "../../../contexts/junction/QueueLengthContext";

interface Props {
  selectedJunction?: Junction;
  changeTab: boolean;
}

const gutter = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};

const cardHeadStyle = { fontSize: "14px", fontWeight: "500" };

function ViewTab(props: Props) {
  const { selectedJunction, changeTab } = props;
  const [phaseDisplay, setPhaseDisplay] = useState(true); //true = Phase Table, false = Phase Plot
  const { hasAccessComponent } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  const changePhaseDisplay = () => {
    setPhaseDisplay(!phaseDisplay);
  };
  return (
    <>
      <Row gutter={gutter}>
        <Col
          style={{ marginTop: "16px" }}
          xl={12}
          lg={12}
          md={12}
          sm={24}
          xs={24}
        >
          <Card
            bodyStyle={{ maxHeight: "421px", padding: "0", height: "100vh" }}
            headStyle={cardHeadStyle}
          >
            <JunctionStatusDataProvider>
              <PhaseDataProvider>
                <SsmDataProvider>
                  <MapViews
                    selectedJunction={selectedJunction}
                    latlon={latLng(
                      selectedJunction!.latitude,
                      selectedJunction!.longitude
                    )}
                    changeTab={changeTab}
                  ></MapViews>
                </SsmDataProvider>
              </PhaseDataProvider>
            </JunctionStatusDataProvider>
          </Card>
        </Col>
        <Col style={{ marginTop: "16px" }} xl={12} lg={12} md={24} sm={24}>
          <Card
            bodyStyle={{ padding: "0px 0px 0px 0px" }}
            title={t("phase_datas")}
            headStyle={cardHeadStyle}
            extra={
              <Button
                type="primary"
                onClick={changePhaseDisplay}
                icon={
                  phaseDisplay ? <BarChartOutlined /> : <DatabaseOutlined />
                }
              ></Button>
            }
          >
            <PhaseDataProvider>
              {phaseDisplay ? (
                <PhaseTable selectedJunction={selectedJunction} />
              ) : (
                <PhasePlot />
              )}
            </PhaseDataProvider>
          </Card>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col
          style={{ marginTop: "10px", width: "400px" }}
          xl={15}
          lg={15}
          md={24}
          sm={24}
        >
          <Card
            title={t("ssm_datas")}
            headStyle={cardHeadStyle}
            bodyStyle={{ padding: "5px" }}
          >
            <PhaseDataProvider>
              <SsmDataProvider>
                <SsmPlot selectedJunction={selectedJunction} />
              </SsmDataProvider>
            </PhaseDataProvider>
          </Card>
        </Col>
        <Col style={{ marginTop: "10px" }} xl={9} lg={9} md={9} sm={24}>
          <Card
            bodyStyle={{ padding: "0px 0px 10px 10px" }}
            hidden={selectedJunction?.listen_mode}
          >
            <PhaseDataProvider>
              <JunctionStatusDataProvider>
                <JunctionEventProvider>
                  <JunctionStatusInformation
                    selectedJunction={selectedJunction}
                  />
                </JunctionEventProvider>
              </JunctionStatusDataProvider>
            </PhaseDataProvider>
          </Card>
        </Col>
      </Row>
      {hasAccessComponent("PredictionTable-READ") && (
        <Row gutter={gutter} style={{ marginTop: "16px" }}>
          <Col style={{ marginTop: "16px" }} xl={12} lg={12} md={24} sm={24}>
            <Card title={t("ai_prediction")} headStyle={cardHeadStyle}>
              <AiPredictionProvider>
                <AiPredictionCard selectedJunction={selectedJunction} />
              </AiPredictionProvider>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ViewTab;
