import http from "../http.common";

export const getPhases = (data: any) => {
  return http.post("/web/junction/phase/list", data);
};

export const getDetectors = (data: any) => {
  return http.post("/web/junction/detector/list", data);
};

export const listSignalPlanExpressions = (juncId: number) => {
  return http.post("/web/junction/plan/list", {
    junction_id: juncId,
  });
};

export const fetchSignalPlanWithItsId = (signalPlanId: number) => {
  return http.post("/web/junction/plan/get", {
    id: signalPlanId,
  });
};

export const fetchActiveSignalPlan = ({
  juncId,
  juncModelId,
}: {
  juncId: number;
  juncModelId: number;
}) => {
  return http.post("/web/junction/plan/current", {
    junction_id: juncId,
    junction_model_id: juncModelId,
  });
};

export const fetchActivePlan = ({
  juncId,
  juncModelId,
  requestTime,
}: {
  juncId: number;
  juncModelId: number;
  requestTime: number;
}) => {
  return http.post("/web/junction/plan/activeplan", {
    junction_id: juncId,
    junction_model_id: juncModelId,
    requestTime: requestTime,
  });
};

export const postSignalPlanToJunction = (data: any) => {
  return http.post("/web/junction/plan/add", data);
};
