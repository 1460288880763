import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import StyledButton from "../../components/custom-styled/button/StyledButton";
import { DeploymentUnitOutlined } from "@ant-design/icons";
import { useBluesisData } from "../../../../contexts/bluesis/BluesisDataContext";
import { t } from "i18next";

function BluesisRouteControl() {
  const { setVisibleBluesisRoute } = useBluesisData();
  const [isActive, setIsActive] = useState(false);

  const toggleMap = () => {
    setIsActive((prev) => !prev);
  };

  useEffect(() => {
    setVisibleBluesisRoute(isActive);
  }, [isActive]);

  return (
    <div className="leaflet-bar" style={{ border: "none" }}>
      <Tooltip placement="right" title={`Bluesis ${t("route")} ${t("on_off")}`}>
        <StyledButton
          icon={<DeploymentUnitOutlined />}
          backgroundColor={isActive ? "#00FF00" : "#2F4050"}
          borderColor={isActive ? "#00FF00" : "#2F4050"}
          onClick={toggleMap}
          style={{
            color: "white",
            marginLeft: 0,
            paddingLeft: 7,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 8,
          }}
        >
          <p
            style={{
              marginBottom: 0,
              color: "#fff",
              fontSize: "13px",
            }}
          >
            {`${t("route")} ${t("on_off")}`}
          </p>
        </StyledButton>
      </Tooltip>
    </div>
  );
}

export default BluesisRouteControl;
