import http from "../http.common";
import { JunctionPhaseDuration } from "./JunctionPhaseDuration";
import { Polygon } from "./Polygon";

const getPolygons = function () {
  return http.get<Polygon[]>(`/web/emergency/listpolygons`);
};

const increasePhaseDuration = function (junctionPhaseDuration:JunctionPhaseDuration) {
  return http.post<JunctionPhaseDuration>(`/web/emergency/giveawayVehicle`,junctionPhaseDuration);
};

const EmergencyPassSystemService = {
  getPolygons,
  increasePhaseDuration,
};

export default EmergencyPassSystemService;
