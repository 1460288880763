import React from "react";
import { Popup } from "react-leaflet";
import {
  Task,
  taskStatusColors,
  taskStatusNames,
  taskTypeEnum,
  taskTypeImages,
} from "../../../../data/task-management/TaskManagement";
import "./taskmanagement.css";
import { t } from "i18next";
import {
  CalendarFilled,
  ClockCircleFilled,
  TeamOutlined,
} from "@ant-design/icons";
import { useTaskManagementData } from "../../../../contexts/taskmanagement/TaskManagementDataContext";

interface TaskManagementProps {
  task: any;
  onClick: (Task: Task) => void;
}

export default function TaskManagementPopup({
  task,
  onClick,
}: Readonly<TaskManagementProps>) {
  const date = new Date(task.start_time);
  const { technicianList } = useTaskManagementData();

  const handleClick = () => {
    // Your function to be triggered when the Popup is clicked
    onClick(task);
  };

  const renderAttendantList = (list: any[]) => {
    const nameList: any[] = [];
    list.forEach((userId: any) => {
      const foundUser = technicianList.find(
        (technician) => technician.id === userId
      );
      nameList.push(foundUser);
    });
    return nameList.map((person: any) => person?.name).join(", ");
  };

  return (
    <Popup closeButton={false} minWidth={420} interactive={false}>
      <span onClick={handleClick} style={{ cursor: "pointer" }}>
        <div style={{ width: "422px", height: "auto" }}>
          <div
            className="popup-topcontainer"
            style={{
              backgroundColor:
                task.priority === 2 && task.task_status !== 4
                  ? "#E72929"
                  : taskStatusColors[task.task_status],
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "44px",
                  height: "44px",
                  borderRadius: "50%",
                  backgroundColor: "#ffffff",
                  marginRight: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={`data:image/png;base64,${
                    task.task_type >= 1 &&
                    task.task_type <= taskTypeImages.length
                      ? taskTypeImages[task.task_type]
                      : taskTypeImages[0]
                  }`}
                  alt="Custom Icon"
                  style={{
                    width: "27px",
                    height: "27px",
                  }}
                />
              </div>
              <div>
                <p
                  className="popuptext"
                  style={{ fontSize: "0.85rem", fontWeight: "700", margin: 0 }}
                >
                  {task.title}
                </p>
                <p
                  className="popuptext"
                  style={{ margin: 0, paddingTop: "4px" }}
                >
                  {taskTypeEnum[task.task_type]}
                </p>
              </div>
            </div>
            <div
              className="status-badge"
              style={{ borderColor: taskStatusColors[task.task_status] }}
            >
              <p
                className="badge-text"
                style={{
                  color:
                    task.priority === 2 && task.task_status !== 4
                      ? "red"
                      : taskStatusColors[task.task_status],
                }}
              >
                {t(
                  task.priority === 2 && task.task_status !== 4
                    ? "urgent"
                    : taskStatusNames[task.task_status]
                )}
              </p>
            </div>
          </div>

          <div className="popup-bottomcontainer">
            <p className="popupbottomtext">{task.description}</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "14px",
                paddingBottom: "10px",
              }}
            >
              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <CalendarFilled style={{ fontSize: "16px" }} />
                  <p
                    className="popupbottomtext"
                    style={{
                      fontWeight: "bold",
                      alignSelf: "center",
                      paddingBottom: "7px",
                      paddingTop: "4px",
                    }}
                  >
                    Başlangıç
                  </p>
                </div>
                {date.toLocaleDateString("tr-TR", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
              </div>

              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <ClockCircleFilled style={{ fontSize: "15px" }} />
                  <p
                    className="popupbottomtext"
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      paddingBottom: "7px",
                      paddingTop: "4px",
                    }}
                  >
                    Tahmini Süre
                  </p>
                </div>
                <p className="popupbottomtext" style={{ textAlign: "center" }}>
                  {task?.est_time ? task.est_time : "-"}
                </p>
              </div>

              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <TeamOutlined style={{ fontSize: "16px" }} />
                  <p
                    className="popupbottomtext"
                    style={{
                      fontWeight: "bold",
                      alignSelf: "center",
                      paddingBottom: "7px",
                      paddingTop: "4px",
                    }}
                  >
                    Görevli Listesi
                  </p>
                </div>

                <div className="name-list">
                  <p
                    className="popupbottomtext"
                    style={{ textAlign: "center" }}
                  >
                    {task.detail.info?.attendant_list &&
                      renderAttendantList(task.detail.info?.attendant_list)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </Popup>
  );
}
