import Flex from "../../components/custom-styled/layout/Flex";
import LanguageControl from "./LanguageControl";
import NotificationControl from "./NotificationControl";
import { NotificationProvider } from "../../../../contexts/information/NotificationContext";
import ProfileControl from "./ProfileControl";
import LayerControl from "./LayerControl";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";
import { useContext } from "react";

function TopRightControl() {
  const { hasAccessComponent } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  return (
    <div className="leaflet-top leaflet-right">
      <div className="leaflet-control leaflet-bar" style={{ border: "none" }}>
        <Flex
          style={{ opacity: "0.9" }}
          justifyContent="flex-end"
          alignItems="center"
          width="100%"
        >
          <LanguageControl />
          {hasAccessComponent("Junction Module-READ") && (
            <NotificationProvider>
              <NotificationControl />
            </NotificationProvider>
          )}
          <ProfileControl />
          <LayerControl />
        </Flex>
      </div>
    </div>
  );
}

export default TopRightControl;
