import { WMSTileLayer } from "react-leaflet";
import withLogging from "../../high-order-components/LoggerHOC";
import { useFCDData } from "../../../../contexts/fcd/FCDContext";
import { useLicenseData } from "../../../../contexts/license/LicenseDataContext";

function FCDLayer() {
  const { visibleSegmentBasedSpeed } = useFCDData();
  const { licenseData } = useLicenseData();

  return (
    <>
      {licenseData?.geoserverUrl && visibleSegmentBasedSpeed && (
        <WMSTileLayer
          url={`${licenseData?.geoserverUrl}/fcd/wms`}
          layers="fcd:fcd_segment_data"
          format="image/png"
          transparent={true}
          version="1.1.0"
        />
      )}
    </>
  );
}

export default withLogging(FCDLayer);
