import { Button, Col, DatePicker, Form, Row } from "antd";
import { t } from "i18next";
import React, { useState } from "react";
import { getArchivedTasks } from "../../../../../data/task-management/TaskManagementService";
import { useTaskManagementData } from "../../../../../contexts/taskmanagement/TaskManagementDataContext";

function MarkerDatePickerModal() {
  const [form] = Form.useForm();
  const [serviceLoad, setServiceLoad] = useState(false);
  const { archivedTaskList, updateArchivedTaskList } = useTaskManagementData();
  const [serviceData, setServiceData] = useState<any[] | null>(null);
  const handleFilter = async () => {
    const values = await form.validateFields();
    if (isObjectNotEmpty(values)) {
      try {
        setServiceLoad(true);
        const startValue: Date = values.startDate.valueOf();
        const startDate = new Date(startValue);
        startDate.setHours(0, 0, 0, 0);
        const endValue: Date = values.endDate.valueOf();
        const endDate = new Date(endValue);
        endDate.setHours(23, 59, 0, 0);
        await getArchivedTasks({
          startTime: startDate.getTime(),
          endTime: endDate.getTime(),
        })
          .then((res: any) => {
            updateArchivedTaskList(res.data);
            setServiceData(res.data);
          })
          .catch((err) => {
            console.log("Erros while fetching archived", err);
          });
        // Call the onFilter function passed from the parent component
      } catch (error) {
        console.error("Failed to filter tasks:", error);
      } finally {
        setServiceLoad(false);
      }
    }
  };

  function isObjectNotEmpty(obj: any) {
    for (var key in obj) {
      if (!obj[key]) {
        return false; // If the object has at least one property, it's not empty
      }
    }
    return true; // Object is empty
  }

  return (
    <>
      <Form form={form} layout="inline" onFinish={handleFilter}>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="startDate" label={t("start_time")}>
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="endDate" label={t("end_time")}>
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={serviceLoad}>
                {t("filter")}
              </Button>
            </Form.Item>
            {serviceData?.length === 0 && (
              <span style={{ color: "#6a6a6a" }}>{t("no_data")}</span>
            )}
          </Col>
        </Row>
      </Form>
      {archivedTaskList.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button type="text" onClick={() => updateArchivedTaskList([])}>
            <span
              style={{ color: "#E72929", fontWeight: 700, textAlign: "center" }}
            >
              Listeyi Temizle
            </span>
          </Button>
        </div>
      )}
    </>
  );
}

export default MarkerDatePickerModal;
