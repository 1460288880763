import { Button, Checkbox, DatePicker, Form, Select, TimePicker } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import VehicleDensityService from "../../../../services/VehicleDensityService";
import PlotsForDaily from "../Plots/PlotsForDaily";
import { dateTimeConvertTimestamp } from "../../../../utils/dateTimeConvertTimestamp";
import colorPallet from "../../../../../SignalPlan/common/colorPallet";
import { IHandleStatistic } from "../../../../models/IHandleStatistic";
import { useTranslation } from "react-i18next";

interface Props {
  selectedJunction: any;
  isHaveLoop: boolean;
}

const { Option } = Select;

const timePickerFormat = "HH:mm";
const datePickerFormat = "DD/MM/YYYY";
const formLabelSpan = { span: 24 };

enum DailyFormFileds {
  "startDate" = "startDate",
  "startTime" = "startTime",
  "endTime" = "endTime",
  "interval" = "interval",
  "signalPlan" = "signalPlan",
  "dataType" = "dataType",
}

const FilterAreaForDaily: React.FC<Props> = ({
  selectedJunction,
  isHaveLoop,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [plotData, setPlotData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.setFields([
      { name: DailyFormFileds.startDate, value: moment() },
      {
        name: DailyFormFileds.startTime,
        value: moment("06:00", timePickerFormat),
      },
      {
        name: DailyFormFileds.endTime,
        value: moment().utcOffset(localStorage.getItem("timezone")!),
      },
      { name: DailyFormFileds.interval, value: 15 },
      { name: DailyFormFileds.signalPlan, value: false },
      { name: DailyFormFileds.dataType, value: "detector" },
    ]);
  }, [form]);

  const datePickerChange = (e: any) => {
    //updating the timepicker based on whether the date is today or not
    new Date(e).getDate() === new Date().getDate()
      ? form.setFields([
          {
            name: DailyFormFileds.endTime,
            value: moment().utcOffset(localStorage.getItem("timezone")!),
          },
        ])
      : form.setFields([
          {
            name: DailyFormFileds.endTime,
            value: moment("22:00", timePickerFormat),
          },
        ]);
  };

  const createPlot = async () => {
    let formValue = form.getFieldsValue();
    let convertedStartTime = dateTimeConvertTimestamp(
      formValue.startDate,
      formValue.startTime
    );
    let convertedEndTime = dateTimeConvertTimestamp(
      formValue.startDate,
      formValue.endTime
    );

    formValue.data = [];
    formValue.dynamicConfigData = [];
    formValue.signalPlanColor = [];
    setIsLoading(true);

    await VehicleDensityService.fetchStatisticData(
      convertedStartTime,
      convertedEndTime,
      +selectedJunction.id,
      "day",
      formValue.dataType ?? "detector",
      formValue.interval
    ).then((res) => {
      let result: IHandleStatistic[] = res.data.result;
      let uniqDetectorId: number[] = [];

      for (let index = 0; index < result.length; index++) {
        let statisticDataKeys = Object.keys(
          result[index]?.getstatistic?.statisticData
        )?.[0];
        let statisticDataArr =
          result[index].getstatistic.statisticData[statisticDataKeys];
        let time = moment
          .utc(statisticDataKeys)
          .utcOffset(localStorage.getItem("timezone")!)
          .format(timePickerFormat);
        let vehicleCount = 0;

        if (statisticDataArr) {
          for (let index = 0; index < statisticDataArr.length; index++) {
            if (statisticDataArr[index].detectorDatas) {
              for (
                let detectorDataIndex = 0;
                detectorDataIndex <
                statisticDataArr[index].detectorDatas.length;
                detectorDataIndex++
              ) {
                if (
                  statisticDataArr[index].detectorDatas[detectorDataIndex]
                    .vehicle_count !== -1
                ) {
                  vehicleCount +=
                    statisticDataArr[index].detectorDatas[detectorDataIndex]
                      .vehicle_count;
                }
                if (
                  !uniqDetectorId.includes(
                    statisticDataArr[index].detectorDatas[detectorDataIndex]
                      .detector_id
                  )
                ) {
                  uniqDetectorId.push(
                    statisticDataArr[index].detectorDatas[detectorDataIndex]
                      .detector_id
                  );
                  formValue.dynamicConfigData[
                    `${statisticDataArr[index].detectorDatas[detectorDataIndex].detector_id}`
                  ] = [];
                }
              }
            }
          }

          for (let index = 0; index < uniqDetectorId.length; index++) {
            let detectorVehicleCount = 0;
            let detectorName;
            for (
              let statisticIndex = 0;
              statisticIndex < statisticDataArr.length;
              statisticIndex++
            ) {
              for (
                let detectorDataIndex = 0;
                detectorDataIndex <
                statisticDataArr[statisticIndex].detectorDatas.length;
                detectorDataIndex++
              ) {
                if (
                  statisticDataArr[statisticIndex].detectorDatas[
                    detectorDataIndex
                  ].detector_id === uniqDetectorId[index] &&
                  statisticDataArr[statisticIndex].detectorDatas[
                    detectorDataIndex
                  ].vehicle_count !== -1
                ) {
                  detectorVehicleCount +=
                    statisticDataArr[statisticIndex].detectorDatas[
                      detectorDataIndex
                    ].vehicle_count;
                  detectorName =
                    statisticDataArr[statisticIndex].detectorDatas[
                      detectorDataIndex
                    ].name;
                }
              }
            }
            let detectorCount = {
              vehicleCount: detectorVehicleCount,
              time: time,
              name: `${detectorName} (${time})`,
            };
            formValue.dynamicConfigData[uniqDetectorId[index]].push(
              detectorCount
            );
          }
          if (formValue.signalPlan) {
            formValue.signalPlanColor.push(
              colorPallet[statisticDataArr[0].planId]
            );
          }
        } else {
          for (let index = 0; index < uniqDetectorId.length; index++) {
            let detectorCount = {
              vehicleCount: 0,
              time: time,
              name: `${time}`,
            };
            if (
              !(
                formValue.endTime
                  .utcOffset(localStorage.getItem("timezone"))
                  .format(timePickerFormat) == time
              )
            ) {
              formValue.dynamicConfigData[uniqDetectorId[index]].push(
                detectorCount
              );
            }
          }
        }
        let count = {
          vehicleCount: vehicleCount,
          time: time,
          name: `${t("total_number_of_vehicles")} (${time})`,
        };
        if (
          !(
            formValue.endTime
              .utcOffset(localStorage.getItem("timezone"))
              .format(timePickerFormat) == time
          )
        ) {
          formValue.data.push(count);
        }
      }
    });
    //It removes the empty values in the dynamicConfigData array because there is no statistical data in the loop(Device).
    formValue.dynamicConfigData = formValue.dynamicConfigData.filter(
      (item: any) => item
    );
    formValue.endTime = formValue.endTime
      .utcOffset(localStorage.getItem("timezone")!)
      .format(timePickerFormat);
    formValue.startTime = formValue.startTime
      .utcOffset(localStorage.getItem("timezone")!)
      .format(timePickerFormat);
    formValue.startDate = formValue.startDate
      .utcOffset(localStorage.getItem("timezone")!)
      .format("LL");
    setPlotData(formValue);
    setIsLoading(false);
  };
  return (
    <>
      <Form layout="inline" form={form} name="vehicleDensityDaily">
        <Form.Item
          labelCol={formLabelSpan}
          label={t("start_date")}
          name={DailyFormFileds.startDate}
        >
          <DatePicker format={datePickerFormat} onChange={datePickerChange} />
        </Form.Item>
        <Form.Item
          labelCol={formLabelSpan}
          label={t("end_time")}
          name={DailyFormFileds.startTime}
        >
          <TimePicker format={timePickerFormat} />
        </Form.Item>
        <Form.Item
          labelCol={formLabelSpan}
          label={t("end_time")}
          name={DailyFormFileds.endTime}
        >
          <TimePicker format={timePickerFormat} />
        </Form.Item>
        <Form.Item
          labelCol={formLabelSpan}
          label={t("interval")}
          name={DailyFormFileds.interval}
        >
          <Select style={{ width: 120 }}>
            <Option value={5}>5 {t("minute_abb")}</Option>
            <Option value={15}>15 {t("minute_abb")}</Option>
            <Option value={60}>60 {t("minute_abb")}</Option>
          </Select>
        </Form.Item>
        {isHaveLoop && (
          <Form.Item
            labelCol={formLabelSpan}
            label={t("sensor_list")}
            name={DailyFormFileds.dataType}
          >
            <Select style={{ width: 50 }}>
              <Option value={"detector"}>{t("detector")}</Option>
              <Option value={"loop"}>{t("Loop")}</Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item
          labelCol={formLabelSpan}
          label={t("color_by_signal_plan")}
          name={DailyFormFileds.signalPlan}
          valuePropName="checked"
        >
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item labelCol={formLabelSpan} label={<span></span>}>
          <Button
            type="primary"
            onClick={createPlot}
            form="vehicleDensityDaily"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            {t("apply")}
          </Button>
        </Form.Item>
      </Form>
      {plotData && (
        <PlotsForDaily
          style={{ marginTop: "25px" }}
          datas={plotData}
        ></PlotsForDaily>
      )}
    </>
  );
};

export default FilterAreaForDaily;
