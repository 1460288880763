import { useEffect, useMemo, useState } from "react";
import { LeafletMouseEvent } from "leaflet";
import { Marker, Tooltip, GeoJSON, Popup, useMap } from "react-leaflet";
import withLogging from "../../high-order-components/LoggerHOC";
import GenericMapModal from "../../components/generic/map-modal/GenericMapModal";
import BluesisModalTitle from "./BluesisModalTitle";
import { Bluesis } from "../../../../data/bluesis/Bluesis";
import BluesisMain from "../../../bluesis/modal";
import RouteDetails from "../../../bluesis/map-content/RouteDetails";
import { Route } from "../../../../data/bluesis/Route";
import { Vector } from "../../../../data/bluesis/Vector";
import { useBluesisData } from "../../../../contexts/bluesis/BluesisDataContext";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import { t } from "i18next";
import GenericMarkerDeleteModal from "../../components/generic/marker-context-modal/GenericMarkerDeleteModal";
import MarkerGenericAddUpdateContent from "../../components/generic/marker-context-modal/MarkerGenericAddUpdateContent";
import { bluesisModel } from "../../components/generic/marker-context-modal/model/Bluesis";
import L from "leaflet";
import BluesisService from "../../../../data/bluesis/BluesisService";
import { BluesisStatusInfo } from "../../map-constants/module-status/BluesisStatusInfo";
import { MapModule } from "@icms/ui-components"
import { useBluesisStatusData } from "../../../../contexts/bluesis/BluesisStatusDataContext";
import { bluesisIconBase64 } from "../../map-constants/static/MarkerIconBase64";

function BluesisLayer() {
  const {
    visibleBluesisRoute,
    bluesisList,
    routeList,
    vectorList,
    updateBluesisList,
  } = useBluesisData();
  const { bluesisStatusData } = useBluesisStatusData();
  const { setLayerData } = useCustomLayerData();
  const [markerData, setMarkerData] = useState<Bluesis[]>([]);
  const [vectorMarkerData, setVectorMarkerData] = useState<Vector[]>([]);
  const [selectedWithDblClickBluesis, setSelectedWithDblClickBluesis] =
    useState<Bluesis>();
  const [selectedWithClickBluesis, setSelectedWithClickBluesis] =
    useState<Bluesis>();
  const [filteredBluesisRoutes, setFilteredBluesisRoutes] = useState<Route[]>(
    []
  );
  const [openRouteDetails, setOpenRouteDetails] = useState<boolean>(false);
  const [bluesis, setBluesis] = useState<Bluesis>();

  const map = useMap();

  const markerHandlers = (bluesis: Bluesis) => {
    return {
      dblclick: (event: LeafletMouseEvent) =>
        handleMarkerDblClick(event, bluesis),
      click: (event: LeafletMouseEvent) => handleMarkerClick(event, bluesis),
      contextmenu: (event: LeafletMouseEvent) => {
        map.contextmenu.removeAllItems();
        map.contextmenu.addItem({
          text: t("update"),
          callback: () => updateBluesis(bluesis),
        });
        map.contextmenu.addItem({
          text: t("delete"),
          callback: () => deleteBluesis(bluesis),
        });
      },
    };
  };

  const updateBluesis = (bluesis: Bluesis) => {
    setBluesis(bluesis);
  };

  const deleteBluesis = (bluesis: Bluesis) => {
    GenericMarkerDeleteModal(bluesis.name, async () => {
      await BluesisService.deleteBluesis(bluesis.id);
      updateBluesisList();
    });
  };

  const handleMarkerClick = (event: LeafletMouseEvent, bluesis: Bluesis) => {
    setSelectedWithClickBluesis(bluesis);
  };

  const handleMarkerDblClick = (event: LeafletMouseEvent, bluesis: Bluesis) => {
    setSelectedWithDblClickBluesis(bluesis);
  };

  const onCloseModal = () => {
    setBluesis(undefined);
    setSelectedWithDblClickBluesis(undefined);
  };

  useEffect(() => {
    setLayerData({
      id: MapModule.BluesisModule,
      data: bluesisList,
      searchable: {
        category: "bluesis",
        filterProperties: ["id", "name"],
        labelProperty: "name",
      },
      legend: {
        countProperty: "device_type",
        setOnMapData: setMarkerData,
      },
    });
    setMarkerData(bluesisList);
  }, [bluesisList]);

  useEffect(() => {
    setVectorMarkerData(vectorList);
  }, [vectorList]);

  useEffect(() => {
    if (selectedWithClickBluesis) {
      let filteredRoutes = routeList.filter((item) => {
        if (selectedWithClickBluesis.route_list.includes(item.id)) {
          return item;
        }
      });
      setFilteredBluesisRoutes(filteredRoutes);
      setOpenRouteDetails(true);
    }
  }, [selectedWithClickBluesis]);

  useEffect(() => {
    if(bluesisStatusData){
      let fiindedBluesis = markerData.find(
        (item) =>
          item.device_id ==bluesisStatusData?.bluesisId
      );
      if (fiindedBluesis) {
        fiindedBluesis.device_type =bluesisStatusData.statusId;
        setMarkerData((prev) =>
          prev.map((item) =>
            item.id === fiindedBluesis?.id ? fiindedBluesis : item
          )
        );
      }
    }
  }, [bluesisStatusData]);

  const modalContent = useMemo(() => {
    return (
      <>
        {selectedWithDblClickBluesis && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <BluesisModalTitle
                componentName="BluesisModalTitle"
                selectedBluesis={selectedWithDblClickBluesis}
              />
            }
          >
            <BluesisMain
              selectedBluesis={selectedWithDblClickBluesis}
              selectedTab="1"
            />
          </GenericMapModal>
        )}
      </>
    );
  }, [selectedWithDblClickBluesis]);

  const addUpdateModalContent = useMemo(() => {
    return (
      <>
        {bluesis && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <BluesisModalTitle
                componentName="BluesisModalTitle"
                selectedBluesis={bluesis}
              />
            }
          >
            <MarkerGenericAddUpdateContent
              modalType="update"
              model={bluesisModel}
              setModalVisible={onCloseModal}
              icon={bluesisIconBase64}
              latlon={new L.LatLng(bluesis.latitude, bluesis.longitude)}
              triggerStateFunc={updateBluesisList}
              formData={bluesis}
            />
          </GenericMapModal>
        )}
      </>
    );
  }, [bluesis]);

  const routeDetailsContent = useMemo(() => {
    return (
      <>
        {openRouteDetails && (
          <RouteDetails
            routes={filteredBluesisRoutes}
            vectors={vectorList}
            visible={openRouteDetails}
            setVisible={setOpenRouteDetails}
            bluesisList={bluesisList}
            selectedBluesis={selectedWithClickBluesis}
            setSelectedBluesis={setSelectedWithClickBluesis}
          ></RouteDetails>
        )}
      </>
    );
  }, [openRouteDetails]);

  return (
    <>
      {markerData.length > 0 &&
        markerData.map((bluesis) => {
          return (
            <Marker
              key={bluesis.id}
              position={[bluesis.latitude, bluesis.longitude]}
              icon={BluesisStatusInfo[bluesis.device_type].marker}
              eventHandlers={markerHandlers(bluesis)}
              contextmenu={true}
            >
              <Tooltip>{bluesis.name}</Tooltip>
            </Marker>
          );
        })}
      {visibleBluesisRoute &&
        vectorMarkerData.length > 0 &&
        vectorMarkerData.map((vector) => {
          return (
            <GeoJSON
              key={vector.id}
              data={JSON.parse(vector.geom)}
              style={{ color: "#5E6871", weight: 5, opacity: 0.65 }}
            >
              <Popup content={vector.name} />
            </GeoJSON>
          );
        })}
      {modalContent}
      {addUpdateModalContent}
      {routeDetailsContent}
    </>
  );
}

export default withLogging(BluesisLayer);
