import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";

export interface statusDataModel {
  id: number;
  status_id: number;
  ts: number;
}

interface StatusDataProps {
  junctionStatusData: statusDataModel[];
}
const JunctionStatusDataContext = createContext<StatusDataProps | undefined>(
  undefined
);

export function JunctionStatusDataProvider({
  children,
}: PropsWithChildren<{}>) {
  const [statusData, setStatusData] = useState<statusDataModel[]>([]);

  useEffect(() => {
    socket.on("junctionStatusData", (data: any) => {
      setStatusData((statusData) => [data]);
    });

    return () => {
      socket.off("junctionStatusData");
    };
  }, []);

  return (
    <JunctionStatusDataContext.Provider
      value={{
        junctionStatusData: statusData,
      }}
    >
      {children}
    </JunctionStatusDataContext.Provider>
  );
}

export function useJunctionStatusData() {
  const context = useContext(JunctionStatusDataContext);
  if (context === undefined) {
    return {
      junctionStatusData: [],
    };
    // throw new Error(
    //   "useJunctionStatusData must be used within a JunctionStatusDataContext"
    // );
  }
  return context;
}
