import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Modal, Popconfirm, Spin, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import JunctionService from "../../../../data/junction/JunctionService";
import PoliceButtonMonitoringJakarta from "./multiple-watch/PoliceButtonMonitoringJakarta";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import { useMultipleWatchData } from "../../../../contexts/map/MultipleWatchContext";
import { WatchContainer } from "../../components/custom-styled/container/style";

const divStyle = {
  fontSize: 11.5,
  fontWeight: 600,
  color: "white",
  padding: "5px 5px",
  borderRadius: 4,
  background: "#e6f2ff33",
};
const buttonStyle = {
  fontSize: 12,
  marginTop: 8,
  padding: "0px 20px",
  marginLeft: 5,
};

function PoliceButtonContent() {
  const { t } = useTranslation();
  const { layerList } = useCustomLayerData();
  const { multipleWatchContentData, clearSelectedItems, setWatchStatus } =
    useMultipleWatchData();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [panelDatas, setPanelDatas] = useState<any[]>([]);
  const [activePoliceModeDatas, setActivePoliceModeDatas] = useState<any>([]);
  const [showPoliceModesListModal, setShowPoliceModesListModal] =
    useState(false);
  const [selectedPoliceModes, setSelectedPoliceModes] = useState<number[]>([]);
  const [loadingCancelPoliceModes, setLoadingCancelPoliceModes] =
    useState(false);

  useEffect(() => {
    setPanelDatas(multipleWatchContentData);
  }, [multipleWatchContentData]);

  const closeModal = () => {
    clearSelectedItems?.();
    setWatchStatus?.();
    setIsModalVisible(false);
    setShow(false);
  };
  const handleShowMultipleModuleMonitoring = () => {
    setIsModalVisible(true);
  };

  const fetchVipPassActiveJunctions = useCallback(() => {
    JunctionService.getVipPassActiveJunctions()
      .then((response) => {
        setActivePoliceModeDatas(response.data);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    fetchVipPassActiveJunctions();
  }, [showPoliceModesListModal]);

  const handleCancelPoliceModes = () => {
    setLoadingCancelPoliceModes(true);

    JunctionService.cancelVipPass(selectedPoliceModes)
      .then((response) => {
        if (response.data === true) {
          message.success(t("police_mode_cancelled_ok"));
          setShowPoliceModesListModal(false);
          setSelectedPoliceModes([]);
        } else {
          message.error(t("police_mode_cancelled_cant"));
          setShowPoliceModesListModal(true);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoadingCancelPoliceModes(false);
      });
  };

  const handleCancelModalPoliceModes = () => {
    setShowPoliceModesListModal(false);
    setSelectedPoliceModes([]);
  };

  return (
    <>
      {layerList.filter((item) => item.isChecked && [1].includes(item.id))
        .length > 0 ? (
        <>
          <WatchContainer>
            {panelDatas && panelDatas.length >= 1 ? (
              <>
                {panelDatas.map((item, index) => {
                  return (
                    <div style={{ fontSize: 11.5 }} key={index}>
                      {item.name}
                    </div>
                  );
                })}
                <Button
                  type="primary"
                  style={buttonStyle}
                  size={"small"}
                  onClick={handleShowMultipleModuleMonitoring}
                >
                  {t("configuration")}
                </Button>
                <Button
                  type="primary"
                  style={buttonStyle}
                  size={"small"}
                  onClick={() => {
                    setWatchStatus();
                    clearSelectedItems();
                    setShow(false);
                  }}
                >
                  {t("clear")}
                </Button>
              </>
            ) : (
              <>
                <div style={divStyle}>
                  <InfoCircleOutlined style={{ marginRight: 2 }} />
                  {t("please_select_for_police_mode")}
                </div>
                {show === false ? (
                  <>
                    <Button
                      type="primary"
                      style={buttonStyle}
                      size={"small"}
                      onClick={() => {
                        setWatchStatus();
                        setShow(true);
                      }}
                    >
                      {t("start_selection")}
                    </Button>
                    <Button
                      type="primary"
                      style={buttonStyle}
                      size={"small"}
                      onClick={() => setShowPoliceModesListModal(true)}
                    >
                      {t("active_police_mode_list")}
                    </Button>
                  </>
                ) : (
                  <Button
                    type="primary"
                    style={buttonStyle}
                    size={"small"}
                    onClick={() => {
                      setWatchStatus?.();
                      clearSelectedItems?.();
                      setShow(false);
                    }}
                  >
                    {t("close")}
                  </Button>
                )}
              </>
            )}
          </WatchContainer>
          <Modal
            title={
              <div
                style={{
                  textAlign: "center",
                  fontSize: 28,
                  fontWeight: "bold",
                }}
              >
                {t("police_mode_config_page")}
              </div>
            }
            visible={isModalVisible}
            destroyOnClose={true}
            onOk={closeModal}
            onCancel={closeModal}
            width="95%"
            footer={null}
          >
            {panelDatas.every((item) => item.watchModuleType === "junction") &&
            panelDatas.length >= 1 ? (
              <PoliceButtonMonitoringJakarta selectedJunctions={panelDatas} />
            ) : null}
          </Modal>

          <Modal
            title={t("active_police_mode_list")}
            visible={showPoliceModesListModal}
            onCancel={() => handleCancelModalPoliceModes()}
            footer={[
              selectedPoliceModes.length > 0 && (
                <Popconfirm
                  title={t("are_you_sure_cancel_police_modes")}
                  onConfirm={handleCancelPoliceModes}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <Button key="cancel" danger>
                    {loadingCancelPoliceModes ? (
                      <Spin size="small" />
                    ) : (
                      t("cancel_police_modes_for_selected_junctions")
                    )}
                  </Button>
                </Popconfirm>
              ),
            ]}
          >
            {activePoliceModeDatas && (
              <Checkbox.Group
                options={activePoliceModeDatas.map((item: any) => ({
                  label: item.name,
                  value: item.junction_id,
                }))}
                value={selectedPoliceModes.map(String)}
                onChange={(values: CheckboxValueType[]) => {
                  const numericValues = values.map(Number);
                  setSelectedPoliceModes(numericValues);
                }}
              />
            )}
          </Modal>
        </>
      ) : null}
    </>
  );
}
export default PoliceButtonContent;
