import { PhaseType } from "../../..";
import columnFieldKeys from "../../../formFieldStructs/planFormPhasesTableFields";

export const checkPhaseMinMaxSeq = (rowData: any, juncPhases: PhaseType[]) => {
  const foundPhase: PhaseType | undefined = juncPhases.find(
    (juncPhase: PhaseType) => {
      return juncPhase.internal_id === rowData.internalId;
    }
  );

  const minSequenceTimeout = foundPhase?.min_sequence_timeout;
  const maxSequenceTimeout = foundPhase?.max_sequence_timeout;

  const phaseMinTime =
    rowData[columnFieldKeys.meanTime] -
    rowData[columnFieldKeys.minus] +
    rowData[columnFieldKeys.extraTime];
  const phaseMaxTime =
    rowData[columnFieldKeys.meanTime] +
    rowData[columnFieldKeys.plus] +
    rowData[columnFieldKeys.extraTime];

  let validates: boolean = true;
  if (foundPhase && minSequenceTimeout && maxSequenceTimeout) {
    if (
      minSequenceTimeout > phaseMinTime ||
      maxSequenceTimeout < phaseMaxTime
    ) {
      validates = false;
    }
  } else {
    validates = false;
  }

  return validates;
};

export const checkPhaseFormMinMaxSeq = (
  rowDatas: any[],
  juncPhases: PhaseType[]
) => {
  let validates: boolean = true;
  let length = rowDatas.length;
  // run this condition if rowDatas has items
  if (length > 0) {
    for (let i = 0; i < length; i++) {
      // if item does not validate, then set validates variable as false and break the loop
      if (!checkPhaseMinMaxSeq(rowDatas[i], juncPhases)) {
        validates = false;
        break;
      }
    }
    // if rowDatas has no items
  } else {
    validates = false;
  }

  return validates;
};
