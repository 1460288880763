import LeftSider from "../../containers/LeftSider";
import { MapContainer } from "react-leaflet";
import withLogging from "./high-order-components/LoggerHOC";
import { CustomLayerControl } from "./layers/CustomLayerControl";
import CustomMapControl from "./controls/CustomMapControl";
import MapEventsControl from "./controls/map-events-control/MapEventsControl";
import { Spin } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProviderChain from "./ProviderChain";
import Flex from "./components/custom-styled/layout/Flex";
import packageJson from "../../../package.json";
import ReleaseNoteModal from "./components/ReleaseNotes";

const appVersion = packageJson.version;
function Map() {
  const [loadingMap, setLoadingMap] = useState<boolean>(true);
  const [leftSiderVisible, setLeftSiderVisible] = useState(false);

  const { t } = useTranslation();

  return (
    <Spin spinning={loadingMap} tip={t("Loading ManGo Modules")} size="large">
      <Flex>
        {leftSiderVisible && <LeftSider collapsed={false} />}
        <MapContainer
          id="newMap"
          style={{ height: "100vh", width: "100%" }}
          key={"newMap"}
          center={
            localStorage.getItem("map_center")
              ? JSON.parse(localStorage.getItem("map_center")!)
              : [38, 39]
          }
          zoom={localStorage.getItem("map_center") ? 13 : 6}
          scrollWheelZoom={true}
          contextmenu={true}
          contextmenuItems={[]}
          whenCreated={(map) => {
            map.on("layeradd", () => {
              setLoadingMap(false);
            });
          }}
          zoomControl={false}
        >
          <ReleaseNoteModal appVersion={appVersion} />
          <ProviderChain>
            <MapEventsControl componentName="MapEventsControl" />
            <CustomMapControl setLeftSiderVisible={setLeftSiderVisible} />
            <CustomLayerControl />
          </ProviderChain>
        </MapContainer>
      </Flex>
    </Spin>
  );
}

export default withLogging(Map);
