import { Ups } from "../../../../data/ups/Ups";
import { GenericMapModalTitle } from "../../components/generic/map-modal/GenericMapModalTitle";
import withLogging from "../../high-order-components/LoggerHOC";

interface UpsModalTitleProps {
  selectedUps: Ups;
}

function UpsModalTitle(props: UpsModalTitleProps) {
  const { selectedUps } = props;

  return (
    <GenericMapModalTitle>
      <span style={{ fontSize: "2vw" }}>{selectedUps.name}</span>
    </GenericMapModalTitle>
  );
}

export default withLogging(UpsModalTitle);
