import { AuthService } from "@icms/ui-components";
import { Avatar, Button, Card, Form, Input, Select, Spin, message } from "antd";
import { HmacSHA1 } from "crypto-js";
import { CYRPTO_KEY } from "@icms/ui-components";
import Meta from "antd/lib/card/Meta";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UserService from "../../../data/user/UserService";

const UserInfo: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(Date.now());

  let user = AuthService.getUser();
  const { t, i18n } = useTranslation();
  const currentValue = {
    user_name: user.user_info.user_name,
    first_name: user.user_info.first_name,
    last_name: user.user_info.last_name,
    email: user.user_info.email,
    password: "",
    password_confirm: "",
    language: user.user_info.language,
  };
  const [formContent, setFormContent] = useState(currentValue);
  const capitalizeFirstLetter = (val: string) => {
    return val.charAt(0).toUpperCase();
  };
  const putUser = (i: any) => {
    setLoading(true);
    const encryptedPassword =
      formContent.password !== ""
        ? HmacSHA1(formContent.password, CYRPTO_KEY).toString()
        : "";
    const req = { ...formContent, password: encryptedPassword };
    UserService.updateUser({ ...req, id_user: user.user_info.id_user })
      .then((res) => {
        message.success(t("update_successful"));
        AuthService.setUser({
          ...user,
          user_info: { ...user.user_info, ...formContent },
        });
        i18n.changeLanguage(req.language);
        setLoading(false);
      })
      .catch((err) => {
        setFormContent(currentValue);
        setKey(Date.now());
        console.log(err);
        setLoading(false);
      });
  };

  const validatePassword = (rule: any, value: string) => {
    if (value) {
      // Minimum 8 karakter kontrolü
      if (value?.length < 8) {
        return Promise.reject(new Error(t("atLeastEightChars")));
      }
      // Büyük harf kontrolü
      if (!/[A-Z]/.test(value)) {
        return Promise.reject(new Error(t("atLeastUpperCase")));
      }
      // Küçük harf kontrolü
      if (!/[a-z]/.test(value)) {
        return Promise.reject(new Error(t("atLeastLowerCase")));
      }
      // Sayı kontrolü
      if (!/\d/.test(value)) {
        return Promise.reject(new Error(t("atLeastNumber")));
      }
      // Özel karakter kontrolü
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
        return Promise.reject(new Error(t("atLeastChar")));
      }
    }
    return Promise.resolve();
  };

  return (
    <Spin spinning={loading}>
      <div style={{ marginBottom: "-20px" }}>
        <Card bordered={false}>
          <Meta
            avatar={
              <Avatar
                style={{ marginTop: "-15px", backgroundColor: "#4AAACB" }}
                size={100}
              >
                {capitalizeFirstLetter(user.user_info.first_name) +
                  capitalizeFirstLetter(user.user_info.last_name)}
              </Avatar>
            }
            title={user.user_info.user_name}
            description={
              user.user_info.first_name + " " + user.user_info.last_name
            }
          />
        </Card>
        <Form
          key={key}
          onChange={(i: any) =>
            setFormContent({ ...formContent, [i.target.id]: i.target.value })
          }
          layout="vertical"
          onFinish={putUser}
        >
          <Form.Item
            initialValue={user.user_info.user_name}
            label={<>{t("user_name")}</>}
            name="user_name"
            rules={[{ required: true, message: String(t("notEmpty")) }]}
          >
            <Input style={{ marginTop: "3px" }} />
          </Form.Item>
          <Form.Item
            initialValue={user.user_info.first_name}
            label={<>{t("name")}</>}
            name="first_name"
            rules={[{ required: true, message: String(t("notEmpty")) }]}
          >
            <Input style={{ marginTop: "3px" }} />
          </Form.Item>
          <Form.Item
            initialValue={user.user_info.last_name}
            label={<>{t("last_name")}</>}
            name="last_name"
            rules={[{ required: true, message: String(t("notEmpty")) }]}
          >
            <Input style={{ marginTop: "3px" }} />
          </Form.Item>
          <Form.Item
            initialValue={user.user_info.email}
            label={<>{t("email")}</>}
            name="email"
            rules={[{ required: true, message: String(t("notEmpty")) }]}
          >
            <Input style={{ marginTop: "3px" }} />
          </Form.Item>
          <Form.Item
            initialValue={user.user_info.language}
            label={<>{t("language")}</>}
            name="language"
          >
            <Select
              onChange={(value) =>
                setFormContent({ ...formContent, language: value })
              }
              style={{ marginTop: "3px" }}
            >
              <Select.Option value="en">English</Select.Option>
              <Select.Option value="tr">Türkçe</Select.Option>
              <Select.Option value="id">Bahasa Indonesia</Select.Option>
              <Select.Option value="tk">Türkmenler</Select.Option>
              <Select.Option value="ru">Русский</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            initialValue=""
            label={<>{t("newPassword")}</>}
            name="password"
            rules={[{ validator: validatePassword }]}
          >
            <Input.Password style={{ marginTop: "3px" }} />
          </Form.Item>
          <Form.Item
            initialValue=""
            label={<>{t("confirmPassword")}</>}
            name="password_confirm"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("password") === value) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(t("dontMatch"));
                  }
                },
              }),
            ]}
          >
            <Input.Password style={{ marginTop: "3px" }} />
          </Form.Item>
          <Form.Item>
            <Button
              disabled={
                JSON.stringify(currentValue) === JSON.stringify(formContent)
              }
              htmlType="submit"
              style={{ backgroundColor: "#4AAACB", color: "white" }}
            >
              {t("save")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};
export default UserInfo;
