import { AuthInfo } from "@icms/ui-components";
import { ResponseMessage } from "@icms/ui-components";
import message from "antd/lib/message";
import axios, { AxiosError, AxiosResponse } from "axios";
import ls from "./localStorage";

import { useTranslation } from "react-i18next";



const { REACT_APP_API_URL,REACT_APP_API_URL_DEV,REACT_APP_HOST_TYPE } = process.env;


let jtoken="";
function getToken(){
  try{
      jtoken=(ls.get("user") as AuthInfo).access_token
      return jtoken
    }catch(e){
      return ""
    }
}

const axiosInstance = axios.create({
  baseURL: (REACT_APP_HOST_TYPE==="dev"?REACT_APP_API_URL_DEV:window.location.origin),
  headers: {
    "Content-Type": "application/json",
    "jtoken":getToken()
  },
  timeout: 240000 
});

axiosInstance.interceptors.request.use(
  config => {
    config!.headers!["jtoken"] = getToken();
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  //const { t } = useTranslation();
  if (error.response?.status === 501 || error.response?.status === 500) {
    message.error(ResponseMessage[error.response.data.messageId]);
  }

  if (error.response && error.response.data) {
    if(error.response.status == 401) {
      console.log("Token expire!");
      window.location.href="/login";
    }
    if(error.response.status == 402) {
      message.error("You don't have license!");
      console.log(error.response.data.message)
    }
    if (error.response?.status === 405) {
      message.error(("You don't have permission!"));
      console.log(error.response.data.message)
    }
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});

/*
// response interceptor for handling common errors (e.g. HTTP 500)
axiosInstance.interceptors.response.use(
  (response:AxiosResponse<ListResponseModel<any>>) => {
    if (response.data.result) {
      message.success(ResponseMessage[response.data.messageId]);
      response.data = response.data.result as any;      
    }
    return response;
  },
  (error: AxiosError) => errorHandler(error)
);
*/
export default axiosInstance;

//console.log(REACT_APP_HOST_TYPE)
