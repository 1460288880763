import React, { useState, useEffect } from 'react';
import { Form, Slider, Button, message, Row, Col, Card, Tabs, Tooltip, Modal, Typography, TimePicker } from 'antd';
import { SettingOutlined, InfoCircleOutlined, PlusOutlined, ExclamationCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import VmsService from '../../data/vms/VmsService';
import { Vms } from '../../data/vms/Vms';
import moment from 'moment';
import { t } from 'i18next';

const { TabPane } = Tabs;
const { Text } = Typography;


interface VmsSettingsProps {
  selectedVms: Vms;
}
const VmsSettings: React.FC<VmsSettingsProps> = ({ selectedVms }) => {

  const [formPixelSettings] = Form.useForm();

  const [formLedSettings] = Form.useForm();

  const [formSensorSettings] = Form.useForm();
  const [formSensorSettingsList, setFormSensorSettignsList] = useState([{ key: Date.now(), brightness: null, luminance: null }]);

  const [formLuminance] = Form.useForm();
  const [luminance, setLuminance] = useState(null);

  const [formInstantBrightness] = Form.useForm();
  const [icon, setIcon] = useState<any>(null);


  const [formTimeTransitions] = Form.useForm();
  const [formTimeOffset] = Form.useForm();

  const [currentTransition, setCurrentTransition] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => { // for pixel settings
    const fetchConfig = async (ip: string) => {
      try {
        setLoading(true);
        const response = await VmsService.getPixelBrightness(ip);
        formPixelSettings.setFieldsValue(response.data);
      } catch (error) {
        message.error(t('failed_to_retrieve_config_data'));
      } finally {
        setLoading(false);
      }
    };

    fetchConfig(selectedVms.ip_address);
  }, [formPixelSettings]);

  useEffect(() => { // for led settings
    const fetchConfig = async (ip: string) => {
      try {
        setLoading(true);
        const response = await VmsService.getLedBrightness(ip);
        formLedSettings.setFieldsValue(response.data);
      } catch (error) {
        message.error(t('failed_to_retrieve_config_data'));
      } finally {
        setLoading(false);
      }
    };

    fetchConfig(selectedVms.ip_address);
  }, [formLedSettings]);

  useEffect(() => { // for sensor settings
    const fetchConfig = async (ip: string) => {
      try {
        setLoading(true);
        const response = await VmsService.getSensorBrightness(ip);
        const sensorData = response.data.map((item: any, index: any) => ({
          key: Date.now() + index,
          brightness: item.brightness,
          luminance: item.luminance,
        }));
        setFormSensorSettignsList(sensorData);
        const initialValues: any = {};
        sensorData.forEach((item: any) => {
          initialValues[`brightness_${item.key}`] = item.brightness;
          initialValues[`luminance_${item.key}`] = item.luminance;
        });
        formSensorSettings.setFieldsValue(initialValues);
      } catch (error) {
        message.error(t('failed_to_retrieve_config_data'));
      } finally {
        setLoading(false);
      }

    }
    fetchConfig(selectedVms.ip_address);

  }, [formSensorSettings]);

  useEffect(() => { // for getting sensor luminance
    const fetchConfig = async (ip: string) => {
      try {
        setLoading(true);
        const response = await VmsService.getSensorLuminance(ip);
        const luminanceValue = response.data.luminance;
        formLuminance.setFieldsValue(response.data);
        setLuminance(luminanceValue);
      } catch (error) {
        message.error(t('failed_to_retrieve_config_data'));
      } finally {
        setLoading(false);
      }
    };

    fetchConfig(selectedVms.ip_address);
  }, [formLuminance]);

  useEffect(() => { // for current transition
    const fetchConfig = async (ip: string) => {
      try {
        const response = await VmsService.getCurrentTransition(ip);
        setCurrentTransition(response.data.current_transition);
      } catch (error) {
        message.error(t('failed_to_retrieve_config_data'));
      }
    };

    fetchConfig(selectedVms.ip_address);
  }, [selectedVms]);

  useEffect(() => { // for time transition
    const fetchConfig = async (ip: string) => {
      try {
        setLoading(true);
        const response = await VmsService.getTimeTransitions(ip);
        const data = response.data;
        const formattedData = {
          rise: moment(data.rise, 'HH:mm'),
          rise_tansition: moment(data.rise_tansition, 'HH:mm'),
          set: moment(data.set, 'HH:mm'),
          set_transition: moment(data.set_transition, 'HH:mm'),
        };
        formTimeTransitions.setFieldsValue(formattedData);
      } catch (error) {
        message.error(t('failed_to_retrieve_config_data'));
      } finally {
        setLoading(false);
      }
    };

    fetchConfig(selectedVms.ip_address);
  }, [formTimeTransitions]);

  useEffect(() => { // for time offsets
    const fetchConfig = async (ip: string) => {
      try {
        setLoading(true);
        const response = await VmsService.getTimeOffset(ip);
        formTimeOffset.setFieldsValue(response.data);
      } catch (error) {
        message.error(t('failed_to_retrieve_config_data'));
      } finally {
        setLoading(false);
      }
    };

    fetchConfig(selectedVms.ip_address);
  }, [formTimeOffset]);

  useEffect(() => {
    if (currentTransition === 'day_mode') {
      setIcon(
        <Text style={{ marginLeft: '10px', fontSize: '20px' }}>
          <span role="img" aria-label="day" style={{ marginRight: '5px' }}>☀️</span>
          {t('day_mode')}
        </Text>
      );
    } else if (currentTransition === 'evening_mode') {
      setIcon(
        <Text style={{ marginLeft: '10px', fontSize: '20px' }}>
          <span role="img" aria-label="night" style={{ marginRight: '5px' }}>🌙</span>
          {t('night_mode')}
        </Text>
      );
    }
  }, [currentTransition]);

  const onFinishForPixelSettings = async (values: any) => {
    const ip = selectedVms.ip_address;
    const pixelConfig = { value: "software_based_time" };

    setLoading(true);

    try {
      const handlePixelBrightnessUpdate = async () => {
        try {
          const brightnessResponse = await VmsService.setPixelBrightness(ip, values);
          if (brightnessResponse.data.success) {
            message.success(t('the_settings_are_saved_to_the_device'));
          } else {
            message.error(t('saving_settings_to_device_failed'));
          }
        } catch (error) {
          message.error(t('an_error_occurred_while_saving_settings_to_the_device'));
        }
      };

      await handlePixelBrightnessUpdate();

      const response = await VmsService.setBrightnessController(ip, pixelConfig);

      if (!response.data.success) {
        message.error(t('an_error_occurred_while_adjusting_the_device_brightness_controller'));
        message.info(t('vms_pixel_failure'));
      } else {
        message.success(t('settings_update_applied'));
      }
    } catch (error) {
      message.error(t('error'));
    } finally {
      setLoading(false);
    }
  };
  const onFinishForLedSettings = async (values: any) => {
    const ip = selectedVms.ip_address;
    const ledConfig = { value: "hardware_based_time" };

    setLoading(true);

    try {

      const handleLedBrightnessUpdate = async () => {
        try {
          const brightnessResponse = await VmsService.setLedBrightness(ip, values);
          if (brightnessResponse.data.success) {
            message.success(t('the_settings_are_saved_to_the_device'));
          } else {
            message.error(t('saving_settings_to_device_failed'));
          }
        } catch (error) {
          message.error(t('an_error_occurred_while_saving_settings_to_the_device'));
        }
      };

      await handleLedBrightnessUpdate();

      const response = await VmsService.setBrightnessController(ip, ledConfig);

      if (!response.data.success) {
        message.error(t('an_error_occurred_while_adjusting_the_device_brightness_controller'));
        message.info(t('there_is_no_led_connected'));
      } else {
        message.success(t('settings_update_applied'));
      }
    } catch (error) {
      message.error(t('error'));
    } finally {
      setLoading(false);
    }
  };

  const onFinishForInstantSettings = async (values: any) => {
    const ip = selectedVms.ip_address;
    const ledConfig = { value: "hardware_based_time" };

    setLoading(true);

    try {
      const handleInstantBrightnessUpdate = async () => {
        try {
          const instantBrightnessResponse = await VmsService.setInstantBrightness(ip, values);
          if (instantBrightnessResponse.data.success) {
            message.success(t('test_settings_have_been_sent_to_the_device'));
          } else {
            message.error(t('test_failed'));
          }
        } catch (error) {
          message.error(t('an_error_occurred_during_testing'));
        }
      };
      await handleInstantBrightnessUpdate();
      const response = await VmsService.setBrightnessController(ip, ledConfig);

      if (!response.data.success) {
        message.error(t('an_error_occurred_while_adjusting_the_device_brightness_controller'));
        message.info(t('there_is_no_led_connected'));
      } else {
        message.success(t('the_test_is_successful'));
      }
    } catch (error) {
      message.error(t('error'));
    } finally {
      setLoading(false);
    }
  };

  const onFinishForTimeOffsets = async (values: any) => {
    const ip = selectedVms.ip_address;

    setLoading(true);
    try {
      const timeOffsetResponse = await VmsService.setTimeOffset(ip, values);
      if (timeOffsetResponse.data.success) {
        message.success(t('the_settings_are_saved_to_the_device'));
      } else {
        message.error(t('saving_settings_to_device_failed'));
      }
    } catch (error) {
      message.error(t('an_error_occurred_while_saving_settings_to_the_device'));
    } finally {
      setLoading(false);
    }
  };

  //for sensor settings --start
  const addFormSensorSettingsField = () => {
    setFormSensorSettignsList([...formSensorSettingsList, { key: Date.now(), brightness: null, luminance: null }]);
  };

  const removeFormSensorSettingsField = (key: number) => {
    setFormSensorSettignsList(formSensorSettingsList.filter(item => item.key !== key));
  };

  const onFinishForSensorSettings = async () => {
    const ip = selectedVms.ip_address;
    const sensorConfig = { value: "sensor_based" };

    setLoading(true);

    try {
      const handleSensorBrightnessUpdate = async () => {
        try {
          formSensorSettings
            .validateFields()
            .then(async values => {
              const sensorData = formSensorSettingsList.map(item => ({
                luminance: values[`luminance_${item.key}`],
                brightness: values[`brightness_${item.key}`]
              }));
              const brightnessResponse = await VmsService.setSensorBrightness(ip, sensorData);
              if (brightnessResponse.data.success) {
                message.success(t('the_settings_are_saved_to_the_device'));
              } else {
                message.error(t('saving_settings_to_device_failed'));
              }
            })
            .catch(info => {
              message.error(t('please_fill_in_all_fields'));
            });
        } catch (error) {
          message.error(t('an_error_occurred_while_saving_settings_to_the_device'));
        }
      };

      await handleSensorBrightnessUpdate();

      const response = await VmsService.setBrightnessController(ip, sensorConfig);

      if (!response.data.success) {
        message.error(t('an_error_occurred_while_adjusting_the_device_brightness_controller'));
        message.info(t('there_is_no_sensor_connected'));
      } else {
        message.success(t('settings_update_applied'));
      }
    } catch (error) {
      message.error(t('error'));
    } finally {
      setLoading(false);
    }
  };
  //for sensor settings --end

  return (
    <Tabs defaultActiveKey="1" centered tabBarExtraContent={icon}>
      <TabPane //pixel settings
        tab={
          <span>
            {t('pixel_settings')}&nbsp;
            <Tooltip title="info">
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        }
        key="1"
      >
        <Card
          bordered={false}
          style={{
            maxWidth: 800,
            margin: '20px auto',
            borderRadius: '8px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
            padding: '20px',
          }}
          actions={[
            <Button
              type="primary"
              htmlType="submit"
              form="vms-pixel-settings-form"
              loading={loading}
              icon={<SettingOutlined />}
            >
              {t('save')}
            </Button>
          ]}
        >
          <Form
            form={formPixelSettings}
            id="vms-pixel-settings-form"
            name="vms-pixel-settings"
            onFinish={onFinishForPixelSettings}
            layout="vertical"
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3>{t('daytime_brightness_transition_value')}</h3>
                <Form.Item
                  name="day_transition_value"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message:t("this_field_is_required")! }]}
                >
                  <Slider
                    min={0}
                    max={100}
                    marks={{ 0: '0', 100: '100' }}
                    tipFormatter={value => `${value}`}
                    tooltipVisible
                    getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                    tooltipPlacement='right'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3>{t('intraday_value')}</h3>
                <Form.Item
                  name="day_value"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message:t("this_field_is_required")! }]}
                >
                  <Slider
                    min={0}
                    max={100}
                    marks={{ 0: '0', 100: '100' }}
                    tipFormatter={value => `${value}`}
                    tooltipVisible
                    getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                    tooltipPlacement='right'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3>{t('evening_brightness_transition_value')}</h3>
                <Form.Item
                  name="night_transition_value"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message:t("this_field_is_required")!}]}
                >
                  <Slider
                    min={0}
                    max={100}
                    marks={{ 0: '0', 100: '100' }}
                    tipFormatter={value => `${value}`}
                    tooltipVisible
                    getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                    tooltipPlacement='right'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3>{t('night_value')}</h3>
                <Form.Item
                  name="night_value"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message:t("this_field_is_required")! }]}
                >
                  <Slider
                    min={0}
                    max={100}
                    marks={{ 0: '0', 100: '100' }}
                    tipFormatter={value => `${value}`}
                    tooltipVisible
                    getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                    tooltipPlacement='right'
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </TabPane>
      <TabPane //led settings
        tab={
          <span>
            {t('led_settings')}&nbsp;
            <Tooltip title="info">
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        }
        key="2"
      >
        <Card
          bordered={false}
          style={{
            maxWidth: 800,
            margin: '20px auto',
            borderRadius: '8px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
            padding: '20px',
          }}
          actions={[
            <Button
              type="primary"
              htmlType="submit"
              form="vms-instant-settings-form"
              loading={loading}
              icon={<SettingOutlined />}
            >
              {t('instant_test')}
            </Button>
          ]}
        >
          <Form
            form={formInstantBrightness}
            id="vms-instant-settings-form"
            name="vms-instant-settings"
            onFinish={onFinishForInstantSettings}
            layout="vertical"
          >
            <h3>{t('test_brightness_value')}</h3>
            <Form.Item
              name="value"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message:t("this_field_is_required")! }]}
            >
              <Slider
                min={0}
                max={100}
                marks={{ 0: '0', 100: '100' }}
                tipFormatter={value => `${value}`}
                tooltipVisible
                getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                tooltipPlacement='right'
              />
            </Form.Item>


          </Form>

        </Card>
        <Card
          bordered={false}
          style={{
            maxWidth: 800,
            margin: '20px auto',
            borderRadius: '8px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
            padding: '20px',
          }}
          actions={[
            <Button
              type="primary"
              htmlType="submit"
              form="vms-led-settings-form"
              loading={loading}
              icon={<SettingOutlined />}
            >
              {t('save')}
            </Button>
          ]}
        >
          <Form
            form={formLedSettings}
            id="vms-led-settings-form"
            name="vms-led-settings"
            onFinish={onFinishForLedSettings}
            layout="vertical"
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3>{t('daytime_brightness_transition_value')}</h3>
                <Form.Item
                  name="day_transition_value"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message:t("this_field_is_required")! }]}
                >
                  <Slider
                    min={0}
                    max={100}
                    marks={{ 0: '0', 100: '100' }}
                    tipFormatter={value => `${value}`}
                    tooltipVisible
                    getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                    tooltipPlacement='right'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3>{t('intraday_value')}</h3>
                <Form.Item
                  name="day_value"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message:t("this_field_is_required")! }]}
                >
                  <Slider
                    min={0}
                    max={100}
                    marks={{ 0: '0', 100: '100' }}
                    tipFormatter={value => `${value}`}
                    tooltipVisible
                    getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                    tooltipPlacement='right'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3>{t('evening_brightness_transition_value')}</h3>
                <Form.Item
                  name="night_transition_value"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message:t("this_field_is_required")! }]}
                >
                  <Slider
                    min={0}
                    max={100}
                    marks={{ 0: '0', 100: '100' }}
                    tipFormatter={value => `${value}`}
                    tooltipVisible
                    getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                    tooltipPlacement='right'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <h3>{t('night_value')}</h3>
                <Form.Item
                  name="night_value"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: true, message:t("this_field_is_required")! }]}
                >
                  <Slider
                    min={0}
                    max={100}
                    marks={{ 0: '0', 100: '100' }}
                    tipFormatter={value => `${value}`}
                    tooltipVisible
                    getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                    tooltipPlacement='right'
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </TabPane>
      <TabPane // sensor settings
        tab={
          <span>
            {t('sensor_settings')}&nbsp;
            <Tooltip title="info">
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        }
        key="3"
      >
        <Card
          title={t('instant_lux_value')}
          bordered={false}
          style={{
            maxWidth: 800,
            margin: '20px auto',
            borderRadius: '8px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
            padding: '20px',
          }}
        >
          <Form form={formLuminance}
            id="vms-getting-luminance-form"
            name="vms-getting-luminance"
            layout="vertical"
          >

            <Form.Item
              name="luminance"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              {luminance !== null ? (
                luminance === -1 ? (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#ff4d4f',
                    backgroundColor: '#fff1f0',
                    border: '1px solid #ffa39e',
                    borderRadius: '4px',
                    padding: '10px',
                  }}>
                    <ExclamationCircleOutlined style={{ marginRight: '10px' }} />
                    <span>{t('no_sensor_connected_data_could_not_be_received')}</span>
                  </div>
                ) : (
                  <Slider
                    min={0}
                    max={60000}
                    value={luminance}
                    disabled
                    tooltipVisible
                    getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                    tooltipPlacement='right'
                  />
                )
              ) : (
                <div>Yükleniyor...</div>
              )}
            </Form.Item>
          </Form>
        </Card>
        <Card
          title={t('vms_sensor_setting_records')}
          bordered={false}
          style={{
            maxWidth: 800,
            margin: '20px auto',
            borderRadius: '8px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
            padding: '20px',
          }}
          actions={[
            <Button type="primary" onClick={onFinishForSensorSettings} icon={<SettingOutlined />}>
              {t('save')}
            </Button>]}
        >
          <Form form={formSensorSettings}>
            {formSensorSettingsList.map(item => (
              <Form.Item key={item.key} style={{ marginBottom: 20 }}>
                <Row gutter={16} align="middle">
                  <Col span={10}>
                    <Form.Item
                      name={`luminance_${item.key}`}
                      label={t('luminance')}
                      rules={[{ required: true, message:t("this_field_is_required")! }]}
                    >
                      <Slider min={0} max={60000} tooltipVisible getTooltipPopupContainer={(trigger:any) => trigger.parentNode} tooltipPlacement='right'/>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      name={`brightness_${item.key}`}
                      label={t('brightness')}
                      rules={[{ required: true, message:t("this_field_is_required")! }]}
                    >
                      <Slider min={0} max={100} tooltipVisible getTooltipPopupContainer={(trigger:any) => trigger.parentNode} tooltipPlacement='right'/>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Button
                      type="link"
                      icon={<DeleteOutlined style={{ color: "red" }} />}
                      onClick={() => removeFormSensorSettingsField(item.key)}
                    />
                  </Col>
                </Row>
              </Form.Item>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={addFormSensorSettingsField} style={{ width: '100%' }}>
                <PlusOutlined /> {t('add')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </TabPane>
      <TabPane // time settings
        tab={
          <span>
            {t('time_settings')}&nbsp;
            <Tooltip title="info">
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        }
        key="4"
      >
        <Card
          bordered={false}
          style={{
            maxWidth: 800,
            margin: '20px auto',
            borderRadius: '8px',
            backgroundColor: '#fff',
            boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
            padding: '20px',
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card
                bordered={false}
                style={{
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  padding: '20px',
                }}
              >
                <Form
                  form={formTimeTransitions}
                  id="vms-time-transitions-form"
                  name="vms-time-transitions"
                  layout="vertical"
                >
                  <Form.Item
                    label={t('rise_time')}
                    name="rise"
                  >
                    <TimePicker format="HH:mm" disabled />
                  </Form.Item>
                  <Form.Item
                    label={t('rise_transition_time')}
                    name="rise_tansition"
                  >
                    <TimePicker format="HH:mm" disabled />
                  </Form.Item>
                  <Form.Item
                    label={t('set_time')}
                    name="set"
                  >
                    <TimePicker format="HH:mm" disabled />
                  </Form.Item>
                  <Form.Item
                    label={t('set_transition_time')}
                    name="set_transition"
                  >
                    <TimePicker format="HH:mm" disabled />
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                bordered={false}
                style={{
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
                  padding: '20px',
                }}
              >
                <Form
                  form={formTimeOffset}
                  id="vms-time-offset-form"
                  name="vms-time-offset"
                  layout="vertical"
                  onFinish={onFinishForTimeOffsets}
                >
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <h3>{t('rise_offset')}</h3>
                      <Form.Item
                        name="rise_offset"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message:t("this_field_is_required")! }]}
                      >
                        <Slider
                          min={0}
                          max={300}
                          marks={{ 0: '0', 300: '300' }}
                          tipFormatter={value => `${value}`}
                          tooltipVisible
                          getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                          tooltipPlacement='right'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <h3>{t('set_offset')}</h3>
                      <Form.Item
                        name="set_offset"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message:t("this_field_is_required")! }]}
                      >
                        <Slider
                          min={0}
                          max={300}
                          marks={{ 0: '0', 300: '300' }}
                          tipFormatter={value => `${value}`}
                          tooltipVisible
                          getTooltipPopupContainer={(trigger:any) => trigger.parentNode}
                          tooltipPlacement='right'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      form="vms-time-offset-form"
                      loading={loading}
                      icon={<SettingOutlined />}
                    >
                      {t('save')}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Card>
      </TabPane>

    </Tabs>
  )
};



export default VmsSettings;