import { Dropdown, Menu } from "antd";
import { useEffect, useState } from "react";
import StyledButton from "../../components/custom-styled/button/StyledButton";
import { FilterOutlined } from "@ant-design/icons";
import { useInventoryData } from "../../../../contexts/inventory/InventoryDataContext";
import { t } from "i18next";

function InventoryControl() {
  const { inventoryTypes, setInventoryType } = useInventoryData();
  const [menu, setMenu] = useState<JSX.Element>();

  const getInventoryType = async () => {
    let element = (
      <Menu
        style={{
          background: "#1F2933",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        {inventoryTypes.map((item, idx) => {
          return (
            <Menu.Item
              className="menu-item-hover"
              style={{
                color: "#E0E6ED",
                fontSize: "14px",
                padding: "10px 15px",
                transition: "background-color 0.3s ease",
              }}
              key={idx}
              onClick={() => setInventoryType(item)}
            >
              {item.type_name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    setMenu(element);
  };

  useEffect(() => {
    getInventoryType();
  }, [inventoryTypes]);

  return (
    <>
      {menu && (
        <Dropdown
          overlay={menu}
          align={{ offset: [181, -27] }}
          trigger={["click"]}
        >
          <StyledButton
            icon={<FilterOutlined />}
            backgroundColor={"#2F4050"}
            borderColor={"#2F4050"}
            style={{
              color: "white",
              marginLeft: 0,
              paddingLeft: 7,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 8,
            }}
          >
            <p
              style={{
                marginBottom: 0,
                color: "#fff",
                fontSize: "13px",
              }}
            >
              {t("filter_inventory")}
            </p>
          </StyledButton>
        </Dropdown>
      )}
    </>
  );
}

export default InventoryControl;
