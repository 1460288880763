import { Barrier } from "../../data/barrier/Barrier";
import { Button, Card, Col, Row } from "antd";
import { t } from "i18next";
import BarrierService from "../../data/barrier/BarrierService";
import { useBarrierData } from "../../contexts/barrier/BarrierDataContext";
import { useEffect, useState } from "react";

interface Props {
  selectedBarrier: Barrier;
}

const gutter = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};
const streamStyle = { height: "100%", width: "100%" };
function BarrierControl(props: Props) {
  const { updateAllBarier, barrierList } = useBarrierData();
  const { selectedBarrier } = props;
  const [barrier, setBarrier] = useState<Barrier>(selectedBarrier);

  const closeBarrier = async () => {
    let res = await BarrierService.requestBarrier({
      id: barrier.id,
      status: false,
      requestUrl: barrier.close_request_url,
    });
    if (res.data) {
      updateAllBarier();
    }
  };

  const openBarrier = async () => {
    let res = await BarrierService.requestBarrier({
      id: barrier.id,
      status: true,
      requestUrl: barrier.open_request_url,
    });
    if (res.data) {
      updateAllBarier();
    }
  };

  useEffect(() => {
    let updatedBarrier = barrierList.find(item => item.id === barrier.id);
    if (updatedBarrier) {
        setBarrier(updatedBarrier);
    }
  }, [barrierList])
  

  return (
    <>
      <Row gutter={gutter}>
        <Col span={24}>
          <Card bodyStyle={{ padding: "5px", height: "50vh" }}>
            {barrier.proxy ? (
              <img
                src={
                  window.location.protocol +
                  "//" +
                  window.location.hostname +
                  ":"+window.location.port+
                  "/camerastream/barrier" +
                  barrier.id
                }
                style={streamStyle}
                className="streamloading"
              ></img>
            ) : (
              <img src={barrier.stream_url} style={streamStyle}></img>
            )}
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "10px" }}>
        <Col span={8}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <p style={{ margin: 0 }}>{t("status")}:</p>
            <div
              style={{
                border: `3px solid ${
                    barrier.status ? "#68F973" : "#F97768"
                }`,
                backgroundColor: "#ffffff",
                width: "34px",
                height: "34px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 0px 7px 3px rgba(0, 0, 0, 0.4)",
                marginLeft: "5px",
              }}
            >
              {selectedBarrier.status ? (
                <img
                  src="/static/barrier/barrier-open.png"
                  style={{ width: "16px", height: "16px" }}
                />
              ) : (
                <img
                  src="/static/barrier/barrier-closed.png"
                  style={{ width: "16px", height: "16px" }}
                />
              )}
            </div>
          </div>
        </Col>
        <Col span={8} offset={8}>
          {barrier.status ? (
            <Button
              style={{
                color: "white",
                backgroundColor: "#F97768",
                width: "100%",
              }}
              onClick={closeBarrier}
            >
              {t("close_barrier")}
            </Button>
          ) : (
            <Button
              style={{
                color: "white",
                backgroundColor: "#68F973",
                width: "100%",
              }}
              onClick={openBarrier}
            >
              {t("open_barrier")}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default BarrierControl;
