import { FormProps } from "../common/FormProps";

export interface JunctionGroup {
  id: number;
  name: string;
  latitude: number;
  longitude: number;
  type_id: JunctionGroupEnum;
  angle: number;
  junction_id: number;
  ags_value: number;
  ags_percent: number;
  ags: boolean;
  internal_id:number;
}


export const JunctionGroupFormProps: { [key: string]: FormProps } = {
  name: { rules: [{ required: true , message:"Zorunlu alan"}] },
  internal_id: { rules: [{ required: true , message:"Zorunlu alan"}] },
  // ags_value: { rules: [{ required: true , message:"Zorunlu alan"}] },
  // ags_percent: { rules: [{ required: true , message:"Zorunlu alan"}] },
  longitude: { rules: [{ required: true , message:"Zorunlu alan"}] },
  latitude: { rules: [{ required: true , message:"Zorunlu alan"}] },
};


type iconExpression = {
  url: string;
  type?: JunctionGroupEnum;
  name: string;
  title?: string;
  base64?: string;
  className: string;
};

export enum JunctionGroupEnum {
  Straight = 1,
  Right,
  Left,
  StraightRight,
  StraightLeft,
  Tram,
  Pedestrian,
  StraightLeftRight,
  Flash,
}
//in this area name is used for type id
const markerIcons: { [key in JunctionGroupEnum]: iconExpression } = {
  [JunctionGroupEnum.Straight]: {
    url: "g1.png",
    base64: '/9j/4AAQSkZJRgABAQIAAAAAAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAyADIDASIAAhEBAxEB/8QAGgABAQEBAAMAAAAAAAAAAAAAAAcIAwUGCf/EACoQAAEDAgYBAwQDAAAAAAAAAAABAgMEBQYIETd1s0EHEiETFCLBUWFx/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AMqAADzWDsM3TF+IaSy2KnWetqHaJ4axvl7l8NRPlV/Z7T6x+ld29M7vDDWSfe22pai09eyNWNe7T8mKmq+1yL41XVNF/lEvGT264SjbVWazUtY/Eb6Vauurp42o1Wo9rfps0VVRqK9P9XVV8IlPzB3fDNq9PJWY0oaittddO2kRtM1qyRyK17myNVVTRU9i/P6VQPnqDtWtgZVzNo5HyUyPVI3yN9rnN1+FVNV0XT+ziAAAAAAaCyTbnXfh5O+Eq2dTaq3cxF0zEpyTbnXfh5O+Eq2dTaq3cxF0zAYnAAAAAAABoLJNudd+Hk74SrZ1NqrdzEXTMSnJNudd+Hk74SrZ1NqrdzEXTMBicAAAAAAAGgsk25134eTvhKtnU2qt3MRdMwAGJwAAAAH/2Q==',
    name: "Straight",
    title: "Düz Geliş",
    className: "g1",
  },
  [JunctionGroupEnum.Right]: {
    url: "g2.png",
    base64: '/9j/4AAQSkZJRgABAQIAAAAAAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAyADIDASIAAhEBAxEB/8QAGwABAQACAwEAAAAAAAAAAAAAAAgGBwEDBAn/xAAtEAABAwMCAwcEAwAAAAAAAAABAAIDBAURBgghNkEHEjF0dbKzEyIyUSNSYf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCVERd1JTT1lXDS0cMk9TO9sUUUTS58j3HAa0DiSSQAAg6VkGjtI3fWE9yp7BAKmroaN1c6nB++VjXsa4MHV335xwyAcZOAaz7G9ullsVup7jrikiul8eO+aWUh9NTZH4lvhI7xyTlv6HDvHetBbqC3sjit9HS0zY4xGxkMbWd1gwA0ADgOA4f4g+XDmlri1wIcDgg9FwqC3gWfSNu1hDPY5vpakqv5LnRwtBiAIy2V39JHdWj8hhxAJy+fUBERAW8doGnorx2pGvqqdk0NqpnVDe/4MlJDWOx1Iy4j9EA+IC0cqV2P806m8lH70G6NymvLjoLs+bVWTuNuNfUijjmceMALHuMgHUgNwM+BcDxxgyH2c9p100ffrxfZJJ7jd6m2PoaeaqlMn03ukjIe4uOSGhjsD946ZVD73ORrB6kfieo3Qei41tTcq6ora+eSoq6h5kllkOXPcTkkledEQEREBUrsf5p1N5KP3qalSux/mnU3ko/egzPe5yNYPUj8T1G6sje5yNYPUj8T1G6AiIgIiICpXY/zTqbyUfvREGZb3eR7B6ifieo4REBERAREQf/Z',
    name: "Right",
    title: "Sağa Dönüş",
    className: "g2",
  },
  [JunctionGroupEnum.Left]: {
    url: "g3.png",
    base64: '/9j/4AAQSkZJRgABAQIAAgACAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAyADIDASIAAhEBAxEB/8QAGQABAQEBAQEAAAAAAAAAAAAAAAcIBgkF/8QAMhAAAQMDAQUFBwUBAAAAAAAAAQACAwQFEQYHCCE3sRIxUXR1ExQiMkFxkRVCUmFigf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDKiIu52K2zTN32hWyj1rVmmtT3cAfhZNJkdmN7v2tJ4E/8yM5AfNqdE3ek0FDq6sh93tdRWNo6bt8HTkse4vaP4jsYz9SeHcVzC9SX26gdRRUklHTGkiAbHC6JvYYAMABuMDA4Kd7QtiGjdY0Ug/TIbVcSCY62hjEbg7/TR8Lx9xnwIQefSLpdoejbnoTVNVY7ywCaLD45W/JNGfle3+jg/YgjvC5pAREQEREFGvG1a8XrZjQ6Uucs0lRb62Oopa5shD/ZNjkb7N57zgubg+Awe4Kz7ou0y8XS81Wk79WTV0Xu5qKOadxe+MtIDmdo8SCDkZ7sHxWUla90DnHT+Sn6BBWt9exwVGkLNewxoqqSqNOX/UxvaTj8tH5PisdLb2+Tyoi9Qi6OWIUBERAREQFa90DnHT+Sn6BRRWvdA5yU/kp+gQXPfJ5UReoRdHLEK29vk8qIvUIujliFAREQEREBWvdA5yU/kp+gREFz3yeVEXqEXRyxCiICIiD/2Q==',
    name: "Left",
    title: "Sola Dönüş",
    className: "g3",
  },
  [JunctionGroupEnum.StraightRight]: {
    url: "g4.png",
    base64: '/9j/4AAQSkZJRgABAQIAAAAAAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAyADIDASIAAhEBAxEB/8QAGgABAAMBAQEAAAAAAAAAAAAAAAYICQcCA//EACwQAAEDAwMBBwQDAAAAAAAAAAECAwQABQYHESESCCIxMjd1s0FRdMETFGH/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8AqpXtlpx95DTDa3HXFBKEIG6lE8AADxNeK7r2Yrxg9hyWA7emp8zKJ0pEOEBHSWIpcWEBXUVb9R35O3A8PE0EVzrRfK8NxG25BcoyVxZCN5TbXK4Kye6lwfYjbvDgK3Sdu6Vc0rTrNbrbrJid1uN8YMi1x46lSWQ2HP5G/Ap6TwdwfA1nXqEMWVkTz2DLnps73fTHmoAXHJPKArqPUkfQnnbg7kbkIzSlKBSlKBUr0m9U8N96hfOiopUr0m9U8N96hfOigvrrRBlXLSzJoVvYcky5ERTbTTaepS1EgAAVVnAOznmz16tk68wrfDgNOsyHWpb4UpxHUCpHQkK72242VsKu1XLr9rphtpzGPjKH5dwuLkhMVaoTaVtMuFQT0qWpQBIJ56erbYg8jagrf2idFH8HmO33HW1PYu+vlA3K4KyfIr7oJ8qvp5TzsVcMq7Paa1fgYxZpeK2n+vOvs5lTUhK0hxuI0obd8HgrUDwk/Tk8FIVSagUpSgVK9JvVPDfeoXzoqKVK9JvVPDfeoXzooL+at3V+yaZ5NcYnEhiC6UHcgpJG24I5BG+9ZwW+Y/b50eZDcLcmO4l1pYG/SpJ3B5/0Vohrz6O5b+Av9VnPQfaXJfmSnpMt5x+Q8suOOuKKlLUTuSSfEk18aUoFKUoFSvSb1Tw33qF86KUoL268+juW/gL/AFWc9KUClKUClKUH/9k=',
    name: "StraightRight",
    title: "Düz Geliş Sağa Dönüş",
    className: "g4",
  },
  [JunctionGroupEnum.StraightLeft]: {
    url: "g5.png",
    base64: '/9j/4AAQSkZJRgABAQIAAAAAAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAyADIDASIAAhEBAxEB/8QAGQABAQEBAQEAAAAAAAAAAAAAAAgGBwUE/8QALxAAAQMCAwUHBAMAAAAAAAAAAQIDBAAFBhEhBwgSQbMTFDE3UWF1MjZCdCJScf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCVKUpQK22yrZveto9+7hZ0BqK1kqXNdB7KOn3/ALKPgEjU+wBIyVtbiuz46Lg+5HiKWA66232ikJ5kJzGZ9sxV87u94wdPwW5BwBDmRrfb3A08qWhKXXnSkEuLIJ4lH10A0AAAAAQti7DV0wlf5VnvsVUabHVkQfpWnkpJ5pPI141WVvX3jA7zTOHsTtT2b8mKJkCdGjpcDQUpaeFRKgSklBzH+Eaio1oFKUoFbfZPs4u+0jESbda8mIjWSpk1aSUR0euX5KOuSdMzzAzIxFULut7XoOEX1YXxEI8W0zX+0an8IT2LpAGTquaDkBxH6ef8dUh7u1nduujsyGvADEE22NEbj9g6/wAMh1YKip1RKQkk5jmPYAACt7uoYTveDrLiW3Yjt7sKT35KkheqXEhOXElQ0IzB1FarHO2nCuCMWR7DiDv7TrzSXjKbYDjKEKzyJyVx/jySfGuiQJka4QmJkCQ1JivoDjTzSgpC0nUEEaEUEZb6/mnavhWuu/U/1QG+v5p2r4Vrrv1P9ApSlApSlB9tyuk65piC4SXJHdGExmS4cyhpJJSkH0GZy9Bp4VYu5bdX5mzq5QHtWoE4hokk5BaQoj2GeZ09TUW1Ye5B9oYk/fR0xQc831/NO1fCtdd+p/qgN9fzTtXwrXXfqf6BSlKBSlKBVh7kH2hiT99HTFKUHPN9fzTtXwrXXfqf6UoFKUoP/9k=',
    name: "StraightLeft",
    title: "Düz Geliş Sola Dönüş",
    className: "g5",
  },
  [JunctionGroupEnum.Tram]: {
    url: "g6.png",
    base64: '/9j/4AAQSkZJRgABAQIAAAAAAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAyADIDASIAAhEBAxEB/8QAGwABAAIDAQEAAAAAAAAAAAAAAAcIAwQGBQn/xAAwEAACAQQCAQICCQUBAAAAAAABAgMABAURBhIhBxMxQQgUFiJCUVWU0hUjMmGB1P/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwCqlKV2PpHbWM/OLafL2H9Qx1hb3WRntiNrIILeSUK3y6lkUEHwd6PxoPH45hIcxJItzm8ViEVkQSX7yAOzb0AI0c6GjtiAo8bI2N+xzLgcvFGuIL/O4eXJW6o8uPj+sJOqsQAdSxIN+VPXfbqe2tbNXos/T+4gs4ITyrJoY41TrBj8cka6GtIptT1X8hs6GvJrO3BrhjtuW5on8zZ43/yUHzfpU5/SgwVpjMnZy28Pe+gvLmxvb5beOH60fbguI2dYlVPc63RUsAC3Tfy8QZQKUpQKlX0AsZ7u85oYgBE3G7y2aVz1RHlConY/Jdnyx8AAk6AJqKqn36L8uQxOI5vm8a8KzRQ2trEHUMXlkmAVNEgL2/x7seqkhm8A0FvvtXx79fxP7yP+VPtXx39fxP7yP+VaEEWZzHHsNd4/K2NpPLbJLO/1BpElLKpBVWZWUfHwRvz5A1WbFYvPwX8cmTzdld2q77wx44RFvB197udedH4fKgrh9J9BfcVzV7j5oLy0PJILkzW8gkRUbHxRDbDx27xN934gFSfDDdXKt968ZTI8h4Fz6wlkgeDDX1tLGUQIPb96VD5327DSqVYDZUupZHXrUGgUpSgV0PDOZZ7hd9Necbv2s5p4zFL/AG1kWRfyZXBU/wDRXPUoJ6416w+peYxGWyE/NrHHQWTQwo1zYQdZZpSxVNiI6+5HM29fgA/FWzJ6repQ4/lsjb8/xN5Njlime2trKFiYWkEbPsxADq7wjXz7k/hrgbHkvDbTi2Jw+W49e5p7b3Ll54Mk1ooml69x0MbbIEca7B0egOhs1t4bmPAMdfrPa8IyUDvHJbtI+cLqI5UaNwV9nZBR2GgQfPgig8bmnqrzLmmMXHcjzLXVkJBL7SQRRBmHwLdFG9f7riKzXkcUV3PHbTe/AjsscvUr7ig+G0fI2POqw0ClKUClKUClKUClKUClKUH/2Q==',
    name: "Tram",
    title: "Tramvay",
    className: "g6",
  },
  [JunctionGroupEnum.Pedestrian]: {
    url: "g7.png",
    base64: '/9j/4AAQSkZJRgABAQIAAAAAAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCABCADIDASIAAhEBAxEB/8QAGgABAQEBAQEBAAAAAAAAAAAAAAgHBgEECf/EADsQAAADBAYHAg4DAQAAAAAAAAADBgECBAUHERITGNMIFBYxQVWUIlYVFyEyUlRmkZWWpKXS4yMkM3H/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8AlQWDQG7Ra2ieRbVMRLZz/Pf+EtV1j/cyzbt9rzbNVfCrgI+GwIHR+VK3ScCoZTMJITBRl5duRJxrpjLBjzja2OlvM3ut4t8lQCobFCXo0b+6CCxQn6NG/ughP2FNb81TfUH5IYU1vzVN9QfkgKBs0JejRv7oEfn8KAwprfmqb6g/JE/gAAAANgQOkCqUQk4FPSmXyQ6Cg7y7fiSTXjG2zHn21tdMdZvebw3VDHxYFAdMaEStE8ik0+nuqTKGv70nVDzLNo8x93tOuNY3svMb5G8QGf4rFvypN9OfnBisW/Kk305+cN/xCUY95/oIrLDEJRj3n+gissBgGKxb8qTfTn5wn8X/AIhKMe8/0EVliAAAAAAFAUW6Oe3aElik2p1DXb3+v4PvbFg18vzr12uuxXuZvE/jYECraZJYk4GDRcNO35AXeas9DSV2ILbWY81+oxpT1rtte4tqbWzgA0rCL7b/AGn9wYRfbf7T+4crt5pC+pqT5ccyA280hfU1J8uOZADqsIvtv9p/cJVFAbeaQvqak+XHMgT+AAAAAqChjSBSyIo2k6em0vnZ0bB314/DElPFttnPvsqa8Y63c8zgzy1iXxUNC+j+lVtRrJ1DNo6dFRsZfXjkMcU6Wywc+4ypjxbW7nWcd4DtMViI5UpOnIzgxWIjlSk6cjOHuFRD8zUfUE5IYVEPzNR9QTkgPMViI5UpOnIzhFQtbCoh+ZqPqCckRSAAAAA6CVLVUyiAKgZSpp3AwRVd3Dw0eaUW5W1rW1OuvMYytrWt/wCtaAAPr8Y6375KT4of+YeMdb98lJ8UP/MAAPGOt++Sk+KH/mOUAAAAAB//2Q==',
    name: "Pedestrian",
    title: "Yaya Geçidi",
    className: "g7",
  },
  [JunctionGroupEnum.StraightLeftRight]: {
    url: "g8.png",
    base64: '/9j/4AAQSkZJRgABAQIAAAAAAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAyADIDASIAAhEBAxEB/8QAGwABAAMAAwEAAAAAAAAAAAAAAAQHCAMFBgn/xAAzEAABAwMBBgMIAAcAAAAAAAABAgMEAAURBgcIEhMhMRQiQRUjMjdRYXWzFnFygaGxwf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDKlKUoO70bpi6aw1DEstijl+bIVgZ6JbT6rWfRI7k/7OBXqtseyi77M7oy3Lc8da5IHh57bZQlaseZCk5PCodemTkdR6gXnudXjS6PF2S0wJft9cUzJ099KeFaUrSkNowchIKwfuck+mLU3gL9p6xbPnf4vtj1ytU99MNTTPDxpUpK1BYJ7EcBIPcHFB886VzzRHEx8QlOqihZ5RdACynPTiA6ZxXBQKUpQT7DZ59/u8W12eK5Lnyl8tplsZKj/wAAAJJPQAEntWmrvuuSxoa1RLPNt69R80v3CVKcWlvBTgNNBKT5QfUgFR69OiRS+xTaQ/s11X7RRDZlwpKAxLbLaeby8592s9UnIBxnhVgZ7AjZWrttGlNN6Vs2olrl3G13VRRHcgIQsggZIUFKTwkdiO4PQigqvdl2Yar0HtIuL+pLbyIr1pW2iQ26lxsrLzZCcg98JJx9K95vR6VvOsdBWy16cgrmzDdmVqSkgBCOW6kqUScAAqGT96sDQus7HriyIuunJqZMfPC4kgpcZXjqhaT1B/we4JHWu5uM2LbYMibcJDUaJHQXHXnVBKUJHUkk9hQZN2X7s92Nwdd163Cbtj0d1kssSSqQ0sjyOJISUZB69SfuD2qmdquzu77OdSLtl1TzYzhUqHNQkhElsHuPooZHEnJwT3IIJ2honbjpPWernLBYk3FbqUOOiU6ylthaEdSQSriH90j74rP+87tjjaudXpbTqI0iyxXgt2cpCVl91OR7pR+FIyRxDqrJweE+YM90pSgVI8bK9n+B8Q74Lm87kcR4OZjHFjtnHTNR6UGidySU+jaDfIiXliK7ay6toHyqWh1sJUR9QFrA/qNWrvmSn4+yWO0w8ttuTdGWnkpOA4gIdWEn6jiQk/zSKqPcm+Z13/DufvZq1t9T5VW78w1+l6gxfDmyoXP8HIdY57SmHeWop421fEk47g+oqPSlApSlApSlBoLcm+Z13/DufvZq1t9T5VW78w1+l6lKDE9KUoFKUoP/2Q==',
    name: "StraightLeftRight",
    title: "Düz Geliş Sola Sağa Dönüş",
    className: "g8",
  },
  [JunctionGroupEnum.Flash]: {
    url: "g9.png",
    base64: '/9j/4AAQSkZJRgABAQIAAAAAAAD/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAAyADIDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAgGBwX/xAA0EAAABQMBAwoEBwAAAAAAAAAAAQIDBAUGEQcIEnQTFxghMTZWlLLRFVVh0iI3QVFxk8L/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8AlQexbdsVu5nnmbepUypOspJbiYzRrNBGeCM8DxxSuw/3pubgm/WA5BzT374QrflVewc09++EK35VXsK31Y16g6d3WdDk0SVNcJhD3KtvJQX4s9WDL6DGdLSleFp3mUewCa69YV1W/T1Tq3b1SgQ0qJBvSGFISRn2FkxmRQGtGvkHUKyHaFFocmE4t9t7lXH0qIiSZnjBEJ/AAAAAUrsP96bm4Jv1iahSuw/3pubgm/WA9zaM0evC9dQzq1uwWJEJURpreVJQ2ZKTvZLCjL6Dl/Rw1I+UxfOtfcOz69a51+wL6+CUiDT3Y5RW3jW+lRqNSs57DLq6iHOelTeHyyjf1r+4ByW/7Er9hVGNCuaIiM/Ia5ZvcdS4Sk5Mu1JmWcl2fwMuNvqrqRVdSanCm1piIwuIybKEx0mRGRqMzM8mYxAAAAACldh/vTc3BN+sTUN7pHqbUdM6hUJlKhQ5a5jSWllJ3sJIjzkt0yAbLbD/ADfXwLH+hxAa3U++Z2oV0KrdTjRoz5soZ5OPndIk5/czP9RkgAAAAAAAAAAAAAAAAAAAAH//2Q==',
    name: "Flash",
    title: "Flaş",
    className: "g9",
  },
};



export { markerIcons };
export type { iconExpression };




export const deviceList: any = [
  { key: 1, value: 1, label: "test" },
  { key: 2, value: 2, label: "test2" },
];
