import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const lsLanguage = localStorage.getItem("language");
const language = lsLanguage ? lsLanguage : "en";
//test commit
i18next
  .use(initReactI18next)
  .use(Backend)
  .init({
    // debug: true,
    lng: language,
    fallbackLng: language,
    react: {
      useSuspense: false,
    },
    backend: {
      allowMultiLoading: false,
    },
  });
