import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { MangoCar } from "../../data/mango-car/MangoCar";
import { socket } from "../websocket/WebSocketContext";

export interface MangoCarDataContextDataState {
  mangoCarData: MangoCar[];
}

const MangoCarDataContext = createContext<
  MangoCarDataContextDataState | undefined
>(undefined);

export function MangoCarDataProvider({ children }: PropsWithChildren<{}>) {
  const [mangoCarData, setMangoCarData] = useState<MangoCar[]>([]);

  useEffect(() => {
    socket.on("vehicleGPSData", (data: MangoCar) => {
      setMangoCarData((mangoCarData) => [{ ...data, date: new Date() }]);
    });

    return () => {
      socket.off("vehicleGPSData");
    };
  }, []);

  return (
    <MangoCarDataContext.Provider
      value={{
        mangoCarData: mangoCarData,
      }}
    >
      {children}
    </MangoCarDataContext.Provider>
  );
}

export function useMangoCarData() {
  const context = useContext(MangoCarDataContext);
  if (context === undefined) {
    return {
      mangoCarData: [],
    };
    // throw new Error(
    //   "useMangoCarData must be used within a MangoCarDataContext"
    // );
  }
  return context;
}
