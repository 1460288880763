import { useTranslation } from "react-i18next";
import { Dropdown, Menu, MenuProps } from "antd";
import { RightOutlined, TranslationOutlined } from "@ant-design/icons";
import DarkButton from "../../components/custom-styled/button/DarkButton";

interface Language {
  nativeName: string;
}

interface Languages {
  [key: string]: Language;
}

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  disabled?: boolean,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    disabled,
    label,
  } as MenuItem;
}

function LanguageControl() {
  const { i18n } = useTranslation();

  const languages: Languages = {
    en: {
      nativeName: "English",
    },
    tr: {
      nativeName: "Türkçe",
    },
    id: {
      nativeName: "Bahasa Indonesia",
    },
    tk: {
      nativeName: "Türkmenler",
    },
    ru: {
      nativeName: "Русский",
    },
  };

  const menuItems: MenuItem[] = Object.keys(languages).map((lng, index) => {
    const isLngSelected = i18n.resolvedLanguage === lng;

    return getItem(
      languages[lng].nativeName,
      lng,
      <RightOutlined />,
      isLngSelected
    );
  });
  const languagesMenu = (
    <Menu
      onClick={(e) => {
        i18n.changeLanguage(e.key);
        localStorage.setItem("language", e.key);
      }}
      style={{
        width: "150px",
        marginTop: "15px",
        padding: "0px 0px",
      }}
      items={menuItems}
    />
  );

  return (
    <Dropdown overlay={languagesMenu} trigger={["click"]}>
      <DarkButton icon={<TranslationOutlined color="white" />} />
    </Dropdown>
  );
}

export default LanguageControl;
