import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, Table, Button, Modal, Form, Select } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Vms, VmsDataModuleTypeEnum } from "../../data/vms/Vms";
import VmsService from "../../data/vms/VmsService";
import { t } from "i18next";
import { Route } from "../../data/bluesis/Route";
import BluesisService from "../../data/bluesis/BluesisService";
import { Vector } from "../../data/bluesis/Vector";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { AuthService } from "@icms/ui-components";

const { Option } = Select;

export interface VmsInstantMessageSourceModel {
  drawing: string;
  label: string;
  dataModuleType: string | VmsDataModuleTypeEnum;
  selectedRoute: string | Route;
  vmsId: number;
  messageColor:string;
}

const initialSourceModel: VmsInstantMessageSourceModel[] = [];

interface Props {
  selectedVms: Vms;
}

const VmsInstantMessageSource: React.FC<Props> = ({ selectedVms }) => {
  const { t } = useTranslation();

  const [sources, setSources] =
    useState<VmsInstantMessageSourceModel[]>(initialSourceModel);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [drawing, setDrawing] = useState("");
  const [label, setLabel] = useState("");
  const [drawingData, setDrawingData] = useState<any>("");
  const [drawingOptions, setDrawingOptions] = useState<string[]>([]);
  const [labelOptions, setLabelOptions] = useState<string[]>([]);
  const [dataModuleType, setDataModuleType] = useState<VmsDataModuleTypeEnum | string>("");
  const [bluesisRoutes, setBluesisRoutes] = useState<Route[]>([]);
  const [selectedRoute, setSelectedRoute] = useState<Route | string>("");
  const [bluesisVectors, setBluesisVectors] = useState<Vector[]>([]);

  const [editSourceId, setEditSourceId] = useState<any | null>(null);

  const [crudMode, setCrudMode] = useState<string>("");

  const [loading, setLoading] = useState(true);

  const [messageColor, setMessageColor] = useState<string>("");

  let user = AuthService.getUser();

  const getVmsDrawingFiles = useCallback(() => {
    VmsService.getVmsDrawingFiles(selectedVms)
      .then((response) => {
        setDrawingData(response.data.drawing_data);
        setDrawingOptions(Object.keys(response.data.drawing_data));
      })
      .catch((err) => console.log(err));
  }, [selectedVms]);

  useEffect(() => {
    getVmsDrawingFiles();
  }, [getVmsDrawingFiles]);

  const getBluesisRoutes = async () => {
    await BluesisService.getRoutes().then((res) => {
      const allowedOrgIds = new Set(user.user_info.org_id_list);
      const filteredRoutes = res.data.filter((route) =>
        allowedOrgIds.has(route.org_id)
      );
      setBluesisRoutes(filteredRoutes);
    });
  };
  const getBluesisVectors = async () => {
    await BluesisService.getVectors().then((res) => {
      const allowedOrgIds = new Set(user.user_info.org_id_list);
      const filteredVectors = res.data.filter((vector) =>
        allowedOrgIds.has(vector.org_id)
      );
      setBluesisVectors(filteredVectors);
    });
  };

  useEffect(() => {
    getBluesisRoutes();
    getBluesisVectors();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const addVmsSource = async (source: VmsInstantMessageSourceModel) => {
    await VmsService.addInstantMessageSourceVms(source,messageColor)
      .then((response) => {
        fetchVmsInstantMessageDataSources();
      })
      .catch((err) => console.log(err));
  };

  const addSource = () => {
    setCrudMode("add");
    showModal();
  };
  const editSource = (sourceId: any) => {
    setCrudMode("edit");
    setEditSourceId(sourceId);
    const sourceToEdit = sources.find((source: any) => source.key === sourceId);
    if (sourceToEdit) {
      setDrawing(sourceToEdit.drawing);
      setLabel(sourceToEdit.label);
      setDataModuleType(sourceToEdit.dataModuleType == "Bluesis" ? 0 : 1);
      if (sourceToEdit.selectedRoute) {
        setSelectedRoute(sourceToEdit.selectedRoute.toString());
      }
      showModal();
    }
  };
  const updateVmsSource = async (
    source: VmsInstantMessageSourceModel,
    id: number
  ) => {
    await VmsService.updateInstantMessageSourceVms(source, id,messageColor)
      .then((response) => {
        fetchVmsInstantMessageDataSources();
      })
      .catch((err) => console.log(err));
  };

  const deleteVmsSource = async (id: number) => {
    await VmsService.deleteInstantMessageSourceVms(id)
      .then((response) => {
        fetchVmsInstantMessageDataSources();
      })
      .catch((err) => console.log(err));
  };

  const fetchVmsInstantMessageDataSources = async () => {
    try {
      setLoading(true);
      const response = await VmsService.getInstantMessageSourceVms(
        selectedVms.id
      );
      const formattedData = response.data.map((item: any) => ({
        key: item.id,
        drawing: item.drawing_name,
        label: item.tag,
        dataModuleType: item.vms_source_id == 0 ? "Bluesis" : "Diğer",
        selectedRoute: item.bluesis_route_id,
        messageColor:item.text_color
      }));
      setSources(formattedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVmsInstantMessageDataSources();
  }, []);

  const handleOk = async () => {
    const newSource: VmsInstantMessageSourceModel = {
      drawing,
      label,
      dataModuleType,
      selectedRoute,
      vmsId: selectedVms.id,
      messageColor
    };
    addVmsSource(newSource);
    setIsModalVisible(false);
    resetForm();
  };

  const handleUpdate = () => {
    const updatedSource: VmsInstantMessageSourceModel = {
      drawing,
      label,
      dataModuleType,
      selectedRoute,
      vmsId: selectedVms.id,
      messageColor
    };
    updateVmsSource(updatedSource, editSourceId);
    setIsModalVisible(false);
    resetForm();
  };

  const handleDelete = (key: any) => {
    Modal.confirm({
      title: t("delete"),
      content: t("it_will_be_deleted_r_you_sure"),
      onOk: () => {
        deleteVmsSource(key);
        resetForm();
      },
      onCancel: () => {},
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    resetForm();
  };

  const resetForm = () => {
    setDrawing("");
    setLabel("");
    setDataModuleType("");
    setSelectedRoute("");
  };

  const editButton = (record: any) => (
    <Button
      type="link"
      icon={<EditOutlined />}
      onClick={() => editSource(record)}
    />
  );

  const columns = [
    {
      title: t("drawing_file"),
      dataIndex: "drawing",
      key: "drawing",
    },
    {
      title: t("ticket"),
      dataIndex: "label",
      key: "label",
    },
    {
      title: t("vms_data_provider_module"),
      dataIndex: "dataModuleType",
      key: "dataModuleType",
    },
    {
      title: t("route"),
      dataIndex: "selectedRoute",
      key: "selectedRoute",
      render: (selectedRoute: any) => {
        if (selectedRoute) {
          setLoading(true);
        }
        const route = bluesisRoutes.find(
          (routeData) => routeData.id == selectedRoute
        );
        const matchingVectors = bluesisVectors.filter(
          (vector) => vector.route_id == selectedRoute
        );
        const routeNameWithDetails = `${
          route ? route.name : ""
        } (${manipulateVectorName(
          `${matchingVectors
            .map(
              (vector) =>
                `${vector.origin_device_id} - ${vector.destination_device_id}`
            )
            .join(", ")}`
        )})`;
        if (routeNameWithDetails) {
          setLoading(false);
        }
        return routeNameWithDetails;
      },
    },
    {
      title: t("text_color"),
      dataIndex: "messageColor",
      key: "messageColor",
      render: (messageColor: string, record: any) => (
        <span style={{ color: messageColor }}>{messageColor}</span>
      ),
    },
    {
      title: "",
      dataIndex: "key",
      key: "edit",
      render: editButton,
    },
    {
      title: "",
      dataIndex: "key",
      render: (key: any) => (
        <Button
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(key)}
          danger
        />
      ),
    },
  ];
  
  const getTextColorForTagID = (selectedTagID:any) => {
    if (drawingData[drawing]) {
      const selectedDrawingData = drawingData[drawing];
      const variableTextData = selectedDrawingData.variableTextData;  
      const selectedTag = variableTextData.find((data:any) => data.tagID === selectedTagID);
  
      if (selectedTag) {
        const textColor = selectedTag.textColor;
        setMessageColor(textColor);       
      }
    }
  };

  const manipulateVectorName = (rawVectorData: any) => {
    const rawVectorDataArray = rawVectorData.split(", ");
    let result = [];

    for (let i = 0; i < rawVectorDataArray.length; i++) {
      let [origin, destination] = rawVectorDataArray[i].split(" - ");

      if (!result.length) {
        result.push(origin, destination);
        break;
      }
    }

    while (result.length < rawVectorDataArray.length + 1) {
      for (let i = 0; i < rawVectorDataArray.length; i++) {
        let [origin, destination] = rawVectorDataArray[i].split(" - ");

        if (result[result.length - 1] === origin) {
          result.push(destination);
        } else if (result[0] === destination) {
          result.unshift(origin);
        }
      }
    }

    const resultStr = result.join("-");
    return resultStr;
  };

  const tableRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  return (
    <div>
      <Card
        title={t("vms_data_sources")}
        extra={
          <>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={addSource}
              style={{ marginRight: "10px" }}
            >
              {t("vms_add_source")}
            </Button>
            <Button
              type="primary"
              onClick={handlePrint}
              icon={<DownloadOutlined />}
            />
          </>
        }
      >
        <Table
          dataSource={sources}
          columns={columns}
          ref={tableRef}
          loading={loading}
        />
      </Card>

      <Modal
        title={t("vms_add_source")}
        visible={isModalVisible}
        onOk={crudMode === "edit" ? handleUpdate : handleOk}
        onCancel={handleCancel}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Form.Item label={t("drawing_file")}>
            <Select
              value={drawing}
              onChange={(value) => {
                setDrawing(value);
                const selectedDrawingData = drawingData[value];
                const variableTextData = selectedDrawingData.variableTextData;
                const tagIDs = variableTextData.map((data: any) => data.tagID);
                setLabelOptions(tagIDs);
                setLabel("");
                setDataModuleType("");
                setSelectedRoute("");
              }}
              style={{ width: "100%" }}
            >
              {drawingOptions.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {drawing && (
            <Form.Item label={t("ticket")}>
              <Select
                value={label}
                onChange={(value) => {
                  setLabel(value);
                  getTextColorForTagID(value);
                }}
                style={{ width: "100%" }}
              >
                {labelOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {label && (
            <Form.Item label={t("modules")}>
              <Select
                style={{ width: "100%" }}
                value={dataModuleType}
                onChange={(value) => setDataModuleType(value)}
              >
                {Object.keys(VmsDataModuleTypeEnum)
                  .filter((key) => isNaN(Number(key)))
                  .map((key) => (
                    <Option
                      key={key}
                      value={
                        VmsDataModuleTypeEnum[
                          key as keyof typeof VmsDataModuleTypeEnum
                        ]
                      }
                    >
                      {key}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          )}
          {(dataModuleType === VmsDataModuleTypeEnum.Bluesis ||
            crudMode === "edit") && (
           <Form.Item label={t("route")}>
  <Select
    showSearch
    style={{ width: "100%" }}
    value={selectedRoute}
    onChange={(value) => setSelectedRoute(value)}
    filterOption={(input:any, option:any) =>
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    optionFilterProp="children"
  >
    {bluesisRoutes.map((routeData) => {
      const matchingVectors = bluesisVectors.filter(
        (vector) => vector.route_id === routeData.id
      );

      const routeNameWithDetails = `${
        routeData.name
      } ( ${manipulateVectorName(
        `${matchingVectors
          .map(
            (vector) =>
              `${vector.origin_device_id} - ${vector.destination_device_id}`
          )
          .join(", ")}`
      )} )`;

      return (
        <Option key={routeData.id} value={routeData.id}>
          {routeNameWithDetails}
        </Option>
      );
    })}
  </Select>
</Form.Item>

          )}
        </Form>
      </Modal>
    </div>
  );
};

export default VmsInstantMessageSource;
