import { Inventory } from "../../../../data/inventory/Inventory";
import { GenericMapModalTitle } from "../../components/generic/map-modal/GenericMapModalTitle";
import withLogging from "../../high-order-components/LoggerHOC";

interface InventoryModalTitleProps {
  selectedInventory: Inventory;
}

function InventoryModalTitle(props: InventoryModalTitleProps) {
  const { selectedInventory } = props;

  return (
    <GenericMapModalTitle color="#5271FF">
      <span style={{ fontSize: "2vw" }}>{selectedInventory.barcode}</span>
    </GenericMapModalTitle>
  );
}

export default withLogging(InventoryModalTitle);
