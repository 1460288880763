import Headers from "./components/Headers";
import { StatisticProvider } from "./context/StatisticContext";
import Archive from "./components/Archive";
import VehicleDensity from "./components/VehicleDensity";
import Summary from "./components/Summary";
import { useEffect, useState } from "react";
import JunctionPhaseService from "../../../data/junction-phase/JunctionPhaseService";

interface Props {
  selectedJunction: any;
}

const Statistic: React.FC<Props> = ({ selectedJunction }) => {
  const [isHaveLoop, setIsHaveLoop] = useState<boolean>(false);
  
  useEffect(() => {
    if (selectedJunction) {
      JunctionPhaseService.get(selectedJunction.id).then(res => {
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index];
          if(element.detectors && element.detectors.length > 0){
            setIsHaveLoop(true);
            break;
          }else{
            setIsHaveLoop(false);
          }
        }      
      })
      
    }
  }, [selectedJunction])
  
  return (
    <StatisticProvider>
      <Headers />
      <Summary isHaveLoop={isHaveLoop} selectedJunction={selectedJunction}/>
      <Archive selectedJunction={selectedJunction} />
      <VehicleDensity isHaveLoop={isHaveLoop} selectedJunction={selectedJunction}/>
    </StatisticProvider>
  );
};

export default Statistic;
