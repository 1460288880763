import http from "../../../data/http.common";

export interface IErrorMonitorFormOutput {
  errorTypes: (number | string)[];
  junctionIds: (number | string)[];
  startTime: number;
  endTime: number;
  page: number;
}

// CED: Centris Error Data Service

export const fetchCedByMultiErrorTypeAndJunction = async (
  formOutput: IErrorMonitorFormOutput
) => {
  return http.post("/web/junction/statistic/pastrecord", {
    ...formOutput,
    limit: 10,
  });
};
