import { useEffect } from "react";

// context
import { PlanProvider } from "./context/PlanContext";

// Tab View Imports
import Plans from "./page";

// types
import { Junction } from "../../../data/junction/Junction";

interface Props {
  selectedJunction?: Junction;
}

const SignalPlan: React.FC<Props> = ({ selectedJunction }) => {
  return (
    <>
      <PlanProvider selectedJunction={selectedJunction}>
        <Plans selectedJunction={selectedJunction} />
      </PlanProvider>
    </>
  );
};

export default SignalPlan;
