import { Button, Checkbox } from "antd";
import { useContext } from "react";
import { MULTI_MODE_TYPES } from "../../constants";
import { MultiModeSchedularContext } from "../../context/MultiModeSchedularContext";

const MultiModeTypeFilter = () => {
  const schedularContextValues = useContext(MultiModeSchedularContext);
  const [selectedMultiModeTypes, setSelectedMultiModeTypes] =
    schedularContextValues?.selectedMultiModeTypes;
  return (
    <>
      <h2>MM TYPE FILTER</h2>
      <Checkbox.Group
        options={MULTI_MODE_TYPES}
        value={selectedMultiModeTypes}
        onChange={(val: any) => {
          setSelectedMultiModeTypes(val);
        }}
      />
      <Button
        onClick={() => {
          if (selectedMultiModeTypes.length > 0) {
            setSelectedMultiModeTypes([]);
          }
        }}
        type="primary"
      >
        Clear
      </Button>
      <Button
       style={{marginLeft:'10px'}}
        onClick={() => {
          if (selectedMultiModeTypes.length < MULTI_MODE_TYPES.length) {
            setSelectedMultiModeTypes(MULTI_MODE_TYPES);
          }
        }}
        type="primary"
      >
        Select All
      </Button>
    </>
  );
};

export default MultiModeTypeFilter;
