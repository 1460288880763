import styled from 'styled-components';


export interface GenericMapModalTitleProps {
  animation?: string;
  color?: string;
  margin?: string;
}

export const GenericMapModalTitle = styled.div<GenericMapModalTitleProps>`
  text-align: center;
  font-size: 30px;
  background-color: ${(p) => p.color};
  margin: ${(p) => (p.margin ? p.margin : "-16px -24px")};
  padding: 16px 24px;
  color: ${(p) => (p.color ? "white" : "black")};
  animation: ${(p) => p.animation} @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;