import { Tabs, Button } from "antd";
import { LatLng, Map } from "leaflet";
import React, { useState } from "react";
import { Vms } from "../../data/vms/Vms";
import DetailsVms from "./DetailsVms";
import VmsPlans from "./VmsPlans";
import InstantMessage from "./InstantMessage";
import EditorIndex from "./EditorIndex";
import { t } from "i18next";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import VmsInstantMessageSource from "./VmsInstantMessageSource";
import VmsSettings from "./VmsSettings";
import VmsStream from "./VmsStream";
import ReportForm from "../profile/ReportForm";

const { TabPane } = Tabs;
interface Props {
  selectedTab?: string;
  selectedVms?: Vms;
}
const VmsMain = (props: Props) => {
  const { selectedVms } = props;
  const [sendedDrawings, setSendedDrawings] = useState<any | null>(null);

  const [activeKey, setActiveKey] = useState("1");

  const handleChangeTab = (key: any) => {
    setActiveKey(key);
  };

  return (
    <Tabs
      activeKey={activeKey}
      onChange={handleChangeTab}
      destroyInactiveTabPane
      animated
      type="card"
      size="large"
    >
      <TabPane tab={t("editor")} key="1">
        <EditorIndex
          selectedVms={selectedVms!}
          sendedDrawings={sendedDrawings}
          setSendedDrawings={setSendedDrawings}
        />
      </TabPane>
      <TabPane tab={t("plan")} key="2">
        <VmsPlans
          selectedVms={selectedVms!}
          sendedDrawingsFromEditor={sendedDrawings}
        />
      </TabPane>
      {selectedVms && (
        <TabPane tab={t("settings")} key="3">
          <VmsSettings selectedVms={selectedVms} />
        </TabPane>
      )}
      {/* <TabPane tab={t("instant_messages")} key="4">
        <InstantMessage selectedVms={selectedVms} />
      </TabPane> */}
      <TabPane tab={t("vms_data_configuration")} key="4">
        <VmsInstantMessageSource selectedVms={selectedVms!} />
      </TabPane>
      <TabPane tab={t("detail")} key="5">
        <DetailsVms selectedVms={selectedVms} />
      </TabPane>
      <TabPane tab={t("watch")} key="6">
        <VmsStream
          streamUrl={selectedVms?.stream_url || ""}
          width={
            selectedVms?.resolution_x ? `${selectedVms.resolution_x}px` : "100%"
          }
          height={
            selectedVms?.resolution_y ? `${selectedVms.resolution_y}px` : "100%"
          }
        />
      </TabPane>

      <TabPane tab={t("reportBug")} key="8">
        <ReportForm initialSelectedTopic="1" releatedId={selectedVms?.id} />
      </TabPane>
    </Tabs>
  );
};

export default VmsMain;
