import { createContext, useState } from "react";
import { TTypeBasedGroupedPlanOutput } from "../components/DayContainer";
import { days, MULTI_MODE_TYPES } from "../constants";

interface Props {
  children: any;
}

interface Output {
  selectedDays: [
    selectedDays: string[],
    setSelectedDays: React.Dispatch<React.SetStateAction<string[]>>
  ];
  selectedMultiModeTypes: [
    selectedMultiModeTypes: (keyof TTypeBasedGroupedPlanOutput)[],
    setSelectedMultiModeTypes: React.Dispatch<
      React.SetStateAction<(keyof TTypeBasedGroupedPlanOutput)[]>
    >
  ];
}

export const MultiModeSchedularContext = createContext<any>(null);

export const MultiModeSchedularProvider = (props: Props) => {
  const [selectedDays, setSelectedDays] = useState<string[]>(days);
  const [selectedMultiModeTypes, setSelectedMultiModeTypes] =
    useState<(keyof TTypeBasedGroupedPlanOutput)[]>(MULTI_MODE_TYPES);

  return (
    <MultiModeSchedularContext.Provider
      value={{
        selectedDays: [selectedDays, setSelectedDays],
        selectedMultiModeTypes: [
          selectedMultiModeTypes,
          setSelectedMultiModeTypes,
        ],
      }}
    >
      {props.children}
    </MultiModeSchedularContext.Provider>
  );
};
