import L from "leaflet";
import { JunctionStatus } from "../../../../data/junction/Junction";

export const JunctionStatusInfo = {
  [JunctionStatus.G]: {
    text: "green_wave",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/G.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "#00d800",
    icon: `/static/assets/map-markers/G.png`,
  },
  [JunctionStatus.PF]: {
    text: "fixed_phase",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/PF.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "#2e7d32",
    icon: `/static/assets/map-markers/PF.png`,
  },
  [JunctionStatus.FP]: {
    text: "planned_flash",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/FP.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
      className: "blinking",
    }),
    color: "red",
    icon: `/static/assets/map-markers/FP.png`,
  },
  [JunctionStatus.PD]: {
    text: "dynamic_phase",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/PD.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "#2196f3",
    icon: `/static/assets/map-markers/PD.png`,
  },
  [JunctionStatus.S]: {
    text: "sequence",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/S.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "orange",
    icon: `/static/assets/map-markers/S.png`,
  },
  [JunctionStatus.C]: {
    text: "power_cut",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/C.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "black",
    icon: `/static/assets/map-markers/C.png`,
  },
  [JunctionStatus.NC]: {
    text: "no_connection",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/NC.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "#4a148c",
    icon: `/static/assets/map-markers/NC.png`,
  },
  [JunctionStatus.PS]: {
    text: "passive",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/PS.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "gray",
    icon: `/static/assets/map-markers/PS.png`,
  },
  [JunctionStatus.FE]: {
    text: "error_flash",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/FE.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
      className: "blinkingMarker",
    }),
    color: "#b71c1c",
    icon: `/static/assets/map-markers/FE.png`,
  },
  [JunctionStatus.LM]: {
    text: "listen_mode",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/LM.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "#b71c1c",
    icon: `/static/assets/map-markers/LM.png`,
  },
  [JunctionStatus.DJ]: {
    text: "is_dummy",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/DJ.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "#666666",
    icon: `/static/assets/map-markers/DJ.png`,
  },
  [JunctionStatus.DS]: {
    text: "dynamic_sequence",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/DS.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "orange",
    icon: `/static/assets/map-markers/DS.png`,
  },
  [JunctionStatus.VP]: {
    text: "vip_pass",
    marker: L.icon({
      iconUrl: "/static/assets/map-markers/VP.png",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "#9966ff",
    icon: `/static/assets/map-markers/VP.png`,
  },
  [JunctionStatus.NS]: {
    text: "not_sync",
    marker: L.icon({
      iconUrl:"/static/assets/map-markers/NS.png", 
      iconSize: [37.5,37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "#333",
    icon: `/static/assets/map-markers/NS.png`,
  },
  [JunctionStatus.FF]: {
    text: "forced_flash",
    marker: L.icon({
      iconUrl:"/static/assets/map-markers/FF.png", 
      iconSize: [37.5,37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "#b71c1c",
    icon: `/static/assets/map-markers/FF.png`,
  },
  [JunctionStatus.UK]: {
    text: "unknown",
    marker: L.icon({
      iconUrl:"", 
      iconSize: [37.5,37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
    color: "#b71c1c",
    icon: ``,
  }
};
