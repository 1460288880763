  import InventoryService from "../../../../../../data/inventory/InventoryService";
import { MarkerForm } from "./MarkerForm";
  
  export const InventoryModel: MarkerForm = {
    type: "inventory",
    addService: InventoryService.addInventory,
    updateService: InventoryService.updateInventory,
    items: [
      {
        label: "address",
        name: "address",
        type: "text-area",
        isRequired: false,
      },
      {
        label: "latitude",
        name: "latitude",
        type: "number",
        isRequired: true,
      },
      {
        label: "longitude",
        name: "longitude",
        type: "number",
        isRequired: true,
      },
      {
        label: "sub_type",
        name: "sub_type_id",
        type: "dropdown",
        isRequired: true,
        dropdownItem: "inventoryTypeList",
      },
      {
        label: "montage_date",
        name: "montage_date",
        type: "date-picker",
        isRequired: true,
      },
      {
        label: "expiration_date",
        name: "expiration_date",
        type: "date-picker",
        isRequired: true,
      },
      {
        label: "size",
        name: "size",
        type: "input",
        isRequired: false,
      },
      {
        label: "status",
        name: "status",
        type: "input",
        isRequired: false,
      },
      {
        label: "related_barcode_number",
        name: "related_barcode_number",
        type: "input",
        isRequired: false,
      },
    ],
  };
  