import { JunctionDetector } from "../../../../data/junction-detector/JunctionDetector";
import { GenericMapModalTitle } from "../../components/generic/map-modal/GenericMapModalTitle";
import withLogging from "../../high-order-components/LoggerHOC";

interface VieroModalTitleProps {
  selectedViero: JunctionDetector;
}

function VieroModalTitle(props: VieroModalTitleProps) {
  const { selectedViero } = props;

  return (
    <GenericMapModalTitle color="#5FD1C1">
      <span style={{ fontSize: "2vw" }}>{selectedViero.name}</span>
    </GenericMapModalTitle>
  );
}

export default withLogging(VieroModalTitle);
