export interface Task {
  id: number;
  title: string;
  description: string;
  latitude: number;
  longitude: number;
  task_type: number;
  task_status: number;
  detail_id: number;
  est_time: string;
  start_time: Date;
  end_time: Date;
  priority: number;
  created_by: number;
}

export const taskStatusColors: {
  [key: number]: string;
} = {
  1: "#FF5733",
  2: "#5cb85c ",
  3: "#cdbb09",
  4: "#666666",
  5: "#333333",
};

export const taskStatusNames: {
  [key: number]: string;
} = {
  1: "ACTIVE",
  2: "DONE",
  3: "PENDING",
  4: "ARCHIVED",
  5: "SUSPENDED",
};
export const taskStatusNamesTR: {
  [key: number]: string;
} = {
  1: "Aktif",
  2: "Tamamlandı",
  3: "Beklemede",
  4: "Arşivlendi",
  5: "Askıya Alındı",
};

export const TaskPriority: Record<number, string> = {
  "0": "Low",
  "1": "Medium",
  "2": "High",
};

export const taskTypeEnum: Record<number, string> = {
  "0": "Diğer",
  "1": "Kablo Arızası",
  "2": "Sinyal Ünite Onarımı",
  "3": "Data Arızası",
  "4": "Ampül/Modül Değişimi",
  "5": "Sinyal Direği Onarımı",
  "6": "Kısa Devre Kablo Arızası",
  "7": "KKC Arızası",
  "8": "Bas-Geç Butonu",
  "9": "Sinyal Ünite Montaj/Demontaj",
  "10": "Kavşak Yapımı",
  "11": "Sinyal Direği Montaj/Demontaj",
  "12": "Kablo Çekimi",
  "13": "Altyapı Yapım/Arıza",
  "14": "Elektronik Kart Değişimi",
  "15": "KKC Montaj/Demontaj",
  "16": "Direk Düzeltme",
  "17": "VMS Arızası",
  "18": "Araç Sayımı",
  "19": "VMS Elektronik Kart Değişimi",
  "20": "Bluesis Data Arızası",
  "21": "SamSinyal Ünite Onarımı",
  "22": "Bağlantı Olumsuz",
  "23": "Kavşak Bakım/Temizlik",
  "24": "UPS Akü Değişim-Ölçüm-Bakım-Onarım",
  "25": "Levha Montajı",
};

export const taskTypeImages = [
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAL0SURBVFiFtdfLj1VFEAbw30wIDjMOEcZEJqDxkYHAgpE/wDGEjIAhIcFEo+4khocLEhI2BBhlRaKQuOK9csUGFogreUggsjRx4Q5UBEQIbwkwhMui+9CH69x7+lzhS07Ovd1dVX2qur6q7pKPqViGhRjG63gpzt3A7/gFx/E9btfQ3RZzsB930ch8/sU+DFUp72oz14utWIdJcWwcJ+NzHpfj+Ct4FSN4t2n9t9iMe1WbKWMIv0pf9BdWYVqG7DSsxoWS/BkM5hpfgH+i4Dg2Cd6oi15siTqKjxiuEhoqGb+GRR0YbsZo1NUQwtbSE72S269h7jMwXmBeaRNn0DPRom8ktz+LL2/GqBSOrc2Tc0qTmyoU9UcFf0reOoUPtM8qGIsydzSFYr90UNoduMk4rXX+b6/YQJ+UHTuLwakCcTSEVGuHRSVjR+L6MYEJG3gk8EE7rJHIqh8+iQMPVOf5aziHA+gujW8obWxhhY7pUrg/gr3xz48Vgu2wK+p4KI9wjsX1u7slcvipQ+Pr8HlpI5cyZE7E9zBcibv5rAPj2yXXn8CUTLmVUeYy3I9/ltQ0PlIyfkgLcmmBpVHuXvkgVeVwM5bH91V8ql61e2KrG7fi7+xqFTEQ378JvUIdFLZuTsJZvIyZNZUcxJv4rqYczIrvs7BHiMfRDhR1iuPR5i74WD4RlbECh4X418GAREQfEuiwoOLVmUomSz3iuMBuuVgrFaQXi8GiGF2Q1/104Y8oc0V+/vfhYpTbW56YLYSgIRSXHLwhsODszPXwVbRxH281T34tuXS0htJcLBZqRQPbJlrQI7RLz6slux51/4wXWi2cIXU6N4Wb0P/Fe1I/eFEG3wwLnVERjjHh8NRFnxDzwu3nMT9XeFAKR5Eda+Sl2wC+kE574fYJqb5dAerBRqyXPPDQ01ezv+P4DKFbGsE7nr6a7cCXal7NyhgUGsiCrHKeO0Ke/yfVmlGnBPfjfaHne1vggfL1/JxwPT+GH+ImKvEYlEDsFqMY0K0AAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAABFQAAARUBKX4CGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAIwSURBVFiF7Zc/aFRBEMZ/c6cGMWBlZThJKVpZKFcIKTSFVukOLIO1FhZaia12toqgEgjYiXiihRJQUCEW/ktjE6MWNho1inB+Frfv3tzF2+w7trDIwPLmzftm9tvZfbO7SEISwDjwPLRxZ78IrABHCttGDWgCl4HHwCrwGrgJzAK1PqxzmgEU2oyz/w62GwkdjwGXgI6LNdgWgMnCp0YpY0P0reG5jYiYmQH3gDPQi/sRaIcM/Am2w8ALM5vEAXPIKWAq6O+BaUm7JR2TtB/YEwgC7ASum1nNp69FmaaWsxe2+UjqdwFrAfcVaESwbRdzNlcGDgLbg35B0nIEe5JyOqa2mFkrvDQdqNmd0j5pOOyTgU4OOP1+jKmkFTN7C+wr/Iat1lhrDaR1zn2rJ/wttwK2k2sK6m6EnQR8ganl/AtGkhrlgqgiKaNMJvBuBL9RfIYSeFnRpwO8yUngakWfOUm/shGQ1AbuJOK/AWdzdQ7lXnACeLAB9jNwVNKn7AQkrQLHgXOsX2DfgWvAIUlPc3ZO6HywShlwm7Ky7UiobPMFPvHA0sOvK0TqItbc+4/so3byX1TCTQKbBP4lvd3OzOpDMF4KTOWddRiBJafvTYhTYJaiqAoEFp0+HQtgZhOOwGIMW4XAM+Bn0M+bWSMS44qL86gqgVi5PE1ZjpfpbkT++wT9Z/wFBu59KaU4BjLgIf2n4Q/AXeAV/fe/L7j7XhYCo142sxJwDsnX7aoE/gLat4pdMMPkqQAAAABJRU5ErkJggg==",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANGSURBVFiFxZc/SFVRHMc/5yqCOChqkw2WFghBLi3qGhmiqESBNFUQiFNDg4tYQ4EOgunQkNAqItGWQ2lN2VQ4VVBElhTxqsFU8DTc77XfOx5fPV/Ugct99/P7/b6/7z333D/Pee/5nyOJQedc4pw76ZyrKrWBc65KWtFeeO/zNpmaATxwNYwXuwFXpTUDJLvikeZ3VfAGaPgLBhqk5aWdFDJwyzRvLLW50W00Jm5FDQDtwDawZpsDDjgHHCyi4UHgLOACE2vq0Z5nACgHXsjhQNB8Wny6CAM7NYGJAfEXQLk1MKjAg0BoXPw70FKEgRbVeGA8iD0QH7QGlgWPm8QOTdc20LeP695n6jsMP65eyzrmqMDzYOpXxK/t0aACOKGtYo+ca9JYCS7Fc/GjACME97zO3gPPbKFZL8PAunK8fg9n1zU4kWfKsbOQPRtGAOZ00GoSpsRuRs7qtmn8GHhijm9H8m8qNmVYq9gcwFMd1JmEjHUHYm3in4Bjhh8T80BbUNMt/tSwuowBrALrQdGqEuoDPio+FjnTMcVGA14vvhrwdWA1AWqBHPmjFsh57z8H/LD2j9g9HgU5AEgjJ007ckBtAnwADjjnykzwA1DjnDsUFL3UfiBiYCDIAUAaNdLMWBlwIGNLpFPUYKZnUex8MG3NwAbwAxgCqrUNiW0AzUHNeWktGtYgtpQA72SsyRhf0L7Nno33/hVwhfQZMAm81zYpdkU5dmQaC4Zlvd4BXJabCeOwSewtUB1ZcJ3AQ37dfg+BzkhetTQ80GT4hNhlSBfHJvARKDNJ80q6T+RDQjk50sUaiyWq9cC84WXqtQnUZvCeEnuD6/RV/Po+DFxX7Vfy11ev+D3vf72MTgm+BipNcj+wRfpC6fpTA0CXaraAfsMr1cMDp3YMKDirwI1A7IyExoswMK6aMwG/oR6zOyyY8m8q7AkK62LroICBBPNoF+uR9re8SxK5Z7dJ7+eeULiYNRBpviHt/GdLJPmCMdFeqgHSb82s+YUwvuvPgvf+DnCJ9F1+JIzvYxyR1kVp5w0nl7uGc67Ce79ZSNk5lwPw3tf8Jm9PrfjfpVS0YPNiRiGt8hK1v5RYX7KB06Ua2HMN/KvxEy2bJk5dsNq/AAAAAElFTkSuQmCC",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAADsAAAA7AF5KHG9AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAuNJREFUWIXF10uolVUUB/DfuVnmi8wwREsoTUsRH4MSswjFWTTRHDZQkAYiPiYRobNQQxS8RGIQCoJdCAdWUM1SClTMR6aDUFPJG6LXMo3L1XMarH08H+d+533ABQvOWev71vrvvfZa/2/ziKWQY5uGNzAf0zEVT2N08pcwgH5cw1mcwI/4r10gY7Ae51KCdvQe+rCo1eRzcLGDxNVaRK/83c2Vs11MntX3mkneo1LbbsuYZh98Ezd1d/WH8HgraCfhky4A+QkrWklcwGp8IQ7PCLwu2nAeZmIyJlS9N4TruIJfcRw/4GryP5fe/7oZEA/wG9amZLVkrJgHI2r4H8Ni7BUtuaOZ5IUEoCf9L4muOIZTuCxW9a8YPvCkOGCTxEpfxgKxc9mdWiV2tqE80P0W/DbFnoj3MaoegG53wH3MTrEPJ1vdcmzsMoA9Ke5bGdsgXqoH4m2c6ULyf8TZ6BEElfX11Uo+LfN7ET7GEdxpMullfIk1oubEGC7hFvYLliyqQVR3sVNQb1Z6RFu+imV4N+k7WIpZ8sf4KDEfStiWbAdVBtUwksp2wS/YiuV4UeNx+gyWYINKjT/KxNucbPsytpX1AFRrEX/ivKjpCVxItruZ506KHXsWfzcAcBEjswAG6wBoVpekWJ9V2bcn+6Eq+6YsgL4Okx9OcWYJjsj6BnAgZ5G3RPkQM/7zBqWopUN4JcX5Jsd/Q4Ubqn27ygDKPDAjbdn5FgB8mt5dWsPfm/zf5fgGMb0gWuMD8VVbloniq3gGpgiSGZfA3k46IHq8XxzCeYbLbcELK/BEjv8rKlt/GlvwmqpT2kAmiPnfzvnpr6bjsgyJUvwh6PhOWk0J4/FU0l78rHKHaFVO0RkdX8LcNhI/lEJC0UmQEn4XRJSVD/F9MwFmpwCdDqOyFrHb8LLWldFYJz4w2018T5DOwlYS512fXhB3hfLBel4cvHHJXxQt+JdgvfLl9KgOLqePTP4HSaPtm8wKJM8AAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAOwAAADsAEnxA+tAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAA7dJREFUWIW9l11MHGUUhp/zLexCsWjiFqEaiTag/BkbS2PKT7hRTIyUQkk0xhATE//axMSL2lt7Uy+MMdEQiOmdegEI1ahpjErlxxaK1cCWxJA0hWhTgm0lUGCX/Y4XLGZnw+zObK3nZnbO9573PDO7c+ZbwUc0NTXlLMbCLaCHEGqBBxJL88AEKoMVu+V0b29v3KuneBVWNbQ3i/IBUJFBegm1b02PDnznxTfgRVRTf/gYcAoo8iDfhciLRaWVqwtzM2O3DVDd0HYUeB8fdyuhfaqotHJhYW7mQiaha1Q2tu81lnEgZ9ti5SLIgDU6Hojbi9aYcaA0SRJTbG1kZOA3tx4mHYBRTro1B7Dw1dRo34nIcP+Z3PVdN4AbKZJcQ+Bk2h5uC9UHWvegPO1IChNxQzlwBUCE49V17fsAJid7YppjGgDHLVf0mccaOx7yDaBinktJxRHbaf4yV4ClRC4X4bPqA617quraBwvXiaO8DmhyobW2xTcAIo7HTVTOTv80MCP3xN8GapKWyjCBWREOLuXEj06P9l9Q+CHlclwfXVcAI1LssEAnN7nkoCuzlWcTHyec12JKfAOo6obTnegmCCFXANiZODomoVVd9g0gInMpmcrE8Xu3GjVsrVU5vfjTN4AqKY20uaKhrWQtFnoXuLxNyeW1aN6JR+padgPNjko45xugokS+Bf5ISu0wVrpmz5cta9zsA+1m8yU0D9othJ6YPV+2HDTBLiA/qe76SmDn12590k7CmvrDLyt6ypFU+qO5wdd+H/p8MTld3vRCOLgR7QbaUhq8OjXS35MVAEB1ffuXQOpMWBJhwFr9FcAYeVyVQ0Bhim5weqS/jZS5kByuY3YrQnnmpfU1ew54NCldqEqniHQC6Hb2wuxaNK8zXfNNmYeoauioFLXjQIEXPWDV6pORsS8mMgnTvoy2IjLce0mUdzw2B+jz0twzAIC9aXpIvIQyu8qHXn09A0QivVFUP/Ug/TtsFsf/cwAANfJLJo0gPw8NDW1k0mUFgNXrmSSKuo7d2wcwEswsktRdUdrIOAccETfX1Nj30kkCgqfteFYAkfs7pvKLg8+jPLytQLm6GoyOMdx3ZwAKwqEqq3rMVSBQEAt+swJn7gjASlfLVP6R08cV2XaTaeDaSnj9Rz+efv5s/Bs73hx8xYrUOpqrTtz6uPUTv17+foSJUOENQfem5PYD/w/Aavju/XddXS0nFLsPwEZjN28V3zuVjVdWXwFA/pHBs0Bj4nR+9aPWB7Px+Qe45z3icmz7mwAAAABJRU5ErkJggg==",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAALxSURBVFiFvddNiJZVFAfw33WmGVHQN4QJZzCwUFJmNZGLWmnWwleImAaJCNJFS6GFC0HaCQWu3BVBDC79ohZuLQqC8qMMWihCm7IvUV6FqfHjtrhHfJSZ533ejzpw4Nxz/s85//c+95znvnLOuilm8UkTbOA/xvpG2AbJnsJfONADgbV4Hk8Pg8Bn+BYjWI0jNdgjWB32R/h8IALYiIyZWB/FlRr8FRwNezvuYsMgBN7AjbAncQ/bY93G16HtStF7mKwQeq+uxgr1MoPvw34BnZzz2ZTSOObxHb7BfEppPOd8Fp3APiAwUVegG4Ep/Br2JK6FvQXr8AE+DHtLxK4FFm5hzSAEfsR0xd6UUlqJS8qv+wJfhn0pYpsCCy0s1FbocgZ2YBFjWIXfcDBirVhfx5PhOxi+VaELeGWQQ9jC33gn1rNYqMyH+0qXbAjfAmbDfhO3MT7oHDiAGx6e7AddsBe/h777WGwi/Ie75U/xwLKSUlqBr/Bzzvmtiv9E/ELxCl6rxI7hObyYc75TW6AbwyD4DPZX1k8o7TYX+shWYz+ebZS7CWgJQi/jjjLz14Zde9j6HUTLyW78pIzqjWG3+8rU5w5cVk5/VS/3lauP4puj4IzSpq2wMzb/H6+gjas55ws555uhF3C1n9fQD4HdOLOE/0w/BLrOgUfAKa1Rbkd3lRFdlTGMYl3O+VbTnKONqxd5Ff/g7WXixwJzsmnCXnfgU7Ryzq8vEz+NmznnvU1zNj4DKaWEXbidUhpZIj6iTMRdgW0mPbTfNqXVOspNaLoSmw5fJzDb/os2bOMHbFW++edTSodSSodwPnxbA9O8G3rYgXMqn1cc9nAKPu4/N9RJiPXK5eMlpXPeV9rwZOhi+EYDc9+w/hkFgX3K1WsGF/En9lTie8J3MTDXsW+YBE4pt6JFHMfEEpiJiC0G9tRQCCgTroM/MNcAPxfYDsaGQWCnMnqnejiwU/HMzm7YJm04gvmc8y8NsCCwJ+LZWvkX2bbHfJK7cP4AAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAABFQAAARUBKX4CGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAIwSURBVFiF7Zc/aFRBEMZ/c6cGMWBlZThJKVpZKFcIKTSFVukOLIO1FhZaia12toqgEgjYiXiihRJQUCEW/ktjE6MWNho1inB+Frfv3tzF2+w7trDIwPLmzftm9tvZfbO7SEISwDjwPLRxZ78IrABHCttGDWgCl4HHwCrwGrgJzAK1PqxzmgEU2oyz/w62GwkdjwGXgI6LNdgWgMnCp0YpY0P0reG5jYiYmQH3gDPQi/sRaIcM/Am2w8ALM5vEAXPIKWAq6O+BaUm7JR2TtB/YEwgC7ASum1nNp69FmaaWsxe2+UjqdwFrAfcVaESwbRdzNlcGDgLbg35B0nIEe5JyOqa2mFkrvDQdqNmd0j5pOOyTgU4OOP1+jKmkFTN7C+wr/Iat1lhrDaR1zn2rJ/wttwK2k2sK6m6EnQR8ganl/AtGkhrlgqgiKaNMJvBuBL9RfIYSeFnRpwO8yUngakWfOUm/shGQ1AbuJOK/AWdzdQ7lXnACeLAB9jNwVNKn7AQkrQLHgXOsX2DfgWvAIUlPc3ZO6HywShlwm7Ky7UiobPMFPvHA0sOvK0TqItbc+4/so3byX1TCTQKbBP4lvd3OzOpDMF4KTOWddRiBJafvTYhTYJaiqAoEFp0+HQtgZhOOwGIMW4XAM+Bn0M+bWSMS44qL86gqgVi5PE1ZjpfpbkT++wT9Z/wFBu59KaU4BjLgIf2n4Q/AXeAV/fe/L7j7XhYCo142sxJwDsnX7aoE/gLat4pdMMPkqQAAAABJRU5ErkJggg==",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAADxAAAA8QE5DAzkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAxBJREFUWIXt1l2IlWUQB/Dfrua22i4dyiJbugi8CJT0RtqgLEz0ouw6EUS6686ooG4M+jAEieyDIohg28AoMim1gsCUPgnL8kJhJSg1tdDdzGW3VruYedu3t7N7zlk3IvAPD+c988wzM+87/5l5uIj/GG0t6rdjKZZjEeam/CT24UPsxvnpDqAda7ABf+AdfInjaeMqLMHdqfsoXse5ZgOZDFfjA3yDFQ1027AS+/F+BnZB6MEhbMGsFs514DkcxLVTdd6Jr/H4VA3gScGNzqkcflrkusqT2bgfn+KXXJ9gfR1HbXgXm5tx2Cs+9UvoxwhWV3QW4nAavUukqAer8B4GsKByZk3a6k/bW3BTsTkjf+fjI0G2Afwkcr8DP6fOdfgYj+GB3B/KdVCwfgSv4I2UE1XzOw7gB4zhebyFU0Ug67A1n18Qn7mKN/FUHXkVmzKAKmanbbm/Dmam4JKMEtaKRvJr/t+Zb7iiONQAT+CIKN8bRFlCV9q+D6PpU3sTBkcFPz4vBTUZBlO3V6RkUsysIxvBRvxYkt1rnAvN4CSuwDZRLQRZ72kmgEtFrn/L/9tFy22lofQIIt8pqgXmpO2GAYzhW+MM/V7kdKvI6/EGzq/BYuzNQL5KeU3MiroBDGeERJk945/56xMMX9sggE14VXBhUJQf0Z6L4TQnff7V5eaJtrtd5LoT16exPalzuSDXNjwivlT1ZTaKz96L0ym/BQ+JBjaMK0XjuhHHiio4Ksbpd+LTD2JZBlbgNG7DzWLarRd3g6WiNe9PG7eXnBPcWZY2T6WPJTimAR4UDO6oyNvyDfrwRa4+Qbbq3OjAZ6JztowZohW/Zrxll1Gb4Ll8vl/MjXrnm0K3YPMOwYGu0t4QLss1VJJ3ZUA7xewon5kSOvAyTuBsST6ajmr5XGA4dV/U2iWmIZb7+x1vogDO4Y7pdFygW4zWmsjtGN7ONZayWup0N2u0XiecCOcFy89gl5gVA7l3SJTXmdSZ9mt5gQE8a7w5VXGrGLfz/60AFoluN3eC/RN4WFzhL+L/gT8BnVe7IGqPjuMAAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAJ8SURBVFiF1ddLqI1RFAfw33XI9czr5i0pyePGREQZMDCh6HpEkhhgSJFMDEiZKQyIEqI8BihlJCVuRPKIMPCWR3kTuhis73YP7j3fPscx8K9T+9trf3v9z9pr/df+CvLRHoPwNnvuibHZfPNcd3TDp4T9ysYW7EYdDuE1LuA+rmICZmbjjtV2PhQv0Q/nsRWdi+xz8QwjcAorqk1gLbZhHs5kc0txEbvQBauwH9NxttoE9mExdmI5RuIhJuEwNqA/nojceF2ug/Y59nZoQid8yQhcEscxAPOz+dpsr0K5BNrl2O+gHo2YgRr8yGw/sueZ4kjG4G65BPJQj0fonW1+GkczWwOuiSQdjyNYX66DvAhcF4k1C9NEvRdjIBbhASZie7kEUlDQQrTBrxE4WrSuQyWb50WASMLvbdiGiPB3wLdKCORVQR4GCJUcJpSwUVRIoxCoXKREoBQaRV9YJqLwFmvwFPewMm+D1AhMxnB0LZq7LpSQOKbH2IRbWIiNOIgXOJbop1UsEKV4AO/9mnjNqMtIErpwMBsvwd6/cQ47sC4bL26DQDEKWsr190r5Ayk5sEc0nF54LrS/FJrwLmHfZAJXst8UXMZo9El1UA0C8EY0nFeiC26uFoHUKvii5VxXY2rie3X4WGpBagQuYbbofh9wIvG9etxMXFsStSIPdkjX/BpxeRlVDQLQA8dxLhvnYaKEf1+OFL8RbfmiFqEphTnyNaMiFITOj81ZdzthTUXNqEkcw7gSa2oxGDf+BQFC6X6/HRWjCz4Lsv+EQNXw3xL4Kj5G20I3oZ65qPRKdgwnxU25tfvicPG5louaCglAX9EZW4vic3FjysVPugJ6yM0c9UwAAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAALlSURBVFiFvZc/SBtRHMe/9969l6NDr4RiyZDJhiIcglIlQ8Wp/sGhkxScg4LgIC4ZsygOzgUJziGbVhFRRMduSoqH0gwdk1Yql4unyeXyOtSGtMmZy+n5ne693+9934fH+/3uToJHzczMxAB8AvAOgNIcE0KgWq3CNE1BKf0VCoU+bm5uHnrxlbwkzc7OsnK5/BXAm/vyKpUKDMMApbRaqVTU4+Pj207esheAUqmkEUIamxNCkEgkEI/HwTlHoVBAMpls5DuOw1VV/QAg28mbeAGglIabx6lUCqOjowiFQpAkCZFIBOvr6w24O0W9eHsCaFYkEkFvb2/LvKIo0DStW7vuAfr6+lxjIyMjwQOcnZ25xo6OjoIHKBaL0HW9Zb5cLuP8/Dx4AABYXl7Gzs4OLMuC4zjI5/OYm5vzY+WtDNspk8kgk8n4Xf5wAM45JicnIYTA/v4+bm879pzHA+jv70cikQClFAAwPj6Ovb09bG9vd+3V9R2QZfmfzf9qYmICiqK4rHpEAE3TWjb/q7GxseAB6vW6a8xxnOABdF1HrVZrGzs89PQGfhhArVbDxsZGC8Tu7q6vSvBVBblcDktLS5iamkK9XsfBwcHTliHw5yS2trb8Ln8YACEECwsLGBgYgCzLuLq6wuLiouvduNfLD8DKygqGh4fBGIMkSQiHw0in036sugeIRqOIRls/djjnGBwcDB4gFou5xoaGhoIHyOVyrrEn6QOXl5c4OTlpmTcMA/l8PhgAQkixeby2toZsNgvTNGHbNk5PTzE/Pw8hRKNV27b9zYu3px+T6elpyhj7AuDtfXk3NzcwTROyLF/H4/EXqVSqY122f639J13XhaZpnyVJegngGQCDc/6cEEIopaCUOpZl/bQsi8my/J1S+j6dThe8eHs6gXZaXV0t2rbdAwCMsR/JZPKVHx/frZgxViKE9AAApbTk18c3gKqqFwBe3w0v/Pr4asUAIIS4bvfcrX4DiID/ZxGPD58AAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAGkSURBVFiF7ZfPSsNAEMZ//j0KpT0JgsWDT9E+glXxqO9gUNCb9OahPotvUOtLSFFBLxqVtB6tLUQPO6HLukm2SSSXfjCkM7vzzXR3dpaFkrGQ0e9Hvm35XmTlW8yYQGHIuwK5+UpfgbwJtJnWQSkJlIImqgZs0vzPwHXgNiF4JD2ZWygaQGAEegGugA7waowF4pMKlxqoA9dAVcj7Yl+RwD6wJLZHmVMVn02XJNLQE9IQOARaxC//nswJRb/JG1wvuAdgHxglJDCSBPuazWkr0grLFsgD1oCK/B5n4HFOwAfeNN2z/AlPGx8C77MkcCkSDZh6BzjV9IolgZo2fiI+cXyRzrI4fxpkpo6Rse3SWbXY4vj+8LtswVDTjy1BzrXxD/EprAZMGaP2vAasA2fAdwaeVOjH8A7YIfkYfgG7wL1mczqGSegJUdSIDhISaAFHTBtRN29wUK04ugdCpu3WR22DBwzE1teCBzi0Ype74AnVYgeo6t8SewhsiEzEti1zBqiteHbgd0Yd1dvTCqtLQZdQHBoJwWcuuPm7YP4umL8LSscvBSYDC6c/qAEAAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAALxSURBVFiFvddNiJZVFAfw33WmGVHQN4QJZzCwUFJmNZGLWmnWwleImAaJCNJFS6GFC0HaCQWu3BVBDC79ohZuLQqC8qMMWihCm7IvUV6FqfHjtrhHfJSZ533ejzpw4Nxz/s85//c+95znvnLOuilm8UkTbOA/xvpG2AbJnsJfONADgbV4Hk8Pg8Bn+BYjWI0jNdgjWB32R/h8IALYiIyZWB/FlRr8FRwNezvuYsMgBN7AjbAncQ/bY93G16HtStF7mKwQeq+uxgr1MoPvw34BnZzz2ZTSOObxHb7BfEppPOd8Fp3APiAwUVegG4Ep/Br2JK6FvQXr8AE+DHtLxK4FFm5hzSAEfsR0xd6UUlqJS8qv+wJfhn0pYpsCCy0s1FbocgZ2YBFjWIXfcDBirVhfx5PhOxi+VaELeGWQQ9jC33gn1rNYqMyH+0qXbAjfAmbDfhO3MT7oHDiAGx6e7AddsBe/h777WGwi/Ie75U/xwLKSUlqBr/Bzzvmtiv9E/ELxCl6rxI7hObyYc75TW6AbwyD4DPZX1k8o7TYX+shWYz+ebZS7CWgJQi/jjjLz14Zde9j6HUTLyW78pIzqjWG3+8rU5w5cVk5/VS/3lauP4puj4IzSpq2wMzb/H6+gjas55ws555uhF3C1n9fQD4HdOLOE/0w/BLrOgUfAKa1Rbkd3lRFdlTGMYl3O+VbTnKONqxd5Ff/g7WXixwJzsmnCXnfgU7Ryzq8vEz+NmznnvU1zNj4DKaWEXbidUhpZIj6iTMRdgW0mPbTfNqXVOspNaLoSmw5fJzDb/os2bOMHbFW++edTSodSSodwPnxbA9O8G3rYgXMqn1cc9nAKPu4/N9RJiPXK5eMlpXPeV9rwZOhi+EYDc9+w/hkFgX3K1WsGF/En9lTie8J3MTDXsW+YBE4pt6JFHMfEEpiJiC0G9tRQCCgTroM/MNcAPxfYDsaGQWCnMnqnejiwU/HMzm7YJm04gvmc8y8NsCCwJ+LZWvkX2bbHfJK7cP4AAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAABFQAAARUBKX4CGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAIwSURBVFiF7Zc/aFRBEMZ/c6cGMWBlZThJKVpZKFcIKTSFVukOLIO1FhZaia12toqgEgjYiXiihRJQUCEW/ktjE6MWNho1inB+Frfv3tzF2+w7trDIwPLmzftm9tvZfbO7SEISwDjwPLRxZ78IrABHCttGDWgCl4HHwCrwGrgJzAK1PqxzmgEU2oyz/w62GwkdjwGXgI6LNdgWgMnCp0YpY0P0reG5jYiYmQH3gDPQi/sRaIcM/Am2w8ALM5vEAXPIKWAq6O+BaUm7JR2TtB/YEwgC7ASum1nNp69FmaaWsxe2+UjqdwFrAfcVaESwbRdzNlcGDgLbg35B0nIEe5JyOqa2mFkrvDQdqNmd0j5pOOyTgU4OOP1+jKmkFTN7C+wr/Iat1lhrDaR1zn2rJ/wttwK2k2sK6m6EnQR8ganl/AtGkhrlgqgiKaNMJvBuBL9RfIYSeFnRpwO8yUngakWfOUm/shGQ1AbuJOK/AWdzdQ7lXnACeLAB9jNwVNKn7AQkrQLHgXOsX2DfgWvAIUlPc3ZO6HywShlwm7Ky7UiobPMFPvHA0sOvK0TqItbc+4/so3byX1TCTQKbBP4lvd3OzOpDMF4KTOWddRiBJafvTYhTYJaiqAoEFp0+HQtgZhOOwGIMW4XAM+Bn0M+bWSMS44qL86gqgVi5PE1ZjpfpbkT++wT9Z/wFBu59KaU4BjLgIf2n4Q/AXeAV/fe/L7j7XhYCo142sxJwDsnX7aoE/gLat4pdMMPkqQAAAABJRU5ErkJggg==",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAADsAAAA7AF5KHG9AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAaRJREFUWIXtlz1Lw1AUhp9YwdJ/oKAF3dTBxb2uLl2cxC4dnKWibjbd9A/obCctol3ci2C79ZcUhXbRgq1DbuA2OWluYuIH9IHLScI573nv7Q03hX+ABeyqGIYNjIVhBxXMhQhmgTrwBDSAnIGJSEwzsAQ8Awfqfg94AVYMdC1PjGXgENj2PNsCygYGxp4Yy4CLpQ1Tap4YyLyBmDSLgho6LQOtWAYuteszzUB1Sk3VE0MNFJicUUu7fgsTEbBwVs6NRgaC3F7EMCBtQtvTowbYJpswVWYGZgZ+3cCfwEY+w39i2KnPzpQ6fne3Ceo3BP0bPaEoJHwAywk0zwNDQb+oJ2WBvpB0n4CBR0G3r3pOsAOsqzjSko++0fxY0xnhHHpuj6lca4WfMU1UVK2rcxWleAFoM7lsD5jtiTz+ZW8rzUic4v/thjhvxz6wivOZngPW1LM75A13ErX5IjAQhOKOgdL0EXQWvAJN7f4d6EaYQFfVuDSVZmRKOK+M+19gE+ezqoN/lh3gHNhQuWVVW4rTWEfaPBnBQMawNjF6WvNemo1S5QsDcrCaa0K/EwAAAABJRU5ErkJggg==",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAKaSURBVFiFzddLqE5RFAfw3+WjPLr3uhnelNfMgBHKwOAayCMpIwbEgJJMRFEMFDKTuh4T0h2YySN5lYEBSmTqUaRM5HkN7oDPYO/jbvue853v8/7XrrPXXvu/1t577bX2oTX2oJm0FVHejWnoim1alIk6hf6hGn7jasbf4zk+ZvI7eBuNdsfvO5nOxyj/JQdOYDaG6ohKMISjv+rAH0ejRDYd8zNZf8X8pcn3VAwkc/tjP8UjvKlzKg2ivBVB+LiFTqtWzP+O/+IIdgsRO/iXbW8Tr24TL5KBv3UEL9AsgrAPJ+P3jN+zwFJsx+rEJi287WQHbghbuhcvO+FsYFnm5UIc7GBVZ7EpEhJi6aHyndyHe3WE7cTAIuGOD6A3yo5jY/zOa0jlNWxgCi4ksul1HuJu1p+AdZiMM6prwEHsTPproKfC27L2Gs+ytjGSTREqI1xrk6+HkIxmJW1zBw418RlbItlsnG6huzmzNa4RB94l2zJcsX1VmByNnm5Ddziz1aT9I/ginO3PJKDKI2hE4geJVz2Yk3l+CruElL0A5zG3jRXneIoPSf9LV4nSClxO+vexWAiwfiGFzlf92Cgr5wVW4koqaBhbs/PJt/AVB7Af84S6niewqgXk3CO5AzcqlAsUj5GLmIQnwuNjeab3AddruEozbBOfhGJ0Elf9GCgjQuYr0CWk2zygHsfxVpn0amLnU5Rpqi/HIzgXV3C3grwdB0rL8R5jn905JmJDjU6nOIzuBo78ZuJ2Mch/8ibMcc/YK7YDq4zW81OYKVYzP1bTApdwLJM9aseBN7iZydYmBDeN1ovbJfMLvCrhGYN/fgR1DmwVav76n+BeH+dubaVUdgQpeoW6nWMJxhu9vn1CUUtR/Dn3aoFvXyMrtvFgdDgAAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAADxAAAA8QE5DAzkAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAxBJREFUWIXt1l2IlWUQB/Dfrua22i4dyiJbugi8CJT0RtqgLEz0ouw6EUS6686ooG4M+jAEieyDIohg28AoMim1gsCUPgnL8kJhJSg1tdDdzGW3VruYedu3t7N7zlk3IvAPD+c988wzM+87/5l5uIj/GG0t6rdjKZZjEeam/CT24UPsxvnpDqAda7ABf+AdfInjaeMqLMHdqfsoXse5ZgOZDFfjA3yDFQ1027AS+/F+BnZB6MEhbMGsFs514DkcxLVTdd6Jr/H4VA3gScGNzqkcflrkusqT2bgfn+KXXJ9gfR1HbXgXm5tx2Cs+9UvoxwhWV3QW4nAavUukqAer8B4GsKByZk3a6k/bW3BTsTkjf+fjI0G2Afwkcr8DP6fOdfgYj+GB3B/KdVCwfgSv4I2UE1XzOw7gB4zhebyFU0Ug67A1n18Qn7mKN/FUHXkVmzKAKmanbbm/Dmam4JKMEtaKRvJr/t+Zb7iiONQAT+CIKN8bRFlCV9q+D6PpU3sTBkcFPz4vBTUZBlO3V6RkUsysIxvBRvxYkt1rnAvN4CSuwDZRLQRZ72kmgEtFrn/L/9tFy22lofQIIt8pqgXmpO2GAYzhW+MM/V7kdKvI6/EGzq/BYuzNQL5KeU3MiroBDGeERJk945/56xMMX9sggE14VXBhUJQf0Z6L4TQnff7V5eaJtrtd5LoT16exPalzuSDXNjwivlT1ZTaKz96L0ym/BQ+JBjaMK0XjuhHHiio4Ksbpd+LTD2JZBlbgNG7DzWLarRd3g6WiNe9PG7eXnBPcWZY2T6WPJTimAR4UDO6oyNvyDfrwRa4+Qbbq3OjAZ6JztowZohW/Zrxll1Gb4Ll8vl/MjXrnm0K3YPMOwYGu0t4QLss1VJJ3ZUA7xewon5kSOvAyTuBsST6ajmr5XGA4dV/U2iWmIZb7+x1vogDO4Y7pdFygW4zWmsjtGN7ONZayWup0N2u0XiecCOcFy89gl5gVA7l3SJTXmdSZ9mt5gQE8a7w5VXGrGLfz/60AFoluN3eC/RN4WFzhL+L/gT8BnVe7IGqPjuMAAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAALxSURBVFiFvddNiJZVFAfw33WmGVHQN4QJZzCwUFJmNZGLWmnWwleImAaJCNJFS6GFC0HaCQWu3BVBDC79ohZuLQqC8qMMWihCm7IvUV6FqfHjtrhHfJSZ533ejzpw4Nxz/s85//c+95znvnLOuilm8UkTbOA/xvpG2AbJnsJfONADgbV4Hk8Pg8Bn+BYjWI0jNdgjWB32R/h8IALYiIyZWB/FlRr8FRwNezvuYsMgBN7AjbAncQ/bY93G16HtStF7mKwQeq+uxgr1MoPvw34BnZzz2ZTSOObxHb7BfEppPOd8Fp3APiAwUVegG4Ep/Br2JK6FvQXr8AE+DHtLxK4FFm5hzSAEfsR0xd6UUlqJS8qv+wJfhn0pYpsCCy0s1FbocgZ2YBFjWIXfcDBirVhfx5PhOxi+VaELeGWQQ9jC33gn1rNYqMyH+0qXbAjfAmbDfhO3MT7oHDiAGx6e7AddsBe/h777WGwi/Ie75U/xwLKSUlqBr/Bzzvmtiv9E/ELxCl6rxI7hObyYc75TW6AbwyD4DPZX1k8o7TYX+shWYz+ebZS7CWgJQi/jjjLz14Zde9j6HUTLyW78pIzqjWG3+8rU5w5cVk5/VS/3lauP4puj4IzSpq2wMzb/H6+gjas55ws555uhF3C1n9fQD4HdOLOE/0w/BLrOgUfAKa1Rbkd3lRFdlTGMYl3O+VbTnKONqxd5Ff/g7WXixwJzsmnCXnfgU7Ryzq8vEz+NmznnvU1zNj4DKaWEXbidUhpZIj6iTMRdgW0mPbTfNqXVOspNaLoSmw5fJzDb/os2bOMHbFW++edTSodSSodwPnxbA9O8G3rYgXMqn1cc9nAKPu4/N9RJiPXK5eMlpXPeV9rwZOhi+EYDc9+w/hkFgX3K1WsGF/En9lTie8J3MTDXsW+YBE4pt6JFHMfEEpiJiC0G9tRQCCgTroM/MNcAPxfYDsaGQWCnMnqnejiwU/HMzm7YJm04gvmc8y8NsCCwJ+LZWvkX2bbHfJK7cP4AAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAggAAAIIBsKhZvgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAGJSURBVFiF3ZexSsNgFIW/W7SL0I5VcPEBrA8gIjhUBHFwtbh19wUcRHDp1E1K+gq17eImiIurOnUS1LXdnBSuQ65QYjD/n7SNeOCQ5vw3J4c/3JtUVJU8IcA2cAqszvneb8A5wBDQnDgU+5EWXTseZvBInb4DLBs7GXxSXfQKlIGBsWza3ALsAvWJ87ppcwkQACvAGLgyjk0LZh3gBSgBfTs/MappJatx9izghwZwYIziW2/4GPoECIAHoPVLTctqAp8QPlvfi+i3xkmth9+jcCqqAUcxelwAtdratAK0CYfNKGbt0hjVR3ZNO8nfZRRvAvv4j9suYWfcJxUm7cAFUFRVfAgsAmdJ/llfRpnhOwf+XwAhHKW54U/swAewkNP9PwuEvZoX+gIUCd9ieXwVDyTN/wIRqQI7EflGVR+9zXwnnAVu8nOqNdN4pe2CDUctEWkDVB216QcQkQpQiVmq2JoXnPtfRJaALWD9l7JjEXkC7lT13cnXtQtEZA24diqGPVV9nmqAWeEL2jW/y4GUuo0AAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7QAAAO0Bq2+TWQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAIXSURBVFiF7dZNiI1RGAfw3+E2GhYSmhqTNL5DycJKmVEkmRgjicUslOyFNWU7pdmILJQsyGwsTISiZIGy8dUoHykfiQX5GONYvOcy887gTvfeuZv71NN5zznPc/7/85znOe8JMUa1lEk1Ra8TqBNAoVTDEEIH9lQY/3DJBDAfWypM4PgoAiGEZjSMYTzWWNkygkAIYQMuGTs3+qpB4DdQCGEuzqI7xhiKisZqAI8gEEJowHn0xxjPVBMwL8UjOIbZWD+R4FBI5bUP13AkhJC3mVxVArLygnVJJ1TGcw/8S4ZwE4/xBG/wDV/TfCOmpnYhujETsYCmMsHfY6csoZdgDlYlsB/DdCi1d2VRv45QwMEywO9hBw6gE/vxFB/RjHPYmPrTZJs9geXYhFnwOulbRNzGrtRGvEvzF1L/AXpl13ILbmAQa2OMioqVyX5ebrwTP3Eoxigk9rtlV+1SdMUY+0IIm3ERj3ALJ/ECM/xJ2HZMx2cM5KLTiEV4iO+5ucWYgu2wAG1psWe4kha/jJfpuwU9KRKxgnpVLjxtaZcxte1pvFWWRJUEjxgcQSCBhXQUYdhYb87xSxmgI3xDKc/yEEKX7F9RvCa3yvKmA/f/u0AmTbLKWIZ+WSl+GBWBv2kC24ZXeC7L/J5x+Lcmn4G0+6NYXVIEctFYgb2y6jgdY/w0Dt81ssjdiTGeIp1zLaXmr+I6gTqBXyeNKnoGUltEAAAAAElFTkSuQmCC",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA3QAAAN0BcFOiBwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAECSURBVFiF7Zc9CsJAEEbfiIKFlVpa2Al2dhZaWdhaegRv4AliK97DI3gHrW1s7MRasFkLE5j87BIwWZv9YGAzs5nvEZZhgzGGogBGwBkwFcYNWKR8HAC7is2TOGmfBnZ1HbVf1NcPLgCtIzCJY52pTVRcVT5S+cjWuFkS4GmMuQCISKqQ5OPaS5Xu6p2prXHZL1CbAsDfAVyHsKPWYxHZxOuB3qTyAD21nqsDO7e6OKbgm3oG0aXMIDoALSt1hcoBiMgKWPowzwGISBvY+zLPAQBbYPhPgJVP8yIAKdzlEcC7AkAACAABIAAEgCzAw4Nn2iNzGZ3x/b+r4zJq4t4z7fkBhDnTFmXTcAoAAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAB2AAAAdgFOeyYIAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAh9JREFUWIW910+ITVEcwPHPG8IoI5OZIpQNMVNWykJZyFgoaxaylrJmJzZ2/s5i1mqyYqfYSqOwUWSKSCw0C3rmRYaexb23d+e8e+f+e+NXZ3PO78/3/u7vnN851JcjuI59DXzUlhH8QhcdnBmU44M4i+ECvck4eHpMY12T4HuwFDt7hs0VARK7nXUBDmU4y4PIA+hiAVN1IW6XhEgDdHAzsPuDi2hVBWjhTgmINMCPeO40FgPbB6KCHThEFkAyPx/YzsfzlSFuBY5eYEsBANEX3w9sP1YFKIIYwfd47mmG3eXA7osa9ZA4y/sdkziP8ZT+etwN9L+KdlhtycvEaKA3iieB3mvsbhI8DVFUmOH6YysfZgOBeIOt8fq11PwM1g4yeBpiOoDoYDs24BxOrkbgEGJWf6HVSvdQDZthjAVz43hUF6KKbMNz+Y2oqIs2kgP4lBH09/+AOK538oWjrb8bvtR/TtSWC6L2mjhfwpUAoKV8Ky8ta/R/WRsnLG9G7Vi/laFfOxOb8DBw9gET8XoWQAIRZuKb3mFVSsbwKnAyZ3nTyQMgSvu7wP6tCpm4FBjfE51yackDmIiDNdqiU/gbj6uy+3gWwCnR5SQd9H1diL1WfvGkARZxIwiUXEqH9NfEnIbvhxAgHAs4ltLNKsyjqwUwhx0Z+i3RO7KLzyruirIAM4pTuwsbmwYnupT+1LsPDOxxWkUOi25B+5s4+QcROR95652yoQAAAABJRU5ErkJggg==",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAJmSURBVFiFvde7a1RBFAbwXzQ+AgoGBEkjFloYSGflBlRQfIDV/gVWVtpqI4mdae3sbERSbCfGIoWiAVEsNIVgrxFFIipExRCLmXFn79593az54HLvnNk757tnvnPO7AimteKZ/4c2X6M4vYUE2nyN9nhhChc26fQhljtN9iJQF1hXjco0xjZDABYxW5HALGq4JkSilMAv7MV3TET7FdyOzzdV24rkcAwXhUgewByO5ATe4DouYQEbmeOEQbcihR7WMiK3cB8zOYFHuIGrOIEPykO+iIbWSKSvLNp+ZuOxSPyephZ24jdswzrOYRdeChGYUY66EM7xeK93sOVYw2o23pETTiL8isvZj4oaKFtwrYctIdfA8/h8KE1uL3kBXkTHIwV7A/vjoiuYx9sS22T2zgT+xPl3uIv3ce5xP2lYxGqftoQyDRxPk4MSSNlQw5Km0lOG5OpPKGqAoLdvVQikBfN77qSXBhaxR0j5s1UINLAbr7KxaBuP9obWTMjrwDh+ROevqxBgOBr4h6oaKO53mS4SuvaSbQMSoHMdoFwDXVFVA/l+066LYjUcGgEG18BQCVTRQFdshQZmhAa3IZTgO9hXlUBD2OtcA8k2p1UXCamnjOCYcABaEPtQlQisZlc3W0IegadCl10XK2GnbphwsjCexHl8xhlB/WW2HE9wSojEYRzERyGKPY/lZRj0PJAwhaPxSngwzDrQqRck1DXLMiGL6oMQqGlvreloVbSllCwiL8uz9F8H8i/vB90i0YJ+CSwLR/dBMRQC6Z9NFRS3Il+rhqVeafhF88BZ5UqH1E8la61g/i+KvrW1UhXPNwAAAABJRU5ErkJggg==",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAJuSURBVFiFxdY5aFVBFAbgTwMaAmLEBSy1ECOCCFpoIUZwRbGysLVQECwUFYyKdlYSN7DQSkHsrOJWqFjYJCAuKGihhUvcsJEkkphrMXPN5HHve3lJXvzhwNyZM/P/M+fMmdukMdiJ41iKHgw1iKeUPEvs+lSSt+B9hYAM26ZKwNlIOIR7+BK/36C50eQrMBgJT8S+NiOncLqexaZhNy7iKObU8J+OJwnZrmQs7xsQkrImZqLL6Bh+xLIqc/ZV+BcJyPBI2FxV8vfR+Sf242H87i0RMQ/fxiggE062lDzd+QC2CMlzNxH1FHOTedcKSKoJ6EVrJfmsuHAmZO6N2P4ex5vxLPYdSOatx3AByck43lYwluFS5c5z8kGsE+J0FAejz+E43oOm2DcDr0oIhnAfX0vG/2B1LuBMQp7hZcURHU4mtif9HSWLF9WBIvu3mU+xYxNexPaGSHIsmfA2IV+EviqLF9WBIjuQClgVd94uhOBYhXNXIqCzxsK1ciC3D4yE4AuWF+w8vcM5LtZYuFYO5PaZkIS3ExHnSpz7MDsKWKJ6fMdiQ9ibV6WZuIWtqqMTh2J7DtYKL2C9GMZzo/PKMsV3OrVhITzTx0FaiLQubxYq3ljwEjfxTri+lbiDX/WKaRWSYiJxzW1jveQ5Vgn1fiLkg1g8XgGTIeLKRMhzrMSPcQroF17RCWMFXtdB3IELsf0bOyZDRBPWYA9OobuEvBcLhFs16SJStOBBAXkmXM1cxPlGimjGEVzFdswXqlomhGzhVIioRJmI/NHqN/LE/zcRlxstoJqIdgU/oo3CAiN/Vt1TRVokohuPazn+Bf2+fopUHBxtAAAAAElFTkSuQmCC",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAGkSURBVFiF7ZfPSsNAEMZ//j0KpT0JgsWDT9E+glXxqO9gUNCb9OahPotvUOtLSFFBLxqVtB6tLUQPO6HLukm2SSSXfjCkM7vzzXR3dpaFkrGQ0e9Hvm35XmTlW8yYQGHIuwK5+UpfgbwJtJnWQSkJlIImqgZs0vzPwHXgNiF4JD2ZWygaQGAEegGugA7waowF4pMKlxqoA9dAVcj7Yl+RwD6wJLZHmVMVn02XJNLQE9IQOARaxC//nswJRb/JG1wvuAdgHxglJDCSBPuazWkr0grLFsgD1oCK/B5n4HFOwAfeNN2z/AlPGx8C77MkcCkSDZh6BzjV9IolgZo2fiI+cXyRzrI4fxpkpo6Rse3SWbXY4vj+8LtswVDTjy1BzrXxD/EprAZMGaP2vAasA2fAdwaeVOjH8A7YIfkYfgG7wL1mczqGSegJUdSIDhISaAFHTBtRN29wUK04ugdCpu3WR22DBwzE1teCBzi0Ype74AnVYgeo6t8SewhsiEzEti1zBqiteHbgd0Yd1dvTCqtLQZdQHBoJwWcuuPm7YP4umL8LSscvBSYDC6c/qAEAAAAASUVORK5CYII=",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAJRSURBVFiFxZc9aBRBFMd/LyecCSpKQFDCoWIRUBQEBduDNAEtBRHBRmwC4geIlTYiBEKwCKS4KqUEUgjWKQInYiyEoBCLoIhFRI8YFITcs5h3Ouzt7N5MyOUPw9t98z7+u28+UVViGjABrAGaaWvARHS8yOQ1YCsneadtAbWYmHvoASJSAy4CV4ABUz8DWvZ8ELhtfdMi8hxoquqn0uAlXzwITAW+esSzGwn8jSlgMLkEwFzgV8/n2M4HbOeSCAB1L0gTuARUehgnFbNtev71FAKTXoDRhNky6vlPhuw6AyoPZ00uq+qH0sGUgfksZ2J1oYhA1eRmbHIPHd9qyKCIQF+w6wR6WojyICKHgaOeqq2q7/pCQEQEeAFc8NQN4GZsrNQSXM0kXwcepASKJiAie4GnGfV9Vf2eQiClBHdwu2IHm8AJEXkM/AJmVXVjRwjYwHuYUe8DHnnvG8BsrzFjS3AG2F/Qv4rblHpGbAne4Eb7eXs/Dhyw53VgXFW/7RgBVW1hU01EBoDPRuA3cFlVP8bEg+2thHXcQtQGrqnqqxybPxnZheSVELhu8q6qLgRsZnAkZ4JRCvbzRdxevpjTNwT8BKZjzwkx54EinAMWgHuJ/v+QVAJVXQKWtpsc+rAdi8jJXSMgIk+AVZPRBL6YPC0iwSNVQfIqcMtej6UQeG1yGLgRS8B8hjOxuiA2rbo7RA4BK8ARU70FXuJuPEWoAOO4mQLwFTilqj9yrUvO9mO4+1/oMlrWWsBY8tVM/9/7GsB73LJblrRttg28+2Oo/QVBeNMMSLnGLAAAAABJRU5ErkJggg==",
  "iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAADsAAAA7AF5KHG9AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAP1JREFUWIXl1j1KxFAUBeAvsRKrgSl0GxbW7sVxC4IbEARrXdAUFta6BWF0wAUo2iQgkpfJz7tPwQMHwkA4F/IVw39PNePdBS6a52u8zT9neGqs8dl03fxWLKtv423PSo0vsOk44BXLEgfcdYy3vY0eP8Z7zwEfOIkar3HfM972QRDILnipZgeZgpdqdpB98MJB7oIXCnIovDCQY+Clupo6PhZeqlsTQU6Blw3kVHhZQM6FNxvkecD4YJC54E0GmRPeaJC54Y0CGQVvMMhIeDtBRsNLdYvlHm5w+vObFMg+Diq8KPRvtiObGo+/NA5PFQ5xiaPC48+4Krz5B/MFwraJMUTLdBwAAAAASUVORK5CYII=",
];
