import { useEffect, useRef, useState } from "react";
import { Junction } from "../../../../data/junction/Junction";
import JunctionService from "../../../../data/junction/JunctionService";
import { AxiosResponse } from "axios";
import { GenericMapModalTitle } from "../../components/generic/map-modal/GenericMapModalTitle";
import moment from "moment";
import withLogging from "../../high-order-components/LoggerHOC";
import { useJunctionStatusData } from "../../../../contexts/junction/JunctionStatusDataContext";
import { JunctionStatusInfo } from "../../map-constants/module-status/JunctionStatusIno";

interface JunctionModalTitleProps {
  selectedJunction: Junction;
  margin?: string;
}

// hold timer outside the component as global to define and clear from different lines
var kkcTimerInterval: undefined | ReturnType<typeof setInterval>;

function JunctionModalTitle(props: JunctionModalTitleProps) {
  const { selectedJunction, margin } = props;
  const { junctionStatusData } = useJunctionStatusData();

  const [junctionStatusId, setJunctionStatusId] = useState<number>(-1);

  // KKCTIME(START)
  const [kkcTime, setKkcTime] = useState<number>(0);
  const kkcTimerShouldStartRef = useRef<boolean>(true);

  useEffect(() => {
    if (selectedJunction) {
      JunctionService.getKKCTime(
        selectedJunction.id,
        Math.floor(100000000 + Math.random() * 900000000)
      )
        .then((res: AxiosResponse) => {
          if (res.data?.result && typeof res.data?.result === "number") {
            setKkcTime(res.data?.result);
          }
        })
        .catch((err: Error) => console.log(err));
    }
  }, [selectedJunction]);

  // start interval after kkcTime is taken and set for the first time
  useEffect(() => {
    if (kkcTime && kkcTime !== 0 && kkcTimerShouldStartRef.current) {
      kkcTimerInterval = setInterval(async () => {
        setKkcTime((prev) => prev + 1000);
      }, 1000);
      kkcTimerShouldStartRef.current = false;
    }
  }, [kkcTime]);

  useEffect(() => {
    return () => {
      // clear interval if it is defined
      if (kkcTimerInterval) {
        clearInterval(kkcTimerInterval);
        kkcTimerInterval = undefined;
      }
    };
  }, []);
  // KKCTIME(END)

  useEffect(() => {
    if (
      junctionStatusData.length > 0 &&
      junctionStatusData[junctionStatusData.length - 1]?.id ==
        selectedJunction?.id
    ) {
      setJunctionStatusId(
        junctionStatusData[junctionStatusData.length - 1].status_id
      );
    } else {
      setJunctionStatusId(selectedJunction?.status_id);
    }
  }, [junctionStatusData]);

  const getColor = () => {
    if (junctionStatusId !== -1) {
      return JunctionStatusInfo[
        junctionStatusId as keyof typeof JunctionStatusInfo
      ].color;
    }
    return JunctionStatusInfo[selectedJunction?.status_id].color;
  };

  return (
    <GenericMapModalTitle
      color={getColor()}
      animation={
        junctionStatusId === 9 || junctionStatusId === 3
          ? "blinker 1s linear infinite;"
          : undefined
      }
      margin={margin}
    >
      <span style={{ fontSize: "2vw" }}>
        {selectedJunction?.id ? selectedJunction.id + "-" : ""}
        {selectedJunction?.name}
      </span>
      {kkcTime && kkcTime !== 0 ? (
        <span
          style={{
            marginLeft: 10,
            fontSize: "2vw",
          }}
        >
          -{" "}
          {moment(kkcTime)
            ?.utcOffset(localStorage.getItem("timezone")!)
            .format("DD/MM/YYYY HH:mm:ss")}
        </span>
      ) : null}
    </GenericMapModalTitle>
  );
}

export default withLogging(JunctionModalTitle);
