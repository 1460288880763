import { PermissionContext, PermissionDataProps } from "@icms/ui-components";
import { PropsWithChildren, createContext, useContext, useState } from "react";

export interface FCDContextState {
  visibleSegmentBasedSpeed: boolean;
  setVisibleSegmentBasedSpeed: (isVisible: boolean) => void;
}

const FCDContext = createContext<FCDContextState | undefined>(undefined);

export function FCDProvider({ children }: PropsWithChildren<{}>) {
  const [visibleSegmentBasedSpeed, setVisibleSegmentBasedSpeed] =
    useState<boolean>(false);
  const { hasAccessComponent } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  const updateVisibleSegmentBasedSpeed = (isVisible: boolean) => {
    setVisibleSegmentBasedSpeed(isVisible);
  };

  return hasAccessComponent("FCD Module-READ") ? (
    <FCDContext.Provider
      value={{
        visibleSegmentBasedSpeed: visibleSegmentBasedSpeed,
        setVisibleSegmentBasedSpeed: updateVisibleSegmentBasedSpeed,
      }}
    >
      {children}
    </FCDContext.Provider>
  ) : (
    <>{children}</>
  );
}

export function useFCDData() {
  const context = useContext(FCDContext);
  if (context === undefined) {
    return {
      visibleSegmentBasedSpeed: false,
      setVisibleSegmentBasedSpeed: (isVisible: boolean) => {},
    };
    //throw new Error("useFCDData must be used within a FCDContext");
  }
  return context;
}
