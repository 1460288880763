import http from "../http.common";
import { JunctionPhase } from "./JunctionPhase";
import { SetJunctionPhase } from "./SetJunctionPhase";

const get = function (junctionId: number) {
  return http.post<JunctionPhase[]>(`/web/junction/phase/list`, {
    junction_id: junctionId,
  });
};
const getPhaseByGroup = function (junctionId: number, groupId: number) {
  return http.post<JunctionPhase[]>(`/web/junction/phase/getphasebygroup`, {
    junction_id: junctionId,
    group_id: groupId,
  });
};
const add = function (data: JunctionPhase) {
  return http.post<JunctionPhase[]>(`/web/junction/phase/add`, data);
};
const setPhaseTime = function (data: SetJunctionPhase) {
  return http.post<SetJunctionPhase>(`/web/junction/manage/setphasetime`, data);
};
const update = function (data: JunctionPhase) {
  return http.post<JunctionPhase[]>(`/web/junction/phase/update`, data);
};
const deletePhase = function (data: JunctionPhase) {
  return http.post<JunctionPhase[]>(`/web/junction/phase/delete`, data);
};

const getPoliceButtonModePhase = function(junctionIds:any) {
  return http.post<any>(`/web/junction/phase/getPoliceButtonPhase`, {junctionIds:junctionIds});
}

const urgentPhase = function(req:any) {
  return http.post<any>(`/web/junction/manage/urgentphase`, req);
}

const lockPhase = function(req:any) {
  return http.post<any>(`/web/junction/manage/lockphase`, req);
}

const finishPhase = function(req:any) {
  return http.post<any>(`/web/junction/manage/finishphase`, req);
}

const JunctionPhaseService = {
  get,
  getPhaseByGroup,
  add,
  update,
  setPhaseTime,
  deletePhase,
  getPoliceButtonModePhase,
  urgentPhase,
  lockPhase,
  finishPhase
};

export default JunctionPhaseService;
