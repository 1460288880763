import http from "../../../../data/http.common";
type TimeRangeType = "day" | "week" | "month";

const fetchStatisticData = function (
  startTime: number,
  endTime: number,
  junctionId: number,
  timeRangeType: TimeRangeType,
  dataType: "loop" | "detector",
  interval?: number
) {
  return http.post("/web/junction/statistic/statisticdata", {
    startTime: startTime,
    endTime: endTime,
    junctionId: junctionId,
    timeRangeType: timeRangeType,
    dataType: dataType,
    interval: interval,
  });
};

const VehicleDensityService = {
  fetchStatisticData,
};

export default VehicleDensityService;
