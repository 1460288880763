import React, { useEffect, useState } from "react";
import { useQueueLengthData } from "../../../../contexts/junction/QueueLengthContext";
import { DirectionQueue } from "../../../../data/fcd/AllDirectionQueueLength";
import { LatLngExpression } from "leaflet";
import { t } from "i18next";
import { Polyline, Tooltip } from "react-leaflet";
import withLogging from "../../high-order-components/LoggerHOC";

interface QueuePolyline {
  junction_id: number;
  direction_id: number;
  queuePolyline: CustomPolyline;
  totalPolyline: CustomPolyline;
}

interface CustomPolyline {
  color: string;
  coord: LatLngExpression[];
  tooltip: {
    isActive: boolean;
    content: string;
  };
}

const polylineClass = "leaflet-polyline-glow-effect";
const polylineWeight = 5;

function QueueLengthLayer() {
  const { queueLengthData } = useQueueLengthData();
  const [queuePolyline, setQueuePolyline] = useState<QueuePolyline[]>([]);

  const addQueueLength = (queueLength: DirectionQueue, junctionId: number) => {
    let length = 0;
    let secondPolylineStartIndex = 0;
    let firstPolylineCoord: LatLngExpression[] = [];
    let secondPolylineCoord: LatLngExpression[] = [];

    for (let index = 0; index < queueLength?.segment_list?.length; index++) {
      let segment = queueLength?.segment_list[index];
      length += segment.length / 1000;
      firstPolylineCoord.push(
        [segment.endLat, segment.endLong],
        [segment.startLat, segment.startLong]
      );
      if (length > queueLength.queue) {
        secondPolylineStartIndex = index;
        break;
      }
    }
    for (
      let index = secondPolylineStartIndex;
      index < queueLength?.segment_list?.length;
      index++
    ) {
      let segment = queueLength?.segment_list[index];
      length += segment.length / 1000;
      secondPolylineCoord.push(
        [segment.endLat, segment.endLong],
        [segment.startLat, segment.startLong]
      );
    }

    length = Math.round(length);
    const tooltipContent = `${t("queue_length")} ${
      queueLength.queue
    } / ${length}  m`;
    const color = getColor(Math.round((queueLength.queue / length) * 100));

    setQueuePolyline((prevQueuePolyline) => {
      const existingIndex = prevQueuePolyline.findIndex(
        (polyline) =>
          polyline.junction_id === junctionId &&
          polyline.direction_id === queueLength.direction_id
      );

      if (existingIndex > -1) {
        const updatedQueuePolyline = [...prevQueuePolyline];
        updatedQueuePolyline[existingIndex] = {
          ...updatedQueuePolyline[existingIndex],
          queuePolyline: {
            ...updatedQueuePolyline[existingIndex].queuePolyline,
            color,
            tooltip: {
              isActive: true,
              content: tooltipContent,
            },
          },
          totalPolyline: {
            ...updatedQueuePolyline[existingIndex].totalPolyline,
            color: "#8c8c8c",
          },
        };

        return updatedQueuePolyline;
      } else {
        return [
          ...prevQueuePolyline,
          {
            junction_id: junctionId,
            direction_id: queueLength.direction_id,
            queuePolyline: {
              color,
              coord: firstPolylineCoord,
              tooltip: {
                isActive: true,
                content: tooltipContent,
              },
            },
            totalPolyline: {
              color: "#8c8c8c",
              coord: secondPolylineCoord,
              tooltip: {
                isActive: false,
                content: "",
              },
            },
          },
        ];
      }
    });
  };

  const getColor = (value: number) => {
    return value > 100
      ? "#000"
      : value > 80
      ? "#990000"
      : value > 60
      ? "#FF3333"
      : value > 40
      ? "#FFFF33"
      : value > 20
      ? "#4C9900"
      : value >= 0
      ? "#99FF33"
      : "#000";
  };

  useEffect(() => {
    if (queueLengthData) {
      for (let index = 0; index < queueLengthData?.length!; index++) {
        let el = queueLengthData[index].all_direction_queue_length;
        for (let y = 0; y < el.all_queue_length.length; y++) {
          addQueueLength(el.all_queue_length[y], el.junction_id);
        }
      }
    }
  }, [queueLengthData]);

  return (
    <>
      {queuePolyline.map((polyline, index) => (
        <React.Fragment key={index}>
          {polyline.queuePolyline.coord.length > 0 && (
            <Polyline
              positions={polyline.queuePolyline.coord}
              color={polyline.queuePolyline.color}
              weight={polylineWeight}
              className={polylineClass}
            >
              {polyline.queuePolyline.tooltip.isActive && (
                <Tooltip sticky >
                  {polyline.queuePolyline.tooltip.content}
                </Tooltip>
              )}
            </Polyline>
          )}
          {polyline.totalPolyline.coord.length > 0 && (
            <Polyline
              positions={polyline.totalPolyline.coord}
              color={polyline.totalPolyline.color}
              weight={polylineWeight}
              className={polylineClass}
            >
              <Tooltip sticky >
                {polyline.queuePolyline.tooltip.content}
              </Tooltip>
            </Polyline>
          )}
        </React.Fragment>
      ))}
    </>
  );
}

export default withLogging(QueueLengthLayer);
