import http from "../http.common";

export const getPhases = (data: any) => {
  return http.post("/web/junction/phase/list", data);
};

export const getDetectors = (data: any) => {
  return http.post("/web/junction/detector/list", data);
};

export const listSignalPlanExpressions = (juncId: number) => {
  return http.post("/web/junction/planMM/list", {
    junction_id: juncId,
  });
};

export const fetchSignalPlanWithItsId = (signalPlanId: number) => {
  return http.post("/web/junction/planMM/get", {
    id: signalPlanId,
  });
};

export const fetchActiveSignalPlan = ({
  juncId,
  juncModelId,
}: {
  juncId: number;
  juncModelId: number;
}) => {
  return http.post("/web/junction/planMM/current", {
    junction_id: juncId,
    junction_model_id: juncModelId,
  });
};

export const fetchActivePlan = ({
  juncId,
  juncModelId,
  requestTime,
}: {
  juncId: number;
  juncModelId: number;
  requestTime: number;
}) => {
  return http.post("/web/junction/planMM/activeplan", {
    junction_id: juncId,
    junction_model_id: juncModelId,
    requestTime: requestTime,
  });
};

export const postSignalPlanToJunction = (data: any) => {
  return http.post("/web/junction/planMM/add", data);
};

export const getCorridorAndDischargeList = (data: {
  junction_id:string;
  junction_model_id:number;
}) => {
  return http.post("/web/junction/planMM/getCorridorAndDischargeList", data);
}

export const changeAiState = (junctionId:number, status:boolean) => {
  let params = {
    junction_id: junctionId,
    ai_is_active:status
  }
  return http.post("/web/junction/planMM/changeaistate", params);
};

