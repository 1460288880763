import React from "react";
import { Card } from "antd";
import styled from "styled-components";
import { Vms, VmsStatus, VmsType } from "../../data/vms/Vms";
import { VmsStatusInfo } from "../map/map-constants/module-status/VmsStatusInfo";
import { t } from "i18next";
import { MapContainer, Marker } from "react-leaflet";
import MapTileControl from "../map/controls/bottom-right-control/MapTileControl";

// VMS ve VMS Status ve Type tiplerini buraya ekleyin

interface Props {
  selectedVms?: Vms;
}

const DetailsVms: React.FC<Props> = ({ selectedVms }) => {
  return (
    <Container>
      <Left>
        <Card
          title=""
          style={{ height: "400px", position: "relative" }}
          bodyStyle={{ height: "100%" }}
        >
          {selectedVms && (
            <MapContainer
              id="editMap"
              style={{
                height: "100%",
                width: "100%",
              }}
              key={"editMap"}
              center={[selectedVms.latitude, selectedVms.longitude]}
              zoom={12}
              scrollWheelZoom={true}
            >
              <Marker
                icon={VmsStatusInfo[0].marker}
                draggable={false}
                position={[selectedVms.latitude, selectedVms.longitude]}
              >
                {" "}
              </Marker>
              <MapTileControl />
            </MapContainer>
          )}
        </Card>
      </Left>
      <Right>
        <Card title="VMS Information">
          {selectedVms && (
            <>
              <p>
                <strong>{t("vms_name")}:</strong> {selectedVms.name}
              </p>
              <p>
                <strong>{t("vms_ip_address")}:</strong>{" "}
                <a
                  href={selectedVms.ip_address}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selectedVms.ip_address}
                </a>
              </p>
              <p>
                <strong>{t("vms_stream_url")}:</strong>{" "}
                <a
                  href={selectedVms.stream_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selectedVms.stream_url}
                </a>
              </p>
              <p>
                <strong>{t("vms_resolution_x")}:</strong>{" "}
                {selectedVms.resolution_x}
              </p>
              <p>
                <strong>{t("vms_resolution_y")}:</strong>{" "}
                {selectedVms.resolution_y}
              </p>
              <p>
                <strong>{t("vms_offset_x")}:</strong> {selectedVms.offset_x}
              </p>
              <p>
                <strong>{t("vms_offset_y")}:</strong> {selectedVms.offset_y}
              </p>
              <p>
                <strong>{t("status")}:</strong>{" "}
                {VmsStatus[selectedVms.status_id]}
              </p>
              <p>
                <strong>{t("vms_type")}:</strong> {VmsType[selectedVms.type_id]}
              </p>
            </>
          )}
        </Card>
      </Right>
    </Container>
  );
};

export default DetailsVms;

const Container = styled.div`
  position: relative;
  display: flex;
  margin-top: 10px;
`;
const Left = styled.div`
  position: relative;
  width: 50%;
`;
const Right = styled.div`
  width: 50%;
  padding-left: 24px;
  position: relative;
`;
