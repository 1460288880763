import { Card, Descriptions, Tag } from "antd";
import { t } from "i18next";
import { LatLng } from "leaflet";
import { RefObject, useMemo } from "react";
import { Junction } from "../../data/junction/Junction";
import { JunctionStatusInfo } from "../map/map-constants/module-status/JunctionStatusIno";
interface Props {
  junction: Junction;
  containerRef: RefObject<HTMLElement>;
  latlon?: LatLng;
}

function JunctionInfo(props: Props) {
  const { junction } = props;

  const getStatus = useMemo(() => {
    let val;
    if(junction.is_dummy == true) {
      val = JunctionStatusInfo[11];
    }else{
      val = JunctionStatusInfo[junction.status_id];
    }

    if (val) {
      return (
        <Tag style={{ display: "flex", alignItems: "center" }}>
          <div
            className={`junction-${val.text.toUpperCase()} junction-legend`}
            style={{marginRight:7, borderRadius:"50px"}}
          ></div>
          {val.text}
        </Tag>
      );
    }
    return <Tag>Invalid Status</Tag>;
  }, [junction]);
  
  const getListenModeStatus = useMemo(() => {
    const val = junction.listen_mode;
    if (val) {
      return <Tag>Aktif</Tag>;
    }
    return <Tag>Pasif</Tag>;
  }, [junction]);

  return (
    <Card style={{minHeight:"350px"}} title={t("junction_details")} bodyStyle={{justifyContent:"center", alignItems:"center", }}>
      <>
        <Descriptions
          labelStyle={{ fontWeight: "bold" }}
          layout={"vertical"}
          size={"small"}
          column={2}
        >
          <Descriptions.Item  label={t("junction_name")}>
            {junction.name}
          </Descriptions.Item>
          <Descriptions.Item  label={t("address")}>
            {junction.address}
          </Descriptions.Item>
          <Descriptions.Item  label={t("latitude")}>
            {junction.latitude}
          </Descriptions.Item>
          <Descriptions.Item  label={t("longitude")}>
            {junction.longitude}
          </Descriptions.Item>
          <Descriptions.Item  label={t("situation")}>
            {" "}
            {getStatus}
          </Descriptions.Item>
          <Descriptions.Item  label="Listen Mode">
            {getListenModeStatus}
          </Descriptions.Item>
        </Descriptions>
      </>
    </Card>
  );
}
export default JunctionInfo;
