import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  Switch,
  DatePicker,
} from "antd";
import { useTranslation } from "react-i18next";
import { LatLng } from "leaflet";
import { useEffect, useState } from "react";
import { LoadingOutlined, UserAddOutlined } from "@ant-design/icons";
import { useJunctionData } from "../../../../../contexts/junction/JunctionDataContext";
import { useAuth } from "../../../../../contexts/auth/AuthContext";
import {
  JunctionDetectorEnum,
  detectorIcons,
} from "../../../../../data/junction-detector/JunctionDetector";
import { MarkerForm } from "./model/MarkerForm";
import { AxiosResponse } from "axios";
import {
  TaskPriority,
  taskTypeEnum,
  taskTypeImages,
} from "../../../../../data/task-management/TaskManagement";
import TextArea from "antd/lib/input/TextArea";
import { useTaskManagementData } from "../../../../../contexts/taskmanagement/TaskManagementDataContext";
import moment from "moment";
import { postActivity } from "../../../../../data/task-management/TaskManagementService";
import { useInventoryData } from "../../../../../contexts/inventory/InventoryDataContext";

interface Props {
  model: MarkerForm;
  latlon?: LatLng;
  setModalVisible: () => void;
  triggerStateFunc?: () => void;
  setMarkerIcon?: (base64: string) => void;
  modalType: "add" | "update";
  formData: any;
}

function MarkerGenericModalForm(props: Props) {
  const {
    model,
    latlon,
    setModalVisible,
    setMarkerIcon,
    triggerStateFunc,
    modalType,
    formData,
  } = props;

  const { junctionList } = useJunctionData();
  const { allOrganizations } = useAuth();
  const { inventorySubTypes } = useInventoryData();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { technicianList } = useTaskManagementData();
  const [serviceLoading, setServiceLoading] = useState<boolean>(false);
  const [showRequiredFieldError, setShowRequiredFieldError] =
    useState<boolean>(false);

  useEffect(() => {
    if (model.type === "task") {
      handleTaskTypeMarkerChange(0);
    }
  }, []);

  useEffect(() => {
    if (formData) {
      let fields: any = [];
      for (const item of model.items) {
        const fieldValue = formData[item.name];
        if (item.type === "date-picker") {
          fields.push({ name: item.name, value: moment(fieldValue) });
        } else {
          fields.push({ name: item.name, value: fieldValue });
        }
      }
      form.setFields(fields);
    }

    return () => {
      setServiceLoading(false);
    };
  }, [form]);

  useEffect(() => {
    form.setFields([
      { name: "lat", value: latlon?.lat },
      { name: "lng", value: latlon?.lng },
      { name: "latitude", value: latlon?.lat },
      { name: "longitude", value: latlon?.lng },
    ]);
  }, [latlon]);

  const onFinish = async () => {
    let values = form.getFieldsValue();
    if (model.type === "task") {
      values["task_status"] = 1;
    }
    //Check for required fields
    let isFormValid: boolean = true;
    model.items.some((field) => {
      if (
        field.isRequired === true &&
        (values[field.name] === undefined ||
          values[field.name] === null ||
          values[field.name] === "")
      ) {
        isFormValid = false;
        return true;
      }
      return false;
    });
    if (!isFormValid) {
      setShowRequiredFieldError(true);
    } else {
      setServiceLoading(true);
      switch (modalType) {
        case "add":
          model
            .addService(values)
            .then(async (res) => {
              if (model.type === "task") {
                await postActivity(res.data?.id, {
                  activity: "Görev Oluşturuldu",
                });
              }
              checkServiceStatus(res);
            })
            .finally(() => setServiceLoading(false));

          break;
        case "update":
          values.id = formData.id;
          model
            .updateService(values)
            .then(async (res) => {
              if (model.type === "task") {
                const message: string = res.data;
                const extractedId = message.match(/is (\d+)/);
                if (extractedId) {
                  await postActivity(parseInt(extractedId[1]), {
                    activity: "Görev Detayı değiştirildi",
                  });
                }
              }
              checkServiceStatus(res);
            })
            .finally(() => setServiceLoading(false));
          break;
        default:
          break;
      }
    }
  };

  const checkServiceStatus = (res: AxiosResponse<any, any>) => {
    if (res.status === 200 || res.status === 201) {
      triggerStateFunc?.();
      setModalVisible();
    } else {
      setShowRequiredFieldError(true);
    }
  };
  const handleSelectOptionChange = (value: number) => {
    setMarkerIcon?.(detectorIcons[value as keyof typeof detectorIcons].base64!);
  };
  const handleTaskTypeMarkerChange = (value: number) => {
    setMarkerIcon?.(taskTypeImages[value]);
  };

  const getFormField = (type: string, value: any, isMulti: any) => {
    let isDefaultValue = false;
    if (type === "number") {
      return <InputNumber></InputNumber>;
    } else if (type === "dropdown") {
      let changeMethod;
      if (value === "junctionList") {
        value = junctionList.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      } else if (value === "deviceTypeList") {
        value = Object.entries(JunctionDetectorEnum)
          .filter((e) => !isNaN(e[0] as any))
          .map((e) => ({ label: e[1], value: parseInt(e[0]) }));
        changeMethod = handleSelectOptionChange;
      } else if (value === "orgList") {
        value = allOrganizations.map((org) => {
          return {
            value: org.value,
            label: org.label,
          };
        });
      } else if (value === "taskTypeList") {
        isDefaultValue = true;
        value = Object.entries(taskTypeEnum).map(([value, label]) => ({
          value: parseInt(value),
          label: label,
        }));
        changeMethod = handleTaskTypeMarkerChange;
      } else if (value === "priorityList") {
        value = Object.entries(TaskPriority).map(([value, label]) => ({
          value: parseInt(value),
          label: t(label),
        }));
      } else if (value === "technicianList") {
        value = technicianList.map((technician) => ({
          value: parseInt(technician?.id),
          label: technician?.name,
        }));
      } else if (value === "inventoryTypeList") {
        value = inventorySubTypes.map((inv_sub) => ({
          value: inv_sub.id,
          label: (
            <>
              <img
                src={inv_sub.iconUrl}
                style={{ width: "30px", height: "30px" }}
              />
              {inv_sub.sub_type_name}
            </>
          ),
        }));
      }
      value = value.map((item: any) => ({
        ...item,
        label: item.label || t(item.label),
      }));

      return (
        <Select
          style={{ width: 250 }}
          mode={isMulti ? "multiple" : undefined}
          showSearch
          filterOption={(input, option) =>
            ((option?.label ?? "") as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={value}
          defaultValue={isDefaultValue ? 0 : undefined}
          onChange={changeMethod ?? undefined}
        />
      );
    } else if (type === "boolean") {
      return <Switch />;
    } else if (type === "date-picker") {
      return <DatePicker />;
    } else if (type === "text-area") {
      return <TextArea rows={2} />;
    } else if (type === "text-area-map") {
      return <TextArea rows={3} />;
    } else {
      return <Input></Input>;
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
    >
      {model &&
        model.items.map((value, index) => {
          return (
            <Form.Item
              key={index}
              label={t(`${value.label}`)}
              name={value.name}
              required={value.isRequired}
              valuePropName={value.type === "boolean" ? "checked" : undefined}
              {...(value.type === "boolean" ? { initialValue: false } : {})}
            >
              {getFormField(value.type, value.dropdownItem, value.isMulti)}
            </Form.Item>
          );
        })}
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" style={{ width: "104px" }}>
          {serviceLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 18,
                    color: "white",
                  }}
                  spin
                />
              }
            />
          ) : (
            t("submit")
          )}
        </Button>
        {showRequiredFieldError && (
          <span
            style={{
              fontSize: 11,
              color: "red",
              paddingLeft: "14px",
            }}
          >
            {t("please_correct_the_errors_in_the_form")}
          </span>
        )}
      </Form.Item>
    </Form>
  );
}

export default MarkerGenericModalForm;
