import { Line } from "@ant-design/plots";
import React from "react";
import { useTranslation } from "react-i18next";
import { LineChartData } from "./AiReporting";

interface VieroAiChartProps {
  data: LineChartData[];
  legendName: string;
}

function VieroAiChart(props: VieroAiChartProps) {
  const { t } = useTranslation();
  const { data, legendName } = props;
  console.log(data)
  const legend: any = {
    position: "top",
    title: {
      text: legendName,
      style: {
        marginLeft: 20,
        fontSize: 20,
        fontWeight: 600,
      },
    },
   
  };

  const config: any = {
    data,
    xField: "ts",
    yField: "value",
    seriesField: "type",
    legend: legend,
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 2500,
      },
    },
    meta: {
      type: {
        formatter: (v: string) => {
          return translateChartTooltip(v);
        },
      },
    },
  };

  const translateChartTooltip = (value: string) => {
    switch (value) {
      case "totalCarCount":
        return `${t("total_car_count")}`;
      case "motorCount":
        return `${t("motor_count")}`;
      case "heavyCarCount":
        return `${t("heavy_vehicle")}`;
      case "lightCarCount":
        return `${t("light_vehicle")}`;
      case "mediumCarCount":
        return `${t("medium_vehicle")}`;
      default:
        return value;
    }
  };

  return <Line {...config} />;
}

export default React.memo(VieroAiChart);
