import { useEffect, useState } from "react";
import { Bluesis } from "../../../data/bluesis/Bluesis";
import { Button, Card, Col, Form, Input, Row, Select, message } from "antd";
import { t } from "i18next";
import BluesisMap from "./BluesisMap";
import { LatLng } from "leaflet";
import TextArea from "antd/lib/input/TextArea";
import BluesisService from "../../../data/bluesis/BluesisService";
import moment from "moment";

const gutter = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};
enum InformationFormFileds {
  "device_id" = "device_id",
  "name" = "name",
  "ip_address" = "ip_address",
  "energy_source" = "energy_source",
  "device_type" = "device_type",
  "network_type" = "network_type",
  "latitude" = "latitude",
  "longitude" = "longitude",
  "is_connected" = "is_connected",
  "created_date" = "created_date",
  "mount_location" = "mount_location",
  "note" = "note",
}

const formItemStyle = { fontWeight: "bold", width: "20%", marginTop: "3px" };

interface Props {
  selectedBluesis: Bluesis;
}

function Information(props: Props) {
  const { selectedBluesis } = props;
  const [latlon, setLatlon] = useState<LatLng>();
  const [formLatLng, setFormLatLng] = useState<LatLng>();
  const [deviceType, setDeviceType] = useState<number>(selectedBluesis.device_type);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFields([
      {
        name: InformationFormFileds.device_id,
        value: selectedBluesis!.device_id,
      },
      {
        name: InformationFormFileds.name,
        value: selectedBluesis!.name,
      },
      { name: InformationFormFileds.ip_address, value: selectedBluesis!.ip },
      {
        name: InformationFormFileds.energy_source,
        value: selectedBluesis!.energy_source,
      },
      {
        name: InformationFormFileds.device_type,
        value: selectedBluesis!.device_type,
      },
      {
        name: InformationFormFileds.network_type,
        value: selectedBluesis!.network_type,
      },
      {
        name: InformationFormFileds.latitude,
        value: selectedBluesis!.latitude,
      },
      {
        name: InformationFormFileds.longitude,
        value: selectedBluesis!.longitude,
      },
      {
        name: InformationFormFileds.created_date,
        value: moment.unix(selectedBluesis!.created_date / 1000).utcOffset(localStorage.getItem("timezone")!).format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        name: InformationFormFileds.mount_location,
        value: selectedBluesis!.mount_location,
      },
      {
        name: InformationFormFileds.note,
        value: selectedBluesis!.note,
      },
    ]);
  }, [form]);

  useEffect(() => {
    let latlon = new LatLng(
      selectedBluesis.latitude,
      selectedBluesis.longitude
    );
    setLatlon(latlon);
  }, [selectedBluesis]);

  useEffect(() => {
    if (formLatLng?.lat && formLatLng?.lng) {
      form.setFields([
        {
          name: InformationFormFileds.latitude,
          value: formLatLng?.lat,
        },
        {
          name: InformationFormFileds.longitude,
          value:formLatLng?.lng,
        }
      ])
    }
  }, [formLatLng])
  

  const changeDeviceType = (value: number) => {
    setDeviceType(value);
  };

  const updateBluesis = async () => {
    let formValues = form.getFieldsValue();
    delete formValues.created_date;
    formValues.id = selectedBluesis.id;
    await BluesisService.updateBluesis(formValues).then(res => {
      message.success(t("update_bluesis"));
    }).catch(err => {
      message.error(t("error_update_bluesis"));
    });
  };

  const deleteBluesis = async () => {
    await BluesisService.deleteBluesis(selectedBluesis.id).then(res => {
      message.success(t("delete_bluesis"));
    }).catch(err => {
      message.error(t("error_delete_bluesis"));
    });;
  };

  return (
    <>
      <Row gutter={gutter}>
        <Col span={5}>
          {selectedBluesis && (
            <Card bodyStyle={{ paddingTop: "0px", paddingBottom: "0px" }}>
              <Form
                form={form}
                labelCol={{ span: 24 }}
                style={{ minWidth: 800, paddingLeft: 10 }}
              >
                <Form.Item
                  style={formItemStyle}
                  label={t("device_id")}
                  name={InformationFormFileds.device_id}
                >
                  <Input disabled defaultValue={selectedBluesis.device_id} />
                </Form.Item>
                <Form.Item
                  style={formItemStyle}
                  label={t("name")}
                  name={InformationFormFileds.name}
                >
                  <Input disabled defaultValue={selectedBluesis.name} />
                </Form.Item>

                <Form.Item
                  style={formItemStyle}
                  label={t("latitude")}
                  name={InformationFormFileds.latitude}
                >
                  <Input
                    disabled={true}
                    defaultValue={selectedBluesis.latitude}
                  />
                </Form.Item>
                <Form.Item
                  style={formItemStyle}
                  label={t("longitude")}
                  name={InformationFormFileds.longitude}
                >
                  <Input
                    disabled={true}
                    defaultValue={selectedBluesis.longitude}
                  />
                </Form.Item>
                <Form.Item
                  style={formItemStyle}
                  label={t("device_type")}
                  name={InformationFormFileds.device_type}
                >
                  <Select
                    value={selectedBluesis.device_type}
                    placeholder={t("select_device_type")}
                    onChange={changeDeviceType}
                    disabled
                    options={[
                      {
                        value: 1,
                        label: `${t("real_device")}`,
                      },
                      {
                        value: 3,
                        label: `${t("virtual_device")}`,
                      },
                    ]}
                  />
                </Form.Item>
                {deviceType !== 3 && (
                  <>
                    <Form.Item
                      style={formItemStyle}
                      label={t("ip_address")}
                      name={InformationFormFileds.ip_address}
                    >
                      <Input disabled defaultValue={selectedBluesis.ip} />
                    </Form.Item>
                    <Form.Item
                      style={formItemStyle}
                      label={t("network_type")}
                      name={InformationFormFileds.network_type}
                    >
                      <Select
                        disabled
                        defaultValue={selectedBluesis.network_type}
                        placeholder={t("network")}
                        options={[
                          {
                            value: 1,
                            label: `${t("fiber")}`,
                          },
                          {
                            value: 2,
                            label: `${t("sim")}`,
                          },
                          {
                            value: 3,
                            label: `${t("wimax")}`,
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      style={formItemStyle}
                      label={t("energy_source")}
                      name={InformationFormFileds.energy_source}
                    >
                      <Select
                        disabled
                        defaultValue={selectedBluesis.energy_source}
                        placeholder={t("select_energy_type")}
                        options={[
                          {
                            value: 1,
                            label: `${t("electric")}`,
                          },
                          {
                            value: 2,
                            label: `${t("solar_panel")}`,
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      style={formItemStyle}
                      label={t("mount_location")}
                      name={InformationFormFileds.mount_location}
                    >
                      <Input disabled defaultValue={selectedBluesis.mount_location} />
                    </Form.Item>
                  </>
                )}
                <Form.Item
                  style={formItemStyle}
                  label={t("created_date")}
                  name={InformationFormFileds.created_date}
                >
                  <Input
                    disabled={true}
                    defaultValue={selectedBluesis.created_date}
                  />
                </Form.Item>
                <Form.Item
                  style={formItemStyle}
                  label={t("note")}
                  name={InformationFormFileds.note}
                >
                  <TextArea disabled rows={4} defaultValue={selectedBluesis.note} />
                </Form.Item>
                {/* <Form.Item>
                  <Button type="primary" danger onClick={deleteBluesis}>
                    {t("delete")}
                  </Button>
                  <Button
                    style={{ marginLeft: "15px" }}
                    type="primary"
                    onClick={updateBluesis}
                  >
                    {t("update")}
                  </Button>
                </Form.Item> */}
              </Form>
            </Card>
          )}
        </Col>
        <Col span={18}>
          <Card bodyStyle={{ height: "75vh" }}>
            {latlon && <BluesisMap selectedBluesis={selectedBluesis} latlon={latlon} setLanLng = {setFormLatLng}></BluesisMap>}
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Information;
