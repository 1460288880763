import { UploadFile } from "antd";
import http from "../http.common";
import { Inventory } from "./Inventory";
import { InventorySubTypeWithIcon, InventoryType } from "./InventoryType";
import { bboxModel } from "../../contexts/inventory/InventoryDataContext";

interface InventoryImage extends UploadFile {
  name: string;
  uid: string;
  url: string;
}

const getInventories = function (bbox: bboxModel, type_id: number) {
  const abortController = new AbortController();

  const promise = http.post<Inventory[]>(
    "/web/inventory/listinventory",
    { bbox, type_id },
    { signal: abortController.signal }
  );

  return { promise, abortController };
};

const getInventoryTypes = function () {
  return http.get<InventoryType[]>("/web/inventory/listinventorytype");
};

const getInventorySubTypes = function () {
  return http.get<InventorySubTypeWithIcon[]>(
    "/web/inventory/listinventorysubtype"
  );
};

const getImageListByInventory = function (prefix: string) {
  return http.post<InventoryImage[]>("/web/inventory/inventoryimagelist", {
    prefix,
  });
};

const getSpritesheet = function () {
  return http.get<any>("/file/inventory/spritesheet.json");
};

const addInventory = function (inventory: Inventory) {
  return http.post<boolean>("/web/inventory/add", inventory);
};

const updateInventory = function (inventory: Inventory) {
  return http.post<boolean>("/web/inventory/update", inventory);
};

const deleteInventory = function (id: number) {
  return http.post<boolean>("/web/inventory/delete", id);
};

const uploadFile = function (formData: FormData) {
  return http.post<InventoryImage>("/web/inventory/upload", formData);
};

const removeFile = function (objectName: string) {
  return http.post<InventoryImage>("/web/inventory/removefile", { objectName });
};

const InventoryService = {
  getInventories,
  getInventoryTypes,
  getImageListByInventory,
  getInventorySubTypes,
  getSpritesheet,
  uploadFile,
  removeFile,
  addInventory,
  updateInventory,
  deleteInventory
};

export default InventoryService;
