import StyledButton from "../../components/custom-styled/button/StyledButton";
import { t } from "i18next";
import { UsbOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useUserInfo } from "../../../../contexts/auth/UserContext";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";

function AssignedToMeControl() {
  const { assignedModules } = useUserInfo();
  const { layerDataList } = useCustomLayerData();
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((prev) => !prev);
  };

  useEffect(() => {
    if (assignedModules?.length > 0 && isActive == true) {
      for (let index = 0; index < assignedModules.length; index++) {
        const module = assignedModules[index];
        const layer = layerDataList.find((item) => item.id == module.module_id);
        if (layer) {
          layer.legend?.setOnMapData(() => {
            const filteredData = layer?.data?.filter((item: any) => {
              return module.assigned_ids.includes(item.id);
            });
            return filteredData;
          });
        }
      }
    } else {
      for (let index = 0; index < assignedModules.length; index++) {
        const module = assignedModules[index];
        const layer = layerDataList.find((item) => item.id == module.module_id);
        if (layer) {
          layer.legend?.setOnMapData(() => {
            return layer?.data;
          });
        }
      }
    }
  }, [isActive, layerDataList]);

  return (
    <div className="leaflet-bar" style={{ border: "none" }}>
      <StyledButton
        icon={<UsbOutlined />}
        backgroundColor={isActive ? "#00FF00" : "#2F4050"}
        borderColor={isActive ? "#00FF00" : "#2F4050"}
        onClick={handleClick}
        style={{
          color: "white",
          marginLeft: 0,
          paddingLeft: 7,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 8,
        }}
      >
        <p
          style={{
            marginBottom: 0,
            color: "#fff",
            fontSize: "13px",
          }}
        >
          {`${t("assigned_devices")}`}
        </p>
      </StyledButton>
    </div>
  );
}

export default AssignedToMeControl;
