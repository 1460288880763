export const convertTaskModel = (task: any) => {
  return {
    id: task.id,
    title: task.title,
    description: task.description,
    latitude: task.latitude,
    longitude: task.longitude,
    address: task.detail.info?.address,
    task_type: task.task_type,
    priority: task.priority,
    est_time: task.est_time,
    start_time: task.start_time,
    end_time: task.end_time,
    attendant_list: [1, 2],
  };
};
