import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Row, Col, Button, Select, Spin, message,Tooltip } from "antd";
import {
  ArrowLeftOutlined,
  InfoCircleOutlined,
  LockFilled,
  PlusOutlined,
  SendOutlined,
  UnlockFilled,
  DownloadOutlined
} from "@ant-design/icons";

// context
import { PlanContext } from "../context/PlanContext";

import PlanCard from "../components/PlanCard";
import CreateNewPlan from "../components/CreateNewPlan";
import PlanSchedular from "../components/PlanSchedular";
import SignalPlanForm from "../components/SignalPlanForm";

import checkIfSignalPlanAvailable from "../common/checkIfSignalPlanAvailable";

import {
  listSignalPlanExpressions,
  fetchSignalPlanWithItsId,
  fetchActiveSignalPlan,
} from "../../../../data/signal-plan/SignalPlanService";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface Props {
  selectedJunction: any;
}

const { Option } = Select;

const Plans: React.FC<Props> = ({ selectedJunction }) => {
  const { t } = useTranslation();

  const planContextValues = useContext(PlanContext);

  const [plans, setPlans] = useState<any>(null);
  const [contextSignalPlan, setContextSignalPlan] =
    planContextValues.signalPlan;
  const [contextSignalPlanBackup, setContextSignalPlanBackup] =
    planContextValues.signalPlanBackup;
  const [locked, setLocked] = planContextValues.locked;

  const [predefinedPlanData, setPredefinedPlanData] = useState<object | null>(
    null
  );

  const columns: any = [
    {
      title: t("phase"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("minus"),
      dataIndex: "minus",
      key: "minus",
    },
    {
      title: t("mean_time"),
      dataIndex: "meanTime",
      key: "meanTime",
    },
    {
      title: t("plus"),
      dataIndex: "plus",
      key: "plus",
    },
  ];

  const showNewPlan = () => {
    setShowCreatePlanScreen(true);
    if (showEditPlanScreen) {
      setShowEditPlanScreen(false);
    }
    if (predefinedPlanData) {
      setPredefinedPlanData(null);
    }
  };

  const [planActionMode, setPlanActionMode] = useState<"edit" | "copy" | null>(
    null
  );

  const [showCreatePlanScreen, setShowCreatePlanScreen] =
    useState<boolean>(false);

  const [showEditPlanScreen, setShowEditPlanScreen] = useState<boolean>(false);

  const showEditPlan = (planInfo: any, mode: "edit" | "copy") => {
    setShowEditPlanScreen(true);
    setPredefinedPlanData(planInfo);

    // detect edit or copy situation
    setPlanActionMode(mode);

    if (showCreatePlanScreen) {
      setShowCreatePlanScreen(false);
    }
  };

  const closePlanCreationOrEdit = () => {
    if (showCreatePlanScreen) {
      setShowCreatePlanScreen(false);
    }
    if (showEditPlanScreen) {
      setShowEditPlanScreen(false);
    }
    if (predefinedPlanData) {
      setPredefinedPlanData(null);
    }
  };

  // SIGNAL PLAN SELECTION AND RELATED PROCESSES
  const [signalPlanOptions, setSignalPlanOptions] = useState<any[]>([]);
  const [selectedIdOfSignalPlan, setSelectedIdOfSignalPlan] = useState<
    null | number
  >(null);

  useEffect(() => {
    manageSignalPlanRequests();
  }, []);

  const manageSignalPlanRequests = async () => {
    // set signal plan _combo box OPTIONS_
    let listedSignalPlanExpressions: null | any[] = null;

    setIsLoading(true);

    await listSignalPlanExpressions(selectedJunction.id).then((res: any) => {
      if (Array.isArray(res.data)) {
        // variable to compare active plan id and get the index of active plan for combo box
        listedSignalPlanExpressions = res.data;

        if (listedSignalPlanExpressions) {
          setSignalPlanOptions(listedSignalPlanExpressions);
        }
      }
    });

    // fetch _ACTIVE SIGNAL PLAN_
    fetchActiveSignalPlan({
      juncId: selectedJunction?.id,
      juncModelId: selectedJunction?.junction_model_id,
    }).then((res: any) => {
      setIsLoading(false);

      const activeSignalPlan = res.data?.current;
      const activeSignalPlanId = activeSignalPlan?.id;

      if (activeSignalPlan) {
        // set signal plan as active plan at the beginning
        setContextSignalPlan(activeSignalPlan);
        setContextSignalPlanBackup(activeSignalPlan);

        // set active signal plan id as selected(to detect default value of select)
        if (activeSignalPlanId) {
          setSelectedIdOfSignalPlan(activeSignalPlanId);
        }
      }
    }).catch((_error: Error) => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (contextSignalPlan?.plans) {
      setPlans(contextSignalPlan?.plans);
    }
  }, [contextSignalPlan]);

  const [flashPlan, setFlashPlan] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // fetch signal plan if plan id is changed
  const changeSPOnPlanSelectChange = (selectedIdOfSignalPlanVal: number | null) => {
    setSelectedIdOfSignalPlan(selectedIdOfSignalPlanVal);

    if (selectedIdOfSignalPlanVal !== null) {
      if (!isLoading) {
        setIsLoading(true);
      }
      fetchSignalPlanWithItsId(selectedIdOfSignalPlanVal)
        .then((res: any) => {
          setIsLoading(false);
          if (res.data?.current?.plans) {
            setPlans(res.data?.current?.plans);
            setContextSignalPlan(res.data?.current);
          }
        })
        .catch((_error) => {
          setIsLoading(false);
        });
    }
  };

  const saveSignalPlanButtonRef = useRef<any>();

  const shouldShowSignalPlanBlocks = useMemo(() => {
    return showCreatePlanScreen || showEditPlanScreen
      ? {
          display: "none",
        }
      : {};
  }, [showCreatePlanScreen, showEditPlanScreen]);



  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(contextSignalPlan,undefined,4)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `PlanJson ${selectedJunction?.name} ${contextSignalPlan?.name} v${contextSignalPlan?.version}.json`;

    link.click();
  };


  return (
  
    <>
      {/* LOCK BUTTON */}
      {contextSignalPlan && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            ...shouldShowSignalPlanBlocks,
          }}
        >
          <Tooltip title={signalPlanOptions.find(elem=> elem.id ===selectedIdOfSignalPlan)?.is_algo&&"User cannot edit ALGO plan!"}>
          <Button
          
          disabled={signalPlanOptions.find(elem => elem.id === selectedIdOfSignalPlan)?.is_algo || signalPlanOptions.find(elem => elem.id === selectedIdOfSignalPlan)?.is_isbak}
          onClick={() => {
              setLocked((prev: boolean) => !prev);
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: locked ? "#3d3d3d" : "#FFF",
              backgroundColor: locked ? "#FFF" : "#3d3d3d",
            }}
          >
            { t("edit") }
            {locked ? (
              <LockFilled
                style={{
                  fontSize: "1.2rem",
                }}
              />
            ) : (
              <UnlockFilled
                style={{
                  fontSize: "1.2rem",
                }}
              />
            )}
          </Button>
          </Tooltip>
        </div>
      )}

      {/* PLAN DETAIL HEADER */}
      {contextSignalPlan && signalPlanOptions && (
        <>
        <div
          style={{
            display: "flex",
            gap: ".7rem",
            height: "2.1rem",
            fontSize: "1rem",
            fontWeight: "bold",
            ...shouldShowSignalPlanBlocks,
          }}
        >
          <span>
            <InfoCircleOutlined />
          </span>
          <span>{ t("weekly_plan_detail")}</span>
      
        </div>
        </>
      )}

      {/* PLAN DETAIL OPTIONS */}
      {contextSignalPlan &&
        selectedIdOfSignalPlan !== null &&
        signalPlanOptions && (<>
          <Select
          disabled={!locked}
            style={{
              width: "70%",
              marginBottom: "2rem",
              marginTop: ".8rem",
              ...shouldShowSignalPlanBlocks,
            }}
            value={selectedIdOfSignalPlan}
            onChange={(val: any) => {
              changeSPOnPlanSelectChange(val);
            }}
          >
            {signalPlanOptions?.map((signalPlanOption: any) => {
              let { name, version, when_add, who_add, id,is_algo,is_active,is_default,is_isbak, ...rest } =signalPlanOption;
              let  dateS=moment.unix(when_add).utcOffset(localStorage.getItem("timezone")!).format('DD/MM/YYYY HH:mm')
              let nameView=is_algo==true?name+ '':name + 'v'+version 
               //nameView=nameView+(is_active==true?' (ACTIVE)':'')
              return (
                <Option value={id} key={id}> 
                 <span style={{color:'#2d6feb'}}>{nameView} </span>  
                
                 <span style={{color:'gray',fontWeight:'400'}}> {dateS} </span>  
                 {is_isbak
                  ? <span style={{color:'green'}}> (ISBAK DEVICE PLAN) </span>
                  : is_active
                    ? <span style={{color:'green'}}> (ACTIVE) </span>
                    : is_default
                      ? <span style={{color:'orange'}}> (DEFAULT) </span>
                      : <></>}              
                </Option>
              );
            })}
          </Select>
          <span style={{marginLeft:'10px'}}>
          <Button type="primary"   icon={<DownloadOutlined />} onClick={exportData}>
              Export Selected Plan
          </Button >
          </span>
          </>
        )}

      {/* FORM */}
      {contextSignalPlan && (
        <div
          style={{
            ...shouldShowSignalPlanBlocks,
          }}
        >
          <SignalPlanForm
            ref={saveSignalPlanButtonRef}
            selectedJunction={selectedJunction}
            manageSignalPlanRequests={manageSignalPlanRequests}
          />
        </div>
      )}

      {/* SCHEDULAR */}
      {contextSignalPlan && (
        <div
          style={{
            ...shouldShowSignalPlanBlocks,
          }}
        >
          <PlanSchedular selectedJunction={selectedJunction} />
        </div>
      )}

      {/* CREATE PLAN: NEW (FLASH || PLAN) */}
      {!locked && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            ...shouldShowSignalPlanBlocks,
          }}
        >
          <Button
            type="ghost"
            onClick={() => {
              const isSignalPlanAvailable =
                checkIfSignalPlanAvailable(contextSignalPlan);

              if (!isSignalPlanAvailable) {
                setFlashPlan(true);
                showNewPlan();
              } else {
                message.warning(
                  t("flash_plan_created_before")
                );
              }
            }}
            style={{
              marginRight: ".6rem",
            }}
          >
            <PlusOutlined />
            <span>{ t("create_flash_plan") }</span>
          </Button>
          <Button type="primary" onClick={showNewPlan}>
            <PlusOutlined />
            <span>{ t("create_new_plan") }</span>
          </Button>
        </div>
      )}

      {/* PLAN RELATED BLOCKS */}
      {!(showCreatePlanScreen || showEditPlanScreen) && (
        <>
          <Row gutter={[12, 50]}>
            {plans &&
              plans?.map((planTableData: any, index: number) => (
                <Col
                  xl={4}
                  lg={6}
                  md={8}
                  sm={12}
                  xs={24}
                  key={`plan-card-${index}`}
                >
                  <PlanCard
                    columns={columns}
                    data={planTableData}
                    showEditPlan={showEditPlan}
                  />
                </Col>
              ))}
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          ></div>
        </>
      )}
      {(showCreatePlanScreen || showEditPlanScreen) && (
        <>
          <Button onClick={closePlanCreationOrEdit} shape="circle" size="large">
            <ArrowLeftOutlined />
          </Button>
          {/* Create a Plan */}
          {showCreatePlanScreen && !showEditPlanScreen && (
            <>
              <CreateNewPlan
                selectedJunction={selectedJunction}
                flashPlanStateSet={
                  flashPlan
                    ? {
                        state: flashPlan,
                        cleanState: () => setFlashPlan(false),
                      }
                    : null
                }
                closePlanCreationOrEdit={closePlanCreationOrEdit}
              />
            </>
          )}
          {/* Edit Plan or Create Copied Plan */}
          {showEditPlanScreen && !showCreatePlanScreen && (
            <>
              <CreateNewPlan
                selectedJunction={selectedJunction}
                predefinedPhasesFormObject={predefinedPlanData}
                planActionModeSet={
                  planActionMode
                    ? {
                        state: planActionMode,
                        cleanState: () => setPlanActionMode(null),
                      }
                    : null
                }
                closePlanCreationOrEdit={closePlanCreationOrEdit}
              />
            </>
          )}
        </>
      )}

      {/* SAVE SIGNAL PLAN */}
      {!locked && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "2rem",
            ...shouldShowSignalPlanBlocks,
          }}
        >
          <Button
            onClick={() => {
              saveSignalPlanButtonRef.current.save();
            }}
            type="primary"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 12,
            }}
          >
            <span>
              <SendOutlined />
            </span>
            <span>{ t("save_n_send_to_junction") }</span>
          </Button>
        </div>
      )}

      {/* isloading */}
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#333",
            opacity: "0.3",
            zIndex: 5,
          }}
        >
          <div
            style={{
              display: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <Spin
              style={{
                position: "fixed",
                top: "50vh",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "#fff",
                zIndex: 6,
              }}
              tip={`${t("loading_signal_plan")}...`}
              size="large"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Plans;
