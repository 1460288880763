import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CustomLayerData,
  CustomLayerGroup,
} from "../../pages/map/map-constants/LayerData";
import { useLicenseData } from "../license/LicenseDataContext";
import UserService from "../../data/user/UserService";
import { MapModule, AuthService } from "@icms/ui-components";
import { PermissionContext } from "@icms/ui-components";
import { PermissionDataProps } from "@icms/ui-components";

export interface layerData {
  id: MapModule;
  data: any;
  searchable?: layerSearchData;
  legend?: layerLegendData;
}
interface layerSearchData {
  category: string;
  labelProperty: string;
  filterProperties: string[];
}

interface layerLegendData {
  countProperty: string;
  setOnMapData: Dispatch<SetStateAction<any>>;
}
interface CustomLayerDataContextState {
  layerList: CustomLayerGroup[];
  changeLayer: (id: MapModule, checked: boolean) => void;
  layerDataList: layerData[];
  setLayerData: (data: layerData) => void;
}

const CustomLayerDataContext = createContext<
  CustomLayerDataContextState | undefined
>(undefined);

export function CustomLayerDataProvider({ children }: PropsWithChildren<{}>) {
  const { licenseData } = useLicenseData();
  const { hasAccessComponent } = useContext(
    PermissionContext
  ) as PermissionDataProps;
  const [layerList, setLayerList] = useState<CustomLayerGroup[]>([]);
  const [layerDataList, setLayerDataList] = useState<layerData[]>([]);

  const user = AuthService.getUser();

  const changeLayer = (id: MapModule, checked: boolean) => {
    if (!checked) {
      setLayerDataList(layerDataList.filter((item) => item.id !== id));
    }

    setLayerList((prev) => {
      return prev
        .map((item) => {
          if (item.id === id) {
            return { ...item, isChecked: checked };
          }
          return item;
        })
        .filter((item) =>
          hasAccessComponent(item.authorized_component_name + "-READ")
        );
    });
  };

  const updateLayerDataList = (value: layerData) => {
    if (!layerDataList.some((item) => item.id == value.id)) {
      setLayerDataList((prev) => [...prev, value]);
    } else {
      setLayerDataList((prev) => {
        return prev.map((item) => {
          if (item.id === value.id) {
            return { ...item, data: value.data };
          }
          return item;
        });
      });
    }
  };

  useEffect(() => {
    if (licenseData && licenseData.modules) {
      let cachedLayer: {
        id: MapModule;
        isChecked: boolean;
      }[] = JSON.parse(localStorage.getItem("layerList") ?? "[]");

      UserService.getModules(user.user_info.id_user).then((res) => {
        const filteredCustomLayerData = CustomLayerData.filter((i) =>
          licenseData.licenseLayers.find(
            (licenseLayer) =>
              licenseData.modules[licenseLayer.name as string] &&
              licenseLayer.id === i.id
          )
        );

        const updatedLayerList =
          cachedLayer.length > 0
            ? filteredCustomLayerData.map((customItem) => {
                const cachedItem = cachedLayer.find(
                  (cachedItem) => cachedItem.id === customItem.id
                );
                return cachedItem
                  ? { ...customItem, isChecked: cachedItem.isChecked }
                  : customItem;
              })
            : filteredCustomLayerData.map((customItem) => ({
                ...customItem,
                isChecked:
                  res.data.length > 0
                    ? res.data.includes(customItem.id)
                    : customItem.isChecked,
              }));
        let filteredLayerList = updatedLayerList.filter((item) =>
          hasAccessComponent(item.authorized_component_name + "-READ")
        );
        setLayerList(filteredLayerList);
      });
    }
  }, [licenseData]);

  useEffect(() => {
    if (layerList.length > 0) {
      let convertedMap = layerList.map((i) => {
        return { id: i.id, isChecked: i.isChecked };
      });
      localStorage.setItem("layerList", JSON.stringify(convertedMap));
    }
  }, [layerList]);

  return (
    <CustomLayerDataContext.Provider
      value={{
        layerList: layerList,
        changeLayer: changeLayer,
        layerDataList: layerDataList,
        setLayerData: updateLayerDataList,
      }}
    >
      {children}
    </CustomLayerDataContext.Provider>
  );
}

export function useCustomLayerData() {
  const context = useContext(CustomLayerDataContext);
  if (context === undefined) {
    return {
      layerList: [],
      changeLayer: (id: MapModule, checked: boolean) => {},
      layerDataList: [],
      setLayerData: (data: layerData) => {},
    };
    // throw new Error(
    //   "useCustomLayerData must be used within a CustomLayerDataContext"
    // );
  }
  return context;
}
