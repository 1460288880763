import { LoadingOutlined } from "@ant-design/icons";
import { FormInstance, Spin, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PaginationComponent from "../Pagination";

import moduleExpressions from "./moduleExpressions.json";

import errorExpressionsEN from "./errorExpressions.en.json";
import errorExpressionsTR from "./errorExpressions.tr.json";
import errorExpressionsID from "./errorExpressions.id.json";
import { useJunctionData } from "../../../../../../../contexts/junction/JunctionDataContext";

let errorExpressions=errorExpressionsEN

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface Props {
  contentData: any;
  archiveFilterForm: FormInstance<any>;
}

const PastRecord: React.FC<Props> = ({ contentData, archiveFilterForm }) => {

  const [page, setPage] = useState<number>(1);
  const { t, i18n } = useTranslation();
  errorExpressions=i18n.language=="tr"?errorExpressionsTR:(i18n.language=="id"?errorExpressionsID:errorExpressionsEN)

  // junctions from ctx
  const junctions = useJunctionData().junctionList;

  const columns: ColumnsType<any> = useMemo(() => {
    return [
      {
        title: t("date"),
        dataIndex: "update_ts",
        key: "update_ts",
        render: (update_ts: number) => (
          <>{ moment.unix(update_ts).utcOffset(localStorage.getItem("timezone")!).format("DD.MM.YY HH:mm") }</>
        )
      },
      {
        title: t("error_module"),
        dataIndex: "module_id",
        key: "module_id",
        render: (module_id: string) => <span>{(moduleExpressions as any)?.["" + +module_id]}</span>,
      },
      {
        title: t("error_description"),
        dataIndex: "err_id",
        key: "err_id",
        render: (errorId: string) => <span>{(errorExpressions as any)?.["" + +errorId]}</span>,
      },

      {
        title: t("parameters"),
        dataIndex: "params",
        key: "params",
        render: (errParams: string) => {
          try {
            const parsedErrParams: string[] = JSON.parse(errParams);
            if (Array.isArray(parsedErrParams)) {
              const colOutput = parsedErrParams?.join(", ");
              return <>{colOutput}</>;
            }
          } catch (err) {
            return <>{errParams}</>;
          }
        },
      },
      {
        title: "Junction",
        dataIndex: "junc_id",
        key: "junc_id",
        render: (junctionId: number) => {
          const junctionName = junctions
            ?.find((junction) => {
              return +junction?.id === +junctionId
            })
            ?.name?.toUpperCase();
          return junctionName ? (
            <Tag color={"tomato"}>{junctionName}</Tag>
          ) : (
            <Spin indicator={antIcon} />
          );
        },
      },
    ]
  }, [junctions]);

  return (
    <>
      {contentData && (
        <>
          <Table
            dataSource={contentData?.rows}
            columns={columns}
            pagination={false}
            size="large"
          />
          <PaginationComponent
            pageNumber={contentData?.page ? contentData?.page : page}
            totalPages={contentData?.totalPages ? contentData?.totalPages : 1}
            setterMethod={(newPageNumber: number) => {
              setPage(newPageNumber);
              let fields = archiveFilterForm.getFieldsValue();
              archiveFilterForm.setFieldsValue({
                ...fields,
                page: newPageNumber,
              });

              archiveFilterForm.submit();
            }}
          />
        </>
      )}
    </>
  );
};

export default PastRecord;
