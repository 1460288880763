import { PermissionContext, PermissionDataProps } from "@icms/ui-components";
import { NotificationProvider } from "../../contexts/information/NotificationContext";
import ErrorTable from "./components/ErrorTable";
import { useContext } from "react";

const ErrorMonitor = () => {
  const { hasAccessComponent } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  return hasAccessComponent("Junction Module-READ") ? (
    <>
      <NotificationProvider>
        <ErrorTable />
      </NotificationProvider>
    </>
  ) : null;
};

export default ErrorMonitor;
