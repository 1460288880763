import { useNavigate } from "react-router-dom";
import HeaderButtons from "./HeaderButtons";

function HeaderElements() {
  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate(`/profile`);
  };

  const logoutClick = () => {
    navigate(`/login`);
  };

  return (
    <HeaderButtons
      navigateToProfile={navigateToProfile}
      logoutClick={logoutClick}
    />
  );
}

export default HeaderElements;
