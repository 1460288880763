import { Segmented } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UserInfo from "./components/UserInfo";
import Options from "./components/Options";
import { LicenseDataProvider } from "../../contexts/license/LicenseDataContext";

const Profile: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [segment, setSegment] = useState("userInfo");

  return (
    <div
      style={{
        marginTop: "-20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <LicenseDataProvider>
        <div style={{ width: "500px" }}>
          <Segmented
            onChange={(i: any) => {
              setSegment(i);
            }}
            block
            options={[
              { label: t("userInfo"), value: "userInfo" },
              { label: t("options"), value: "options" },
            ]}
          />
          <hr style={{ marginTop: "-2px" }} />
        </div>
        {segment == "userInfo" ? <UserInfo /> : <Options />}
      </LicenseDataProvider>
    </div>
  );
};
export default Profile;
