import { Table,Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation, } from "react-i18next";
import { useState,useEffect } from "react";
import moment from "moment";
import { JunctionDetailModel } from "./JunctionStatusInformation";
import { SyncOutlined } from "@ant-design/icons";
import { useJunctionEvent } from "../../../contexts/junction/JunctionEventContext";

interface Props {
  junctionDetail?: JunctionDetailModel;
  refresh?: any;
}



function AiPlanFlow(props: Props) {
  const { t } = useTranslation();
  const { junctionDetail,refresh } = props;
  const [dataSource, setDataSource] = useState<any[]>([]);
  const event = useJunctionEvent();
 useEffect(() => {
    if (junctionDetail!=undefined)
        setDataSource(() => junctionDetail.AIFlow);
  }, [junctionDetail])

  const columns: ColumnsType<any> = [
    {
      title: t("transaction_id"),
      dataIndex: "transaction_id",
      key: "transaction_id",
      align: "center",
    },
    {
      title: t("message"),
      dataIndex: "message",
      key: "message",
      align: "center",
    },
    {
      title: `${t("status")}`,
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Time",
      dataIndex: "update",
      responsive: ["md"],
      render: (_, record: any) => (
        <>
            {moment(parseInt(record.ts)*1000).utcOffset(localStorage.getItem("timezone")!).format('DD/MM/YYYY HH:mm')}
        </>
      )
    }
  ];
  
  return (
    <>
     <Button type="default" style={{float:"right",margin:"10px"}} onClick={refresh} shape="circle" icon={<SyncOutlined /> }  />
        <Table
          rowKey={"key"}
          style={{ height: "100%", marginTop:"10px" }}
          dataSource={dataSource}
          columns={columns}
          size="small"
          tableLayout="fixed"
        />
    </>
  );
}

export default AiPlanFlow;
