import { Result } from "antd";
import { t } from "i18next";

function InventoryTasks() {
  return (
    <Result
      status="404"
      title={t("not_task_found")}
      subTitle={t("try_again_task")}
    />
  );
}

export default InventoryTasks;
