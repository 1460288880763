import { useEffect, useMemo, useState } from "react";
import { Junction } from "../../../../data/junction/Junction";
import { LeafletMouseEvent } from "leaflet";
import { Marker, Tooltip, useMap } from "react-leaflet";
import withLogging from "../../high-order-components/LoggerHOC";
import GenericMapModal from "../../components/generic/map-modal/GenericMapModal";
import JunctionMain from "../../../junction";
import JunctionModalTitle from "./JunctionModalTitle";
import { useJunctionData } from "../../../../contexts/junction/JunctionDataContext";
import { useAllStatusData } from "../../../../contexts/device/AllStatusDataContext";
import { useWebsocket } from "../../../../contexts/websocket/WebSocketContext";
import { JunctionStatusDataProvider } from "../../../../contexts/junction/JunctionStatusDataContext";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import GenericMarkerDeleteModal from "../../components/generic/marker-context-modal/GenericMarkerDeleteModal";
import { t } from "i18next";
import { useMultipleWatchData } from "../../../../contexts/map/MultipleWatchContext";
import { JunctionStatusInfo } from "../../map-constants/module-status/JunctionStatusIno";
import { MapModule } from "@icms/ui-components"

function JunctionLayer() {
  const { junctionList, removeJunction } = useJunctionData();
  const { allStatusData } = useAllStatusData();
  const { addSelectedItem } = useMultipleWatchData();
  const { setLayerData } = useCustomLayerData();
  const { SetJunction, FreeJunction, FreeMultipleJunction } = useWebsocket();
  const [markerData, setMarkerData] = useState<Junction[]>([]);
  const [selectedJunction, setSelectedJunction] = useState<Junction>();
  const [isUpdateTabJunction, setIsUpdateTabJunction] =
    useState<boolean>(false);

  const map = useMap();

  const markerHandlers = (junction: Junction) => {
    return {
      click: (event: LeafletMouseEvent) =>
        addSelectedItem(event, junction, "junction"),
      dblclick: (event: LeafletMouseEvent) =>
        handleMarkerDblClick(event, junction),
      contextmenu: (event: LeafletMouseEvent) =>
        handleMarkerContextMenu(junction),
    };
  };

  const updateJunction = (junction: Junction) => {
    setIsUpdateTabJunction(true);
    setSelectedJunction(junction);
  };

  const deleteJunction = (junction: Junction) => {
    GenericMarkerDeleteModal(junction.name, () => {
      removeJunction(junction);
    });
  };

  const handleMarkerDblClick = (
    event: LeafletMouseEvent,
    junction: Junction
  ) => {
    SetJunction(junction.id);
    setSelectedJunction(junction);
  };

  const handleMarkerContextMenu = (junction: Junction) => {
    map.contextmenu.removeAllItems();
    map.contextmenu.addItem({
      text: t("update"),
      callback: () => updateJunction(junction),
    });
    map.contextmenu.addItem({
      text: t("delete"),
      callback: () => deleteJunction(junction),
    });
  };

  const onCloseModal = () => {
    setIsUpdateTabJunction(false);
    setSelectedJunction(undefined);
    FreeJunction();
    FreeMultipleJunction();
  };

  useEffect(() => {
    let findedJunction = markerData.find(
      (item) => item.id == allStatusData[allStatusData.length - 1].id
    );
    if (findedJunction) {
      findedJunction.status_id =
        allStatusData[allStatusData.length - 1].status_id;
      setMarkerData((prev) =>
        prev.map((item) =>
          item.id === findedJunction?.id ? findedJunction : item
        )
      );
    }
  }, [allStatusData]);

  useEffect(() => {
    setLayerData({
      id: MapModule.JunctionModule,
      data: junctionList,
      searchable: {
        category: "junction",
        filterProperties: ["id", "name"],
        labelProperty: "name",
      },
      legend: {
        countProperty: "status_id",
        setOnMapData: setMarkerData,
      },
    });
    setMarkerData(junctionList);
  }, [junctionList]);

  const modalContent = useMemo(() => {
    return (
      <>
        {selectedJunction && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <JunctionStatusDataProvider>
                <JunctionModalTitle
                  componentName="JunctionModalTitle"
                  selectedJunction={selectedJunction}
                />
              </JunctionStatusDataProvider>
            }
          >
            <JunctionMain
              selectedJunction={selectedJunction}
              setSelectedJunction={setSelectedJunction}
              selectedTab={
                selectedJunction.is_dummy || isUpdateTabJunction ? "4" : "1"
              }
            />
          </GenericMapModal>
        )}
      </>
    );
  }, [selectedJunction]);

  return (
    <>
      {markerData.length > 0 &&
        markerData.map((junction) => {
          return (
            <Marker
              key={junction.id}
              position={[junction.latitude, junction.longitude]}
              icon={
                junction.listen_mode && junction.status_id === 5
                  ? JunctionStatusInfo[10].marker
                  : junction.is_dummy
                  ? JunctionStatusInfo[11].marker
                  : JunctionStatusInfo[junction.status_id].marker
              }
              eventHandlers={markerHandlers(junction)}
              contextmenu={true}
            >
              <Tooltip>{junction.name}</Tooltip>
            </Marker>
          );
        })}
      {modalContent}
    </>
  );
}

export default withLogging(JunctionLayer);
