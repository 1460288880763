import { Col, Row } from "antd";
import { t } from "i18next";
import React from "react";

interface ActivityListProps {
  activityList: any[];
  userList: any[];
}

export const ActivityList = ({ activityList, userList }: ActivityListProps) => {
  const userLanguage = navigator.language || navigator.languages;

  return (
    <div style={{ maxHeight: "400px", overflow: "scroll" }}>
      {activityList.length > 0 ? (
        activityList.map((activity: any) => {
          const commentedUser = userList.find(
            (user) => parseInt(user.id) === parseInt(activity.user_id)
          );
          const renderDateSection = () => {
            const activityDate = new Date(parseInt(activity.date));

            const activityTime = activityDate.toLocaleDateString(userLanguage, {
              day: "2-digit",
              month: "long",
              hour: "numeric",
              minute: "numeric",
            });
            const today = new Date();
            // Calculate the difference in milliseconds
            const differenceInMs = today.getTime() - activityDate.getTime();

            // Convert milliseconds to days
            const differenceInDays = Math.floor(
              differenceInMs / (1000 * 60 * 60 * 24)
            );
            return (
              <span>
                {activityTime}{" "}
                {differenceInDays < 30 &&
                  differenceInDays > 1 &&
                  `(${differenceInDays} ${t("days_ago")})`}
              </span>
            );
          };
          return (
            <div key={activity.id} style={{ paddingLeft: "1rem" }}>
              <Row>
                <Col>
                  <div className="activity-bullet"></div>
                  <div className="activity-seperator"></div>
                </Col>
                <Col className="task-activity">
                  <span style={{ fontWeight: 500 }}>{commentedUser?.name}</span>
                  {activity.activity}
                  <span>{renderDateSection()}</span>
                </Col>
              </Row>
            </div>
          );
        })
      ) : (
        <div
          style={{
            alignSelf: "center",
            color: "#666666",
          }}
        >
          {t("no_comment_found")}
        </div>
      )}
    </div>
  );
};
