import http from "../../../data/http.common";

//For FCD Data Config Cluster ID

export interface FCDClusterOutput {
  clusterId: number;
}

export const fcdJunction = async (
  formOutput: FCDClusterOutput
) => {
  return http.post("/web/junction/cluster/getConfigurationFile", {
    clusterId:formOutput
  });
};
