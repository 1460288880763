import {
  DownloadOutlined,
  FieldTimeOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  SwapOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Card, Col, Divider, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Junction } from "../../../data/junction/Junction";
import DownloadProgram from "./DownloadProgram";
import Info from "./Info";
import Mode from "./Mode";
import Reset from "./Reset";
import TimeDate from "./TimeDate";
import UploadProgram from "./UploadProgram";
import { DeviceStateProvider } from "../../../contexts/device/DeviceStateContext";
import { JunctionStatusDataProvider } from "../../../contexts/junction/JunctionStatusDataContext";

interface Props {
  selectedJunction?: Junction;
}

function Manage(props: Props) {
  const { t } = useTranslation();
  const { selectedJunction } = props;
  const [kkcType, setKkcType] = useState<number>();

  return (
    <DeviceStateProvider>
      <div style={{ display: "flex", justifyContent: "center" }} id="deviceManagement">
        <Row
          gutter={[24, 24]}
          style={{ width: "100%", padding: "20px" }}
          justify="center"
        >
          {[
            {
              icon: <InfoCircleOutlined />,
              title: t("device_informations"),
              component: (
                <Info selectedJunction={selectedJunction} setKkcType={setKkcType} />
              ),
            },
            {
              icon: <SwapOutlined />,
              title: t("status_n_mode"),
              component: (
                <JunctionStatusDataProvider>
                  <Mode selectedJunction={selectedJunction} />
                </JunctionStatusDataProvider>
              ),
            },
            {
              icon: <FieldTimeOutlined />,
              title: t("date_n_time"),
              component: <TimeDate selectedJunction={selectedJunction} />,
            },
            {
              icon: <SettingOutlined />,
              title: t("reset"),
              component: <Reset selectedJunction={selectedJunction} />,
            },
            {
              icon: <UploadOutlined />,
              title: t("install_program"),
              component: <UploadProgram selectedJunction={selectedJunction} />,
            },
            {
              icon: <DownloadOutlined />,
              title: t("download_program"),
              component: (
                <DownloadProgram selectedJunction={selectedJunction} kkcType={kkcType!} />
              ),
            },
          ].map((item, index) => (
            <Col
              key={index}
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                title={
                  <>
                    {item.icon}
                    {item.title}
                    <Divider />
                  </>
                }
                headStyle={{
                  fontWeight: "bold",
                  backgroundColor: "#2f4050",
                  color: "#ffffff",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
                bodyStyle={{
                  backgroundColor: "#2f4050",
                  color: "#ffffff",
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                  height: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  borderRadius: 10,
                  transition: "transform 0.3s",
                }}
                hoverable={true}
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
              >
                {item.component}
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </DeviceStateProvider>
  );
}

export default Manage;
