import React from "react";
import StyledButton from "./StyledButton";

export interface CustomButtonProps {
  icon?: React.ReactNode;
  customIcon?: React.ReactNode;
  title?: string;
  onClick?: () => void;
  css?: string;
  color?: string;
}

function CustomButton(props: CustomButtonProps) {
  const { icon, color, onClick, customIcon, css } = props;

  return (
    <StyledButton
      type="primary"
      icon={icon}
      backgroundColor={color}
      borderColor={color}
      boxShadow="0 0 5px rgb(47 64 80 / 70%)"
      margin="0 0 0 10px"
      css={css}
      onClick={onClick}
    >
      {customIcon && icon}
    </StyledButton>
  );
}

export default CustomButton;
