import { TTypeBasedGroupedPlanOutput } from "./components/DayContainer";

// height variables exist to scale and display time slices properly
export const dayNameHeight: number = 50;
export const planTypeSeperatorHeight: number = 50;
export const planTypeDetailSeperatorHeight: number = 50;
export const schedularHeaderHeight =
  dayNameHeight + planTypeSeperatorHeight + planTypeDetailSeperatorHeight;

export const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const MULTI_MODE_TYPES: (keyof TTypeBasedGroupedPlanOutput)[] = [
  "chaos",
  "discharge",
  "cfm",
  "coord",
];

export const SCHEDULAR_DISPLAY_MINUTES_TIME_LIMIT = 2;

export const colorPallet = [
  "#000",
  "#f89c30",
  "#2f4050",
  "#dc3856",
  "#6a7b9e",
  "#3f51b5",
  "#436b59",
  "#FFA977",
  "#36a6d6",
  "#74A9B3",
  "#067F94",
  "#F7DCCC",
  "#FC3449",
  "#71C8C1",
  "#FAB648",
  "#FA6648",
];

export const SCHEDULAR_MARGIN_LEFT = 38;
