import L from "leaflet";

export const BluesisStatusInfo = {
  1: {
    text: "Active",
    marker: L.icon({
      iconUrl: `/static/assets/map-markers/bluesis.png`,
      iconSize: [42,42],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
  },
  2: {
    text: "Passive",
    marker: L.icon({
      iconUrl: `/static/assets/map-markers/bluesis-p.png`,
      iconSize: [42,42],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
  },
  3: {
    text: "Virtual",
    marker: L.icon({
      iconUrl: `/static/assets/map-markers/bluesis-virtual.png`,
      iconSize: [42,42],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
  },
};
