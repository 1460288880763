const colors = [
    "#17202A", //karanlık -1
    "#FE0000", //kırmızı -2
    "#FFE000", //sarı - 3
    "#FFFF00", //kırmızı sarı - 4 // gradient olacak yapılacak //yapıldı
    "#009000", //yeşil -5
    "#FD0000", //kırmızı flaş 1 -6 // flaş animasyon 1 saniye yapılcak pattern olabilir
    "#FFF000", //sarı flaş 1 -7 // flaş animasyon 1 saniye yapılcak pattern olabilir
    "#008100", //yeşil flaş 1 - 8 // flaş animasyon 1 saniye yapılcak pattern olabilir
    "#7B1107", //kırmızı flaş 2 - 9 // flaş animasyon 2 saniye yapılcak pattern olabilir
    "#FFC300", //sarı flaş 2 -10 // flaş animasyon 2 saniye yapılcak pattern olabilir
    "#148725", //yeşil flaş 2 -11 // flaş animasyon 2 saniye yapılcak pattern olabilir
    "#FFFFFF", //BEYAZ 12
    "#0000FF", //BLUE 12
  ];



var countObjectID=0;
class svgObject{
    objName="svgObject";
    id=0;
    type=0;  

    //default color for all object if not defined
    color="white";
    hoverColor="red";
    selectedColor="blue";

    isMouseHold=false;
    x=0;
    y=0;
    isHold=false;
    isHover=false;
    variables=[];

    constructor(x,y) {
        this.id=++countObjectID;
        this.x=x;
        this.y=y;
        return this;
    }

    move(x,y)
    {
        this.x=x;
        this.y=y;    
    }
} 


class svgPoint extends svgObject{ 
  objName="svgPoint";
  constructor(x,y) {
      super(x,y);
      this.type=1;
  }
} 


class svgRectangle{
  color;
  typeId;
  objName="svgRectangle";
  x;
  y;
  width;
  height;
  info;
  data;
  constructor(x,y,width,height,c,info) {
          this.x = x;
          this.y = y;
          this.height = height;
          this.width = width;
          this.color=c;
          this.info=info;
  }

} 




class SvgConfig {
  constructor() {
    this.ResolutionX = 0;
    this.ResolutionY = 0;
    this.TimeRangeSecond = 0;
    this.GroupLength = 0;
    this.OffsetX = 0;
    this.OffsetY = 0;
    this.GroupHeight = 0;
    this.GroupPad = 0;
  }

  setConf({ ResolutionX, ResolutionY, TimeRangeSecond, GroupLength, OffsetX, OffsetY, GroupHeight,GroupPad }) {
    this.ResolutionX = ResolutionX;
    this.ResolutionY = ResolutionY;
    this.TimeRangeSecond = TimeRangeSecond;
    this.GroupLength = GroupLength;
    this.OffsetX = OffsetX;
    this.OffsetY = OffsetY;
    this.GroupHeight = GroupHeight;
    this.GroupPad=GroupPad;
  }
}






  export {colors,svgObject,svgRectangle,svgPoint,SvgConfig}
