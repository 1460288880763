import http from "../http.common";
import { Bluesis } from "./Bluesis";
import { BluesisRouteReq, BluesisRouteRes, Route, VectorInfo } from "./Route";
import { RouteDetail } from "./RouteDetail";
import { TravelTimeResult } from "./TravelTimeResult";
import { Vector } from "./Vector";

const getBluesises = function () {
  return http.get<Bluesis[]>(`/web/bluesis/bluesis/list`);
};

const getRoutes = function () {
  return http.get<Route[]>(`/web/bluesis/bluesis/listroutes`);
};

const getRouteDetails = function (id: number) {
  return http.post<RouteDetail[]>(`/web/bluesis/bluesis/listroutedetails`, {
    device_id: id,
  });
};

const getVectors = function () {
  return http.get<Vector[]>(`/web/bluesis/bluesis/listvectors`);
};

const getTravelTimeResults = async function (
  startTime: number,
  endTime: number,
  devices: [number, number][]
) {
  return http.post<any>(`/web/bluesis/bluesisanalysis/gettraveltime`, {
    startTs: startTime,
    endTs: endTime,
    devices: devices,
  });
};

const getMacCount = function (
  deviceId: number,
  startTime: number,
  endTime: number
) {
  return http.post<any[]>(`/web/bluesis/bluesisanalysis/getmaccount`, {
    deviceId: deviceId,
    startTime: startTime,
    endTime: endTime,
  });
};

const updateBluesis = function (bluesis: Bluesis) {
  return http.post<Bluesis>(`/web/bluesis/bluesis/updateBluesis`, bluesis);
};

const updateRoute = function (route_id: number, name: string) {
  return http.post<{ id: number; name: string }>(
    `/web/bluesis/bluesis/updateroute`,
    {
      id: route_id,
      name: name,
    }
  );
};

const deleteBluesis = function (id: number) {
  return http.post<number>(`/web/bluesis/bluesis/deleteBluesis`, { id: id });
};

const deleteRoute = function (id: number) {
  return http.post<number>(`/web/bluesis/bluesis/deleteroute`, { id: id });
};

const getVectorsFromGeoserver = function (bbox: number[]) {
  return http.get<object>(
    `http://localhost:8080/geoserver/local/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=local%3AGet_Duplicate_Routes_Test&outputFormat=application%2Fjson&viewparams=swLng:${bbox[0]};swLat:${bbox[1]};nwLng:${bbox[2]};nwLat:${bbox[3]};`
  );
};

const addBluesis = function (bluesis: Bluesis) {
  return http.post<Bluesis>(`/web/bluesis/bluesis/addbluesis`, bluesis);
};

const getBluesisRoute = function (req: BluesisRouteReq) {
  return http.post<BluesisRouteRes>(`/gate/bluesis/getRoute`, req);
};

const addBluesisRoute = function (req: { name: string; orgId: number, vectorList: VectorInfo[]}) {
  return http.post<boolean>(`/web/bluesis/bluesis/addroute`, req);
};

const BluesisService = {
  getBluesises,
  getRoutes,
  getRouteDetails,
  getVectors,
  getTravelTimeResults,
  getMacCount,
  getBluesisRoute,
  updateBluesis,
  updateRoute,
  deleteBluesis,
  deleteRoute,
  getVectorsFromGeoserver,
  addBluesis,
  addBluesisRoute,
};

export default BluesisService;
