import { Button, Checkbox, Form, Modal } from "antd";
import { useContext } from "react";

import { PlanContext } from "../../../context/PlanContext";

import days from "../../../common/days";
import { useTranslation } from "react-i18next";

interface Props {
  visible: boolean;
  copyIndex: null | number;
  clearModal: () => void;
}

const inRange = (number: number) => {
  const min = 0;
  const max = 6;

  if (number >= min && number <= max) {
    return true;
  }
  return false;
};

const DayCopyPasteModal: React.FC<Props> = ({
  visible,
  copyIndex,
  clearModal,
}) => {
  const { t } = useTranslation();
  const planContextValues = useContext(PlanContext);
  const [, setContextSignalPlan] = planContextValues.signalPlan;

  const copyPasteDailyPlan = (copyIndex: number, pasteIndexes: number[]) => {
    let pasteIndexesAppropriate = true;
    for (let i = 0; i < pasteIndexes?.length; i++) {
      if (!inRange(pasteIndexes[i])) {
        pasteIndexesAppropriate = false;
        break;
      }
    }

    if (pasteIndexesAppropriate && inRange(copyIndex)) {
      setContextSignalPlan((prev: any) => ({
        ...prev,
        dailyPlans: prev?.dailyPlans?.map(
          (plansOfTheDay: any, indexOfTheDay: number) => {
            if (pasteIndexes.includes(indexOfTheDay)) {
              return {
                ...plansOfTheDay,
                plans: prev?.dailyPlans?.[copyIndex]?.plans
              };
            }
            return plansOfTheDay;
          }
        ),
      }));

      clearModal();
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        title={t("day_selection")}
        onOk={() => {
          clearModal();
        }}
        onCancel={() => {
          clearModal();
        }}
        centered
      >
        {
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={(formVals: any) => {
              const items = Object.keys(formVals)
                .map((item) => +item)
                .filter((item) => formVals[item]);

              if (items?.length > 0) {
                copyPasteDailyPlan(
                  copyIndex!,
                  Object.keys(formVals)
                    .map((item) => +item)
                    .filter((item) => formVals[item])
                );
              }
            }}
            autoComplete="off"
          >
            {days?.map((day: string, dayIndex: number) => (
              <div key={`${day}-${dayIndex}`}>
                {dayIndex !== copyIndex && (
                  <Form.Item
                    name={dayIndex}
                    valuePropName="checked"
                    wrapperCol={{ offset: 8, span: 16 }}
                  >
                    <Checkbox>{t(day)}</Checkbox>
                  </Form.Item>
                )}
              </div>
            ))}

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                { t("apply") }
              </Button>
            </Form.Item>
          </Form>
        }
      </Modal>
    </>
  );
};

export default DayCopyPasteModal;
