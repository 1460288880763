import React, { useEffect, useState } from "react";
import ImageUploader from "./ImageUploader";
import ImageWithParkingPoints from "./ImageWithParkingPoints";
import { JunctionDetector } from "../../../data/junction-detector/JunctionDetector";
import DeviceService from "../../../data/device/DeviceService";
import { Button, Col, Popconfirm, Popover, Row, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

interface Props {
  selectedVieroDevice:JunctionDetector;
}

const ParkingConfiguration: React.FC<Props> = ({selectedVieroDevice}) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedPoints, setSelectedPoints] = useState<
    { x: number; y: number, rotation:number }[]
  >([]);
  const [existImageUrl, setExistImageUrl] = useState<any>();
  const [configResult, setConfigResult] = useState<any>();


  const handleImageSelect = (file: File) => {
    setSelectedImage(file);
    setSelectedPoints([]);
  };

  const handlePointClick = (point: { x: number; y: number; rotation:number }) => {
    setSelectedPoints((prevPoints) => [...prevPoints, point]);
  };

  const handleSubmit = () => {
    addConfig(selectedVieroDevice.id_device, selectedPoints);
   
    if(selectedImage){
      const formData = new FormData();
      formData.append("file", selectedImage);
      uploadImageAndWriteDb(formData,selectedVieroDevice.id_device)
    }
  };


  const uploadImageAndWriteDb = async (file:any,camera_device_id: number) => {
    try {
      const response = await DeviceService.fileUpload(file,camera_device_id);
    } catch (error) {
      console.log(error);
    }
  };


  const addConfig = async (
    device_id:number, points:any
  ) => {
    await DeviceService.addParkingCameraConfig(device_id, points)
      .then((response) => {
        response.data == true ? message.success("Config added successfully"): message.warning("Config can not added.");
      })
      .catch((err) => console.log(err));
  };
  const handlePointRemove = (id: number) => {
    const updatedPoints = selectedPoints.filter(
      (point: any) => point.id !== id
    );
    setSelectedPoints(updatedPoints);
  };

  const getParkingFile = async (camera_device_id: number) => {
    try {
      const response = await DeviceService.getParkingCameraFile(camera_device_id);
      const serve_path_data = response.data[0].serve_path;
      var origin = (window.location.origin === 'http://localhost:8081') ? 'http://localhost:3000' : window.location.origin;
      setExistImageUrl(origin+serve_path_data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    getParkingFile(selectedVieroDevice.id_device);
  },[selectedVieroDevice.id_device]);


  const getConfig = async (device_id: number) => {
    try {
      const response = await DeviceService.getParkingCameraConfig(device_id);
      const configData = response.data[0];
      setConfigResult(configData);
      setSelectedPoints(configData.parking_points);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getConfig(selectedVieroDevice.id_device);
  }, [selectedVieroDevice.id_device]);

  const handleRotationChange = (id: number, rotation: number) => {
    const updatedPoints = selectedPoints.map((point:any) =>
      point.id === id ? { ...point, rotation } : point
    );
    setSelectedPoints(updatedPoints);
  };

  const handleMovePoint = (id: number, newPosition: { x: number; y: number }) => {
    const updatedPoints = selectedPoints.map((point:any) =>
    point.id === id ? { ...point, x: newPosition.x, y: newPosition.y } : point
  );
  setSelectedPoints(updatedPoints);

  };

  const handleCancel = () => {
    message.info('Submit operation cancelled.');
  };

  const infoPopoverContent = (
    <div>
      <ul>
        <li>To use the Parking Editor, you must first upload an image file, or if you have previously created a configuration, you can perform your operations on your existing image.</li>
        <li>After uploading your image file, enter the parking number in the "Enter Id" field at the top left corner of the image to add parking points.</li>
        <li>Once you've entered the parking ID, click on the desired location on the image to add a point.</li>
        <li>Clicking on the added points opens a menu where you can delete, rotate, or reposition the selected point. You can also use arrow keys for repositioning.</li>
        <li>After completing all your actions, don't forget to click the Submit button to finalize your configuration.</li>
      </ul>
    </div>
  );
  
  

  return (
    <div>
       <Popover content={infoPopoverContent} title="Parking Editor Info" trigger="click" placement="right">
            <Button icon={<InfoCircleOutlined />}>Parking Editor Info</Button>
          </Popover>
       <Row justify="start" style={{ marginBottom: 16 }}>
        <Col>
          <ImageUploader onImageSelect={handleImageSelect} />
        </Col>
      </Row>
      {selectedImage ? 
      (
        <ImageWithParkingPoints
          imageUrl={URL.createObjectURL(selectedImage)}
          onPointClick={handlePointClick}
          onPointRemove={handlePointRemove}
          existPoints={selectedPoints}
          onRotationChange={(id, rotation) => handleRotationChange(id, rotation)}
          onPointMove={handleMovePoint}

        />
      ):
        
      (existImageUrl && configResult) ? 
      (
        <ImageWithParkingPoints
          imageUrl={existImageUrl}
          onPointClick={handlePointClick}
          onPointRemove={handlePointRemove}
          existPoints={selectedPoints}
          onRotationChange={(id, rotation) => handleRotationChange(id, rotation)}
          onPointMove={handleMovePoint}

        />
      ):
      
      null}

    {(selectedImage || (existImageUrl && configResult)) ? (
      <Row justify="start" style={{ marginTop: 16 }}>
      <Col>
         <Popconfirm
         title="Are you sure?"
         onConfirm={handleSubmit}
         onCancel={handleCancel}
         okText="Yes"
         cancelText="No"
       >
         <Button type="primary">Submit</Button>
       </Popconfirm>
       </Col>
        </Row>
        ):null}
       
    </div>
  );
};

export default ParkingConfiguration;
