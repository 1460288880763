import { LayerControl } from "@icms/ui-components";
import L from "leaflet";

export const deviceList: any = [
  { key: 1, value: 1, label: "test" },
  { key: 2, value: 2, label: "test2" },
];

export const centrisVersion: any = [
  { key: "1.1", value: "1.1", label: "1.1" },
  { key: "2.0", value: "2.0", label: "2.0" },
];
export const layerJunction = new L.LayerGroup();
export const layerGroup = new L.LayerGroup();
export const layerDetector = new L.LayerGroup();
export const layerOther = new L.LayerGroup();




export enum MapLayer {
  Groups = 1,
  Detectors,
  Others
}
export const mapLayers: LayerControl[] = [
  { name: "Gruplar", layer: layerGroup, id: MapLayer.Groups, isChecked: true },
  { name: "Dedektörler", layer: layerDetector, id: MapLayer.Detectors, isChecked: true },
  { name: "Diğerleri", layer: layerOther, id: MapLayer.Others, isChecked: true }
];
export enum MapCtxMenu {
  AddGroup = 1,
}