import { MapContainer, Marker, Tooltip, GeoJSON, Popup } from "react-leaflet";
import MapTileControl from "../../map/controls/bottom-right-control/MapTileControl";
import { Bluesis } from "../../../data/bluesis/Bluesis";
import { useEffect, useRef, useState } from "react";
import { useBluesisData } from "../../../contexts/bluesis/BluesisDataContext";
import { BluesisStatusInfo } from "../../map/map-constants/module-status/BluesisStatusInfo";
import BluesisService from "../../../data/bluesis/BluesisService";
import {
  BluesisRouteReq,
  BtrPoint,
  VectorInfo,
  Waypoint,
} from "../../../data/bluesis/Route";
import { Steps, Button, Input, Spin, message, Form, Select } from "antd";
import L, { LatLngExpression, LeafletMouseEvent } from "leaflet";
import "leaflet/dist/leaflet.css";
import { DeploymentUnitOutlined, LoadingOutlined } from "@ant-design/icons";
import wellknown, { GeoJSONGeometryOrNull, GeoJSONPosition } from "wellknown";
import { t } from "i18next";
import { useAuth } from "../../../contexts/auth/AuthContext";
import { GenericModalProps } from "../../map/components/generic/marker-context-modal/MarkerGenericAddUpdateContent";
import { Vector } from "../../../data/bluesis/Vector";
import { Tooltip as Tt } from "antd";

const pointIcon = new L.DivIcon({
  className: "custom-div-icon",
  html: "<div class='point-marker'></div>",
  iconSize: [10, 10],
  iconAnchor: [5, 5],
});

function NewRoute(props: GenericModalProps) {
  const geoJsonLayer = useRef<any>(null);
  const { latlon, setModalVisible } = props;
  const { allOrganizations } = useAuth();
  const { bluesisList, vectorList, updateRoutes } = useBluesisData();
  const [markerData, setMarkerData] = useState<Bluesis[]>([]);
  const [selectedMarkers, setSelectedMarkers] = useState<Bluesis[]>([]);
  const [createdRoute, setCreatedRoute] = useState<GeoJSONGeometryOrNull>(null);
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [routeName, setRouteName] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vectors, setVectors] = useState<VectorInfo[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<number>(
    allOrganizations[0].value
  );
  const [vectorMarkerData, setVectorMarkerData] = useState<Vector[]>([]);
  const [visibleRoute, setVisibleRoute] = useState<boolean>(true);

  useEffect(() => {
    setMarkerData(bluesisList);
  }, [bluesisList]);

  useEffect(() => {
    setVectorMarkerData(vectorList);
  }, [vectorList]);

  useEffect(() => {
    if (geoJsonLayer.current) {
      geoJsonLayer.current.clearLayers().addData(createdRoute);
    }
}, [createdRoute]);

  const handleMarkerClick = (event: LeafletMouseEvent, bluesis: Bluesis) => {
    setSelectedMarkers((prev) => {
      let element = event.target.getElement();
      const existingMarkerIndex = prev.findIndex(
        (marker) => marker.device_id === bluesis.device_id
      );
      if (existingMarkerIndex !== -1) {
        if (element) {
          L.DomUtil.removeClass(element, "selectedModule");
        }
        const updatedMarkers = [...prev];
        updatedMarkers.splice(existingMarkerIndex, 1);
        return updatedMarkers.map((marker, index) => ({
          ...marker,
          id: index + 1,
        }));
      } else {
        if (element) {
          L.DomUtil.addClass(element, "selectedModule");
        }
        return [
          ...prev,
          {
            ...bluesis,
            id: prev.length + 1,
          },
        ];
      }
    });
  };
  const handleDragEnd = (event: L.DragEndEvent, idx: number) => {
    const { lat, lng } = event.target.getLatLng();

    const newWKT = `POINT (${lng} ${lat})`;

    setWaypoints((prevWaypoints) => {
      const updatedWaypoints = [...prevWaypoints];
      updatedWaypoints[idx] = {
        ...updatedWaypoints[idx],
        WKT: newWKT,
      };
      return updatedWaypoints;
    });
  };

  const getPointWKTCoordinate = (wkt: string): LatLngExpression | null => {
    let geojson = wellknown.parse(wkt);
    if (geojson && geojson.type === "Point") {
      const coordinates: GeoJSONPosition = geojson.coordinates;
      return {
        lat: coordinates[1],
        lng: coordinates[0],
      };
    }
    return null;
  };

  const getRoutes = async () => {
    setIsLoading(true);
    const startCoordinates = selectedMarkers[0];
    const endCoordinates = selectedMarkers[selectedMarkers.length - 1];
    const coordinates: BtrPoint[] = selectedMarkers.map((item) => {
      return {
        btrId: item.device_id,
        WKT: latLongToWKTPoint(item.latitude, item.longitude),
      };
    });

    const requestParams: BluesisRouteReq = {
      btrCoordinates: coordinates,
      originBtr: startCoordinates.device_id,
      destinationBtr: endCoordinates.device_id,
      funcName: "generateBluetoothVectors",
    };

    if (waypoints.length > 0) {
      requestParams.waypoints = waypoints;
    }
    await BluesisService.getBluesisRoute(requestParams)
      .then((res) => {
        setVectors(res.data.vectorList);
        setCreatedRoute(wellknown.parse(res.data.route.WKT));
        setWaypoints(res.data.waypoints);
        setCurrentStep(1);
        let mes =
          res.data.warnId && res.data.warnId.length > 0
            ? t(`${res.data.message}`)
            : t(`${res.data.message}`) +
              ` :${res.data.warnId && res.data.warnId!.join(", ")}`;
        message.info(mes);
      })
      .catch((err) => {
        message.error(t("not_create_route"));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleNextStep = () => {
    if (currentStep === 0 && selectedMarkers.length >= 2) {
      getRoutes();
    } else if (currentStep === 1) {
      setRouteName(
        `${selectedMarkers[0].name} - ${
          selectedMarkers[selectedMarkers.length - 1].name
        }`
      );
      setCurrentStep(2);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const handleRouteSubmission = async () => {
    setIsLoading(true);
    let params = {
      name: routeName,
      orgId: selectedOrgId,
      vectorList: vectors,
    };
    await BluesisService.addBluesisRoute(params)
      .then((res) => {
        message.success(t("success_add_route"));
        updateRoutes();
        setModalVisible();
      })
      .catch((err) => {
        message.error(t("err_add_route"));
      })
      .finally(() => {
        setSelectedMarkers([]);
        setCreatedRoute(null);
        setWaypoints([]);
        setCurrentStep(0);
        setIsLoading(false);
      });
  };

  const latLongToWKTPoint = (lat: number, long: number) => {
    return `POINT(${long} ${lat})`;
  };

  const markerList = () => {
    return (
      <div style={{ marginTop: "20px", marginBottom: "10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            overflowX: "auto",
          }}
        >
          {selectedMarkers.map((marker, index) => (
            <div
              key={marker.id}
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
            >
              <span>
                {marker.device_id} - {marker.name}
              </span>
              {index < selectedMarkers.length - 1 && (
                <span style={{ margin: "0 10px", fontSize: "18px" }}>→</span>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };
  return (
    <Spin
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
    >
      <div style={{ height: "75vh", position: "relative" }}>
        <MapContainer
          id="bluesisRouteMap"
          style={{ height: "75vh" }}
          zoom={14}
          center={latlon}
          scrollWheelZoom={true}
        >
          <MapTileControl />
          {markerData.length > 0 &&
            markerData.map((bluesis) => (
              <Marker
                key={bluesis.id}
                position={[bluesis.latitude, bluesis.longitude]}
                icon={BluesisStatusInfo[bluesis.device_type].marker}
                eventHandlers={{
                  click: (e) => handleMarkerClick(e, bluesis),
                }}
              >
                <Tooltip>{bluesis.name}</Tooltip>
              </Marker>
            ))}
          <Tt
            placement="right"
            title={`Bluesis ${t("route")} ${t("on_off")}`}
          >
            <Button
              icon={<DeploymentUnitOutlined />}
              style={{
                color: "white",
                backgroundColor: visibleRoute ? "#00FF00" : "#2F4050",
                borderColor: visibleRoute ? "#00FF00" : "#2F4050",
                position: "absolute",
                top: "80px",
                left: "10px",
                zIndex: 10000,
              }}
              onClick={() => setVisibleRoute((prev) => !prev)}
            />
          </Tt>

          {visibleRoute &&
            vectorMarkerData.length > 0 &&
            vectorMarkerData.map((vector) => {
              return (
                <GeoJSON
                  key={vector.id}
                  data={JSON.parse(vector.geom)}
                  style={{ color: "#5E6871", weight: 5, opacity: 0.65 }}
                >
                  <Popup content={vector.name} />
                </GeoJSON>
              );
            })}
          {createdRoute && (
            <GeoJSON ref={geoJsonLayer} data={createdRoute!} style={{ color: "blue", weight: 5 }} />
          )}

          {waypoints.length > 0 &&
            waypoints.map((point, idx) => {
              let pos = getPointWKTCoordinate(point.WKT);
              if (pos) {
                return (
                  <Marker
                    key={idx}
                    position={pos}
                    draggable={true}
                    icon={pointIcon}
                    eventHandlers={{
                      dragend: (event) => handleDragEnd(event, idx),
                    }}
                  />
                );
              }
            })}
        </MapContainer>

        <div
          style={{
            position: "absolute",
            top: 10,
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "8px",
            zIndex: 1000,
            width: "60%",
          }}
        >
          <Steps current={currentStep}>
            <Steps.Step title={t("select_device")} />
            <Steps.Step title={t("confirm_route")} />
            <Steps.Step title={t("finish_process")} />
          </Steps>

          {currentStep === 0 && (
            <div>
              <p>{t("min_two_marker")}</p>
              {markerList()}
              <Button
                type="primary"
                onClick={handleNextStep}
                disabled={selectedMarkers.length < 2}
              >
                {t("next_step")}
              </Button>
            </div>
          )}

          {currentStep === 1 && (
            <div>
              <p>{t("is_approve_route")}</p>
              {markerList()}
              <Button
                onClick={() => getRoutes()}
                disabled={selectedMarkers.length < 2}
              >
                {t("new_route")}
              </Button>
              <Button type="primary" onClick={handleNextStep}>
                {t("next_step")}
              </Button>
            </div>
          )}

          {currentStep === 2 && (
            <div>
              <p>{t("info_add_route")}</p>
              <Input
                placeholder={t("route_name")}
                value={routeName}
                onChange={(e) => setRouteName(e.target.value)}
                style={{ marginBottom: "10px" }}
              />
              {allOrganizations.length > 1 && (
                <Form.Item label={t("organization")} name="org_id">
                  <Select
                    style={{ width: 120 }}
                    onChange={(value) => setSelectedOrgId(value)}
                    options={allOrganizations}
                  />
                </Form.Item>
              )}
              <div style={{ marginTop: "10px" }}>
                <Button onClick={handlePreviousStep}>{t("previous")}</Button>
                <Button
                  type="primary"
                  onClick={handleRouteSubmission}
                  style={{ marginLeft: "10px" }}
                >
                  {t("approve")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
}

export default NewRoute;