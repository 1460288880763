import http from "../http.common";
import { Task } from "./TaskManagement";

export const getTechnicianList = function () {
  return http.get(`/taskmanager/v1/user/list`);
};

export const getTasks = function () {
  return http.get<Task[]>(`/taskmanager/v1/task/list`);
};

export const getArchivedTasks = function (data: any) {
  return http.post(`taskmanager/v1/task/archived`, data);
};

export const addTask = function (task: Task) {
  return http.post<Task>(`/taskmanager/v1/task`, task);
};

export const updateTask = function (task: Task) {
  return http.put<Task>(`/taskmanager/v1/task/${task.id}`, task);
};

export const deleteTask = function (taskId: any) {
  return http.delete(`/taskmanager/v1/task/${taskId}`);
};

export const updateTaskStatusService = function (taskId: any, status: any) {
  return http.put(`/taskmanager/v1/task/status/${taskId}`, status);
};

export const getTaskComments = function (taskId: any) {
  return http.get(`/taskmanager/v1/comment/${taskId}`);
};

export const postComment = function (taskId: any, comment: any) {
  return http.post(`/taskmanager/v1/comment/${taskId}`, comment);
};

export const getTaskActivities = function (taskId: any) {
  return http.get(`/taskmanager/v1/activity/${taskId}`);
};

export const postActivity = function (taskId: any, activity: any) {
  return http.post(`/taskmanager/v1/activity/${taskId}`, activity);
};

export const getTaskImages = function (fileName: any) {
  return http.get(`/file/mobile/${fileName}`, {
    responseType: "arraybuffer",
  });
};

export const addTaskImage = function (taskId: any, data: FormData) {
  return http.post(`/taskmanager/v1/image/${taskId}`, data);
};
