import { Tabs } from "antd";
import { useState } from "react";
import { JunctionDetector } from "../../data/junction-detector/JunctionDetector";
import Information from "./Information";
import AiReporting from "./AiReporting";
import FisheyeReporting from "./FisheyeReporting";
import { t } from "i18next";
import ParkingConfiguration from "./parking-configuration-page";
import ParkingStream from "./parking-stream-page";
import { InstantParkingDataProvider } from "../../contexts/viero/ParkingDataContext";
import ReportForm from "../profile/ReportForm";

const { TabPane } = Tabs;
interface Props {
  selectedTab?: string;
  selectedVieroDevice?: JunctionDetector;
  selectedCamera?: JunctionDetector;
}

const VieroDeviceMain = (props: Props) => {
  const { selectedTab, selectedVieroDevice, selectedCamera } = props;
  const [activeKey, setActiveKey] = useState(selectedTab ?? "1");

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  console.log(selectedVieroDevice);

  return (
    <Tabs
      activeKey={activeKey}
      onChange={handleTabChange}
      destroyInactiveTabPane
    >
      <TabPane tab={t("device")} key="1">
        <Information
          selectedDevice={
            selectedVieroDevice ? selectedVieroDevice : selectedCamera
          }
        />
      </TabPane>
      {selectedVieroDevice &&
      selectedVieroDevice.devicetype_id !== 11 &&
      selectedVieroDevice.devicetype_id !== 14 ? (
        <TabPane tab={t("reporting")} key="2">
          {selectedVieroDevice?.devicetype_id == 9 ? (
            <AiReporting selectedVieroDevice={selectedVieroDevice} />
          ) : null}
          {selectedVieroDevice?.devicetype_id == 10 ? (
            <FisheyeReporting selectedVieroDevice={selectedVieroDevice} />
          ) : null}
        </TabPane>
      ) : null}
      {selectedVieroDevice && selectedVieroDevice.devicetype_id == 14 ? (
        <TabPane tab="Parking Editor" key="3">
          <ParkingConfiguration selectedVieroDevice={selectedVieroDevice} />
        </TabPane>
      ) : null}
      {selectedVieroDevice && selectedVieroDevice.devicetype_id == 14 ? (
        <TabPane tab="Parking Simulation" key="4">
          <InstantParkingDataProvider>
            <ParkingStream selectedVieroDevice={selectedVieroDevice} />
          </InstantParkingDataProvider>
        </TabPane>
      ) : null}
      <TabPane tab={t("reportBug")} key="7">
        <ReportForm
          initialSelectedTopic="5"
          releatedId={selectedVieroDevice?.id_device}
        />
      </TabPane>
    </Tabs>
  );
};

export default VieroDeviceMain;
