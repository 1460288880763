import { groupedPlansForWeek } from "../../components/Schedular";
import { checkOverlap, isInputValid } from "../../helpers";
import { IDischargePlan } from "../MultiModePlan";
import { StaticCtxDefaultPlans } from "../StaticCtxDefaultPlans";
import { IColGroup } from "./IColGroup";

export type TCameraIdsBasedGroupedPlanOutput = {
  dischargeIds: IDischargePlan["dischargeIds"];
  plans: IDischargePlan[];
}[];

export class DischargeColGroup implements IColGroup {
  plans: IDischargePlan[];
  cols: TCameraIdsBasedGroupedPlanOutput;
  private static groupedPlans: groupedPlansForWeek = {};

  constructor(plans: IDischargePlan[], dayIndex: keyof groupedPlansForWeek) {
    this.plans = plans;
    this.cols = this.decomposeToColumns(dayIndex);
  }

  decomposeToColumns(
    dayIndex?: keyof groupedPlansForWeek
  ): TCameraIdsBasedGroupedPlanOutput {
    let groupedOutput: TCameraIdsBasedGroupedPlanOutput = [];
    for (let i = 0; i < this.plans.length; i++) {
      const cameraIdsTag: string = JSON.stringify(
        StaticCtxDefaultPlans?.plans?.find((plan) => {
            return plan?.id === this.plans?.[i]?.planId;
          })
          ?.dischargeIds?.sort()
      );

      // const cameraIdsTag: string = JSON.stringify(
      //   this.plans?.[i]?.dischargeIds?.sort()
      // );

      const indexOfPlanCol = groupedOutput.findIndex(
        (item) => JSON.stringify(item.dischargeIds) === cameraIdsTag
      );

      const planHasColSibling = indexOfPlanCol !== -1;

      if (planHasColSibling) {
        groupedOutput[indexOfPlanCol].plans.push(this.plans[i]);
      } else {
        try {
          groupedOutput = [
            ...groupedOutput,
            {
              dischargeIds: JSON.parse(cameraIdsTag),
              plans: [this.plans?.[i]],
            },
          ];

          if (isInputValid(dayIndex) && dayIndex !== undefined) {
            // DischargeColGroup.groupedPlans[dayIndex] = groupedOutput;
            DischargeColGroup.groupedPlans[dayIndex] = groupedOutput;
          }
        } catch (err) {}
      }
    }

    return groupedOutput;
  }

  static checkIntersection(
    plan: IDischargePlan,
    dayIndex: keyof groupedPlansForWeek
  ) {
    const checkPlanCameraIds = StaticCtxDefaultPlans?.plans?.find(
      (defPlan) => defPlan?.id === plan?.planId
    )?.dischargeIds;

    const hasOverlap = DischargeColGroup.groupedPlans?.[dayIndex]?.some(
      (groupedPlanObj) => {
        return (
          JSON.stringify(groupedPlanObj?.dischargeIds?.sort()) ===
            JSON.stringify(checkPlanCameraIds?.sort()) &&
          groupedPlanObj?.plans.some((planInGroupedPlans) => {
            return checkOverlap(plan, planInGroupedPlans);
          })
        );
      }
    );

    return hasOverlap;
  }
}
