import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { t } from "i18next";

const { confirm } = Modal;

const GenericMarkerDeleteModal = (contentName: string, saveFunc: Function) => {
  confirm({
    title: t("delete_record"),
    icon: <ExclamationCircleOutlined />,
    content: contentName + " " + t("it_will_be_deleted_r_you_sure"),
    okText: t("yes"),
    okType: "danger",
    cancelText: t("no"),
    async onOk() {
      saveFunc();
    },
  });
};

export default GenericMarkerDeleteModal;
