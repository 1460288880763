import moment from "moment";
import { createContext, useEffect, useState } from "react";
import {
  getPhases,
  getDetectors,
  getCorridorAndDischargeList,
} from "../../../../data/mm-signal-plan/SignalPlanService";
import { PhaseType } from "../components/CreateNewPlan";
import { TDefaultPlan } from "../components/SPMultiModeSchedular/components/Schedular";
import { IMultiModePlanCommon } from "../components/SPMultiModeSchedular/models/MultiModePlan";
import { StaticCtxDefaultPlans } from "../components/SPMultiModeSchedular/models/StaticCtxDefaultPlans";

const mockPlan1: IMultiModePlanCommon[] = [
  {
    planId: 1,
    startTime: moment("06:00", "HH:mm"),
    endTime: moment("07:00", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 2,
    // startTime: moment("07:00", "HH:mm"),
    startTime: "07:00",
    endTime: moment("08:00", "HH:mm"),
    // junctionId: 3,
    // corridorIds: [2, 1],
    // type: "chaos",
  },
  {
    planId: 3,
    startTime: moment("08:00", "HH:mm"),
    endTime: moment("09:00", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 4,
    startTime: moment("09:00", "HH:mm"),
    endTime: moment("10:00", "HH:mm"),
    // junctionId: 6,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 5,
    startTime: moment("11:00", "HH:mm"),
    endTime: moment("12:00", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [1, 3],
    // type: "chaos",
  },
  {
    planId: 6,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("17:00", "HH:mm"),
    // junctionId: 6,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 7,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:20", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [3],
    // type: "chaos",
  },
  {
    planId: 8,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("13:00", "HH:mm"),
    // junctionId: 6,
    // type: "cfm",
  },
  {
    planId: 9,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 1,
    // type: "cfm",
  },
  {
    planId: 10,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("13:00", "HH:mm"),
    // junctionId: 6,
    // type: "coord",
  },
  {
    planId: 11,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 1,
    // type: "coord",
  },
  {
    planId: 12,
    startTime: moment("08:00", "HH:mm"),
    endTime: moment("11:00", "HH:mm"),
    // junctionId: 6,
    // dischargeIds: [1, 2],
    // type: "discharge",
  },
  {
    planId: 13,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 1,
    // dischargeIds: [3],
    // type: "discharge",
  },
  {
    planId: 14,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("13:00", "HH:mm"),
    // junctionId: 9,
    // dischargeIds: [2, 1],
    // type: "discharge",
  },
  {
    planId: 15,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 10,
    // dischargeIds: [1, 2],
    // type: "discharge",
  },
];
const mockPlan2: IMultiModePlanCommon[] = [
  {
    planId: 2,
    startTime: moment("06:00", "HH:mm"),
    endTime: moment("07:00", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 1,
    // startTime: moment("07:00", "HH:mm"),
    startTime: "07:00",
    endTime: moment("08:00", "HH:mm"),
    // junctionId: 3,
    // corridorIds: [2, 1],
    // type: "chaos",
  },
  {
    planId: 3,
    startTime: moment("08:00", "HH:mm"),
    endTime: moment("09:00", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 4,
    startTime: moment("09:00", "HH:mm"),
    endTime: moment("10:00", "HH:mm"),
    // junctionId: 6,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 5,
    startTime: moment("11:00", "HH:mm"),
    endTime: moment("12:00", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [1, 3],
    // type: "chaos",
  },
  {
    planId: 6,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("17:00", "HH:mm"),
    // junctionId: 6,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 7,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:20", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [3],
    // type: "chaos",
  },
  {
    planId: 8,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("13:00", "HH:mm"),
    // junctionId: 6,
    // type: "cfm",
  },
  {
    planId: 9,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 1,
    // type: "cfm",
  },
  {
    planId: 10,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("13:00", "HH:mm"),
    // junctionId: 6,
    // type: "coord",
  },
  {
    planId: 11,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 1,
    // type: "coord",
  },
  {
    planId: 12,
    startTime: moment("08:00", "HH:mm"),
    endTime: moment("11:00", "HH:mm"),
    // junctionId: 6,
    // dischargeIds: [1, 2],
    // type: "discharge",
  },
  {
    planId: 13,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 1,
    // dischargeIds: [3],
    // type: "discharge",
  },
  {
    planId: 14,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("13:00", "HH:mm"),
    // junctionId: 9,
    // dischargeIds: [2, 1],
    // type: "discharge",
  },
  {
    planId: 15,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 10,
    // dischargeIds: [1, 2],
    // type: "discharge",
  },
];
const mockPlans3: IMultiModePlanCommon[] = [
  {
    planId: 2,
    startTime: moment("06:00", "HH:mm"),
    endTime: moment("07:00", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 3,
    // startTime: moment("07:00", "HH:mm"),
    startTime: "07:00",
    endTime: moment("08:00", "HH:mm"),
    // junctionId: 3,
    // corridorIds: [2, 1],
    // type: "chaos",
  },
  {
    planId: 1,
    startTime: moment("08:00", "HH:mm"),
    endTime: moment("09:00", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 4,
    startTime: moment("09:00", "HH:mm"),
    endTime: moment("10:00", "HH:mm"),
    // junctionId: 6,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 5,
    startTime: moment("11:00", "HH:mm"),
    endTime: moment("12:00", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [1, 3],
    // type: "chaos",
  },
  {
    planId: 6,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("17:00", "HH:mm"),
    // junctionId: 6,
    // corridorIds: [1, 2],
    // type: "chaos",
  },
  {
    planId: 7,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:20", "HH:mm"),
    // junctionId: 1,
    // corridorIds: [3],
    // type: "chaos",
  },
  {
    planId: 8,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("13:00", "HH:mm"),
    // junctionId: 6,
    // type: "cfm",
  },
  {
    planId: 9,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 1,
    // type: "cfm",
  },
  {
    planId: 10,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("13:00", "HH:mm"),
    // junctionId: 6,
    // type: "coord",
  },
  {
    planId: 11,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 1,
    // type: "coord",
  },
  {
    planId: 12,
    startTime: moment("08:00", "HH:mm"),
    endTime: moment("11:00", "HH:mm"),
    // junctionId: 6,
    // dischargeIds: [1, 2],
    // type: "discharge",
  },
  {
    planId: 13,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 1,
    // dischargeIds: [3],
    // type: "discharge",
  },
  {
    planId: 14,
    startTime: moment("12:00", "HH:mm"),
    endTime: moment("13:00", "HH:mm"),
    // junctionId: 9,
    // dischargeIds: [2, 1],
    // type: "discharge",
  },
  {
    planId: 15,
    startTime: moment("13:00", "HH:mm"),
    endTime: moment("14:00", "HH:mm"),
    // junctionId: 10,
    // dischargeIds: [1, 2],
    // type: "discharge",
  },
];

export type TCtxMMSP = {
  plans: TDefaultPlan[];
  dailyPlans: {
    id: number;
    plans: IMultiModePlanCommon[];
  }[];
  [key: string]: any;
};

// "cfm" | "discharge" | "chaos" | "coord"
const weeklyMultiModeSP: TCtxMMSP = {
  id: 11111111,
  ts: 1677493626432,
  name: "MMSP1",
  note: "Hey, this is multi mode sp!",
  user: "issd",
  plans: [
    {
      id: 1,
      name: "Plan 1",
      maxTS: 85,
      minTS: 85,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 3,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 3,
          signalOutput: 4,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 9,
          internalId: 5,
          signalOutput: 6,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 4,
          signalOutput: 5,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: null,
      managementType: null,
      offsetDuration: 0,
      phaseInsertion: null,
      trailingPriority: null,
      restrictedPriority: null,
      multiModeType: "chaos",
      corridorIds: [1, 2],
    },
    {
      id: 2,
      name: "Plan 2",
      maxTS: 90,
      minTS: 90,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 3,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 5,
          schedule: null,
          extraTime: 4,
          internalId: 6,
          signalOutput: 7,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 2,
          internalId: 3,
          signalOutput: 4,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 9,
          internalId: 5,
          signalOutput: 6,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 4,
          signalOutput: 5,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: null,
      managementType: null,
      offsetDuration: 0,
      phaseInsertion: null,
      trailingPriority: null,
      restrictedPriority: null,
      multiModeType: "chaos",
      corridorIds: [2, 1],
    },
    {
      id: 3,
      name: "Plan 3",
      maxTS: 85,
      minTS: 85,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 3,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 3,
          signalOutput: 4,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 9,
          internalId: 5,
          signalOutput: 6,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 4,
          signalOutput: 5,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: null,
      managementType: null,
      offsetDuration: 0,
      phaseInsertion: null,
      trailingPriority: null,
      restrictedPriority: null,
      multiModeType: "chaos",
      corridorIds: [1, 2],
    },
    {
      id: 4,
      name: "Plan 4",
      maxTS: 86,
      minTS: 85,
      phases: [
        {
          plus: 1,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 3,
          signalOutput: 3,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 9,
          internalId: 5,
          signalOutput: 5,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 4,
          signalOutput: 4,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: "",
          isMultiOccurring: "",
          interruptorPhaseId: "",
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: null,
      managementType: null,
      offsetDuration: 0,
      phaseInsertion: null,
      trailingPriority: null,
      restrictedPriority: null,
      multiModeType: "chaos",
      corridorIds: [1, 2],
    },
    {
      id: 5,
      name: "Plan 5",
      maxTS: 97,
      minTS: 97,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 5,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 6,
          signalOutput: 9,
          isInterrupting: true,
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: false,
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 2,
          internalId: 3,
          signalOutput: 3,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 9,
          internalId: 5,
          signalOutput: 5,
          isInterrupting: true,
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 4,
          signalOutput: 4,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 6,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: true,
      managementType: "restrictedPriority",
      offsetDuration: 0,
      phaseInsertion: false,
      trailingPriority: false,
      restrictedPriority: true,
      multiModeType: "chaos",
      corridorIds: [1, 3],
    },
    {
      id: 6,
      name: "Plan 6",
      maxTS: 97,
      minTS: 97,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 5,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 6,
          signalOutput: 9,
          isInterrupting: true,
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: false,
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 2,
          internalId: 3,
          signalOutput: 3,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 9,
          internalId: 5,
          signalOutput: 5,
          isInterrupting: true,
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 4,
          signalOutput: 4,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 6,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: true,
      managementType: "restrictedPriority",
      offsetDuration: 0,
      phaseInsertion: false,
      trailingPriority: false,
      restrictedPriority: true,
      multiModeType: "chaos",
      corridorIds: [1, 2],
    },
    {
      id: 7,
      name: "Plan 7",
      maxTS: 97,
      minTS: 97,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 5,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 6,
          signalOutput: 9,
          isInterrupting: true,
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: false,
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 2,
          internalId: 3,
          signalOutput: 3,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 9,
          internalId: 5,
          signalOutput: 5,
          isInterrupting: true,
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 4,
          signalOutput: 4,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 6,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: true,
      managementType: "restrictedPriority",
      offsetDuration: 0,
      phaseInsertion: false,
      trailingPriority: false,
      restrictedPriority: true,
      multiModeType: "chaos",
      corridorIds: [3],
    },
    {
      id: 8,
      name: "Plan 8",
      maxTS: 97,
      minTS: 97,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 5,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: true,
      managementType: "restrictedPriority",
      offsetDuration: 0,
      phaseInsertion: false,
      trailingPriority: false,
      restrictedPriority: true,
      multiModeType: "cfm",
    },
    {
      id: 9,
      name: "Plan 9",
      maxTS: 97,
      minTS: 97,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 5,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: true,
      managementType: "restrictedPriority",
      offsetDuration: 0,
      phaseInsertion: false,
      trailingPriority: false,
      restrictedPriority: true,
      multiModeType: "cfm",
    },
    {
      id: 10,
      name: "Plan 10",
      maxTS: 97,
      minTS: 97,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 5,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: true,
      managementType: "restrictedPriority",
      offsetDuration: 0,
      phaseInsertion: false,
      trailingPriority: false,
      restrictedPriority: true,
      multiModeType: "coord",
    },
    {
      id: 11,
      name: "Plan 11",
      maxTS: 97,
      minTS: 97,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 5,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: true,
      managementType: "restrictedPriority",
      offsetDuration: 0,
      phaseInsertion: false,
      trailingPriority: false,
      restrictedPriority: true,
      multiModeType: "coord",
    },
    {
      id: 12,
      name: "Plan 12",
      maxTS: 97,
      minTS: 97,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 5,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: true,
      managementType: "restrictedPriority",
      offsetDuration: 0,
      phaseInsertion: false,
      trailingPriority: false,
      restrictedPriority: true,
      multiModeType: "discharge",
      dischargeIds: [1, 2],
    },
    {
      id: 13,
      name: "Plan 13",
      maxTS: 97,
      minTS: 97,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 5,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: true,
      managementType: "restrictedPriority",
      offsetDuration: 0,
      phaseInsertion: false,
      trailingPriority: false,
      restrictedPriority: true,
      multiModeType: "discharge",
      dischargeIds: [3],
    },
    {
      id: 14,
      name: "Plan 14",
      maxTS: 97,
      minTS: 97,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 5,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: true,
      managementType: "restrictedPriority",
      offsetDuration: 0,
      phaseInsertion: false,
      trailingPriority: false,
      restrictedPriority: true,
      multiModeType: "discharge",
      dischargeIds: [2, 1],
    },
    {
      id: 15,
      name: "Plan 15",
      maxTS: 97,
      minTS: 97,
      phases: [
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 7,
          internalId: 1,
          signalOutput: 1,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: null,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
        {
          plus: 0,
          minus: 0,
          meanTime: 10,
          schedule: null,
          extraTime: 6,
          internalId: 2,
          signalOutput: 2,
          isInterrupting: "",
          successorPhase: "",
          trailerPhaseId: null,
          isMultiOccurring: "",
          interruptorPhaseId: 5,
          maxSequenceTimeout: 80,
          minSensorThreshold: "",
          minSequenceTimeout: 5,
        },
      ],
      planType: "Smart",
      cycleTime: 0,
      greenWaveMode: null,
      urgentEnabled: true,
      managementType: "restrictedPriority",
      offsetDuration: 0,
      phaseInsertion: false,
      trailingPriority: false,
      restrictedPriority: true,
      multiModeType: "discharge",
      dischargeIds: [1, 2],
    },
  ],
  dailyPlans: [
    { id: 1, plans: mockPlan1 },
    { id: 2, plans: mockPlan2 },
    { id: 3, plans: mockPlans3 },
    { id: 4, plans: mockPlan1 },
    { id: 5, plans: mockPlan2 },
    { id: 6, plans: mockPlans3 },
    { id: 7, plans: mockPlan1 },
  ],
};

interface Props {
  children: any;
  selectedJunction: any;
}

export type TCorridorAndDischargeList = {
  corridorList: { id: number; name: string }[];
  dischargeList: { id: number; name: string }[];
};

export const MultiModePlanContext = createContext<any>(null);

export const MultiModePlanProvider = (props: Props) => {
  const { selectedJunction } = props;

  const [signalPlan, setSignalPlan] = useState<any>(null);
  // const [signalPlan, setSignalPlan] = useState<TCtxMMSP>(weeklyMultiModeSP);
  const [signalPlanBackup, setSignalPlanBackup] = useState<any>(null);
  const [phases, setPhases] = useState<PhaseType[]>([]);
  const [detectors, setDetectors] = useState<PhaseType[]>([]);
  const [corridorAndDischargeList, setCorridorAndDischargeList] =
    useState<TCorridorAndDischargeList>({
      corridorList: [],
      dischargeList: [],
    });
  const [locked, setLocked] = useState<boolean>(true);

  // if edit is locked, then return backup(initial) value
  useEffect(() => {
    if (locked) {
      setSignalPlan(signalPlanBackup);
    }
  }, [locked]);

  useEffect(() => {
  //  const { id: junction_id, junction_model_id } = selectedJunction;
    if (selectedJunction?.id && selectedJunction?.junction_model_id) {
      // get phases
      getPhases({
        junction_id: selectedJunction.id,
        junction_model_id: selectedJunction.junction_model_id,
      }).then((res: any) => {
        if (res.data && Array.isArray(res.data)) {
          setPhases(res.data);
        }
      });

      getDetectors({
        junction_id: selectedJunction.id,
      }).then((res: any) => {
        if (res.data && Array.isArray(res.data)) {
          setDetectors(res.data);
        }
      });

      getCorridorAndDischargeList({
        junction_id: selectedJunction.id,
        junction_model_id: selectedJunction.junction_model_id,
      }).then((res: any) => {
        if (res.data) {
          setCorridorAndDischargeList(res.data);
        }
      });
    }else{

    }
  }, []);

  useEffect(() => {
    StaticCtxDefaultPlans.plans = signalPlan?.plans;
  }, [signalPlan]);

  return (
    <MultiModePlanContext.Provider
      value={{
        signalPlan: [signalPlan, setSignalPlan],
        signalPlanBackup: [signalPlanBackup, setSignalPlanBackup],
        locked: [locked, setLocked],
        juncPhases: phases,
        juncDetectors: detectors,
        corridorAndDischargeList: corridorAndDischargeList,
      }}
    >
      {props.children}
    </MultiModePlanContext.Provider>
  );
};
