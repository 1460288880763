import { Card, message, Modal } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Junction, JunctionStatus } from "../../data/junction/Junction";
import {
  layerDetector,
  layerGroup,
  layerJunction,
  mapLayers,
} from "./junction.data";
import L, { LatLng } from "leaflet";
import { Map as LMap } from "leaflet";
import styled from "styled-components";
import { Div, LayerControl } from "@icms/ui-components";
import React from "react";
import {
  JunctionGroup,
  markerIcons,
} from "../../data/junction-group/JunctionGroup";
import "leaflet-rotatedmarker";
import JunctionGroupService from "../../data/junction-group/JunctionGroupService";
import MapEditor from "./MapEditor";
import JunctionPhaseService from "../../data/junction-phase/JunctionPhaseService";
import { JunctionPhase } from "../../data/junction-phase/JunctionPhase";
import JunctionInfo from "./JunctionInfo";
import JunctionDesignPdf from "./JunctionDesignPdf";
import JunctionFiles from "./JunctionFiles";
import {
  detectorIcons,
  JunctionDetector,
} from "../../data/junction-detector/JunctionDetector";
import JunctionDetectorService from "../../data/junction-detector/JunctionDetectorService";
import ReactDOM from "react-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useTranslation } from "react-i18next";
import { useJunctionData } from "../../contexts/junction/JunctionDataContext";
import { JunctionStatusInfo } from "../map/map-constants/module-status/JunctionStatusIno";

interface Props {
  latlon?: LatLng;
  map?: React.MutableRefObject<LMap | undefined>;
  selectedJunction?: Junction;
  setSelectedJunction: any;
}
const EditView = (props:Props) => {
  
  const { t } = useTranslation();
  message.config({
    getContainer: () => document.getElementById("miniMap")!,
  });
  const { latlon, selectedJunction, setSelectedJunction } = props;
  const [selectedLatLonJunction, setSelectedLatLonJunction] =
    useState<LatLng>();
  const [selectedLatLonGroup, setSelectedLatLonGroup] = useState<LatLng>();
  const [selectedLatLonDetector, setSelectedLatLonDetector] =
    useState<LatLng>();

  const [groups, setGroups] = useState<JunctionGroup[]>([]);
  const [detectors, setDetectors] = useState<JunctionDetector[]>([]);
  const [phases, setPhases] = useState<JunctionPhase[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<any[]>([]);
  const [selectedDetectors, setSelectedDetectors] = useState<any[]>([]);

  const [isPhaseOperationStart, setIsPhaseOperationStart] =
    useState<boolean>(false);
  const [mp, setMp] = useState<LMap>();
  const [responsedJunction, setResponsedJunction] = useState<Junction>();
  const [rotationAngle, setRotationAngle] = useState<any>();
  const [rotationAngleDetector, setRotationAngleDetector] = useState<any>();

  const [rotatableGroupMarker, setRotatableGroupMarker] = useState<any>();
  const [rotatableDetectorMarker, setRotatableDetectorMarker] = useState<any>();

  const [bindedGroup, setBindedGroup] = useState<JunctionGroup>();
  const [bindedDetector, setBindedDetector] = useState<JunctionDetector>();

  const [designFileUrl, setDesignFileUrl] = useState<string>("");

  const [isPedestrianGroupValue, setIsPedestrianGroupValue] = useState<boolean>(false);
  const leafletMapRef = useRef<LMap>();
  const containerRef = useRef<HTMLDivElement>(null);
  const mapEditorRef = useRef<any>();


  const { addJunction, editJunction, removeJunction } = useJunctionData();

  const positionJunction = useMemo(
    () => selectedLatLonJunction,
    [selectedLatLonJunction]
  );
  const positionGroup = useMemo(
    () => selectedLatLonGroup,
    [selectedLatLonGroup]
  );
  const positionDetector = useMemo(
    () => selectedLatLonDetector,
    [selectedLatLonDetector]
  );

  const onMapCreate = useCallback((map: LMap) => {
    leafletMapRef.current = map;
    setMp(map);
  }, []);

  const openJunctionDrawer = useCallback(() => {
    mapEditorRef?.current.junctionDrawerOpen();
  }, [mapEditorRef]);

  const closeJunctionDrawer = useCallback(() => {
    mapEditorRef?.current.junctionDrawerClose();
  }, [mapEditorRef]);

  const openGroupDrawer = useCallback(() => {
    mapEditorRef?.current.groupDrawerOpen();
  }, [mapEditorRef]);
  const openDetectorDrawer = useCallback(() => {
    mapEditorRef?.current.detectorDrawerOpen();
  }, [mapEditorRef]);

  const closeGroupDrawer = useCallback(() => {
    mapEditorRef?.current.groupDrawerClose();
  }, [mapEditorRef]);
  const closeDetectorDrawer = useCallback(() => {
    mapEditorRef?.current.detectorDrawerClose();
  }, [mapEditorRef]);

  const onlayerChange = useCallback(
    (e: any, l: LayerControl) => {
      if (e.target.checked) {
        leafletMapRef.current?.addLayer(l.layer);
      } else {
        leafletMapRef.current?.removeLayer(l.layer);
      }
    },
    [leafletMapRef]
  );

  const editorMapLayerFilter = useMemo(
    () => (
      <>
        {mapLayers.map((m) => (
          <React.Fragment key={`layer_${m.id}`}>
            <Div margin="10px 0 0 0">
              <Checkbox
                onChange={(e: any) => onlayerChange(e, m)}
                value={m.id}
                defaultChecked={m.isChecked}
              >
                {m.name}
              </Checkbox>
            </Div>
          </React.Fragment>
        ))}
      </>
    ),
    [onlayerChange]
  );

  const getGroups = useCallback(() => {
    let groupJunctionId = selectedJunction?.id
      ? selectedJunction.id
      : responsedJunction?.id;
    JunctionGroupService.get(groupJunctionId!)
      .then((r) => {
        setGroups(r.data);
        mp?.eachLayer(function (layer: any) {
          if (layer._drawnByGeoman === true) {
            layer.remove();
          }
        });
      })
      .catch((err) => console.log(err));
  }, [selectedJunction, responsedJunction, mp]);

  const getDetectors = useCallback(() => {
    let detectorJunctionId = selectedJunction?.id
      ? selectedJunction.id
      : responsedJunction?.id;
    JunctionDetectorService.get(detectorJunctionId!)
      .then((r) => {
        setDetectors(r.data);
        mp?.eachLayer(function (layer: any) {
          if (layer._drawnByGeoman === true) {
            layer.remove();
          }
        });
      })
      .catch((err) => console.log(err));
  }, [selectedJunction, responsedJunction, mp]);

  const getPhases = useCallback(() => {
    if (selectedJunction?.id) {
      JunctionPhaseService.get(selectedJunction!.id)
        .then((r) => setPhases(r.data))
        .catch((err) => console.log(err));
    }
  }, [selectedJunction]);

  const triggerModalClose = () => {
    let element: HTMLElement = document.getElementsByClassName(
      "ant-modal-close-x"
    )[0] as HTMLElement;
    element.click();
  };

  const insertJunction = useCallback(
    (values: Junction) => {
      values.listen_mode = values.listen_mode ? values.listen_mode : false;
      values.is_dummy = values.is_dummy ? values.is_dummy : false;
      values.devicemodel_id = values.devicemodel_id ? Number(values.devicemodel_id) : 2;
      values.ipaddress = values.ipaddress ? values.ipaddress : "0.0.0.0";
      values.http_port = values.http_port ? Number(values.http_port) : 59998;
      addJunction(values)
        .then((f: any) => {
          setResponsedJunction(f[0]);
          setSelectedJunction?.(f[0]);
          message.success(t("junction_saved"));
        })
        .catch((error) => {
          console.log(error);
          if(error.statusCode==405){
            message.error(t("permission_error"));         
          }else
            message.error(t("unable_to_add_junction"));
        });
       
    },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [addJunction, setSelectedJunction]
  );

  const updateJunction = useCallback(
    (values: Junction) => {
      editJunction(values)
        .then((f: any) => {
          setResponsedJunction(f[0]);
          setSelectedJunction?.(f[0]);
          message.success(t("junction_updated"));
        })
        .catch((error) => {
          console.log(error);
          if(error.statusCode==405){
            message.error(t("permission_error"));         
          }else{
            message.error(t("junction_failed_to_update"));
          }
        });
    },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [editJunction, setSelectedJunction]
  );

  const deleteJunction = useCallback(
    (values?: Junction) => {
      if (values) {
        removeJunction(values).then((f: any) => {
          closeJunctionDrawer();
          message.success(t("junction_deleted_successfully"));
          triggerModalClose();
        });
      } else {
        return;
      }
    },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeJunctionDrawer, removeJunction]
  );

  const insertGroup = useCallback(
    (values: JunctionGroup) => {
      values.ags = values.ags_value ? true : false;
      values.junction_id = (
        selectedJunction?.id ? selectedJunction.id : responsedJunction?.id
      )!;
      JunctionGroupService.add(values)
        .then((f) => {
          message.success(t("group_added_successfully"));
          mp?.eachLayer(function (layer: any) {
            if (layer._drawnByGeoman === true) {
              layer.remove();
            }
          });
          getGroups();
        })
        .catch((err) => {
          if(err.statusCode==405){
            message.error(t("permission_error"));         
          }else{
            message.error(t("failed_to_add_group"));
          }
        });
    },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [getGroups, mp, responsedJunction?.id, selectedJunction?.id]
  );

  const updateGroup = useCallback(
    (values: JunctionGroup) => {
      JunctionGroupService.update(values)
        .then((f) => {
          message.success(t("group_successfully_updated"));
          mp?.eachLayer(function (layer: any) {
            if (layer._drawnByGeoman === true) {
              layer.remove();
            }
          });
          getGroups();
        })
        .catch((err) => {
          console.log(err);
          if(err.statusCode==405){
            message.error(t("permission_error"));         
          }else
          message.error(t("failed_to_update_group"));
        });
    },
    [getGroups, mp]
  );

  const deleteGroup = useCallback(
    (values: JunctionGroup) => {
      JunctionGroupService.deleteGroup(values)
        .then((f) => {
          message.success(t("group_successfully_deleted"));
          //     mp?.eachLayer(function(layer:any){
          //       if (layer._drawnByGeoman === true) {
          //      layer.remove()
          //    }
          //  });
          getGroups();
        })
        .catch((err) => {
          console.log(err);
          if(err.statusCode==405){
            message.error(t("permission_error"));         
          }else
          message.error(t("failed_to_delete_group"));
        });
    },
    [getGroups]
  );

  const insertDetector = useCallback(
    (values: JunctionDetector) => {
      values.junction_id = (
        selectedJunction?.id ? selectedJunction.id : responsedJunction?.id
      )!;
      JunctionDetectorService.add(values)
        .then((f) => {
          message.success(t("detector_registered_successfully"));
          mp?.eachLayer(function (layer: any) {
            if (layer._drawnByGeoman === true) {
              layer.remove();
            }
          });
          getDetectors();
        })
        .catch((err) => {
          console.log(err);
          if(err.statusCode==405){
            message.error(t("permission_error"));         
          }else
          message.error(t("unable_to_add_detector"));
        });
    },
    [getDetectors, mp, responsedJunction?.id, selectedJunction?.id]
  );

  const updateDetector = useCallback(
    (values: JunctionDetector) => {
      JunctionDetectorService.update(values)
        .then((f) => {
          message.success(t("detector_updated_successfully"));
          mp?.eachLayer(function (layer: any) {
            if (layer._drawnByGeoman === true) {
              layer.remove();
            }
          });
          getDetectors();
        })
        .catch((err) => {
          console.log(err);
          if(err.statusCode==405){
            message.error(t("permission_error"));         
          }else
          message.error(t("detector_could_not_be_updated"));
        });
    },
    [getDetectors, mp]
  );

  const deleteDetector = useCallback(
    (values: JunctionDetector) => {
      JunctionDetectorService.deleteDetector(values)
        .then((f) => {
          message.success(t("detector_deleted_successfully"));
          //     mp?.eachLayer(function(layer:any){
          //       if (layer._drawnByGeoman === true) {
          //      layer.remove()
          //    }
          //  });
          getDetectors();
        })
        .catch((err) => {
          console.log(err);
          if(err.statusCode==405){
            message.error(t("permission_error"));         
          }else
          message.error(t("detector_could_not_be_deleted"));
        });
    },
    [getDetectors]
  );

  const insertPhase = useCallback(
    (values: JunctionPhase) => {
      JunctionPhaseService.add(values)
        .then((f) => {
          message.success(t("phase_added_successfully"));
          getPhases();
        })
        .catch((err) => {
          console.log(err);
          if(err.statusCode==405){
            message.error(t("permission_error"));         
          }else
          message.error(t("failed_to_add_phase"));
        });
    },
    [getPhases]
  );

  const updatePhase = useCallback(
    (values: JunctionPhase) => {
      JunctionPhaseService.update(values).then(() => {
        message.success(t("phase_successfully_updated"));
        getPhases();
      });
    },
    [getPhases]
  );

  const deletePhase = useCallback(
    (values: JunctionPhase) => {
      JunctionPhaseService.deletePhase(values).then(() => {
        message.success(t("phase_successfully_deleted"));
        getPhases();
      });
    },
    [getPhases]
  );

  const addJunctionMarker = useCallback(
    (icon: L.Icon, position: L.LatLng, m: L.Map) => {
      const marker = L.marker(position, { icon: icon });
      const addJunctionEvents: any = {
        click: "click",
        edit: "pm:edit",
      };

      Object.keys(addJunctionEvents).map((event) => {
        marker.addEventListener(addJunctionEvents[event], (e: any) => {
          openJunctionDrawer();
          const junctionPosition = e.target.getLatLng();
          setSelectedLatLonJunction(junctionPosition);
        });
      });
      marker.on("pm:remove", () => {
        closeJunctionDrawer();
        confirmAlert({
          customUI: ({ onClose }) => {
            return ReactDOM.createPortal(    
                (<Modal
                  title={`${t("junction")} - ` + (selectedJunction ? selectedJunction.name : responsedJunction?.name)}
                  visible={true}
                  onOk={()=> {deleteJunction(selectedJunction ? selectedJunction : responsedJunction); 
                    onClose();
                  }}
                  onCancel={()=>{
                    const tempJunction = selectedJunction ? selectedJunction : responsedJunction;
                   
                    
                    addJunctionMarker(JunctionStatusInfo[tempJunction!.status_id].marker, new L.LatLng(
                      tempJunction!.latitude,
                      tempJunction!.longitude
                    ), mp!);
                    onClose();
                  }}
                  getContainer={false}
                  maskClosable={false}
                  centered
                >
                  <p>{ t("junction_n_all_dependencies_will_be_deleted") }</p>
             
                </Modal>), document.getElementById('miniMap')!
            
            );
          },
        });
      });

      layerJunction.addLayer(marker);
    },
    [closeJunctionDrawer, deleteJunction, mp, openJunctionDrawer, responsedJunction, selectedJunction]
  );

  const selectionPhaseGroups = useCallback(
    (e: any, g: JunctionGroup) => {
      if (!e.target._icon.classList.contains("markerSelectedClass")) {
        e.target._icon.classList.add("markerSelectedClass");
        selectedGroups.push(g.id);
      } else {
        e.target._icon.classList.remove("markerSelectedClass");
        const index = selectedGroups.indexOf(g.id);
        if (index > -1) {
          selectedGroups.splice(index, 1);
        }
      }
      setSelectedGroups(selectedGroups);
    },
    [selectedGroups]
  );
  const selectionPhaseDetectors = useCallback(
    (e: any, d: JunctionDetector) => {
      if (!e.target._icon.classList.contains("markerSelectedClass") && d.devicetype_id === 7) {
        e.target._icon.classList.add("markerSelectedClass");
        selectedDetectors.push(d.id);
      } else {
        e.target._icon.classList.remove("markerSelectedClass");
        const index = selectedDetectors.indexOf(d.id);
        if (index > -1) {
          selectedDetectors.splice(index, 1);
        }
      }
      setSelectedDetectors(selectedDetectors);
    },
    [selectedDetectors]
  );
  const addGroupMarker = useCallback(
    (g: JunctionGroup) => {
      const icon = L.icon({
        iconUrl: `data:image/jpeg;base64,${markerIcons[g.type_id].base64}`,
        iconSize: [20, 30],
      });
      const addGroupEvents: any = {
        click: "click",
        edit: "pm:edit",
      };

      const marker = L.marker(new L.LatLng(g.latitude, g.longitude), {
        icon: icon,
      });
      marker.bindTooltip(g.name);

      Object.keys(addGroupEvents).map((event) => {
        marker.addEventListener(addGroupEvents[event], (e: any) => {
          const groupPosition = e.target.getLatLng();
          setSelectedLatLonGroup(groupPosition);
        });
      });

      if (g.angle) {
        const mk: any = marker;
        mk.setRotationOrigin("center");
        mk.setRotationAngle(g.angle);
      }

      if (isPhaseOperationStart) {
        closeJunctionDrawer();
        closeGroupDrawer();
        mapEditorRef?.current.phaseSelection();
        marker.on("click", (e: any) => {
          selectionPhaseGroups(e, g);
        });
      }

      if ((selectedJunction || responsedJunction) && !isPhaseOperationStart) {
        marker.on("click", (e: any) => {
          g.type_id === 7 ? setIsPedestrianGroupValue(true) : setIsPedestrianGroupValue(false)
          openGroupDrawer();
          closeDetectorDrawer();
          setRotatableGroupMarker(e.target);
          setBindedGroup(g);
        });

       


        marker.on("pm:remove", () => {
          closeGroupDrawer();
          confirmAlert({
            customUI: ({ onClose }) => {
              return ReactDOM.createPortal(    
                  (<Modal
                    title={`${t("group")} ` + g.name}
                    visible={true}
                    onOk={()=>{deleteGroup(g); onClose();}}
                    onCancel={()=>{
                      getGroups();
                      onClose();
                    }}
                    getContainer={false}
                    maskClosable={false}
                    centered
                  >
                    <p>{ t("it_will_be_deleted_r_you_sure") }</p>
               
                  </Modal>), document.getElementById('miniMap')!
              
              );
            },
          });
        });
      }
      layerGroup.addLayer(marker);
    },
    [closeDetectorDrawer, closeGroupDrawer, closeJunctionDrawer, deleteGroup, getGroups, isPhaseOperationStart, openGroupDrawer, responsedJunction, selectedJunction, selectionPhaseGroups]
  );

  const addDetectorMarker = useCallback(
    (d: JunctionDetector) => {
      const icon = L.icon({
        iconUrl: `data:image/jpeg;base64,${detectorIcons[d.devicetype_id].base64}`,
        iconSize: [30, 32],
      });
      const addDetectorEvents: any = {
        click: "click",
        edit: "pm:edit",
      };

      const marker = L.marker(new L.LatLng(d.latitude, d.longitude), {
        icon: icon,
      });
      marker.bindTooltip(d.name);

      Object.keys(addDetectorEvents).map((event) => {
        marker.addEventListener(addDetectorEvents[event], (e: any) => {
          const detectorPosition = e.target.getLatLng();
          setSelectedLatLonDetector(detectorPosition);
        });
      });

      if (d.angle) {
        const mk: any = marker;
        mk.setRotationOrigin("center");
        mk.setRotationAngle(d.angle);
      }

      if (isPhaseOperationStart) {
        closeJunctionDrawer();
        closeDetectorDrawer();
        mapEditorRef?.current.phaseSelection();
        marker.on("click", (e: any) => {
          selectionPhaseDetectors(e, d);
        });
      }

      if ((selectedJunction || responsedJunction) && !isPhaseOperationStart) {
        marker.on("click", (e: any) => {
          openDetectorDrawer();
          closeGroupDrawer();
          setRotatableDetectorMarker(e.target);
          setBindedDetector(d);
        });

        marker.on("pm:remove", () => {
          closeDetectorDrawer();
          confirmAlert({
            customUI: ({ onClose }) => {
              return ReactDOM.createPortal(    
                  (<Modal
                    title={`${t("detector")} ` + d.name}
                    visible={true}
                    onOk={()=>{deleteDetector(d); onClose();}}
                    onCancel={()=>{
                      getDetectors();
                      onClose();
                    }}
                    getContainer={false}
                    maskClosable={false}
                    centered
                  >
                    <p>{ t("it_will_be_deleted_r_you_sure") }</p>
               
                  </Modal>), document.getElementById('miniMap')!
              
              );
            },
          });
        });
      }
      layerDetector.addLayer(marker);
    },
    [closeDetectorDrawer, closeGroupDrawer, deleteDetector, getDetectors, openDetectorDrawer, responsedJunction, selectedJunction,isPhaseOperationStart,selectionPhaseDetectors]
  );

  useEffect(() => {
    if (rotationAngle && rotatableGroupMarker) {
      rotatableGroupMarker.setRotationOrigin("center");
      rotatableGroupMarker.setRotationAngle(rotationAngle);
    }

    return function cleanup() {
      setRotationAngle("");
    };
  }, [rotatableGroupMarker, rotationAngle]);

  useEffect(() => {
    if (rotationAngleDetector && rotatableDetectorMarker) {
      rotatableDetectorMarker.setRotationOrigin("center");
      rotatableDetectorMarker.setRotationAngle(rotationAngleDetector);
    }

    return function cleanup() {
      setRotationAngleDetector("");
    };
  }, [rotatableDetectorMarker, rotationAngleDetector]);

  useEffect(() => {
    setSelectedLatLonJunction(latlon);
  }, [latlon]);

  // useEffect(() => {
  //   if (mp) {
  //     const icon = JunctionStatusInfo[JunctionStatus.Passive].marker;
  //     if (icon && latlon) {
  //       const marker = L.marker(latlon, { icon: icon })
  //       marker.addTo(mp);
  //       marker.on('click',(e:any)=>{
  //         openJunctionDrawer();
  //         const junctionPosition = e.target.getLatLng();
  //         setSelectedLatLonJunction(junctionPosition);
  //       })

  //     }
  //   }

  // }, [mp, latlon, addJunctionMarker, openJunctionDrawer]);

  useEffect(() => {
    layerJunction.clearLayers();
    if (selectedJunction) {
      const p = new L.LatLng(
        selectedJunction.latitude,
        selectedJunction.longitude
      );
      setSelectedLatLonJunction(p);
      let icon;
      if(selectedJunction.is_dummy){
        icon = JunctionStatusInfo[11].marker;
      }
      else{
        icon = JunctionStatusInfo[selectedJunction.status_id].marker;
      }
      if (icon && mp) addJunctionMarker(icon, p, mp);
      leafletMapRef.current?.addLayer(layerJunction);
    } else {
      const icon = JunctionStatusInfo[JunctionStatus.PS].marker;
      if (icon && mp && latlon) addJunctionMarker(icon, latlon, mp);
      leafletMapRef.current?.addLayer(layerJunction);
    }
  }, [addJunctionMarker, mp, selectedJunction, leafletMapRef, latlon]); // here it is requested to make the selectedJunction control only on the first render. Because we don't want the event of adding a marker again after a new addition or removal.

  useEffect(() => {
    mapLayers
      .filter((x) => x.isChecked)
      .forEach((v) => {
        leafletMapRef.current?.addLayer(v.layer);
      });
  }, [leafletMapRef]);

  useEffect(() => {
    layerGroup.clearLayers(); // clear all layers in layergroup
    layerDetector.clearLayers(); // clear all layers in layerdetector
    groups.forEach((v) => {
      addGroupMarker(v);
    });
    detectors.forEach((v) => {
      addDetectorMarker(v);
    });
    leafletMapRef.current?.addLayer(layerGroup);
    leafletMapRef.current?.addLayer(layerDetector);
  }, [addDetectorMarker, addGroupMarker, detectors, groups, leafletMapRef]);

  useEffect(() => {
    if (selectedJunction || responsedJunction) {
      getGroups();
      getDetectors();
    }

    getPhases();
  }, [getDetectors, getGroups, getPhases, responsedJunction, selectedJunction]);

  return (
    <>
      <NewJunctionContainer ref={containerRef}>
        <Container>
          <Left>
            <Card
              title={t("junction_map")}
              style={{ height: "350px", position: "relative" }}
            >
              {positionJunction && (
                <MapEditor
                  ref={mapEditorRef}
                  zoom={18}
                  latlon={positionJunction}
                  latlonGroup={positionGroup}
                  latlonDetector={positionDetector}
                  onMapCreate={onMapCreate}
                  selectedJunction={
                    responsedJunction ? responsedJunction : selectedJunction
                  }
                  bindedGroup={bindedGroup}
                  bindedDetector={bindedDetector}
                  setRotationAngle={setRotationAngle}
                  setRotationAngleDetector={setRotationAngleDetector}
                  onJunctionAdd={insertJunction}
                  onJunctionUpdate={updateJunction}
                  onGroupGet={getGroups}
                  onGroupAdd={insertGroup}
                  onGroupUpdate={updateGroup}
                  onDetectorGet={getDetectors}
                  onDetectorAdd={insertDetector}
                  onDetectorUpdate={updateDetector}
                  onPhaseAdd={insertPhase}
                  onPhaseUpdate={updatePhase}
                  onPhaseDelete={deletePhase}
                  editorMapLayerFilter={editorMapLayerFilter}
                  groups={groups}
                  detectors={detectors}
                  phases={phases}
                  phaseGroups={selectedGroups}
                  phaseDetectors = {selectedDetectors} 
                  setSelectedGroups={setSelectedGroups}
                  setSelectedDetectors={setSelectedDetectors}
                  setIsPhaseOperationStart={setIsPhaseOperationStart}
                  setBindedGroup={setBindedGroup}
                  setBindedDetector={setBindedDetector}
                  isPedestrianGroupValue={isPedestrianGroupValue}
                />
              )}
            </Card>
          </Left>
          <Right>
            {selectedJunction && containerRef && (
              <JunctionInfo
                containerRef={containerRef}
                junction={selectedJunction}
              />
            )}
          </Right>
        </Container>
        <Container>
          <Left>
          {selectedJunction && containerRef && (
              <JunctionFiles
                containerRef={containerRef}
                selectedJunction={selectedJunction}
                setDesignFileUrl={setDesignFileUrl}
              />
            )}
          </Left>
          <Right>
          <JunctionDesignPdf containerRef={containerRef} designFileUrl={designFileUrl}/>
          </Right>
        </Container>
      </NewJunctionContainer>
    </>
  );
};

export default EditView;

const Container = styled.div`
  display: flex;
  margin-top: 10px;
`;
const Left = styled.div`
  position: relative;
  width: 50%;
`;
const Right = styled.div`
  width: 50%;
  padding-left: 24px;
  position: relative;
`;
const NewJunctionContainer = styled.div`
  position: relative;
`;