import { Modal } from "antd";
import { useTranslation } from "react-i18next";

interface ModalProps {
  visible: boolean;
  ip: string;
  closeModal: Function;
}

function PreviewDetectorModal({ visible, ip, closeModal }: ModalProps) {
  const { t } = useTranslation();
  const onCancel = () => {
    closeModal(false);
  };

  return (
    <>
      <Modal
        width={700}
        bodyStyle={{ height: "500px" }}
        title={t("camera_preview")}
        visible={visible}
        onCancel={onCancel}
        footer={<></>}
      >
        <div style={{textAlign:"center"}}>
        <img src={window.location.origin+"/stream/"+ip} style={{ width: "700px" }}></img>
        </div>
      </Modal>
    </>
  );
}

export default PreviewDetectorModal;
