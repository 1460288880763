import { ToasterProvider } from "../../../../contexts/information/ToasterContext";
import MapTileControl from "./MapTileControl";
import ToasterControl from "./ToasterControl";

function BottomRightControl() {
  return (
    <div className="leaflet-bottom leaflet-right">
      <div className="leaflet-control leaflet-bar" style={{ border: "none" }}>
        <ToasterProvider>
          <MapTileControl/>
          <ToasterControl />
        </ToasterProvider>
      </div>
    </div>
  );
}

export default BottomRightControl;
