export interface LegendData {
  id: number;
  filterName: string;
  filterStatus: boolean;
  filterCss: string;
  key: string;
}

export const legendData: { [id: number]: LegendData[] } = {
  1: [
    {
      key: "1",
      id: 1,
      filterName: "greenwave",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon junction-GREEN_WAVE",
    },
    {
      key: "1",
      id: 2,
      filterName: "fixed_phase",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon junction-FIXED_PHASE",
    },
    {
      key: "1",
      id: 3,
      filterName: "planned_flash",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon junction-PLANNED_FLASH",
    },
    {
      key: "1",
      id: 4,
      filterName: "dynamic_phase",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon junction-DYNAMIC_PHASE",
    },
    {
      key: "1",
      id: 5,
      filterName: "sequence",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon junction-SEQUENCE",
    },
    {
      key: "1",
      id: 6,
      filterName: "no_power",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon junction-CLOSED",
    },
    {
      key: "1",
      id: 7,
      filterName: "no_connection",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon junction-NO_CONNECTION",
    },
    {
      key: "1",
      id: 8,
      filterName: "passive",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon junction-PASSIVE",
    },
    {
      key: "1",
      id: 9,
      filterName: "error_flash",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon junction-ERROR_FLASH",
    },
    {
      key: "1",
      id: 10,
      filterName: "is_dummy",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon junction-IS_DUMMY",
    },
    {
      key: "1",
      id: 12,
      filterName: "dynamic_sequence",
      filterStatus: false,
      filterCss:
        "map-legend-div map-legend-marker-icon junction-DYNAMICSEQUENCE",
    },
    {
      key: "1",
      id: 13,
      filterName: "police_mode",
      filterStatus: false,
      filterCss:
        "map-legend-div map-legend-marker-icon junction-POLICEMODE",
    },
    {
      key: "1",
      id: 14,
      filterName: "not_sync",
      filterStatus: false,
      filterCss:
        "map-legend-div map-legend-marker-icon junction-NOTSYNC",
    },
    {
      key: "1",
      id: 15,
      filterName: "forced_flash",
      filterStatus: false,
      filterCss:
        "map-legend-div map-legend-marker-icon junction-FORCEDFLASH",
    },
    {
      key: "1",
      id: 99,
      filterName: "unknown",
      filterStatus: false,
      filterCss:
        "map-legend-div map-legend-marker-icon",
    },
  ],
  2: [
    {
      key: "2",
      id: 0,
      filterName: "passive",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon vms-ALL",
    },
    {
      key: "2",
      id: 1,
      filterName: "partially_active",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon vms-ALL",
    },
    {
      key: "2",
      id: 2,
      filterName: "active",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon vms-ALL",
    },
    // {
    //   key: "2",
    //   id: 4,
    //   filterName: "L Type",
    //   filterStatus: false,
    //   filterCss: "map-legend-div map-legend-marker-icon vms-LTYPE",
    // },
    // {
    //   key: "2",
    //   id: 5,
    //   filterName: "N Type",
    //   filterStatus: false,
    //   filterCss: "map-legend-div map-legend-marker-icon vms-NTYPE",
    // },
  ],
  3: [
    {
      key: "3",
      id: 1,
      filterName: "active",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon bluesis-ACTIVE",
    },
    {
      key: "3",
      id: 2,
      filterName: "passive",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon bluesis-PASSIVE",
    },
    {
      key: "3",
      id: 3,
      filterName: "virtual",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon bluesis-VIRTUAL",
    },
  ],
  4: [
    {
      key: "4",
      id: 4,
      filterName: "Fisheye",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon camera-FISHEYE",
    },
    {
      key: "4",
      id: 12,
      filterName: "Bullet",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon camera-BULLET",
    },
    {
      key: "4",
      id: 13,
      filterName: "PTZ",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon camera-PTZ",
    },
  ],
  5: [
    {
      key: "5",
      id: 9,
      filterName: "Viero Ai",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon viero-AI",
    },
    {
      key: "5",
      id: 10,
      filterName: "Viero Fisheye",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon viero-FISHEYE",
    },
    {
      key: "5",
      id: 11,
      filterName: "Viero Discharge",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon viero-DISCHARGE",
    },
    {
      key: "5",
      id: 14,
      filterName: "Viero Parking",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon viero-PARKING",
    },
  ],
  7: [
    {
      key: "7",
      id: 1,
      filterName: "active",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon ups-ACTIVE",
    },
    {
      key: "7",
      id: 2,
      filterName: "passive",
      filterStatus: false,
      filterCss: "map-legend-div map-legend-marker-icon ups-PASSIVE",
    },
  ],
};
