import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    Table,
    Button,
    Input,
    Select,
    TimePicker,
    Row,
    Col,
    Tooltip,
    message,
    Popconfirm,
    Modal,
    Steps,
    Tag,
} from "antd";
import "antd/dist/antd.min.css";
import moment from "moment";
import { CopyOutlined, DeleteOutlined, LeftOutlined, RightOutlined, SendOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { Vms } from "../../../../data/vms/Vms";
import VmsService from "../../../../data/vms/VmsService";
import { useVmsData } from "../../../../contexts/vms/VmsDataContext";
import VmsPlans from "../../VmsPlans";

const { Option } = Select;
const { Step } = Steps;

interface Plan {
    id: number;
    name: string;
    phases?: { drawingFileName: string; duration: number }[];
}
interface Day {
    id: number;
    plans: {
        id: number;
        startTime: string;
    }[];
}
interface VmsPlanProps {
    selectedVms: Vms | null;
    sendedDrawingsFromEditor: any | null;
}

const MultiModeVmsPlan: React.FC<VmsPlanProps> = ({ selectedVms, sendedDrawingsFromEditor }) => {
    const [plans, setPlans] = useState<Plan[]>([]);
    const [weekSchedule, setWeekSchedule] = useState<Day[]>([
        { id: 1, plans: [] },
        { id: 2, plans: [] },
        { id: 3, plans: [] },
        { id: 4, plans: [] },
        { id: 5, plans: [] },
        { id: 6, plans: [] },
        { id: 7, plans: [] },
    ]);
    const [drawings, setDrawings] = useState<string[]>([]);
    const [timeTables, setTimeTables] = useState<any>(null);

    const [currentPage, setCurrentPage] = useState("plans");
    const { vmsList } = useVmsData();
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [vmsGroups, setVmsGroups] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState<any>([]);
    const [processing, setProcessing] = useState(false);
    const [selectedItemsGroups, setSelectedItemsGroups] = useState<string[]>([]);
    const [allSelectedGroups, setAllSelectedGroups] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<any>(null);



    const getVmsDrawingFiles = useCallback(async(vmsGroupId:any) => {
        await VmsService.getVmsDrawingFilesMultiMode(vmsGroupId)
            .then((response) => {
                if (sendedDrawingsFromEditor) {
                    setDrawings(sendedDrawingsFromEditor ? sendedDrawingsFromEditor : [])
                } else { setDrawings(Object.keys(response.data.drawing_data ? response.data.drawing_data : [])) }
            })
            .catch((err) => console.log(err));
    }, []);

    const getVmsTimeTables = useCallback(async (vmsGroupId:any) => {
        await VmsService.getVmsTimeTablesMultiMode(vmsGroupId)
            .then((response) => {
                if(response.data.plan_data === undefined || response.data.plan_data === null){
                    setTimeTables(null)
                }else{
                setTimeTables(response.data.plan_data.timeTable);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    const insertPlan = async (selectedVms: Vms | null, planData: any, vmsGroupId:any) => {
        await VmsService.addPlan(null, "multimodeplan", planData, vmsGroupId)
            .then((response) => {
                getVmsTimeTables(selectedGroupId);
            })
            .catch((err) => console.log(err));
    };
    const insertWeeklyPlan = async (selectedVms: Vms | null, weeklyPlanData: any, vmsGroupId:any) => {
        await VmsService.addWeeklyPlan(null, weeklyPlanData, vmsGroupId)
            .then((response) => { })
            .catch((err) => console.log(err));
    };
    const insertWeeklyPlanTemplate = async (
        selectedVms: Vms | null,
        weeklyPlanTemplateData: any,
        vmsGroupId:any
    ) => {
        await VmsService.addWeeklyPlanTemplate(
            null,
            weeklyPlanTemplateData,
            vmsGroupId
        )
            .then((response) => { })
            .catch((err) => console.log(err));
    };

    const handleAddPlan = () => {
        const generateUniqueId = () => {
            let uniqueId:number;
            do {
                uniqueId = Date.now() % 10000;
            } while (plans.some(plan => plan.id === uniqueId));
            return uniqueId;
        };
    
        const newPlan: Plan = {
            id: generateUniqueId(),
            name: getNewPlanName(),
        };
        setPlans([...plans, newPlan]);
    };
    
    

    const getNewPlanName = () => {
        let counter = 1;
        let newPlanName = ` Multi Mode ${t("plan")}-${counter}`;

        while (plans.some((plan) => plan.name === newPlanName)) {
            newPlanName = ` Multi Mode ${t("plan")}-${counter}`;
            counter++;
        }

        return newPlanName;
    };

    const handleRemovePlan = (id: number) => {
        const updatedPlans = plans.filter((plan) => plan.id !== id);
        setPlans(updatedPlans);
    };

    const handleNewRow = (planId: number) => {
        const updatedPlans = plans.map((plan) => {
            if (plan.id === planId) {
                return {
                    ...plan,
                    phases: [
                        ...(plan.phases || []),
                        { drawingFileName: "", duration: 5 },
                    ],
                };
            }
            return plan;
        });
        setPlans(updatedPlans);
    };

    const handleCellChange = (planId: number, rowIndex: number, key: string) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            const updatedPlans = plans.map((plan) => {
                if (plan.id === planId) {
                    const updatedTableData = [...(plan.phases || [])];
                    updatedTableData[rowIndex] = {
                        ...updatedTableData[rowIndex],
                        [key]:
                            key === "duration"
                                ? parseInt(event.target.value, 10)
                                : event.target.value,
                    };
                    return { ...plan, phases: updatedTableData };
                }
                return plan;
            });
            setPlans(updatedPlans);
        };
    };

    const handleRemoveRow = (planId: number, rowIndex: number) => {
        const updatedPlans = plans.map((plan) => {
            if (plan.id === planId) {
                const updatedTableData = [...(plan.phases || [])];
                updatedTableData.splice(rowIndex, 1);
                return { ...plan, phases: updatedTableData };
            }
            return plan;
        });
        setPlans(updatedPlans);
    };

    const isPlanValid = (plan: Plan) => {
        if (!plan.phases || plan.phases.length === 0) {
            return false;
        }

        return plan.phases.every(
            (phase) => phase.drawingFileName && phase.duration
        );
    };

    const handleAddPlanAssignment = (dayIndex: number) => {
        const updatedSchedule = [...weekSchedule];
        updatedSchedule[dayIndex].plans.push({
            id: 0,
            startTime: "",
        });
        setWeekSchedule(updatedSchedule);
    };

    const handlePlanSelection = (
        dayIndex: number,
        assignmentIndex: number,
        planId: number
    ) => {
        const updatedSchedule = [...weekSchedule];
        updatedSchedule[dayIndex].plans[assignmentIndex].id = planId;
        setWeekSchedule(updatedSchedule);
    };

    const handleStartTimeChange = (dayIndex: number, assignmentIndex: number) => {
        return (time: any) => {
            const updatedSchedule = [...weekSchedule];
            updatedSchedule[dayIndex].plans[assignmentIndex].startTime =
                time.format("HH:mm");
            setWeekSchedule(updatedSchedule);
        };
    };

    const handleRemovePlanAssignment = (
        dayIndex: number,
        assignmentIndex: number
    ) => {
        const updatedSchedule = [...weekSchedule];
        updatedSchedule[dayIndex].plans.splice(assignmentIndex, 1);
        setWeekSchedule(updatedSchedule);
    };

    const handlePlanNameChange = (planId: number) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            const newName = event.target.value;

            if (plans.some((plan) => plan.id !== planId && plan.name === newName)) {
                message.error("Bu isimde başka bir plan zaten var!");
                return;
            }

            const updatedPlans = plans.map((plan) => {
                if (plan.id === planId) {
                    return { ...plan, name: newName };
                }
                return plan;
            });
            setPlans(updatedPlans);
        };
    };
    const handleSaveAllPlans = async () => {
        const invalidPlans = plans.filter((plan) => !isPlanValid(plan));
        if (invalidPlans.length > 0) {
            const invalidPlanNames = invalidPlans.map((plan) => plan.name).join(", ");
            message.error(
                `${t("these_plans_contain_missing_or_incorrect_information")}: ${invalidPlanNames}`
            );
            return;
        }
        await insertPlan(selectedVms, plans, selectedGroupId);

        message.success(t("plans_successfully_saved"));
    };

    const handleCompletePlan = async () => {
        await handleSaveAllPlans();
        try {
            const defaultWeeklyPlanTemplate: any = [
                {
                    id: 1,
                    dailies: [
                        {
                            id: 1,
                            startDay: 1,
                        },
                        {
                            id: 2,
                            startDay: 2,
                        },
                        {
                            id: 3,
                            startDay: 3,
                        },
                        {
                            id: 4,
                            startDay: 4,
                        },
                        {
                            id: 5,
                            startDay: 5,
                        },
                        {
                            id: 6,
                            startDay: 6,
                        },
                        {
                            id: 7,
                            startDay: 7,
                        },
                    ],
                },
            ];
            await insertWeeklyPlan(null, weekSchedule, selectedGroupId);
            await insertWeeklyPlanTemplate(null, defaultWeeklyPlanTemplate, selectedGroupId);
            getVmsTimeTables(selectedGroupId);
            message.success(t("the_weekly_plan_has_been_saved"));
            setCurrentPage("send_plan");
        } catch {
            message.success(t("a_problem_occurred_while_registering_the_weekly_plan"));
        }
    };
    const goBack = () => {
        setCurrentPage("plans");
    };

    const goForward = () => {
        setCurrentPage("send_plan");
    };

    const handleCopyPlan = (fromDayIndex: number) => {
        const fromDay = weekSchedule[fromDayIndex];

        weekSchedule.forEach((toDay, toDayIndex) => {
            if (toDayIndex !== fromDayIndex) {
                toDay.plans = fromDay.plans.map((assignment) => ({
                    ...assignment,
                }));
            }
        });

        setWeekSchedule([...weekSchedule]);
    };
    const sendPlanMultiMode = async (vmsId: any, timeTable: any) => {
        try {
            const vms = vmsList.find(vms => vms.id === vmsId);
            if(vms) {
            const response = await VmsService.sendPlanToVms(
                vms!.id,
                timeTable,
                vms!.ip_address
            );

            if (response.data === ""){
                return { vmsId, status:"error"}
            }

            if (response.data.status === 200 || response.data.status) {
                return { vmsId, status: 'success' };
            }else{
                return { vmsId, status:"error"}
            }
        }else{
            message.error("İşlem gerçekleştirilemedi.")
        }
            
        } catch (error) {
            return { vmsId, status: 'error' };
        }
    };

    const sendPlanToVms = async () => {
        if (!selectedGroupId) {
            message.warning('Lütfen bir VMS grubu seçin!');
            return;
        }
        if (!selectedGroup) {
            message.error('Seçilen grup bulunamadı.');
            return;
        }
    
        if (selectedItemsGroups.length > 0) {
            setModalVisible(true);
            setModalContent([]);
            setProcessing(true);
    
            for (const vmsId of selectedItemsGroups) {
                setModalContent((prev: any) => [...prev, { vmsId, status: 'started' }]);
                const result: any = await sendPlanMultiMode(vmsId, timeTables);
                setModalContent((prev: any) =>
                    prev.map((item: any) => (item.vmsId === result.vmsId ? result : item))
                );
            }
    
        } else {
            message.warning("Lütfen seçili VMS grubundan en az bir adet VMS Seçin");
        }
    
        setProcessing(false);
    };
    

    const renderDayCard = (day: Day, dayIndex: number) => {
        const dayNames = [
            t("monday"),
            t("tuesday"),
            t("wednesday"),
            t("thursday"),
            t("friday"),
            t("saturday"),
            t("sunday"),
        ];

        return (
            <Col
                key={dayIndex}
                span={24 / weekSchedule.length}
                style={{ marginBottom: "20px" }}
            >
                <Card
                    type="inner"
                    hoverable
                    title={
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span style={{ fontWeight: "bold" }}>{dayNames[dayIndex]}</span>
                            {dayIndex === 0 && (
                                <Tooltip
                                    placement="top"
                                    title={t("assign_same_plan_to_all_other_days")}
                                >
                                    <Button
                                        onClick={() => handleCopyPlan(dayIndex)}
                                        icon={<CopyOutlined />}
                                        style={{ marginLeft: "10px" }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    }
                >
                    {day.plans.map((assignment, assignmentIndex) => (
                        <div
                            key={assignmentIndex}
                            style={{
                                marginBottom: "10px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Select
                                style={{ flex: 1, marginRight: "10px", width:"300px" }}
                                placeholder={t("choose_plan")}
                                onChange={(planId: number) =>
                                    handlePlanSelection(dayIndex, assignmentIndex, planId)
                                }
                                value={assignment.id || undefined}
                            >
                                {plans.map((plan) => (
                                    <Option key={plan.id} value={plan.id}>
                                        {plan.name}
                                    </Option>
                                ))}
                            </Select>
                            <TimePicker
                                style={{ flex: 1, marginRight: "10px" }}
                                onChange={handleStartTimeChange(dayIndex, assignmentIndex)}
                                value={
                                    assignment.startTime
                                        ? moment(assignment.startTime, "HH:mm")
                                        : moment("00:00", "HH:mm")
                                }
                                format="HH:mm"
                                placeholder={t("start_time")}
                            />
                            <Button
                                onClick={() =>
                                    handleRemovePlanAssignment(dayIndex, assignmentIndex)
                                }
                                danger
                                ghost
                                style={{ marginRight: "5px", marginBottom: "5px" }}
                                icon={<DeleteOutlined />}
                            />

                        </div>
                    ))}
                    <Button
                        type="primary"
                        onClick={() => handleAddPlanAssignment(dayIndex)}
                        style={{
                            marginBottom: "10px", backgroundColor: '#2c3e50',
                            borderColor: '#2c3e50',
                            color: '#ffffff'
                        }}
                        size="small"

                    >
                        {t("assign_plan")}
                    </Button>
                </Card>
            </Col>
        );
    };

    const getVmsGroups = async () => {
        await VmsService.getVmsGroup()
            .then((response) => {
                setVmsGroups(response.data);
            })
            .catch((err) => console.log(err));
    };

    const handleCheckboxChangeGroups = (checkedValues: any[]) => {
        setSelectedItemsGroups(checkedValues);
        setAllSelectedGroups(checkedValues.length == selectedGroup.groups.length);
      };

    const handleSelectAllGroups = () => {
        if (selectedItemsGroups.length === selectedGroup.groups.length) {
            handleCheckboxChangeGroups([]);
        } else {
            handleCheckboxChangeGroups(selectedGroup.groups);
        }
    };

    const tagRender = (props:any) => {
        const { label, value, closable, onClose } = props;
    
        return (
          <Tag
            color="geekblue"
            closable={closable}
            onClose={onClose}
            style={{
              marginRight: 3,
              backgroundColor: '#ffffff',
              color: '#2c3e50',
              border: '1px solid #2c3e50',
              borderRadius: '4px',
            }}
          >
            {label}
          </Tag>
        );
      };

    useEffect(() => {
        getVmsDrawingFiles(selectedGroupId);
    }, [selectedGroupId]);

    useEffect(() => {
        getVmsTimeTables(selectedGroupId);
    }, [selectedGroupId]);

    useEffect(() => {
        if (timeTables) {
            setPlans(timeTables.vmsPlan.plans);
            if (timeTables.vmsPlan && timeTables.vmsPlan.dailyPlans !== undefined) {
                setWeekSchedule(timeTables.vmsPlan.dailyPlans);
            }
        }
        else{
            setPlans([]);
            setWeekSchedule([
                { id: 1, plans: [] },
                { id: 2, plans: [] },
                { id: 3, plans: [] },
                { id: 4, plans: [] },
                { id: 5, plans: [] },
                { id: 6, plans: [] },
                { id: 7, plans: [] },
            ])
        }
    }, [timeTables]);

    useEffect(() => {
        getVmsGroups();
    }, []);


  useEffect(()=>{
    setSelectedGroup(vmsGroups.find((group: any) => group.id === selectedGroupId));
  },[selectedGroupId]);

  const [selectedPlanOption, setSelectedPlanOption] = useState('');

  const handlePlanOptionChange = (value:any) => {
    setSelectedPlanOption(value);
  };

  const [selectedVmsForPlan, setSelectedVmsForPlan] = useState<any>(null);

  const handleSelectChange = (vmsId: string) => {
      const selectedVmsObject = vmsList.find((vms:any) => vms.id === vmsId);
      setSelectedVmsForPlan(selectedVmsObject);
  };

    return (
        <div>
            <Select
                placeholder="Vms Grubunu Seçin"
                style={{ width: 'calc(100% - 120px)', color: 'black', marginBottom: '16px' }} // 16px boşluk
                dropdownStyle={{ backgroundColor: '#2c3e50', color: '#ffffff' }}
                onChange={(value) => {
                    setSelectedGroupId(value);
                    setSelectedItemsGroups([]);
                }}
            >
                {vmsGroups.map((group: any) => (
                    <Option key={group.id} value={group.id} style={{ color: '#ffffff', backgroundColor: '#2c3e50' }}>
                        {group.vms_group_name}
                    </Option>
                ))}
            </Select>

            {selectedGroupId && (
                <Select
                    placeholder="Plan opsiyonu seçiniz"
                    style={{ width: 'calc(100% - 120px)', color: 'black', marginBottom: '16px' }} // 16px boşluk
                    onChange={handlePlanOptionChange}
                >
                    <Option value="manualplan" style={{ color: '#ffffff', backgroundColor: '#2c3e50', display: 'block' }}>
                        <Tooltip title="VMS grubundaki her bir VMS'in planlarını, VMS'in mevcut planlarının üzerinde değişiklik yaparak manuel olarak hazırlarsınız.">
                            <span>Manuel Mod</span>
                        </Tooltip>
                    </Option>
                    <Option value="overwriteplan" style={{ color: '#ffffff', backgroundColor: '#2c3e50', display: 'block' }}>
                        <Tooltip title="Hazırlayacağınız plan, VMS grubundaki her bir VMS'in planlarının üzerine yazılır.">
                            <span>Multi Mod</span>
                        </Tooltip>
                    </Option>
                </Select>
            )}

            {selectedGroup && selectedPlanOption === "manualplan" && (
                <Select
                    style={{ width: 'calc(100% - 120px)', color: 'black', marginBottom: '16px' }} // 16px boşluk
                    placeholder={t('Vms Seçin')}
                    value={selectedVmsForPlan?.id}
                    onChange={handleSelectChange}
                >
                    {selectedGroup.groups.map((group: any) => {
                        const vms = vmsList.find((vms) => vms.id === group);
                        return (
                            <Option key={group} value={group} style={{ color: '#ffffff', backgroundColor: '#2c3e50' }}>
                                {vms?.name}
                            </Option>
                        );
                    })}
                </Select>
            )}

            {selectedGroup && selectedPlanOption === "manualplan" && selectedVmsForPlan && (<VmsPlans selectedVms={selectedVmsForPlan} />)}
                {/* Planlar Start */}
                {(currentPage === "plans" && selectedGroupId && selectedPlanOption === "overwriteplan") &&(
                    <Card title={<b> {t("plans")}</b>} hoverable style={{marginTop:"20px"}}>
                        <Button
                            type="primary"
                            onClick={handleAddPlan}
                            style={{
                                marginBottom: "20px", backgroundColor: '#2c3e50',
                                borderColor: '#2c3e50',
                                color: '#ffffff'
                            }}
                        >
                            {t("add_new_plan")}
                        </Button>
                        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
                            {plans.map((plan) => (
                                <Col key={plan.id} span={24}>
                                    <Card
                                        type="inner"
                                        hoverable
                                        style={{
                                            background: "#ffffff",
                                            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        <div className="plan-table-container">
                                            <Input
                                                type="text"
                                                placeholder="Plan Adı"
                                                value={plan.name}
                                                onChange={handlePlanNameChange(plan.id)}
                                                style={{ marginBottom: "10px" }}
                                            />
                                            <Table
                                                columns={[
                                                    {
                                                        title: t("drawing_file"),
                                                        dataIndex: "drawingFileName",
                                                        key: "drawingFileName",
                                                        render: (_, record, rowIndex) => (
                                                            <Select
                                                                style={{ width: "100%" }}
                                                                placeholder={t("select_drawing_file")}
                                                                value={record.drawingFileName || undefined}
                                                                onChange={(value: string) =>
                                                                    handleCellChange(
                                                                        plan.id,
                                                                        rowIndex,
                                                                        "drawingFileName"
                                                                    )({
                                                                        target: { value },
                                                                    } as React.ChangeEvent<HTMLInputElement>)
                                                                }
                                                            >
                                                                {drawings.map((file, index) => (
                                                                    <Option key={index} value={file}>
                                                                        {file}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        ),
                                                    },
                                                    {
                                                        title: t("duration"),
                                                        dataIndex: "duration",
                                                        key: "duration",
                                                        render: (_, record, rowIndex) => (
                                                            <Input
                                                                type="number"
                                                                value={record.duration}
                                                                onChange={handleCellChange(
                                                                    plan.id,
                                                                    rowIndex,
                                                                    "duration"
                                                                )}
                                                            />
                                                        ),
                                                    },
                                                    {
                                                        title: "",
                                                        dataIndex: "action",
                                                        key: "action",
                                                        render: (_, __, rowIndex) => (
                                                            <Button
                                                                onClick={() => handleRemoveRow(plan.id, rowIndex)}
                                                                danger
                                                                ghost
                                                                style={{
                                                                    marginRight: "5px",
                                                                    marginBottom: "5px",
                                                                }}
                                                                icon={<DeleteOutlined />}
                                                                type="link"
                                                            />
                                                        ),
                                                    },
                                                ]}
                                                dataSource={plan.phases || []}
                                                pagination={false}
                                                className="plan-table"
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                marginTop: "10px",
                                            }}
                                        >
                                            <div>
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    onClick={() => handleNewRow(plan.id)}
                                                    style={{
                                                        backgroundColor: '#2c3e50',
                                                        borderColor: '#2c3e50',
                                                        color: '#ffffff'
                                                    }}
                                                >
                                                    {t("add_new_line")}
                                                </Button>
                                            </div>
                                            <Button
                                                onClick={() => handleRemovePlan(plan.id)}
                                                size="small"
                                                className="remove-plan-button"
                                                danger
                                                ghost
                                            >
                                                {t("remove_plan")}
                                            </Button>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row>

                    </Card>
                )}
                {/* Planlar End */}

                {/* Haftalık Plan Start */}
                {(currentPage === "plans" && selectedGroupId && selectedPlanOption === "overwriteplan") && (
                    <Card
                        title={<b> {t("weekly_plan")}</b>}
                        hoverable
                        style={{marginTop:"10px"}}
                    >
                        <Row
                            gutter={[16, 16]}
                            style={{
                                overflowX: "auto",
                                justifyContent: "center",
                            }}
                        >
                            {weekSchedule.map((day, index) => renderDayCard(day, index))}
                        </Row>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20px",
                            }}
                        >
                            <Popconfirm
                                title={t("do_you_confirm?")}
                                onConfirm={handleCompletePlan}
                                okText={t("yes")}
                                cancelText={t("no")}
                            >
                                <Button type="primary" size="large" style={{
                                    backgroundColor: '#2c3e50',
                                    borderColor: '#2c3e50',
                                    color: '#ffffff'
                                }}>{t("save")}</Button>
                            </Popconfirm>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <Tooltip title={t("go_forward")}>
                                <Button size="large" shape="circle" icon={<RightOutlined />} style={{ marginRight: "10px" }} onClick={goForward} />
                            </Tooltip>
                        </div>
                    </Card>
                )}


                {/* Haftalık Plan End */}
                {(currentPage === "send_plan" && selectedGroupId && selectedPlanOption === "overwriteplan") &&(
                    <Card style={{marginTop:"20px"}}>
                        <div style={{ display: "block", justifyContent: "center", alignItems: "center"}}>
                            <Card
                                style={{
                                    background: 'linear-gradient(to right, #bdc3c7, #2c3e50)',
                                    borderRadius: '10px',
                                    padding: '20px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '18px',
                                    color: '#ffffff',
                                    height: '150px'
                                }}
                            >
                            <span>{t('Hazırladığınız planı, seçtiğiniz VMS grubundan hangi VMS`lere göndereceksiniz?')}</span><br />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Button
                                    size="small"
                                    onClick={handleSelectAllGroups}
                                    style={{ margin: '0 10px', color: '#ffffff', backgroundColor: '#2c3e50', border: 'none' }}
                                >
                                    {selectedItemsGroups.length === selectedGroup.groups.length ? t("Seçimleri Kaldır") : t("Tümünü Seç")}
                                </Button>
                                <Select
                                    mode="multiple"
                                    style={{ width: 'calc(100% - 120px)', color: "#ffffff" }}
                                    placeholder={t('Vmsleri Seçin')}
                                    value={selectedItemsGroups}
                                    onChange={handleCheckboxChangeGroups}
                                    dropdownStyle={{ backgroundColor: '#2c3e50', color: '#ffffff' }}
                                    tagRender={tagRender}
                                >
                                    {selectedGroup.groups.map((group: any) => (
                                        <Option key={group} value={group} style={{ color: '#ffffff', backgroundColor: '#2c3e50' }}>
                                            {vmsList.find(vms => vms.id === group)?.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                                <Popconfirm
                                    title="Do you confirm?"
                                    onConfirm={sendPlanToVms}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        type="primary"
                                        style={{
                                            backgroundColor: '#2c3e50',
                                            borderColor: '#2c3e50',
                                            color: '#ffffff'
                                        }}
                                    >
                                        Gönder
                                    </Button>
                                </Popconfirm>
                            </Card>
                            <Modal
                                title="Vms Toplu Yönetimi - Plan Gönderme"
                                visible={modalVisible}
                                onCancel={() => setModalVisible(false)}
                                footer={null}
                            >
                                <Steps direction="vertical">
                                    {modalContent.map((item: any, index: any) => {
                                        const vms = vmsList.find(vms => vms.id === item.vmsId);

                                        return (
                                            <Step
                                                key={index}
                                                title={vms?.name}
                                                description={
                                                    item.status === 'started'
                                                        ? `${vms?.name} - Plan gönderme başlatıldı`
                                                        : item.status === 'success'
                                                            ? `${vms?.name} - Plan gönderme bitti`
                                                            : `${vms?.name} - Plan gönderilirken hata oluştu`
                                                }
                                                status={item.status === 'started' ? 'process' : item.status === 'success' ? 'finish' : 'error'}
                                            />
                                        );
                                    })}
                                </Steps>
                                {processing && <div>İşlem devam ediyor...</div>}
                            </Modal>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                            <Tooltip title={t("go_back")}>
                                <Button size="large" shape="circle" icon={<LeftOutlined />} style={{ marginRight: "10px" }} onClick={goBack} />
                            </Tooltip>
                        </div>
                    </Card>
                )}

            </div>
    );
};

export default MultiModeVmsPlan;
