import React, { useContext, useMemo } from "react";

import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Table,
  message,
  Row,
  Col,
  Modal,
  Button,
  Select,
} from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { ColumnsType } from "antd/lib/table";

// context
import { PlanContext } from "../../../../context/PlanContext";

import DetectorPlanModal from "../DetectorPlanModal";
import DetectorInterface from "../../../../models/DetectorInterface";
import DetectorModalStateInterface from "../../../../models/DetectorModalStateInterface";

import columnFieldKeys from "../../formFieldStructs/planFormPhasesTableFields";
import planFormEpicFields from "../../formFieldStructs/planFormEpicFields";

import getPhasesTableColumns from "./utils/getPhasesTableColumns";

import submitPlanForm from "./utils/submitPlanForm";
import { PhaseType } from "../..";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CoordinationInterruptState from "../../../../models/CoordinationInterruptState";
import CoordinationInterruptedModal from "../CoordinationInterruptedModal";

interface Props {
  currentStep: number;
  planFormObject: any;
  isFlashPlan: boolean | undefined;
  planActionMode: "edit" | "copy" | null;
  getPlanFormEpics: any;
  predefinedPhasesFormObject?: any; // whole plan card data is inside this prop
  clearColumnsUptoPlanModes: (
    selectedProperty: "interruptorPhaseId" | "trailerPhaseId" | "schedule" | "coordinatedInterrupt"
  ) => void;
  updatePlanFormEpics: (field: any, value: any) => void;
  multipleUpdatePlanFormEpics: (
    updateBody: {
      field: any;
      value: any;
    }[]
  ) => void;
  updatePhasesForm: (field: any, phaseKey: any, value: any) => void;
  close: any;
}

const availablePlanModes = {
  greenWaveMode: {
    value: planFormEpicFields.greenWaveMode.fieldName,
    label: "green_wave",
  },
  urgentEnabled: {
    value: planFormEpicFields.urgentEnabled.fieldName,
    label: "urgent_enabled",
  },
  phaseInsertion: {
    value: planFormEpicFields.phaseInsertion.fieldName,
    label: "phase_insertion",
  },
  restrictedPriority: {
    value: planFormEpicFields.restrictedPriority.fieldName,
    label: "restricted_priority",
  },
  trailingPriority: {
    value: planFormEpicFields.trailingPriority.fieldName,
    label: "trailing_priority",
  },
  coordinatedInterrupt: {
    value: planFormEpicFields.coordinatedInterrupt.fieldName,
    label: "coordinated_interrupt",
  },
  vipPass: {
    value: planFormEpicFields.vipPass.fieldName,
    label:"vip_pass"
  },
  timeoutInterrupt: {
    value: planFormEpicFields.timeoutInterrupt.fieldName,
    label:"timeout_interrupt"
  }
};

const alternativePlanModes = [
  availablePlanModes.phaseInsertion.value,
  availablePlanModes.restrictedPriority.value,
  availablePlanModes.trailingPriority.value,
  availablePlanModes.coordinatedInterrupt.value,
  availablePlanModes.vipPass.value,
  availablePlanModes.timeoutInterrupt.value,
];

const PhasesFormTable: React.ForwardRefExoticComponent<
  Props & React.RefAttributes<any>
> = forwardRef<any, Props>(
  (
    {
      currentStep,
      planFormObject,
      isFlashPlan,
      planActionMode,
      getPlanFormEpics,
      updatePlanFormEpics,
      multipleUpdatePlanFormEpics,
      updatePhasesForm,
      close,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const planContextValues = useContext(PlanContext);
    const [contextSignalPlan, setContextSignalPlan] =
      planContextValues.signalPlan;
    const juncPhases: PhaseType[] = planContextValues.juncPhases;
    const juncDetectors: DetectorInterface[] = planContextValues.juncDetectors;

    const [form] = Form.useForm();

    // Detector Modal state and functions
    const [detectorModalState, setDetectorModalState] =
      useState<DetectorModalStateInterface>({
        visible: false,
        phaseId: null,
        rowData: null,
      });
    
    const [coordinationInterruptModalState, setCoordinationInterruptModalState] =
      useState<CoordinationInterruptState>({
        visible: false,
        phaseId: null,
        rowData: null,
      });

    const getSelectedDetectors = (rowData: any) => {
      let loopList =
        rowData[columnFieldKeys.loopList] &&
        typeof rowData[columnFieldKeys.loopList] === "string"
          ? rowData[columnFieldKeys.loopList].split("-")
          : [];

      let buttonList =
        rowData[columnFieldKeys.buttonList] &&
        typeof rowData[columnFieldKeys.buttonList] === "string"
          ? rowData[columnFieldKeys.buttonList].split("-")
          : [];

      let detectorList =
        rowData[columnFieldKeys.detectorList] &&
        typeof rowData[columnFieldKeys.detectorList] === "string"
          ? rowData[columnFieldKeys.detectorList].split("-")
          : [];

      return [...loopList, ...buttonList, ...detectorList];
    };

    const showDetectorModal = (id: any, rowData: any) => {
      setDetectorModalState((prev) => ({
        ...prev,
        visible: true,
        phaseId: id,
        rowData,
      }));
    };

    const showCoordinationInterruptModal = (id: any, rowData: any) => {
      setCoordinationInterruptModalState((prev) => ({
        ...prev,
        visible: true,
        phaseId: id,
        rowData,
      }));
    };

    // avoid formHeaderInitials from to be created on each comp update
    const planFormEpics = React.useMemo((): any => getPlanFormEpics(), []);

    const phasesFormObject = React.useMemo(
      () => planFormObject.phasesFormObject,
      [planFormObject]
    );

    const [greenWaveMode, setGreenWaveMode] = useState<boolean>(
      planFormEpics?.[availablePlanModes.greenWaveMode.value]
    );

    const [timeoutInterruptMode, setTimeoutInterruptMode] = useState<boolean>(
      planFormEpics?.[availablePlanModes.timeoutInterrupt.value]
    );

    var cancelToken: any;

    useImperativeHandle(ref, () => ({
      submitTableForm: () => form.submit(),
    }));

    //////////////////// column data updater
    const isRelatedColumnsVisibleWithIsInterrupting: boolean = useMemo(() => {
      let visibility = false;
      for (let i = 0; i < phasesFormObject.length; i++) {
        let phasesFormRow = phasesFormObject[i];
        if (phasesFormRow[columnFieldKeys.isInterrupting]) {
          visibility = true;
          break;
        }
      }
      return visibility;
    }, [phasesFormObject]);

    const [watchIsInterruptingColumn, setWatchIsInterruptingColumn] =
      useState(false);

    const checkTS_beforeSubmit = () => {
      let totalMinTS = 0;
      let totalMaxTS = 0;
      let { meanTime, plus, minus, extraTime } = columnFieldKeys;
      let { minTS, maxTS } = planFormEpicFields;
      phasesFormObject.forEach((phase: any) => {
        totalMinTS += phase[meanTime] - phase[minus] + phase[extraTime];
        totalMaxTS += phase[meanTime] + phase[plus] + phase[extraTime];
      });

      return {
        [minTS.fieldName]: totalMinTS,
        [maxTS.fieldName]: totalMaxTS,
      };
    };

    const getRowIdsFromIsInterruptingFields = (
      id: number,
      isChecked: boolean
    ) => {
      // after changed IsInterrupting
      let updatedTableObject = phasesFormObject.map((row: any) => {
        if (row[columnFieldKeys.phase_id] === id) {
          return {
            ...row,
            [columnFieldKeys.isInterrupting]: isChecked,
          };
        }
        return row;
      });

      let checkedIsInterruptingRows = updatedTableObject
        .filter((row: any) => {
          return row[columnFieldKeys.isInterrupting] === true;
        })
        .map((row: any) => row[columnFieldKeys.phase_id])
        .sort();

      let uncheckedIsInterruptingRows = updatedTableObject
        .filter((row: any) => {
          return !row[columnFieldKeys.isInterrupting];
        })
        .map((row: any) => row[columnFieldKeys.phase_id])
        .sort();

      return {
        checkedIsInterruptingRows,
        uncheckedIsInterruptingRows,
      };
    };

    const columns: ColumnsType<any> = useMemo((): ColumnsType<any> => {
      return getPhasesTableColumns({
        planFormObject,
        updatePhasesForm,
        getSelectedDetectors,
        showDetectorModal,
        showCoordinationInterruptModal,
        getRowIdsFromIsInterruptingFields,
        setWatchIsInterruptingColumn,
        isRelatedColumnsVisibleWithIsInterrupting,
        juncPhases,
      });
    }, [watchIsInterruptingColumn, planFormObject]);
    const [tableColumnsData, setTableColumnsData] = useState(columns);

    // set table columns if watch bit has changed
    useEffect(() => {
      setTableColumnsData(columns);
    }, [watchIsInterruptingColumn, columns]);
    //////////////////// column data updater

    const alternativePlanModeSelection = (e: any, availablePlanMode: any) => {
      // uncheck alternative plan modes
      
      if (
        e.target.checked &&
        alternativePlanModes.includes(availablePlanMode.value)
      ) {
       
        // select alternative field and managementType are selected at default
        let updateObject: {
          field: any;
          value: any;
        }[] = [
          {
            field: availablePlanMode.value,
            value: e.target.checked,
          },
          // set managementType if checked value is one of the alternatives
          {
            field: planFormEpicFields.managementType.fieldName,
            value: availablePlanMode.value,
          },
        ];

        // uncheck alternative
        // insert unselected alternative fields to unite them in updateObject with false 'value' 's
        alternativePlanModes.forEach((alternativePlanMode) => {
          if (alternativePlanMode !== availablePlanMode.value) {
            let fields = form.getFieldsValue();
            form.setFieldsValue({
              ...fields,
              [alternativePlanMode]: false,
            });

            updateObject.push({
              field: alternativePlanMode,
              value: false,
            });
          }
        });

        // update planFormEpics in one line to prevent state updates again and again
        multipleUpdatePlanFormEpics(updateObject);
      }
      // if there is no selected alternatives and managementType, then set field as null
      else if (
        !e.target.checked &&
        alternativePlanModes.includes(availablePlanMode.value)
      ) {
        // updatePlanFormEpics(planFormEpicFields.managementType.fieldName, null);
        // updatePlanFormEpics(availablePlanMode.value, e.target.checked);
        multipleUpdatePlanFormEpics([
          {
            field: planFormEpicFields.managementType.fieldName,
            value: null,
          },
          {
            field: availablePlanMode.value,
            value: e.target.checked,
          },
        ]);
      } else if (!alternativePlanModes.includes(availablePlanMode.value)) {
        updatePlanFormEpics(availablePlanMode.value, e.target.checked);

        // set greenWaveMode
        if (
          availablePlanMode.value === availablePlanModes.greenWaveMode.value
        ) {
          setGreenWaveMode((prev) => !prev);
        } 
      }
   
      if (
        availablePlanMode.value === availablePlanModes.timeoutInterrupt.value
      ) {
        setTimeoutInterruptMode((prev) => !prev);
      }

      console.log(phasesFormObject)
      // trigger column watcher when managementType is changed
      setWatchIsInterruptingColumn((prev) => !prev);
    };

    const savePlanHandler = (planFormOutput: any) => {
      // if EDIT
      if (planActionMode === "edit") {
        const formPlanId = planFormOutput?.id;

        setContextSignalPlan((prev: any) => ({
          ...prev,
          plans: prev?.plans?.map((plan: any) => {
            if (plan?.id === formPlanId) {
              return {
                ...plan,
                ...planFormOutput,
              };
            }
            return plan;
          }),
        }));
      }

      // if CREATE(copy or scratch)
      if (planActionMode !== "edit") {
        if (planFormOutput?.planType === "Flash") {
          setContextSignalPlan((prev: any) => ({
            ...prev,
            plans: [planFormOutput, ...prev.plans],
          }));
        } else {
          setContextSignalPlan((prev: any) => ({
            ...prev,
            plans: [...prev.plans, planFormOutput],
          }));
        }
      }
    };

    const savePlan = (formValues: any) => {
      const { planFormOutput, sequenceTimeoutValidates } = submitPlanForm({
        form,
        formValues,
        planFormObject,
        checkTS_beforeSubmit,
        isFlashPlan,
        planActionMode,
        cancelToken,
        juncPhases,
      });
      // confirm is not required if plan is flash plan
      if (isFlashPlan) {
        savePlanHandler(planFormOutput);
        close();
        return;
      }
      if (!sequenceTimeoutValidates) {
        Modal.confirm({
          title: t("phase_sequence_timeout"),
          icon: <ExclamationCircleOutlined />,
          content: t("not_suitable_min_n_max_sequence_timeout"),
          okText: t("approve"),
          cancelText: t("cancel"),
          centered: true,
          onOk: () => {
            savePlanHandler(planFormOutput);
            close();
          },
        });
      } else {
        savePlanHandler(planFormOutput);
        close();
      }
    };

    const checkIfPlanExistsOnSchedular = (currentPlanId: number) => {
      const dailyPlans = contextSignalPlan?.dailyPlans;

      let isPlanUsed: boolean = false;
      if (dailyPlans && Array.isArray(dailyPlans)) {
        for (let index in dailyPlans) {
          let checkIfPlanIsAvailable = dailyPlans[index]?.plans?.find(
            (plan: any) => plan?.id === currentPlanId
          );
          if (checkIfPlanIsAvailable) {
            isPlanUsed = true;
            break;
          }
        }
      }

      return isPlanUsed;
    };

    const deletePlanFromSignalPlan = (currentPlanId: number) => {
      setContextSignalPlan((prev: any) => ({
        ...prev,
        plans: prev?.plans?.filter((plan: any) => {
          return currentPlanId !== plan?.id;
        }),
      }));

      message.info(t("plan_deleted"));

      // close plan modal when it's deleted
      close();
    };

    return (
      <>
        {currentStep === 1 && (
          <>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              layout="horizontal"
              labelAlign="left"
              form={form}
              initialValues={
                isFlashPlan
                  ? {
                      ...planFormEpics,
                      [planFormEpicFields.name.fieldName]: "Flas Plani",
                      id: 0,
                    }
                  : planFormEpics
              }
              onFinish={(formValues) => savePlan(formValues)}
            >
              <Form.Item
                name="name"
                label={t("plan_name")}
                rules={[
                  {
                    required: true,
                    message: `${t("this_field_is_required")}`,
                  },
                ]}
              >
                <Input
                  placeholder={t("enter_plan_name")}
                  name={planFormEpicFields.name.fieldName}
                  onChange={(e) => {
                    updatePlanFormEpics(
                      planFormEpicFields.name.fieldName,
                      e.target.value
                    );
                  }}
                  disabled={isFlashPlan}
                />
              </Form.Item>
              {!isFlashPlan && (
                <>
                  <Row>
                    {Object.values(availablePlanModes).map(
                      (availablePlanMode, availablePlanModeIndex) => {
                        return (
                          <Col
                            lg={4}
                            sm={12}
                            xs={24}
                            key={availablePlanModeIndex}
                          >
                            <Form.Item
                              name={availablePlanMode.value}
                              valuePropName="checked"
                            >
                              <Checkbox
                                onChange={(e) => {
                                  alternativePlanModeSelection(
                                    e,
                                    availablePlanMode
                                  );
                                }}
                              >
                                {t(availablePlanMode.label)}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        );
                      }
                    )}
                  </Row>

                  {/* If Plan Type is Green Wave */}
                  {greenWaveMode && (
                    <>
                      <Form.Item
                        name="cycleTime"
                        label={t("cycle_time")}
                        rules={[
                          {
                            required: true,
                            message: `${t("this_field_is_required")}`,
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder={t("enter_cycle_time")}
                          defaultValue={0}
                          name={planFormEpicFields.cycleTime.fieldName}
                          onChange={(value) => {
                            updatePlanFormEpics(
                              planFormEpicFields.cycleTime.fieldName,
                              value
                            );
                          }}
                          style={{
                            width: "20%",
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="offsetDuration"
                        label={t("offset_duration")}
                        rules={[
                          {
                            required: true,
                            message: `${t("this_field_is_required")}`,
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder={t("enter_offset")}
                          defaultValue={0}
                          name={planFormEpicFields.offsetDuration.fieldName}
                          onChange={(value) => {
                            updatePlanFormEpics(
                              planFormEpicFields.offsetDuration.fieldName,
                              value
                            );
                          }}
                          style={{
                            width: "20%",
                          }}
                        />
                      </Form.Item>
                    </>
                  )}


                      {/* If Plan Type is Timeout interrupt mode */}
                      {timeoutInterruptMode && (
                    <>
                      <Form.Item
                        name="mainPhase"
                        label={t("main_phase")}
                        rules={[
                          {
                            required: true,
                            message: `${t("this_field_is_required")}`,
                          },
                        ]}
                      >
                             <Select
                              placeholder={t("select")}
                              style={{ width: "20%", overflow: "hidden" }}
                              options={phasesFormObject.map((item:any) => {
                                return {
                                  value: item.internalId,
                                  label: item.name,
                                };
                              })}
                              onChange={(value) => {

                                phasesFormObject.map((item:any) => {
                                  if(item.internalId==value){
                                    item['isMain']=true; 
                                  }else{
                                    item['isMain']=false; 
                                  }
                                })}


                               /* let targetObject = phasesFormObject.find((obj:any) => obj.internalId === value);

                                if (targetObject) {
                                    targetObject.isMain = true;
                                }
                            */
                              }

                            ></Select>
                      </Form.Item>               
                    </>
                  )}

                  <Table
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={phasesFormObject}
                    columns={tableColumnsData}
                    pagination={false}
                    style={{
                      overflow: "scroll",
                    }}
                    // rowKey="id"
                    rowKey={(phase) => phase?.internalId}
                    scroll={{
                      x: true,
                    }}
                  />
                </>
              )}
            </Form>
            {!isFlashPlan && detectorModalState.visible && (
              <DetectorPlanModal
                modalState={detectorModalState}
                setIsModalVisible={(visibleSetter: any) => {
                  setDetectorModalState((prev) => ({
                    ...prev,
                    visible: visibleSetter,
                  }));
                }}
                detectors={juncDetectors}
                updatePhasesForm={updatePhasesForm}
              />
            )}
            {!isFlashPlan && coordinationInterruptModalState.visible && (
              <CoordinationInterruptedModal
                modalState={coordinationInterruptModalState}
                setIsModalVisible={(visibleSetter: any) => {
                  setCoordinationInterruptModalState((prev) => ({
                    ...prev,
                    visible: visibleSetter,
                  }));
                }}
                updatePhasesForm={updatePhasesForm}
              />
            )}
            {planActionMode === "edit" && (
              <Button
                type="primary"
                danger
                onClick={() => {
                  const currentPlanId = planFormObject?.id;
                  const isPlanUsed =
                    checkIfPlanExistsOnSchedular(currentPlanId);

                  // delete plan if it does not exist
                  if (!isPlanUsed) {
                    deletePlanFromSignalPlan(currentPlanId);
                  } else {
                    message.error(t("this_plan_is_used_inside_weekly_plan"));
                  }
                }}
                style={{
                  marginTop: "1rem",
                }}
              >
                { t("delete_plan") }
              </Button>
            )}
          </>
        )}
      </>
    );
  }
);

export default PhasesFormTable;
