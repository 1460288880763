import { Barrier } from "../../../../data/barrier/Barrier";
import { GenericMapModalTitle } from "../../components/generic/map-modal/GenericMapModalTitle";
import withLogging from "../../high-order-components/LoggerHOC";

interface BarrierModalTitleProps {
  selectedBarrier: Barrier;
}

function BarrierModalTitle(props: BarrierModalTitleProps) {
  const { selectedBarrier } = props;

  return (
    <GenericMapModalTitle
      color={selectedBarrier.status ? "#68F973" : "#F97768"}
    >
      <span style={{ fontSize: "2vw" }}>{selectedBarrier.name}</span>
    </GenericMapModalTitle>
  );
}

export default withLogging(BarrierModalTitle);
