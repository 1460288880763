const planFormPhasesTableFields = {
  plus: "plus",
  minus: "minus",
  loopList: "loopList",
  meanTime: "meanTime",
  schedule: "schedule",
  extraTime: "extraTime",
  buttonList: "buttonList",
  phase_id: "internalId",
  detectorList: "detectorList",
  signalOutput: "signalOutput",
  // signalOutput: "output_number",
  isInterrupting: "isInterrupting",
  successorPhase: "successorPhase",
  isMultiOccurring: "isMultiOccurring",
  interruptorPhaseId: "interruptorPhaseId",
  trailerPhaseId: "trailerPhaseId",
  minSequenceTimeout: "minSequenceTimeout",
  // minSequenceTimeout: "min_sequence_timeout",
  maxSequenceTimeout: "maxSequenceTimeout",
  // maxSequenceTimeout: "max_sequence_timeout",
  minSensorThreshold: "minSensorThreshold",
  detectorDecisionList: "detectorDecisionList",
  phase_name: "name", // extra field
  isFixPhase:"isFixPhase"
};

export default planFormPhasesTableFields;
