import { useEffect, useState } from "react";
import {
  layerData,
  useCustomLayerData,
} from "../../../../contexts/map/CustomLayerContext";
import { useTranslation } from "react-i18next";
import { LegendData, legendData } from "../../map-constants/legend/LegendData";
import { Badge } from "antd";

interface LegendTabContentProps {
  layerId: number;
  legendList: { [key: number]: LegendData[] };
  setLegendList: any;
}

function LegendTabContent(props: LegendTabContentProps) {
  const { t } = useTranslation();
  const { layerId } = props;
  const { layerDataList } = useCustomLayerData();
  const [contentData, setContentData] = useState<any>({});
  const [layer, setLayer] = useState<layerData>();

  const groupByKey = (array: any, key: string) => {
    return array.reduce((result: any, item: any) => {
      (result[item[key]] = result[item[key]] || []).push(item);
      return result;
    }, {});
  };

  const handleClick = (filterGroupId: number) => {
    let mapped = props.legendList[layerId].map((filter) => {
      return filter.id === Number(filterGroupId)
        ? { ...filter, filterStatus: !filter.filterStatus }
        : { ...filter };
    });
    props.setLegendList((prev: any) => {
      const newState = { ...prev };
      newState[layerId] = mapped;
      return newState;
    });

    layer?.legend?.setOnMapData(
      layer.data.filter((layerItem: any) => {
        return mapped
          .filter((value: any) => {
            return value.filterStatus === false;
          })
          .map((item: any) => item.id)
          .includes(layerItem[layer.legend?.countProperty!]);
      })
    );
  };

  useEffect(() => {
    let layer = layerDataList.find((item) => item.id === layerId);
    setLayer(layer);
    if (layer?.legend) {
      let groupedData = groupByKey(layer?.data, layer?.legend?.countProperty);
      setContentData(groupedData);
    }
  }, [layerDataList]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        height: "60%",
      }}
    >
      {Object.keys(contentData).length > 0
        ? props.legendList[layerId]?.map((item, index) => {
            return (
              <button
                onClick={() => handleClick(item.id)}
                style={{
                  all: "unset", // Remove all default button styles
                  cursor: "pointer",
                }}
              >
                <div
                  key={item.id + "6-" + index}
                  style={{
                    padding: "5px 0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    key={item.id + "4-" + index}
                    className={item.filterCss}
                  ></div>

                  <div
                    key={index + "81" + item.id}
                    className={
                      item.filterStatus ? "legendFilter strike" : "legendFilter"
                    }
                  >
                    {t(item.filterName!).toUpperCase()}
                  </div>

                  {contentData[item.id]?.length > 0 && !item?.filterStatus ? (
                    <Badge
                      count={contentData[item.id].length}
                      overflowCount={1000}
                      style={{ backgroundColor: "#2f4050" }}
                    />
                  ) : null}
                </div>
              </button>
            );
          })
        : null}
      {}
    </div>
  );
}

export default LegendTabContent;
