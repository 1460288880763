import React, { useContext } from "react";
import { StatisticContext } from "../../context/StatisticContext";
import FilterAreaForSummary from "./components/FilterArea/FilterAreaForSummary";
interface Props {
  selectedJunction: any;
  isHaveLoop: boolean;
}
const Summary: React.FC<Props> = ({ selectedJunction,isHaveLoop }) => {
  const statisticContextValues = useContext(StatisticContext);
  return (
    <>
      <div style={{ marginTop: "25px" }}>
        {statisticContextValues?.headerDirector.summary.daily && (
          <FilterAreaForSummary
            isHaveLoop={isHaveLoop}
            selectedJunction={selectedJunction}
          ></FilterAreaForSummary>
        )}
      </div>
    </>
  );
};

export default Summary;
