import { Button, ButtonProps } from "antd";
import styled, { css } from "styled-components";

interface Props extends ButtonProps {
  margin?: string;
  backgroundColor?: string;
  borderColor?: string;
  boxShadow?: string;
  css?: string;
}
const StyledButton = styled(({margin, backgroundColor, borderColor, boxShadow, css, ...props}) => <Button {...props} />)`
  ${(p:Props) => css`
  margin: ${p.margin};
  background-color: ${p.backgroundColor};
  border-color: ${p.borderColor};
  box-shadow: ${p.boxShadow};
  ${p.css}
  &:hover{
    background-color: ${p.backgroundColor};
    border-color: ${p.borderColor};
  }
  &:active{
    background-color: ${p.backgroundColor};
    border-color: ${p.borderColor};
  }
  &:focus{
    background-color: ${p.backgroundColor};
    border-color: ${p.borderColor};
  }
  `}
`;

export default StyledButton;