import styled from "styled-components";

const SearchBarStyl = styled.div`
  .ant-input {
    background-color: #2f4050;
    color: white;
  }
  opacity: 0.9;
`;

export default SearchBarStyl;
