import { Table, Select, Button, Input, Divider, Row, Col, Card } from "antd";
import { useEffect, useState } from "react";
import InventoryStockManagementService from "../../data/inventory-stock-management/InventoryStockManagementService";
import LocationModal from "./LocationModal";
import { BarsOutlined, EnvironmentOutlined, FilterOutlined } from "@ant-design/icons";
import InventoryCrudMenu from "./crud-menu";

const { Option } = Select;

const InventoryStockManagement = () => {
  const [inventoryStock, setInventoryStock] = useState<any[]>([]);
  const [inventoryTypes, setInventoryTypes] = useState<any[]>([]);
  const [inventorySubTypes, setInventorySubTypes] = useState<any[]>([]);
  const [currentType, setCurrentType] = useState<any>(null);
  const [currentSubType, setCurrentSubType] = useState<any>(null);
  const [currentOperation, setCurrentOperation] = useState<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<any>([0, 0]);
  const [selectedIconUrl, setSelectedIconUrl] = useState<any>(null);
  const [iconUrls, setIconUrls] = useState<any>({});
  const [subTypeIcons, setSubTypeIcons] = useState<any>({});
  const [modalVisible, setModalVisible] = useState<any>(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchBarcode, setSearchBarcode] = useState<string>("");
  const [totalResult, setTotalResult] = useState<number>(0);

  const getInventoryStock = async () => {
    if (!currentType) return;
    try {
      const res = await InventoryStockManagementService.getInventoryStock(
        currentType,
        pagination.current,
        pagination.pageSize,
        searchBarcode,
        currentSubType,
        currentOperation
      );
      setInventoryStock(res.data.data ?? []);
      const newTotal = res.data.total;
      setIconUrls(res.data.urls ?? {});
      setTotalResult(newTotal);
      setPagination((prev) => ({
        ...prev,
        total: newTotal,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBarcode(e.target.value);
  };

  const getInventoryTypes = async () => {
    try {
      const res = await InventoryStockManagementService.getInventoryTypes();
      setInventoryTypes(res.data);
      if (res.data.length > 0 && !currentType) {
        setCurrentType(res.data[0].id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getInventorySubTypes = async (type_id: number) => {
    try {
      const res = await InventoryStockManagementService.getInventorySubTypes(type_id);
      setInventorySubTypes(res.data.result ?? []);
      setSubTypeIcons(res.data.urls ?? {});
    } catch (err) {
      console.error(err);
    }
  };

  const getInventoryMinioUrl = async (
    bucketName: string,
    objecName: string
  ) => {
    try {
      const res = await InventoryStockManagementService.getInventoryMinioUrl(
        bucketName,
        objecName
      );
      setSelectedIconUrl(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getInventoryTypes();
  }, []);

  useEffect(() => {
    if (currentType) {
      getInventorySubTypes(currentType);
    }
  }, [currentType]);

  useEffect(() => {
    getInventoryStock();
  }, [currentType, currentSubType, currentOperation, pagination.current, pagination.pageSize]);

  const columns = [
    { title: "Barcode", dataIndex: "barcode", key: "barcode" },
    { title: "Montage Date", dataIndex: "montage_date", key: "montage_date" },
    { title: "Assignee", dataIndex: "assignee", key: "assignee" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Operation", dataIndex: "operation", key: "operation" },
    { title: "Address", dataIndex: "address", key: "address" },
    {
      title: "Related Barcode Number",
      dataIndex: "related_barcode_number",
      key: "related_barcode_number",
      render: (text: any, record: any) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            console.log(record.related_barcode_number);
          }}
          style={{ color: "white", fontWeight:"bold" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Sub Type Name",
      dataIndex: "sub_type_name",
      key: "sub_type_name",
      render: (text: any, record: any) => (
        <div>
          {iconUrls[record.sub_type_id] && (
            <img
              src={iconUrls[record.sub_type_id]}
              alt={text}
              style={{ width: 20, height: 20, marginLeft: 10 }}
            />
          )}
          {text}
        </div>
      ),
    },
    {
      title: "Location",
      key: "location",
      render: (_: any, record: any) => (
        <Button
          onClick={() => {
            setSelectedLocation([record.latitude, record.longitude]);
            getInventoryMinioUrl("invmgmticons", record.sub_type_id.toString());
            setModalVisible(true);
          }}
          icon={<EnvironmentOutlined style={{ color: "#FFFFFF" }} />}
          style={{ border: "none", background: "none" }}
        ></Button>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px', position: 'relative' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, margin: '10px', zIndex: 10 }}>
        <InventoryCrudMenu />
      </div>
      <Row gutter={16} style={{marginTop:"60px"}}>
        {/* <Col xs={24} md={8}>
          <Card
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <BarsOutlined style={{ fontSize: '32px', marginRight: '10px' }} />
                Total Result
              </div>
            }
            bordered={true}
            style={{
              marginBottom: 20,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: 8,
              backgroundColor: '#3c3e44',
              color: 'white',
            }}
            headStyle={{ backgroundColor: '#3c3e44', color: 'white', borderBottom: '1px solid #e8e8e8' }}
            bodyStyle={{ backgroundColor: '#3c3e44', color: 'white', fontSize: '24px', fontWeight: 'bold' }}
          >
            {totalResult}
          </Card>
        </Col> */}
      </Row>

      <Card
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FilterOutlined style={{ fontSize: '32px', marginRight: '10px' }} />
            Filters
          </div>
        }
        bordered={true}
        style={{
          marginBottom: 20,
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: 8,
        }}
        headStyle={{ backgroundColor: '#3c3e44', color: "white", borderBottom: '1px solid #e8e8e8' }}
        bodyStyle={{ backgroundColor: '#6c6d70' }}
      >
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Select
              value={currentType || undefined}
              style={{ width: '100%' }}
              placeholder="Select Type"
              onChange={(value) => {
                setCurrentType(value);
                setPagination((prev) => ({
                  ...prev,
                  current: 1,
                }));
                setCurrentSubType(null);
              }}
            >
              {inventoryTypes.map((type) => (
                <Option key={type.id} value={type.id}>
                  {type.type_name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} md={8}>
            <Select
              value={currentSubType || undefined}
              style={{ width: '100%' }}
              placeholder="Select Sub Type"
              showSearch
              onChange={(value) => {
                setCurrentSubType(value === "all" ? null : value);
                setPagination((prev) => ({
                  ...prev,
                  current: 1,
                }));
              }}
              filterOption={(input:any, option:any) =>
                option?.children?.props?.children[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key="all" value="all">Tümü</Option>
              {inventorySubTypes.map((subType) => (
                <Option key={subType.id} value={subType.id}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {subTypeIcons[subType.id] && (
                      <img
                        src={subTypeIcons[subType.id]}
                        alt={subType.sub_type_name}
                        style={{ width: 20, height: 20, marginRight: 10 }}
                      />
                    )}
                    {subType.sub_type_name}
                  </div>
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} md={8}>
            <Select
              value={currentOperation || undefined}
              style={{ width: '100%' }}
              placeholder="Select Operation Type"
              showSearch
              onChange={(value) => {
                setCurrentOperation(value === "all" ? null : value);
                setPagination((prev) => ({
                  ...prev,
                  current: 1,
                }));
              }}
              filterOption={(input:any, option:any) =>
                option?.children?.props?.children[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key="all" value="all">Tümü</Option>
              <Option key="montage" value="montage">Montage</Option>
              <Option key="barcode" value="barcode">Barcode</Option>
            </Select>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col xs={24} md={8}>
            <Input
              placeholder="Enter Barcode or Related Barcode"
              style={{ width: '100%' }}
              value={searchBarcode}
              onChange={handleBarcodeChange}
              onPressEnter={getInventoryStock}
            />
          </Col>
        </Row>
      </Card>

      <Table
        className="error-table"
        dataSource={inventoryStock}
        columns={columns}
        pagination={{
          total: pagination.total,
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "50"],
          onChange: (page, pageSize) => {
            setPagination((prev) => ({
              ...prev,
              current: page,
              pageSize: pageSize,
            }));
          },
          onShowSizeChange: (current, size) => {
            setPagination((prev) => ({
              ...prev,
              pageSize: size,
              current: 1,
            }));
          }
        }}
      />

      <LocationModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        location={selectedLocation}
        iconUrl={selectedIconUrl}
      />
    </div>
  );
};

export default InventoryStockManagement;
