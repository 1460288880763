import { useEffect, useState } from "react";
import { DualAxes } from "@ant-design/plots";
import { useTranslation } from "react-i18next";
import { PhaseIdColor } from "./GroupData";
import { phaseDataModel, usePhaseData } from "../../../contexts/junction/PhaseDataContext";
import moment from "moment";

function PhasePlot() {
  const { t } = useTranslation();
  const { phaseData } = usePhaseData();
  const [graphData, setGraphData] = useState<phaseDataModel[]>([]);
  const config = {
    data: [graphData, graphData],
    xField: "ts",
    yField: ["vehicleCount", "greenTime"],
    xAxis: {
      label: {
        autoRotate: true,
        autoHide: false,
        autoEllipsis: false,
        formatter: (val: any) => moment(val*1000).utcOffset(localStorage.getItem("timezone")!).format("HH:mm:ss"),
      },
      tickCount: phaseData.length / 2,
    },
    yAxis: {
      vehicleCount: {
        title: {
          text: `${t("vehicle_count")}`,
        },
        min: 0,
      },
      greenTime: {
        title: {
          text: `${t("green_time")}`,
        },
        min: 0,
      },
    },
    meta: {
      greenTime: {
        alias: `${t("green_time")}`,
      },
      vehicleCount: {
        alias: `${t("vehicle_count")}`,
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        columnWidthRatio: 0.5,
        seriesField:"oldPhaseId",
        color: ({ oldPhaseId }:any) => {
          let phaseColorListCount = Object.keys(PhaseIdColor).length / 2;
          let phaseColor = PhaseIdColor[oldPhaseId]
            ? PhaseIdColor[oldPhaseId]
            : PhaseIdColor[oldPhaseId % phaseColorListCount];
    
          return phaseColor;
        },
        // label: {
        //   formatter: (text:any) => {
        //     return text.name;
        //   }       
        // }
      },
      {
        geometry: "line",
        color: "green"
      },
    ],
  };

  let tooltip: any = {
    title: (val: any) => timestampConvertToTime(val*1000),
    customItems: (originalItems: any[]) => {
      originalItems[0].name = originalItems[0].data.name + `(${t("vehicle_count")})`
      return originalItems;
    }
  };
  //TO DO
  useEffect(() => {
    if (phaseData.length > 0) {
      let convertedPhaseData = phaseData.slice(-15);
      setGraphData(convertedPhaseData);
    }
  }, [phaseData]);

  const timestampConvertToTime = (val: any) => {
    return moment(val).utcOffset(localStorage.getItem("timezone")!).format("HH:mm:ss");
  };
  return <DualAxes {...config} height={330} tooltip={tooltip}/>;
}

export default PhasePlot;
