import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";

export interface JunctionEventDataModel {
  junctionId: number;
  dataType: string;
  data: any;
  ts: number;
}

interface JunctionEventDataProps {
  junctionEventData: JunctionEventDataModel;
}

const JunctionEventContext = createContext<JunctionEventDataProps | undefined>(
  undefined
);

export function JunctionEventProvider({ children }: PropsWithChildren<{}>) {
  const [junctionEvent, setJunctionEvent] = useState<JunctionEventDataModel>();

  useEffect(() => {
    socket.on("junctionEvent", (data) => {
      setJunctionEvent(data);
    });
    return () => {
      socket.off("junctionEvent");
    };
  }, []);

  return (
    <JunctionEventContext.Provider
      value={{
        junctionEventData: junctionEvent!,
      }}
    >
      {children}
    </JunctionEventContext.Provider>
  );
}

export function useJunctionEvent() {
  const context = useContext(JunctionEventContext);
  if (context === undefined) {
    return {
      junctionEventData: {
        junctionId: null,
        dataType: "",
        data: null,
        ts: null,
      },
    };
    // throw new Error(
    //   "useJunctionEvent must be used within a JunctionEventContext"
    // );
  }
  return context;
}
