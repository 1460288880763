import {useState, useEffect ,useRef } from 'react';
const PopupGroup = ({mousePosition,groupInfo,config,timeTrigger,isHover}) => {

    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => { 
       // console.log(groupInfo)
        if(mousePosition.x>config.OffsetX && mousePosition.x<config.ResolutionX && mousePosition.y>config.OffsetY && mousePosition.y<config.ResolutionY ){
            setIsVisible(true);
        }else{
            setIsVisible(false);
        }
    }, [mousePosition]);

    function formatToTwoDigits(num) {
      return num < 10 ? '0' + num : num.toString();
  }
    const calculateTime=(x) => {
      let time = new Date(timeTrigger.getTime());
      let dString="";   
      time.setSeconds(time.getSeconds() - (x)); 
      dString = time.getHours()+':'+formatToTwoDigits(time.getMinutes())+':'+formatToTwoDigits(time.getSeconds())
      return dString
    }


    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };

    const checkMouseInsideRect=(mousePosition)=>{
        let xMaxLimit=config?.ResolutionX-(config?.OffsetX-1);
        let xMinLimit=config.OffsetX-1;
        let x=mousePosition.x;
        if(mousePosition.x>xMaxLimit)
            x=xMaxLimit;
        if(mousePosition.x<xMinLimit)
            x=xMinLimit;
        return x;
    }
    //  GroupID:{groupInfo?.info?.groupID}
    // Second: {groupInfo?.info?.sec}

      return (
        <> 
        {isVisible&& (    
            <>
          <foreignObject x={mousePosition.x} y={mousePosition.y} width="150" height="120"> 
           
            <div  className="boxPopup"> 
              <div>
                {calculateTime(((config.ResolutionX-config.OffsetX)-mousePosition.x)/10)}
              </div>

            </div>
           
          </foreignObject>
             <line key={1+'lnoog'} strokeWidth="3px" stroke={"white"} 
                    x1={checkMouseInsideRect(mousePosition)} 
                    y1={config?.OffsetY-1} 
                    x2={checkMouseInsideRect(mousePosition)} 
                    y2={config?.ResolutionY-(config?.OffsetY-2)} 
                    id={1}>
            </line>  
          </>
        )}
        </> 
      );
  };
  
  export default PopupGroup;
  