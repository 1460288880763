import { Modal } from 'antd';
import { Icon } from 'leaflet';
import { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';

const LocationModal = ({ visible, onClose, location, iconUrl }: any) => {
  const locationKey = location.join('-');

  const AdjustMap = () => {
    const map = useMap(); 
    useEffect(() => {
      setTimeout(() => {
        map.invalidateSize();
      }, 100);
    }, []);
    return null;
  };

  const customIcon = new Icon({
    iconUrl: iconUrl,
    iconSize: [20, 32],
    iconAnchor: [10, 32],
    popupAnchor: [0, -35] 
  });

  return (
    <Modal
      title="Location"
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
    >
      {visible && (
        <MapContainer
          center={location}
          zoom={14}
          style={{ height: '400px', width: '100%' }}
          key={locationKey}
        >
          <TileLayer
            url="https://api.mapbox.com/styles/v1/mangomapboxprimary/clwrwrqpt00yc01pcdznoeb6a/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFuZ29tYXBib3hwcmltYXJ5IiwiYSI6ImNsd3J2eTF6MjA1ZDQya3E4ZXRwbTZ1MmEifQ.RTj3dfki967U8_cNPEe0MQ"
          />
          {iconUrl && <Marker position={location} icon={customIcon}/>}
          <AdjustMap />
        </MapContainer>
      )}
    </Modal>
  );
};

export default LocationModal;
