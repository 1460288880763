import { message } from "antd";
import "./App.less";
import './index.css';
import AppRoutes from "./routes/AppRoutes";
import 'moment/locale/tr'

function App() {
  
  message.config({
    duration: 1.5 // because of long time on the screen, global duration added. 
  });

  return (
    <div style={{ width: "100%", height: "100%" }}>
        <AppRoutes />
    </div>
  );
}

export default App;
