import http from "../../../data/http.common";
import { JunctionOption } from "../components";

//For FCD Data Config Edit Data
export interface Cluster {
    clusterName: string;
    clusterId:number;
    junctionList:JunctionOption[];
}

export const fcdEdit = async (formOutput: Cluster) => {
  return http.post("/web/junction/cluster/addConfigurationFile", {
    ...formOutput
  });
};