import { useEffect, useMemo, useState } from "react";
import { LeafletMouseEvent } from "leaflet";
import { Marker, Tooltip, useMap } from "react-leaflet";
import withLogging from "../../high-order-components/LoggerHOC";
import GenericMapModal from "../../components/generic/map-modal/GenericMapModal";
import L from "leaflet";
import { Ups } from "../../../../data/ups/Ups";
import UpsModalTitle from "./UpsModalTitle";
import UpsMain from "../../../ups";
import { useUpsData } from "../../../../contexts/ups/UpsDataContext";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import { t } from "i18next";
import GenericMarkerDeleteModal from "../../components/generic/marker-context-modal/GenericMarkerDeleteModal";
import MarkerGenericAddUpdateContent from "../../components/generic/marker-context-modal/MarkerGenericAddUpdateContent";
import { UpsModel } from "../../components/generic/marker-context-modal/model/Ups";
import UpsService from "../../../../data/ups/UpsService";
import { MapModule } from "@icms/ui-components"
import { upsIconBase64 } from "../../map-constants/static/MarkerIconBase64";

const activeMarker = L.icon({
  iconUrl: `/static/assets/map-markers/ups.png`,
  iconSize: [42,42],
  iconAnchor: [15.5, 42],
  popupAnchor: [0, -45],
});
const passiveMarker = L.icon({
  iconUrl: `/static/assets/map-markers/ups-p.png`,
  iconSize: [42,42],
  iconAnchor: [15.5, 42],
  popupAnchor: [0, -45],
});

const bypassMarker = L.icon({
  iconUrl: `/static/assets/map-markers/ups-bypass.png`,
  iconSize: [37.5, 37.5],
  iconAnchor: [15.5, 42],
  popupAnchor: [0, -45],
});

function UpsLayer() {
  const { upsList, updateUpsList } = useUpsData();
  const { setLayerData } = useCustomLayerData();
  const [markerData, setMarkerData] = useState<Ups[]>([]);
  const [selectedUps, setSelectedUps] = useState<Ups>();
  const [ups, setUps] = useState<Ups>();

  const map = useMap();

  const markerHandlers = (ups: Ups) => {
    return {
      dblclick: (event: LeafletMouseEvent) => handleMarkerDblClick(event, ups),
      contextmenu: (event: LeafletMouseEvent) => {
        map.contextmenu.removeAllItems();
        map.contextmenu.addItem({
          text: t("update"),
          callback: () => updateUps(ups),
        });
        map.contextmenu.addItem({
          text: t("delete"),
          callback: () => deleteUps(ups),
        });
      },
    };
  };

  const updateUps = (ups: Ups) => {
    setUps(ups);
  };

  const deleteUps = async (ups: Ups) => {
    GenericMarkerDeleteModal(ups.name, async () => {
      await UpsService.deleteUps(ups.id);
      updateUpsList();
    });
  };

  const handleMarkerDblClick = (event: LeafletMouseEvent, ups: Ups) => {
    setSelectedUps(ups);
  };

  const onCloseModal = () => {
    setUps(undefined);
    setSelectedUps(undefined);
  };

  useEffect(() => {
    setLayerData({
      id: MapModule.UpsModule,
      data: upsList,
      searchable: {
        category: "ups",
        filterProperties: ["id", "name"],
        labelProperty: "name",
      },
      legend: {
        countProperty: "status",
        setOnMapData: setMarkerData,
      },
    });
    setMarkerData(upsList);
  }, [upsList]);

  const modalContent = useMemo(() => {
    return (
      <>
        {selectedUps && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <UpsModalTitle
                componentName="UpsModalTitle"
                selectedUps={selectedUps}
              />
            }
          >
            <UpsMain selectedUps={selectedUps} />
          </GenericMapModal>
        )}
      </>
    );
  }, [selectedUps]);

  const addUpdateModalContent = useMemo(() => {
    return (
      <>
        {ups && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <UpsModalTitle componentName="UpsModalTitle" selectedUps={ups} />
            }
          >
            <MarkerGenericAddUpdateContent
              modalType="update"
              model={UpsModel}
              setModalVisible={onCloseModal}
              icon={upsIconBase64}
              latlon={new L.LatLng(ups.latitude, ups.longitude)}
              triggerStateFunc={updateUpsList}
              formData={ups}
            />
          </GenericMapModal>
        )}
      </>
    );
  }, [ups]);

  return (
    <>
      {markerData.length > 0 &&
        markerData.map((ups) => {
          return (
            <Marker
              key={ups.id}
              position={[ups.latitude, ups.longitude]}
              icon={ups.status == 3 ? activeMarker : (ups.status == 4 ? bypassMarker : passiveMarker)}
              eventHandlers={markerHandlers(ups)}
              contextmenu={true}
            >
              <Tooltip>{ups.name}</Tooltip>
            </Marker>
          );
        })}
      {modalContent}
      {addUpdateModalContent}
    </>
  );
}

export default withLogging(UpsLayer);
