import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { JunctionDetector } from "../../data/junction-detector/JunctionDetector";
import DeviceService from "../../data/device/DeviceService";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";

export interface CameraDataContextState {
  cameraList: JunctionDetector[];
  updateAllCamera: () => void;
}

const CameraDataContext = createContext<CameraDataContextState | undefined>(
  undefined
);

export function CameraDataProvider({ children }: PropsWithChildren<{}>) {
  const [cameras, setCameras] = useState<JunctionDetector[]>([]);
  const { hasAccessComponent,componentPermissions } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  const getCameras = async () => {
    await DeviceService.getCameras()
      .then((res: any) => {
        setCameras(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCameras();
  }, [componentPermissions]);

  return hasAccessComponent("Camera Module-READ") ? (
    <CameraDataContext.Provider
      value={{
        cameraList: cameras,
        updateAllCamera: getCameras,
      }}
    >
      {children}
    </CameraDataContext.Provider>
  ) : (
    <>{children}</>
  );
}

export function useCameraData() {
  const context = useContext(CameraDataContext);
  if (context === undefined) {
    return {
      cameraList: [],
      updateAllCamera: () => {},
    };
    //throw new Error("useCameraData must be used within a CameraDataContext");
  }
  return context;
}
