import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { CustomLayerGroup } from "../../pages/map/map-constants/LayerData";
import { LeafletMouseEvent } from "leaflet";
import multipleWatchService, {
  modulteType,
} from "../../pages/map/services/MultipleWatchService";

interface MultipleWatchDataContextState {
  multipleWatchContentData: any;
  addSelectedItem: (
    event: LeafletMouseEvent,
    data: any,
    type: modulteType
  ) => void;
  removeSelectedItem: (event: LeafletMouseEvent, data: any) => void;
  clearSelectedItems: () => void;
  setWatchStatus: () => void;
}

const MultipleWatchDataContext = createContext<
  MultipleWatchDataContextState | undefined
>(undefined);

export function MultipleWatchDataProvider({ children }: PropsWithChildren<{}>) {
  const [watchData, setWatchData] = useState<any>([]);

  const addSelectedItem = (
    event: LeafletMouseEvent,
    data: any,
    type: modulteType
  ) => {
    multipleWatchService.addSelectedItem(data, event.target, type);
    setWatchData(multipleWatchService.getAllItems());
  };

  const removeSelectedItem = (event: LeafletMouseEvent, data: any) => {
    multipleWatchService.removeSelectedItem(data, event.target);
    setWatchData(multipleWatchService.getAllItems());
  };

  const clearSelectedItems = () => {
    multipleWatchService.clearSelectedItems();
    setWatchData(multipleWatchService.getAllItems());
  };

  const setWatchStatus = () => {
    multipleWatchService.setWatchStatus();
  };

  return (
    <MultipleWatchDataContext.Provider
      value={{
        multipleWatchContentData: watchData,
        addSelectedItem: addSelectedItem,
        removeSelectedItem: removeSelectedItem,
        clearSelectedItems: clearSelectedItems,
        setWatchStatus: setWatchStatus,
      }}
    >
      {children}
    </MultipleWatchDataContext.Provider>
  );
}

export function useMultipleWatchData() {
  const context = useContext(MultipleWatchDataContext);
  if (context === undefined) {
    return {
      multipleWatchContentData: undefined,
      addSelectedItem: (
        event: LeafletMouseEvent,
        data: any,
        type: modulteType
      ) => {},
      removeSelectedItem: (event: LeafletMouseEvent, data: any) => {},
      clearSelectedItems: () => {},
      setWatchStatus: () => {},
    };
    // throw new Error(
    //   "useMultipleWatchData must be used within a MultipleWatchDataContext"
    // );
  }
  return context;
}
