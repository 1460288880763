import { Line } from "@ant-design/plots";
import { useTranslation } from "react-i18next";
interface Props {
  data: travelTimeData[];
  type: string;
}
interface travelTimeData {
  ts: number;
  data: number;
}

const TravelTimeChart = (props: Props) => {
  const { t } = useTranslation();
  const data = props.data;

  const config: any = {
    data,
    xField: "ts",
    yField: "data",
    meta: {
      ts: {
        alias: `${t("time")}`,
      },
      data: {
        alias: props.type == "travelTime" ? t("travel_time") : t("mac_count"),
      },
    },
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };
  return <Line {...config} />;
};

export default TravelTimeChart;
