import { JunctionDetector } from "../../../../data/junction-detector/JunctionDetector";
import { GenericMapModalTitle } from "../../components/generic/map-modal/GenericMapModalTitle";
import withLogging from "../../high-order-components/LoggerHOC";

interface CameraModalTitleProps {
  selectedCamera: JunctionDetector;
}

function CameraModalTitle(props: CameraModalTitleProps) {
  const { selectedCamera } = props;

  return (
    <GenericMapModalTitle color="#5271FF">
      <span style={{ fontSize: "2vw" }}>{selectedCamera.name}</span>
    </GenericMapModalTitle>
  );
}

export default withLogging(CameraModalTitle);
