import {colors,svgRectangle} from './objects.js'
class svgEnginePrs{
    dataTriggerPhase=(dat,now,config,setConfig)=>{
        let temp=this.removeFeature(dat,now,config);
        let PHASEArray=JSON.parse(JSON.stringify(temp))
        let responseArray=[];
        for (let index = 0; index < PHASEArray.length; index++) {
            let e=PHASEArray[index]
            let milisec=0;
            if(e["serverts"]==undefined ||e["serverts"]==null){
                //milisec=now.getTime()-(e.ts*1000)
                continue
            }else{
                milisec=now.getTime()-(e.serverts)
            }
            let sec=Math.round(milisec/1000)
            e["secStart"]=sec;
            //e["x"]=((config?.TimeRangeSecond-(sec))*10);
            e["x"]=((config?.TimeRangeSecond*1000-(milisec))/100);
            //e["x"]=config?.ResolutionX-(milisec/100);
            //debugger
            if(sec<config?.TimeRangeSecond){
                responseArray.push(e);
            }else{
                break;
            }
        }
        return responseArray
    }
    findEndTime=(dat,now,config)=>{
        let SSMArray=JSON.parse(JSON.stringify(dat))
        for (let index = 0; index < SSMArray.length; index++) {
            //En sonuncuya geldiginde simdiki zamani etiketle
            if(index==0){
                SSMArray[index]["tsEnd"]=now.getTime()        
            }else{
                SSMArray[index]["tsEnd"]=SSMArray[index-1].ts
            } 
        }
        return SSMArray
    }

    removeFeature=(dat,now,config)=>{
        let SSMArray=JSON.parse(JSON.stringify(dat))
        for (let index = 0; index < SSMArray.length; index++) {
            let milisec=now.getTime()-SSMArray[index].ts

            //remove if feature value
            if(milisec<0)
                SSMArray.splice(index, 1);    
        }
        return SSMArray
    }

    calculateSecond=(dat,now,config)=>{
        let SSMArray=JSON.parse(JSON.stringify(dat))
        for (let index = 0; index < SSMArray.length; index++) {
            let e=SSMArray[index]

            let milisecStart=now.getTime()-e.ts
            let sec=Math.round(milisecStart/1000)
            e["secStart"]=sec;
            e["milisecStart"]=milisecStart;

            let milisecEnd=now.getTime()-e.tsEnd
            sec=Math.round(milisecEnd/1000)
            e["secEnd"]=sec;
            e["milisecEnd"]=milisecEnd;
        }
        return SSMArray
    }

    mergeSamaColor=(dat,now,config)=>{
        let rects=[]
        let SSMArray=JSON.parse(JSON.stringify(dat))
        let i=0;

        for (let groupid = 0; groupid < SSMArray[0]?.data?.length; groupid++) {
          
            let refData=null;
            //yeni datadan eski dataya dogru git
            for (let index = 0; index < SSMArray.length; index++) {
                
                let e=SSMArray[index];

                if(refData!=null 
                    && (refData.data[groupid]!=e.data[groupid]
                    ||index==SSMArray.length-1
                    )){

                    let height=config.GroupHeight;
                    let y=0;
                    let width=0;
                    let x=0;
                    let sec=0;

                    if(index==SSMArray.length-1){
                        width=(refData.tsEnd-e.tsEnd)/100;
                        //x=((config?.TimeRangeSecond-(e.secEnd))*10);
                        x=((config?.TimeRangeSecond*1000-(e.milisecEnd))/100);
                    }else{
                        width=(refData.tsEnd-e.tsEnd)/100;        
                        //x=((config?.TimeRangeSecond-(e.secEnd))*10);
                        x=((config?.TimeRangeSecond*1000-(e.milisecEnd))/100);


                    }

                    let color=colors[refData.data[groupid]-1]



                    if(x+width>(config?.TimeRangeSecond-1)*10){
                        width=width-(x+width-(config?.TimeRangeSecond*10))
                    }


                    if(x<0 && width<Math.abs(x)){
                        continue
                    }
                        
                    if(x<0){
                        width=width-Math.abs(x);
                        x=0;
                    }
                    if(width<0){
                        continue
                    }

              

                    let info={
                        sref:e,
                        groupID:groupid,
                        typeID:e.data[groupid]-1,
                        sec:width/10,
                    }
                    rects.push(new svgRectangle(x,y+((config.GroupHeight+config.GroupPad)*groupid),width,height,color+'',info))
                    refData=e;
                }

                if(refData==null){
                    refData=e;
                }
            }  

        }
        return rects
    }

    timeTriggerSSM=(SSMArray,now,config,setConfig)=>{
        if(config==null||now==null||SSMArray==null || SSMArray.length==0){
            //console.log("birsey nulldon")
            return
        }
        
        if(SSMArray[0]?.data!=undefined&&config?.GroupLength!=SSMArray[0].data.length ){
            setConfig(prevState => {
              let cnf = Object.assign({}, prevState);  
              cnf.GroupLength = SSMArray[0]?.data?.length;    
              cnf.ResolutionY= cnf.GroupLength*(config.GroupHeight+config.GroupPad)+25+24;                     
              return  cnf;                          
            })
        
        }
        //console.log("Raw")
        //console.log(JSON.parse(JSON.stringify(SSMArray)))

        SSMArray=this.removeFeature(SSMArray,now,config);
        //console.log("removeFeature")
        //console.log(JSON.parse(JSON.stringify(SSMArray)))

        SSMArray=this.findEndTime(SSMArray,now,config);
       // console.log("findEndTime")
        //console.log(JSON.parse(JSON.stringify(SSMArray)))

        SSMArray=this.calculateSecond(SSMArray,now,config);
        //console.log("calculateSecond")
        //console.log(JSON.parse(JSON.stringify(SSMArray)))

        SSMArray=this.mergeSamaColor(SSMArray,now,config);
        //console.log("mergeSamaColor")
        //console.log(JSON.parse(JSON.stringify(SSMArray)))

        return SSMArray;
    }

}

const svgEngine = new svgEnginePrs();
export default svgEngine;