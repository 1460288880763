import { AutoComplete, Input } from "antd";
import { useTranslation } from "react-i18next";
import SearchBarStyl from "../../components/custom-styled/input/SearchBar";
import { useEffect, useState } from "react";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import { useMap } from "react-leaflet";

function SearchControl() {
  const { t } = useTranslation();
  const { layerDataList } = useCustomLayerData();
  const map = useMap();
  const [options, setOptions] = useState<any[]>([]);

  const renderTitle = (category: string) => (
    <span key={category}>{t(category)}</span>
  );

  const renderItem = (
    value: string | number,
    label: string,
    category: string
  ) => ({
    key: label + "-" + value,
    value: typeof value === "number" ? value.toString() : value,
    category: category,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {label}
      </div>
    ),
  });

  const handleSearch = (value: string) => {
    const lowercasedValue = value.toLowerCase();
    const filteredOptions = layerDataList.map((item) => {
      const element = item.searchable;
      if (element) {
        return {
          label: renderTitle(element.category),
          options: item.data
            .filter((item: any) => {
              return element.filterProperties.some((filterProp) => {
                const propValue =
                  typeof item[filterProp] === "number"
                    ? item[filterProp].toString()
                    : item[filterProp];

                return propValue.toLowerCase().includes(lowercasedValue);
              });
            })
            .map((item: any, idx: number) => {
              return renderItem(
                item.id,
                item[element.labelProperty],
                element.category
              );
            }),
        };
      }
    });
    setOptions(filteredOptions);
  };

  const handleSearchSelect = (value: string, option: any) => {
    let filteredOptions = layerDataList.find(
      (item) => item.searchable?.category === option.category
    );
    let filteredData = filteredOptions?.data?.find(
      (item: any) => item?.id == value
    );
    if (filteredData?.latitude && filteredData?.longitude) {
      map.flyTo(
        { lat: filteredData.latitude, lng: filteredData.longitude },
        17
      );
    } else if (filteredData?.lat && filteredData?.lng) {
      map.flyTo({ lat: filteredData.lat, lng: filteredData.lng }, 17);
    }
  };

  useEffect(() => {
    let options = [];
    for (let index = 0; index < layerDataList.length; index++) {
      const element = layerDataList[index].searchable;
      if (element) {
        options.push({
          label: renderTitle(element.category),
          options: layerDataList[index].data.map((item: any, idx: number) =>
            renderItem(item.id, item[element.labelProperty], element.category)
          ),
        });
      }
    }
    setOptions(options);
  }, [layerDataList]);

  return (
    <AutoComplete
      dropdownMatchSelectWidth={252}
      style={{ width: 300, top: 0, left: 15 }}
      options={options}
      filterOption={false}
      onSelect={handleSearchSelect}
      onSearch={handleSearch}
      listHeight={550}
    >
      <SearchBarStyl>
        <Input
          placeholder={t("search")}
          aria-placeholder="white"
          className="dark"
          style={{
            backgroundColor: "rgb(47, 64, 80)",
            borderColor: "rgb(47, 64, 80)",
            boxShadow: " rgb(47 64 80 / 70%) 0px 0px 5px",
            color: "white",
            height: 33,
          }}
          allowClear
        />
      </SearchBarStyl>
    </AutoComplete>
  );
}

export default SearchControl;
