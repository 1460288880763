import { useRef } from "react";
import { Column } from "@ant-design/plots";
import { Col, Dropdown, Menu, Row, Tooltip } from "antd";
import {
  DownloadOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import * as htmlToImage from "html-to-image";
import { ExportToXLSX } from "../../../../../../../utils/ExportToXLSX";
import jsPDF from "jspdf";

function PlotsForDaily({ datas }: any) {
  const { t } = useTranslation();
  const ref = useRef<any>([]);
  const graphArea = useRef<HTMLDivElement>(null);

  let dynamicConfig: any = [];
  let dynamicDownloadLabel: any[] = [];
  let dynamicLegend: any = [];
  let allDirectionName = `${t(
    "total_number_of_vehicles"
  )} ( ${datas.data.reduce((accumulator: any, object: any) => {
    return accumulator + object.vehicleCount;
  }, 0)} - ${datas.startDate} )`;

  let maxValueOfEachArray = datas.dynamicConfigData.map((item:any,index:number) => {
    return Math.max(...item.map((o:any) => o.vehicleCount))
  });  
  let tickVehicleCountMaxValue = Math.max(...maxValueOfEachArray.map((o:any) => o))
  
  for (let index = 0; index < datas.dynamicConfigData.length; index++) {
    let item = {
      data: datas.dynamicConfigData[index],
      xField: "time",
      yField: "vehicleCount",
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      yAxis: {
        title: {
          text: t("total_number_of_vehicles"),
        },
        max:tickVehicleCountMaxValue
      },
      seriesField: "name",
      color: datas.signalPlanColor,
    };
    let legend = {
      position: "top",
      title: {
        text: `${datas.dynamicConfigData[index][0].name.substring(
          0,
          datas.dynamicConfigData[index][0].name.lastIndexOf(" ")
        )} ( ${datas.dynamicConfigData[index].reduce(
          (accumulator: any, object: any) => {
            return accumulator + object.vehicleCount;
          },
          0
        )} - ${datas.startDate} )`,
        style: {
          fontSize: 16,
          fontWeight: 600,
        },        
      },
      //remove legend item
      itemName: {
        formatter: (seriesField: any) => {
          return "";
        },
      },
      marker: {
        symbol: () => null,
      },
      flipPage: false
    };
    dynamicDownloadLabel.push({
      label: datas.dynamicConfigData[index][0].name.substring(
        0,
        datas.dynamicConfigData[index][0].name.lastIndexOf(" ")
      ),
      key: `${index + 2}`,
    });
    dynamicConfig.push(item);
    dynamicLegend.push(legend);
  }

  const legendAll: any = {
    position: "top",
    title: {
      text: allDirectionName,
      style: {
        fontSize: 20,
        fontWeight: 600,
      },
    },
    //remove legend item
    itemName: {
      formatter: (seriesField: any) => {
        return "";
      },
    },
    marker: {
      symbol: () => null,
    },
    flipPage: false
  };

  const config = {
    data: datas.data,
    xField: "time",
    yField: "vehicleCount",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      title: {
        text: t("total_number_of_vehicles"),
      },
    },
    seriesField: "name",
    color: datas.signalPlanColor,
  };

  const downloadImage = async (e: any) => {
    if (e.key === "0") {
      const dataUrl = await htmlToImage.toPng(graphArea.current!);

      // download image
      const link = document.createElement("a");
      link.download = `${t("all")}.png`;
      link.href = dataUrl;
      link.click();
    } else if(e.key === "-1") {
      for (let index = 1; index < ref.current.length; index++) {
        ref.current[index]?.downloadImage(ref.current[index]?.options.legend.title.text);
      }
    } else {
      ref.current[e.key]?.downloadImage(e.domEvent.target.textContent);
    }
  };

  const downloadExcel = async (e: any) => {
    if (e.key == "1") {
      let result = renderXLSX(config);
      ExportToXLSX(result, e.domEvent.target.textContent);
    } else if (e.key == "-1") {
      for (let index = -1; index < dynamicConfig.length; index++) {
        if (index == -1) {
          let result = renderXLSX(config);
          ExportToXLSX(result, allDirectionName);
        }else{
          let result = renderXLSX(dynamicConfig[index],"Vehicle Count");
          ExportToXLSX(result,dynamicDownloadLabel[index].label);
        }
      }
    }  else {
      let result = renderXLSX(dynamicConfig[+e.key - 2],"Vehicle Count");
      ExportToXLSX(result, e.domEvent.target.textContent);
    }
  };

  const renderXLSX = (array: any,text?:string) => {
    let result = array.data.map((item: any) => {
      let tempJSON: any = {};
      if (text) {
        tempJSON[text] = item.vehicleCount;
      }else {
        let lastIndexOfSpace = item.name.lastIndexOf(" ");
        tempJSON[`${item.name.slice(0, lastIndexOfSpace)}`] = item.vehicleCount;
      }
      tempJSON.time = item.time;
      return tempJSON;
    });
    return result;
  }

  const downloadPdf = async (e: any) => {
    const doc = new jsPDF({
      hotfixes: ["px_scaling"],
    });

    if (e.key === "0") {
      // doc.text(t("all")!, 100, 15);
      for (let index = 1; index < ref.current.length; index++) {
        let dataUrl = await htmlToImage.toPng(
          ref.current[`${index}`].container
        );

        doc.addImage(dataUrl, "JPEG", 15, 40, 180, 110);
        if (ref.current.length - 1 != index) {
          doc.addPage();
        }
      }
      await doc.save(t("all"));
    } else if(e.key === "-1") {
      for (let index = 1; index < ref.current.length; index++) {
        renderPdf(ref.current[index]?.options.legend.title.text,index);
      }
    } else {
      renderPdf(e.domEvent.target.textContent,e.key);
    }
  };

  const renderPdf = async(text:string, key:number) => {
    let doc = new jsPDF({
      hotfixes: ["px_scaling"],
    });
    // var splitTitle = doc.splitTextToSize(text, 180);
    // doc.text(splitTitle, 15, 20);
    let dataUrl = await htmlToImage.toPng(ref?.current[key]?.container);

    doc.addImage(dataUrl, "JPEG", 15, 40, 180, 110);

    await doc.save(text);
  }

  const menuItems = [
    {
      label: t("all_separately"),
      key:"-1",
    },
    {
      label: allDirectionName,
      key: "1",
    },
    ...dynamicDownloadLabel,
  ];

  const downloadImageAndPdfMenuItems = [
    {
      label: t("all_separately"),
      key:"-1",
    },
    {
      label: t("all"),
      key: "0",
    },
    {
      label: allDirectionName,
      key: "1",
    },
    ...dynamicDownloadLabel,
  ];

  const downloadPdfMenu = (
    <Menu
      onClick={(e) => downloadPdf(e)}
      items={downloadImageAndPdfMenuItems}
    />
  );

  const downloadImageMenu = (
    <Menu
      onClick={(e) => downloadImage(e)}
      items={downloadImageAndPdfMenuItems}
    />
  );
  const downloadExcelMenu = (
    <Menu onClick={(e) => downloadExcel(e)} items={menuItems} />
  );

  return (
    <>
      <Row style={{ marginTop: "10px" }} justify="end">
        <Col>
          <Tooltip placement="top" title={t("download_chart_image")}>
            <Dropdown.Button
              trigger={["click"]}
              overlay={downloadImageMenu}
              placement="bottom"
              type="primary"
              icon={<DownloadOutlined />}
            ></Dropdown.Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement="top" title={t("download_excel_data")}>
            <Dropdown.Button
              trigger={["click"]}
              overlay={downloadExcelMenu}
              placement="bottom"
              type="primary"
              icon={<FileExcelOutlined />}
            ></Dropdown.Button>
          </Tooltip>
        </Col>
        <Col>
          <Tooltip placement="top" title={t("download_pdf_data")}>
            <Dropdown.Button
              trigger={["click"]}
              overlay={downloadPdfMenu}
              placement="bottom"
              type="primary"
              icon={<FilePdfOutlined />}
            ></Dropdown.Button>
          </Tooltip>
        </Col>
      </Row>
      <div ref={graphArea}>
        <Row>
          <Col span={24}>
            <Column
              legend={legendAll}
              {...config}
              key={"plotsForDailyAll"}
              onReady={(plot) => {
                ref.current[1] = plot;
              }}
            />
          </Col>
        </Row>
        <Row>
          {dynamicConfig.map((config: any, index: number) => (
            <Col
              key={index * 3}
              span={11}
              style={{ marginTop: "25px", marginLeft: "25px" }}
            >
              <Column
                key={index * 4}
                legend={dynamicLegend[index]}
                {...config}
                onReady={(plot) => {
                  ref.current[index + 2] = plot;
                }}
              />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default React.memo(PlotsForDaily);
