import React, { useState, useEffect, useCallback } from "react";
import { Button, Menu, Dropdown, Popconfirm, message, Card, Checkbox, Tooltip, Select, Modal, Steps, Tag } from "antd";

import { DeleteOutlined, LeftOutlined, RightOutlined, SendOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { Vms } from "../../../../data/vms/Vms";
import VmsService from "../../../../data/vms/VmsService";
import MultiModeVmsEditor from "./MultiModeVmsEditor";
import { useVmsData } from "../../../../contexts/vms/VmsDataContext";

interface MultiModeVmsEditorProps {
  selectedVms: Vms | null;
  sendedDrawings: any | null;
  setSendedDrawings: React.Dispatch<React.SetStateAction<any | null>>;
}

const { Option } = Select;
const { Step } = Steps;


const MultiModeVmsEditorIndex: React.FC<MultiModeVmsEditorProps> = ({ selectedVms, sendedDrawings, setSendedDrawings }) => {
  const [selectedVmsDrawingData, setSelectedVmsDrawingData] = useState<any | null>(null);
  const [drawingDataBySelectedDrawing, setDrawingDataBySelectedDrawing] = useState<any | null>(null);
  const [selectedDrawingName, setSelectedDrawingName] = useState<string | null>(null);
  const [drawings, setDrawings] = useState<string[]>([]);
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [key, setKey] = useState<number>(0);
  const [crudMode, setCrudMode] = useState<string>("");
  const [operation, setOperation] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedItemsGroups, setSelectedItemsGroups] = useState<string[]>([]);

  const [currentPage, setCurrentPage] = useState<string>("editor");
  const [vmsGroups, setVmsGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<any>([]);
  const [processing, setProcessing] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [allSelectedGroups, setAllSelectedGroups] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState<any>(null);


  const { vmsList } = useVmsData();

  const goBack = () => {
    setCurrentPage("editor");
  };

  const goForward = () => {
    setCurrentPage("send_drawing");
  };

  const handleNewDrawing = () => {
    setSelectedDrawingName(null);
    setDrawingDataBySelectedDrawing(null);
    setCrudMode("add");
    setShowEditor(true);
    setKey((prevKey) => prevKey + 1);
  };

  const handleDrawingSelect = (drawing: string) => {
    setSelectedDrawingName(drawing);
    setDrawingDataBySelectedDrawing(selectedVmsDrawingData[drawing]);
    setCrudMode("edit");
    setShowEditor(true);
    setKey((prevKey) => prevKey + 1);
  };

  const handleEditorClose = () => {
    setShowEditor(false);
  };

  const getVmsDrawingFiles = useCallback(async (vms_group_id:any) => {
    await VmsService.getVmsDrawingFilesMultiMode(vms_group_id)
      .then((response) => {
        setSelectedVmsDrawingData(response.data.drawing_data);
        setDrawings(Object.keys(response.data.drawing_data ? response.data.drawing_data : [] ));
      })
      .catch((err) => console.log(err));
  }, [selectedGroupId]);

  const getVmsDrawingFilesByPlansMultiMode = useCallback(async (vms_group_id: any) => {
    await VmsService.getVmsDrawingFilesByPlansMultiMode(vms_group_id)
      .then((response) => {
        const dataArray = response.data ? response.data : [];
        const drawingsArray = dataArray.map((x: any) => x.drawing_data);
  
        const filteredDrawingsArray = drawings.length > 0
          ? drawingsArray.filter((drawing: string) => drawings.includes(drawing))
          : [];
  
        setSelectedItems(filteredDrawingsArray);
      })
      .catch((err) => console.log(err));
  }, [drawings]);
  

  useEffect(()=>{
    getVmsDrawingFilesByPlansMultiMode(selectedGroupId)
  },[selectedGroupId,drawings]);

  const handleDeleteDrawing = (drawing: string) => {
    if (selectedVms) {
      removeDrawing(selectedVms, drawing, null);
    } else {
      removeDrawing(null, drawing, selectedGroupId);
    }

  };

  const removeDrawing = useCallback((selectedVms: Vms | null, drawingName: string, vmsGroupId:any) => {
    VmsService.deleteDrawing(null, drawingName, vmsGroupId)
      .then((response) => {
        setOperation("delete" + Date.now());
        setSelectedDrawingName(null);
        setShowEditor(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const prepareVmsDrawingFile = async (vmsId: any, drawingData: any) => {
    try {
      const vms = vmsList.find(vms => vms.id === vmsId);
      const response = await VmsService.prepareVmsDrawingFile(
        vms!.id,
        drawingData,
        vms!.ip_address,
      );
      if ([200, 201].includes(response.status) || response.data.status === 200) {
        return { vmsId, status: 'success' };
      }
    } catch (error) {
      console.error(error);
      return { vmsId, status: 'error' };
    }
  };

  const handleUploadToDevice = async () => {
    if (!selectedGroupId || selectedItems.length === 0) {
      message.warning('Lütfen bir VMS grubu ve en az 1 adet çizim dosyası seçin!');
      return;
    }
  
    if (!selectedGroup) {
      message.error('Seçilen grup bulunamadı.');
      return;
    }
  
    const filteredVmsDrawingData: any = {};
    selectedItems.forEach(item => {
      if (selectedVmsDrawingData[item]) {
        filteredVmsDrawingData[item] = selectedVmsDrawingData[item];
      }
    });
  
    setSendedDrawings(Object.keys(filteredVmsDrawingData));
  
    if (selectedItemsGroups.length > 0) {
      setModalVisible(true);
      setModalContent([]);
      setProcessing(true);
  
      for (const vmsId of selectedItemsGroups) {
        setModalContent((prev: any) => [...prev, { vmsId, status: 'started' }]);
        const result: any = await prepareVmsDrawingFile(vmsId, filteredVmsDrawingData);
        setModalContent((prev: any) =>
          prev.map((item: any) => (item.vmsId === result.vmsId ? result : item))
        );
      }
  
    } else {
      message.warning("Lütfen seçili VMS grubundan en az bir adet VMS Seçin");
    }
    setProcessing(false);
  };
  

  useEffect(()=>{
    setSelectedGroup(vmsGroups.find((group: any) => group.id === selectedGroupId));
  },[selectedGroupId]);

  useEffect(() => {
    getVmsDrawingFiles(selectedGroupId);
  }, [operation, selectedGroupId]);

  useEffect(() => {
    if (selectedDrawingName != null && selectedVmsDrawingData) {
      setDrawingDataBySelectedDrawing(selectedVmsDrawingData[selectedDrawingName]);
    } else {
      setDrawingDataBySelectedDrawing(null);
    }
  }, [selectedDrawingName, selectedVmsDrawingData]);
  

  useEffect(() => {
    handleNewDrawing();
  }, []);

  const handleCheckboxChange = (checkedValues: any[]) => {
    setSelectedItems(checkedValues);
    setAllSelected(checkedValues.length === drawings.length);
  };

  const handleCheckboxChangeGroups = (checkedValues: any[]) => {
    setSelectedItemsGroups(checkedValues);
    setAllSelectedGroups(checkedValues.length == selectedGroup.groups.length);
  };


  const toggleAll = () => {
    if (allSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(drawings);
    }
    setAllSelected(!allSelected);
  };

  const toggleAllGroups = () => {
    if (allSelectedGroups) {
      setSelectedItemsGroups([]);
    } else {
      setSelectedItemsGroups(selectedGroup.groups);
    }
    setAllSelectedGroups(!allSelectedGroups);
  };

  const renderMenuItems = () => {
    return (
        <Menu
            style={{
                maxHeight: '325px',
                overflowY: 'auto',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            }}
        >
            {drawings.map((drawing) => (
                <Menu.Item
                    key={drawing}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '8px 12px',
                        borderBottom: '1px solid #e8e8e8',
                        fontSize: '14px',
                    }}
                >
                    <span
                        onClick={() => handleDrawingSelect(drawing)}
                        style={{
                            cursor: 'pointer',
                            color: '#333',
                            transition: 'color 0.3s ease',
                        }}
                        onMouseEnter={(e:any) => e.target.style.color = '#1890ff'}
                        onMouseLeave={(e:any) => e.target.style.color = '#333'}
                    >
                        {drawing}
                    </span>
                    <Popconfirm
                        title={`${drawing} ${t("do_you_confirm?")}`}
                        onConfirm={() => handleDeleteDrawing(drawing)}
                        okText={t("yes")}
                        cancelText={t("no")}
                    >
                        <span
                            style={{
                                cursor: 'pointer',
                                color: 'red',
                                transition: 'color 0.3s ease',
                            }}
                            onMouseEnter={(e:any) => e.target.style.color = '#ff4d4f'}
                            onMouseLeave={(e:any) => e.target.style.color = 'red'}
                        >
                            <DeleteOutlined style={{ marginLeft: 8 }} />
                        </span>
                    </Popconfirm>
                </Menu.Item>
            ))}
        </Menu>
    );
};

  useEffect(() => {
    if (showEditor) {
      setKey((prevKey) => prevKey + 1);
    }
  }, [showEditor]);


  const getVmsGroups = async () => {
    await VmsService.getVmsGroup()
      .then((response) => {
        setVmsGroups(response.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getVmsGroups();
  }, []);

  const handleSelectAll = () => {
    if (selectedItems.length === drawings.length) {
      handleCheckboxChange([]);
    } else {
      handleCheckboxChange(drawings);
    }
  };

  const handleSelectAllGroups = () => {
    if (selectedItemsGroups.length === selectedGroup.groups.length) {
      handleCheckboxChangeGroups([]);
    } else {
      handleCheckboxChangeGroups(selectedGroup.groups);
    }
  };

  const tagRender = (props:any) => {
    const { label, value, closable, onClose } = props;

    return (
      <Tag
        color="geekblue"
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          backgroundColor: '#ffffff',
          color: '#2c3e50',
          border: '1px solid #2c3e50',
          borderRadius: '4px',
        }}
      >
        {label}
      </Tag>
    );
  };


  return (
    <>
      <Select
                placeholder="Vms Grubunu Seçin"
                style={{ width: 'calc(100% - 120px)', color: "black" }}
                dropdownStyle={{ backgroundColor: '#2c3e50', color: '#ffffff' }}
                onChange={(value) => {
                    setSelectedGroupId(value);
                }}
            >
                {vmsGroups.map((group: any) => (
                    <Option key={group.id} value={group.id} style={{ color: '#ffffff', backgroundColor: '#2c3e50' }}>
                        {group.vms_group_name}
                    </Option>
                ))}
            </Select>
      {(currentPage === "editor" && selectedGroupId) && (
        <div style={{ position: "relative", marginTop:"20px"}}>
          <Dropdown overlay={renderMenuItems()}>
            <Button>
              {selectedDrawingName
                ? `${t('selected_drawing')} ${selectedDrawingName}`
                : t("select_drawing")}
            </Button>
          </Dropdown>
          <Button
            type="primary"
            onClick={handleNewDrawing}
            style={{
              marginLeft: "10px", backgroundColor: '#2c3e50',
              borderColor: '#2c3e50',
              color: '#ffffff'
            }}
          >
            {t("add_new_drawing")}
          </Button>
          {showEditor && (
            <div key={key}>
              <h3>{`${t("edited_drawing")} ${selectedDrawingName || t("new_drawing")}`}</h3>
              <MultiModeVmsEditor
                onClose={handleEditorClose}
                drawingNameProp={selectedDrawingName || ""}
                drawingDataProp={drawingDataBySelectedDrawing}
                selectedVms={selectedVms}
                crudMode={crudMode}
                setOperation={setOperation}
                setSelectedDrawingName={setSelectedDrawingName}
                vmsGroupId={selectedGroupId}
                selectedGroup={selectedGroup}
              />
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <Tooltip title={t("go_forward")}>
              <Button size="large" shape="circle" icon={<RightOutlined />} onClick={goForward} />
            </Tooltip>
          </div>
        </div>
      )}

      {(currentPage === "send_drawing" && selectedGroupId) && (
        <Card>
          <div style={{ display: "block", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
            <Card style={{
              background: 'linear-gradient(to right, #bdc3c7, #2c3e50)',
              borderRadius: '10px',
              padding: '20px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '18px',
              color: '#ffffff',
              height: 'auto'
            }}>
              <span>{t('Gruplara göndermek istediğiniz çizimleri seçin.')}</span>
              <br />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                {drawings.length > 0 && <Button
                size="small"
                  onClick={handleSelectAll}
                  style={{ margin: '0 10px', color: '#ffffff', backgroundColor: '#2c3e50', border: 'none' }}
                >
                  {selectedItems.length === drawings.length ? t("Seçimleri Kaldır") : t("Tümünü Seç")}
                </Button>
                }
        <Select
          mode="multiple"
          style={{ width: 'calc(100% - 120px)', color: "#ffffff" }} // 120px, buton genişliğine göre ayarlanabilir
          placeholder={t('Çizimleri Seçin')}
          value={selectedItems}
          onChange={handleCheckboxChange}
          dropdownStyle={{ backgroundColor: '#2c3e50', color: '#ffffff' }}
          tagRender={tagRender}
        >
          {drawings.map((drawing) => (
            <Option key={drawing} value={drawing} style={{ color: '#ffffff', backgroundColor: '#2c3e50' }}>
              {drawing}
            </Option>
          ))}
        </Select>
      </div>
            </Card>
          </div>
          <div style={{ display: "block", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>

            <Card
              style={{
                background: 'linear-gradient(to right, #bdc3c7, #2c3e50)',
                borderRadius: '10px',
                padding: '20px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                color: '#ffffff',
                height: '150px'
              }}
            >
              <span>{t('Çizimleri, seçtiğiniz VMS grubundan hangi VMS`lere göndereceksiniz?')}</span><br />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Button
        size="small"
          onClick={handleSelectAllGroups}
          style={{ margin: '0 10px', color: '#ffffff', backgroundColor: '#2c3e50', border: 'none' }}
        >
          {selectedItemsGroups.length === selectedGroup.groups.length ? t("Seçimleri Kaldır") : t("Tümünü Seç")}
        </Button>
        <Select
          mode="multiple"
          style={{ width: 'calc(100% - 120px)', color: "#ffffff" }} 
          placeholder={t('Vmsleri Seçin')}
          value={selectedItemsGroups}
          onChange={handleCheckboxChangeGroups}
          dropdownStyle={{ backgroundColor: '#2c3e50', color: '#ffffff' }}
          tagRender={tagRender}
        >
          {selectedGroup.groups.map((group:any) => (
            <Option key={group} value={group} style={{ color: '#ffffff', backgroundColor: '#2c3e50' }}>
              {vmsList.find(vms => vms.id === group)?.name}
            </Option>
          ))}
        </Select>
      </div>
              <Popconfirm
                title="Do you confirm?"
                onConfirm={handleUploadToDevice}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size="large"
                  style={{
                    marginTop:"10px",
                    backgroundColor: '#2c3e50',
                    borderColor: '#2c3e50',
                    color: '#ffffff'
                  }}
                >
                  Gönder
                </Button>
              </Popconfirm>
            </Card>
            <Modal
              title="Vms Toplu Yönetimi - Çizim Gönderme"
              visible={modalVisible}
              onCancel={() => setModalVisible(false)}
              footer={null}
            >
              <Steps direction="vertical">
                {modalContent.map((item: any, index: any) => {
                  const vms = vmsList.find(vms => vms.id === item.vmsId);

                  return (
                    <Step
                      key={index}
                      title={vms?.name}
                      description={
                        item.status === 'started'
                          ? `${vms?.name} - Çizim gönderme başlatıldı`
                          : item.status === 'success'
                            ? `${vms?.name} - Çizim gönderme bitti`
                            : `${vms?.name} - Çizim gönderilirken hata oluştu`
                      }
                      status={item.status === 'started' ? 'process' : item.status === 'success' ? 'finish' : 'error'}
                    />
                  );
                })}
              </Steps>
              {processing && <div>İşlem devam ediyor...</div>}
            </Modal>
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <Tooltip title={t("go_back")}>
              <Button size="large" shape="circle" icon={<LeftOutlined />} style={{ marginRight: "10px" }} onClick={goBack} />
            </Tooltip>
          </div>
        </Card>
      )}
    </>
  );
};

export default MultiModeVmsEditorIndex;
