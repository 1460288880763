import React, { useEffect, useState } from "react";
import { JunctionDetector } from "../../../data/junction-detector/JunctionDetector";
import DeviceService from "../../../data/device/DeviceService";
import { Spin } from "antd";
import { useWebsocket } from "../../../contexts/websocket/WebSocketContext";
import { useInstantParkingData } from "../../../contexts/viero/ParkingDataContext";

interface Props {
  selectedVieroDevice: JunctionDetector;
}
interface PointStatus {
  id: number;
  status: number;
};

const ParkingStream: React.FC<Props> = ({ selectedVieroDevice }) => {
  const { instantParkingData }: any = useInstantParkingData();
  const { SetCamera, FreeCamera } = useWebsocket();

  const [configResult, setConfigResult] = useState<any>();
  const [parkingFilePath, setParkingFilePath] = useState<any>();
  const [pointStatus, setPointStatus] = useState<PointStatus[]>([]);

  const getConfig = async (device_id: number) => {
    try {
      const response = await DeviceService.getParkingCameraConfig(device_id);
      const configData = response.data[0];
      setConfigResult(configData);
    } catch (error) {
      console.log(error);
    }
  };

  const flattenData = (data: any) => {
    const result: any = [];
    Object.values(data).forEach((subArray: any) => {
      result.push(...subArray);
    });

    return result;
  };

  const getParkingFile = async (camera_device_id: number) => {
    try {
      const response = await DeviceService.getParkingCameraFile(camera_device_id);
      const serve_path_data = response.data[0].serve_path;
      setParkingFilePath(serve_path_data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    getParkingFile(selectedVieroDevice.id_device);
  },[selectedVieroDevice.id_device]);

  useEffect(() => {
    if (instantParkingData.length > 0) {
      const flattenedData = flattenData(instantParkingData[0]);
      setPointStatus(flattenedData);
    }
  }, [instantParkingData]);

  useEffect(() => {
    getConfig(selectedVieroDevice.id_device);
  }, [selectedVieroDevice.id_device]);

  useEffect(() => {
    SetCamera(selectedVieroDevice.id_device);
    return () => {
      FreeCamera();
    };
  }, [selectedVieroDevice.id_device, SetCamera, FreeCamera]);

  const renderImageWithPoints = () => {
    if (!configResult) {
      return null;
    }
    return (
      <div style={{ position: "relative", display: "inline-block" }}>
        {parkingFilePath && (
          <img
            src={`${(window.location.origin === 'http://localhost:8081') ? 'http://localhost:3000' : window.location.origin}${parkingFilePath}`}
            alt="ConfigImage"
            style={{ position: "relative" }}
          />
        )}
        {configResult.parking_points &&
          configResult.parking_points.map((point: any) => (
            <div
              key={point.id}
              style={{
                position: "absolute",
                left: point.x,
                top: point.y,
                transform: `translate(-50%, -50%) rotate(${point.rotation}deg)`, // Apply rotation
                width: "35px",
                height: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:
                  pointStatus.find((p) => p.id === point.id)?.status === 1
                    ? "red"
                    : "green",
                borderRadius: "5px",
                cursor: "pointer",
                padding: "5px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "70%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                🅿️
              </div>
              <div
                style={{
                  width: "100%",
                  height: "30%",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "12px",
                  marginTop: "2px",
                }}
              >
                {point.id}
              </div>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div>
      <Spin size="default"/>
      {renderImageWithPoints()}
    </div>
  );
};

export default ParkingStream;
