import {
  BellOutlined,
  LogoutOutlined,
  UserOutlined,
  MailOutlined,
  TranslationOutlined,
  RightOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import { List, Badge, Dropdown, Menu, Popover, Checkbox, Button } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import "./Containers.css"; 
import { useNotification } from "../contexts/information/NotificationContext";
import { useJunctionData } from "../contexts/junction/JunctionDataContext";
import { useAuth } from "../contexts/auth/AuthContext";
import Flex from "../pages/map/components/custom-styled/layout/Flex";
import DarkButton from "../pages/map/components/custom-styled/button/DarkButton";

const notificationAudio = new Audio("/static/mango_notification.mp3");

interface Props {
  logoutClick: () => void;
  navigateToProfile: () => void;
}

const HeaderButtons: React.FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { allOrganizations, setOrganizations } = useAuth();
  const [filteredOrganizations, setFilteredOrganizations] = useState<number[]>(
    []
  );
  const notificationList = useNotification().notificationList;
  const readNotification = useNotification().readNotification;
  const junctions:any = undefined;

  const logoutClick = () => {
    localStorage.removeItem("layerList");
    props.logoutClick();
  };

  const navigateToProfile = () => {
    props.navigateToProfile();
  }

  const organizationClick = (checkedValues: CheckboxValueType[]) => {
    setFilteredOrganizations(checkedValues as number[]);
  };

  const organizationShortcut = (type: 'all' | 'clear') => {
    if (type === "all") {
      setFilteredOrganizations(allOrganizations.map((item) => item.value));
    } else {
      setFilteredOrganizations([]);
    }
  }

  useEffect(() => {
    setFilteredOrganizations(allOrganizations.map((item) => item.value));
  }, [allOrganizations]);

  useEffect(() => {
    if (filteredOrganizations.length > 0) {
      setOrganizations(filteredOrganizations);
        
      }
  }, [filteredOrganizations]);

  const menu = (
    <Menu
      multiple
      style={{ width: "150px", marginTop: "15px" }}
      triggerSubMenuAction="hover"
      key="menu"
    >
      <Menu.Item onClick={navigateToProfile} key="100" icon={<UserOutlined />}>
        {t("profile")}
      </Menu.Item>
      {allOrganizations.length > 1 && (
        <Checkbox.Group
          value={filteredOrganizations}
          key="chckgr"
          style={{ width: "100%" }}
          onChange={organizationClick}
        >
          <Menu.SubMenu
            key="submenu"
            icon={<ApartmentOutlined />}
            title={t("organization")}
            popupClassName="subMenu"
          >
            <div style={{justifyContent: "center", display: "flex", alignItems:"center"}}>
              <Button onClick={() => organizationShortcut('all')}>{t("all")}</Button>
              <Button onClick={() => organizationShortcut('clear')}>{t("clear")}</Button>
            </div>
            {allOrganizations.map((item, index) => {
              return (
                <Menu.Item key={"mi" + index}>
                  <Checkbox
                    key={"chc" + index}
                    value={item.value}
                    style={{ width: "100%" }}
                  >
                    {item.label}
                  </Checkbox>
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        </Checkbox.Group>
      )}

      <Menu.Divider />
      <Menu.Item key="s3" icon={<LogoutOutlined />} onClick={logoutClick}>
        {t("logout")}
      </Menu.Item>
    </Menu>
  );

  const lngs: any = {
    en: {
      nativeName: "English",
    },
    tr: {
      nativeName: "Türkçe",
    },
    id: {
      nativeName: "Bahasa Indonesia",
    },
    tk: {
      nativeName: "Türkmenler",
    },
    ru: {
      nativeName: "Русский",
    },
  };

  const lngsMenu = (
    <Menu
      onClick={(e) => {
        i18n.changeLanguage(e.key);
        localStorage.setItem("language", e.key);
      }}
      style={{
        width: "150px",
        marginTop: "15px",
        padding: "0px 0px",
      }}
    >
      {Object.keys(lngs).map((lng: any, index: number) => {
        const isLngSelected: boolean = i18n.resolvedLanguage === lng;
        return (
          <Menu.Item
            key={lng}
            icon={<RightOutlined />}
            style={{
              padding: "8px 8px",
              fontWeight: 500,
              backgroundColor: isLngSelected ? "#f3f3f3" : "#fff",
              color: isLngSelected ? "#333" : "#666",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#e8e8e8",
              borderTopWidth: index !== 0 ? 0 : 1,
              borderBottomWidth: 1,
              borderRadius: "5px",
            }}
            disabled={isLngSelected}
          >
            {lngs[lng].nativeName}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  /* voice notification*/
  const notificationCount = notificationList?.filter(
    (notification) => !notification?.read
  )?.length;



  //some browsers have "User Activation" policy, ex:Chrome, this simple structure handle this issue.
  const playNotificationSound = async () => {
    await notificationAudio.play();
  };

  useEffect(() => {
    if (notificationCount > 0) {
      playNotificationSound();
    }
  }, [notificationCount]);
  /*voice notification*/

  const content = useMemo(
    () => (
      
      <List
        itemLayout="horizontal"
        dataSource={notificationList}
        renderItem={(item, index) => (
          <List.Item
            style={{
              borderLeftWidth: 4,
              borderLeftColor: item.read ? "#13005A" : "tomato",
              borderLeftStyle: "solid",
              paddingLeft: 7,
              marginBottom: 6,
              cursor: item.read ? "default" : "pointer",
              transition: "all .5s",
            }}
            onClick={() => {
              readNotification(index);
            }}
          >
            <List.Item.Meta
              title={
                <>
                         <span
                    style={{
                      color: "#000",
                      fontSize: "1rem",
                      fontWeight: 600,
                    }}
                  >
                    {
                      junctions?.find(
                        (junction:any) => junction?.id === item?.junctionId
                      )?.name
                    }
                  </span><br/>
                  <span
                    style={{
                      fontSize: "1rem",
                      color: "#333",
                      fontWeight: 600,
                    }}
                  >
                    {moment(Date.now())
                      .utcOffset(localStorage.getItem("timezone")!)
                      .format("DD/MM/YY, HH:mm:ss")}
                  </span><br/>        
                </>
              }
              description={<span 
                style={{
                  fontSize: "1rem",
                  color: "#333",
                  fontWeight: 600,
                }}
              >{item.module+" - "+item.description +" - "+ item.parameters}</span>}
            />
          </List.Item>
        )}
        style={{
          overflow: "auto",
          // height: 300,
          maxHeight: "50vh",
        }}
      />
    ),
    [notificationList]
  );

  return (
    <Flex
      style={{ opacity: "0.9" }}
      justifyContent="flex-end"
      alignItems="center"
      width="100%"
    >
      <Dropdown overlay={lngsMenu} trigger={["click"]}>
        <DarkButton icon={<TranslationOutlined color="white" />} />
      </Dropdown>

      <DarkButton icon={<MailOutlined color="white" />} />
      <Popover content={content} title={t("notifications")} trigger="hover"
       overlayStyle={{
        width: "15vw"
      }}
      >
        <Badge
          count={
            notificationList?.filter((notification) => !notification?.read)
              ?.length
          }
          style={{ marginLeft: "30px", zIndex: 5 }}
        >
          <DarkButton icon={<BellOutlined color="white" />} />
        </Badge>
      </Popover>
      <div style={{ marginRight: "40px" }}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <DarkButton icon={<UserOutlined color="white" />} />
        </Dropdown>
      </div>
    </Flex>
  );
};

export default HeaderButtons;
