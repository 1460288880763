import L, { LeafletMouseEvent } from "leaflet";
import { useEffect, useMemo, useState } from "react";
import { Marker, Tooltip, useMap, useMapEvent } from "react-leaflet";
import { Inventory } from "../../../../data/inventory/Inventory";
import "./assets/InventoryMarker.css";
import { t } from "i18next";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import withLogging from "../../high-order-components/LoggerHOC";
import GenericMapModal from "../../components/generic/map-modal/GenericMapModal";
import InventoryModalTitle from "./InventoryModalTitle";
import InventoryModalMain from "../../../inventory";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useInventoryData } from "../../../../contexts/inventory/InventoryDataContext";
import { Alert } from "antd";
import MarkerGenericAddUpdateContent from "../../components/generic/marker-context-modal/MarkerGenericAddUpdateContent";
import { taskIconBase64 } from "../../map-constants/static/MarkerIconBase64";
import { TaskModel } from "../../components/generic/marker-context-modal/model/Task";
import { useTaskManagementData } from "../../../../contexts/taskmanagement/TaskManagementDataContext";
import { MapModule } from "@icms/ui-components";
import InventoryService from "../../../../data/inventory/InventoryService";

const { REACT_APP_API_URL_DEV,REACT_APP_HOST_TYPE } = process.env;
const baseURL = REACT_APP_HOST_TYPE==="dev"?REACT_APP_API_URL_DEV:window.location.origin;

function InventoryLayer() {
  const { setLayerData } = useCustomLayerData();
  const { updateTaskList } = useTaskManagementData();
  const { inventoryList, getInventoryList, infoMsg } = useInventoryData();
  const [markerData, setMarkerData] = useState<Inventory[]>([]);
  const [selectedInventory, setSelectedInventory] = useState<Inventory>();
  const [taskModal, setTaskModal] = useState<{
    visible: boolean;
    inventory?: Inventory;
  }>();
  const [sheet, setSheet] = useState<any>();
  const map = useMap();

  const markerHandlers = (inventory: Inventory) => {
    return {
      dblclick: (event: LeafletMouseEvent) =>
        handleMarkerDblClick(event, inventory),
      contextmenu: (event: LeafletMouseEvent) =>
        handleMarkerContextMenu(inventory),
    };
  };

  const mapEvents = useMapEvent("moveend", (event) => {
    const bounds = map.getBounds();
    const bbox = {
      min_lon: bounds.getWest(),
      min_lat: bounds.getSouth(),
      max_lon: bounds.getEast(),
      max_lat: bounds.getNorth(),
    };
    getInventoryList(bbox, map.getZoom());
  });

  const updateInventory = (inventory: Inventory) => {};

  const assignTask = (inventory: Inventory) => {
    setTaskModal({
      visible: true,
      inventory: inventory,
    });
  };

  const closeTaskModal = () => {
    setTaskModal({ visible: false, inventory: undefined });
  };

  const handleMarkerDblClick = (
    event: LeafletMouseEvent,
    inventory: Inventory
  ) => {
    setSelectedInventory(inventory);
  };

  const handleMarkerContextMenu = (inventory: Inventory) => {
    map.contextmenu.removeAllItems();
    map.contextmenu.addItem({
      text: t("update"),
      callback: () => updateInventory(inventory),
    });
    map.contextmenu.addItem({
      text: t("assign_task"),
      callback: () => assignTask(inventory),
    });
  };

  const onCloseModal = () => {
    setSelectedInventory(undefined);
  };

  const getSpritesheet = async () => {
    let sheet = await InventoryService.getSpritesheet();
    setSheet(sheet.data);
  };

  const modalContent = useMemo(() => {
    return (
      <>
        {selectedInventory && (
          <GenericMapModal
            modalWidth="35%"
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <InventoryModalTitle
                componentName="InventoryModalTitle"
                selectedInventory={selectedInventory}
              />
            }
          >
            <InventoryModalMain selectedInventory={selectedInventory} />
          </GenericMapModal>
        )}
      </>
    );
  }, [selectedInventory]);

  const assignTaskContent = useMemo(() => {
    return (
      <>
        {taskModal?.visible && taskModal?.inventory && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={closeTaskModal}
            modalTitle={
              <InventoryModalTitle
                componentName="InventoryModalTitle"
                selectedInventory={taskModal?.inventory}
              />
            }
          >
            <MarkerGenericAddUpdateContent
              icon={taskIconBase64}
              model={TaskModel}
              modalType="add"
              triggerStateFunc={updateTaskList}
              latlon={
                new L.LatLng(
                  taskModal?.inventory.latitude,
                  taskModal?.inventory.longitude
                )
              }
              setModalVisible={closeTaskModal}
            />
          </GenericMapModal>
        )}
      </>
    );
  }, [taskModal]);

  useEffect(() => {
    getSpritesheet();
  }, []);

  useEffect(() => {
    setMarkerData(inventoryList);
    setLayerData({
      id: MapModule.InventoryModule,
      data: inventoryList,
      searchable: {
        category: "inventory_management",
        filterProperties: ["barcode", "address"],
        labelProperty: "name",
      },
    });
  }, [inventoryList]);

  return (
    <>
      <MarkerClusterGroup showCoverageOnHover={false}>
        {sheet &&
          markerData?.length > 0 &&
          markerData.map((inventory, index) => {
            let frame = sheet.frames[`${inventory.sub_type_id}.png`].frame;
            return (
              <Marker
                key={index}
                position={[inventory.latitude, inventory.longitude]}
                icon={L.divIcon({
                  iconSize: [40, 40],
                  html: `<div class="inventory-marker" style="background: url(${baseURL}/file/inventory/spritesheet.png) -${frame.x}px -${frame.y}px;"></div>`,
                })}
                eventHandlers={markerHandlers(inventory)}
                contextmenu={true}
              >
                <Tooltip>{inventory.barcode}</Tooltip>
              </Marker>
            );
          })}
      </MarkerClusterGroup>
      {infoMsg !== "" && (
        <Alert
          message={t(infoMsg)}
          type="info"
          showIcon
          style={{
            zIndex: "1000",
            padding: "5px",
            marginTop: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            width: "30vw",
          }}
        />
      )}
      {modalContent}
      {assignTaskContent}
    </>
  );
}

export default withLogging(InventoryLayer);
