import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Bluesis } from "../../data/bluesis/Bluesis";
import BluesisService from "../../data/bluesis/BluesisService";
import { useAuth } from "../auth/AuthContext";
import { Route } from "../../data/bluesis/Route";
import { Vector } from "../../data/bluesis/Vector";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";

export interface BluesisDataContextState {
  bluesisList: Bluesis[];
  routeList: Route[];
  vectorList: Vector[];
  updateBluesisList: () => void;
  updateRoutes: () => void;
  setVisibleBluesisRoute: (value: boolean) => void;
  visibleBluesisRoute: boolean;
}

const BluesisDataContext = createContext<BluesisDataContextState | undefined>(
  undefined
);

export function BluesisDataProvider({ children }: PropsWithChildren<{}>) {
  const { filteredOrganizations } = useAuth();
  const [bluesises, setBluesises] = useState<Bluesis[]>([]);
  const [filteredBluesises, setFilteredBluesises] = useState<Bluesis[]>([]);
  const [bluesisRoutes, setBluesisRoutes] = useState<Route[]>([]);
  const [filteredBluesisRoutes, setFilteredBluesisRoutes] = useState<Route[]>(
    []
  );
  const [bluesisVectors, setBluesisVectors] = useState<Vector[]>([]);
  const [filteredBluesisVectors, setFilteredBluesisVectors] = useState<
    Vector[]
  >([]);
  const [visibleBluesisRoute, setVisibleBluesisRoute] =
    useState<boolean>(false);
  const { hasAccessComponent, componentPermissions } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  const getBluesises = async () => {
    await BluesisService.getBluesises()
      .then((res) => {
        setFilteredBluesises(res.data);
        setBluesises(res.data);
      })
      .catch((err) => console.log(err));
  };

  const updateBluesisList = async () => {
    await BluesisService.getBluesises()
      .then((res) => {
        setFilteredBluesises(
          res.data.filter((item) => filteredOrganizations.includes(item.org_id))
        );
      })
      .catch((err) => console.log(err));
  };

  const updateRoutes = async () => {
    await BluesisService.getRoutes()
      .then((res) => {
        setFilteredBluesisRoutes(
          res.data.filter((item) => filteredOrganizations.includes(item.org_id))
        );
      })
      .catch((err) => console.log(err));
    await BluesisService.getVectors()
      .then((res) => {
        setFilteredBluesisVectors(
          res.data.filter((item) => filteredOrganizations.includes(item.org_id))
        );
      })
      .catch((err) => console.log(err));
  };

  const getBluesisRoutes = async () => {
    await BluesisService.getRoutes()
      .then((res) => {
        setBluesisRoutes(res.data);
        setFilteredBluesisRoutes(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getBluesisVectors = async () => {
    await BluesisService.getVectors()
      .then((res) => {
        setBluesisVectors(res.data);
        setFilteredBluesisVectors(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getBluesises();
    getBluesisRoutes();
    getBluesisVectors();
  }, [componentPermissions]);

  useEffect(() => {
    setFilteredBluesises(
      bluesises.filter((item) => filteredOrganizations.includes(item.org_id))
    );
  }, [bluesises, filteredOrganizations]);

  useEffect(() => {
    setFilteredBluesisRoutes(
      bluesisRoutes.filter((item) =>
        filteredOrganizations.includes(item.org_id)
      )
    );
  }, [bluesisRoutes, filteredOrganizations]);

  useEffect(() => {
    setFilteredBluesisVectors(
      bluesisVectors.filter((item) =>
        filteredOrganizations.includes(item.org_id)
      )
    );
  }, [bluesisVectors, filteredOrganizations]);

  return hasAccessComponent("Bluesis Module-READ") ? (
    <BluesisDataContext.Provider
      value={{
        bluesisList: filteredBluesises,
        routeList: filteredBluesisRoutes,
        vectorList: filteredBluesisVectors,
        updateBluesisList: updateBluesisList,
        updateRoutes: updateRoutes,
        setVisibleBluesisRoute: setVisibleBluesisRoute,
        visibleBluesisRoute: visibleBluesisRoute,
      }}
    >
      {children}
    </BluesisDataContext.Provider>
  ) : (
    <>{children}</>
  );
}

export function useBluesisData() {
  const context = useContext(BluesisDataContext);
  if (context === undefined) {
    return {
      bluesisList: [],
      routeList: [],
      vectorList: [],
      updateBluesisList: () => {},
      updateRoutes: () => {},
      setVisibleBluesisRoute: (value: boolean) => {},
      visibleBluesisRoute: false,
    };
    //throw new Error("useBluesisData must be used within a BluesisDataContext");
  }
  return context;
}
