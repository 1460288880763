import http from "../http.common";

const getReleaseNotes = function (version: string) {
  return http.get<any>(`releaseInfo/${version}`);
};

const getVersionInfo = function () {
  return http.get<any>(`version.json`);
};

const AppInfoService = {
  getReleaseNotes,
  getVersionInfo,
};

export default AppInfoService;
