import colorPallet from "./colorPallet";

const getPlanColor = (plan: any) => {
  if (plan?.planType === "Flash") {
    return "#000";
  }

  if (typeof plan?.id === "number" && plan?.id <= colorPallet.length) {
    return colorPallet[plan?.id];
  } else {
    return "#333";
  }
};

export default getPlanColor;