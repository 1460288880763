import { AlertOutlined, DesktopOutlined } from "@ant-design/icons";
import MultiModePanel from "../../components/custom-styled/layout/MultiModePanel";
import MultipleWatchContent from "../../controls/top-left-control/MultipleWatchContent";
import PoliceButtonContent from "../../controls/top-left-control/PoliceModeContent";
import { MapModule } from "@icms/ui-components";
import FCDControl from "../../controls/top-left-control/FCDControl";
import BluesisRouteControl from "../../controls/top-left-control/BluesisRouteControl";
import InventoryControl from "../../controls/top-left-control/InventoryControl";
import KKCFilterControl from "../../controls/top-left-control/KKCFilterControl";
import AssignedToMeControl from "../../controls/top-left-control/AssignedToMeControl";

export enum modeList {
  PoliceMode = 1,
  MultipleWatchMode,
  FCDButton,
  BluesisRouteButton,
  InventoryType,
  KKCFilter,
  AssignedToMeButton,
}
interface ToolbarButton {
  modeId: modeList;
  button: JSX.Element;
}

interface Toolbar {
  layerId: MapModule;
  toolbarButtonList: ToolbarButton[];
}

const buttonList: ToolbarButton[] = [
  {
    modeId: modeList.PoliceMode,
    button: (
      <MultiModePanel title="police_mode" icon={<AlertOutlined />}>
        <PoliceButtonContent />
      </MultiModePanel>
    ),
  },
  {
    modeId: modeList.MultipleWatchMode,
    button: (
      <MultiModePanel title="watch_multiple_modules" icon={<DesktopOutlined />}>
        <MultipleWatchContent />
      </MultiModePanel>
    ),
  },
  {
    modeId: modeList.FCDButton,
    button: <FCDControl />,
  },
  {
    modeId: modeList.BluesisRouteButton,
    button: <BluesisRouteControl />,
  },
  {
    modeId: modeList.InventoryType,
    button: <InventoryControl />,
  },
  {
    modeId: modeList.KKCFilter,
    button: <KKCFilterControl />,
  },
  {
    modeId: modeList.AssignedToMeButton,
    button: <AssignedToMeControl />,
  },
];

const junctionToolbar = buttonList.filter((item) =>
  [
    modeList.PoliceMode,
    modeList.MultipleWatchMode,
    modeList.KKCFilter,
    modeList.AssignedToMeButton,
  ].includes(item.modeId)
);
const vieroToolbar = buttonList.filter((item) =>
  [modeList.MultipleWatchMode, modeList.AssignedToMeButton].includes(
    item.modeId
  )
);
const cameraToolbar = buttonList.filter((item) =>
  [modeList.MultipleWatchMode, modeList.AssignedToMeButton].includes(
    item.modeId
  )
);
const fcdToolbar = buttonList.filter((item) =>
  [modeList.FCDButton].includes(item.modeId)
);
const bluesisToolbar = buttonList.filter((item) =>
  [modeList.BluesisRouteButton, modeList.AssignedToMeButton].includes(
    item.modeId
  )
);
const inventoryToolbar = buttonList.filter((item) =>
  [modeList.InventoryType, modeList.AssignedToMeButton].includes(item.modeId)
);
const barrierToolbar = buttonList.filter((item) =>
  [modeList.AssignedToMeButton].includes(item.modeId)
);
const upsToolbar = buttonList.filter((item) =>
  [modeList.AssignedToMeButton].includes(item.modeId)
);
const vmsToolbar = buttonList.filter((item) =>
  [modeList.AssignedToMeButton].includes(item.modeId)
);

export const MapToolbarData: Toolbar[] = [
  {
    layerId: MapModule.JunctionModule,
    toolbarButtonList: junctionToolbar,
  },
  {
    layerId: MapModule.CameraModule,
    toolbarButtonList: cameraToolbar,
  },
  {
    layerId: MapModule.VieroModule,
    toolbarButtonList: vieroToolbar,
  },
  {
    layerId: MapModule.BluesisModule,
    toolbarButtonList: bluesisToolbar,
  },
  {
    layerId: MapModule.FCDModule,
    toolbarButtonList: fcdToolbar,
  },
  {
    layerId: MapModule.InventoryModule,
    toolbarButtonList: inventoryToolbar,
  },
  {
    layerId: MapModule.BarrierModule,
    toolbarButtonList: barrierToolbar,
  },
  {
    layerId: MapModule.UpsModule,
    toolbarButtonList: upsToolbar,
  },
  {
    layerId: MapModule.VmsModule,
    toolbarButtonList: vmsToolbar,
  },
];
