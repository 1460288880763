import { FormInstance } from "antd";
import axios from "axios";
import { PhaseType } from "../../..";

import planFormPhasesTableFields from "../../../formFieldStructs/planFormPhasesTableFields";
import { checkPhaseFormMinMaxSeq } from "./checkPhaseMinMaxSeq";

const { phase_name: phaseNameFieldKey } = planFormPhasesTableFields;

interface InterfaceOfSubmitPlanParameter {
  form: FormInstance<any>;
  formValues: object;
  planFormObject: any;
  checkTS_beforeSubmit: () => {
    [x: string]: number;
  };
  isFlashPlan: boolean | undefined;
  planActionMode: "edit" | "copy" | null;
  cancelToken: any;
  juncPhases: PhaseType[];
}

const submitPlanForm = ({
  form,
  formValues,
  planFormObject,
  checkTS_beforeSubmit,
  isFlashPlan,
  planActionMode,
  cancelToken,
  juncPhases,
}: InterfaceOfSubmitPlanParameter) => {
  let planTS_values = checkTS_beforeSubmit();

  //Check if there are any previous pending requests
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Yeni istek nedeniyle işlem iptal edildi.");
  }

  //Save the cancel token for the current request
  cancelToken = axios.CancelToken.source();

  // just take the top level properties except phasesFormObject
  // avoid to pair with phases
  let { phasesFormObject, ...planFormOutOfPhases } = planFormObject;

  // check/set plan type
  if (!isFlashPlan) {
    if (planFormOutOfPhases?.greenWaveMode) {
      planFormOutOfPhases.planType = "Greenwave";
    } else {
      planFormOutOfPhases.planType = "Smart";
    }
  } else {
    planFormOutOfPhases.planType = "Flash";
  }

  let planData: any = {
    ...planFormOutOfPhases, // to get fields which form inputs does not cover like managemenType and planType
    ...formValues,
    ...planTS_values,
    phases: phasesFormObject,
  };

  // clear name fields from phases
  var planFormOutput = {
    ...planData,
    phases: planData.phases.map((phase: any) => {
      let { [phaseNameFieldKey]: name, ...rest } = phase;
      return rest;
    }),
  };

  const sequenceTimeoutValidates = checkPhaseFormMinMaxSeq(planFormOutput.phases, juncPhases);

  return {planFormOutput, sequenceTimeoutValidates};
};

export default submitPlanForm;
