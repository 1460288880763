import { Button, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import JunctionDetectorService from "../../../data/junction-detector/JunctionDetectorService";
import { Junction } from "../../../data/junction/Junction";
import { PhaseIdColor } from "./GroupData";
import { phaseDataModel, usePhaseData } from "../../../contexts/junction/PhaseDataContext";

interface Props {
  selectedJunction?: Junction;
}

function PhaseTable(props: Props) {
  const { t } = useTranslation();
  const { selectedJunction } = props;
  const { phaseData } = usePhaseData();
  const [dataSource, setDataSource] = useState<phaseDataModel[]>([]);
  const [detectors, setDetectors] = useState<any[]>([]);
  const [refreshTable, setRefreshTable] = useState<boolean>();
  const [isLast,setIsLast]=useState<boolean>();
  useEffect(() => {
    JunctionDetectorService.get(+selectedJunction?.id!).then((res) => {
      setDetectors(res.data);
      setRefreshTable(true);
    }
    )
  }, [selectedJunction]);

  useEffect(() => {
    //console.log(phaseData)
    if (phaseData.length > 0) {
      //show last 30 record
      if (phaseData.length === 31) {
        phaseData.splice(0, 1);
      }
      phaseData.map((val: any, index: number) => {
        val["key"] = index;
        if (detectors.length > 0) {
          val["detectorDatas"]?.length>0&&val["detectorDatas"].map((item: any) => {
            item["detectorName"] = detectors.find(
              (detec) => detec.internal_id == item?.detectorId
            )?.name;
          });

          val["loopData"]?.length>0&& val["loopData"].map((item: any) => {
            item["detectorName"] = detectors.find(
              (detec) => detec.internal_id-100 == item?.detectorId
            )?.name;
          });
        }
      });
      setDataSource(phaseData);

      if(phaseData[phaseData.length-1]?.is_last==true)
            setIsLast(true);
    }
    return function cleanUp() {
      setDataSource([]);
    };
  }, [phaseData]);

  useEffect(() => {
    if (phaseData.length > 0) {
      //show last 30 record
      if (phaseData.length === 31) {
        phaseData.splice(0, 1);
      }
      phaseData.map((val: any, index: number) => {
        val["key"] = index;
        if (detectors.length > 0) {
          val["detectorDatas"]?.length>0&&val["detectorDatas"].map((item: any) => {
            item["detectorName"] = detectors.find(
              (detec) => detec.internal_id == item?.detectorId
            )?.name;
          });

          val["loopData"]?.length>0&&val["loopData"].map((item: any) => {
            item["detectorName"] = detectors.find(
              (detec) => detec.internal_id-100 == item?.detectorId
            )?.name;
          });
        }
      });
      setDataSource(phaseData);
    }
    return function cleanUp() {
      setDataSource([]);
    };
  }, [detectors]);

  const columns: ColumnsType<phaseDataModel> = [
    {
      dataIndex: "oldPhaseId",
      key: "oldPhaseId",
      align: "left",
      render: (text, row) => {
        let phaseColorListCount = Object.keys(PhaseIdColor).length / 2;
        let phaseColor = PhaseIdColor[text]
          ? PhaseIdColor[text]
          : PhaseIdColor[text % phaseColorListCount];
        return (
          <Button
            size="small"
            style={{
              background: `${phaseColor}`,
              color: "white",
              fontWeight: "bold",
              borderRadius: "5px",
            }}
          >
            {row["oldPhaseId"]}
          </Button>
        );
      },
      width: 40,
    },
    {
      title: t("phase_name"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: t("date"),
      dataIndex: "ts",
      key: "ts",
      align: "center",
      render: (value: number) => {
        let convertedDate = moment
          .unix(value)
          .utcOffset(localStorage.getItem("timezone")!)
          .format("DD/MM/YYYY HH:mm:ss");
        return convertedDate;
      },
      sorter: (a: any, b: any) =>
      new Date(a.ts).valueOf() - new Date(b.ts).valueOf(),
    defaultSortOrder: "descend",
    },
    {
      title: `${t("green_time")}(sn)`,
      dataIndex: "greenTime",
      key: "greenTime",
      align: "center",
    },
    {
      title: `${t("Viero")} (${t("vehicle_count")})`,
      dataIndex: "detectorDatas",
      key: "detectorDatas",
      align: "center",
      render: (value: any) => {
        return value?.length > 0?value.map((item:any, index:any) => `${item.detectorName}(${item.count})`).join('-'):"";
      },
    },
   
    {
      title: `${t("Loop")} (${t("vehicle_count")})`,
      dataIndex: "loopData",
      key: "loopData",
      align: "center",

      render: (value: any) => {
         return value?.length > 0?value.map((item:any, index:any) => `${item.detectorName}(${item.count})`).join('-'):"";
      },
    },
    {
      title: t("min_avg_max"),
      dataIndex: ["min", "mean", "max"],
      key: "min",
      align: "center",
      render: (text, row) => {
        return `${row["min"]} - ${row["mean"]} - ${row["max"]}`;
      },
    },
  ];

  return (
    <>
    {
      <Table
        rowKey={"key"}
        style={{ height: "100%" }}
        dataSource={dataSource}
        columns={columns}
        scroll={{ y: 300 }}
        pagination={false}
        size="small"
        tableLayout="fixed"
        loading={{
          indicator: <Spin />,
          spinning: dataSource.length > 0 && isLast ? false : true,
        }}
      />
    }
  </>
  );
  
}

export default PhaseTable;
