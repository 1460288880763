import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Vehicle } from "../../data/vehicle/Vehicle";
import { socket } from "../websocket/WebSocketContext";
import { xml2json } from "xml-js";
import EmergencyPassSystemService from "../../data/emergency/emergencyPassSystemService";
import { Polygon } from "../../data/emergency/Polygon";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";

interface VehicleDataProps {
  vehicleList: Vehicle[];
  polygonList: Polygon[];
}

const VehicleDataContext = createContext<VehicleDataProps | undefined>(
  undefined
);

export function VehicleDataProvider({ children }: PropsWithChildren<{}>) {
  const [vehicleDatas, setVehicleDatas] = useState<Vehicle[]>([]);
  const [polygonDatas, setPolygonDatas] = useState<Polygon[]>([]);
  const { hasAccessComponent,componentPermissions } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  useEffect(() => {
    socket.on("vehicleList", (data: any) => {
      let vehicles: any = xml2json(data, { compact: true });
      //for azez
      // let vehicleList: Vehicle[] = JSON.parse(
      //   vehicles
      // ).ArrayOfLastPacket.LastPacket.map((item: any) => {
      //   if(item.strNode._text == "XA00003258"){
      //     return {
      //       lat: parseFloat(item.dLatitude._text),
      //       lng: parseFloat(item.dLongitude._text),
      //       speed: parseInt(item.dSpeed._text),
      //     };
      //   }
      // }).filter((e:any) => e);
      let vehicleList: Vehicle[] = JSON.parse(
        vehicles
      ).ArrayOfLastPacket.LastPacket.map((item: any) => {
        return {
          lat: parseFloat(item.dLatitude._text),
          lng: parseFloat(item.dLongitude._text),
          speed: parseInt(item.dSpeed._text),
        };
      });
      setVehicleDatas(vehicleList);
    });
    return () => {
      socket.off("vehicleList");
    };
  }, []);

  useEffect(() => {
    getPolygons();
  }, [componentPermissions]);

  const getPolygons = () => {
    EmergencyPassSystemService.getPolygons().then((res) => {
      setPolygonDatas(res.data);
    });
  };

  return hasAccessComponent("Vehicle Priorty Module-READ") ? (
    <VehicleDataContext.Provider
      value={{
        vehicleList: vehicleDatas,
        polygonList: polygonDatas,
      }}
    >
      {children}
    </VehicleDataContext.Provider>
  ) : (
    <>{children}</>
  );
}

export function useVehicleData() {
  const context = useContext(VehicleDataContext);
  if (context === undefined) {
    return {
      vehicleList: [],
      polygonList: [],
    };
    //throw new Error("useVehicleData must be used within a VehicleDataContext");
  }
  return context;
}
