import { Bluesis } from "../../../../data/bluesis/Bluesis";
import { GenericMapModalTitle } from "../../components/generic/map-modal/GenericMapModalTitle";
import withLogging from "../../high-order-components/LoggerHOC";

interface BluesisModalTitleProps {
  selectedBluesis: Bluesis;
}

function BluesisModalTitle(props: BluesisModalTitleProps) {
  const { selectedBluesis } = props;

  return (
    <GenericMapModalTitle>
      <span style={{ fontSize: "2vw" }}>{selectedBluesis.name}</span>
    </GenericMapModalTitle>
  );
}

export default withLogging(BluesisModalTitle);
