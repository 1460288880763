import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Inventory } from "../../data/inventory/Inventory";
import InventoryService from "../../data/inventory/InventoryService";
import {
  InventorySubTypeWithIcon,
  InventoryType,
} from "../../data/inventory/InventoryType";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";

export interface bboxModel {
  min_lat: number;
  max_lat: number;
  min_lon: number;
  max_lon: number;
}

export interface InventoryDataContextState {
  inventoryList: Inventory[];
  getInventoryList: (bbox: bboxModel, zoom: number) => void;
  setInventoryType: (type: InventoryType) => void;
  infoMsg: string;
  inventoryTypes: InventoryType[];
  inventorySubTypes: InventorySubTypeWithIcon[];
}

const InventoryDataContext = createContext<
  InventoryDataContextState | undefined
>(undefined);

export function InventoryDataProvider({ children }: PropsWithChildren<{}>) {
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [lastAbortController, setLastAbortController] =
    useState<AbortController>();
  const [type, setType] = useState<InventoryType>();
  const [lastMapConfig, setLastMapConfig] = useState<{
    bbox: bboxModel;
    zoom: number;
  }>();
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [inventoryTypes, setInventoryTypes] = useState<InventoryType[]>([]);
  const [inventorySubTypes, setInventorySubTypes] = useState<
    InventorySubTypeWithIcon[]
  >([]);
  const { hasAccessComponent, componentPermissions } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  const getInventoryList = async (bbox: bboxModel, zoom: number) => {
    setLastMapConfig({
      bbox: bbox,
      zoom: zoom,
    });
    if (type && zoom > 16) {
      const { promise, abortController } = InventoryService.getInventories(
        bbox,
        type.id
      );

      setLastAbortController(abortController);
      promise
        .then((response) => {
          setInventories(response.data);
          setInfoMsg("");
        })
        .catch((err) => {
          console.log("inventory cancel request");
        });
    }
    if (zoom <= 16) {
      setInfoMsg("please_increase_zoom");
    }
    if (!type) {
      setInfoMsg("please_select_inventory_type");
    }
  };

  const getInventoryTypes = async () => {
    // let res = await InventoryService.getInventoryTypes();
    // setInventoryTypes(res.data);
  };

  const getInventorySubTypes = async () => {
    // let res = await InventoryService.getInventorySubTypes();
    // setInventorySubTypes(res.data);
  };

  const setInventoryType = (type: InventoryType) => {
    setType(type);
  };

  useEffect(() => {
    getInventoryTypes();
    getInventorySubTypes();
  }, [componentPermissions]);

  useEffect(() => {
    return () => {
      if (lastAbortController) {
        lastAbortController.abort();
      }
    };
  }, [lastAbortController]);

  useEffect(() => {
    if (lastMapConfig) {
      getInventoryList(lastMapConfig.bbox, lastMapConfig.zoom);
    }
  }, [type]);

  return hasAccessComponent("Inventory Module-READ") ? (
    <InventoryDataContext.Provider
      value={{
        inventoryList: inventories,
        getInventoryList: getInventoryList,
        setInventoryType: setInventoryType,
        infoMsg: infoMsg,
        inventoryTypes: inventoryTypes,
        inventorySubTypes: inventorySubTypes,
      }}
    >
      {children}
    </InventoryDataContext.Provider>
  ) : (
    <>{children}</>
  );
}

export function useInventoryData() {
  const context = useContext(InventoryDataContext);
  if (context === undefined) {
    return {
      inventoryList: [],
      getInventoryList: (bbox: bboxModel, zoom: number) => {},
      setInventoryType: (type: InventoryType) => {},
      infoMsg: "",
      inventoryTypes: [],
      inventorySubTypes: [],
    };
    // throw new Error(
    //   "useInventoryData must be used within a InventoryDataContext"
    // );
  }
  return context;
}
