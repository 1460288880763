import { t } from "i18next";
import { LicenseDataProvider } from "../../contexts/license/LicenseDataContext";
import { Alert, Button, Col, Form, Input, Row, Select, Upload } from "antd";
import styled from "styled-components";

import { useEffect, useState } from "react";
import UserService from "../../data/user/UserService";
import { UploadOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const { TextArea } = Input;
const { Option } = Select;

const reportTopicEnum = [
  {
    id: 1,
    topic: "junction_management",
    msg: "junction",
  },
  {
    id: 2,
    topic: "vms_management",
    msg: "vms",
  },
  {
    id: 3,
    topic: "bluesis_management",
    msg: "bluesis",
  },
  {
    id: 4,
    topic: "camera_management",
    msg: "camera",
  },
  {
    id: 5,
    topic: "task_management",
    msg: "task",
  },
  {
    id: 6,
    topic: "viero_management",
    msg: "viero",
  },
  {
    id: 7,
    topic: "ups_management",
    msg: "ups",
  },
  {
    id: 8,
    topic: "others",
  },
];

const priorityEnum = [
  {
    id: 1,
    value: "Lowest",
  },
  {
    id: 2,
    value: "Low",
  },
  {
    id: 3,
    value: "Medium",
  },
  {
    id: 4,
    value: "High",
  },
  {
    id: 5,
    value: "Highest",
  },
];

const FullWidthForm = styled(Form)`
  width: 100%;
  border-radius: 7px;
  padding: 14px 24px 64px 24px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.2);
  position: relative;
  backdrop-filter: blur(3px);
`;

const FullWidthFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: white; /* Change the label color to white */
  }

  .ant-form-item-control {
    border: none; /* Remove border from form item control */
  }

  input,
  textarea,
  .ant-select-selector {
    border: none !important; /* Remove border from input, textarea, and select elements */
  }

  input:focus,
  textarea:focus,
  .ant-select-selector:focus {
    box-shadow: none !important; /* Remove focus box shadow */
  }
`;

const CustomDragger = styled(Dragger)`
  border: none !important;
`;

interface ReportFormProps {
  initialSelectedTopic?: any;
  releatedId?: number;
}

const ReportForm = ({ initialSelectedTopic, releatedId }: ReportFormProps) => {
  const [selectedTopic, setSelectedTopic] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();

  const handleFinish = async (values: any) => {
    setLoading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files", file);
    });
    await UserService.reportService(values)
      .then(async (result: any) => {
        if (fileList.length > 0) {
          await UserService.addAttachments(formData, result.data.key).finally(
            () => setLoading(false)
          );
        }
        setShowSuccess(true);
      })
      .finally(() => {
        setLoading(false);
      });

    // Handle form submission here
  };

  useEffect(() => {
    if (initialSelectedTopic) {
      setSelectedTopic(reportTopicEnum[initialSelectedTopic]);
    }
  }, [initialSelectedTopic]);

  useEffect(() => {
    if (showSuccess) {
      form.resetFields();
      setTimeout(() => {
        setShowSuccess(false);
      }, 20000);
    }
  }, [showSuccess]);

  const handleBeforeUpload = (file: any) => {
    setFileList((prevList: any) => [...prevList, file]);
    return false; // Prevent automatic upload
  };

  const props = {
    name: "file",
    multiple: true,
    beforeUpload: handleBeforeUpload,
    fileList: fileList,
    onRemove: (file: any) => {
      setFileList((prevList: any) =>
        prevList.filter((item: any) => item.uid !== file.uid)
      );
    },
  };

  return (
    <LicenseDataProvider>
      <div
        style={{
          flex: 1,
          borderRadius: 5,
          overflow: "hidden",
          backgroundImage: "url(/static/formBg.jpeg)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="report-form"
          style={{
            height: "100%",
            width: "100%",
            paddingTop: "2rem",
            paddingLeft: "2rem",
            paddingBottom: "2rem",
            paddingRight: "2rem",
            backgroundImage:
              "linear-gradient(to right, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.7) 25%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.3) 75%, rgba(0,0,0,0) 100%)",
          }}
        >
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FullWidthForm
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                initialValues={{
                  topic: reportTopicEnum[initialSelectedTopic]?.topic,
                  additionalInfo: releatedId ?? null,
                }}
              >
                <h1
                  style={{
                    fontWeight: 700,
                    color: "#fffdfa",
                  }}
                >
                  {t("reportBug")}
                </h1>
                <FullWidthFormItem
                  name="title"
                  label={t("title")}
                  rules={[{ required: true, message: `${t("enter_title")}` }]}
                >
                  <Input placeholder={t("enter_title")} />
                </FullWidthFormItem>

                <FullWidthFormItem
                  name="description"
                  label={t("description")}
                  rules={[
                    {
                      required: true,
                      message: `${t("enter_desc")}`,
                    },
                  ]}
                >
                  <TextArea placeholder={t("enter_desc")} rows={4} />
                </FullWidthFormItem>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <FullWidthFormItem
                    name="topic"
                    label={t("topic")}
                    style={{ width: "48%" }}
                    rules={[
                      {
                        required: true,
                        message: `${t("select_topic")}`,
                      },
                    ]}
                  >
                    <Select
                      placeholder={t("select_topic")}
                      onChange={(value: any) => {
                        const selected = reportTopicEnum.find(
                          (el: any) => el.topic === value
                        );

                        setSelectedTopic(selected);
                      }}
                    >
                      {reportTopicEnum.map((tpc: any) => (
                        <Option key={tpc.id} value={tpc.topic}>
                          {t(tpc.topic)}
                        </Option>
                      ))}
                    </Select>
                  </FullWidthFormItem>

                  <FullWidthFormItem
                    name="priority"
                    label={t("priority")}
                    style={{ width: "48%" }}
                  >
                    <Select
                      placeholder={t("priority")}
                      onChange={(value: any) => null}
                    >
                      {priorityEnum.map((pr: any) => (
                        <Option key={pr.id} value={pr.value}>
                          {t(pr.value)}
                        </Option>
                      ))}
                    </Select>
                  </FullWidthFormItem>
                </div>

                <FullWidthFormItem
                  label={t("upload_file")}
                  name="file"
                  valuePropName="fileList"
                  getValueFromEvent={(e) =>
                    Array.isArray(e) ? e : e && e.fileList
                  }
                >
                  <CustomDragger {...props}>
                    <div
                      className="reportFormUpload"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 12,
                        alignItems: "center",
                        padding: "0 16px",
                      }}
                    >
                      <UploadOutlined />
                      <p>{t("upload")}</p>
                    </div>
                  </CustomDragger>
                </FullWidthFormItem>

                <FullWidthFormItem
                  name="additionalInfo"
                  label={t(`${t(selectedTopic?.msg)} ID`)}
                  style={{
                    width: "40%",
                    visibility:
                      selectedTopic && selectedTopic.id !== 8
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <Input placeholder="ID" />
                </FullWidthFormItem>

                <FullWidthFormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={showSuccess}
                  >
                    {t("submit")}
                  </Button>
                </FullWidthFormItem>
                {showSuccess && (
                  <Alert
                    message={t("success")}
                    description={t("formSubmitted")}
                    type="success"
                    style={{ borderRadius: 7 }}
                    showIcon
                  />
                )}
              </FullWidthForm>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              {/* <div
                className="report-form"
                style={{
                  height: "120px",
                  width: "120px",
                  paddingTop: "3rem",
                  paddingLeft: "2rem",
                  paddingBottom: "3rem",
                  paddingRight: "2rem",
                  backgroundImage:
                    "linear-gradient(to right, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.7) 25%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.3) 75%, rgba(0,0,0,0) 100%)",
                }}
              >
                {" "}
              </div> */}
            </Col>
          </Row>
        </div>
      </div>
    </LicenseDataProvider>
  );
};

export default ReportForm;
