import { Button, Col, DatePicker, Form, Row, Table } from "antd";
import { useState } from "react";
import { BarrierPastRecord } from "../../data/barrier/BarrierPastRecord";
import { ColumnsType } from "antd/lib/table";
import { t } from "i18next";
import moment from "moment";
import BarrierService from "../../data/barrier/BarrierService";
import { Barrier } from "../../data/barrier/Barrier";
import { DownloadOutlined } from "@ant-design/icons";
import { BarrierStatus } from "../../data/barrier/BarrierStatus";
import { ExportToXLSX } from "../../utils/ExportToXLSX";

interface Props {
  selectedBarrier: Barrier;
}
const gutter = {
  xs: 16,
  sm: 16,
  md: 24,
  lg: 32,
};
const formLabelSpan = { span: 24 };
const { RangePicker } = DatePicker;
enum BarrierFormFileds {
  "date" = "date",
}

function BarrierPastRecordTable(props: Props) {
  const { selectedBarrier } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<BarrierPastRecord[]>([]);
  const columns: ColumnsType<BarrierPastRecord> = [
    {
      title: `${t("process")}`,
      dataIndex: "process_id",
      key: "process_id",
      render: (value) => {
        return t(BarrierStatus[value]);
      },
    },
    {
      title: `${t("time")}`,
      dataIndex: "ts",
      key: "ts",
      render: (value) => {
        return moment(parseInt(value))
          .utcOffset(localStorage.getItem("timezone")!)
          .format("DD/MM/YYYY HH:mm:ss");
      },
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        moment(parseInt(a.ts)).unix() - moment(parseInt(b.ts)).unix(),
    },
    {
      title: `${t("username")}`,
      dataIndex: "username",
      key: "username",
    },
  ];

  const getPastRecord = async () => {
    try {
      setIsLoading(true);
      let formValue = form.getFieldsValue();
      let startDate = moment(formValue.date[0]).unix();
      let endDate = moment(formValue.date[1]).unix();
      let result = await BarrierService.getBarrierPastRecord({
        barrierId: selectedBarrier.id,
        startTime: startDate * 1000,
        endTime: endDate * 1000,
      });
      setData(result.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const downloadRecord = async () => {
    let convertedData = data.map((item) => {
      let pi = t("process");
      let un = t("username");
      let time = t("time");
      let json: any = {};
      json[pi] = t(BarrierStatus[item.process_id]);
      json[un] = item.username;
      json[time] = moment(parseInt(item.ts))
        .utcOffset(localStorage.getItem("timezone")!)
        .format("DD/MM/YYYY HH:mm:ss");
      return json;
    });
    ExportToXLSX(
      convertedData,
      selectedBarrier.id +
        "-" +
        selectedBarrier.name +
        "_" +
        `${t("past_record")}`
    );
  };

  return (
    <>
      <Row gutter={gutter}>
        <Form layout="inline" form={form} name="barrierPastRecord">
          <Col span={14}>
            <Form.Item
              style={{ fontWeight: "bold" }}
              labelCol={formLabelSpan}
              label={t("time_interval")}
              name={BarrierFormFileds.date}
              initialValue={[
                moment()
                  .utcOffset(localStorage.getItem("timezone")!)
                  .subtract(15, "minutes"),
                moment().utcOffset(localStorage.getItem("timezone")!),
              ]}
            >
              <RangePicker
                defaultValue={[
                  moment()
                    .utcOffset(localStorage.getItem("timezone")!)
                    .subtract(15, "minutes"),
                  moment().utcOffset(localStorage.getItem("timezone")!),
                ]}
                showTime
                format="DD/MM/YYYY HH:mm"
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              style={{ margin: 0 }}
              labelCol={formLabelSpan}
              label={<span></span>}
            >
              <Button
                type="primary"
                form="barrierPastRecord"
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}
                onClick={getPastRecord}
              >
                {t("apply")}
              </Button>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              style={{ margin: 0 }}
              labelCol={formLabelSpan}
              label={<span></span>}
            >
              <Button
                type="primary"
                form="barrierPastRecord"
                disabled={data.length > 0 ? false : true}
                style={{ color: "white", background: "#2d6feb" }}
                icon={<DownloadOutlined />}
                onClick={downloadRecord}
              ></Button>
            </Form.Item>
          </Col>
        </Form>
      </Row>
      <Row gutter={gutter} style={{ marginTop: "10px" }}>
        <Col span={24}>
          {data.length > 0 && (
            <Table
              rowKey={Math.random().toString()}
              style={{ height: "auto", width: "100%" }}
              dataSource={data}
              columns={columns}
              pagination={{ defaultPageSize: 10 }}
              size="small"
              tableLayout="fixed"
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default BarrierPastRecordTable;
