import { useState } from "react";
import { Table, DatePicker, Switch, Row, Col, Button } from "antd";
import { CircleMarker, MapContainer, Tooltip } from "react-leaflet";
import moment from "moment";
import { Incident } from "../../services/IncidentModel";
import { ColumnsType } from "antd/lib/table";
import IncidentService from "../../services/IncidentService";
import MapTileControl from "../../../map/controls/bottom-right-control/MapTileControl";
import { t } from "i18next";
import "../../../error-monitor/components/ErrorTable/styles.css";

const { RangePicker } = DatePicker;

const IncidentTable = () => {
  const [data, setData] = useState<Incident[]>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showMap, setShowMap] = useState(false);

  const fetchData = async () => {
    if (!startDate || !endDate) {
      alert("Lütfen tarih aralığını seçiniz.");
      return;
    }

    setLoading(true);
    try {
      const response = await IncidentService.getHistoricIncidents(
        startDate,
        endDate
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
    setLoading(false);
  };

  const columns: ColumnsType<Incident> = [
    {
      title: `${t("start")} ${t("meter")}`,
      dataIndex: "startMeter",
      key: "startMeter",
    },
    {
      title: `${t("finish")} ${t("meter")}`,
      dataIndex: "endMeter",
      key: "endMeter",
    },
    {
      title: `${t("corridor")} ${t("name")}`,
      dataIndex: "corridorName",
      key: "corridorName",
    },
    {
      title: `${t("corridor_id")}`,
      dataIndex: "corridorId",
      key: "corridorId",
    },
    {
      title: `${t("time")}`,
      dataIndex: "ts",
      key: "ts",
      render: (text) =>
        moment(text)
          .utcOffset(localStorage.getItem("timezone")!)
          .format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  const handleDateChange = (dates: any) => {
    setStartDate(dates ? dates[0] : null);
    setEndDate(dates ? dates[1] : null);
  };

  const handleSwitchChange = (checked: any) => {
    setShowMap(checked);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Row gutter={16} justify="start">
        <Col xs={24} md={8}>
          <RangePicker showTime onChange={handleDateChange} />
        </Col>
        <Col xs={24} md={2}>
          <Button type="primary" onClick={fetchData}>
            {t("approve")}
          </Button>
        </Col>
        <Col xs={24} md={2}>
          <Switch
            checkedChildren={t("map")}
            unCheckedChildren={t("table")}
            onChange={handleSwitchChange}
            style={{ marginLeft: "10px" }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        <Col xs={24}>
          {!showMap ? (
            <div className="error-table">
              <Table
                columns={columns}
                dataSource={data}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 5 }}
              />
            </div>
          ) : (
            <MapContainer
              center={[39.9334, 32.8597]}
              zoom={5}
              style={{ height: "400px", width: "100%" }}
            >
              <MapTileControl />
              {data.map((item, idx) => {
                return (
                  <CircleMarker
                    key={idx}
                    center={[item.latitude, item.longitude]}
                    radius={10}
                    pathOptions={{
                      color: "red",
                      fillColor: "red",
                      fillOpacity: 0.5,
                    }}
                    className="marker-blinking"
                  >
                    <Tooltip sticky permanent={false}>
                      <div
                        style={{
                          fontSize: "14px",
                          padding: "5px",
                          color: "#333",
                        }}
                      >
                        <strong>{t("corridor_id")}:</strong> {item.corridorId}
                        <br />
                        <strong>
                          {t("corridor")} {t("name")}:
                        </strong>{" "}
                        {item.corridorName}
                        <br />
                        <strong>
                          {t("start")} {t("meter")}:
                        </strong>{" "}
                        {item.startMeter}
                        <br />
                        <strong>
                          {t("finish")} {t("meter")}:
                        </strong>{" "}
                        {item.endMeter}
                      </div>
                    </Tooltip>
                  </CircleMarker>
                );
              })}
            </MapContainer>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default IncidentTable;
