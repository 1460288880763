import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import {
    CircleMenu,
    CircleMenuItem,
} from "react-circular-menu";
import { HomeOutlined, NodeExpandOutlined, SubnodeOutlined, TableOutlined, UsergroupAddOutlined, SettingOutlined, PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

const InventoryCrudMenu: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState<string | null>(null);

    const showModal = (content: string) => {
        setModalContent(content);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setModalContent(null);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setModalContent(null);
    };

    const menuItems = [
        { tooltip: "Stok Ekle", icon: <TableOutlined />, content: 'Stok Ekle' },
        { tooltip: "Envanter Tipi Ekle", icon: <NodeExpandOutlined />, content: 'Envanter Tipi Ekle' },
        { tooltip: "Envanter Alt Tipi Ekle", icon: <SubnodeOutlined />, content: 'Envanter Alt Tipi Ekle' },
        { tooltip: "Firma Ekle", icon: <HomeOutlined />, content: 'Firma Ekle' },
        { tooltip: "Zimmet Ekle", icon: <UsergroupAddOutlined />, content: 'Zimmet Ekle' },
        { tooltip: "Operasyon Ekle", icon: <SettingOutlined />, content: 'Operasyon Ekle' }
    ];

    return (
        <>
            <CircleMenu
                startAngle={-30}
                rotationAngle={150}
                itemSize={2}
                radius={5}
                rotationAngleInclusive={false}
                menuToggleElement={<Button size="large" style={{ backgroundColor: 'black', color: 'white', borderColor: 'black' }} icon={<PlusOutlined style={{ color: 'white' }} />} />
            }
            >
                {menuItems.map((item, index) => (
                    <CircleMenuItem
                        key={index}
                        tooltip={item.tooltip}
                        onClick={() => showModal(item.content)}
                    >
                        {item.icon}
                    </CircleMenuItem>
                ))}
            </CircleMenu>
            <Modal title={modalContent} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                {modalContent}
            </Modal>
        </>
    );
};

export default InventoryCrudMenu;
