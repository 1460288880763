import { Row, Col, Button, Modal, Select, TimePicker } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";

// context
import { PlanContext } from "../../context/PlanContext";

import SchedularBlock from "./plan-schedular-components/schedular-block";
import "./plan-schedular.css";

import getPlanColor from "../../common/getPlanColor";
import days from "../../common/days";
import { PlusSquareOutlined } from "@ant-design/icons";
import DayCopyPasteModal from "./plan-schedular-components/DayCopyPasteModal";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export interface ICopyPastePlanModalInformations {
  visible: boolean;
  copyIndex: null | number;
}

const defaultCopyPastePlanModalInfo = {
  visible: false,
  copyIndex: null,
};

interface Props {
  selectedJunction: any;
}

const PlanSchedular: React.FC<Props> = ({selectedJunction}) => {
  const { t } = useTranslation();
  const planContextValues = useContext(PlanContext);
  const [contextSignalPlan, setContextSignalPlan] =
    planContextValues.signalPlan;
  const [locked] = planContextValues.locked;

  // copy paste modal
  const [copyPastePlanModalInfo, setCopyPastePlanModalInfo] =
    useState<ICopyPastePlanModalInformations>(defaultCopyPastePlanModalInfo);

  const clearCopyPastePlanModalInfo = () => {
    setCopyPastePlanModalInfo(defaultCopyPastePlanModalInfo);
  };
  // copy paste modal

  const plans = useMemo(() => {
    return contextSignalPlan?.plans;
  }, [contextSignalPlan]);

  const dailyPlans = useMemo(() => {
    return contextSignalPlan?.dailyPlans;
  }, [contextSignalPlan]);

  const [planOptions, setPlanOptions] = useState<any[]>([]);

  useEffect(() => {
    const obtainedOptions = contextSignalPlan?.plans?.map((plan: any) => {
      return {
        value: plan?.id,
        label: plan?.name,
      };
    });
    setPlanOptions(obtainedOptions);
  }, [contextSignalPlan]);

  interface addPlanModalStateInterface {
    visible: boolean;
    dayIndex: null | number | "all"; // all parameter to add each days
    selectedPlanId: null | number;
    startTime: any;
  }

  const [addPlanModalState, setAddPlanModalState] =
    useState<addPlanModalStateInterface>({
      visible: false,
      dayIndex: null,
      selectedPlanId: null,
      startTime: null,
    });

  const showAddPlanModal = (dayIndex: number | "all") => {
    setAddPlanModalState((prev: any) => ({
      ...prev,
      visible: true,
      dayIndex,
    }));
  };

  const closeAddPlanModal = () => {
    setAddPlanModalState((prev: any) => ({
      ...prev,
      visible: false,
      dayIndex: null,
      selectedPlanId: null,
      startTime: null,
    }));
  };

  const updateSelectedPlanOnAddPlanModal = (value: number) => {
    setAddPlanModalState((prev: any) => ({
      ...prev,
      selectedPlanId: value,
    }));
  };

  const updateStartTimeOfSelectedSchedularPlan = (startTime: string) => {
    setAddPlanModalState((prev: any) => ({
      ...prev,
      startTime,
    }));
  };

  const getSortedDayPlans = (plan: any, dayPlans: any[]) => {
    // sort plan objects according to startTime
    return [...dayPlans, plan].sort((a, b) => {
      if (a.startTime < b.startTime) {
        return -1;
      }
      if (a.startTime > b.startTime) {
        return 1;
      }
      return 0;
    });
  };

  const addPlanToSchedular = () => {
    const dayIndex = addPlanModalState.dayIndex;
    const startTime = addPlanModalState.startTime;
    const planId = addPlanModalState.selectedPlanId;

    if (dayIndex !== null && Array.isArray(contextSignalPlan?.dailyPlans)) {
      const dayPlans = contextSignalPlan?.dailyPlans[dayIndex]?.plans;

      const sortedDayPlans = getSortedDayPlans(
        {
          id: planId,
          planMode: null,
          startTime: startTime,
        },
        dayPlans
      );

      setContextSignalPlan((prev: any) => ({
        ...prev,
        dailyPlans: dailyPlans.map((dayPlans: any, index: number) => {
          if (index === dayIndex) {
            return {
              ...dayPlans,
              plans: sortedDayPlans,
            };
          }
          return dayPlans;
        }),
      }));
    }
  };

  const addPlanToAllDays = () => {
    const startTime = addPlanModalState.startTime;
    const planId = addPlanModalState.selectedPlanId;

    if (Array.isArray(contextSignalPlan?.dailyPlans)) {
      setContextSignalPlan((prev: any) => ({
        ...prev,
        dailyPlans: dailyPlans.map((dayPlans: any) => {
          return {
            ...dayPlans,
            plans: getSortedDayPlans(
              {
                id: planId,
                planMode: null,
                startTime: startTime,
              },
              dayPlans.plans
            ),
          };
        }),
      }));
    }
  };

  return (
    <div
      style={{
        marginBottom: "2rem",
        backgroundColor: "#F3F3F3",
        padding: 2,
        boxShadow: "1px 2px 9px #d3d3d3",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        {!locked && (
          <Button
            type="primary"
            onClick={() => {
              showAddPlanModal("all");
            }}
          >
            <PlusSquareOutlined />
            <span>{ t("add_plan_to_all_days") }</span>
          </Button>
        )}
      </div>

      <Row gutter={[2, 4]}>
        {days.map((day: any, index: number) => (
          <Col flex={1} key={`schedular-${day}`}>
            <Row className="schedular__plans-container">
              <SchedularBlock
                dayTitle={t(days[index])}
                key={`day-${days[index]}`}
                copyPasteDay={setCopyPastePlanModalInfo}
                dayIndex={index}
                selectedJunction={selectedJunction}
              />
              {dailyPlans[index]?.plans.map(
                (dayPlan: any, dayPlanIndex: number) => {
                  const matchingPlan = plans.find(
                    (plan: any) => plan.id === dayPlan.id
                  );
                  return (
                    <SchedularBlock
                      planName={matchingPlan?.name}
                      color={getPlanColor(matchingPlan)}
                      startTime={dayPlan?.startTime}
                      planMode={dayPlan.planMode}
                      dayIndex={index}
                      signalIndexOfDayColumn={dayPlanIndex}
                      key={`schedular-${day}-${matchingPlan?.name}-index${dayPlanIndex}`}
                      selectedJunction={selectedJunction}
                    />
                  );
                }
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {!locked && (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      showAddPlanModal(index);
                    }}
                  >
                    <PlusSquareOutlined />
                    <span>{ t("add_plan") }</span>
                  </Button>
                )}
              </div>
            </Row>
          </Col>
        ))}
      </Row>

      {addPlanModalState.visible && (
        <Modal
          title={t("add_plan")}
          visible={addPlanModalState.visible}
          onOk={() => {
            if (typeof addPlanModalState.dayIndex === "number") {
              addPlanToSchedular();
            } else if (addPlanModalState.dayIndex === "all") {
              // if dayIndex is set as "all", then set all days
              addPlanToAllDays();
            }

            closeAddPlanModal();
          }}
          onCancel={() => {
            closeAddPlanModal();
          }}
        >
          <Row>
            <Col span={12}>
              <Select
                style={{ width: "100%" }}
                placeholder={t("select_plan")}
                onChange={(value) => {
                  updateSelectedPlanOnAddPlanModal(value);
                }}
              >
                {planOptions.map(({ value, label }: any) => {
                  return (
                    <Option value={value} key={`plan-option-${value}`}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={12}>
              <TimePicker
                format={"HH:mm"}
                placeholder={t("select_time")}
                onChange={(time: any) => {
                  const date = time._d;
                  const hourMinuteSecond = date.toLocaleTimeString("tr-TR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                  updateStartTimeOfSelectedSchedularPlan(hourMinuteSecond);
                }}
                showNow={false}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </Modal>
      )}

      {copyPastePlanModalInfo.visible && (
        <DayCopyPasteModal
          {...copyPastePlanModalInfo}
          clearModal={clearCopyPastePlanModalInfo}
        />
      )}
    </div>
  );
};

export default PlanSchedular;
