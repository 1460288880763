import React, { useContext } from "react";
import { Transfer, Timeline, Row, Col } from "antd";
import { MultiModePlanContext } from "../../../context/MultiModePlanContext";
import { PhaseType } from "..";
import { useTranslation } from "react-i18next";

interface Props {
  selectedPhases: any;
  setSelectedPhases: any;
  checkedPhasesBeforeSelected: any;
  setCheckedPhasesBeforeSelected: any;
}

const PhaseSelection: React.FC<Props> = ({
  checkedPhasesBeforeSelected,
  setCheckedPhasesBeforeSelected,
  selectedPhases,
  setSelectedPhases,
}) => {
  const { t } = useTranslation();
  const planContextValues = useContext(MultiModePlanContext);
  const phases: PhaseType[] = planContextValues?.juncPhases;

  const filterOption = (inputValue: string, option: PhaseType) => {
    return option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  };

  const handleTransferChanges = (
    targetKeys: string[],
    direction: "left" | "right",
    moveKeys: string[]
  ) => {
    if (direction === "right") {
      setSelectedPhases((prevSelectedPhases: any) => [
        ...prevSelectedPhases,
        ...moveKeys,
      ]);
    } else if (direction === "left") {
      setSelectedPhases((prevSelectedPhases: any) =>
        getSelectedPhasesAfterDelete(prevSelectedPhases, moveKeys)
      );
    }
  };

  const getSelectedPhasesAfterDelete = (
    selectedPhasesBeforeUpdate: string[],
    moveKeys: string[]
  ) => {
    moveKeys.forEach((moveKey) => {
      selectedPhasesBeforeUpdate = selectedPhasesBeforeUpdate.filter(
        (selectedPhase) => selectedPhase !== moveKey
      );
    });

    return selectedPhasesBeforeUpdate;
  };

  const onSelectChange = (checkedPhases: any) => {
    setCheckedPhasesBeforeSelected(checkedPhases);
  };

  return (
    <>
      <Row gutter={[30, 50]}>
        <Col lg={checkedPhasesBeforeSelected.length ? 18 : 24} xs={24}>
          <Transfer
            dataSource={phases}
            showSearch
            filterOption={filterOption}
            targetKeys={selectedPhases}
            onChange={handleTransferChanges}
            render={(item: any) => {
              return `(${item?.internal_id}) ${item?.name}`;
            }}
            showSelectAll={false}
            onSelectChange={onSelectChange}
            selectedKeys={checkedPhasesBeforeSelected}
            titles={[t("phases"), t("list_of_selected_phases")]}
            oneWay
            listStyle={{
              width: "100%",
              overflow: "hidden",
            }}
            rowKey={(phase: any) => phase?.internal_id}
          />
        </Col>
        {checkedPhasesBeforeSelected.length > 0 && (
          <Col lg={6} xs={24}>
            <Timeline>
              {checkedPhasesBeforeSelected.map(
                (checkedPhase: any, index: any) => (
                  <Timeline.Item key={`checked-phase-key-${index}`}>
                    {
                      phases?.filter(
                        ({ internal_id }: PhaseType) =>
                          internal_id === checkedPhase
                      )[0]?.name
                    }
                  </Timeline.Item>
                )
              )}
            </Timeline>
          </Col>
        )}
      </Row>
    </>
  );
};

export default PhaseSelection;
