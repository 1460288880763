import { useEffect, useMemo, useState } from "react";
import { LeafletMouseEvent } from "leaflet";
import { Marker, Tooltip, useMap } from "react-leaflet";
import withLogging from "../../high-order-components/LoggerHOC";
import GenericMapModal from "../../components/generic/map-modal/GenericMapModal";
import L from "leaflet";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import { t } from "i18next";
import GenericMarkerDeleteModal from "../../components/generic/marker-context-modal/GenericMarkerDeleteModal";
import MarkerGenericAddUpdateContent from "../../components/generic/marker-context-modal/MarkerGenericAddUpdateContent";
import { MapModule } from "@icms/ui-components"
import { Barrier } from "../../../../data/barrier/Barrier";
import BarrierModalTitle from "./BarrierModalTitle";
import BarrierService from "../../../../data/barrier/BarrierService";
import { useBarrierData } from "../../../../contexts/barrier/BarrierDataContext";
import { BarrierModel } from "../../components/generic/marker-context-modal/model/Barrier";
import { barrierIconBase64 } from "../../map-constants/static/MarkerIconBase64";
import BarrierMain from "../../../barrier";

function BarrierLayer() {
  const { barrierList, updateAllBarier } = useBarrierData();
  const { setLayerData } = useCustomLayerData();
  const [markerData, setMarkerData] = useState<Barrier[]>([]);
  const [selectedBarrier, setSelecteBarrier] = useState<Barrier>();
  const [barrier, setBarrier] = useState<Barrier>();

  const map = useMap();

  const markerHandlers = (barrier: Barrier) => {
    return {
      dblclick: (event: LeafletMouseEvent) =>
        handleMarkerDblClick(event, barrier),
      contextmenu: (event: LeafletMouseEvent) => {
        map.contextmenu.removeAllItems();
        map.contextmenu.addItem({
          text: t("update"),
          callback: () => updateBarrier(barrier),
        });
        map.contextmenu.addItem({
          text: t("delete"),
          callback: () => deleteBarrier(barrier),
        });
      },
    };
  };

  const updateBarrier = (barrier: Barrier) => {
    setBarrier(barrier);
  };

  const deleteBarrier = async (barrier: Barrier) => {
    GenericMarkerDeleteModal(barrier.name, async () => {
      await BarrierService.deleteBarrier(barrier.id);
      updateAllBarier();
    });
  };

  const handleMarkerDblClick = (event: LeafletMouseEvent, barrier: Barrier) => {
    setSelecteBarrier(barrier);
  };

  const onCloseModal = () => {
    setSelecteBarrier(undefined);
    setBarrier(undefined);
  };

  useEffect(() => {
    setLayerData({
      id: MapModule.BarrierModule,
      data: barrierList,
      searchable: {
        category: "barrier",
        filterProperties: ["id", "name"],
        labelProperty: "name",
      },
    });
    setMarkerData(barrierList);
  }, [barrierList]);

  const modalContent = useMemo(() => {
    return (
      <>
        {selectedBarrier && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalWidth="35%"
            modalTitle={
              <BarrierModalTitle
                componentName="BarrierModalTitle"
                selectedBarrier={selectedBarrier}
              />
            }
          >
            <BarrierMain selectedBarrier={selectedBarrier} />
          </GenericMapModal>
        )}
      </>
    );
  }, [selectedBarrier]);

  const addUpdateModalContent = useMemo(() => {
    return (
      <>
        {barrier && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <BarrierModalTitle
                componentName="BarrierModalTitle"
                selectedBarrier={barrier}
              />
            }
          >
            <MarkerGenericAddUpdateContent
              modalType="update"
              model={BarrierModel}
              setModalVisible={onCloseModal}
              icon={barrierIconBase64}
              latlon={new L.LatLng(barrier.latitude, barrier.longitude)}
              triggerStateFunc={updateAllBarier}
              formData={barrier}
            />
          </GenericMapModal>
        )}
      </>
    );
  }, [barrier]);

  useEffect(() => {
    let updatedBarrier;
    if (selectedBarrier) {
      updatedBarrier = barrierList.find(
        (item) => item.id === selectedBarrier.id
      );
    }
    if (updatedBarrier) {
      setSelecteBarrier(updatedBarrier);
    }
  }, [barrierList]);

  return (
    <>
      {markerData.length > 0 &&
        markerData.map((barrier) => {
          return (
            <Marker
              key={barrier.id}
              position={[barrier.latitude, barrier.longitude]}
              icon={L.divIcon({
                html: `<div style="border: 3px solid ${
                  barrier.status ? "#68F973" : "#F97768"
                }; background-color: #ffffff; width: 34px; height: 34px; border-radius: 50%; display: flex; justify-content: center; align-items: center; box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.4);"><img src="${
                  barrier.status
                    ? "/static/barrier/barrier-open.png"
                    : "/static/barrier/barrier-closed.png"
                }" alt="Custom Icon" style="width: 16px; height: 16px;"/></div>`,
                iconAnchor: [18, 11],
              })}
              eventHandlers={markerHandlers(barrier)}
              contextmenu={true}
            >
              <Tooltip>{barrier.name}</Tooltip>
            </Marker>
          );
        })}
      {modalContent}
      {addUpdateModalContent}
    </>
  );
}

export default withLogging(BarrierLayer);
