import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { ModulesAssigned } from "../../data/user/User";
import UserService from "../../data/user/UserService";

interface UserInfoProps {
  assignedModules: ModulesAssigned[];
}

const UserInfoContext = createContext<UserInfoProps | undefined>(undefined);

export function UserInfoProvider({ children }: PropsWithChildren<{}>) {
  const [assignedModules, setAssignedModules] = useState<ModulesAssigned[]>([]);

  const getAssignedModules = () => {
    UserService.getModulesAssignedToUser()
      .then((res) => {
        setAssignedModules(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAssignedModules();
  }, []);

  return (
    <UserInfoContext.Provider
      value={{
        assignedModules: assignedModules,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
}

export function useUserInfo() {
  const context = useContext(UserInfoContext);
  if (context === undefined) {
    return {
      assignedModules: [],
    };
    //throw new Error("useUserInfo must be used within a UserInfoContext");
  }
  return context;
}
