import { Pagination } from "antd";

interface PaginationProps {
  pageNumber: number;
  totalPages: number;
  pageLimit?: number;
  setterMethod: (selectedPageNumber: number) => void;
}

const PaginationComponent: React.FC<PaginationProps> = ({
  pageNumber,
  totalPages,
  pageLimit = 10,
  setterMethod,
}) => {
  return (
    <>
      {pageNumber > 0 && pageNumber && (
        <Pagination
          className="ant-pagination ant-table-pagination ant-table-pagination-right"
          current={pageNumber}
          pageSize={pageLimit}
          total={totalPages * pageLimit}
          showSizeChanger={false}
          onChange={(newPageNumber: number) => {
            setterMethod(newPageNumber);
          }}
        />
      )}
    </>
  );
};

export default PaginationComponent;
