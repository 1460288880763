import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";
import { IconType } from "../../data/toaster-notification/ToasterIconEnum";

export interface ToasterMessageModel {
  title: string;
  description: string;
  ts: number;
  icon?: IconType;
  design?: {
    titleColor?: string;
    backgroundColor?: string;
    iconColor?: string;
    descriptionColor?: string;
  };
  duration?: number;
  placement?: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
}

export interface ToasterContextState {
  toasterMessage: ToasterMessageModel | undefined | null;
}

const ToasterContext = createContext<ToasterContextState | undefined>(
  undefined
);

export function ToasterProvider({ children }: PropsWithChildren<{}>) {
  const [toasterMessage, setToasterMessage] = useState<ToasterMessageModel>();

  useEffect(() => {
    socket.on("toasterMessage", (data: any) => {
      try {
        setToasterMessage(data);
      } catch (err) {
        console.log("Socket Error (toasterMessage)", err);
      }
    });

    return () => {
      socket.off("toasterMessage");
    };
  }, []);

  return (
    <ToasterContext.Provider
      value={{
        toasterMessage: toasterMessage,
      }}
    >
      {children}
    </ToasterContext.Provider>
  );
}

export function useToaster() {
  const context = useContext(ToasterContext);
  if (context === undefined) {
    return {
      toasterMessage: null,
    };
    // throw new Error("useToaster must be used within a ToasterContext");
  }
  return context;
}
