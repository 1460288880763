import { useEffect, useMemo, useState } from "react";
import { LeafletMouseEvent } from "leaflet";
import { Marker, Tooltip, useMap, useMapEvent } from "react-leaflet";
import withLogging from "../../high-order-components/LoggerHOC";
import GenericMapModal from "../../components/generic/map-modal/GenericMapModal";
import {
  JunctionDetector,
  detectorIcons,
} from "../../../../data/junction-detector/JunctionDetector";
import VieroModalTitle from "./VieroModalTitle";
import VieroDeviceMain from "../../../viero-device";
import L from "leaflet";
import { useVieroDeviceData } from "../../../../contexts/viero/VieroDeviceDataContext";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import GenericMarkerDeleteModal from "../../components/generic/marker-context-modal/GenericMarkerDeleteModal";
import { t } from "i18next";
import MarkerGenericAddUpdateContent from "../../components/generic/marker-context-modal/MarkerGenericAddUpdateContent";
import DeviceService from "../../../../data/device/DeviceService";
import { useMultipleWatchData } from "../../../../contexts/map/MultipleWatchContext";
import { MapModule } from "@icms/ui-components"
import { vieroModel } from "../../components/generic/marker-context-modal/model/Viero";
import { useVieroStatusData } from "../../../../contexts/viero/VieroStatusDataContext";

function VieroLayer() {
  const { vieroStatusData } = useVieroStatusData();
  const { vieroDeviceList, updateAllViero } = useVieroDeviceData();
  const { setLayerData } = useCustomLayerData();
  const { addSelectedItem } = useMultipleWatchData();
  const [markerData, setMarkerData] = useState<JunctionDetector[]>([]);
  const [selectedViero, setSelectedViero] = useState<JunctionDetector>();
  const [viero, setViero] = useState<JunctionDetector>();

  const map = useMap();

  const markerHandlers = (viero: JunctionDetector) => {
    return {
      click: (event: LeafletMouseEvent) =>
        addSelectedItem(event, viero, "viero"),
      dblclick: (event: LeafletMouseEvent) =>
        handleMarkerDblClick(event, viero),
      contextmenu: (event: LeafletMouseEvent) => handleMarkerContextMenu(viero),
    };
  };

  const handleMarkerDblClick = (
    event: LeafletMouseEvent,
    viero: JunctionDetector
  ) => {
    setSelectedViero(viero);
  };

  const handleMarkerContextMenu = (viero: JunctionDetector) => {
    map.contextmenu.removeAllItems();
    map.contextmenu.addItem({
      text: t("update"),
      callback: () => updateViero(viero),
    });
    map.contextmenu.addItem({
      text: t("delete"),
      callback: () => deleteViero(viero),
    });
  };

  const updateViero = (viero: JunctionDetector) => {
    setViero(viero);
  };

  const deleteViero = (viero: JunctionDetector) => {
    GenericMarkerDeleteModal(viero.name, async () => {
      await DeviceService.deleteDevice(viero.id);
      updateAllViero();
    });
  };

  const onCloseModal = () => {
    setViero(undefined);
    setSelectedViero(undefined);
  };

  useEffect(() => {
    setLayerData({
      id: MapModule.VieroModule,
      data: vieroDeviceList,
      searchable: {
        category: "viero_management",
        filterProperties: ["id", "name"],
        labelProperty: "name",
      },
      legend: {
        countProperty: "devicetype_id",
        setOnMapData: setMarkerData,
      },
    });
    setMarkerData(vieroDeviceList);
  }, [vieroDeviceList]);



  useEffect(() => {
    if(vieroStatusData){
      let fiindedViero = markerData.find(
        (item) =>
          item.id_device ==vieroStatusData?.vieroId
      );
      if (fiindedViero) {
        fiindedViero.status_id =vieroStatusData.statusId;
        setMarkerData((prev) =>
          prev.map((item) =>
            item.id === fiindedViero?.id ? fiindedViero : item
          )
        );
      }
    }
  }, [vieroStatusData]);

  const modalContent = useMemo(() => {
    return (
      <>
        {selectedViero && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <VieroModalTitle
                componentName="VieroModalTitle"
                selectedViero={selectedViero}
              />
            }
            modalWidth="90%"
          >
            <VieroDeviceMain
              selectedVieroDevice={selectedViero}
              selectedTab="1"
            />
          </GenericMapModal>
        )}
      </>
    );
  }, [selectedViero]);

  const addUpdateModalContent = useMemo(() => {
  
    return (
      <>
        {viero && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <VieroModalTitle
                componentName="VieroModalTitle"
                selectedViero={viero}
              />
            }
          >
            <MarkerGenericAddUpdateContent
              modalType="update"
              model={vieroModel}
              setModalVisible={onCloseModal}
              icon={detectorIcons[viero.devicetype_id].base64!}
              latlon={new L.LatLng(viero.latitude, viero.longitude)}
              triggerStateFunc={updateAllViero}
              formData={viero}
            />
          </GenericMapModal>
        )}
      </>
    );
  }, [viero]);
  return (
    <>
      {markerData.length > 0 &&
        markerData.map((viero) => {
          return (
            <Marker
              key={viero.id}
              position={[viero.latitude, viero.longitude]}
              icon={viero.status_id==2&&[10,11,9].includes(viero.devicetype_id)?detectorIcons[viero.devicetype_id].markerp:detectorIcons[viero.devicetype_id].marker}
              eventHandlers={markerHandlers(viero)}
              contextmenu={true}
            >
              <Tooltip>{viero.name}</Tooltip>
            </Marker>
          );
        })}
      {modalContent}
      {addUpdateModalContent}
    </>
  );
}

export default withLogging(VieroLayer);
