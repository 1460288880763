import CustomButton from "../../components/custom-styled/button/CustomButton";
import { MenuOutlined } from "@ant-design/icons";

interface MenuControlProps {
  setLeftSiderVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

function MenuControl(props: MenuControlProps) {
  const { setLeftSiderVisible } = props;

  return (
    <CustomButton
      icon={<MenuOutlined color="white" />}
      color="#2f4050"
      css="margin:0px;"
      onClick={() => setLeftSiderVisible((prev) => !prev)}
    />
  );
}

export default MenuControl;
