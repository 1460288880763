import { useEffect, useState } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import { Icon } from "leaflet";
import {
  iconExpression,
  markerIcons,
} from "../../data/junction-group/JunctionGroup";
import {
  detectorIcons,
  iconExpressionDetector,
} from "../../data/junction-detector/JunctionDetector";
import { Modal, message } from "antd";
import { t } from "i18next";

function GroupandDetectorEditor({
  groupLatLonInfo,
  isVisibleToolbar,
  angleValue,
  groupNameInfo,
  setAngleValue,
  setSelectedGroup,
  setSelectedDetector,
  setBindedGroup,
  setBindedDetector,
}: any) {
  const context = useLeafletContext();

  const leafletContainer: any = context.layerContainer || context.map;

  const angleVal = angleValue;

  const [marker, setMarker] = useState<any>();

  useEffect(() => {
    if (!isVisibleToolbar) {
      leafletContainer.pm.removeControls();
    } else {
      leafletContainer.pm.addControls({
        position: "bottomright",
        drawText: false,
        drawMarker: false,
        drawRectangle: false,
        drawPolygon: false,
        drawPolyline: false,
        drawCircle: false,
        drawCircleMarker: false,
        dragMode: false,
        editMode: true,
        rotateMode: false,
        cutPolygon: false,
      });
    }
    const lng = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en";
    leafletContainer.pm.setLang(lng);
    leafletContainer.pm.enableGlobalEditMode({
      preventMarkerRemoval: true, // when right click prevent directly remove feature.
    });
  }, [isVisibleToolbar, leafletContainer]);

  useEffect(() => {
    const insertMarkersToToolbar = (icons: iconExpression[]) => {
      icons.forEach((icon) => {
        leafletContainer.pm.Toolbar.copyDrawControl("drawMarker", {
          name: icon.name,
          title: icon.title,
          className: icon.className,
        }).drawInstance.setOptions({
          snapDistance: 0,
          markerStyle: {
            icon: new Icon({
              iconUrl: `data:image/jpeg;base64,${icon.base64}`,
              iconSize: [20, 30],
            }),
          },
        });
      });
    };
    const insertDetectorMarkersToToolbar = (
      icons: iconExpressionDetector[]
    ) => {
      icons.forEach((icon) => {
        leafletContainer.pm.Toolbar.copyDrawControl("drawMarker", {
          name: icon.name,
          title: icon.title,
          className: icon.className,
        }).drawInstance.setOptions({
          snapDistance: 0,
          markerStyle: {
            icon: new Icon({
              iconUrl: `data:image/jpeg;base64,${icon.base64}`,
              iconSize: [30, 32],
            }),
          },
        });
      });
    };

    insertMarkersToToolbar(Object.values(markerIcons));
    insertDetectorMarkersToToolbar(Object.values(detectorIcons));
    leafletContainer.pm.setGlobalOptions({ pmIgnore: true, snappable: false });

    const afterShapeCreation = (e: any, layer: any) => {
      setMarker(e.layer);
      groupLatLonInfo({ layer });
      setAngleValue(0);
      groupNameInfo(e.shape);
      setSelectedGroup(undefined);
      setSelectedDetector(undefined);
      setBindedGroup(undefined);
      setBindedDetector(undefined);
      leafletContainer.pm.enableGlobalEditMode({
        preventMarkerRemoval: true, // when right click prevent directly remove feature.
      });
      e.layer.on("click", () => {
        setMarker(e.layer);
        groupLatLonInfo({ layer });
        setSelectedGroup(undefined);
        setSelectedDetector(undefined);
        setBindedGroup(undefined);
        setBindedDetector(undefined);
      });
    };

    leafletContainer.on("pm:create", (e: any) => {
      let layer;
      layer = e.layer;
      afterShapeCreation(e, layer);

      if (e.layer || e.layer.pm) {
        const shape = e;
        groupLatLonInfo(e);
        shape.layer.pm.disable();

        leafletContainer.pm.getGeomanLayers(true);

        leafletContainer.pm
          .getGeomanLayers()
          .map((layer: any, index: any) => layer);
        shape.layer.on("pm:edit", (e: any) => {
          groupLatLonInfo(e);
          setMarker(shape.layer);
        });
      }
    });

    leafletContainer.on("pm:drawstart", (e: any) => {
      message.info(
        t("ensure_that_editor")
      );
    });

    leafletContainer.on("pm:remove", (e: any) => {
      groupLatLonInfo();
    });

    return () => {
      leafletContainer.pm.removeControls();
      leafletContainer.pm.setGlobalOptions({
        pmIgnore: true,
        snappable: false,
      });
    };
  }, [context]);

  useEffect(() => {
    if (marker) {
      marker.setRotationOrigin("center");
      marker.setRotationAngle(angleVal);
    }
  }, [angleVal, marker]);

  return null;
}

export default GroupandDetectorEditor;
