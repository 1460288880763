import { Tabs } from "antd";
import { t } from "i18next";
import { Inventory } from "../../data/inventory/Inventory";
import InventoryInformation from "./InventoryInformation";
import InventoryTasks from "./InventoryTasks";
import InventoryFiles from "./InventoryFiles";

const { TabPane } = Tabs;
interface Props {
  selectedInventory: Inventory;
}

const InventoryModalMain = (props: Props) => {
  const { selectedInventory } = props;
  return (
    <Tabs destroyInactiveTabPane>
      <TabPane tab={t("details")} key="1">
        <InventoryInformation selectedInventory={selectedInventory} />
      </TabPane>
      <TabPane tab={t("tasks")} key="2">
        <InventoryTasks />
      </TabPane>
      <TabPane tab={t("files")} key="3">
        <InventoryFiles selectedInventory={selectedInventory} />
      </TabPane>
    </Tabs>
  );
};

export default InventoryModalMain;
