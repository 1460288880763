import styled from "styled-components";

export const MenuContainer = styled.div`
  width: 20%;
`;

export const LegendContainer = styled.div`
  max-width: 400px;
`;

export const WatchContainer = styled.div`
  max-width: 300px;
`;

export const GroupTitle = styled.span`
  font-weight: 500;
  opacity: 0.5;
  color: black;
  font-size: 18px;
`;
