import {useState, useEffect ,useRef} from 'react';
//import Header from '/components/header';
//import {useWSContext} from '../../service/WebSocket'
import svgEngine from './engine.js'
import Timelines from './timeLines.js'
import Grouplines from './groupLines.js'
import ConfigPop from './configPop.js'
import PopupGroup from './popupGroup.js'
import { useSsmData } from "../../../../contexts/junction/SsmDataContext";
import { phaseDataModel, usePhaseData } from "../../../../contexts/junction/PhaseDataContext";
import JunctionPhaseService from "../../../../data/junction-phase/JunctionPhaseService";
import "./TimeLineChart.css";


const SsmPlot=({selectedJunction})=>{
  return (
    <TimeLineChart GroupLength={5} selectedJunction={selectedJunction}/>
  )
}

const TimeLineChart = ({TimeRangeSecond=60,GroupHeight=15,GroupLength=4,GroupPad=2,selectedJunction}) => {
  const [config, setConfig] = useState({
    ResolutionX: 50+(TimeRangeSecond*10),
    ResolutionY: (GroupLength*(GroupHeight+GroupPad))+25+24,
    TimeRangeSecond: TimeRangeSecond,
    GroupLength: GroupLength,
    OffsetX: 25,
    OffsetY: 25,
    GroupHeight: GroupHeight,
    GroupPad: GroupPad,
    ShowGrid:true
  });

  const [SSMArray, setSSMArray] = useState([]);
  const [PHASEArray, setPHASEArray] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const { ssmData } = useSsmData();
  const { phaseData } = usePhaseData();
  const [timeTrigger, setTimeTrigger] = useState(new Date());
  const [SSMBar, setSSMBar] = useState([]);
  const [phaseLine, setPhaseLine] = useState([]);
  const [showPopup, setShowPopup] = useState(true); 
  const configRef = useRef(config); 
  const SSMArrayRef = useRef(SSMArray);
  const PHASEArrayRef = useRef(PHASEArray); 
  const [hoverGroup, setHoverGroup] = useState(null);
  const [isHover, setIsHover] = useState(null);

  const [scaleValue, setScaleValue] = useState(1);
  const divRef = useRef(null);
  const svgRef = useRef(null);

  const [phaseList, setPhaseList] = useState([]);
  const [activeGroups, setActiveGroups] = useState([]);

    const exportToPNG = () => {
      const svg = svgRef.current;
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
  
      const svgWidth = config?.ResolutionX + 100;
      const svgHeight = config?.ResolutionY + 100;
  
      canvas.width = svgWidth;
      canvas.height = svgHeight;
  
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        const pngData = canvas.toDataURL('image/png');
  
        const link = document.createElement('a');
        link.href = pngData;
        link.download = 'exported-image.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
  
      img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    };
    useEffect(() => {
      const updateScale = () => {
        const roundDownToNearest10 = (num) => {
          return Math.floor(num / 10) * 10;
        };
        if (divRef.current) {
          const divWidth = divRef.current.offsetWidth;
          const divHeight = divRef.current.offsetHeight;
          const svgWidth = config?.ResolutionX + 50;
          const svgHeight = config?.ResolutionY + 50;
  
          const scaleX = divWidth / svgWidth;
          const scaleY = divHeight / svgHeight;
          const newScaleValue = Math.min(scaleX, scaleY);
  
          setScaleValue(newScaleValue);

          TimeRangeSecond=roundDownToNearest10((divWidth/10)-5);
          setConfig({
            ResolutionX: 50+(TimeRangeSecond*10),
            ResolutionY: (GroupLength*(GroupHeight+GroupPad))+25+24,
            TimeRangeSecond: TimeRangeSecond,
            GroupLength:GroupLength,
            OffsetX: 25,
            OffsetY: 25,
            GroupHeight: GroupHeight,
            GroupPad: GroupPad,
            ShowGrid:true
          });
        }
      };
  
      updateScale();
      window.addEventListener('resize', updateScale);
  
      return () => {
        window.removeEventListener('resize', updateScale);
      };
    }, []);

    

    useEffect(() => { 
      if(ssmData==undefined || ssmData==null || ssmData.length==0)
        return;
      setSSMArray(prevItems => {
        let updatedItems = [ssmData[0],...prevItems];      
        if (updatedItems.length > 150) {
          //console.log('pop')
          updatedItems.pop();
        }
        return updatedItems;
      });
    }, [ssmData]);

    useEffect(() => { 
      let rvrs=JSON.parse(JSON.stringify(phaseData))
      if(phaseData==undefined || phaseData==null || phaseData.length==0)
        return;

      setPHASEArray([...rvrs].reverse());  
      if(phaseList.length>0){
        let p=phaseList.find((phase) =>
          phase.internal_id==rvrs[rvrs.length-1].newPhaseId
        )
        setActiveGroups(p.groupsinternal)
        //console.log(p.groupsinternal)
      }
        
    }, [phaseData]);


    useEffect(()=>{
      //console.log(selectedJunction)
       JunctionPhaseService.get(parseInt(selectedJunction.id)).then((res) => {
        //console.log(res.data);
        setPhaseList(res.data);
        //setRelatedJunctionPhaseList(list);
      });
    }, [])

    useEffect(() => { 
      oneSec(configRef.current)
      let intervalID=setInterval(oneSec, 1000); 
      return () => clearInterval(intervalID);     
    }, []);

    const oneSec=()=>{
      const now=new Date();
      setTimeTrigger(now);
      let phase=svgEngine.dataTriggerPhase(PHASEArrayRef.current,now,configRef.current,setConfig)
      setPhaseLine(phase);
      let a=svgEngine.timeTriggerSSM(SSMArrayRef.current,now,configRef.current,setConfig)
      setSSMBar(a);
    }

    useEffect(() => {
      configRef.current = config;
    }, [config]);

    useEffect(() => {
      SSMArrayRef.current = SSMArray;
    }, [SSMArray]);

    useEffect(() => {
      PHASEArrayRef.current = PHASEArray;
    }, [PHASEArray]);

    const handleMouseMove = (e) => {
      const svg = e.target.closest('svg');
      if (svg) {
        const rect = svg.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setMousePosition({ x, y });
      }
    };
  //     <ConfigPop config={config} setConfig={setConfig}/>
  //style={{
   // transform: `scale(${scaleValue})`,
   // transformOrigin: 'left top' // SVG'nin ortalanmasını sağlar
  //}}
    return (
              <div  style={{ justifyContent: 'center', alignItems: 'center'}} ref={divRef}>
                  <svg xmlns="http://www.w3.org/2000/svg"  
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}  
                    onMouseMove={handleMouseMove} 
                    width={config?.ResolutionX+20} 
                    height={config?.ResolutionY+20} 
            
                    ref={svgRef}
                    onClick={exportToPNG} // SVG tıklanınca PNG export
                    fill="none"> 
                     <rect  x={0} y={0} width={config?.ResolutionX} height={config?.ResolutionY} fill="white"></rect>
                        <Grouplines config={config} timeTrigger={timeTrigger} activeGroups={activeGroups}/>
                  
            
                      
                      <Timelines config={config} timeTrigger={timeTrigger}/>
                      <rect key={"colorfitlr"} x={config?.OffsetX-1} y={config?.OffsetY-2} width={config?.ResolutionX-(config?.OffsetX*2-2)} height={config?.ResolutionY-((config?.OffsetY*2)-3)} stroke="black" strokeWidth="1"/>
                        <defs>
                          <linearGradient id="mygradient" x1="0%" y1="0%" x2="0%" y2="100%">
                              <stop offset="0%" stopColor="#ff0000" />
                              <stop offset="49%" stopColor="#ff0000" />
                              <stop offset="50%" stopColor="#ffff00" />
                              <stop offset="100%" stopColor="#ffff00" />
                          </linearGradient>
                        </defs>
                 
                                
                      {SSMBar?.filter(obj => obj!=null).map((obj, index) => (
                          <rect key={"ssmbr"+index} x={config?.OffsetX+obj.x} y={config?.OffsetY+obj.y}  width={obj.width}  height={obj.height} fill={ obj.color}/>                
                      ))}

                      {SSMBar?.filter(obj => obj!=null && obj.color=="#009000"&& obj.info.sec>4 && obj.x!=0 ).map((obj, index) => (
                            <text
                            key={index+"kettphase"}
                            x={config?.OffsetX+obj.x+15}
                            y={config?.OffsetY+obj.y+12} 
                            fontFamily="Roboto,Arial, Helvetica, sans-serif"
                            fill="#000"
                            fontWeight="700"
                            fontSize="10">
                                {Math.round(obj.info.sec)} sn
                          </text>
                      ))}

                      {phaseLine?.filter(obj => obj!=null).map((obj, index,array) => (
                        <>
                            <line key={index+'lnphase'} strokeWidth="2px" stroke={"#09F"}  
                              id={index}
                              x1={config?.OffsetX+obj.x} 
                              y1={2} 
                              x2={config?.OffsetX+obj.x} 
                              y2={config.ResolutionY-config?.OffsetY} 
                            />  
                            
                            <text
                              key={index+"kettphase"}
                              x={config?.OffsetX+obj.x+15} 
                              y={10}
                              fontFamily="Roboto,Arial, Helvetica, sans-serif"
                              fill="#666"
                              fontWeight="700"
                              fontSize="10">

                                  <tspan x={config?.OffsetX + obj.x + 10} dy="0">
                                      {"Phase: " + obj.newPhaseId}
                                  </tspan>

                            </text>

                            <text
                              key={index+"kettphase2"}
                              x={config?.OffsetX+obj.x+15} 
                              y={9}
                              fontFamily="Roboto,Arial, Helvetica, sans-serif"
                              fill="#09F"
                              fontWeight="700"
                              fontSize="10">

                                  <tspan x={config?.OffsetX + obj.x + 10} dy="1.1em">
                                      {array[index - 1] !== undefined ? "GreenTime: " + array[index - 1].greenTime : ""}
                                  </tspan>
                            </text>
                          </>
                      ))}

                    <PopupGroup mousePosition={mousePosition} groupInfo={hoverGroup} config={config} timeTrigger={timeTrigger} isHover={isHover}/>
                      

                  </svg> 

        </div>
    );
};

export default SsmPlot;
