import { Typography } from "antd";
import { useEffect, useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { usePhaseData,durationDataModel } from "../../../contexts/junction/PhaseDataContext";

const { Text } = Typography;

function CountDownTool() {
  const {durationData } = usePhaseData();
  const [data, setData] = useState<durationDataModel>();


  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
   setData(durationData)
  }, [durationData]);

  if(data === undefined){
    return null;
  }
  return (

    <div
      style={{
        position: "absolute",
        bottom: "10px",
        left: "5px",
        width: "15vw",
        maxWidth: "140px",
        height: isVisible ? "25vh" : "5vh",
        maxHeight: "150px",
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "rgba(50, 50, 50, 0.7)",
        borderRadius: "10px",
        padding: "10px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        overflow: "hidden",
        transition: "height 0.3s ease",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "5px",
          right: "5px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          color: "white",
          fontSize: "14px",
        }}
        onClick={() => setIsVisible(!isVisible)}
      >
        {isVisible ? <CaretDownOutlined /> : <CaretUpOutlined />}
      </div>
      {isVisible ? (
        <>
          <div
            style={{
              fontWeight: "bold",
              fontSize: "0.8rem",
              fontFamily: "monospace",
              color: "white",
            }}
          >
            <Text style={{ color: "white" }}>
              {`Phase: ${data?.phaseId}`}
            </Text>
          </div>
          <img
            src={"/static/assets/countdown-tool/green-light.png"}
            alt="Traffic Light"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "60%",
              objectFit: "contain",
            }}
          />
          <div
            style={{
              fontWeight: "bold",
              fontSize: "1rem",
              fontFamily: "monospace",
              color: "white",
            }}
          >
            <Text style={{ color: "white" }}>
              {`${data?.actual}/${data?.mean}`}
            </Text>
          </div>
        </>
      ) : (
        <Text style={{ color: "white", fontWeight: "bold", fontSize: "0.7rem", fontFamily: "monospace" }}>
          Yeşil Süresi
        </Text>
      )}
    </div>
  );
}

export default CountDownTool;
