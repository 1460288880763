import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Vms } from "../../data/vms/Vms";
import VmsService from "../../data/vms/VmsService";
import { useAuth } from "../auth/AuthContext";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";

export interface VmsDataContextState {
  vmsList: Vms[];
  updateVmsList: () => void;
}

const VmsDataContext = createContext<VmsDataContextState | undefined>(
  undefined
);

export function VmsDataProvider({ children }: PropsWithChildren<{}>) {
  const { filteredOrganizations } = useAuth();
  const [allVmses, setAllVmses] = useState<Vms[]>([]);
  const [filteredVmses, setFilteredVmses] = useState<Vms[]>([]);
  const { hasAccessComponent,componentPermissions } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  const getVmses = async () => {
    await VmsService.getVmses()
      .then((res) => {
        setAllVmses(res.data);
        setFilteredVmses(res.data);
      })
      .catch((err) => console.log(err));
  };

  const updateVmsList = async () => {
    await VmsService.getVmses()
      .then((res) => {
        setFilteredVmses(
          res.data.filter((item) => filteredOrganizations.includes(item.org_id))
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getVmses();
  }, [componentPermissions]);

  useEffect(() => {
    setFilteredVmses(
      allVmses.filter((item) => filteredOrganizations.includes(item.org_id))
    );
  }, [filteredOrganizations]);

  return hasAccessComponent("Vms Module-READ") ? (
    <VmsDataContext.Provider
      value={{
        vmsList: filteredVmses,
        updateVmsList: updateVmsList,
      }}
    >
      {children}
    </VmsDataContext.Provider>
  ) : (
    <>{children}</>
  );
}

export function useVmsData() {
  const context = useContext(VmsDataContext);
  if (context === undefined) {
    return {
      vmsList: [],
      updateVmsList: () => {}
    }
    //throw new Error("useVmsDatÏa must be used within a VmsDataContext");
  }
  return context;
}
