import http from "./../http.common";
import { AiReportResponse } from "./Ai";
import { FisheyeDevice } from "./Fisheye";

const getFisheyeDatas = (startTime: number, endTime: number, deviceId?: number) => {
  return http.post<FisheyeDevice[]>(`/web/junction/viero-device/fisheye`, {
    startTime: startTime,
    endTime: endTime,
    deviceId: deviceId,
  });
};

const getAiDatas = (startTime: number, endTime: number, deviceId?: number) => {
  return http.post<AiReportResponse>(`/web/junction/viero-device/ai-viero-count`, {
    startTime: startTime,
    endTime: endTime,
    deviceId: deviceId,
  });
};

const VieroDeviceService = {
  getFisheyeDatas,
  getAiDatas,
};
export default VieroDeviceService;
