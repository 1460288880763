export interface Vms {
  id:number;
  name:string;
  latitude: number;
  longitude: number;
  address:string;
  ip_address: string;
  stream_url:string;
  resolution_x:number;
  resolution_y:number;
  offset_x:number;
  offset_y:number;
  type_id:VmsType;
  status_id: VmsStatus;
  ups_id:number;
  org_id:number;
  oldsys_id:string;
  }
  
  export enum VmsStatus {
    PASSIVE=0,
    PARTIALLYACTIVE,
    ACTIVE
  }

  export enum VmsType {
    L=1,
    N
  }

  export enum VmsDataModuleTypeEnum{
    Bluesis=0,
    OtherSource
  }