import checkIfSignalPlanAvailable from "./checkIfSignalPlanAvailable";

const generateIdForNewPlan = (contextSignalPlan: any, planFormOutput: any) => {
  // if plans property of ctx plan is null or undefined, then return value 1 for only MMSP
  if (
    contextSignalPlan?.plans === null ||
    contextSignalPlan?.plans === undefined
  ) {
    return 1;
  }

  if (
    contextSignalPlan?.plans &&
    Array.isArray(contextSignalPlan?.plans) &&
    planFormOutput?.planType !== "Flash"
  ) {
    // check if there is a flash plan available inside signal plans's (plans)
    let isSignalPlanAvailable = checkIfSignalPlanAvailable(contextSignalPlan);

    // if there is no plan or only flash plan available, then generate 1 as id value
    if (
      contextSignalPlan?.plans.length === 0 ||
      (contextSignalPlan?.plans.length === 1 && isSignalPlanAvailable)
    ) {
      return 1;
    }
    let max = 1;

    for (let i = 0; i < contextSignalPlan?.plans.length; i++) {
      if (contextSignalPlan?.plans[i]?.id > max) {
        max = contextSignalPlan?.plans[i]?.id;
      }
    }

    return max + 1;
  } else if (
    contextSignalPlan?.plans &&
    Array.isArray(contextSignalPlan?.plans) &&
    planFormOutput?.planType === "Flash"
  ) {
    return 0;
  }
};

export default generateIdForNewPlan;
