import React, { useState, useEffect, useCallback } from "react";
import { Button, Menu, Dropdown, Popconfirm, message, Card, Checkbox, Tooltip, Select, Tag } from "antd";
import EditorVms from "./EditorVms";
import VmsService from "../../data/vms/VmsService";
import { Vms } from "../../data/vms/Vms";
import { DeleteOutlined, LeftOutlined, RightOutlined, SendOutlined } from "@ant-design/icons";
import { t } from "i18next";

interface EditorProps {
  selectedVms: Vms;
  sendedDrawings: any | null;
  setSendedDrawings: React.Dispatch<React.SetStateAction<any | null>>;

}
const { Option } = Select;

const EditorIndex: React.FC<EditorProps> = ({ selectedVms, sendedDrawings, setSendedDrawings }) => {
  const [selectedVmsDrawingData, setSelectedVmsDrawingData] = useState<any | null>(null);
  const [drawingDataBySelectedDrawing, setDrawingDataBySelectedDrawing] = useState<any | null>(null);
  const [selectedDrawingName, setSelectedDrawingName] = useState<string | null>(null);
  const [drawings, setDrawings] = useState<string[]>([]);
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [key, setKey] = useState<number>(0);
  const [crudMode, setCrudMode] = useState<string>("");
  const [operation, setOperation] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<string>("editor");
  const [allSelected, setAllSelected] = useState(false);

  const goBack = () => {
    setCurrentPage("editor");
  };

  const goForward = () => {
    setCurrentPage("send_drawing");
  };

  const handleNewDrawing = () => {
    setSelectedDrawingName(null);
    setDrawingDataBySelectedDrawing(null);
    setCrudMode("add");
    setShowEditor(true);
    setKey((prevKey) => prevKey + 1);
  };

  const handleDrawingSelect = (drawing: string) => {
    setSelectedDrawingName(drawing);
    setDrawingDataBySelectedDrawing(selectedVmsDrawingData[drawing]);
    setCrudMode("edit");
    setShowEditor(true);
    setKey((prevKey) => prevKey + 1);
  };

  const handleEditorClose = () => {
    setShowEditor(false);
  };

  const getVmsDrawingFiles = useCallback(async () => {
    await VmsService.getVmsDrawingFiles(selectedVms)
      .then((response) => {
        setSelectedVmsDrawingData(response.data.drawing_data);
        setDrawings(Object.keys(response.data.drawing_data));
      })
      .catch((err) => console.log(err));
  }, [selectedVms]);

  const getVmsDrawingFilesByPlans = useCallback(async (vms_id: any) => {
    await VmsService.getVmsDrawingFilesByPlans(vms_id)
      .then((response) => {
        const dataArray = response.data ? response.data : [];
        const drawingsArray = dataArray.map((x: any) => x.drawing_data);
  
        const filteredDrawingsArray = drawings.length > 0
          ? drawingsArray.filter((drawing: string) => drawings.includes(drawing))
          : [];
  
        setSelectedItems(filteredDrawingsArray);
      })
      .catch((err) => console.log(err));
  }, [drawings]);
  

  useEffect(()=>{
    getVmsDrawingFilesByPlans(selectedVms.id)
  },[selectedVms, drawings]);

  const handleDeleteDrawing = (drawing: string) => {
    removeDrawing(selectedVms, drawing);
  };

  const removeDrawing = useCallback((selectedVms: Vms, drawingName: string) => {
    VmsService.deleteDrawing(selectedVms.id, drawingName,null)
      .then((response) => {
        setOperation("delete" + Date.now());
        setSelectedDrawingName(null);
        setShowEditor(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const prepareVmsDrawingFile = async (drawingData: any) => {
    try {
      const response = await VmsService.prepareVmsDrawingFile(
        selectedVms.id,
        drawingData,
        selectedVms.ip_address
      );
      if (response.status === 200) {
        message.success(t("drawing_files_are_uploaded_to_the_device"));
      }
    } catch (error) {
      console.error(error);
      message.error(t("an_error_occurred_while_uploading_the_drawing_files_to_the_device"));
    }
  };

  const handleUploadToDevice = async () => {
    if (selectedItems.length === 0) {
      message.warning(t("please_select_at_least_one_drawing"));
      return;
    }

    const filteredVmsDrawingData: any = {};
    selectedItems.forEach(item => {
      if (selectedVmsDrawingData[item]) {
        filteredVmsDrawingData[item] = selectedVmsDrawingData[item];
      }
    });
    setSendedDrawings(Object.keys(filteredVmsDrawingData));
    await prepareVmsDrawingFile(filteredVmsDrawingData);
  };

  useEffect(() => {
    getVmsDrawingFiles();
  }, [getVmsDrawingFiles, operation]);

  useEffect(() => {
    if (selectedDrawingName != null && selectedVmsDrawingData) {
      setDrawingDataBySelectedDrawing(selectedVmsDrawingData[selectedDrawingName]);
    } else {
      setDrawingDataBySelectedDrawing(null);
    }
  }, [selectedDrawingName, selectedVmsDrawingData]);

  useEffect(() => {
    handleNewDrawing();
  }, []);

  const handleCheckboxChange = (checkedValues: any[]) => {
    setSelectedItems(checkedValues);
    setAllSelected(checkedValues.length === drawings.length);
  };

  const toggleAll = () => {
    if (allSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(drawings);
    }
    setAllSelected(!allSelected);
  };

  const renderMenuItems = () => {
    return (
        <Menu
            style={{
                maxHeight: '325px',
                overflowY: 'auto',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            }}
        >
            {drawings.map((drawing) => (
                <Menu.Item
                    key={drawing}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '8px 12px',
                        borderBottom: '1px solid #e8e8e8',
                        fontSize: '14px',
                    }}
                >
                    <span
                        onClick={() => handleDrawingSelect(drawing)}
                        style={{
                            cursor: 'pointer',
                            color: '#333',
                            transition: 'color 0.3s ease',
                        }}
                        onMouseEnter={(e:any) => e.target.style.color = '#1890ff'}
                        onMouseLeave={(e:any) => e.target.style.color = '#333'}
                    >
                        {drawing}
                    </span>
                    <Popconfirm
                        title={`${drawing} ${t("do_you_confirm?")}`}
                        onConfirm={() => handleDeleteDrawing(drawing)}
                        okText={t("yes")}
                        cancelText={t("no")}
                    >
                        <span
                            style={{
                                cursor: 'pointer',
                                color: 'red',
                                transition: 'color 0.3s ease',
                            }}
                            onMouseEnter={(e:any) => e.target.style.color = '#ff4d4f'}
                            onMouseLeave={(e:any) => e.target.style.color = 'red'}
                        >
                            <DeleteOutlined style={{ marginLeft: 8 }} />
                        </span>
                    </Popconfirm>
                </Menu.Item>
            ))}
        </Menu>
    );
};

  useEffect(() => {
    if (showEditor) {
      setKey((prevKey) => prevKey + 1);
    }
  }, [showEditor]);

  const handleSelectAll = () => {
    if (selectedItems.length === drawings.length) {
      handleCheckboxChange([]);
    } else {
      handleCheckboxChange(drawings);
    }
  };

  const tagRender = (props:any) => {
    const { label, value, closable, onClose } = props;

    return (
      <Tag
        color="geekblue"
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          backgroundColor: '#ffffff',
          color: '#2c3e50',
          border: '1px solid #2c3e50',
          borderRadius: '4px',
        }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <>
      {currentPage === "editor" && (
        <div style={{ position: "relative" }}>
          <Dropdown overlay={renderMenuItems()}>
            <Button>
              {selectedDrawingName
                ? `${t('selected_drawing')} ${selectedDrawingName}` 
                : t("select_drawing")}
            </Button>
          </Dropdown>
          <Button
            type="primary"
            onClick={handleNewDrawing}
            style={{ marginLeft: "10px" }}
          >
            {t("add_new_drawing")}
          </Button>
          {showEditor && (
            <div key={key}>
              <h3>{`${t("edited_drawing")} ${selectedDrawingName || t("new_drawing")}`}</h3>
              <EditorVms
                onClose={handleEditorClose}
                drawingNameProp={selectedDrawingName || ""}
                drawingDataProp={drawingDataBySelectedDrawing}
                selectedVms={selectedVms}
                crudMode={crudMode}
                setOperation={setOperation}
                setSelectedDrawingName={setSelectedDrawingName}
              />
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <Tooltip title={t("go_forward")}>
            <Button size="large" shape="circle" icon={<RightOutlined />} onClick={goForward} />
            </Tooltip>
          </div>
        </div>
      )}
  
      {currentPage === "send_drawing" && (
        <Card>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
          <Card  style={{
                  background: 'linear-gradient(to right, #bdc3c7, #2c3e50)',
                  borderRadius: '10px',
                  padding: '20px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  textAlign: 'center',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '18px',
                  color: '#ffffff',
                  height: '150px'
                }}>
                  <span>{t('please_select_the_drawings_you_want_to_send_to_the_device')}</span><br/>
                  <br />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                {drawings.length > 0 && <Button
                size="small"
                  onClick={handleSelectAll}
                  style={{ margin: '0 10px', color: '#ffffff', backgroundColor: '#2c3e50', border: 'none' }}
                >
                  {selectedItems.length === drawings.length ? t("Seçimleri Kaldır") : t("Tümünü Seç")}
                </Button>
                }
        <Select
          mode="multiple"
          style={{ width: 'calc(100% - 120px)', color: "#ffffff" }} // 120px, buton genişliğine göre ayarlanabilir
          placeholder={t('Çizimleri Seçin')}
          value={selectedItems}
          onChange={handleCheckboxChange}
          dropdownStyle={{ backgroundColor: '#2c3e50', color: '#ffffff' }}
          tagRender={tagRender}
        >
          {drawings.map((drawing) => (
            <Option key={drawing} value={drawing} style={{ color: '#ffffff', backgroundColor: '#2c3e50' }}>
              {drawing}
            </Option>
          ))}
        </Select>
        </div>
          </Card>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
            <Popconfirm
              title={t("do_you_confirm?")}
              onConfirm={handleUploadToDevice}
              okText={t("yes")}
              cancelText={t("no")}
              disabled={selectedItems.length === 0}
            >
              <Card
                style={{
                  background: 'linear-gradient(to right, #bdc3c7, #2c3e50)',
                  borderRadius: '10px',
                  padding: '20px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  textAlign: 'center',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '18px',
                  color: '#ffffff',
                  height: '150px'
                }}
                onClick={() => {
                  if (selectedItems.length === 0) {
                    message.warning(t("please_select_at_least_one_drawing"));
                  }
                }}
              >
                <SendOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                {t("send_drawing_files_to_vms")}
              </Card>
            </Popconfirm>
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <Tooltip title={t("go_back")}>
            <Button size="large" shape="circle" icon={<LeftOutlined />} style={{ marginRight: "10px" }} onClick={goBack} />
            </Tooltip>
          </div>
        </Card>
      )}
    </>
  );
};

export default EditorIndex;
