import { ToasterProvider } from "../../../contexts/information/ToasterContext";
import { useLicenseData } from "../../../contexts/license/LicenseDataContext";
import { useCustomLayerData } from "../../../contexts/map/CustomLayerContext";
import IncidentLayer from "./incident/IncidentLayer";
import MangoCarLayer from "./mango-car/MangoCarLayer";

export function CustomLayerControl() {
  const { layerList } = useCustomLayerData();
  const { licenseData } = useLicenseData();

  return (
    <div>
      {licenseData?.modules.incidentDetection && (
        <ToasterProvider>
          <IncidentLayer componentName="IncidentLayer" />
        </ToasterProvider>
      )}
      {licenseData?.modules.mangoCar && <MangoCarLayer />}
      {layerList.map((layerGroup) => (
        <div key={layerGroup.id}>
          {layerGroup.isChecked && layerGroup.layer}
        </div>
      ))}
    </div>
  );
}
