import React, { useCallback, useEffect, useState } from "react";
import { Ups } from "../../data/ups/Ups";
import UpsService from "../../data/ups/UpsService";
import { t } from "i18next";


interface Props {
  selectedUps: Ups;
}

const UpsDynamicInfoPage: React.FC<Props> = ({ selectedUps }) => {
  const [upsData, setUpsData] = useState<Ups | null>(null);

  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
  };

  const infoCardStyle: React.CSSProperties = {
    width: "250px",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#f0f0f0",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  };

  const batteryStatusStyle: React.CSSProperties = {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  

  const fetchUpsData = useCallback(() => {
    UpsService.getUps(selectedUps)
      .then((response) => {
        setUpsData(response.data);
      })
      .catch((err) => console.error(err));
  }, [selectedUps]);

  useEffect(() => {
    fetchUpsData();
  }, [fetchUpsData]);

  const getUpsValue = (name: string) => {
    return upsData?.raw_data.find((item: any) => item.name === name)?.value;
  };

  const formatSysUpTime = (centiseconds: number) => {
    const totalSeconds = Math.floor(centiseconds / 100);
    const days = Math.floor(totalSeconds / 86400);
    const hours = Math.floor((totalSeconds % 86400) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${days} ${t("days")} ${hours} ${t("hours")} ${minutes} ${t("minutes")} ${seconds} ${t("seconds")}`;
  };

  const getBatteryStatusInfo = () => {
    const batteryStatus = getUpsValue("upsBatteryStatus");
    if (batteryStatus == 1) {
      return { text: "Unknown", color: "gray" };
    } else if (batteryStatus == 2) {
      return { text: "Normal", color: "darkgreen" };
    } else if (batteryStatus == 3) {
      return { text: "Low", color: "orange" };
    } else if (batteryStatus == 4) {
      return { text: "Depleted", color: "red" };
    } else return { text: "Unknown", color: "gray" };
  };

  const getChargeRemainingInfo = () => {
    const chargeRemaining = getUpsValue("upsEstimatedChargeRemaining");
    if (chargeRemaining >= 0 && chargeRemaining < 20) {
      return "red";
    } else if (chargeRemaining >= 20 && chargeRemaining < 50) {
      return "orange";
    } else if (chargeRemaining >= 50 && chargeRemaining < 80) {
      return "lightgreen";
    } else if (chargeRemaining >= 80 && chargeRemaining <= 100) {
      return "darkgreen";
    }
    return "gray";
  };

  return (
    <div>
      <div style={containerStyle}>
        <div style={infoCardStyle}>
          <b>{t("input_voltage")}</b>
          <div>{getUpsValue("upsInputVoltage")}V</div>
        </div>
        <div style={infoCardStyle}>
          <b>{t("output_voltage")}</b>
          <div>{getUpsValue("upsOutputVoltage")}V</div>
        </div>
        <div style={infoCardStyle}>
          <b>{t("output_load")}</b>
          <div>{getUpsValue("upsOutputPercentLoad")}%</div>
        </div>
        <div style={infoCardStyle}>
          <b>{t("battery_temperature")}</b>
          <div>{getUpsValue("upsBatteryTemperature")}°C</div>
        </div>
        <div style={infoCardStyle}>
          <b>{t("system_uptime")}</b>
          <div>{formatSysUpTime(getUpsValue("sysUpTime"))}</div>
        </div>
        <div style={infoCardStyle}>
          <b>{t("charge_remaining")}</b>
          <div style={{ ...batteryStatusStyle, background: getChargeRemainingInfo() }}>
            {getUpsValue("upsEstimatedChargeRemaining")}%
          </div>
        </div>
        <div style={infoCardStyle}>
          <b>{t("battery_status")}</b>
          <div
            style={{
              ...batteryStatusStyle,
              backgroundColor: getBatteryStatusInfo().color,
            }}
          >
            {getBatteryStatusInfo().text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpsDynamicInfoPage;
