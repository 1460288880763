import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Collapse,
  Select,
  Typography,
  message,
  Upload,
  Alert,
  Popconfirm
} from "antd";
import { ChromePicker } from "react-color";
import Draggable from "react-draggable";
import { CopyOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { Vms } from "../../data/vms/Vms";
import VmsService from "../../data/vms/VmsService";
import { t } from "i18next";

const { Panel } = Collapse;
const { Option } = Select;
const { Title } = Typography;
const fontOptions = [
  "Arial",
  "Verdana",
  "Helvetica",
  "Tahoma",
  "Century Gothic",
  "Times New Roman",
  "Georgia",
  "Segoe UI",
  "Courier New",
  "Trebuchet MS",
];

interface EditorVmsProps {
  onClose: () => void;
  drawingNameProp: any;
  drawingDataProp: any;
  selectedVms?: Vms;
  crudMode: string;
  setOperation?: any;
  setSelectedDrawingName?: any;
}

const EditorVms: React.FC<EditorVmsProps> = ({
  onClose,
  drawingNameProp,
  drawingDataProp,
  selectedVms,
  crudMode,
  setOperation,
  setSelectedDrawingName,
}) => {
  const [imageFormDatas, setImageFormDatas] = useState<any[]>([]);
  const [textFormDatas, setTextFormDatas] = useState<any[]>([]);
  const [variableTextFormDatas, setVariableTextFormDatas] = useState<any[]>([]);

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [drawingName, setDrawingName] = useState(
    drawingNameProp ? drawingNameProp : ""
  );
  const [isDrawingNameValid, setIsDrawingNameValid] = useState(true);
  const [scaleFactorX, setScaleFactorX] = useState<number>(1);
  const [scaleFactorY, setScaleFactorY] = useState<number>(1);


  useEffect(()=>{
    setScaleFactorX(800/selectedVms!.resolution_x);
    setScaleFactorY(335/selectedVms!.resolution_y);
  },[]);

  const handleImageUpload = (file: any) => {
    const defaultImageFormData = {
      file,
      x: 0,
      y: 0,
      width: selectedVms?.resolution_x,
      height: selectedVms?.resolution_y,
      zIndex: 3,
    };
    setImageFormDatas((prevFormDatas) => [
      ...prevFormDatas,
      defaultImageFormData,
    ]);
    const fileInput = document.getElementById(
      "image-input"
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const beforeUpload = (file: any) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error(t('image_must_be_smaller_than_10MB'));
      return false;
    }
    if (hasTurkishCharacter(file.name.normalize())) {
      message.error(t('image_name_contains_turkish_characters'));
      return false; 
    }
    handleImageUpload(file);
    return false;
  };
  

  const hasTurkishCharacter = (name:string) => { // for drawing image name contains Turkish characters true or false 
    var turkishChars = /[çğıöşüÇĞİÖŞÜ]/;
    return turkishChars.test(name);
  }

  const handleDeleteImage = (index: any) => {
    setImageFormDatas((prevFormDatas) => {
      const updatedFormDatas = [...prevFormDatas];
      updatedFormDatas.splice(index, 1);
      return updatedFormDatas;
    });

    setErrorMessage((prevErrorMessage) => {
      const errorMessages = prevErrorMessage.split("\n");
      const updatedErrorMessages = errorMessages.filter((errorMessage) => {
        if (errorMessage.includes(`- ${t("picture")} ${index + 1}`)) {
          return false;
        }
        return true;
      });
      return updatedErrorMessages.join("\n");
    });
  };
  const handleTextFormSubmit = (formData: any) => {
    const defaultTextFormData = {
      text: t("text"),
      textColor: "#FFFFFF",
      backgroundColor: "#212121",
      font: fontOptions[0],
      x: 0,
      y: 0,
      width: 70,
      height: 30,
      bold: true,
      highlight: "#000000",
      fontSize: 20,
      zIndex: 3,
      ...formData,
    };
    setTextFormDatas((prevFormDatas) => [
      ...prevFormDatas,
      defaultTextFormData,
    ]);
  };

  const handleVariableTextFormSubmit = (formData: any) => {
    const generateRandomTagID = () => {
      const randomFourDigitNumber = Math.floor(1000 + Math.random() * 9000);
      return `TAG${randomFourDigitNumber}`;
    };

    const defaultVariableTextFormData = {
      text: "...",
      textColor: "#FFFFFF",
      backgroundColor: "#212121",
      font: fontOptions[0],
      x: 0,
      y: 0,
      width: 70,
      height: 30,
      bold: true,
      highlight: "#000000",
      fontSize: 20,
      zIndex: 3,
      tagID: generateRandomTagID(),
    };

    setVariableTextFormDatas((prevFormDatas) => [
      ...prevFormDatas,
      defaultVariableTextFormData,
    ]);
  };


  const handleColorChangeText = (color: any, fieldName: any, index: any) => {
    const value = color.hex;
    setTextFormDatas((prevFormDatas) => {
      const updatedFormDatas = [...prevFormDatas];
      const updatedFormData = { ...updatedFormDatas[index] };
      updatedFormData[fieldName] = value;
      updatedFormDatas[index] = updatedFormData;
      return updatedFormDatas;
    });
  };

  const handleColorChangeVariableText = (
    color: any,
    fieldName: any,
    index: any
  ) => {
    const value = color.hex;
    setVariableTextFormDatas((prevFormDatas) => {
      const updatedFormDatas = [...prevFormDatas];
      const updatedFormData = { ...updatedFormDatas[index] };
      updatedFormData[fieldName] = value;
      updatedFormDatas[index] = updatedFormData;
      return updatedFormDatas;
    });
  };

  const handleTextPositionChange = (index: any, newPosition: any) => {
    const { x, y } = newPosition;
    const updatedFormDatas = [...textFormDatas];
    updatedFormDatas[index].x = x;
    updatedFormDatas[index].y = y;
    setTextFormDatas(updatedFormDatas);
    checkItemBoundaries(
      x,
      y,
      updatedFormDatas[index].width,
      updatedFormDatas[index].height,
      "text",
      index
    );
  };

  const handleTextPositionChangeVariableText = (
    index: any,
    newPosition: any
  ) => {
    const { x, y } = newPosition;
    const updatedFormDatas = [...variableTextFormDatas];
    updatedFormDatas[index].x = x;
    updatedFormDatas[index].y = y;
    setVariableTextFormDatas(updatedFormDatas);
    checkItemBoundaries(
      x,
      y,
      updatedFormDatas[index].width,
      updatedFormDatas[index].height,
      "variable-text",
      index
    );
  };

  const handleDeleteText = (index: any) => {
    setTextFormDatas((prevFormDatas) => {
      const updatedFormDatas = [...prevFormDatas];
      updatedFormDatas.splice(index, 1);
      return updatedFormDatas;
    });

    setErrorMessage((prevErrorMessage) => {
      const errorMessages = prevErrorMessage.split("\n");
      const updatedErrorMessages = errorMessages.filter((errorMessage) => {
        if (errorMessage.includes(`- ${t("text")}-${index + 1}`)) {
          return false;
        }
        return true;
      });
      return updatedErrorMessages.join("\n");
    });
  };
  const handleDeleteVariableText = (index: any) => {
    setVariableTextFormDatas((prevFormDatas) => {
      const updatedFormDatas = [...prevFormDatas];
      updatedFormDatas.splice(index, 1);
      return updatedFormDatas;
    });

    setErrorMessage((prevErrorMessage) => {
      const errorMessages = prevErrorMessage.split("\n");
      const updatedErrorMessages = errorMessages.filter((errorMessage) => {
        if (errorMessage.includes(`- ${t("variable_text")}-${index + 1}`)) {
          return false;
        }
        return true;
      });
      return updatedErrorMessages.join("\n");
    });
  };

  const handleDrawingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isValid = /^[a-zA-Z0-9\s]*$/.test(value);
    isValid ? setHasError(false) : setHasError(true);
    setIsDrawingNameValid(isValid);
    setDrawingName(value);
  };

  const checkItemBoundaries = (
    x: any,
    y: any,
    width: any,
    height: any,
    type: any,
    index: any
  ) => {
    const containerWidth = selectedVms?.resolution_x;
    const containerHeight = selectedVms?.resolution_y;

    if (
      x < 0 ||
      y < 0 ||
      x + width > containerWidth! ||
      y + height > containerHeight!
    ) {
      let errorMessages = [...errorMessage.split("\n")];

      if (
        type === "image" &&
        !errorMessage.includes(`- ${t("picture")} ${index + 1} ${t("outside_the_vms_screen")}`)
      ) {
        errorMessages.push(`- ${t("picture")} ${index + 1} ${t("outside_the_vms_screen")}`);
      } else if (
        type === "text" &&
        !errorMessage.includes(`- ${t("text")}-${index + 1} ${t("outside_the_vms_screen")}`)
      ) {
        errorMessages.push(`- ${t("text")}-${index + 1} ${t("outside_the_vms_screen")}`);
      } else if (
        type === "variable-text" &&
        !errorMessage.includes(
          `- ${t("variable_text")}-${index + 1} ${t("outside_the_vms_screen")}`
        )
      ) {
        errorMessages.push(`- ${t("variable_text")}-${index + 1} ${t("outside_the_vms_screen")}`);
      }

      setHasError(true);
      setErrorMessage(errorMessages.join("\n"));
    } else {
      setErrorMessage((prevErrorMessage) => {
        const errorMessages = prevErrorMessage.split("\n");
        const updatedErrorMessages = errorMessages.filter((errorMessage) => {
          if (
            type === "image" &&
            errorMessage.includes(`- ${t("picture")} ${index + 1}`)
          ) {
            return false;
          }
          if (
            type === "text" &&
            errorMessage.includes(`- ${t("text")}-${index + 1}`)
          ) {
            return false;
          }
          if (
            type === "variable-text" &&
            errorMessage.includes(`- ${t("variable_text")}-${index + 1}`)
          ) {
            return false;
          }
          return true;
        });
        return updatedErrorMessages.join("\n");
      });
    }
  };

  const insertDrawing = async (
    selectedVms: Vms,
    drawingName: string,
    drawingData: any
  ) => {
    await VmsService.addDrawing(selectedVms.id, drawingName, drawingData,null)
      .then((response) => {
        setOperation("adding" + Date.now()); //trigger for getting active data -> using for dependency array [operation]
        setSelectedDrawingName(drawingName);
      })
      .catch((err) => console.log(err));
  };

  const updateDrawing = async (
    selectedVms: Vms,
    drawingName: string,
    drawingUpdateName: string,
    drawingData: any
  ) => {
    await VmsService.editDrawing(
      selectedVms.id,
      drawingName,
      drawingUpdateName,
      drawingData,
      null
    )
      .then((response) => {
        setOperation("edit" + Date.now()); //trigger for getting active data -> using for dependency array [operation]
        setSelectedDrawingName(drawingUpdateName);
      })
      .catch((err) => console.log(err));
  };

  const saveVmsImagesToFileSystem = async () => {
    imageFormDatas.map(async (fileDatas, index) => {
      const formData = new FormData();
      formData.append("file", fileDatas.file);
      const fileUploadName = selectedVms?.id.toString() + "_" + drawingName;
      await VmsService.fileUpload(formData, fileUploadName)
        .then((r) => { })
        .catch((err) => console.log(err));
    });
  };

  const handleAllFormOK = () => {
    if (hasError) {
      message.error(t("please_correct_the_errors_in_the_form"));
      return;
    }
    if (drawingName.trim() === "") {
      message.error(t("drawing_file_name_cannot_be_left_blank"));
      return;
    }

    //"tostring" has been added to the data types in a way that the vms device can understand.
    const imageData = imageFormDatas.map((formData) => ({
      fileName: formData.file.name,
      fileUrl:
        "file/vmsImages/" +
        selectedVms?.id.toString() +
        "_" +
        drawingName +
        "_" +
        formData.file.name,
      positionX: formData.x.toString(),
      positionY: formData.y.toString(),
      width: formData.width.toString(),
      height: formData.height.toString(),
      zindex: formData.zIndex.toString(),
    }));

    //"tostring" has been added to the data types in a way that the vms device can understand.
    const textData = textFormDatas.map((formData) => ({
      text: formData.text,
      textColor: formData.textColor,
      backGround: formData.backgroundColor,
      family: formData.font,
      bold: formData.bold.toString(),
      highlight: formData.highlight,
      positionX: formData.x.toString(),
      positionY: formData.y.toString(),
      fontSize: formData.fontSize.toString(),
      height: formData.height.toString(),
      width: formData.width.toString(),
      zindex: formData.zIndex.toString(),
    }));

    //"tostring" has been added to the data types in a way that the vms device can understand.
    const variableTextData = variableTextFormDatas.map((formData) => ({
      text: formData.text,
      textColor: formData.textColor,
      backGround: formData.backgroundColor,
      family: formData.font,
      bold: formData.bold.toString(),
      highlight: formData.highlight,
      positionX: formData.x.toString(),
      positionY: formData.y.toString(),
      fontSize: formData.fontSize.toString(),
      height: formData.height.toString(),
      width: formData.width.toString(),
      zindex: formData.zIndex.toString(),
      tagID: formData.tagID,
    }));

    const drawingOutputObject = {
      imageData: imageData,
      textData: textData,
      variableTextData: variableTextData,
      rectangleData: [],
      lineData: [],
    };
    if (crudMode == "add") {
      insertDrawing(selectedVms!, drawingName, drawingOutputObject);
    }
    if (crudMode == "edit") {
      updateDrawing(
        selectedVms!,
        drawingNameProp,
        drawingName,
        drawingOutputObject
      );
    }
    saveVmsImagesToFileSystem();
    message.success(t("drawing_file_save_operation_successful"));
  };

  useEffect(() => {
    if (errorMessage !== "") {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [errorMessage]);

  //it is used to set textdata and variable textdata from vms to forms.
  useEffect(() => {
    if (drawingDataProp && drawingDataProp.textData) {
      setTextFormDatas(
        drawingDataProp.textData.map((textData: any, index: any) => ({
          x: parseInt(textData.positionX),
          y: parseInt(textData.positionY),
          text: textData.text,
          textColor: textData.textColor,
          backgroundColor: textData.backGround,
          font: textData.family,
          width: parseInt(textData.width),
          height: parseInt(textData.height),
          bold: textData.bold.toLowerCase() === "true",
          highlight: textData.highlight,
          fontSize: textData.fontSize,
          zIndex: parseInt(textData.zindex),
        }))
      );
    }
    if (drawingDataProp && drawingDataProp.variableTextData) {
      setVariableTextFormDatas(
        drawingDataProp.variableTextData.map(
          (variableTextData: any, index: any) => ({
            x: parseInt(variableTextData.positionX),
            y: parseInt(variableTextData.positionY),
            text: variableTextData.text,
            textColor: variableTextData.textColor,
            backgroundColor: variableTextData.backGround,
            font: variableTextData.family,
            width: parseInt(variableTextData.width),
            height: parseInt(variableTextData.height),
            bold: variableTextData.bold.toLowerCase() === "true",
            highlight: variableTextData.highlight,
            fontSize: variableTextData.fontSize,
            zIndex: parseInt(variableTextData.zindex),
            tagID: variableTextData.tagID,
          })
        )
      );
    }
  }, [drawingDataProp]);



  //it is used to set image from vms to forms.
  useEffect(() => {
    if (drawingDataProp && drawingDataProp.imageData) {
      const fetchImages = async () => {
        const imagePromises = drawingDataProp.imageData.map(
          async (imageData: any, index: any) => {
            try {
              var origin = window.location.origin;
              const address = (origin === 'http://localhost:8081') ? 'http://localhost:3000' : origin;  // 8081 > 3000 convertion  used for local dev tests. 
              const response = await axios.get(
                `${address}/${imageData.fileUrl}`,
                {
                  responseType: "blob",
                }
              );

              const file = new File([response.data], imageData.fileName, {
                type: response.data.type,
              });

              return {
                file,
                x: parseInt(imageData.positionX),
                y: parseInt(imageData.positionY),
                width: parseInt(imageData.width),
                height: parseInt(imageData.height),
                zIndex: parseInt(imageData.zindex),
              };
            } catch (error) {
              console.error("Resim yüklenirken hata oluştu:", error);
              return null;
            }
          }
        );

        const images = await Promise.all(imagePromises);
        setImageFormDatas(images.filter((image) => image !== null));
      };

      fetchImages();
    }
  }, [drawingDataProp]);

  const handleDuplicateText = (index: any) => {
    const duplicatedText = { ...textFormDatas[index] };
    setTextFormDatas((prevFormDatas) => [
      ...prevFormDatas.slice(0, index + 1),
      duplicatedText,
      ...prevFormDatas.slice(index + 1),
    ]);
  };

  const handleDuplicateVariableText = (index: any) => {
    const generateRandomTagID = () => {
      const randomFourDigitNumber = Math.floor(1000 + Math.random() * 9000);
      return `TAG${randomFourDigitNumber}`;
    };

    const duplicatedVariableText = {
      ...variableTextFormDatas[index],
      tagID: generateRandomTagID(),
    };

    setVariableTextFormDatas((prevFormDatas) => [
      ...prevFormDatas.slice(0, index + 1),
      duplicatedVariableText,
      ...prevFormDatas.slice(index + 1),
    ]);
  };

  const rectTop = 20;
  const rectLeft = 52;
  const rectWidth = 800;
  const rectHeight = 335;

  return (
    <><Row gutter={10}>
      <Col xs={24}>
        <Form layout="vertical">
          <Form.Item
            label={t("drawing_file_name")}
            validateStatus={!isDrawingNameValid ? "error" : ""}
            help={!isDrawingNameValid
              ? "Türkçe karakterler ve işaretçiler kullanılamaz!"
              : ""}
          >
            <Input value={drawingName} onChange={handleDrawingNameChange} />
          </Form.Item>
        </Form>
      </Col>
      {/* Drawing Tools Start */}
      <Col xs={24} sm={12} md={10} lg={8}>
        <div>
          <Title level={5}>{t("tools")}</Title>
          <Collapse
            defaultActiveKey={["image-form", "text-form", "variable-text-form"]}
            style={{
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
              border: "none",
              borderTop: "none",
              borderBottom: "none",
            }}
          >
            <Panel // resim paneli

              header={<b>{t("picture")}</b>}
              key="image-form"
              style={{ backgroundColor: "#ffffff", border: "none", background: 'linear-gradient(to right,#FFFFFF , #2D6FEB)' }}
            >
              {/* Adding image for drawing */}
              <Form layout="vertical">
                <Form.Item>
                  <Upload
                    beforeUpload={beforeUpload}
                    accept="image/*"
                    showUploadList={false}
                  >
                    <Button icon={<PlusOutlined />} disabled={hasError} />
                  </Upload>
                </Form.Item>

                {imageFormDatas.map((formData, index) => (
                  <Collapse bordered={false} accordion>
                    <Panel
                      header={<>
                        <span>{`${t("picture")} ${index + 1}`}</span>
                        <Button
                          type="link"
                          icon={<DeleteOutlined style={{ color: "red" }} />}
                          onClick={(e) => {
                            handleDeleteImage(index);
                            e.stopPropagation();
                          } } />
                      </>}
                      key={`${t("picture")} ${index + 1}`}
                      style={{ backgroundColor: "#ffffff", border: "none" }}
                    >
                      <Form.Item label={`${t("picture")} ${index + 1}`} key={index}>
                        <Row gutter={10}>
                          <Col span={12}>
                            <Form.Item label={t("x_position")}>
                              <Input
                                type="number"
                                value={formData.x}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  const updatedFormData = {
                                    ...formData,
                                    x: value,
                                  };
                                  setImageFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index] = updatedFormData;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    value,
                                    formData.y,
                                    formData.width,
                                    formData.height,
                                    "image",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={t("y_position")}>
                              <Input
                                type="number"
                                value={formData.y}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  const updatedFormData = {
                                    ...formData,
                                    y: value,
                                  };
                                  setImageFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index] = updatedFormData;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    formData.x,
                                    value,
                                    formData.width,
                                    formData.height,
                                    "image",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col span={12}>
                            <Form.Item label={t("width")}>
                              <Input
                                type="number"
                                value={formData.width}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  const updatedFormData = {
                                    ...formData,
                                    width: value,
                                  };
                                  setImageFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index] = updatedFormData;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    formData.x,
                                    formData.y,
                                    value,
                                    formData.height,
                                    "image",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={t("height")}>
                              <Input
                                type="number"
                                value={formData.height}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  const updatedFormData = {
                                    ...formData,
                                    height: value,
                                  };
                                  setImageFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index] = updatedFormData;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    formData.x,
                                    formData.y,
                                    formData.width,
                                    value,
                                    "image",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item label={t("z_index")}>
                          <Input
                            type="number"
                            value={formData.zIndex}
                            onChange={(e) => {
                              const value = +e.target.value;
                              const updatedFormData = {
                                ...formData,
                                zIndex: value,
                              };
                              setImageFormDatas((prevFormDatas) => {
                                const updatedFormDatas = [...prevFormDatas];
                                updatedFormDatas[index] = updatedFormData;
                                return updatedFormDatas;
                              });
                            } } />
                        </Form.Item>
                      </Form.Item>
                    </Panel>
                    <div style={{ marginBottom: "20px" }} />
                  </Collapse>
                ))}
              </Form>
            </Panel>
            <Panel // metin paneli

              header={<b>{t("text")}</b>}
              key="text-form"
              style={{ backgroundColor: "#ffffff", border: "none", background: 'linear-gradient(to right,#FFFFFF , #2D6FEB)' }}
            >
              <Form layout="vertical">
                {textFormDatas.map((formData, index) => (
                  <Collapse bordered={false} accordion key={`collapse-${index}`}>
                    <Panel
                      header={<div>
                        <span>{`${t("text")} ${index + 1}`}</span>
                        <Button
                          type="link"
                          icon={<CopyOutlined />}
                          onClick={(e) => {
                            handleDuplicateText(index);
                            e.stopPropagation();
                          } }
                          style={{ marginLeft: '8px' }} />
                        <Button
                          type="link"
                          icon={<DeleteOutlined style={{ color: "red" }} />}
                          onClick={(e) => {
                            handleDeleteText(index);
                            e.stopPropagation();
                          } } />
                      </div>}
                      key={`${t("text")} ${index + 1}`}
                      style={{ backgroundColor: "#ffffff", border: "none" }}

                    >
                      <Form.Item key={index}>
                        <Form.Item label={t("text")}>
                          <Input
                            value={formData.text}
                            onChange={(e) => {
                              const value = e.target.value;
                              setTextFormDatas((prevFormDatas) => {
                                const updatedFormDatas = [...prevFormDatas];
                                updatedFormDatas[index].text = value;
                                return updatedFormDatas;
                              });
                            } } />
                        </Form.Item>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label={t("text_color")}>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <ChromePicker
                                  color={formData.textColor}
                                  onChange={(color) => handleColorChangeText(color, "textColor", index)}
                                  styles={{ default: { picker: { width: '100%' } } }} />
                              </div>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={t("background_color")}>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <ChromePicker
                                  color={formData.backgroundColor}
                                  onChange={(color) => handleColorChangeText(color, "backgroundColor", index)}
                                  styles={{ default: { picker: { width: '100%' } } }} />
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item label={t("font")}>
                          <Select
                            value={formData.font}
                            onChange={(value) => {
                              setTextFormDatas((prevFormDatas) => {
                                const updatedFormDatas = [...prevFormDatas];
                                updatedFormDatas[index].font = value;
                                return updatedFormDatas;
                              });
                            } }
                          >
                            {fontOptions.map((font) => (
                              <Option key={font} value={font}>
                                {font}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label={t("x_position")}>
                              <Input
                                type="number"
                                value={formData.x}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].x = value;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    value,
                                    formData.y,
                                    formData.width,
                                    formData.height,
                                    "text",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={t("y_position")}>
                              <Input
                                type="number"
                                value={formData.y}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].y = value;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    formData.x,
                                    value,
                                    formData.width,
                                    formData.height,
                                    "text",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label={t("width")}>
                              <Input
                                type="number"
                                value={formData.width}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].width = value;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    formData.x,
                                    formData.y,
                                    value,
                                    formData.height,
                                    "text",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={t("height")}>
                              <Input
                                type="number"
                                value={formData.height}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].height = value;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    formData.x,
                                    formData.y,
                                    formData.width,
                                    value,
                                    "text",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label={t("darkness")}>
                              <Input
                                type="checkbox"
                                checked={formData.bold}
                                onChange={(e) => {
                                  const value = e.target.checked;
                                  setTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].bold = value;
                                    return updatedFormDatas;
                                  });
                                } } />
                            </Form.Item>
                          </Col>
                          <Col span={12}></Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label={t("text_size")}>
                              <Input
                                type="number"
                                value={formData.fontSize}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].fontSize = value;
                                    return updatedFormDatas;
                                  });
                                } } />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={t("z_index")}>
                              <Input
                                type="number"
                                value={formData.zIndex}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].zIndex = value;
                                    return updatedFormDatas;
                                  });
                                } } />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Panel>
                    <div style={{ marginBottom: "20px" }} />
                  </Collapse>
                ))}
                <Button
                  onClick={handleTextFormSubmit}
                  disabled={hasError}
                  icon={<PlusOutlined />} />
              </Form>
            </Panel>
            <Panel // değişken metin paneli

              header={<b>{t("variable_text")}</b>}
              key="variable-text-form"
              style={{ backgroundColor: "#ffffff", border: "none", background: 'linear-gradient(to right,#FFFFFF , #2D6FEB)' }}
            >
              <Form layout="vertical">
                {variableTextFormDatas.map((formData, index) => (
                  <Collapse bordered={false} accordion>
                    <Panel
                      header={<div>
                        <span>{`${t("variable_text")} ${index + 1}`}</span>
                        <Button
                          type="link"
                          icon={<CopyOutlined />}
                          onClick={(e) => {
                            handleDuplicateVariableText(index);
                            e.stopPropagation();
                          } }
                          style={{ marginLeft: '8px' }} />
                        <Button
                          type="link"
                          icon={<DeleteOutlined style={{ color: "red" }} />}
                          onClick={(e) => {
                            handleDeleteVariableText(index);
                            e.stopPropagation();
                          } } />
                      </div>}
                      key={`${t("variable_text")} ${index + 1}`}
                      style={{ backgroundColor: "#ffffff", border: "none" }}
                    >
                      <Form.Item key={index}>
                        <Form.Item label={t("variable_text")}>
                          <Input
                            value={formData.text}
                            onChange={(e) => {
                              const value = e.target.value;
                              setVariableTextFormDatas((prevFormDatas) => {
                                const updatedFormDatas = [...prevFormDatas];
                                updatedFormDatas[index].text = value;
                                return updatedFormDatas;
                              });
                            } } />
                        </Form.Item>
                        <Form.Item label={t("ticket")}>
                          <Input
                            value={formData.tagID}
                            onChange={(e) => {
                              const value = e.target.value;
                              setVariableTextFormDatas((prevFormDatas) => {
                                const updatedFormDatas = [...prevFormDatas];
                                updatedFormDatas[index].tagID = value;
                                const tagIDs = updatedFormDatas.map(
                                  (data) => data.tagID
                                );
                                if (tagIDs.filter(
                                  (tag, i) => tag === value && i !== index
                                ).length > 0) {
                                  setHasError(true);
                                  message.error(
                                    "Please change TAG name. TAG name must be different."
                                  );
                                } else {
                                  setHasError(false);
                                }
                                if (!e.target.value || e.target.value == "") {
                                  setHasError(true);
                                  message.error(
                                    "Lütfen geçerli bir etiken girin."
                                  );
                                }
                                return updatedFormDatas;
                              });
                            } } />
                        </Form.Item>

                        <Row gutter={10}>
                          <Col span={12}>
                            <Form.Item label={t("text_color")}>
                              <ChromePicker
                                color={formData.textColor}
                                onChange={(color) => handleColorChangeVariableText(
                                  color,
                                  "textColor",
                                  index
                                )} />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={t("background_color")}>
                              <ChromePicker
                                color={formData.backgroundColor}
                                onChange={(color) => handleColorChangeVariableText(
                                  color,
                                  "backgroundColor",
                                  index
                                )} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item label={t("font")}>
                          <Select
                            value={formData.font}
                            onChange={(value) => {
                              setVariableTextFormDatas((prevFormDatas) => {
                                const updatedFormDatas = [...prevFormDatas];
                                updatedFormDatas[index].font = value;
                                return updatedFormDatas;
                              });
                            } }
                          >
                            {fontOptions.map((font) => (
                              <Option key={font} value={font}>
                                {font}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Row gutter={10}>
                          <Col span={12}>
                            <Form.Item label={t("x_position")}>
                              <Input
                                type="number"
                                value={formData.x}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setVariableTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].x = value;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    value,
                                    formData.y,
                                    formData.width,
                                    formData.height,
                                    "variable-text",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={t("y_position")}>
                              <Input
                                type="number"
                                value={formData.y}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setVariableTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].y = value;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    formData.x,
                                    value,
                                    formData.width,
                                    formData.height,
                                    "variable-text",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col span={12}>
                            <Form.Item label={t("width")}>
                              <Input
                                type="number"
                                value={formData.width}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setVariableTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].width = value;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    formData.x,
                                    formData.y,
                                    value,
                                    formData.height,
                                    "variable-text",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={t("height")}>
                              <Input
                                type="number"
                                value={formData.height}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setVariableTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].height = value;
                                    return updatedFormDatas;
                                  });
                                  checkItemBoundaries(
                                    formData.x,
                                    formData.y,
                                    formData.width,
                                    value,
                                    "variable-text",
                                    index
                                  );
                                } } />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={10}>
                          <Col span={12}>
                            <Form.Item label={t("darkness")}>
                              <Input
                                type="checkbox"
                                checked={formData.bold}
                                onChange={(e) => {
                                  const value = e.target.checked;
                                  setVariableTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].bold = value;
                                    return updatedFormDatas;
                                  });
                                } } />
                            </Form.Item>
                          </Col>
                          <Col span={12}></Col>
                        </Row>
                        <Row gutter={10}>
                          <Col span={12}>
                            <Form.Item label={t("text_size")}>
                              <Input
                                type="number"
                                value={formData.fontSize}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setVariableTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].fontSize = value;
                                    return updatedFormDatas;
                                  });
                                } } />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label={t("z_index")}>
                              <Input
                                type="number"
                                value={formData.zIndex}
                                onChange={(e) => {
                                  const value = +e.target.value;
                                  setVariableTextFormDatas((prevFormDatas) => {
                                    const updatedFormDatas = [...prevFormDatas];
                                    updatedFormDatas[index].zIndex = value;
                                    return updatedFormDatas;
                                  });
                                } } />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Panel>
                    <div style={{ marginBottom: "20px" }} />
                  </Collapse>
                ))}
                <Button
                  onClick={handleVariableTextFormSubmit}
                  disabled={hasError}
                  icon={<PlusOutlined />} />
              </Form>
            </Panel>
          </Collapse>
        </div>
      </Col>
      {/* Drawing Tools End */}

      {/* VMS Screen Start */}
      <Col xs={24} sm={12} md={14} lg={16}>
        <div style={{ position: "sticky", top: 0 }}>
          <div>
            <Title level={5}>{t("vms_screen")}</Title>

            <div
              className="container"
              style={{
                width: `900px`,
                height: `500px`,
                backgroundImage: `url('/static/vms/vmsdigitalsign.png')`,
                backgroundSize: 'cover',
                position: "relative",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: `${rectTop}px`,
                  left: `${rectLeft}px`,
                  width: `${rectWidth}px`,
                  height: `${rectHeight}px`,
                  backgroundImage: 'linear-gradient(135deg, #FFFFFF , #2D6FEB)',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)'
                }}
              >
                {imageFormDatas.map((formData, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(formData.file)}
                    alt={`${t("picture")} ${index + 1}`}
                    style={{
                      position: "absolute",
                      top: `${formData.y * scaleFactorY}px`,
                      left: `${formData.x * scaleFactorX}px`,
                      width: `${formData.width * scaleFactorX}px`,
                      height: `${formData.height * scaleFactorY}px`,
                      zIndex: formData.zIndex,
                    }} />
                ))}

                {textFormDatas.map((formData, index) => (
                  <Draggable
                    key={index}
                    position={{ x: formData.x * scaleFactorX, y: formData.y * scaleFactorY }}
                    onStop={(e, data) => {
                      const { x, y } = data;
                      handleTextPositionChange(index, { x: Math.round(x / scaleFactorX), y: Math.round(y / scaleFactorY) });
                    } }
                  >
                    <div
                      className="draggable"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        fontSize: `${formData.fontSize}px`,
                        width: formData.width * scaleFactorX,
                        height: formData.height * scaleFactorY,
                        fontWeight: formData.bold ? "bold" : "lighter",
                        color: formData.textColor,
                        backgroundColor: formData.backgroundColor,
                        fontFamily: formData.font,
                        zIndex: formData.zIndex,
                      }}
                    >
                      {formData.text}
                    </div>
                  </Draggable>
                ))}

                {variableTextFormDatas.map((formData, index) => (
                  <Draggable
                    key={index}
                    position={{ x: formData.x * scaleFactorX, y: formData.y * scaleFactorY }}
                    onStop={(e, data) => {
                      const { x, y } = data;
                      handleTextPositionChangeVariableText(index, { x: Math.round(x / scaleFactorX), y: Math.round(y / scaleFactorY) });
                    } }
                  >
                    <div
                      className="draggable"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        fontSize: `${formData.fontSize}px`,
                        width: formData.width * scaleFactorX,
                        height: formData.height * scaleFactorY,
                        fontWeight: formData.bold ? "bold" : "lighter",
                        color: formData.textColor,
                        backgroundColor: formData.backgroundColor,
                        fontFamily: formData.font,
                        zIndex: formData.zIndex,
                      }}
                    >
                      {formData.text}
                    </div>
                  </Draggable>
                ))}
              </div>
            </div>
          </div>
          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              style={{ marginTop: "10px" }} />
          )}
        </div>
      </Col>
      {/* VMS Screen End */}
    </Row><div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Popconfirm
          title={t("do_you_confirm?")}
          onConfirm={handleAllFormOK}
          okText={t("yes")}
          cancelText={t("no")}
        >
          <Button type="primary" size="large" disabled={hasError} style={{ marginTop: "10px" }}>
            {t("complete_drawing")}
          </Button>
        </Popconfirm>
      </div></>
  );
};

export default EditorVms;
