import React, { useEffect, useMemo, useState } from "react";
import { Marker, Tooltip } from "react-leaflet";
import withLogging from "../../high-order-components/LoggerHOC";
import { useTaskManagementData } from "../../../../contexts/taskmanagement/TaskManagementDataContext";
import {
  Task,
  taskStatusColors,
  taskTypeImages,
} from "../../../../data/task-management/TaskManagement";
import L, { LeafletMouseEvent, latLng } from "leaflet";
import TaskManagementPopup from "./TaskManagementPopup";
import GenericMapModal from "../../components/generic/map-modal/GenericMapModal";
import { GenericMapModalTitle } from "../../components/generic/map-modal/GenericMapModalTitle";
import { t } from "i18next";
import { TaskManagementDetail } from "./TaskManagementDetail";
import MarkerGenericAddUpdateContent from "../../components/generic/marker-context-modal/MarkerGenericAddUpdateContent";
import { TaskModel } from "../../components/generic/marker-context-modal/model/Task";
import { convertTaskModel } from "./helpers/convertTaskObject";
import { taskIconBase64 } from "../../map-constants/static/MarkerIconBase64";

function TaskManagementLayer(): JSX.Element {
  const { taskData, archivedTaskList, activeTechicianList, updateTaskList } =
    useTaskManagementData();

  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [updatedTask, setUpdatedTask] = useState<any>(null);

  const [taskList, setTaskList] = useState<Task[]>([]);

  useEffect(() => {
    setTaskList(taskData);
  }, [taskData]);

  const eventHandler = (task: Task) => {
    return {
      dblclick: (event: LeafletMouseEvent) => null,
      contextmenu: (event: LeafletMouseEvent) => null,
    };
  };

  const handlePopupClick = (task: Task) => {
    setSelectedTask(task);
  };

  const editTask = (task: any) => {
    setUpdatedTask(task);
  };

  const deleteTask = () => {
    updateTaskList();
    onCloseModal();
  };

  const onCloseModal = () => {
    setSelectedTask(null);
    setUpdatedTask(null);
  };

  const detailedModalContent = useMemo(() => {
    if (updatedTask) {
      const taskData = convertTaskModel(updatedTask);
      return (
        <GenericMapModal
          componentName="GenericMapModal"
          onClose={() => setUpdatedTask(null)}
          modalTitle={
            <GenericMapModalTitle color="#FFFFFF">
              <span
                style={{ fontSize: "1rem", color: "#656565", fontWeight: 700 }}
              >
                {updatedTask.title}
              </span>
            </GenericMapModalTitle>
          }
        >
          <MarkerGenericAddUpdateContent
            modalType="update"
            model={TaskModel}
            setModalVisible={onCloseModal}
            icon={taskIconBase64}
            latlon={new L.LatLng(updatedTask.latitude, updatedTask.longitude)}
            triggerStateFunc={updateTaskList}
            formData={taskData}
          />
        </GenericMapModal>
      );
    }
  }, [updatedTask]);

  const updateModalContent = useMemo(() => {
    return (
      selectedTask && (
        <GenericMapModal
          componentName="GenericMapModal"
          onClose={onCloseModal}
          modalTitle={
            <GenericMapModalTitle color="#FFFFFF">
              <span
                style={{ fontSize: "1rem", color: "#656565", fontWeight: 700 }}
              >
                {t("task_details")}
              </span>
            </GenericMapModalTitle>
          }
        >
          <TaskManagementDetail
            latlon={latLng(selectedTask.latitude, selectedTask.longitude)}
            selectedTask={selectedTask}
            setSelectedTask={setSelectedTask}
            onEditTask={(selected) => editTask(selected)}
            onDeleteTask={() => deleteTask()}
          />
        </GenericMapModal>
      )
    );
  }, [selectedTask]);

  return (
    <>
      {taskList.length > 0 &&
        taskList.map((task: Task) => {
          const customIcon = L.divIcon({
            className: `${
              task.priority === 2 ? "task-flash" : "custom-div-icon"
            }`,
            html: `<div style="border: 3px solid ${
              task.priority === 2 && task.task_status !== 4
                ? "#E72929"
                : taskStatusColors[task.task_status]
            }; background-color: #ffffff; width: 34px; height: 34px; border-radius: 50%; display: flex; justify-content: center; align-items: center; box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.4);"><img src="data:image/png;base64,${
              task.task_type >= 1 && task.task_type <= taskTypeImages.length
                ? taskTypeImages[task.task_type]
                : taskTypeImages[0]
            }" alt="Custom Icon" style="width: 16px; height: 16px;"/></div>`,
            iconAnchor: [18, 11],
          });
          return (
            <Marker
              key={task.id}
              position={[task.latitude, task.longitude]}
              icon={customIcon}
              eventHandlers={eventHandler(task)}
              contextmenu={true}
            >
              <TaskManagementPopup task={task} onClick={handlePopupClick} />
              <Tooltip>{task.title}</Tooltip>
            </Marker>
          );
        })}
      {activeTechicianList.length > 0 &&
        activeTechicianList.map((technician) => {
          if (technician.roles.includes("technician") && technician.latlon) {
            const customWorkerIcon = L.divIcon({
              className: "custom-div-icon",
              html: `<div style="border: 3px solid #153448; background-color: #ffffff; width: 30px; height: 30px; border-radius: 50%; display: flex; justify-content: center; align-items: center; box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.4); padding-top: 4px; overflow: hidden;"><img src=${require(`./assets/worker.png`)} alt="Custom Icon" style="width: 24px; height: 24px;"/></div>`,
              iconAnchor: [18, 11],
            });
            return (
              <Marker
                key={technician.userId}
                position={technician.latlon}
                icon={customWorkerIcon}
                contextmenu={true}
              >
                <Tooltip>{technician.username}</Tooltip>
              </Marker>
            );
          }
        })}
      {archivedTaskList.length > 0 &&
        archivedTaskList.map((task: Task) => {
          const customIcon = L.divIcon({
            html: `<div style="border: 3px solid ${
              taskStatusColors[task.task_status]
            }; background-color: #ffffff; width: 34px; height: 34px; border-radius: 50%; display: flex; justify-content: center; align-items: center; box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.4);"><img src="data:image/png;base64,${
              task.task_type >= 1 && task.task_type <= taskTypeImages.length
                ? taskTypeImages[task.task_type]
                : taskTypeImages[0]
            }" alt="Custom Icon" style="width: 16px; height: 16px;"/></div>`,
            iconAnchor: [18, 11],
          });
          return (
            <Marker
              key={task.id}
              position={[task.latitude, task.longitude]}
              icon={customIcon}
              eventHandlers={eventHandler(task)}
              contextmenu={true}
            >
              <TaskManagementPopup task={task} onClick={handlePopupClick} />
              <Tooltip>{task.title}</Tooltip>
            </Marker>
          );
        })}
      {detailedModalContent}
      {updateModalContent}
    </>
  );
}

export default withLogging(TaskManagementLayer);
