import { Button, Card, Table } from "antd";
import { RefObject, useCallback, useEffect, useState } from "react";
import { Junction } from "../../data/junction/Junction";
import { JunctionFile } from "../../data/junction/JunctionFile";
import JunctionService from "../../data/junction/JunctionService";
import JunctionFileUpload from "./JunctionFileUpload";
import { DownloadOutlined } from "@ant-design/icons";
import { t } from "i18next";

const { REACT_APP_API_URL_DEV, REACT_APP_HOST_TYPE } =  process.env;
const baseURL =  REACT_APP_HOST_TYPE === "dev" ? REACT_APP_API_URL_DEV : window.location.origin;

interface Props {
  containerRef: RefObject<HTMLElement>;
  selectedJunction?: Junction;
  setDesignFileUrl: Function;
}
function JunctionFiles(props: Props) {
  const { selectedJunction, setDesignFileUrl } = props;
  const [dataSource, setDataSource] = useState<JunctionFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "orginal_name",
      key: "orginal_name",
    },
    {
      title: "Mime type",
      dataIndex: "mimetype",
      key: "mimetype",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: JunctionFile) => (
        <Button
          icon={<DownloadOutlined />}
          onClick={() => download(record)}
        ></Button>
      ),
    },
  ];
  const download = useCallback((record: JunctionFile) => {
    fetch(`${baseURL}${record.serve_path}`).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = record.orginal_name;
        a.click();
      });
    });
  }, []);

  const onAddClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const getFiles = useCallback(() => {
    if (selectedJunction) {
      JunctionService.getFiles(selectedJunction.id).then((res) => {
        setDataSource(res.data);
  
        let lastDesignAddedFile = res.data.reduce((prev:any, current:any) => {
          if (!prev) {
            return current;
          }
          return current.orginal_name === "design.pdf" && prev.id > current.id
            ? prev
            : current;
        }, null);
  
        let designFileServePath = lastDesignAddedFile ? lastDesignAddedFile.serve_path : null;
        setDesignFileUrl(designFileServePath);
      });
    }
  }, [selectedJunction, setDesignFileUrl]);

  const onSave = useCallback(
    (values: any) => {
      getFiles();
      onClose();
    },
    [onClose, getFiles]
  );

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  return (
    <Card
      title={t("junction_files")}
      extra={<Button onClick={onAddClick}>Add</Button>}
    >
      <>
        <Table
          style={{ width: "100%" }}
          dataSource={dataSource}
          columns={columns}
        />
        <JunctionFileUpload
          isOpen={isModalOpen}
          onClose={onClose}
          onSave={onSave}
          selectedJunction={selectedJunction}
        />
      </>
    </Card>
  );
}

export default JunctionFiles;
