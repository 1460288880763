import { Button, Col, message, Modal, Row, Select, TimePicker } from "antd";
import moment from "moment";
import { Moment } from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MultiModePlanContext } from "../../../../context/MultiModePlanContext";
import {
  days,
  schedularHeaderHeight,
  SCHEDULAR_DISPLAY_MINUTES_TIME_LIMIT,
  SCHEDULAR_MARGIN_LEFT,
} from "../../constants";
import { MultiModeSchedularContext } from "../../context/MultiModeSchedularContext";
import { areInputsValid } from "../../helpers";
import {
  ICFMPlan,
  IChaosPlan,
  ICoordPlan,
  IDischargePlan,
  TAlternativePlan,
} from "../../models/MultiModePlan";
import { CfmColGroup } from "../../models/PlanCols/CfmColGroup";
import { ChaosColGroup } from "../../models/PlanCols/ChaosColGroup";
import { CoordColGroup } from "../../models/PlanCols/CoordColGroup";
import { DischargeColGroup } from "../../models/PlanCols/DischargeColGroup";
import DayContainer from "../DayContainer";
import DayCopyPasteModal from "../DayCopyPasteModal";
import DayFilter from "../DayFilter";
import MultiModeTypeFilter from "../MultiModeTypeFilter";
import TimeSlices from "../TimeSlices";
import { PlusSquareOutlined } from "@ant-design/icons";

const { Option } = Select;

interface Props {
  weeklyMultiModeSP?: any;
}

export type TDefaultPlan = {
  id: number;
  name: string;
  multiModeType: TAlternativePlan["type"];
  corridorIds?: number[];
  dischargeIds?: number[];
  [key: string]: any;
};

type TCorridorOrDischargeBasedGroupedPlanOutput = {
  corridorIds?: IChaosPlan["corridorIds"];
  dischargeIds?: IDischargePlan["dischargeIds"];
  // plans: IChaosPlan[] | IDischargePlan[] | ICFMPlan[] | ICoordPlan[];
  plans: TAlternativePlan[];
}[];
export type groupedPlansForWeek = {
  0?: TCorridorOrDischargeBasedGroupedPlanOutput;
  1?: TCorridorOrDischargeBasedGroupedPlanOutput;
  2?: TCorridorOrDischargeBasedGroupedPlanOutput;
  3?: TCorridorOrDischargeBasedGroupedPlanOutput;
  4?: TCorridorOrDischargeBasedGroupedPlanOutput;
  5?: TCorridorOrDischargeBasedGroupedPlanOutput;
  6?: TCorridorOrDischargeBasedGroupedPlanOutput;
};

interface IAddPlanModalState {
  visible: boolean;
  dayIndex: null | keyof groupedPlansForWeek | "all"; // all parameter to add each days
  selectedPlanId: null | number;
  startTime: any;
  endTime: any;
}

export interface ICopyPastePlanModalInformations {
  visible: boolean;
  copyIndex: null | number;
}

const defaultCopyPastePlanModalInfo = {
  visible: false,
  copyIndex: null,
};

const Schedular: React.FC<Props> = () => {
  // const { t } = useTranslation();
  const { t } = { t: (par: string) => par };
  const schedularContextValues = useContext(MultiModeSchedularContext);
  const [selectedDays] = schedularContextValues?.selectedDays;
  const [selectedMultiModeTypes] =
    schedularContextValues?.selectedMultiModeTypes;

  const planContextValues = useContext(MultiModePlanContext);
  const [contextSignalPlan, setContextSignalPlan] =
    planContextValues?.signalPlan;
  const [locked] = planContextValues.locked;

  // copy paste modal
  const [copyPastePlanModalInfo, setCopyPastePlanModalInfo] =
    useState<ICopyPastePlanModalInformations>(defaultCopyPastePlanModalInfo);

  const clearCopyPastePlanModalInfo = () => {
    setCopyPastePlanModalInfo(defaultCopyPastePlanModalInfo);
  };
  // copy paste modal

  const [addPlanModalState, setAddPlanModalState] =
    useState<IAddPlanModalState>({
      visible: false,
      dayIndex: null,
      selectedPlanId: null,
      startTime: null,
      endTime: null,
    });

  const [planOptions, setPlanOptions] = useState<any[]>([]);

  const showAddPlanModal = useCallback(
    (dayIndex: number | "all") => {
      setAddPlanModalState((prev: any) => ({
        ...prev,
        visible: true,
        dayIndex,
      }));
    },
    [addPlanModalState]
  );

  const closeAddPlanModal = () => {
    setAddPlanModalState((prev: any) => ({
      ...prev,
      visible: false,
      dayIndex: null,
      selectedPlanId: null,
      startTime: null,
    }));
  };

  const updateSelectedPlanOnAddPlanModal = (value: number) => {
    setAddPlanModalState((prev: any) => ({
      ...prev,
      selectedPlanId: value,
    }));
  };

  const updateStartTimeOfSelectedSchedularPlan = (startTime: string) => {
    setAddPlanModalState((prev: any) => ({
      ...prev,
      startTime,
    }));
  };

  const updateEndTimeOfSelectedSchedularPlan = (endTime: string) => {
    setAddPlanModalState((prev: any) => ({
      ...prev,
      endTime,
    }));
  };

  const addPlanToSchedular = () => {
    const dayIndex = addPlanModalState.dayIndex;
    const startTime: Moment = addPlanModalState.startTime;
    const endTime: Moment = addPlanModalState.endTime;
    const planId = addPlanModalState.selectedPlanId;

    // if any of these inputs are not valid, then do nothing
    if (!areInputsValid(dayIndex, startTime, endTime, planId)) {
      message.error("Input/inputs not valid");
      return;
    }

    // if endTime equal or less than startTime, then do nothing
    if (endTime <= startTime) {
      message.error("Start and finish times are not valid");
      return;
    }

    // if time range under the time limit
    if (
      moment(endTime, "HH:mm") <
      moment(startTime, "HH:mm").add(
        SCHEDULAR_DISPLAY_MINUTES_TIME_LIMIT,
        "minutes"
      )
    ) {
      message.error(
        `Plan time range can not be less than ${SCHEDULAR_DISPLAY_MINUTES_TIME_LIMIT} minutes`
      );
      return;
    }

    /**
     * if any intersection is available
     * find default plan from plan id
     * find type and corridorIds/dischargeIds properties if available from found default plan
     */
    const foundPlan = contextSignalPlan?.plans?.find(
      (defaultPlan: TDefaultPlan) => defaultPlan.id === planId
    ) as TDefaultPlan;

    // { multiModeType, corridorIds, dischargeIds }
    if (dayIndex !== null && dayIndex !== "all") {
      const { multiModeType } = foundPlan;
      let intersectionAvailable: boolean | undefined = false;
      if (multiModeType === "chaos") {
        intersectionAvailable = ChaosColGroup.checkIntersection(
          {
            startTime: startTime,
            endTime: endTime,
            planId: planId,
          } as IChaosPlan,
          dayIndex
        );
      } else if (multiModeType === "discharge") {
        intersectionAvailable = DischargeColGroup.checkIntersection(
          {
            startTime: startTime,
            endTime: endTime,
            planId: planId,
          } as IDischargePlan,
          dayIndex
        );
      } else if (multiModeType === "cfm") {
        intersectionAvailable = CfmColGroup.checkIntersection(
          {
            startTime: startTime,
            endTime: endTime,
            planId: planId,
          } as ICFMPlan,
          dayIndex
        );
      } else if (multiModeType === "coord") {
        intersectionAvailable = CoordColGroup.checkIntersection(
          {
            startTime: startTime,
            endTime: endTime,
            planId: planId,
          } as ICoordPlan,
          dayIndex
        );
      }

      if (intersectionAvailable) {
        message.error("Start and finish times cause overlap");
        return;
      }
    }

    // add new plan to sp ctx
    if (dayIndex !== null && Array.isArray(contextSignalPlan?.dailyPlans)) {
      setContextSignalPlan((prev: any) => ({
        ...prev,
        dailyPlans: dailyPlans.map((dayPlans: any, index: number) => {
          if (index === dayIndex) {
            return {
              ...dayPlans,
              plans: [
                ...dayPlans?.plans,
                {
                  planId: planId,
                  planMode: null,
                  startTime: startTime,
                  endTime: endTime,
                },
              ],
            };
          }
          return dayPlans;
        }),
      }));
    }
  };

  const addPlanToAllDays = () => {
    const startTime: Moment = addPlanModalState.startTime;
    const endTime: Moment = addPlanModalState.endTime;
    const planId = addPlanModalState.selectedPlanId;

    // if any of these inputs are not valid, then do nothing
    if (!areInputsValid(startTime, endTime, planId)) {
      message.error("Input/inputs not valid");
      return;
    }

    // if endTime equal or less than startTime, then do nothing
    if (endTime <= startTime) {
      message.error("Start and finish times are not valid");
      return;
    }

    /**
     * if any intersection is available
     * find default plan from plan id
     * find type and corridorIds/dischargeIds properties if available from found default plan
     */
    const foundPlan = contextSignalPlan?.plans?.find(
      (defaultPlan: TDefaultPlan) => defaultPlan.id === planId
    ) as TDefaultPlan;

    let overlapError = false;
    for (let dayIndex = 0; dayIndex < days.length; dayIndex++) {
      if (dayIndex !== null) {
        const { multiModeType } = foundPlan;
        let intersectionAvailable: boolean | undefined = false;
        if (multiModeType === "chaos") {
          intersectionAvailable = ChaosColGroup.checkIntersection(
            {
              startTime: startTime,
              endTime: endTime,
              planId: planId,
            } as IChaosPlan,
            dayIndex as keyof groupedPlansForWeek
          );
        } else if (multiModeType === "discharge") {
          intersectionAvailable = DischargeColGroup.checkIntersection(
            {
              startTime: startTime,
              endTime: endTime,
              planId: planId,
            } as IDischargePlan,
            dayIndex as keyof groupedPlansForWeek
          );
        } else if (multiModeType === "cfm") {
          intersectionAvailable = CfmColGroup.checkIntersection(
            {
              startTime: startTime,
              endTime: endTime,
              planId: planId,
            } as ICFMPlan,
            dayIndex as keyof groupedPlansForWeek
          );
        } else if (multiModeType === "coord") {
          intersectionAvailable = CoordColGroup.checkIntersection(
            {
              startTime: startTime,
              endTime: endTime,
              planId: planId,
            } as ICoordPlan,
            dayIndex as keyof groupedPlansForWeek
          );
        }

        if (intersectionAvailable) {
          overlapError = intersectionAvailable;
          message.error(
            "Start and finish times cause overlap on " + days[dayIndex]
          );
          break;
        }
      }
    }

    if (overlapError) {
      return;
    }

    if (Array.isArray(contextSignalPlan?.dailyPlans)) {
      setContextSignalPlan((prev: any) => ({
        ...prev,
        dailyPlans: dailyPlans.map((dayPlans: any) => {
          return {
            ...dayPlans,
            plans: [
              ...dayPlans?.plans,
              {
                planId: planId,
                planMode: null,
                startTime: startTime,
                endTime: endTime,
              },
            ],
          };
        }),
      }));
    }
  };

  const dailyPlans = useMemo(() => {
    return contextSignalPlan?.dailyPlans;
  }, [contextSignalPlan]);

  useEffect(() => {
    const obtainedOptions = contextSignalPlan?.plans?.map(
      (plan: TDefaultPlan) => {
        return {
          value: plan?.id,
          label: `${plan?.name} - ${plan?.multiModeType}`,
        };
      }
    );
    setPlanOptions(obtainedOptions);
  }, [contextSignalPlan]);

  const getDayContainerPlans = (selectedDayIndex: number) => {
    const dayContainerPlans = contextSignalPlan?.dailyPlans?.[
      selectedDayIndex
    ]?.plans?.map((plan: TAlternativePlan) => {
      return {
        ...plan,
      } as TAlternativePlan;
    });

    return dayContainerPlans;
  };

  return (
    <>
      {/* Plan Type and Day filters */}
      <div style={{ marginLeft: SCHEDULAR_MARGIN_LEFT }}>
        <DayFilter />
        <MultiModeTypeFilter />
        <br />
        <br />
      </div>

      {/* Add Plan to All Days Button */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        {!locked && (
          <Button
            type="primary"
            onClick={() => {
              showAddPlanModal("all");
            }}
          >
            <PlusSquareOutlined />
            <span>{t("add_plan_to_all_days")}</span>
          </Button>
        )}
      </div>

      {/* Schedular Content */}
      <div
        style={{
          width: "100%",
          height: "100%",
          overflowX: "scroll",
          marginBottom: 35,
        }}
      >
        {selectedDays.length > 0 && selectedMultiModeTypes.length > 0 && (
          <div
            className="sch-container"
            style={{
              display: "inline-flex",
              marginLeft: SCHEDULAR_MARGIN_LEFT,
              marginBottom: 65,
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <TimeSlices schedularHeaderHeight={schedularHeaderHeight} />
            {selectedDays.map((dayName: string, _dayIndex: number) => {
              const selectedDayIndex = days.findIndex((day) => day === dayName);

              const dayContainerPlans = getDayContainerPlans(selectedDayIndex);

              return (
                <DayContainer
                  // pull type property("cfm" | "discharge" | "chaos" | "coord") from SP object's plans array
                  plans={dayContainerPlans}
                  dayName={dayName}
                  key={selectedDayIndex}
                  showAddPlanModal={showAddPlanModal}
                  copyPasteDay={setCopyPastePlanModalInfo}
                />
              );
            })}
          </div>
        )}
      </div>

      {/* Plan Addition Modal */}
      {addPlanModalState.visible && (
        <Modal
          title={t("add_plan")}
          visible={addPlanModalState.visible}
          onOk={() => {
            if (typeof addPlanModalState.dayIndex === "number") {
              addPlanToSchedular();
            } else if (addPlanModalState.dayIndex === "all") {
              // if dayIndex is set as "all", then set all days
              addPlanToAllDays();
            }

            closeAddPlanModal();
          }}
          onCancel={() => {
            closeAddPlanModal();
          }}
        >
          <Row>
            <Col span={24}>
              <Select
                style={{ width: "100%" }}
                placeholder={t("select_plan")}
                onChange={(value) => {
                  updateSelectedPlanOnAddPlanModal(value);
                }}
              >
                {planOptions.map(({ value, label }: any) => {
                  return (
                    <Option value={value} key={`plan-option-${value}`}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={24}>
              <TimePicker
                format={"HH:mm"}
                placeholder={t("select_start_time")}
                onChange={(time: any) => {
                  const date = time._d;
                  const hourMinuteSecond = date.toLocaleTimeString("tr-TR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                  updateStartTimeOfSelectedSchedularPlan(hourMinuteSecond);
                }}
                showNow={false}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={24}>
              <TimePicker
                format={"HH:mm"}
                placeholder={t("select_end_time")}
                onChange={(time: any) => {
                  const date = time._d;
                  const hourMinuteSecond = date.toLocaleTimeString("tr-TR", {
                    hour: "2-digit",
                    minute: "2-digit",
                  });
                  updateEndTimeOfSelectedSchedularPlan(hourMinuteSecond);
                }}
                showNow={false}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </Modal>
      )}

      {/* Copy Day to Others Modal */}
      {copyPastePlanModalInfo.visible && (
        <DayCopyPasteModal
          {...copyPastePlanModalInfo}
          clearModal={clearCopyPastePlanModalInfo}
        />
      )}
    </>
  );
};

export default Schedular;
