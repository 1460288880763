import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";

export interface vieroStatusData {
  vieroId: number;
  statusId: number;
  ts: number;
}

interface StatusDataProps {
  vieroStatusData: vieroStatusData|undefined;
}
const VieroStatusDataContext = createContext<StatusDataProps | undefined>(
  undefined
);

export function VieroStatusDataProvider({ children }: PropsWithChildren<{}>) {
  const [statusData, setStatusData] = useState<vieroStatusData>();

  useEffect(() => {
    socket.on("vieroStatus", (data: vieroStatusData) => {
      setStatusData(data);
  
    });

    return () => {
      socket.off("vieroStatus");
    };
  }, []);

  return (
    <VieroStatusDataContext.Provider
      value={{
        vieroStatusData: statusData,
      }}
    >
      {children}
    </VieroStatusDataContext.Provider>
  );
}

export function useVieroStatusData() {
  const context = useContext(VieroStatusDataContext);
  if (context === undefined) {
    return {
      vieroStatusData: undefined,
    };
  }
  return context;
}
