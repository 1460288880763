import { useMapEvent } from "react-leaflet";
import "leaflet-contextmenu";
import { useState } from "react";
import GenericMapModal from "../../components/generic/map-modal/GenericMapModal";
import { GenericMapModalTitle } from "../../components/generic/map-modal/GenericMapModalTitle";
import {
  MapContextDataContentProps,
  MapContextMenu,
  mapContextData,
  mapContextDataContent,
} from "../../map-constants/module-control/MapContextData";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import { t } from "i18next";
import { LatLng } from "leaflet";
import withLogging from "../../high-order-components/LoggerHOC";
import { useVmsData } from "../../../../contexts/vms/VmsDataContext";
import { useUpsData } from "../../../../contexts/ups/UpsDataContext";
import { useBluesisData } from "../../../../contexts/bluesis/BluesisDataContext";
import { useCameraData } from "../../../../contexts/camera/CameraDataContext";
import { useVieroDeviceData } from "../../../../contexts/viero/VieroDeviceDataContext";
import { useTaskManagementData } from "../../../../contexts/taskmanagement/TaskManagementDataContext";
import { useBarrierData } from "../../../../contexts/barrier/BarrierDataContext";

function MapEventsControl() {
  const [selectedComponent, setSelectedComponent] =
    useState<MapContextDataContentProps>();
  const [latlon, setLatlon] = useState<LatLng>();

  const { layerList } = useCustomLayerData();
  const { updateVmsList } = useVmsData();
  const { updateUpsList } = useUpsData();
  const { updateBluesisList } = useBluesisData();
  const { updateAllCamera } = useCameraData();
  const { updateAllViero } = useVieroDeviceData();
  const { updateTaskList } = useTaskManagementData();
  const { updateAllBarier } = useBarrierData();

  const map = useMapEvent("contextmenu", (e) => {
    map.contextmenu.removeAllItems();
    const checkedGroups = layerList.filter((layer) => layer.isChecked);
    checkedGroups.forEach((layer) => {
      const id = layer.id;
      const contextMenuItems = mapContextData[id];

      if (contextMenuItems) {
        contextMenuItems.forEach((item) => {
          map.contextmenu.addItem({
            ...item,
            text: t(item.text),
            callback: () => {
              if (item.index) {
                setLatlon(e.latlng);
                setSelectedComponent(
                  mapContextDataContent[item.index as MapContextMenu]
                );
              }
            },
          });
        });
      }
    });
  });

  const onClose = () => {
    setSelectedComponent(undefined);
  };

  const getContext = () => {
    if (selectedComponent && selectedComponent.component) {
      if (selectedComponent.model) {
        switch (selectedComponent.model.type) {
          case "vms":
            return (
              <selectedComponent.component
                {...selectedComponent}
                setModalVisible={onClose}
                latlon={latlon}
                triggerStateFunc={updateVmsList}
                modalType="add"
              />
            );
          case "ups":
            return (
              <selectedComponent.component
                {...selectedComponent}
                setModalVisible={onClose}
                latlon={latlon}
                triggerStateFunc={updateUpsList}
                modalType="add"
              />
            );
          case "bluesis":
            return (
              <selectedComponent.component
                {...selectedComponent}
                setModalVisible={onClose}
                latlon={latlon}
                triggerStateFunc={updateBluesisList}
                modalType="add"
              />
            );
          case "camera":
            return (
              <selectedComponent.component
                {...selectedComponent}
                setModalVisible={onClose}
                latlon={latlon}
                triggerStateFunc={updateAllCamera}
                modalType="add"
              />
            );
          case "viero":
            return (
              <selectedComponent.component
                {...selectedComponent}
                setModalVisible={onClose}
                latlon={latlon}
                triggerStateFunc={updateAllViero}
                modalType="add"
              />
            );
          case "task":
            return (
              <selectedComponent.component
                {...selectedComponent}
                setModalVisible={onClose}
                latlon={latlon}
                triggerStateFunc={updateTaskList}
                modalType="add"
              />
            );
          case "barrier":
            return (
              <selectedComponent.component
                {...selectedComponent}
                setModalVisible={onClose}
                latlon={latlon}
                triggerStateFunc={updateAllBarier}
                modalType="add"
              />
            );
          case "inventory":
            return (
              <selectedComponent.component
                {...selectedComponent}
                setModalVisible={onClose}
                latlon={latlon}
                //  triggerStateFunc={updateAllBarier}
                modalType="add"
              />
            );
          default:
            return (
              <selectedComponent.component
                {...selectedComponent}
                setModalVisible={onClose}
                latlon={latlon}
                modalType="add"
              />
            );
        }
      } else {
        return (
          <selectedComponent.component
            {...selectedComponent}
            setModalVisible={onClose}
            latlon={latlon}
          />
        );
      }
    }
  };

  return (
    <>
      {selectedComponent && (
        <GenericMapModal
          componentName="DynamicModal"
          onClose={onClose}
          modalWidth={selectedComponent.modalWidth ?? "80%"}
          modalTitle={
            <GenericMapModalTitle>
              {t(selectedComponent.title)}
            </GenericMapModalTitle>
          }
          modalFooterHide={selectedComponent.modalFooterHide ?? false}
          bodyStyle={selectedComponent.bodyStyle}
        >
          {getContext()}
        </GenericMapModal>
      )}
    </>
  );
}

export default withLogging(MapEventsControl);
