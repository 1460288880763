import { Button, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IContentData } from "../..";
import { ColumnsType } from "antd/lib/table";
import { QueueLengthModal } from "../../../../../../../data/fcd/QueueLengthModal";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { Junction } from "../../../../../../../data/junction/Junction";

interface Props {
  contentData: any;
  selectedJunction: Junction;
}
const QueueLength: React.FC<Props> = ({ contentData,selectedJunction }) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
   setDataSource(contentData.map((item:any) => {
    item.ts = moment.unix(item.ts).utcOffset(localStorage.getItem("timezone")!).format("DD.MM.YY HH:mm");
    return item;
  }));
  },[contentData])

  const columns: ColumnsType<QueueLengthModal> = [
    {
      title: t("date"),
      dataIndex: "ts",
      key: "ts",
      align: "center",
    },
    {
      title: `${t("cluster_id")}`,
      dataIndex: "cluster_id",
      key: "cluster_id",
      align: "center",
    },
    {
      title: `${t("corridor_id")}`,
      dataIndex: "corridor_id",
      key: "corridor_id",
      align: "center",
    },
    {
      title: `${t("queue_length")}`,
      dataIndex: "queue_length",
      key: "queue_length",
      align: "center",
    }
  ];

  const headers = [
    { label: `${t("date")}`, key: "ts" },
    { label: `${t("cluster_id")}`, key: "cluster_id" },
    { label: `${t("junction_id")}`, key: "junction_id" },
    { label: `${t("corridor_id")}`, key: "corridor_id" },
    { label: `${t("queue_length")}`, key: "queue_length" },
  ];

  return (
    <>
      {dataSource && (
        <>
        <Button
            disabled={dataSource.length > 0 ? false : true}
            style={{
              color: "white",
              background: "#808080",
              width: "100%",
              marginBottom: "10px",
            }}
            icon={<DownloadOutlined />}
          >
            <CSVLink
              filename={`${selectedJunction.name}_`+t("queue_length")}
              data={dataSource}
              headers={headers}
              style={{ color: "white", background: "#808080", width: "100%" }}
            >
              {t("download_csv_data")}
            </CSVLink>
          </Button>
          <Table
            rowKey="id"
            dataSource={dataSource}
            columns={columns}
            pagination={{pageSize:10}}
          />
        </>
      )}
    </>
  );
};

export default QueueLength;
