import { groupedPlansForWeek } from "../../components/Schedular";
import { checkOverlap, isInputValid } from "../../helpers";
import { ICoordPlan } from "../MultiModePlan";
import { IColGroup } from "./IColGroup";

export class CoordColGroup implements IColGroup {
  plans: ICoordPlan[];
  cols: { plans: ICoordPlan[] }[];
  private static groupedPlans: groupedPlansForWeek = {};

  constructor(plans: ICoordPlan[], dayIndex: keyof groupedPlansForWeek) {
    this.plans = plans;
    this.cols = this.decomposeToColumns(dayIndex);
  }

  decomposeToColumns(dayIndex?: keyof groupedPlansForWeek) {
    const groupedOutput = [{ plans: this.plans }];
    try {
      if (isInputValid(dayIndex) && dayIndex !== undefined) {
        CoordColGroup.groupedPlans[dayIndex] = groupedOutput;
      }
    } catch (err) {}
    return groupedOutput;
  }

  static checkIntersection(
    plan: ICoordPlan,
    dayIndex: keyof groupedPlansForWeek
  ) {
    const hasOverlap = CoordColGroup.groupedPlans?.[dayIndex]?.some(
      (groupedPlanObj) => {
        return groupedPlanObj?.plans.some((planInGroupedPlans) => {
          return checkOverlap(plan, planInGroupedPlans);
        });
      }
    );

    return hasOverlap;
  }
}
