import BarrierService from "../../../../../../data/barrier/BarrierService";
import { MarkerForm } from "./MarkerForm";

export const BarrierModel: MarkerForm = {
  type: "barrier",
  addService: BarrierService.addBarrier,
  updateService: BarrierService.updateBarrier,
  items: [
    {
      label: "name",
      name: "name",
      type: "input",
      isRequired: true,
    },
    {
      label: "version",
      name: "version",
      type: "number",
      isRequired: false,
    },
    {
      label: "latitude",
      name: "latitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "longitude",
      name: "longitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "stream_url",
      name: "stream_url",
      type: "input",
      isRequired: false,
    },
    // {
    //   label: "stream_username",
    //   name: "stream_username",
    //   type: "input",
    //   isRequired: false,
    // },
    // {
    //   label: "stream_password",
    //   name: "stream_password",
    //   type: "input",
    //   isRequired: false,
    // },
    {
      label: "organization",
      name: "org_id",
      type: "dropdown",
      isRequired: true,
      dropdownItem: "orgList",
    },
    {
      label: "status",
      name: "status",
      type: "boolean",
      isRequired: true,
    },
    {
      label: "control_port",
      name: "control_port",
      type: "number",
      isRequired: false,
    },
    {
      label: "open_request_url",
      name: "open_request_url",
      type: "input",
      isRequired: true,
    },
    {
      label: "close_request_url",
      name: "close_request_url",
      type: "input",
      isRequired: true,
    },
    {
      label: "Proxy",
      name: "proxy",
      type: "boolean",
      isRequired: true,
    },
  ],
};
