import {
  ApartmentOutlined,
  FormOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import DarkButton from "../../components/custom-styled/button/DarkButton";
import { useContext, useEffect, useState } from "react";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/auth/AuthContext";

function ProfileControl() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { allOrganizations, setOrganizations } = useAuth();
  const [filteredOrganizations, setFilteredOrganizations] = useState<number[]>(
    []
  );

  const { removeComponentPermissions } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  const logoutClick = () => {
    localStorage.removeItem("layerList");
    removeComponentPermissions();
    navigate(`/login`);
  };

  const navigateToProfile = () => {
    navigate("/profile");
  };

  const organizationClick = (checkedValues: CheckboxValueType[]) => {
    setFilteredOrganizations(checkedValues as number[]);
  };

  const organizationShortcut = (type: "all" | "clear") => {
    if (type === "all") {
      setFilteredOrganizations(allOrganizations.map((item) => item.value));
    } else {
      setFilteredOrganizations([]);
    }
  };

  useEffect(() => {
    setFilteredOrganizations(allOrganizations.map((item) => item.value));
  }, [allOrganizations]);

  useEffect(() => {
    if (filteredOrganizations.length > 0) {
      setOrganizations(filteredOrganizations);
    }
  }, [filteredOrganizations]);

  const profileContent = (
    <Menu
      multiple
      style={{ width: "150px", marginTop: "15px" }}
      triggerSubMenuAction="hover"
      key="menu"
    >
      <Menu.Item onClick={navigateToProfile} key="100" icon={<UserOutlined />}>
        {t("profile")}
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate("/report")}
        key="120"
        icon={<FormOutlined />}
      >
        {t("reportBug")}
      </Menu.Item>
      {allOrganizations.length > 1 && (
        <Checkbox.Group
          value={filteredOrganizations}
          key="chckgr"
          style={{ width: "100%" }}
          onChange={organizationClick}
        >
          <Menu.SubMenu
            key="submenu"
            icon={<ApartmentOutlined />}
            title={t("organization")}
            popupClassName="subMenu"
          >
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button onClick={() => organizationShortcut("all")}>
                {t("all")}
              </Button>
              <Button onClick={() => organizationShortcut("clear")}>
                {t("clear")}
              </Button>
            </div>
            {allOrganizations.map((item, index) => {
              return (
                <Menu.Item key={"mi" + index}>
                  <Checkbox
                    key={"chc" + index}
                    value={item.value}
                    style={{ width: "100%" }}
                  >
                    {item.label}
                  </Checkbox>
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        </Checkbox.Group>
      )}

      <Menu.Divider />
      <Menu.Item key="s3" icon={<LogoutOutlined />} onClick={logoutClick}>
        {t("logout")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={profileContent} trigger={["click"]}>
      <DarkButton icon={<UserOutlined color="white" />} />
    </Dropdown>
  );
}

export default ProfileControl;
