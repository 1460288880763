import http from "../../../data/http.common";
import { Incident } from "./IncidentModel";

const getHistoricIncidents = function (startDate: number, endDate: number) {
  let params = {
    startDate: startDate,
    endDate: endDate,
  };
  return http.post<Incident[]>(`/web/incident/historical`, params);
};

const IncidentService = {
  getHistoricIncidents,
};

export default IncidentService;
