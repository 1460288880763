import { DownloadOutlined } from "@ant-design/icons";
import { Button, Form, Spin, Steps } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Junction, JunctionDeviceState } from "../../../data/junction/Junction";
import JunctionService from "../../../data/junction/JunctionService";
import { Row } from "./style";
import FileSaver from "file-saver";
import { useTranslation } from "react-i18next";
import { useDeviceState } from "../../../contexts/device/DeviceStateContext";

interface Props {
  selectedJunction?: Junction;
  kkcType:number;
}

function DownloadProgram(props: Props) {
  const { t } = useTranslation();
  const { selectedJunction, kkcType } = props;
  const [guidGetKkcProgram, setGuidKkcProgram] = useState<number>();
  const [kkcProgram, setKkcProgram] = useState<any>();
  const [stepper, setStepper] = useState<any>();
  const { deviceStateData } = useDeviceState();
  const [ldGetKkcProgram, setLdGetKkcProgram] = useState<boolean>(false);

  const onFinish = useCallback(() => {
    if(selectedJunction?.devicemodel_id==2){
        let generated_id =  Math.floor(100000000 + Math.random() * 900000000)
      setGuidKkcProgram(generated_id);
      setLdGetKkcProgram(true);
      JunctionService.getKKCProgram(selectedJunction!.id, generated_id).then(
        (r) => {
          setKkcProgram("data:application/octet-stream;base64," + r.data.result);
        }
      ).catch(e=>{
        console.log(e)
      })
    }
    else{
        let url='http://'+selectedJunction?.ipaddress+':'+selectedJunction?.http_port+'/kkc/GetKKCProgram'
        window.open(url, '_blank');
    }
  }, [selectedJunction]);
  


  useEffect(() => {
    if (kkcProgram && kkcType === 2) {
      FileSaver.saveAs(kkcProgram, selectedJunction?.id + ".maestroupload");
    }else if (kkcProgram && kkcType === 6) {
      FileSaver.saveAs(kkcProgram, selectedJunction?.id + ".DR2");
    }else if(kkcProgram){
      FileSaver.saveAs(kkcProgram, selectedJunction?.id + ".unknown");
    }
  }, [kkcProgram, kkcType, selectedJunction]);

  useEffect(() => {
    const { Step } = Steps;
    if (
      deviceStateData.filter((x) => x.guid === guidGetKkcProgram).length > 0
    ) {
      deviceStateData
        .filter((x) => x.guid === guidGetKkcProgram)
        .forEach((item) => {
          if (item.commandState === 6) {
            setTimeout(() => {
              setLdGetKkcProgram(false);
            }, 2000);
          }
          if (item.commandState === 5) {
            setLdGetKkcProgram(true);
          }
        });

      setStepper(
        <Steps direction="vertical" size="small" current={10}>
          {deviceStateData
            .filter((x) => x.guid === guidGetKkcProgram)
            .map((item, index) => (
              <Step
                key={index}
                title={JunctionDeviceState[item.commandState]}
              />
            ))}
        </Steps>
      );
    }
  }, [deviceStateData]);

  return (
    <Spin
      tip={stepper}
      spinning={ldGetKkcProgram}
      size="small"
      style={{ position: "relative" }}
    >
      <Row>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item>
            <Button icon={<DownloadOutlined />} htmlType="submit">
              { t("download") }
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </Spin>
  );
}

export default DownloadProgram;
