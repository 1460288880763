// context
import { MultiModePlanProvider } from "./context/MultiModePlanContext";

// Tab View Imports
import Plans from "./page";

// types
import { Junction } from "../../../data/junction/Junction";

interface Props {
  selectedJunction: Junction;
}

const MMSignalPlan: React.FC<Props> = ({ selectedJunction }) => {
  return (
    <>
      <MultiModePlanProvider selectedJunction={selectedJunction}>
        <Plans selectedJunction={selectedJunction} />
      </MultiModePlanProvider>
    </>
  );
};

export default MMSignalPlan;
