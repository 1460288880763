import BottomLeftControl from "./bottom-left-control/BottomLeftControl";
import BottomRightControl from "./bottom-right-control/BottomRightControl";
import TopRightControl from "./top-right-control/TopRightControl";
import TopLeftControl from "./top-left-control/TopLeftControl";

interface CustomMapControlProps {
  setLeftSiderVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

function CustomMapControl(props: CustomMapControlProps) {
  const { setLeftSiderVisible } = props;

  return (
    <>
      <TopLeftControl setLeftSiderVisible={setLeftSiderVisible} />
      <TopRightControl />
      <BottomRightControl />
      <BottomLeftControl />
    </>
  );
}

export default CustomMapControl;
