import { Table } from "antd";
import { useContext, useEffect, useMemo } from "react";
import TableTitle from "../TableTitle";
import "./plan-card.css";

import getPlanColor from "../../common/getPlanColor";
import { PlanContext } from "../../context/PlanContext";
import { PhaseType } from "../CreateNewPlan";

interface Props {
  planName?: string;
  columns: any;
  data: any;
  showEditPlan: any;
}

const PlanCard: React.FC<Props> = ({ columns, data, showEditPlan }) => {
  const planContextValues = useContext(PlanContext);
  const juncPhases: PhaseType[] = planContextValues.juncPhases;
  const phases = data?.phases;

  const editOrCopyPlan = (mode: "edit" | "copy") => {
    showEditPlan(data, mode);
  };

  const getPhaseNameById = (phaseId: string | number) => {
    return juncPhases.find((juncPhase: PhaseType) => {
      return juncPhase.internal_id === phaseId;
    })?.name;
  };

  const planCardTableDataSource = useMemo(() => {
    return phases.map((phase: any) => ({
      ...phase,
      name: getPhaseNameById(phase.internalId),
    }));
  }, [phases, juncPhases]);

  return (
    <div
      className="plan-card"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TableTitle
        cardInfo={data}
        planColor={getPlanColor(data)}
        editOrCopyPlan={editOrCopyPlan}
      />
      {phases && Array.isArray(phases) && phases.length > 0 && (
        <Table
          size="small"
          columns={columns}
          dataSource={planCardTableDataSource}
          pagination={false}
          bordered
          rowKey="internalId"
        />
      )}
    </div>
  );
};

export default PlanCard;
