import {
  PropsWithChildren,
  useEffect,
  useState,
  createContext,
  useContext,
} from "react";
import LicenseService from "../../data/license/LicenseService";
import { LicenseData, LicenseLayers } from "../../data/license/License";
import { modeList } from "../../pages/map/map-constants/module-control/MapToolbarData";
import { MapModule } from "@icms/ui-components"
export interface LicenseDataContextState {
  licenseData: LicenseData | undefined;
}

const licenseLayers: LicenseLayers[] = [
  { id: MapModule.JunctionModule, name: "junctionManagement" },
  { id: MapModule.BluesisModule, name: "bluesis" },
  { id: MapModule.CameraModule, name: "cameraModule" },
  { id: MapModule.VehiclePriortyModule, name: "emergencyPass" },
  { id: MapModule.VieroModule, name: "vieroManagement" },
  { id: MapModule.VmsModule, name: "vms" },
  { id: MapModule.UpsModule, name: "ups" },
  { id: MapModule.FCDModule, name: "fcd" },
  { id: MapModule.TaskManagementModule, name: "taskManagement" },
  { id: MapModule.InventoryModule, name: "inventoryManagement" },
  { id: MapModule.BarrierModule, name: "barrierManagement" },
  { id: MapModule.QueueLengthModule, name: "queueLength" },
];

const LicenseDataContext = createContext<LicenseDataContextState | undefined>(
  undefined
);

export function LicenseDataProvider({ children }: PropsWithChildren<{}>) {
  const [licenseData, setLicenseData] = useState<LicenseData>();

  useEffect(() => {
    LicenseService.licenseModules()
      .then((res) => {
        setLicenseData({
          defaultMapTile: res.data.Payload.Settings.frontendSettings.defaultMapTile,
          modules: res.data.Payload.Settings.licensedModules,
          toolbarButton: [
            {
              modeId: modeList.PoliceMode,
              active: res.data.Payload.Settings.frontendSettings.policeButton,
            },
          ],
          queueLength: res.data.Payload.Settings.frontendSettings.queueLength,
          licenseLayers,
          geoserverUrl: res.data.Payload.Settings.frontendSettings.geoServerURL,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <LicenseDataContext.Provider
      value={{
        licenseData,
      }}
    >
      {children}
    </LicenseDataContext.Provider>
  );
}

export function useLicenseData() {
  const context = useContext(LicenseDataContext);
  if (context === undefined) {
    throw new Error("useLicenseData must be used within a LicenseDataContext");
  }
  return context;
}
