import moment, { Moment } from "moment";

export const isInputValid = (input: any): boolean => {
  return input !== null && input !== undefined;
};

export const areInputsValid = (...inputs: any[]): boolean => {
  return inputs?.reduce((current, next) => {
    return current && isInputValid(next);
  }, true);
};

type TOverlapInput = {
  startTime: string | Moment;
  endTime: string | Moment;
  [key: string]: any;
};

type TConvertedTimeRangeObject = {
  startTime: Moment;
  endTime: Moment;
  [key: string]: any;
};

const convertStartEndObjToMoment = (
  range: TOverlapInput
): TConvertedTimeRangeObject => {
  return {
    startTime:
      typeof range.startTime === "string"
        ? moment(range.startTime, "hh:mm")
        : range.startTime,
    endTime:
      typeof range.endTime === "string"
        ? moment(range.endTime, "hh:mm")
        : range.endTime,
  };
};

export const checkOverlap = (
  range1: TOverlapInput,
  range2: TOverlapInput
): boolean => {
  range1 = convertStartEndObjToMoment(range1);
  range2 = convertStartEndObjToMoment(range2);

  let objectHasLaterEndTime;
  let objectHasSoonerEndTime;

  if ((range1.startTime as Moment).isAfter(range2.startTime)) {
    objectHasSoonerEndTime = range2;
    objectHasLaterEndTime = range1;
  } else {
    objectHasSoonerEndTime = range1;
    objectHasLaterEndTime = range2;
  }

  const theRefEndTime = objectHasSoonerEndTime?.endTime;

  const theRefStartTime = objectHasLaterEndTime.startTime;

  if (!(theRefEndTime as Moment).isAfter(theRefStartTime)) {
    return false;
  } else {
    return true;
  }
};
