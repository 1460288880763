import { Modal } from "antd";
import { CSSProperties, ReactNode, useEffect, useState } from "react";
import withLogging from "../../../high-order-components/LoggerHOC";

interface GenericMapModalProps {
  children: ReactNode;
  onClose?: () => void;
  modalTitle: JSX.Element;
  modalWidth?: string;
  maskClosable?: boolean;
  modalFooterHide?: boolean;
  bodyStyle?: CSSProperties;
}

function GenericMapModal(props: GenericMapModalProps) {
  const {
    children,
    onClose,
    modalTitle,
    modalWidth,
    maskClosable,
    modalFooterHide,
    bodyStyle,
  } = props;
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const onDissmis = () => {
    setVisible(false);
    onClose?.();
  };

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      destroyOnClose={true}
      onCancel={onDissmis}
      width={modalWidth ?? "90%"}
      maskClosable={maskClosable}
      footer={modalFooterHide ? null : <></>}
      bodyStyle={bodyStyle}
    >
      {children}
    </Modal>
  );
}

export default withLogging(GenericMapModal);
