import { Button, Col, Modal, Row, Select, Tabs, Tooltip } from "antd";
import L, { DivIcon, LatLng } from "leaflet";
import {
  TaskPriority,
  taskStatusColors,
  taskStatusNames,
  taskStatusNamesTR,
  taskTypeEnum,
  taskTypeImages,
} from "../../../../data/task-management/TaskManagement";
import { MapContainer, Marker } from "react-leaflet";
import { useEffect, useState } from "react";
import { t } from "i18next";
import {
  DeleteFilled,
  EditFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useTaskManagementData } from "../../../../contexts/taskmanagement/TaskManagementDataContext";
import {
  deleteTask,
  getTaskActivities,
  getTaskComments,
  postActivity,
  updateTaskStatusService,
} from "../../../../data/task-management/TaskManagementService";
import { CommentList } from "./components/CommentList";
import { ActivityList } from "./components/ActivityList";
import { ImageList } from "./components/ImageList";
import MapTileControl from "../../controls/bottom-right-control/MapTileControl";

interface Props {
  latlon: LatLng;
  selectedTask: any | undefined;
  setSelectedTask: any;
  onEditTask: (task: any) => void;
  onDeleteTask: (task: any) => void;
}

const { Option } = Select;
const { TabPane } = Tabs;

export const TaskManagementDetail = ({
  latlon,
  selectedTask,
  setSelectedTask,
  onEditTask,
  onDeleteTask,
}: Props) => {
  const [customIcon, setCustomIcon] = useState<DivIcon>();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("-");
  const [statusServiceLoading, setStatusServiceLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [imageList, setImageList] = useState<any[]>([]);
  const [commentList, setCommentList] = useState([]);
  const [activityList, setActivityList] = useState([]);

  const { technicianList, updateTaskStatus } = useTaskManagementData();

  useEffect(() => {
    if (selectedTask) {
      setImageList(selectedTask.detail?.images || null);
      const date = new Date(selectedTask.start_time);
      const endDate = new Date(selectedTask.end_time);
      const userLanguage = navigator.language || navigator.languages;
      setStartTime(
        date.toLocaleDateString(userLanguage, {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })
      );
      if (selectedTask.end_time) {
        setEndTime(
          endDate.toLocaleDateString(userLanguage, {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })
        );
      }
      setCustomIcon(
        L.divIcon({
          className: "custom-div-icon",
          html: `<div style="border: 3px solid ${
            taskStatusColors[selectedTask.task_status]
          }; background-color: #ffffff; width: 34px; height: 34px; border-radius: 50%; display: flex; justify-content: center; align-items: center; box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.4);"><img src="data:image/png;base64,${
            selectedTask.task_type >= 1 &&
            selectedTask.task_type <= taskTypeImages.length
              ? taskTypeImages[selectedTask.task_type]
              : taskTypeImages[0]
          }" alt="Custom Icon" style="width: 16px; height: 16px;"/></div>`,
          iconAnchor: [18, 11],
        })
      );

      //Comment Activity and Image services
      const fetchComments = async () => {
        await getTaskComments(selectedTask.id).then((res) => {
          setCommentList(res.data);
        });
      };
      const fetchActivities = async () => {
        await getTaskActivities(selectedTask.id).then((res) => {
          setActivityList(res.data);
        });
      };

      fetchComments();
      fetchActivities();
    }
  }, [selectedTask]);

  const renderAttendantList = (attendant_list: any[]) => {
    const attendantList = attendant_list.map((assigneeId) => {
      const foundUser = technicianList.find(
        (techinican) => techinican.id === assigneeId
      );
      return (
        <span
          key={foundUser.id}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            fontSize: "13px",
            color: "#333333",
            fontWeight: "300",
          }}
        >
          <Tooltip title={`Email: ${foundUser.email}`} placement="top">
            <InfoCircleOutlined style={{ color: "#656565" }} />
          </Tooltip>
          {foundUser.name}
        </span>
      );
    });
    return attendantList;
  };

  const handleTaskStatus = async (taskStatus: any) => {
    setStatusServiceLoading(true);
    await updateTaskStatusService(selectedTask.id, { status: taskStatus })
      .then(async () => {
        updateTaskStatus(selectedTask.id, taskStatus);
        setSelectedTask((prev: any) => ({
          ...prev,
          task_status: taskStatus,
        }));
        await postActivity(selectedTask.id, {
          activity: `Görev Durumu, ${taskStatusNamesTR[taskStatus]} olarak değiştirildi`,
        }).catch(() => {});
      })
      .catch((err) => {
        console.log("Error is", err);
      })
      .finally(() => {
        setStatusServiceLoading(false);
      });
  };

  const handleTaskEdit = () => {
    onEditTask(selectedTask);
  };

  const handleDelete = async () => {
    await deleteTask(selectedTask.id)
      .then(() => {
        onDeleteTask(selectedTask);
      })
      .catch((err: any) => {
        console.log("error is", err);
      });
  };

  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={12}>
          <MapContainer
            center={latlon}
            zoom={18}
            animate={false}
            className="task-detail-map"
          >
            <MapTileControl />
            <Marker position={latlon} icon={customIcon}></Marker>
          </MapContainer>
        </Col>

        <Col xs={24} sm={24} md={12} className="task-detail-container">
          <div style={{ position: "absolute", right: "5rem", top: 14 }}>
            <Button shape="round" onClick={handleTaskEdit}>
              <EditFilled color="#656565" size={99} />
              {t("edit")}
            </Button>
          </div>
          <div style={{ position: "absolute", right: 0, top: 14 }}>
            <Button
              shape="round"
              onClick={() => {
                setShowDeleteModal(true);
              }}
              className="delete-btn"
            >
              <DeleteFilled color="#656565" size={99} />
              {t("delete")}
            </Button>
          </div>
          <Row className="detail-modal-row">
            <div className="task-detail-item">
              <span className="detail-label">{t("title")}:</span>
              <span className="detail-text">{selectedTask?.title}</span>
            </div>
          </Row>

          <Row className="detail-modal-row">
            <Col xs={24} sm={24} md={12}>
              <div className="task-detail-item">
                <span className="detail-label">{t("status")}:</span>
                <div className="custom-selector">
                  {" "}
                  <Select
                    defaultValue={t(taskStatusNames[selectedTask.task_status])}
                    className="task-priority-select"
                    onChange={handleTaskStatus}
                    loading={statusServiceLoading}
                    disabled={statusServiceLoading}
                  >
                    {Object.keys(taskStatusNames).map((stat: any) => (
                      <Option key={stat} value={stat}>
                        {t(taskStatusNames[stat])}
                      </Option>
                    ))}
                  </Select>
                  <div
                    className="status-circle"
                    style={{
                      backgroundColor:
                        taskStatusColors[selectedTask.task_status],
                    }}
                  />
                </div>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <div className="task-detail-item">
                <span className="detail-label">{t("priority")}:</span>
                <span className="detail-text">
                  {selectedTask?.priority !== undefined &&
                  selectedTask?.priority !== null
                    ? t(TaskPriority[selectedTask.priority])
                    : "-"}
                </span>
              </div>
            </Col>
          </Row>

          <Row className="detail-modal-row">
            <Col xs={24} sm={24} md={12}>
              <div className="task-detail-item">
                <span className="detail-label">{t("start_time")}:</span>
                <span className="detail-text">{startTime}</span>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <div className="task-detail-item">
                <span className="detail-label">{t("end_time")}:</span>
                <span className="detail-text">{endTime}</span>
              </div>
            </Col>
          </Row>

          <Row className="detail-modal-row">
            <Col xs={24} sm={24} md={12}>
              <div className="task-detail-item">
                <span className="detail-label">{t("task_type")}:</span>
                <span className="detail-text">
                  {selectedTask?.task_type
                    ? taskTypeEnum[selectedTask.task_type]
                    : "-"}
                </span>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12}>
              <div className="task-detail-item">
                <span className="detail-label">{t("est_time")}:</span>
                <span className="detail-text">
                  {selectedTask?.est_time ? selectedTask.est_time : "-"}
                </span>
              </div>
            </Col>
          </Row>

          <Row className="detail-modal-row">
            <div className="task-detail-item">
              <span className="detail-label">{t("description")}:</span>
              <span className="detail-text">
                {selectedTask?.description || "-"}
              </span>
            </div>
          </Row>

          <Row className="detail-modal-row">
            <div className="task-detail-item">
              <span className="detail-label">{t("attendant_list")}:</span>
              <span className="assignee-list">
                {renderAttendantList(
                  selectedTask?.detail?.info?.attendant_list
                )}
              </span>
            </div>
          </Row>
        </Col>
      </Row>
      <Row>
        <Tabs style={{ width: "100%" }}>
          <TabPane key="1" tab={<span>{t("images")}</span>}>
            <ImageList
              taskId={selectedTask.id}
              imageList={imageList}
              setImageList={setImageList}
              userList={technicianList}
            />
          </TabPane>
          <TabPane key="2" tab={<span>{t("comments")}</span>}>
            <CommentList
              taskId={selectedTask.id}
              commentList={commentList}
              setCommentList={setCommentList}
              userList={technicianList}
            />
          </TabPane>
          <TabPane key="3" tab={<span>{t("activities")}</span>}>
            <ActivityList
              activityList={activityList}
              userList={technicianList}
            />
          </TabPane>
          {/* <TabPane key="4" tab={<span>{t("inventoriess")}</span>}>
            <h1>Inventory</h1>
          </TabPane> */}
        </Tabs>
      </Row>
      <Modal
        title={t("delete_record")}
        visible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        footer={[
          <Button key="cancel" onClick={() => setShowDeleteModal(false)}>
            {t("abort")}
          </Button>,
          <Button key="delete" type="primary" danger onClick={handleDelete}>
            {t("delete")}
          </Button>,
        ]}
      >
        <p>{t("it_will_be_deleted_r_you_sure")}</p>
      </Modal>
    </>
  );
};
