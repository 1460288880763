import { createContext, useEffect, useMemo, useState } from "react";
import { IArchiceContentData } from "../components/Archive";
import {
  fetcHistoricalQueueLength,
  fetchAiPredictionData,
  fetchPastRecord,
  fetchPhaseData,
  fetchPhaseDataExport,
  fetchSsmData,
  fetchStatusData
} from "../services/ArchiveServices";

export const StatisticContext = createContext<contextProps | undefined>(
  undefined
);

enum epicConstant {
  summary = "summary",
  archive = "archive",
  vehicleDensity = "vehicleDensity",
}

enum subConstant {
  daily = "daily",
  pastRecord = "pastRecord",
  phaseData = "phaseData",
  ssmData = "ssmData",
  weekly = "weekly",
  monthly = "monthly",
  aiPredictionData = "aiPredictionData",
  queueLengthData = "queueLengthData",
  statusData = "statusData"
}

interface IHeaderDirector {
  [epicConstant.summary]: ISummary;
  [epicConstant.archive]: IArchive;
  [epicConstant.vehicleDensity]: IVehicleDensity;
}

interface ISummary {
  [subConstant.daily]: boolean;
}

export interface IArchive {
  [subConstant.pastRecord]: boolean;
  [subConstant.phaseData]: boolean;
  [subConstant.ssmData]: boolean;
  [subConstant.statusData]: boolean;
  [subConstant.aiPredictionData]: boolean;
  [subConstant.queueLengthData]: boolean;
}

interface IVehicleDensity {
  [subConstant.daily]: boolean;
  [subConstant.weekly]: boolean;
  [subConstant.monthly]: boolean;
}

const initialHeaderDirector: IHeaderDirector = {
  [epicConstant.summary]: {
    [subConstant.daily]: false,
  },
  [epicConstant.archive]: {
    [subConstant.pastRecord]: false,
    [subConstant.phaseData]: false,
    [subConstant.ssmData]: false,
    [subConstant.statusData]: false,
    [subConstant.aiPredictionData]: false,
    [subConstant.queueLengthData]: false,
  },
  [epicConstant.vehicleDensity]: {
    [subConstant.daily]: false,
    [subConstant.weekly]: false,
    [subConstant.monthly]: false,
  },
};

// boostrap archive cache with initialCacheTemplate(s)
const initialCacheTemplate = {
  formValues: {},
  search: "",
  loading: false,
};

export type TArchiveFormCache = {
  [K in keyof IArchive]: typeof initialCacheTemplate;
};

const initialArchiveFormCache = (): TArchiveFormCache => {
  const arr: (keyof IArchive)[] = [
    subConstant.pastRecord,
    subConstant.phaseData,
    subConstant.ssmData,
    subConstant.statusData,
    subConstant.aiPredictionData,
    subConstant.queueLengthData,
  ];

  /**
   * @output_example
   * {
   *  pastRecord: initialTemplate,
   *  phaseData: initialTemplate,
   *  ssmData: initialTemplate,
   * }
   */
  return arr
    .map((item) => {
      return {
        [item]: initialCacheTemplate,
      } as TArchiveFormCache;
    })
    .reduce(
      (
        previousValue: TArchiveFormCache,
        currentValue: TArchiveFormCache
      ): TArchiveFormCache => {
        return {
          ...previousValue,
          ...currentValue,
        } as TArchiveFormCache;
      }
    );
};

interface IDeterminedServiceAndContentKey {
  serviceExport?:any;
  service: any;
  key: keyof IArchiceContentData | null;
}

interface contextProps {
  headerDirector: IHeaderDirector;
  updater: (
    epic: keyof typeof epicConstant,
    sub: keyof typeof subConstant
  ) => void;
  determinedArchiveServiceAndContentKey: IDeterminedServiceAndContentKey;
  archiveFormCache: {
    state: TArchiveFormCache;
    setter: React.Dispatch<React.SetStateAction<TArchiveFormCache>>;
  };
}

export const StatisticProvider = (props: any) => {
  const [headerDirector, setHeaderDirector] = useState<IHeaderDirector>({
    ...initialHeaderDirector,
    [epicConstant.summary]: {
      ...initialHeaderDirector[epicConstant.summary],
      [subConstant.daily]: true,
    },
  });

  const updateHeaderDirector = (
    epic: keyof typeof epicConstant,
    sub: keyof typeof subConstant
  ) => {
    // if epic property does not contain sub property inside it
    if (
      !(
        headerDirector.hasOwnProperty(epic) &&
        headerDirector[epic].hasOwnProperty(sub)
      )
    ) {
      return;
    }

    setHeaderDirector({
      ...initialHeaderDirector,
      [epic]: {
        ...initialHeaderDirector[epic],
        [sub]: true,
      },
    });
  };

  const [archiveFormCache, setArchiveFormCache] = useState<TArchiveFormCache>(
    initialArchiveFormCache()
  );

  // service indentifier
  const determinedArchiveServiceAndContentKey: IDeterminedServiceAndContentKey =
    useMemo(() => {
      const archiveDirector = headerDirector?.archive;
      if (archiveDirector?.pastRecord) {
        return {
          service: fetchPastRecord,
          key: "pastRecord",
        };
      } else if (archiveDirector?.phaseData) {
        return {
          service: fetchPhaseData,
          serviceExport:fetchPhaseDataExport,
          key: "phaseData",
        };
      } else if (archiveDirector?.ssmData) {
        return {
          service: fetchSsmData,
          key: "ssmData",
        };
      } else if (archiveDirector?.aiPredictionData) {
        return {
          service: fetchAiPredictionData,
          key: "aiPredictionData",
        };
      } else if (archiveDirector?.queueLengthData) {
        return {
          service: fetcHistoricalQueueLength,
          key: "queueLengthData",
        };
      } else if (archiveDirector?.statusData) {
        return {
          service: fetchStatusData,
          key: "statusData",
        };
      } else {
        return {
          service: null,
          key: null,
        };
      }
    }, [headerDirector]);

  return (
    <StatisticContext.Provider
      value={{
        updater: updateHeaderDirector,
        headerDirector: headerDirector,
        determinedArchiveServiceAndContentKey:
          determinedArchiveServiceAndContentKey,
        archiveFormCache: {
          state: archiveFormCache,
          setter: setArchiveFormCache,
        },
      }}
    >
      {props.children}
    </StatisticContext.Provider>
  );
};
