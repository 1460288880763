import React from "react";
import { useLicenseData } from "../../contexts/license/LicenseDataContext";
import IncidentTable from "./components/IncidentTable";

function HistoricalIncident() {
  const { licenseData } = useLicenseData();

  return (
    <>
      {licenseData?.modules.incidentDetection && (
        <IncidentTable></IncidentTable>
      )}
    </>
  );
}

export default HistoricalIncident;
