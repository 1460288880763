import { Card, Col, Row } from "antd";
import { latLng } from "leaflet";
import { Junction } from "../../../../../data/junction/Junction";
import MapViews from "../../../../junction/ViewTab/MapViews";
import React, { useEffect } from "react";
import { SsmDataProvider } from "../../../../../contexts/junction/SsmDataContext";
import { JunctionStatusDataProvider } from "../../../../../contexts/junction/JunctionStatusDataContext";
import { useWebsocket } from "../../../../../contexts/websocket/WebSocketContext";
import JunctionModalTitle from "../../../layers/junction/JunctionModalTitle";
import { PhaseDataProvider } from "../../../../../contexts/junction/PhaseDataContext";

interface Props {
  selectedMarkers: Junction[];
}

const gutter = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};

const cardHeadStyle = { fontSize: "14px", fontWeight: "500" };

const MultipleJunctionMonitoring = React.forwardRef<any, Props>(
  (props, ref) => {
    const { selectedMarkers } = props;
    const { SetMultipleJunction } = useWebsocket();

    useEffect(() => {
      const idList = selectedMarkers.map((item: any) => +item.id);
      SetMultipleJunction(idList);
    }, [selectedMarkers]);

    return (
      <>
        {selectedMarkers && selectedMarkers.length > 0 ? (
          <Row gutter={gutter}>
            {selectedMarkers.map((junction) => (
              <Col
                style={{ marginTop: "16px" }}
                xl={selectedMarkers.length % 2 === 0 ? 12 : 8}
                lg={selectedMarkers.length % 2 === 0 ? 12 : 8}
                md={selectedMarkers.length % 2 === 0 ? 12 : 8}
                sm={24}
                key={junction.id}
              >
                <Card
                  bodyStyle={{
                    padding: "0",
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                  }}
                  headStyle={cardHeadStyle}
                  style={{
                    marginBottom: 20,
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <JunctionStatusDataProvider>
                    <PhaseDataProvider>
                      <SsmDataProvider>
                        <JunctionModalTitle
                          componentName="JunctionModalTitle"
                          selectedJunction={junction}
                          margin="0"
                        />
                        <div
                          style={{
                            flex: 1,
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <MapViews
                            selectedJunction={junction}
                            latlon={latLng(junction.latitude, junction.longitude)}
                            changeTab={true}
                            closeMapEvents={true}
                          />
                        </div>
                      </SsmDataProvider>
                    </PhaseDataProvider>
                  </JunctionStatusDataProvider>
                </Card>
              </Col>
            ))}
          </Row>
        ) : null}
      </>
    );
  }
);

export default MultipleJunctionMonitoring;
