import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { AiPrediction } from "../../data/ai-prediction/AiPrediction";
import { socket } from "../websocket/WebSocketContext";

interface AiPredictionProps {
  aiPrediction: AiPrediction;
}

const AiPredictionContext = createContext<AiPredictionProps | undefined>(
  undefined
);

export function AiPredictionProvider({ children }: PropsWithChildren<{}>) {
  const [aiPrediction, setAiPrediction] = useState<AiPrediction>();

  useEffect(() => {
    socket.on("aiPrediction", (data) => {
      setAiPrediction(data);
    });
    return () => {
      socket.off("aiPrediction");
    };
  }, []);

  return (
    <AiPredictionContext.Provider
      value={{
        aiPrediction: aiPrediction!,
      }}
    >
      {children}
    </AiPredictionContext.Provider>
  );
}

export function useAiPrediction() {
  const context = useContext(AiPredictionContext);
  if (context === undefined) {
    return {
      aiPrediction: null,
    };
    // throw new Error(
    //   "useAiPrediction must be used within a AiPredictionContext"
    // );
  }
  return context;
}
