import React, { useState } from "react";
import { VmsDataProvider } from "../../../contexts/vms/VmsDataContext";
import { Tabs, Typography, Layout, Card, PageHeader, Space } from "antd";
import VmsGroup from "./group/VmsGroup";
import MultiModeVmsEditorIndex from "./editor/MultiModeVmsEditorIndex";
import { useTranslation } from "react-i18next";
import MultiModeVmsPlan from "./plan/MultiModeVmsPlan";
import { EditOutlined, UnorderedListOutlined, FieldTimeOutlined } from "@ant-design/icons";
import "./MultiModeVmsMain.css"; // Custom CSS file for additional styling

const { TabPane } = Tabs;
const { Title } = Typography;
const { Content } = Layout;

const MultiModeVmsMain = () => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState("1");
  const [sendedDrawings, setSendedDrawings] = useState<any | null>(null);

  const handleChangeTab = (key: string) => {
    setActiveKey(key);
  };

  return (
    <VmsDataProvider>
      <Layout style={{ padding: '20px', background: '#f0f2f5' }}>
        <PageHeader
          className="site-page-header"
          title={<Title level={2} style={{ color: '#1890ff' }}>{t("VMS Çoklu Yönetim Sayfası")}</Title>}
          subTitle={t("Lütfen yönetmek istediğiniz sekmeyi seçin")}
          style={{ marginBottom: '20px', background: '#fff', padding: '20px', borderRadius: '8px' }}
        />
        <Card style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
          <Tabs
            activeKey={activeKey}
            onChange={handleChangeTab}
            destroyInactiveTabPane
            type="line"
            size="large"
            tabBarGutter={32}
            tabBarStyle={{ marginBottom: '20px' }}
          >
            <TabPane
              tab={
                <span>
                  <UnorderedListOutlined />
                  {t("VMS Grupları")}
                </span>
              }
              key="1"
            >
              <VmsGroup />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <EditOutlined />
                  {t("VMS Editör")}
                </span>
              }
              key="2"
            >
              <MultiModeVmsEditorIndex
                selectedVms={null}
                sendedDrawings={sendedDrawings}
                setSendedDrawings={setSendedDrawings}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <FieldTimeOutlined />
                  {t("VMS Plan")}
                </span>
              }
              key="3"
            >
              <MultiModeVmsPlan
                selectedVms={null}
                sendedDrawingsFromEditor={sendedDrawings}
              />
            </TabPane>
          </Tabs>
        </Card>
      </Layout>
    </VmsDataProvider>
  );
};

export default MultiModeVmsMain;
