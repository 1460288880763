import Schedular from "./components/Schedular";
import { MultiModeSchedularProvider } from "./context/MultiModeSchedularContext";
import "./styles.css";
import "antd/dist/antd.min.css";

const SPMultiModeSchedular: React.FC = () => {
  return (
    <>
      <MultiModeSchedularProvider>
        <Schedular />
      </MultiModeSchedularProvider>
    </>
  );
};

export default SPMultiModeSchedular;
