import {
  MapContainer,
  ZoomControl,
} from "react-leaflet";
import { LatLng, Map as LMap } from "leaflet";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Select,
  Slider,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import { Junction, JunctionFormProps } from "../../data/junction/Junction";
import {
  JunctionGroup,
  JunctionGroupEnum,
  JunctionGroupFormProps,
} from "../../data/junction-group/JunctionGroup";
import GroupandDetectorEditor from "./GroupandDetectorEditor";
import Fullscreen from "react-leaflet-fullscreen-plugin";
import { AuthService, CustomControlContent } from "@icms/ui-components";
import {
  DownloadOutlined,
  EditOutlined,
  FilterOutlined,
  PlusOutlined,
  TableOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { layerGroup } from "./junction.data";
import FileSaver from "file-saver";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import tokml from "tokml";
import {
  JunctionPhase,
  JunctionPhaseFormProps,
} from "../../data/junction-phase/JunctionPhase";
import {
  JunctionDetector,
  JunctionDetectorEnum,
  JunctionDetectorFormProps,
} from "../../data/junction-detector/JunctionDetector";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import OrganizationService from "../../data/organization/OrganizationService";
import MapTileControl from "../map/controls/bottom-right-control/MapTileControl";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload";
import JunctionService from "../../data/junction/JunctionService";
import L from "leaflet";
import { confirmAlert } from "react-confirm-alert";
import ReactDOM from "react-dom";
interface Props {
  onMapCreate?: (map: LMap) => void;
  children?: any;
  latlon: LatLng;
  latlonGroup?: LatLng;
  latlonDetector?: LatLng;
  zoom: number;
  selectedJunction?: Junction | undefined;
  onJunctionAdd: (g: Junction) => void;
  onJunctionUpdate: (g: Junction) => void;
  onGroupGet: any;
  onDetectorGet: any;
  onGroupAdd: (g: JunctionGroup) => void;
  onGroupUpdate: (g: JunctionGroup) => void;
  onDetectorAdd: (d: JunctionDetector) => void;
  onDetectorUpdate: (d: JunctionDetector) => void;
  onPhaseAdd: (g: JunctionPhase) => void;
  onPhaseUpdate: (g: JunctionPhase) => void;
  onPhaseDelete: (g: JunctionPhase) => void;
  editorMapLayerFilter?: JSX.Element;
  groups?: JunctionGroup[];
  detectors?: JunctionDetector[];
  phases?: JunctionPhase[];
  phaseGroups: any[];
  phaseDetectors: any[];
  setSelectedGroups: any;
  setSelectedDetectors: any;
  setIsPhaseOperationStart?: any;
  bindedGroup?: JunctionGroup | undefined;
  bindedDetector?: JunctionDetector | undefined;
  setRotationAngle: any;
  setRotationAngleDetector: any;
  setBindedGroup: any;
  setBindedDetector: any;
  isPedestrianGroupValue: any;
}

const { Title } = Typography;

const MapEditor = React.forwardRef<any, Props>((props, ref) => {
  message.config({
    getContainer: () => document.getElementById("miniMap")!,
  });
  const {
    onMapCreate,
    children,
    latlon,
    zoom,
    selectedJunction,
    onJunctionAdd,
    onJunctionUpdate,
    onGroupGet,
    onDetectorGet,
    onGroupAdd,
    onGroupUpdate,
    onDetectorAdd,
    onDetectorUpdate,
    onPhaseAdd,
    onPhaseUpdate,
    onPhaseDelete,
    editorMapLayerFilter,
    groups,
    detectors,
    phases,
    latlonGroup,
    latlonDetector,
    phaseGroups,
    phaseDetectors,
    setSelectedGroups,
    setSelectedDetectors,
    setIsPhaseOperationStart,
    bindedGroup,
    bindedDetector,
    setRotationAngle,
    setRotationAngleDetector,
    setBindedGroup,
    setBindedDetector,
    isPedestrianGroupValue,
  } = props;
  const { t } = useTranslation();

  const [selectedGroup, setSelectedGroup] = useState<JunctionGroup>();
  const [selectedDetector, setSelectedDetector] = useState<JunctionDetector>();

  const [map, setMap] = useState<LMap>();
  const [junctionDrawerIsVisible, setJunctionDrawerIsVisible] =
    useState<boolean>(false);
  const [groupDrawerIsVisible, setGroupDrawerIsVisible] =
    useState<boolean>(false);
  const [detectorDrawerIsVisible, setDetectorDrawerIsVisible] =
    useState<boolean>(false);
  const [groupListDrawerIsVisible, setGroupListDrawerIsVisible] =
    useState<boolean>(false);
  const [detectorListDrawerIsVisible, setDetectorListDrawerIsVisible] =
    useState<boolean>(false);
  const [phaseListDrawerIsVisible, setPhaseListDrawerIsVisible] =
    useState<boolean>(false);
  const [addPhaseDrawerIsVisible, setAddPhaseDrawerIsVisible] =
    useState<boolean>(false);

  const [toolbarVisible, setToolbarVisible] = useState<boolean>(true);
  const [typeId, setTypeId] = useState<any>();
  const [angleValue, setAngleValue] = useState<number>(0);
  const [isMapFullscreen, setIsMapFullscreen] = useState<boolean>(false);
  const [selectedPhase, setSelectedPhase] = useState<JunctionPhase>();
  const [deleteButtonVisible, setDeleteButtonVisible] =
    useState<boolean>(false);
  const [filteredOrganizationList, setFilteredOrganizationList] = useState<
    any[]
  >([]);

  const [isPedestrianGroup, setIsPedestrianGroup] = useState<boolean>(false);
  const [junctionForm] = Form.useForm();
  const [groupForm] = Form.useForm();
  const [addPhaseForm] = Form.useForm();
  const [detectorForm] = Form.useForm();

  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const groupColumns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      render: (input: any) => (
        <Tag color="black" key={input}>
          {input.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Internal ID",
      dataIndex: "internal_id",
      key: "internal_id",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
      sorter: (a: any, b: any) => a.internal_id - b.internal_id,
    },
    {
      title: t("ags_value"),
      dataIndex: "ags_value",
      key: "ags_value",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("ags_percent"),
      dataIndex: "ags_percent",
      key: "ags_percent",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("angle"),
      dataIndex: "angle",
      key: "angle",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("latitude"),
      dataIndex: "latitude",
      key: "latitude",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("longitude"),
      dataIndex: "longitude",
      key: "longitude",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
  ];
  const detectorColumns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      render: (input: any) => (
        <Tag color="black" key={input}>
          {input.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Internal ID",
      dataIndex: "internal_id",
      key: "internal_id",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
      sorter: (a: any, b: any) => a.internal_id - b.internal_id,
    },
    {
      title: t("device_type"),
      dataIndex: "devicetype_id",
      key: "devicetype_id",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {JunctionDetectorEnum[input]}
        </Tag>
      ),
    },
    {
      title: t("angle"),
      dataIndex: "angle",
      key: "angle",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("ip_address"),
      dataIndex: "ipaddress",
      key: "ipaddress",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("http_port"),
      dataIndex: "http_port",
      key: "http_port",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("sftp_port"),
      dataIndex: "sftp_port",
      key: "sftp_port",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("ping_time"),
      dataIndex: "pingtime",
      key: "pingtime",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("version"),
      dataIndex: "version",
      key: "version",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("latitude"),
      dataIndex: "latitude",
      key: "latitude",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("longitude"),
      dataIndex: "longitude",
      key: "longitude",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
  ];

  const phaseColumns = [
    {
      title: "Internal ID",
      dataIndex: "internal_id",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
      sorter: (a: any, b: any) => a.internal_id - b.internal_id,
    },
    {
      title: t("phase_name"),
      dataIndex: "name",
      render: (input: any) => (
        <Tag color="black" key={input}>
          {input.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t("min_sequence_timeout"),
      dataIndex: "min_sequence_timeout",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("max_sequence_timeout"),
      dataIndex: "max_sequence_timeout",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("groups"),
      dataIndex: "groups",
      render: (groups: number[] | null) => {
        if (groups === null) {
          return null;
        }

        const groupNames = getGroupName(groups);
        const internalIds = getGroupInternalId(groups);

        return groupNames.map((tag, index) => (
          <div key={tag}>
            <Tag color="blue">
              {internalIds[index]} - {tag}
            </Tag>
          </div>
        ));
      },
    },
    {
      title: t("Loops"),
      dataIndex: "detectors",
      render: (detectors: number[] | null) => {
        if (detectors === null || detectors === undefined) {
          detectors = [];
        }
        const detectorNames = getDetectorName(detectors);
        const internalIds = getDetectorInternalId(detectors);

        return detectorNames.map((tag, index) => (
          <div key={tag}>
            <Tag color="blue">
              {internalIds[index]} - {tag}
            </Tag>
          </div>
        ));
      },
    },
    {
      title: t("output_number"),
      dataIndex: "output_number",
      render: (input: any) => (
        <Tag color="gray" key={input}>
          {input}
        </Tag>
      ),
    },
    {
      title: t("processes"),
      dataIndex: "key",
      key: "key",
      render: (id: any) => (
        <Typography.Link
          onClick={() => {
            setSelectedPhase(phases?.find((x) => x.key === id));
          }}
        >
          {t("edit")}
        </Typography.Link>
      ),
    },
  ];

  useEffect(() => {
    let user = AuthService.getUser();
    if (user.user_info.org_id_list.length > 1) {
      OrganizationService.getOrganizations()
        .then((res) => {
          setFilteredOrganizationList(
            res.data
              .filter((item) => user.user_info.org_id_list.includes(item.id))
              .map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })
          );
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    if (selectedPhase) {
      addPhaseForm.setFieldsValue({ internal_id: selectedPhase.internal_id });
      addPhaseForm.setFieldsValue({ name: selectedPhase.name });
      addPhaseForm.setFieldsValue({
        max_sequence_timeout: selectedPhase.max_sequence_timeout,
      });
      addPhaseForm.setFieldsValue({
        min_sequence_timeout: selectedPhase.min_sequence_timeout,
      });
      addPhaseForm.setFieldsValue({
        output_number: selectedPhase.output_number,
      });
      addPhaseForm.setFieldsValue({ vip_phase: selectedPhase.vip_phase });
      setAddPhaseDrawerIsVisible(true);
      setIsPhaseOperationStart(true);
      setDeleteButtonVisible(true);
    } else {
      setAddPhaseDrawerIsVisible(false);
      setIsPhaseOperationStart(false);
      setDeleteButtonVisible(false);
    }
  }, [addPhaseForm, selectedPhase, setIsPhaseOperationStart]);

  useEffect(() => {
    if (addPhaseDrawerIsVisible === true) {
      setPhaseListDrawerIsVisible(false);
    }
  }, [addPhaseDrawerIsVisible]);

  useEffect(() => {
    if (addPhaseDrawerIsVisible) {
      setIsPhaseOperationStart(true);
    } else {
      setIsPhaseOperationStart(false);
    }
  });

  const triggerFullscreenMap = () => {
    let element: HTMLElement = document.getElementsByClassName(
      "leaflet-control-zoom-fullscreen fullscreen-icon"
    )[0] as HTMLElement;
    element.click();
  };

  const mapCreate = useCallback(
    (m: LMap) => {
      setMap(m);
      onMapCreate?.(m);
      triggerFullscreenMap();
    },
    [onMapCreate]
  );

  const snapMap = useMemo(
    () =>
      new SimpleMapScreenshoter({
        screenName: "junction_" + selectedJunction?.name.toString(),
        position: "bottomleft",
      }),
    [selectedJunction?.name]
  );

  const onJunctionDrawerCancel = useCallback(() => {
    setJunctionDrawerIsVisible(false);
  }, []);

  const onGroupDrawerCancel = useCallback(() => {
    setGroupDrawerIsVisible(false);
    onGroupGet();
  }, [onGroupGet]);

  const onDetectorDrawerCancel = useCallback(() => {
    setDetectorDrawerIsVisible(false);
    onDetectorGet();
  }, [onDetectorGet]);

  const onAddPhaseDrawerCancel = useCallback(() => {
    setIsPhaseOperationStart(false);
    addPhaseForm.resetFields();
    setAddPhaseDrawerIsVisible(false);
    setSelectedGroups([]);
    setSelectedDetectors([]);
    setSelectedPhase(undefined);
    setToolbarVisible(true);
  }, [
    addPhaseForm,
    setIsPhaseOperationStart,
    setSelectedGroups,
    setSelectedDetectors,
  ]);

  const onGroupListDrawerCancel = useCallback(() => {
    setGroupListDrawerIsVisible(false);
  }, []);
  const onDetectorListDrawerCancel = useCallback(() => {
    setDetectorListDrawerIsVisible(false);
  }, []);
  const onPhaseListDrawerCancel = useCallback(() => {
    setSelectedPhase(undefined); //kontrol et
    setPhaseListDrawerIsVisible(false);
  }, []);

  const onJunctionDrawerCancelClicked = useCallback(() => {
    onJunctionDrawerCancel();
  }, [onJunctionDrawerCancel]);

  const onGroupDrawerCancelClicked = useCallback(() => {
    onGroupDrawerCancel();
  }, [onGroupDrawerCancel]);

  const onDetectorDrawerCancelClicked = useCallback(() => {
    onDetectorDrawerCancel();
  }, [onDetectorDrawerCancel]);

  const onAddPhaseDrawerCancelClicked = useCallback(() => {
    onAddPhaseDrawerCancel();
  }, [onAddPhaseDrawerCancel]);

  const onJunctionDrawerFinish = useCallback(
    (values: Junction) => {
      let user = AuthService.getUser();
      if (user.user_info.org_id_list.length == 1) {
        values.org_id = user.user_info.org_id_list[0];
      }
      if (selectedJunction) {
        values.id = selectedJunction.id;
        onJunctionUpdate(values);
      } else {
        onJunctionAdd(values);
      }
      onJunctionDrawerCancelClicked();
    },
    [
      onJunctionAdd,
      onJunctionDrawerCancelClicked,
      onJunctionUpdate,
      selectedJunction,
    ]
  );

  const onGroupDrawerFinish = useCallback(
    (values: JunctionGroup) => {
      if (!values.angle) {
        values.angle = 0;
      }
      if (selectedGroup) {
        values.type_id = selectedGroup.type_id;
        values.id = selectedGroup.id;
        values.ags = selectedGroup.ags;
        values.ags_percent = values.ags_percent ? values.ags_percent : 0;
        values.ags_value = values.ags_value ? values.ags_value : 0;
        onGroupUpdate(values);
      } else {
        if (groups?.some((item) => item.internal_id == values.internal_id)) {
          message.error(t("internal_id_duplicate"));
          return;
        }
        values.type_id = typeId;
        values.ags_percent = values.ags_percent ? values.ags_percent : 0;
        values.ags_value = values.ags_value ? values.ags_value : 0;
        onGroupAdd(values);
      }

      onGroupDrawerCancelClicked();
    },
    [
      onGroupAdd,
      onGroupDrawerCancelClicked,
      onGroupUpdate,
      selectedGroup,
      typeId,
    ]
  );

  const onDetectorDrawerFinish = useCallback(
    (values: JunctionDetector) => {
      if (!values.angle) {
        values.angle = 0;
      }

      if (selectedDetector) {
        values.devicetype_id = selectedDetector.devicetype_id;
        values.id = selectedDetector.id;
        onDetectorUpdate(values);
      } else {
        if (detectors?.some((item) => item.internal_id == values.internal_id)) {
          message.error(t("internal_id_duplicate"));
          return;
        }
        values.devicetype_id = typeId;
        onDetectorAdd(values);
      }

      onDetectorDrawerCancelClicked();
    },
    [
      onDetectorAdd,
      onDetectorDrawerCancelClicked,
      onDetectorUpdate,
      selectedDetector,
      typeId,
    ]
  );
  const onAddPhaseDrawerFinish = useCallback(
    (values: JunctionPhase) => {
      if (phaseGroups.length === 0) {
        message.error(t("select_at_least_one_group"));
      } else {
        if (selectedJunction) {
          values.junction_id = selectedJunction.id;
        }
        values.groups = phaseGroups;
        values.detectors = phaseDetectors;
        if (!selectedPhase) {
          onPhaseAdd(values);
          onAddPhaseDrawerCancelClicked();
          setSelectedGroups([]);
          setSelectedDetectors([]);
        } else {
          values.key = selectedPhase.key;
          onPhaseUpdate(values);
          onAddPhaseDrawerCancelClicked();
          setSelectedGroups([]);
          setSelectedDetectors([]);
          setSelectedPhase(undefined);
        }
      }
    },
    [
      phaseGroups,
      phaseDetectors,
      selectedJunction,
      selectedPhase,
      onPhaseAdd,
      onAddPhaseDrawerCancelClicked,
      setSelectedGroups,
      setSelectedDetectors,
      onPhaseUpdate,
    ]
  );
  const onAddPhaseDrawerDelete = useCallback(() => {
    if (selectedPhase) {
      onPhaseDelete(selectedPhase);
      setAddPhaseDrawerIsVisible(false);
      setSelectedPhase(undefined);
    }
  }, [onPhaseDelete, selectedPhase]);

  const callBackLatLonInfo = (editorData: any) => {
    if (editorData) {
      groupForm.setFieldsValue({ latitude: editorData.layer._latlng.lat });
      groupForm.setFieldsValue({ longitude: editorData.layer._latlng.lng });
      // groupForm.setFieldsValue({ angle: 0 });
      detectorForm.setFieldsValue({ latitude: editorData.layer._latlng.lat });
      detectorForm.setFieldsValue({ longitude: editorData.layer._latlng.lng });
      // detectorForm.setFieldsValue({ angle: 0 });
    } else {
      groupForm.resetFields();
      detectorForm.resetFields();
    }
  };

  const callBackGroupNameInfo = (name: any) => {
    if (name) {
      if (JunctionGroupEnum[name]) {
        JunctionGroupEnum[name].toString() === "7"
          ? setIsPedestrianGroup(true)
          : setIsPedestrianGroup(false);
        setTypeId(JunctionGroupEnum[name]);
        groupForm.resetFields();
        setGroupDrawerIsVisible(true);
        setDetectorDrawerIsVisible(false);
      }
      if (JunctionDetectorEnum[name]) {
        setTypeId(JunctionDetectorEnum[name]);
        detectorForm.resetFields();
        setDetectorDrawerIsVisible(true);
        setGroupDrawerIsVisible(false);
      }
    }
  };

  const onAngleChange = useCallback(
    (value: any) => {
      if (selectedGroup) {
        setRotationAngle(value);
      } else {
        setAngleValue(value);
      }
    },
    [selectedGroup, setRotationAngle]
  );
  const onAngleChangeDetector = useCallback(
    (value: any) => {
      if (selectedDetector) {
        setRotationAngleDetector(value);
      } else {
        setAngleValue(value);
      }
    },
    [selectedDetector, setRotationAngleDetector]
  );
  const fullscreenOptions: any = {
    position: "topleft",
    title: t("open_full_screen"),
    titleCancel: t("close_full_screen"),
    content: null,
    forceSeparateButton: true,
    forcePseudoFullscreen: false,
    fullscreenElement: false,
  };

  const saveToFileAsGeoJson = (content: any, filename: any) => {
    var file = filename + ".geojson";
    FileSaver.saveAs(
      new File([JSON.stringify(content)], file, {
        type: "text/plain;charset=utf-8",
      }),
      file
    );
  };

  const saveToFileAsKml = (content: any, filename: any) => {
    var file = filename + ".kml";
    FileSaver.saveAs(
      new File([content], file, {
        type: "application/xml;charset=utf-8",
      }),
      file
    );
  };

  const getGroupName = (groupIDs: number[]) => {
    return groupIDs.map(
      (phasegroup) => groups?.find((g) => g.id == phasegroup)?.name
    );
  };
  const getDetectorName = (detectorIDs: number[]) => {
    return detectorIDs?.map(
      (phasedetector) => detectors?.find((d) => d.id == phasedetector)?.name
    );
  };
  const getGroupInternalId = (groupIDs: number[]) => {
    return groupIDs.map(
      (phasegroup) => groups?.find((g) => g.id == phasegroup)?.internal_id
    );
  };

  const getDetectorInternalId = (detectorIDs: number[]) => {
    return detectorIDs?.map(
      (phasedetector) =>
        detectors?.find((d) => d.id == phasedetector)?.internal_id
    );
  };

  useImperativeHandle(ref, () => ({
    junctionDrawerOpen() {
      setJunctionDrawerIsVisible(true);
    },
    junctionDrawerClose() {
      setJunctionDrawerIsVisible(false);
    },
    groupDrawerOpen() {
      setGroupDrawerIsVisible(true);
    },
    groupDrawerClose() {
      setGroupDrawerIsVisible(false);
    },
    detectorDrawerOpen() {
      setDetectorDrawerIsVisible(true);
    },
    detectorDrawerClose() {
      setDetectorDrawerIsVisible(false);
    },
    phaseSelection() {
      setToolbarVisible(false);
    },
  }));

  useEffect(() => {
    isPedestrianGroupValue
      ? setIsPedestrianGroup(true)
      : setIsPedestrianGroup(false);
  }, [isPedestrianGroupValue]);

  useEffect(() => {
    // eğer var olan bir gruba tıklanırsa bilgilerini selectedGroup'a aktarmak için kullanılır. direk olarak da setleyebilirdik ancak bir de editör tarafından oluşturulan bir group özelliğine de tıklanabilir.
    if (bindedGroup) {
      setSelectedGroup(bindedGroup);
    } else {
      setSelectedGroup(undefined);
    }

    if (bindedDetector) {
      setSelectedDetector(bindedDetector);
    } else {
      setSelectedDetector(undefined);
    }
  }, [bindedDetector, bindedGroup]);

  useEffect(() => {
    if (map) {
      map.flyTo(latlon);
    }
  }, [latlon, map]);

  useEffect(() => {
    map?.addControl(snapMap);
    return function cleanup() {
      map?.removeControl(snapMap);
    };
  }, [map, snapMap]);

  useEffect(() => {
    junctionForm.setFieldsValue({ latitude: latlon?.lat });
    junctionForm.setFieldsValue({ longitude: latlon?.lng });
  }, [latlon, junctionForm]);

  useEffect(() => {
    groupForm.setFieldsValue({ latitude: latlonGroup?.lat });
    groupForm.setFieldsValue({ longitude: latlonGroup?.lng });
  }, [latlonGroup, groupForm]);

  useEffect(() => {
    detectorForm.setFieldsValue({ latitude: latlonDetector?.lat });
    detectorForm.setFieldsValue({ longitude: latlonDetector?.lng });
  }, [latlonDetector, detectorForm]);

  useEffect(() => {
    if (selectedJunction) {
      junctionForm.setFieldsValue({ id: selectedJunction.id });
      junctionForm.setFieldsValue({ name: selectedJunction.name });
      junctionForm.setFieldsValue({ address: selectedJunction.name });
      junctionForm.setFieldsValue({ latitude: selectedJunction.latitude });
      junctionForm.setFieldsValue({ longitude: selectedJunction.longitude });
      junctionForm.setFieldsValue({
        listen_mode: selectedJunction.listen_mode,
      });
      junctionForm.setFieldsValue({ is_dummy: selectedJunction.is_dummy });
      junctionForm.setFieldsValue({
        devicemodel_id: selectedJunction?.devicemodel_id?.toString(),
      });
      junctionForm.setFieldsValue({ ipaddress: selectedJunction.ipaddress });
      junctionForm.setFieldsValue({ http_port: selectedJunction.http_port });
      junctionForm.setFieldsValue({ org_id: selectedJunction.org_id });
    }
  }, [junctionForm, selectedJunction]);

  useEffect(() => {
    if (selectedGroup) {
      groupForm.setFieldsValue({ ags_percent: selectedGroup.ags_percent });
      groupForm.setFieldsValue({ ags_value: selectedGroup.ags_value });
      groupForm.setFieldsValue({ angle: selectedGroup.angle });
      groupForm.setFieldsValue({ internal_id: selectedGroup.internal_id });
      groupForm.setFieldsValue({ name: selectedGroup.name });
    } else {
      groupForm.resetFields();
    }
    if (selectedDetector) {
      detectorForm.setFieldsValue({
        internal_id: selectedDetector.internal_id,
      });
      detectorForm.setFieldsValue({ name: selectedDetector.name });
      detectorForm.setFieldsValue({ angle: selectedDetector.angle });
      detectorForm.setFieldsValue({ version: selectedDetector.version });
      detectorForm.setFieldsValue({ pingtime: selectedDetector.pingtime });
      detectorForm.setFieldsValue({ http_port: selectedDetector.http_port });
      detectorForm.setFieldsValue({ sftp_port: selectedDetector.sftp_port });
      detectorForm.setFieldsValue({ ipaddress: selectedDetector.ipaddress });
    } else {
      detectorForm.resetFields();
    }
  }, [detectorForm, groupForm, selectedDetector, selectedGroup]);

  useEffect(() => {
    // using for map toolbar effect.
    if (isMapFullscreen) {
      setToolbarVisible(true);
    } else {
      setToolbarVisible(false);
      setGroupDrawerIsVisible(false);
      setGroupListDrawerIsVisible(false);
      setPhaseListDrawerIsVisible(false);
      setAddPhaseDrawerIsVisible(false);
      setJunctionDrawerIsVisible(false);
    }
  }, [isMapFullscreen]);

  useEffect(() => {
    // using for map snapshot effect.
    if (selectedJunction) {
      setToolbarVisible(true);
      setJunctionDrawerIsVisible(false);
    } else {
      setToolbarVisible(false);
      setJunctionDrawerIsVisible(true);
    }
  }, [selectedJunction]);

  useEffect(() => {
    // when open the drawer, map dragging must be disabled.
    if (
      junctionDrawerIsVisible ||
      groupDrawerIsVisible ||
      detectorDrawerIsVisible ||
      groupListDrawerIsVisible ||
      detectorListDrawerIsVisible ||
      phaseListDrawerIsVisible ||
      addPhaseDrawerIsVisible
    ) {
      map?.dragging.disable();
      map?.doubleClickZoom.disable();
    } else {
      map?.dragging.enable();
      map?.doubleClickZoom.enable();
    }
  }, [
    addPhaseDrawerIsVisible,
    detectorDrawerIsVisible,
    detectorListDrawerIsVisible,
    groupDrawerIsVisible,
    groupListDrawerIsVisible,
    junctionDrawerIsVisible,
    map?.doubleClickZoom,
    map?.dragging,
    phaseListDrawerIsVisible,
  ]);

  const downloadContent = (
    <>
      <Tooltip
        title={t("download_geojson")}
        getTooltipContainer={(trigger) => trigger}
        placement="right"
      >
        <Button
          onClick={() => {
            saveToFileAsGeoJson(layerGroup.toGeoJSON(), selectedJunction?.name);
          }}
          style={{ width: "fit-content" }}
        >
          <DownloadOutlined />
          {t("download_geojson")}
        </Button>
      </Tooltip>

      <Tooltip
        title={t("download_kml")}
        getTooltipContainer={(trigger) => trigger}
        placement="right"
      >
        <Button
          onClick={() => {
            saveToFileAsKml(
              tokml(layerGroup.toGeoJSON()),
              selectedJunction?.name
            );
          }}
          style={{ width: "fit-content" }}
        >
          <DownloadOutlined />
          {t("download_kml")}
        </Button>
      </Tooltip>
    </>
  );
  const filterContent = (
    <Popover
      content={editorMapLayerFilter}
      getPopupContainer={(trigger) => trigger}
      placement="rightBottom"
    >
      <Button style={{ width: "fit-content" }}>
        <FilterOutlined />
        {t("filters")}
      </Button>
    </Popover>
  );

  const phaseContent = (
    <Row>
      <Tooltip
        title={t("add_phase")}
        getTooltipContainer={(trigger) => trigger}
        placement="right"
      >
        <Button
          onClick={() => {
            // showModal();
            setIsPhaseOperationStart(true);
            setAddPhaseDrawerIsVisible(true);
            addPhaseForm.resetFields();
          }}
          style={{ width: "fit-content" }}
        >
          <PlusOutlined />
          {t("add_phase")}
        </Button>
      </Tooltip>
    </Row>
  );

  const [statusCode, setStatusCode] = useState<any>("");
const [staticImageLatitude, setStaticImageLatitude] = useState<number | null>(null);
const [staticImageLongitude, setStaticImageLongitude] = useState<number | null>(null);
const [isLoading, setIsLoading] = useState<boolean>(true);

const handleChangeUploadJunctionFile = async (info: UploadChangeParam<UploadFile>) => {
  if (info.file.status === 'done') {
    message.success('File uploaded successfully:', info.file.response);
    triggerFullscreenMap();
  } else if (info.file.status === 'error') {
    message.error('File upload failed:', info.file.error);
  }
};

const requestForUploadJunctionFile = async (options: any) => {
  const { file, onSuccess, onError } = options;
  try {
    const formData = new FormData();
    formData.append('file', file as RcFile);
    await JunctionService.fileUploadJunctionImage(formData, selectedJunction?.id!);
    onSuccess && onSuccess({}, file);
  } catch (error) {
    onError && onError(error as Error);
  }
};

const checkImageStatus = async () => {
  try {
    var origin = window.location.origin;
    const address = origin === 'http://localhost:8081' ? 'http://localhost:3000' : origin; 
    const response = await fetch(`${address}/file/junctionImages/drone_${selectedJunction?.id}`);
    return response.status;
  } catch (error) {
    console.error('İstek sırasında hata oluştu:', error);
    return null;
  }
};

const getStatusCode = async () => {
  const statusCode = await checkImageStatus();
  setStatusCode(statusCode);
};

const getJunctionStaticImage = async () => {
  setIsLoading(true);
  try {
    const res = await JunctionService.getJunctionStaticImage(selectedJunction?.id!);
    setStaticImageLatitude(res.data[0]?.latitude || null);
    setStaticImageLongitude(res.data[0]?.longitude || null);
  } catch (error) {
    console.log(error);
  } finally {
    setIsLoading(false);
  }
};

useEffect(() => {
  getJunctionStaticImage();
}, [selectedJunction]);

useEffect(() => {
  getStatusCode();
  if (map && statusCode == 200 && staticImageLatitude !== null && staticImageLongitude !== null) {
    const latlng = new L.LatLng(
      staticImageLatitude || latlon.lat,
      staticImageLongitude || latlon.lng
    );

    var origin = window.location.origin;
    const address = origin === 'http://localhost:8081' ? 'http://localhost:3000' : origin;
    const marker = L.marker(latlng, {
      icon: L.icon({
        iconUrl: `${address}/file/junctionImages/drone_${selectedJunction?.id}`,
        iconSize: [500, 500],
        iconAnchor: [0, 0],
      }),
    }).addTo(map);

    if (marker.pm) {
      marker.pm.enable({ draggable: true });
      marker.on('pm:dragend', (event: any) => {
        const { lat, lng } = event.target.getLatLng();
        marker.setZIndexOffset(-1);
        setStaticImageLatitude(lat);
        setStaticImageLongitude(lng);
        JunctionService.upsertJunctionStaticImage(selectedJunction?.id!, lat, lng)
          .then((res) => {
            res.data === true ? message.success(t("map_location_saved")) : message.error(t("map_locaiton_not_saved"));
          });
      });

      marker.on('pm:remove', (event: any) => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return ReactDOM.createPortal(
              (<Modal
                title={`${t("image")} `}
                visible={true}
                onOk={() => {
                  JunctionService.deleteJunctionStaticImage(selectedJunction?.id!)
                    .then((res) => {
                      res.data === 1 ? message.success(t("success")) : message.error(t("error"));
                    });
                  onClose();
                }}
                onCancel={() => {
                  window.location.reload();
                  onClose();
                }}
                getContainer={false}
                maskClosable={false}
                centered
              >
                <p>{t("it_will_be_deleted_r_you_sure")}</p>
              </Modal>), document.getElementById('miniMap')!
            );
          },
        });
      });
    }

    return () => {
      map.removeLayer(marker);
    };
  }
}, [statusCode, map, staticImageLatitude, staticImageLongitude, handleChangeUploadJunctionFile]);

if (isLoading) {
  return <Spin />;
}


  const imageUploadContent = (
<Row>
    <Tooltip title={t("image_upload")} placement="left" getTooltipContainer={(trigger) => trigger}>
    <Upload
      customRequest={requestForUploadJunctionFile}
      showUploadList={false}
      onChange={handleChangeUploadJunctionFile}
      accept="image/*"
    >
      <Button style={{ backgroundColor: '#1A232B', zIndex: 1000 }} icon={<UploadOutlined style={{ color: 'white' }} />}></Button>
    </Upload>
  </Tooltip>
  </Row>
  );

  const listContent = (
    <Row>
      <Popover
        content={
          <>
            <Button
              type="text"
              icon={<TableOutlined />}
              onClick={() => setGroupListDrawerIsVisible(true)}
            >
              {t("group_list")}
            </Button>
            <Button
              type="text"
              icon={<TableOutlined />}
              onClick={() => setDetectorListDrawerIsVisible(true)}
            >
              {t("detector_list")}
            </Button>
            <Button
              type="text"
              icon={
                <>
                  <TableOutlined />
                  <EditOutlined />
                </>
              }
              onClick={() => setPhaseListDrawerIsVisible(true)}
            >
              {t("phase_list")}
            </Button>
          </>
        }
        getPopupContainer={(trigger) => trigger}
        placement="leftBottom"
      >
        <Button style={{ width: "fit-content" }}>
          <UnorderedListOutlined />
          {t("junction_content_list")}
        </Button>
      </Popover>
    </Row>
  );

  
  return (
    <>
      <MapContainer
        center={latlon}
        zoom={zoom}
        scrollWheelZoom={true}
        id="miniMap"
        style={{ position: "absolute", top: 48, bottom: 0, left: 0, right: 0 }}
        whenCreated={mapCreate}
        zoomControl={false}
      >
        <ZoomControl />
        {groupListDrawerIsVisible && (
          <Drawer
            title={t("group_list")}
            placement="left"
            visible={groupListDrawerIsVisible}
            mask={false}
            getContainer={false}
            style={{ position: "absolute", height: "100%" }}
            width={"60%"}
            closable={true}
            onClose={onGroupListDrawerCancel}
          >
            <Button type="primary">
              <CSVLink
                filename={selectedJunction?.name + "_Groups.csv"}
                data={groups!}
                className="btn btn-primary"
              >
                {t("import_as_csv")}
              </CSVLink>
            </Button>
            <Button onClick={handlePrint} type="primary" danger>
              {t("import_as_pdf")}
            </Button>
            <div ref={componentRef}>
              <Table
                dataSource={groups}
                columns={groupColumns}
                size="small"
                pagination={false}
              ></Table>
            </div>
          </Drawer>
        )}
        {detectorListDrawerIsVisible && (
          <Drawer
            title={t("detector_list")}
            placement="left"
            visible={detectorListDrawerIsVisible}
            mask={false}
            getContainer={false}
            style={{ position: "absolute", height: "100%" }}
            width={"60%"}
            closable={true}
            onClose={onDetectorListDrawerCancel}
          >
            <Button type="primary">
              <CSVLink
                filename={selectedJunction?.name + "_Detectors.csv"}
                data={detectors!}
                className="btn btn-primary"
              >
                {t("import_as_csv")}
              </CSVLink>
            </Button>
            <Button onClick={handlePrint} type="primary" danger>
              {t("import_as_pdf")}
            </Button>
            <div ref={componentRef}>
              <Table
                dataSource={detectors}
                columns={detectorColumns}
                size="small"
              ></Table>
            </div>
          </Drawer>
        )}
        {phaseListDrawerIsVisible && (
          <Drawer
            title={t("phase_list")}
            placement="left"
            visible={phaseListDrawerIsVisible}
            mask={false}
            getContainer={false}
            style={{ position: "absolute", height: "100%" }}
            width={"60%"}
            closable={true}
            onClose={onPhaseListDrawerCancel}
          >
            <Button type="primary">
              <CSVLink
                filename={selectedJunction?.name + "_Phases.csv"}
                data={phases!}
                className="btn btn-primary"
              >
                {t("import_as_csv")}
              </CSVLink>
            </Button>
            <Button onClick={handlePrint} type="primary" danger>
              {t("import_as_pdf")}
            </Button>
            <div ref={componentRef}>
              <Table
                dataSource={phases}
                columns={phaseColumns}
                size="small"
              ></Table>
            </div>
          </Drawer>
        )}
        {junctionDrawerIsVisible && (
          <Drawer
            title={t("junction_details")}
            placement="left"
            visible={junctionDrawerIsVisible}
            mask={false}
            getContainer={false}
            style={{ position: "absolute", height: "100%" }}
            width={"20%"}
            closable={true}
            onClose={onJunctionDrawerCancel}
          >
            <Form
              form={junctionForm}
              layout="vertical"
              onFinish={onJunctionDrawerFinish}
            >
              <Form.Item
                label="ID"
                name="id"
                rules={JunctionFormProps.name.rules}
              >
                {selectedJunction ? (
                  <InputNumber readOnly disabled />
                ) : (
                  <InputNumber />
                )}
              </Form.Item>
              <Form.Item
                label={t("junction_name")}
                name="name"
                rules={JunctionFormProps.name.rules}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("address")}
                name="address"
                rules={JunctionFormProps.address.rules}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("latitude")}
                name="latitude"
                rules={JunctionFormProps.latitude.rules}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                label={t("longitude")}
                name="longitude"
                rules={JunctionFormProps.longitude.rules}
              >
                <Input disabled={true} />
              </Form.Item>

              {filteredOrganizationList.length > 1 && (
                <Form.Item
                  label={t("organization")}
                  name="org_id"
                  rules={JunctionFormProps.org_id.rules}
                >
                  <Select
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentElement;
                    }}
                    defaultValue={selectedJunction?.org_id}
                    style={{ width: 120 }}
                    //onChange={handleChange}
                    options={filteredOrganizationList}
                  />
                </Form.Item>
              )}
              <Form.Item label={t("listen_mode")} name="listen_mode">
                <Switch
                  defaultChecked={junctionForm.getFieldValue("listen_mode")}
                />
              </Form.Item>
              <Form.Item label={t("is_dummy")} name="is_dummy">
                <Switch
                  defaultChecked={junctionForm.getFieldValue("is_dummy")}
                />
              </Form.Item>
              <Card
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/static/centrislogo.png"
                      alt="Icon"
                      style={{ width: 50, height: 25, marginRight: 8 }}
                    />
                    <span>
                      <Title level={5}>Centris Info</Title>
                    </span>
                  </div>
                }
              >
                <Form.Item label={"Centris" + t("ip_address")} name="ipaddress">
                  <Input />
                </Form.Item>
                <Form.Item label="Centris Port" name="http_port">
                  <Input />
                </Form.Item>

                <Form.Item
                  label={t("select") + " Centris " + t("version")}
                  name="devicemodel_id"
                >
                  <Radio.Group>
                    <Radio value="1">Centris V1</Radio>
                    <Radio value="2">Centris V2</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Form.Item style={{ marginTop: "50px" }}>
                <Button
                  style={{ marginRight: "5px" }}
                  type="default"
                  onClick={onJunctionDrawerCancelClicked}
                >
                  {t("cancel")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("send")}
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        )}
        {groupDrawerIsVisible && (
          <Drawer
            title={t("group_details")}
            placement="left"
            visible={groupDrawerIsVisible}
            mask={false}
            getContainer={false}
            style={{ position: "absolute", height: "100%" }}
            width={"20%"}
            closable={true}
            onClose={onGroupDrawerCancel}
          >
            <Form
              form={groupForm}
              layout="vertical"
              onFinish={onGroupDrawerFinish}
            >
              <Form.Item
                label={t("group_number")}
                name="internal_id"
                rules={JunctionGroupFormProps.internal_id.rules}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("group_name")}
                name="name"
                rules={JunctionGroupFormProps.name.rules}
              >
                <Input />
              </Form.Item>
              {!isPedestrianGroup && (
                <Form.Item
                  label={t("emergency_crossing_distance")}
                  name="ags_value"
                  initialValue={100}
                  // rules={JunctionGroupFormProps.ags_value.rules}
                >
                  <Input />
                </Form.Item>
              )}

              {!isPedestrianGroup && (
                <Form.Item
                  label={t("ags_percent")}
                  name="ags_percent"
                  initialValue={70}
                  // rules={JunctionGroupFormProps.ags_percent.rules}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item
                style={{ width: "100%" }}
                label={t("group_angle")}
                name="angle"
              >
                <Slider
                  max={359}
                  min={0}
                  onChange={onAngleChange}
                  trackStyle={{ background: "blue" }}
                  handleStyle={{ borderColor: "blue" }}
                  tooltipVisible={true}
                  getTooltipPopupContainer={(trigger) => trigger}
                />
              </Form.Item>
              <Form.Item
                label={t("latitude")}
                name="latitude"
                rules={JunctionGroupFormProps.latitude.rules}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                label={t("longitude")}
                name="longitude"
                rules={JunctionGroupFormProps.longitude.rules}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item style={{ marginTop: "50px" }}>
                <Button
                  style={{ marginRight: "5px" }}
                  type="default"
                  onClick={onGroupDrawerCancelClicked}
                >
                  {t("cancel")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("send")}
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        )}
        {detectorDrawerIsVisible && (
          <Drawer
            title={t("detector_details")}
            placement="left"
            visible={detectorDrawerIsVisible}
            mask={false}
            getContainer={false}
            style={{ position: "absolute", height: "100%" }}
            width={"20%"}
            closable={true}
            onClose={onDetectorDrawerCancel}
          >
            <Form
              form={detectorForm}
              layout="vertical"
              onFinish={onDetectorDrawerFinish}
            >
              <Form.Item
                label={t("detector_number")}
                name="internal_id"
                rules={JunctionDetectorFormProps.internal_id.rules}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("detector_name")}
                name="name"
                rules={JunctionDetectorFormProps.name.rules}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("ip_address")}
                name="ipaddress"
                initialValue={"192.168.1.1"}
                rules={JunctionDetectorFormProps.ipaddress.rules}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("http_port")}
                name="http_port"
                initialValue={"80"}
                rules={JunctionDetectorFormProps.http_port.rules}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("sftp_port")}
                name="sftp_port"
                initialValue={"99"}
                rules={JunctionDetectorFormProps.sftp_port.rules}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("ping_time")}
                name="pingtime"
                initialValue={"100"}
                rules={JunctionDetectorFormProps.pingtime.rules}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("version")}
                name="version"
                initialValue={"1.0"}
                rules={JunctionDetectorFormProps.version.rules}
              >
                <Input />
              </Form.Item>

              <Form.Item
                style={{ width: "100%" }}
                label={t("detector_angle")}
                name="angle"
              >
                <Slider
                  max={359}
                  min={0}
                  onChange={onAngleChangeDetector}
                  trackStyle={{ background: "blue" }}
                  handleStyle={{ borderColor: "blue" }}
                  tooltipVisible={true}
                  getTooltipPopupContainer={(trigger) => trigger}
                />
              </Form.Item>
              <Form.Item
                label={t("latitude")}
                name="latitude"
                rules={JunctionDetectorFormProps.latitude.rules}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item
                label={t("longitude")}
                name="longitude"
                rules={JunctionDetectorFormProps.longitude.rules}
              >
                <Input disabled={true} />
              </Form.Item>
              <Form.Item style={{ marginTop: "50px" }}>
                <Button type="default" onClick={onDetectorDrawerCancelClicked}>
                  {t("cancel")}
                </Button>
                <Button type="primary" htmlType="submit">
                  {t("send")}
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        )}
        {addPhaseDrawerIsVisible && (
          <Drawer
            title={t("phase_details")}
            placement="left"
            visible={addPhaseDrawerIsVisible}
            mask={false}
            getContainer={false}
            style={{ position: "absolute", height: "100%" }}
            width={"20%"}
            closable={true}
            onClose={onAddPhaseDrawerCancel}
          >
            <Form
              form={addPhaseForm}
              layout="vertical"
              onFinish={onAddPhaseDrawerFinish}
            >
              <Form.Item
                label={t("phase_number")}
                name="internal_id"
                rules={JunctionPhaseFormProps.internal_id.rules}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("phase_name")}
                name="name"
                rules={JunctionPhaseFormProps.name.rules}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t("min_sequence_timeout")}
                name="min_sequence_timeout"
                initialValue={5}
                rules={[
                  ...JunctionPhaseFormProps.min_sequence_timeout.rules,
                  {
                    validator(_, value) {
                      const { getFieldValue } = addPhaseForm;
                      if (
                        value &&
                        value > getFieldValue("max_sequence_timeout")
                      ) {
                        return Promise.reject("Min > Max");
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label={t("max_sequence_timeout")}
                name="max_sequence_timeout"
                initialValue={100}
                rules={JunctionPhaseFormProps.max_sequence_timeout.rules}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label={t("output_number")}
                name="output_number"
                rules={JunctionPhaseFormProps.output_number.rules}
              >
                <Input />
              </Form.Item>
              <Form.Item label={t("vip_phase")} name="vip_phase">
                <Switch
                  defaultChecked={addPhaseForm.getFieldValue("vip_phase")}
                />
              </Form.Item>
              <Form.Item style={{ marginTop: "50px" }}>
                <Button type="default" onClick={onAddPhaseDrawerCancelClicked}>
                  {t("cancel")}
                </Button>
                <Popconfirm
                  title={t("selected_phase_will_be_deleted")}
                  onConfirm={onAddPhaseDrawerDelete}
                  onCancel={() => {
                    return;
                  }}
                  okText={t("yes")}
                  cancelText={t("cancel")}
                  getPopupContainer={(trigger) => trigger}
                  placement="bottomRight"
                >
                  <Button type="default" hidden={!deleteButtonVisible}>
                    {t("delete")}
                  </Button>
                </Popconfirm>
                <Button type="primary" htmlType="submit">
                  {t("send")}
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
        )}

        <Fullscreen
          eventHandlers={{
            enterFullscreen: () => setIsMapFullscreen(true),
            exitFullscreen: () => setIsMapFullscreen(false),
          }}
          {...fullscreenOptions}
        />
        {/* {isMapFullscreen && <FileLayer position="bottomleft" />} */}
        {selectedJunction && (
          <>
            <CustomControlContent
              position="topright"
              content={listContent}
              controlHidden={isMapFullscreen}
            ></CustomControlContent>
            <CustomControlContent
              position="topright"
              content={phaseContent}
              controlHidden={isMapFullscreen}
            ></CustomControlContent>
            <CustomControlContent
              position="bottomleft"
              content={downloadContent}
              controlHidden={isMapFullscreen}
            ></CustomControlContent>
            <CustomControlContent
              position="bottomleft"
              content={filterContent}
              controlHidden={isMapFullscreen}
            ></CustomControlContent>
               <CustomControlContent
              position="topright"
              content={imageUploadContent}
              controlHidden={isMapFullscreen}
            ></CustomControlContent>
          </>//
        )}    
        <MapTileControl />
        {
          <GroupandDetectorEditor
            groupLatLonInfo={callBackLatLonInfo}
            // groupDrawerVisibleInfo={callBackGroupDrawerVisibleInfo}
            isVisibleToolbar={toolbarVisible}
            angleValue={angleValue}
            groupNameInfo={callBackGroupNameInfo}
            setAngleValue={setAngleValue}
            setSelectedGroup={setSelectedGroup}
            setSelectedDetector={setSelectedDetector}
            setBindedGroup={setBindedGroup}
            setBindedDetector={setBindedDetector}
          ></GroupandDetectorEditor>
        }
        {/* <DetectorEditor
          detectorLatLonInfo={callbackLatLonInfoDetector}
          detectorDrawerVisibleInfo={callBackDetectorDrawerVisibleInfo}
          isVisibleToolbar={toolbarVisible}
          angleValue={angleValue}
          detectorNameInfo={callBackDetectorNameInfo}
          setAngleValue={setAngleValue}
          setSelectedDetector={setSelectedDetector}
          setBindedDetector={setBindedDetector}
        ></DetectorEditor> */}
        {/* 
        <PhaseModal
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
        ></PhaseModal> */}

        {children}
      </MapContainer>
    </>
  );
});
export default MapEditor;
