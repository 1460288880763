import "./styles.css";

interface Props {
  schedularHeaderHeight: number;
}

const TimeSlices: React.FC<Props> = ({ schedularHeaderHeight }) => {
  const divider: number[] = new Array(25).fill(0);
  return (
    <div
      className="mmsp-schedular__time-slices__lines"
      style={{
        height: `calc(100% - ${schedularHeaderHeight}px)`,
        top: schedularHeaderHeight,
      }}
    >
      {divider.map((_val, subIndex) => {
        return (
          <span
            className="mmsp-schedular__time-slices__lines__line"
            key={`line-${subIndex}`}
            style={{
              top: `calc(${subIndex} * ${100 / 24}%)`,
              transform: "translateY(-50%)",
            }}
          >
            <span className="mmsp-schedular__time-slices__lines__line__time">{`${
              subIndex >= 10 ? subIndex : `0${subIndex}`
            }:00`}</span>
          </span>
        );
      })}
    </div>
  );
};

export default TimeSlices;
