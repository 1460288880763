import { Tabs } from "antd";
import { t } from "i18next";
import { Bluesis } from "../../../data/bluesis/Bluesis";
import Information from "./Information";

const { TabPane } = Tabs;

interface Props {
  selectedTab?: string;
  selectedBluesis: Bluesis;
}

function BluesisMain(props: Props) {
  const { selectedTab, selectedBluesis } = props;

  return (
    <Tabs defaultActiveKey={selectedTab ?? "1"}>
      <TabPane tab={t("device_informations")} key="1">
        <Information selectedBluesis={selectedBluesis} />
      </TabPane>
    </Tabs>
  );
}

export default BluesisMain;
