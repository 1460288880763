import http from "../http.common";
import { JunctionDetector } from "./JunctionDetector";

const get = function (junctionId: number) {
  return http.post<JunctionDetector[]>(`/web/junction/detector/list`,{junction_id:junctionId});
};

const add = function (data: JunctionDetector) {
  return http.post<JunctionDetector[]>(`/web/junction/detector/add`, data);
};
const update = function (data: JunctionDetector) {
  return http.post<JunctionDetector[]>(`/web/junction/detector/update`, data);
};
const deleteDetector = function (data: JunctionDetector) {
  return http.post<JunctionDetector[]>(`/web/junction/detector/delete`, data);
};



const JunctionDetectorService = {
  get,
  add,
  update,
  deleteDetector
};

export default JunctionDetectorService;
