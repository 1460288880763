import BluesisService from "../../../../../../data/bluesis/BluesisService";
import { MarkerForm } from "./MarkerForm";

export const bluesisModel: MarkerForm = {
  type: "bluesis",
  addService: BluesisService.addBluesis,
  updateService: BluesisService.updateBluesis,
  items: [
    {
      label: "bluesis_name",
      name: "name",
      type: "input",
      isRequired: true,
    },
    {
      label: "device_id",
      name: "device_id",
      type: "number",
      isRequired: true,
    },
    {
      label: "latitude",
      name: "latitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "longitude",
      name: "longitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "energy_source",
      name: "energy_source",
      type: "number",
      isRequired: true,
    },
    {
      label: "device_type",
      name: "device_type",
      type: "number",
      isRequired: true,
    },
    {
      label: "network_type",
      name: "network_type",
      type: "number",
      isRequired: true,
    },
    {
      label: "bluesis_ip_address",
      name: "ip_address",
      type: "input",
      isRequired: false,
    },
    {
      label: "note",
      name: "note",
      type: "input",
      isRequired: true,
    },
    {
      label: "mount_location",
      name: "mount_location",
      type: "input",
      isRequired: true,
    },
    {
      label: "organization",
      name: "org_id",
      type: "dropdown",
      isRequired: true,
      dropdownItem: "orgList",
    },
    {
      label: "city_code",
      name: "city_id",
      type: "number",
      isRequired: true,
    },
  ],
};
