import LegendControl from "./LegendControl";

function BottomLeftControl() {
  return (
    <div className="leaflet-bottom leaflet-left">
      <div className="leaflet-control leaflet-bar" style={{ border: "none" }}>
        <LegendControl />
      </div>
    </div>
  );
}

export default BottomLeftControl;
