import DeviceService from "../../../../../../data/device/DeviceService";
import { MarkerForm } from "./MarkerForm";

export const cameraModel: MarkerForm = {
  type: "camera",
  addService: DeviceService.addCameraAndViero,
  updateService: DeviceService.updateDevice,
  items: [
    {
      label: "name",
      name: "name",
      type: "input",
      isRequired: true,
    },
    {
      label: "device_id",
      name: "id_device",
      type: "number",
      isRequired: true,
    },
    {
      label: "ip_address",
      name: "ipaddress",
      type: "input",
      isRequired: true,
    },
    {
      label: "http_port",
      name: "http_port",
      type: "number",
      isRequired: true,
    },
    {
      label: "device_type",
      name: "devicetype_id",
      type: "dropdown",
      isRequired: true,
      dropdownItem: "deviceTypeList",
    },
    {
      label: "junction",
      name: "junction_id",
      type: "dropdown",
      isRequired: true,
      dropdownItem: "junctionList",
    },
    {
      label: "latitude",
      name: "latitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "longitude",
      name: "longitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "internal_id",
      name: "internal_id",
      type: "number",
      isRequired: true,
    },
    {
      label: "stream_url",
      name: "stream_url",
      type: "input",
      isRequired: true,
    },
    {
      label: "Proxy",
      name: "proxy",
      type: "boolean",
      isRequired: true,
    },
  ],
};
