import { Tabs, Tooltip } from "antd";
import { LatLng, Map } from "leaflet";
import React, { useContext, useState } from "react";
import { Junction } from "../../data/junction/Junction";
import SignalPlan from "./SignalPlan";
import Manage from "./Manage";
import ViewTab from "./ViewTab/Index";
import Statistic from "./Statistic";
import { t } from "i18next";
import MMSignalPlan from "./MMSignalPlan";
import MapView from "./EditView";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";
import ReportForm from "../profile/ReportForm";

const { TabPane } = Tabs;

interface Props {
  selectedTab?: string;
  latlon?: LatLng;
  map?: React.MutableRefObject<Map | undefined>;
  selectedJunction: Junction;
  setSelectedJunction: any;
}

const JunctionMain = (props: Props) => {
  const { selectedTab, latlon, map, selectedJunction, setSelectedJunction } =
    props;
  const [changeTab, setChangeTab] = useState<boolean>(false);
  const onChange = () => {
    changeTab === true ? setChangeTab(false) : setChangeTab(true);
  };
  const { hasAccessComponent } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  return (
    <Tabs defaultActiveKey={selectedTab} onChange={onChange}>
      <TabPane
        key="1"
        tab={
          selectedJunction?.is_dummy ? (
            <Tooltip title="İzleme Ekranı Dummy Mod Aktif Kavşaklarda Görüntülenemez.">
              <span>{t("watch")}</span>
            </Tooltip>
          ) : (
            <span>{t("watch")}</span>
          )
        }
        disabled={selectedJunction?.is_dummy}
      >
        <ViewTab
          selectedJunction={selectedJunction}
          changeTab={changeTab}
        ></ViewTab>
      </TabPane>
      <TabPane
        key="2"
        tab={
          selectedJunction?.is_dummy ? (
            <Tooltip title="KKC/Centris Management Ekranı Dummy Mod Aktif Kavşaklarda Görüntülenemez.">
              <span>{t("kkc_centris_management")}</span>
            </Tooltip>
          ) : (
            <span>{t("kkc_centris_management")}</span>
          )
        }
        disabled={selectedJunction?.is_dummy}
      >
        <Manage selectedJunction={selectedJunction} />
      </TabPane>
      <TabPane
        tab={
          selectedJunction?.listen_mode || selectedJunction?.is_dummy ? (
            <Tooltip title="Sinyal Planı Ekranı Listen Mod / Dummy Mod Aktif Kavşaklarda Görüntülenemez.">
              <span>{t("signal_plan")}</span>
            </Tooltip>
          ) : (
            <span>{t("signal_plan")}</span>
          )
        }
        key="3"
        disabled={selectedJunction?.listen_mode || selectedJunction?.is_dummy}
      >
        <SignalPlan selectedJunction={selectedJunction} />
      </TabPane>
      {hasAccessComponent("AISignalPlan-READ") && (
        <TabPane
          tab={
            selectedJunction?.listen_mode || selectedJunction?.is_dummy ? (
              <Tooltip title="Sinyal Planı Ekranı Listen Mod / Dummy Mod Aktif Kavşaklarda Görüntülenemez.">
                <span>{t("signal_plan")}</span>
              </Tooltip>
            ) : (
              // <span>{t("mm_signal_plan")}</span>
              <span>{"AI " + t("signal_plan")}</span>
            )
          }
          key="5"
          disabled={selectedJunction?.listen_mode || selectedJunction?.is_dummy}
        >
          <MMSignalPlan selectedJunction={selectedJunction} />
        </TabPane>
      )}
      <TabPane tab={t("edit")} key="4">
        <MapView
          latlon={latlon}
          map={map}
          selectedJunction={selectedJunction}
          setSelectedJunction={setSelectedJunction}
        />
      </TabPane>
      <TabPane
        tab={
          selectedJunction?.is_dummy ? (
            <Tooltip title="İstatistik Ekranı Dummy Mod Aktif Kavşaklarda Görüntülenemez.">
              <span>{t("statistics")}</span>
            </Tooltip>
          ) : (
            <span>{t("statistics")}</span>
          )
        }
        key="6"
        disabled={selectedJunction?.is_dummy}
      >
        <Statistic selectedJunction={selectedJunction} />
      </TabPane>
      <TabPane tab={t("reportBug")} key="7">
        <ReportForm initialSelectedTopic="0" releatedId={selectedJunction?.id} />
      </TabPane>
    </Tabs>
  );
};

export default JunctionMain;
