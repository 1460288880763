import { t } from "i18next";
import { Ups } from "../../data/ups/Ups";
import { Tag, Form, Row, Col } from "antd";
import L from "leaflet";
import moment from "moment";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

enum upsStatus{
  "Zeroref",
  "Other",
  "None",
  "Normal",
  "Bypass",
  "Battery",
  "Booster",
  "Reducer"
}

interface Props {
  selectedUps?: Ups;
}

const cardContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
};

const equalHeightColumnStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

const UpsStaticInfoPage: React.FC<Props> = ({ selectedUps }) => {
  if (!selectedUps) {
    return null;
  }
  const activeIcon = new L.Icon({
    iconUrl: "/static/assets/map-markers/ups.png",
    iconSize: [30, 30],
  });

  const passiveIcon = new L.Icon({
    iconUrl: "/static/assets/map-markers/ups-p.png",
    iconSize: [30, 30],
  });

  return (
    <div>
      <Row gutter={16}>
        <Col span={12} style={equalHeightColumnStyle}>
          <div style={cardContainerStyle}>
            <MapContainer
              center={[selectedUps.latitude, selectedUps.longitude]}
              zoom={13}
              style={{ height: "100%" }}
            >
              <TileLayer
               url="https://api.mapbox.com/styles/v1/mangomapboxprimary/clwrwrqpt00yc01pcdznoeb6a/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFuZ29tYXBib3hwcmltYXJ5IiwiYSI6ImNsd3J2eTF6MjA1ZDQya3E4ZXRwbTZ1MmEifQ.RTj3dfki967U8_cNPEe0MQ"
              />
              <Marker
                position={[selectedUps.latitude, selectedUps.longitude]}
                icon={selectedUps.status === 3 ? activeIcon : passiveIcon}
              />
            </MapContainer>
          </div>
        </Col>
        <Col span={12} style={equalHeightColumnStyle}>
          <div style={cardContainerStyle}>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t("name")}><span >{selectedUps.name} </span></Form.Item>
                  <Form.Item label={t("ip_address")}>
                    <a 
                      href={`http://${selectedUps.ip_address}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {selectedUps.ip_address}
                    </a>
                  </Form.Item>
                  <Form.Item label={t("protocol")}><span >{selectedUps.protocol} </span></Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t("status")}>
                    <Tag color={selectedUps.status === 3 ? "#03A63C" :(selectedUps.status === 4 ? "#049DD9": "gray")}>
                    {/* TODO:Yeni statuslar gelecek. */}
                    <span >{upsStatus[selectedUps.status]} </span> 
                    </Tag>
                  </Form.Item>
                  <Form.Item label={t("number_of_batteries")}>
                  <span >{selectedUps.number_of_batteries} </span>
                  </Form.Item>
                  <Form.Item label={t("kwa")}><span >{selectedUps.kwa} </span></Form.Item>
                </Col>
              </Row>
              <Form.Item label={t("created_date")}>
              <span >{moment
                  .unix(selectedUps.created_date)
                  .format("DD.MM.YYYY HH:mm:ss")} </span>
              </Form.Item>
              <Form.Item label={t("battery_montage_date")}>
              <span >
                {moment
                  .unix(selectedUps.battery_montage_date)
                  .format("DD.MM.YYYY HH:mm:ss")} </span>
              </Form.Item>
              <Form.Item label={t("battery_created_date")}>
              <span >
                {moment
                  .unix(selectedUps.battery_created_date)
                  .format("DD.MM.YYYY HH:mm:ss")} </span>
              </Form.Item>
              <Form.Item label={t("brand_model")}>
                <span >
                {selectedUps.brand_model}
                </span>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpsStaticInfoPage;
