import { FormProps } from "../common/FormProps";

export interface JunctionPhase {
  key:number;
  internal_id:number;
  name:string;
  max_sequence_timeout:number;
  min_sequence_timeout:number;
  groups:number[];
  detectors:number[];
  output_number:number;
  junction_id:number;
  vip_phase:number;
}


export const JunctionPhaseFormProps: { [key: string]: FormProps } = {
  internal_id: { rules: [{ required: true , message:"Zorunlu alan"}] },
  name: { rules: [{ required: true , message:"Zorunlu alan"}] },
  max_sequence_timeout: { rules: [{ required: true , message:"Zorunlu alan"}] },
  min_sequence_timeout: { rules: [{ required: true , message:"Zorunlu alan"}] },
  output_number: { rules: [{ required: true , message:"Zorunlu alan"}] },
};

