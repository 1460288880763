interface HtmlListRendererProps {
  htmlString: string;
  selectedLanguage: "tr" | "en" | null;
}

const HtmlListRenderer = ({
  htmlString,
  selectedLanguage,
}: HtmlListRendererProps) => {
  // Function to split and return the relevant language part
  const processMessage = (message: string) => {
    const trRegex = /\[TR\](.*?)(?=\[EN\]|\[TR\]|$)/;
    const enRegex = /\[EN\](.*?)(?=\[TR\]|\[EN\]|$)/;

    const turkishMatch = trRegex.exec(message);
    const turkishPart = turkishMatch ? turkishMatch[1]?.trim() : null;
    const englishMatch = enRegex.exec(message);
    const englishPart = englishMatch ? englishMatch[1]?.trim() : null;

    if (selectedLanguage === "tr" && turkishPart) {
      return turkishPart;
    } else {
      return englishPart;
    }
  };

  // Container element to parse and process the HTML string
  const createMarkup = () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const ulElements = doc.querySelectorAll("ul");
    ulElements.forEach((ul) => {
      ul.classList.add("release-note-list");
    });

    const liElements = doc.querySelectorAll("li");
    liElements.forEach((li) => {
      li.classList.add("release-note-list-item");
    });

    // Process 'li p' elements to display the correct language part
    const items = doc.querySelectorAll("li p");
    items.forEach((item) => {
      item.textContent = processMessage(item.textContent ?? "");
    });

    return { __html: doc.body.innerHTML };
  };

  return (
    <div>
      <div dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
};

export default HtmlListRenderer;
