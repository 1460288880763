import { Navigate, Outlet } from "react-router-dom";
import { AuthService } from "@icms/ui-components";
import { JunctionDataProvider } from "../contexts/junction/JunctionDataContext";
import { AuthProvider } from "../contexts/auth/AuthContext";

interface Props {
  roles?: string[];
}
const PrivateRoute = (props: Props) => {
  //TODO:check roles
  let authenticated = AuthService.isAuthenticated();

  if (authenticated === true)
    return (
      <>
        <AuthProvider>
          <JunctionDataProvider>
            <Outlet />
          </JunctionDataProvider>
        </AuthProvider>
      </>
    );
  else return <Navigate to="/login" />;
};

export default PrivateRoute;
