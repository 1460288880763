import { Dropdown, Menu, Space, Col, Row, Input } from "antd";
import { CloseCircleOutlined, DownOutlined, CopyOutlined } from "@ant-design/icons";
import { useContext, useMemo, useState, useEffect } from "react";

// context
import { PlanContext } from "../../../../context/PlanContext";
import { ICopyPastePlanModalInformations } from "../..";
import { t } from "i18next";
import { JunctionKKCType } from "../../../../../../../data/junction/Junction";

interface Props {
  color?: string;
  dayTitle?: string;
  planName?: string;
  startTime?: any;
  planMode?: string;
  dayIndex: number;
  signalIndexOfDayColumn?: number;
  copyPasteDay?: React.Dispatch<
    React.SetStateAction<ICopyPastePlanModalInformations>
  >;
  selectedJunction: any;
}

const SchedularBlock: React.FC<Props> = ({
  dayTitle,
  color,
  planName,
  startTime,
  planMode,
  dayIndex,
  signalIndexOfDayColumn,
  copyPasteDay,
  selectedJunction
}) => {
  const planContextValues = useContext(PlanContext);
  const [contextSignalPlan, setContextSignalPlan] =
    planContextValues.signalPlan;
  const [locked] = planContextValues.locked;

  const [isEditing, setIsEditing] = useState(false);
  const [editedTime, setEditedTime] = useState(startTime); 
  const [selectedPlanMode, setSelectedPlanMode] = useState(planMode);

  //şu an için isbaknet 60 olarak kabul edildi. 
  //selectedJunction.kkc_type_id  60  ise  yani enumda ISBAKNETE karşılık geliyorsa 
  //bu durumda plan mode değişiklikleri flaş planları için katı olarak FLASH,
  //diğerleri için MP ve TU şeklinde seçilebilir oluyor.
  useEffect(() => {
    if (contextSignalPlan && JunctionKKCType[selectedJunction.kkc_type_id] === "IsbakNET") {
      const currentPlanMode = contextSignalPlan?.dailyPlans?.[dayIndex]?.plans?.[signalIndexOfDayColumn!]?.planMode;
      setSelectedPlanMode(currentPlanMode || "null");
      const currentPlanId = contextSignalPlan?.dailyPlans?.[dayIndex]?.plans?.[signalIndexOfDayColumn!]?.id;
      if (currentPlanMode !== "FLASH" && currentPlanId === 0 && !locked) {
        setSelectedPlanMode("FLASH");
        updatePlanMode(dayIndex, signalIndexOfDayColumn!, "FLASH");
      } else {
        setSelectedPlanMode(currentPlanMode || "null");
      }
    }
  }, [contextSignalPlan, dayIndex, signalIndexOfDayColumn,locked]);

  const dropdownItems = useMemo(() => {
    let items = contextSignalPlan?.plans?.map((plan: any) => {
      return {
        label: plan?.name,
        key: plan?.id,
      };
    });

    return items;
  }, [contextSignalPlan]);

  const planModeItems = [
    { label: "MP", key: "MP" },
    { label: "TU", key: "TU" },
  ];

  const updatePlanTime = (dayIndex: number, signalIndexOfDayColumn: number, newTime: string) => {
    setContextSignalPlan((prev: any) => {
      const updatedDailyPlans = prev?.dailyPlans?.map(
        (plansOfTheDay: any, indexOfTheDay: number) => {
          if (indexOfTheDay === dayIndex) {
            const updatedPlans = plansOfTheDay.plans.map(
              (plan: any, indexOfPlan: number) => {
                if (indexOfPlan === signalIndexOfDayColumn) {
                  return {
                    ...plan,
                    startTime: newTime,
                  };
                }
                return plan;
              }
            );
            updatedPlans.sort((a: any, b: any) =>
              a.startTime.localeCompare(b.startTime)
            );
            return {
              ...plansOfTheDay,
              plans: updatedPlans,
            };
          }
          return plansOfTheDay;
        }
      );

      return {
        ...prev,
        dailyPlans: updatedDailyPlans,
      };
    });
  };

  const updatePlanMode = (dayIndex: number, signalIndexOfDayColumn: number, newMode: string) => {
    setContextSignalPlan((prev: any) => ({
      ...prev,
      dailyPlans: prev?.dailyPlans?.map((plansOfTheDay: any, indexOfTheDay: number) => {
        if (indexOfTheDay === dayIndex) {
          return {
            ...plansOfTheDay,
            plans: plansOfTheDay.plans.map((plan: any, indexOfPlan: number) => {
              if (indexOfPlan === signalIndexOfDayColumn) {
                return {
                  ...plan,
                  planMode: newMode,
                };
              }
              return plan;
            }),
          };
        }
        return plansOfTheDay;
      }),
    }));
  };

  const handleTimeEdit = () => {
    setIsEditing(false);
    updatePlanTime(dayIndex, signalIndexOfDayColumn!, editedTime);
  };

  const changeExistingPlanOnSchedular = (selectedPlanId: number) => {
    setContextSignalPlan((prev: any) => ({
      ...prev,
      dailyPlans: prev?.dailyPlans?.map(
        (plansOfTheDay: any, indexOfTheDay: number) => {
          if (indexOfTheDay === dayIndex) {
            return {
              ...plansOfTheDay,
              plans: plansOfTheDay.plans.map(
                (plan: any, indexOfPlan: number) => {
                  if (indexOfPlan === signalIndexOfDayColumn) {
                    return {
                      ...plan,
                      id: selectedPlanId,
                    };
                  }
                  return plan;
                }
              ),
            };
          }
          return plansOfTheDay;
        }
      ),
    }));
  };

  const deleteExistingPlanFromSchedular = () => {
    setContextSignalPlan((prev: any) => ({
      ...prev,
      dailyPlans: prev?.dailyPlans?.map(
        (plansOfTheDay: any, indexOfTheDay: number) => {
          if (indexOfTheDay === dayIndex) {
            return {
              ...plansOfTheDay,
              plans: plansOfTheDay.plans.filter(
                (_: any, indexOfPlan: number) => indexOfPlan !== signalIndexOfDayColumn
              ),
            };
          }
          return plansOfTheDay;
        }
      ),
    }));
  };

  // Determine if plan mode dropdown should be disabled
  const isPlanModeDisabled = selectedPlanMode === "FLASH" && !locked;

  return (
    <Col
      style={{
        height: dayTitle ? 30 : 27,
        fontSize: ".9rem",
        backgroundColor: dayTitle ? "#333" : "#fdfdfd",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: dayTitle ? "black" : color ? color : "#000",
        borderRadius: dayTitle ? 2 : 3,
      }}
    >
      <div
        style={{
          color: "#fff",
          backgroundColor: dayTitle ? "#333" : color ? color : "#000",
          fontSmooth: "always",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          gutter={[3, 1]}
          style={{
            width: "100%",
            maxHeight: "100%",
            height: "100%",
          }}
        >
          <Col offset={4}>
            {!locked && isEditing ? (
              <Input
                value={editedTime}
                onChange={(e) => setEditedTime(e.target.value)}
                onPressEnter={handleTimeEdit}
                onBlur={handleTimeEdit}
                autoFocus
                style={{
                  width: "3rem",
                  height: "1rem",
                  fontSize: "0.85rem",
                  padding: "0 5px",
                }}
              />
            ) : (
              <span onClick={() => !locked && setIsEditing(true)}>
                {startTime}
              </span>
            )}
          </Col>
          <Col span={dayTitle ? 20 : 12} offset={dayTitle ? 4 : 1}>
            {dayTitle ? (
              <>
                <span>{dayTitle.toUpperCase()}</span>
                {!locked && (
                  <CopyOutlined
                    onClick={() => {
                      if (copyPasteDay !== undefined) {
                        copyPasteDay({
                          visible: true,
                          copyIndex: dayIndex,
                        });
                      }
                    }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: 5,
                      transform: "translateY(-50%)",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {/* Plan name dropdown */}
                {!locked && (
                  <Dropdown
                    overlay={
                      <Menu
                        items={dropdownItems}
                        selectable
                        onSelect={(selected) => {
                          changeExistingPlanOnSchedular(+selected.key);
                        }}
                      />
                    }
                    trigger={["click"]}
                  >
                    <Space style={{ cursor: "pointer" }}>
                      {planName}
                      <DownOutlined />
                    </Space>
                  </Dropdown>
                )}

                {/* Plan mode dropdown */}
                {!locked && JunctionKKCType[selectedJunction.kkc_type_id] === "IsbakNET" && (
                  <Dropdown
                    overlay={
                      <div>
                        <div style={{ padding: '8px', fontWeight: 'bold', background:"white" }}>{t("plan_mode")}</div>
                        <Menu
                          items={planModeItems}
                          selectable
                          onSelect={(selected) => {
                            setSelectedPlanMode(selected.key);
                            updatePlanMode(dayIndex, signalIndexOfDayColumn!, selected.key);
                          }}
                        />
                      </div>
                    }
                    trigger={["click"]}
                    disabled={isPlanModeDisabled}
                  >
                    <Space style={{ cursor: "pointer" }}>
                      {selectedPlanMode}
                      <DownOutlined />
                    </Space>
                  </Dropdown>
                )}

                {locked && (
                  <>
                    <span>{planName}</span>
                    {JunctionKKCType[selectedJunction.kkc_type_id] === "IsbakNET" && (<span style={{ marginLeft: '8px' }}>{selectedPlanMode}</span>)}
                  </>
                )}
              </>
            )}
          </Col>

          {!locked && !dayTitle && (
            <CloseCircleOutlined
              style={{
                position: "absolute",
                right: 4,
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "red",
              }}
              onClick={() => {
                deleteExistingPlanFromSchedular();
              }}
            />      
          )}
        </Row>
      </div>
    </Col>
  );
};

export default SchedularBlock;
