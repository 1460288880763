import { t } from "i18next";
import moment from "moment";
import React from "react";
import { useState, useEffect } from "react";
import Chart from "react-google-charts";
interface Props {
  contentData: any;
}

const colors: string[] = [
  "#17202A", //karanlık -1
  "#FF0000", //kırmızı -2
  "#FFF000", //sarı - 3
  "#FFFF00", //kırmızı sarı - 4 // gradient olacak yapılacak //yapıldı
  "#008000", //yeşil -5
  "#FD0000", //kırmızı flaş 1 -6 // flaş animasyon 1 saniye yapılcak pattern olabilir
  "#FFF000", //sarı flaş 1 -7 // flaş animasyon 1 saniye yapılcak pattern olabilir
  "#008100", //yeşil flaş 1 - 8 // flaş animasyon 1 saniye yapılcak pattern olabilir
  "#7B1107", //kırmızı flaş 2 - 9 // flaş animasyon 2 saniye yapılcak pattern olabilir
  "#FFC300", //sarı flaş 2 -10 // flaş animasyon 2 saniye yapılcak pattern olabilir
  "#148725", //yeşil flaş 2 -11 // flaş animasyon 2 saniye yapılcak pattern olabilir
  "#FFFFFF", //BEYAZ 12
  "#0000FF", //BLUE 12
];

const columns = [
  { type: "string", id: "Role" },
  { type: "string", id: "Name" },
  { type: "string", id: "style", role: "style" },
  { type: "datetime", id: "Start" },
  { type: "datetime", id: "End" },
];

const SsmData: React.FC<Props> = ({ contentData }) => {
  const [dataChart, setDataChart] = useState<any[]>([]);
  const [tick, setTick] = useState<any[]>([]);

  useEffect(() => {
    const historicalSsm = contentData?.result;
    let insertedSsmElementsItems: any[] = [];
    for (let index = 0; index < historicalSsm?.length; index++) {
      const element = historicalSsm[index]?.data?.map(
        (value: any, idx: number) => {
          let lastTime;
          let finishTime;
          let startTime = new Date(historicalSsm[index].ts);
          startTime.setHours(
            moment(historicalSsm[index].ts)
              .utcOffset(localStorage.getItem("timezone")!)
              .toObject().hours
          );

          if (historicalSsm[index + 1]) {
            finishTime = new Date(historicalSsm[index + 1].ts);
            finishTime.setHours(
              moment(historicalSsm[index + 1].ts)
                .utcOffset(localStorage.getItem("timezone")!)
                .toObject().hours
            );
          } else {
            lastTime = new Date(historicalSsm[index].ts);
            lastTime.setHours(
              moment(historicalSsm[index].ts)
                .utcOffset(localStorage.getItem("timezone")!)
                .toObject().hours
            );
            lastTime.setSeconds(lastTime.getSeconds() + 1);
          }
          let finishTs = historicalSsm[index + 1] ? finishTime : lastTime;
          return [idx + 1 + "", "", colors[value - 1], startTime, finishTs];
        }
      );
      insertedSsmElementsItems.push(...element);
    }

    if (historicalSsm?.length > 0) {
      const startTick = new Date(historicalSsm?.[0]?.ts);
      startTick.setHours(
        moment(historicalSsm?.[0]?.ts)
          .utcOffset(localStorage.getItem("timezone")!)
          .toObject().hours
      );
      const endTick = new Date(historicalSsm?.[historicalSsm?.length - 1]?.ts);
      endTick.setHours(
        moment(historicalSsm?.[historicalSsm?.length - 1]?.ts)
          .utcOffset(localStorage.getItem("timezone")!)
          .toObject().hours
      );
      const diff = Math.round((endTick.getTime() - startTick.getTime()) / 1000);
      for (let index = 0; index < diff; index++) {
        const element = new Date(startTick.getTime() + 1000 * index);
        setTick((prev) => {
          return [...prev, element];
        });
      }
    }

    //manipulation insertedSsmElementsItems start
    const uniqueGroups: any[] = Array.from(
      new Set(insertedSsmElementsItems.map((data) => data[0]))
    );
    const formattedSSM: any[] = [];

    for (const groupID of uniqueGroups) {
      const groupData = insertedSsmElementsItems.filter(
        (data) => data[0] == groupID
      );
      let previousColor: string | null = null;
      let previousIndex: number | null = null;

      for (let i = 0; i < groupData.length; i++) {
        const data = groupData[i];
    
        if (previousColor === null) {
          previousColor = data[2];
          previousIndex = i;
        } else if (previousColor !== data[2]) {
          var end = new Date(groupData[i - 1][4]).getTime();
          var start = new Date(groupData[previousIndex!][3]).getTime();
          var duration = (end -start)/1000;
          const formattedData: any = [
            groupID,
            duration.toFixed(2).toString() + t("seconds"),
            groupData[previousIndex!][2],
            groupData[previousIndex!][3],
            groupData[i - 1][4],
          ];

          formattedSSM.push(formattedData);

          previousColor = data[2];
          previousIndex = i;
        }
      }

      if (previousIndex !== null && previousIndex !== groupData.length - 1) {
        var _end = new Date( groupData[groupData.length - 1][4]).getTime();
        var _start = new Date(groupData[previousIndex][3]).getTime();
        var _duration = (_end -_start)/1000;
        const formattedData: any = [
          groupID,
          _duration.toFixed(2).toString() + t("seconds"),
          groupData[previousIndex][2],
          groupData[previousIndex][3],
          groupData[groupData.length - 1][4],
        ];

        formattedSSM.push(formattedData);
      }
    }
    //manipulation insertedSsmElementsItems end

    const dataMerged = [columns, ...formattedSSM];
    setDataChart(dataMerged);

    return () => {
      setTick([]);
      setDataChart([]);
    };
  }, [contentData]);


  return (
    <div id="statistic-ssm">
      {dataChart.length > 0 && (
        <Chart
          options={{
            hAxis: {
              format: "HH:mm:ss",
              ticks: tick
            },
            width: tick.length * 40,
            backgroundColor: "white",
          }}
          height={contentData?.result?.[0]?.data?.length * 53}
          chartType="Timeline"
          data={dataChart}
          width={"100%"}     
          graphID={"ssmstatistic"}
          style={{
            overflow:"visible",
            overflowX: "auto",
            overflowY: "hidden"
          }}
        />
      )}
      <svg style={{width:0,height:0,position:"absolute"}} aria-hidden="true" focusable="false">
        <linearGradient id="my-gradientx" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#ff0000" />
            <stop offset="49%" stopColor="#ff0000" />
            <stop offset="50%" stopColor="#ffff00" />
            <stop offset="100%" stopColor="#ffff00" />
        </linearGradient>
      </svg>
    </div>
  );
};

export default React.memo(SsmData);
