import SearchControl from "./SearchControl";
import ZoomControl from "./ZoomControl";
import MenuControl from "./MenuControl";
import ModuleToolbarControl from "./ModuleToolbarControl";

interface TopLeftControlProps {
  setLeftSiderVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

function TopLeftControl(props: TopLeftControlProps) {
  const { setLeftSiderVisible } = props;

  return (
    <div className="leaflet-top leaflet-left">
      <div className="leaflet-control leaflet-bar" style={{ border: "none" }}>
        <MenuControl setLeftSiderVisible={setLeftSiderVisible} />
        <SearchControl />
      </div>
      <div className="leaflet-control leaflet-bar" style={{ border: "none", width: "32px" }}>
        <ZoomControl />
        <ModuleToolbarControl />
      </div>
    </div>
  );
}

export default TopLeftControl;
