import { AuthService } from "@icms/ui-components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

function Home() {
  const { i18n } = useTranslation();
  
  useEffect(()=>{
    const user = AuthService.getUser();
    i18n.changeLanguage(user.user_info.language);
  },[])

  return <Navigate to="/map" />;
}
export default Home;
