import { message, Input, Popover, Slider, Button } from 'antd';
import React, { useState, MouseEvent, ChangeEvent, useEffect } from 'react';

interface ImageWithPointsProps {
  imageUrl: string;
  onPointClick: (point: { id: number; x: number; y: number; rotation: number }) => void;
  onPointRemove: (id: number) => void;
  existPoints?: any;
  onRotationChange: (id: number, rotation: number) => void;
  onPointMove: (id: number, newPosition: { x: number; y: number }) => void;


}

const ImageWithParkingPoints: React.FC<ImageWithPointsProps> = ({
  imageUrl,
  onPointClick,
  onPointRemove,
  existPoints,
  onRotationChange,
  onPointMove
}) => {
  const [points, setPoints] = useState<{ id: number; x: number; y: number; rotation: number }[]>([]);
  const [inputId, setInputId] = useState<number | ''>('');
  const [selectedPoint, setSelectedPoint] = useState<number | null>(null);
  

  const handleImageClick = (e: MouseEvent<HTMLImageElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const existingPoint = points.find(
      (point) => Math.abs(point.x - x) < 20 && Math.abs(point.y - y) < 20
    );

    if (existingPoint) {
      message.warning('ID added already');
      setSelectedPoint(existingPoint.id);
    } else if (inputId !== '') {
      const id = Number(inputId);
      const isIdExist = points.some((point) => point.id === id);

      if (isIdExist) {
        message.warning('ID added already');
      } else {
        setPoints([...points, { id, x, y, rotation: 0 }]);
        onPointClick({ id, x, y, rotation: 0 });
        setInputId('');
      }
    }
  };

  const handlePointRemove = (id: number) => {
    const updatedPoints = points.filter((point) => point.id !== id);
    setPoints(updatedPoints);
    onPointRemove(id);
    setSelectedPoint(null);
  };

  const handleRotateSliderChange = (value: number) => {
    if (selectedPoint !== null) {
      const updatedPoints = points.map((point) =>
        point.id === selectedPoint ? { ...point, rotation: value } : point
      );
      setPoints(updatedPoints);
      onRotationChange(selectedPoint as number, value);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputId(e.target.value === '' ? '' : Number(e.target.value));
  };

  useEffect(() => {
    if (existPoints) {
      setPoints(existPoints);
    }
  }, [existPoints]);

  const content = (
    <div>
      <Button onClick={() => handlePointRemove(selectedPoint as number)}>Delete</Button>
      <br />
      <Slider
        defaultValue={0}
        min={0}
        max={360}
        step={1}
        onChange={handleRotateSliderChange}
      />
      <br />
      <div>
        <Button onClick={() => handleMovePoint('up')}>↑</Button>
        <Button onClick={() => handleMovePoint('down')}>↓</Button>
        <Button onClick={() => handleMovePoint('left')}>←</Button>
        <Button onClick={() => handleMovePoint('right')}>→</Button>
      </div>
      <br />
      <Button onClick={() => setSelectedPoint(null)}>Cancel</Button>
    </div>
  );

  const handleMovePoint = (direction: 'up' | 'down' | 'left' | 'right') => {
    if (selectedPoint !== null) {
      const selectedPointData = points.find((point) => point.id === selectedPoint);

      if (selectedPointData) {
        const delta = 1;
        let newX = selectedPointData.x;
        let newY = selectedPointData.y;

        if (direction === 'left') {
          newX -= delta;
        } else if (direction === 'right') {
          newX += delta;
        } else if (direction === 'up') {
          newY -= delta;
        } else if (direction === 'down') {
          newY += delta;
        }

        const updatedPoints = points.map((point) =>
          point.id === selectedPoint ? { ...point, x: newX, y: newY } : point
        );

        setPoints(updatedPoints);
        const newPosition = { x: newX, y: newY };
        onPointMove(selectedPoint as number, newPosition);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (selectedPoint !== null) {
      e.preventDefault();
      const delta = 1;
      const selectedPointData = points.find((point) => point.id === selectedPoint);

      if (selectedPointData) {
        let newX = selectedPointData.x;
        let newY = selectedPointData.y;

        switch (e.key) {
          case 'ArrowLeft':
            newX -= delta;
            break;
          case 'ArrowRight':
            newX += delta;
            break;
          case 'ArrowUp':
            newY -= delta;
            break;
          case 'ArrowDown':
            newY += delta;
            break;
          default:
            return;
        }

        const updatedPoints = points.map((point) =>
          point.id === selectedPoint ? { ...point, x: newX, y: newY } : point
        );

        setPoints(updatedPoints);
        const newPosition = { x: newX, y: newY };
        onPointMove(selectedPoint as number, newPosition);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown as any);
    return () => {
      document.removeEventListener('keydown', handleKeyDown as any);
    };
  }, [handleKeyDown]);

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <img src={imageUrl} alt="Uploaded" onClick={handleImageClick} style={{ position: 'relative' }} />
      <Input
        type="number"
        placeholder="Enter ID"
        value={inputId}
        onChange={handleInputChange}
        style={{ position: 'absolute', top: '10px', left: '10px', width: '100px' }}
      />
      {points.map((point) => (
        <Popover
          key={point.id}
          content={content}
          title={`ID: ${point.id}`}
          visible={selectedPoint === point.id}
          trigger="click"
        >
          <div
            style={{
              position: 'absolute',
              left: point.x,
              top: point.y,
              transform: `translate(-50%, -50%) rotate(${point.rotation}deg)`,
              width: '35px',
              height: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
              borderRadius: '5px',
              cursor: 'pointer',
              padding: '5px',
            }}
            onClick={() => setSelectedPoint(point.id)}
          >
            <div
              style={{
                width: '100%',
                height: '70%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'black',
                fontWeight: 'bold',
                fontSize: '10px',
              }}
            >
              🅿️
            </div>
            <div
              style={{
                width: '100%',
                height: '30%',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                color: 'black',
                fontWeight: 'bold',
                fontSize: '12px',
                marginTop: '2px',
              }}
            >
              {point.id}
            </div>
          </div>
        </Popover>
      ))}
    </div>
  );
};

export default ImageWithParkingPoints;


//dönülecek yer