import { useEffect, useMemo } from "react";
import { useNotification } from "../../../../contexts/information/NotificationContext";
import { Badge, List, Popover } from "antd";
import moment from "moment";
import DarkButton from "../../components/custom-styled/button/DarkButton";
import { BellOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useJunctionData } from "../../../../contexts/junction/JunctionDataContext";

const notificationAudio = new Audio("/static/mango_notification.mp3");

function NotificationControl() {
  const { t } = useTranslation();
  const junctions = useJunctionData().junctionList;
  const notificationList = useNotification().notificationList;
  const readNotification = useNotification().readNotification;

  const notificationContent = useMemo(
    () => (
      <List
        itemLayout="horizontal"
        className="notification-pop"
        dataSource={notificationList}
        renderItem={(item, index) => (
          <List.Item
            style={{
              borderLeftWidth: 4,
              borderLeftColor: item.read ? "#13005A" : "tomato",
              borderLeftStyle: "solid",
              paddingLeft: 7,
              marginBottom: 6,
              cursor: item.read ? "default" : "pointer",
              transition: "all .5s",
            }}
            onClick={() => {
              readNotification(index);
            }}
          >
            <List.Item.Meta
              title={
                <>
                  <span
                    style={{
                      color: "#000",
                      fontSize: "1rem",
                      fontWeight: 600,
                    }}
                  >
                    {
                      junctions?.find(
                        (junction) => junction?.id === item?.junctionId
                      )?.name
                    }
                  </span>
                  <br />
                  <span
                    style={{
                      fontSize: "1rem",
                      color: "#333",
                      fontWeight: 600,
                    }}
                  >
                    {moment(Date.now())
                      .utcOffset(localStorage.getItem("timezone")!)
                      .format("dd/MM/YY, HH:mm:ss")}
                  </span>
                  <br />
                </>
              }
              description={
                <span
                  style={{
                    fontSize: "1rem",
                    color: "#333",
                    fontWeight: 600,
                  }}
                >
                  {item.module +
                    " - " +
                    item.description +
                    " - " +
                    item.parameters}
                </span>
              }
            />
          </List.Item>
        )}
        style={{
          overflow: "auto",
          // height: 300,
          maxHeight: "50vh",
        }}
      />
    ),
    [notificationList]
  );

  /* voice notification*/
  const notificationCount = notificationList?.filter(
    (notification) => !notification?.read
  )?.length;

  //some browsers have "User Activation" policy, ex:Chrome, this simple structure handle this issue.
  const playNotificationSound = async () => {
    await notificationAudio.play();
  };

  useEffect(() => {
    if (notificationCount > 0) {
      playNotificationSound();
    }
  }, [notificationCount]);
  /*voice notification*/

  return (
    <Popover
      content={notificationContent}
      title={t("notifications")}
      trigger="hover"
    >
      <Badge
        count={
          notificationList?.filter((notification) => !notification?.read)
            ?.length
        }
        style={{ marginLeft: "30px", zIndex: 5 }}
      >
        <DarkButton icon={<BellOutlined color="white" />} />
      </Badge>
    </Popover>
  );
}

export default NotificationControl;
