import { useEffect, useState, useMemo, useCallback } from "react";
import { Input, Modal, Select, Image, InputNumber } from "antd";

import DetectorInterface from "../../../models/DetectorInterface";
import DetectorModalStateInterface from "../../../models/DetectorModalStateInterface";

import JunctionDetectorEnum from "../../../models/JunctionDetectorEnum";

import planFormPhasesTableFields from "../formFieldStructs/planFormPhasesTableFields";
import { useTranslation } from "react-i18next";

// get the necessary field names
const {
  loopList: loopListFieldKey,
  buttonList: buttonListFieldKey,
  detectorList: detectorListFieldKey,
  detectorDecisionList: detectorDecisionListFieldKey,
} = planFormPhasesTableFields;

const { Option } = Select;

interface Props {
  modalState: DetectorModalStateInterface;
  setIsModalVisible: any;
  detectors: DetectorInterface[];
  updatePhasesForm: (field: any, phaseKey: any, value: any) => void;
}

const DetectorPlanModal: React.FC<Props> = ({
  modalState,
  setIsModalVisible,
  detectors,
  updatePhasesForm,
}) => {
  const { t } = useTranslation();
  const rowPhaseId = useMemo(() => modalState.phaseId, [modalState]);

  // available camera detectors
  const availableJuncVieros: string[] = useMemo(
    () =>
      detectors
        .filter(
          (detector) => detector.devicetype_id === JunctionDetectorEnum.Camera
        )
        .map((detector) => `${detector?.internal_id}`),
    [detectors]
  );

  // available loop detectors
  const availableJuncLoops: string[] = useMemo(
    () =>
      detectors
        .filter(
          (detector) => detector.devicetype_id === JunctionDetectorEnum.Loop
        )
        .map((detector) => `${detector?.internal_id}`),
    [detectors]
  );

  // available button detectors
  const availableJuncButtons: string[] = useMemo(
    () =>
      detectors
        .filter(
          (detector) => detector.devicetype_id === JunctionDetectorEnum.Button
        )
        .map((detector) => `${detector?.internal_id}`),
    [detectors]
  );

  const initializeSelectedDetectors = useCallback(
    (rowData: any) => {
      let loopList =
        rowData[loopListFieldKey] &&
        typeof rowData[loopListFieldKey] === "string"
          ? rowData[loopListFieldKey].split("-")
          : [];

      let buttonList =
        rowData[buttonListFieldKey] &&
        typeof rowData[buttonListFieldKey] === "string"
          ? rowData[buttonListFieldKey].split("-")
          : [];

      let detectorList =
        rowData[detectorListFieldKey] &&
        typeof rowData[detectorListFieldKey] === "string"
          ? rowData[detectorListFieldKey].split("-")
          : [];

      return [...loopList, ...buttonList, ...detectorList];
    },
    [modalState.rowData]
  );

  const [tempSelectedDetectors, setTempSelectedDetectors] = useState<string[]>(
    initializeSelectedDetectors(modalState.rowData)
  );

  const checkVieroListTitle = () => {
    let shouldShowVieroListTitle = false;
    for (let i = 0; i < tempSelectedDetectors.length; i++) {
      if (availableJuncVieros.includes(tempSelectedDetectors[i])) {
        shouldShowVieroListTitle = true;
        break;
      }
      continue;
    }
    return shouldShowVieroListTitle;
  };

  const [showVieroListTitle, setShowVieroListTitle] = useState<boolean>(
    checkVieroListTitle()
  );

  const handleChange = (value: string[]) => {
    // the "value" is a array of selected detectors' ids
    setTempSelectedDetectors(value);
  };

  const joinedArraysIntersection = (array1: any[], array2: any[]) => {
    let joinedIntersection = array1
      .filter((value) => array2.includes(value))
      .join("-");

    if (joinedIntersection === "") {
      return null;
    }
    return joinedIntersection;
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    // detectorList
    // let selectedCameras = joinedArraysIntersection(availableJuncVieros, tempSelectedDetectors);

    // buttonList
    let selectedButtons = joinedArraysIntersection(availableJuncButtons, tempSelectedDetectors);

    // loopList
    let selectedLoops = joinedArraysIntersection(availableJuncLoops, tempSelectedDetectors);

    // detectorDecisionList
    let detectorDecisions = Object.values(detectorDecisionListObject).join("-");

    // parameters: field, phaseKey, value
    // updatePhasesForm(detectorListFieldKey, rowPhaseId, selectedCameras);
    updatePhasesForm(detectorListFieldKey, rowPhaseId, Object.keys(detectorDecisionListObject).join("-"));
    updatePhasesForm(buttonListFieldKey, rowPhaseId, selectedButtons);
    updatePhasesForm(loopListFieldKey, rowPhaseId, selectedLoops);
    updatePhasesForm(
      detectorDecisionListFieldKey,
      rowPhaseId,
      detectorDecisions
    );
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const initializeDetectorDecisionListObject = useCallback(
    (rowData: any) => {
      let returnValue: any = {};

      let detectorList =
        rowData[detectorListFieldKey] &&
        typeof rowData[detectorListFieldKey] === "string"
          ? rowData[detectorListFieldKey].split("-")
          : [];

      let detectorDecisionList =
        rowData[detectorDecisionListFieldKey] &&
        typeof rowData[detectorDecisionListFieldKey] === "string"
          ? rowData[detectorDecisionListFieldKey].split("-")
          : [];

      detectorList.forEach((detector: any, index: number) => {
        returnValue[detector] = detectorDecisionList[index];
      });

      return returnValue;
    },
    [modalState.rowData]
  );

  /*
    @detectorDecisionListObject
    {
      [detectorId]: decision
    }
  */
  const [detectorDecisionListObject, setDetectorDecisionListObject] =
    useState<any>(initializeDetectorDecisionListObject(modalState.rowData));

  const updateDetectorDecisionListObject = (detectorId: any, value: any) => {
    setDetectorDecisionListObject((prev: any) => {
      return {
        ...prev,
        [detectorId]: value,
      };
    });
  };

  useEffect(() => {
    // check whether should show availableJuncVieros list header
    setShowVieroListTitle(checkVieroListTitle());

    // selectedVieros
    let selectedVieros: string[] = availableJuncVieros.filter((viero: any) =>
      tempSelectedDetectors.includes(viero)
    );

    // before update
    // if necessary delete already deleted selectedViero also from detectorDecisionListObject
    let detectorDecisionListVieroIds = Object.keys(detectorDecisionListObject);

    let decisionFieldsRemoveList = detectorDecisionListVieroIds.filter(
      (detectorDecisionListVieroId: string) => {
        return !selectedVieros.includes(detectorDecisionListVieroId);
      }
    );

    if (decisionFieldsRemoveList.length > 0) {
      let cloneDetectorDecisionListObject: any = {
        ...detectorDecisionListObject,
      };
      for (let i = 0; i < decisionFieldsRemoveList.length; i++) {
        delete cloneDetectorDecisionListObject[decisionFieldsRemoveList[i]];
      }
      setDetectorDecisionListObject(cloneDetectorDecisionListObject);
    }
  }, [tempSelectedDetectors]);

  return (
    <>
      <Modal
        title={t("detectors")}
        visible={modalState.visible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        centered
        maskClosable={false}
      >
        <Select
          mode="tags"
          placeholder={t("please_select")}
          onChange={handleChange}
          style={{ width: "100%" }}
          value={tempSelectedDetectors}
        >
          {detectors?.map(
            (detector: DetectorInterface, detectorIndex: number) => (
              <Option key={detector?.internal_id}>
                {
                  <div key={`detector-${detectorIndex}`}>
                    <span>{`${detector?.internal_id} - ${detector.name}`}</span>
                    <Image
                      src={`/static/assets/detectors/detector${detector?.devicetype_id}.png`}
                      preview={false}
                      width={20}
                      height={20}
                      style={{
                        marginLeft: ".8rem",
                      }}
                    />
                  </div>
                }
              </Option>
            )
          )}
        </Select>
        <br />
        <br />

        {/* Title of selected availableJuncVieros */}
        {showVieroListTitle && <h4>{ t("camera_typed_detectors") }</h4>}

        <Input.Group>
          {tempSelectedDetectors.map((selectedDetectorId: string) => {
            let selectedDetectorObject = detectors.find(
              (item) => +item.internal_id === +selectedDetectorId
            );

            if (
              selectedDetectorObject?.devicetype_id ===
              JunctionDetectorEnum.Camera
            ) {
              // if the viero detector id key is not inserted
              if (
                !detectorDecisionListObject.hasOwnProperty(
                  selectedDetectorObject?.internal_id
                )
              ) {
                updateDetectorDecisionListObject(
                  selectedDetectorObject?.internal_id,
                  20
                );
              }
              return (
                <InputNumber
                  addonBefore={`${selectedDetectorObject?.internal_id} - ${selectedDetectorObject?.name}`}
                  defaultValue={20}
                  style={{
                    marginBottom: ".6rem",
                    width: "100%",
                  }}
                  value={detectorDecisionListObject[selectedDetectorObject?.internal_id]}
                  onChange={(value) =>
                    updateDetectorDecisionListObject(
                      selectedDetectorObject?.internal_id,
                      value
                    )
                  }
                  key={`vieroId-${selectedDetectorObject?.internal_id}`}
                />
              );
            }
          })}
        </Input.Group>
      </Modal>
    </>
  );
};

export default DetectorPlanModal;
