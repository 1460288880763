import { useEffect, useMemo, useState } from "react";
import L, { LeafletMouseEvent } from "leaflet";
import { Marker, Tooltip, useMap } from "react-leaflet";
import withLogging from "../../high-order-components/LoggerHOC";
import GenericMapModal from "../../components/generic/map-modal/GenericMapModal";
import {
  JunctionDetector,
  detectorIcons,
} from "../../../../data/junction-detector/JunctionDetector";
import VieroDeviceMain from "../../../viero-device";
import CameraModalTitle from "./CameraModalTitle";
import { useCameraData } from "../../../../contexts/camera/CameraDataContext";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import { t } from "i18next";
import GenericMarkerDeleteModal from "../../components/generic/marker-context-modal/GenericMarkerDeleteModal";
import MarkerGenericAddUpdateContent from "../../components/generic/marker-context-modal/MarkerGenericAddUpdateContent";
import DeviceService from "../../../../data/device/DeviceService";
import { useMultipleWatchData } from "../../../../contexts/map/MultipleWatchContext";
import { MapModule } from "@icms/ui-components"
import { cameraModel } from "../../components/generic/marker-context-modal/model/Camera";

function CameraLayer() {
  const { cameraList, updateAllCamera } = useCameraData();
  const { setLayerData } = useCustomLayerData();
  const { addSelectedItem } = useMultipleWatchData();
  const [markerData, setMarkerData] = useState<JunctionDetector[]>([]);
  const [selectedCamera, setSelectedCamera] = useState<JunctionDetector>();
  const [camera, setCamera] = useState<JunctionDetector>();

  const map = useMap();

  const markerHandlers = (camera: JunctionDetector) => {
    return {
      click: (event: LeafletMouseEvent) =>
        addSelectedItem(event, camera, "camera"),
      dblclick: (event: LeafletMouseEvent) =>
        handleMarkerDblClick(event, camera),
      contextmenu: (event: LeafletMouseEvent) =>
        handleMarkerContextMenu(camera),
    };
  };

  const updateCamera = (camera: JunctionDetector) => {
    setCamera(camera);
  };

  const deleteCamera = (camera: JunctionDetector) => {
    GenericMarkerDeleteModal(camera.name, async () => {
      await DeviceService.deleteDevice(camera.id);
      updateAllCamera();
    });
  };

  const handleMarkerDblClick = (
    event: LeafletMouseEvent,
    camera: JunctionDetector
  ) => {
    setSelectedCamera(camera);
  };

  const handleMarkerContextMenu = (camera: JunctionDetector) => {
    map.contextmenu.removeAllItems();
    map.contextmenu.addItem({
      text: t("update"),
      callback: () => updateCamera(camera),
    });
    map.contextmenu.addItem({
      text: t("delete"),
      callback: () => deleteCamera(camera),
    });
  };

  const onCloseModal = () => {
    setCamera(undefined);
    setSelectedCamera(undefined);
  };

  useEffect(() => {
    setLayerData({
      id: MapModule.CameraModule,
      data: cameraList,
      searchable: {
        category: "camera_management",
        filterProperties: ["id", "name"],
        labelProperty: "name",
      },
      legend: {
        countProperty: "devicetype_id",
        setOnMapData: setMarkerData,
      },
    });
    setMarkerData(cameraList);
  }, [cameraList]);

  const modalContent = useMemo(() => {
    return (
      <>
        {selectedCamera && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <CameraModalTitle
                componentName="CameraModalTitle"
                selectedCamera={selectedCamera}
              />
            }
          >
            <VieroDeviceMain selectedCamera={selectedCamera} selectedTab="1" />
          </GenericMapModal>
        )}
      </>
    );
  }, [selectedCamera]);

  const addUpdateModalContent = useMemo(() => {
    return (
      <>
        {camera && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <CameraModalTitle
                componentName="CameraModalTitle"
                selectedCamera={camera}
              />
            }
          >
            <MarkerGenericAddUpdateContent
              modalType="update"
              model={cameraModel}
              setModalVisible={onCloseModal}
              icon={detectorIcons[camera.devicetype_id].base64!}
              latlon={new L.LatLng(camera.latitude, camera.longitude)}
              triggerStateFunc={updateAllCamera}
              formData={camera}
            />
          </GenericMapModal>
        )}
      </>
    );
  }, [camera]);

  return (
    <>
      {markerData.length > 0 &&
        markerData.map((camera) => {
          return (
            <Marker
              key={camera.id}
              position={[camera.latitude, camera.longitude]}
              icon={detectorIcons[camera.devicetype_id].marker}
              eventHandlers={markerHandlers(camera)}
              contextmenu={true}
            >
              <Tooltip>{camera.name}</Tooltip>
            </Marker>
          );
        })}
      {modalContent}
      {addUpdateModalContent}
    </>
  );
}

export default withLogging(CameraLayer);
