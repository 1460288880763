import { createContext, useEffect, useState } from "react";
import { getPhases, getDetectors } from "../../../../data/signal-plan/SignalPlanService";
import { PhaseType } from "../components/CreateNewPlan";

interface Props {
  children: any;
  selectedJunction: any;
}

export const PlanContext = createContext<any>(null);

export const PlanProvider = (props: Props) => {
  const { selectedJunction } = props;

  const [signalPlan, setSignalPlan] = useState<any>(null);
  const [signalPlanBackup, setSignalPlanBackup] = useState<any>(null);
  const [phases, setPhases] = useState<PhaseType[]>([]);
  const [detectors, setDetectors] = useState<PhaseType[]>([]);
  const [locked, setLocked] = useState<boolean>(true);

  // if edit is locked, then return backup(initial) value
  useEffect(() => {
    if (locked) {
      setSignalPlan(signalPlanBackup);
    }
  }, [locked]);

  useEffect(() => {
    const { id: junction_id, junction_model_id } = selectedJunction;
    if (junction_id && junction_model_id) {
      // get phases
      getPhases({
        junction_id: selectedJunction?.id,
        junction_model_id: selectedJunction?.junction_model_id,
      }).then((res: any) => {
        if (res.data && Array.isArray(res.data)) {
          setPhases(res.data);
        }
      });

      getDetectors({
        junction_id: selectedJunction?.id,
      }).then((res: any) => {
        if (res.data && Array.isArray(res.data)) {
          setDetectors(res.data);
        }
      });
    }
  }, []);

  return (
    <PlanContext.Provider
      value={{
        signalPlan: [signalPlan, setSignalPlan],
        signalPlanBackup: [signalPlanBackup, setSignalPlanBackup],
        locked: [locked, setLocked],
        juncPhases: phases,
        juncDetectors: detectors,
      }}
    >
      {props.children}
    </PlanContext.Provider>
  );
};
