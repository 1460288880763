import { useMap } from "react-leaflet";

function ZoomControl() {
  const map = useMap();

  const zoomIn = (e: any) => {
    e.preventDefault();
    map.setZoom(map.getZoom() + 1);
  };

  const zoomOut = (e: any) => {
    e.preventDefault();
    map.setZoom(map.getZoom() - 1);
  };

  return (
    <div className="leaflet-bar" style={{ border: "none", marginTop: "5px" }}>
      <a
        className="leaflet-control-zoom-in"
        href="/"
        title="Zoom in"
        role="button"
        aria-label="Zoom in"
        onClick={zoomIn}
      >
        +
      </a>
      <a
        className="leaflet-control-zoom-out"
        href="/"
        title="Zoom out"
        role="button"
        aria-label="Zoom out"
        onClick={zoomOut}
      >
        −
      </a>
    </div>
  );
}

export default ZoomControl;
