import { CarOutlined, CheckOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import StyledButton from "../../components/custom-styled/button/StyledButton";
import { useFCDData } from "../../../../contexts/fcd/FCDContext";

function FCDControl() {
  const { setVisibleSegmentBasedSpeed } = useFCDData();
  const [isActive, setIsActive] = useState(false);

  const toggleMap = () => {
    setIsActive((prev) => !prev);
  };

  useEffect(() => {
    setVisibleSegmentBasedSpeed(isActive);
  }, [isActive]);

  return (
    <div className="leaflet-bar" style={{ border: "none", marginTop: "10px" }}>
      <Tooltip placement="right" title="FCD">
        <StyledButton
          icon={isActive ? <CheckOutlined /> : <CarOutlined />}
          backgroundColor={isActive ? "#00FF00" : "#2F4050"}
          borderColor={isActive ? "#00FF00" : "#2F4050"}
          onClick={toggleMap}
          style={{ color: "white" }}
        ></StyledButton>
      </Tooltip>
    </div>
  );
}

export default FCDControl;
