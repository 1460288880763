import { Card } from "antd";
import DarkButton from "../button/DarkButton";
import { useState } from "react";
import styled from "styled-components";
import Flex from "./Flex";
import { t } from "i18next";
import StyledButton from "../button/StyledButton";

interface Props {
  children: React.ReactNode;
  title: string;
  icon: any;
}

function MultiModePanel(props: Props) {
  const { children, title, icon } = props;
  const [isOpen, setOpen] = useState(false);

  const onClick = () => {
    setOpen((val) => !val);
  };

  return (
    <Flex justifyContent="flex-start">
      {/* <CSSTransition in={isOpen} timeout={1300} classNames="slide"> */}
      {/* <DarkButton icon={icon} onClick={onClick} css={btnCss} /> */}
      <StyledButton
        type="primary"
        backgroundColor={"#2f4050"}
        borderColor="#2f4050"
        boxShadow="0 0 5px rgb(47 64 80 / 70%)"
        css={btnCss}
        onClick={onClick}
      >
        {props.icon}
        <p
          style={{
            marginBottom: 0,
            color: "#fff",
            fontSize: "13px",
          }}
        >
          {/* {props.name && t(props.name)} */}
          {t(props.title)}
        </p>
      </StyledButton>
      <CardCustom isOpen={isOpen}>
        {isOpen && (
          <div
            style={{
              position: "absolute",
              zIndex: 99999,
              backgroundColor: "rgba(47, 64, 80, 0.9)",
              minWidth: 300,
              left: "13rem",
              top: "2rem",
              color: "#fff",
              padding: "0.6rem 1rem",
              borderRadius: 4,
            }}
          >
            <h2 style={{ color: "#fff" }}>{t(title)}</h2>
            {children}
          </div>
        )}
      </CardCustom>

      {/* </CSSTransition> */}
    </Flex>
  );
}

export default MultiModePanel;

const btnCss = `
  margin-left: 0px;
  padding-left: 0.5rem;
          display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
`;

const CardCustom = styled.div<any>`
  visibility: ${(p) => (p.isOpen ? "visible" : "hidden")};
`;
