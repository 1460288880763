import JunctionLayer from "../layers/junction/JunctionLayer";
import VmsLayer from "../layers/vms/VmsLayer";
import VieroLayer from "../layers/viero/VieroLayer";
import CameraLayer from "../layers/camera/CameraLayer";
import BluesisLayer from "../layers/bluesis/BluesisLayer";
import UpsLayer from "../layers/ups/UpsLayer";
import { VehicleDataProvider } from "../../../contexts/eps/VehicleContext";
import VehicleLayer from "../layers/vehicle/VehicleLayer";
import { MapModule } from "@icms/ui-components"
import FCDLayer from "../layers/fcd/FCDLayer";
import TaskManagementLayer from "../layers/taskmanagement/TaskManagementLayer";
import { BluesisStatusDataProvider } from "../../../contexts/bluesis/BluesisStatusDataContext";
import InventoryLayer from "../layers/inventory/InventoryLayer";
import BarrierLayer from "../layers/barrier/BarrierLayer";
import { VieroStatusDataProvider } from "../../../contexts/viero/VieroStatusDataContext";
import QueueLengthLayer from "../layers/queue-length/QueueLengthLayer";
import JunctionService from "../../../data/junction/JunctionService";
import VmsService from "../../../data/vms/VmsService";
import BluesisService from "../../../data/bluesis/BluesisService";
import DeviceService from "../../../data/device/DeviceService";
import { getTasks } from "../../../data/task-management/TaskManagementService";
import UpsService from "../../../data/ups/UpsService";
import InventoryService from "../../../data/inventory/InventoryService";
import BarrierService from "../../../data/barrier/BarrierService";

export interface CustomLayerGroup {
  id: MapModule;
  name: string;
  authorized_component_name: string;
  isChecked: boolean;
  layer: JSX.Element;
  getterFunc?: Function;
}

export const CustomLayerData: CustomLayerGroup[] = [
  {
    id: MapModule.JunctionModule,
    name: "junction_management",
    authorized_component_name: "Junction Module",
    isChecked: true,
    layer: <JunctionLayer componentName="JunctionLayer" />,
    getterFunc: JunctionService.getJunctions
  },
  {
    id: MapModule.VmsModule,
    name: "vms_management",
    authorized_component_name: "Vms Module",
    isChecked: false,
    layer: <VmsLayer componentName="VmsLayer" />,
    getterFunc: VmsService.getVmses
  },
  {
    id: MapModule.BluesisModule,
    name: "bluesis_management",
    authorized_component_name: "Bluesis Module",
    isChecked: false,
    layer: (
      <BluesisStatusDataProvider>
        <BluesisLayer componentName="BluesisLayer" />,
      </BluesisStatusDataProvider>
    ),
    getterFunc: BluesisService.getBluesises
  },
  {
    id: MapModule.CameraModule,
    name: "camera_management",
    authorized_component_name: "Camera Module",
    isChecked: false,
    layer: <CameraLayer componentName="CameraLayer" />,
    getterFunc: DeviceService.getCameras
  },
  {
    id: MapModule.TaskManagementModule,
    name: "task_management",
    authorized_component_name: "Task Management Module",
    isChecked: false,
    layer: <TaskManagementLayer componentName="CameraLayer" />,
    getterFunc: getTasks
  },
  {
    id: MapModule.VieroModule,
    name: "viero_management",
    authorized_component_name: "Viero Module",
    isChecked: false,
    layer: (
      <VieroStatusDataProvider>
       <VieroLayer componentName="VieroLayer" />,
      </VieroStatusDataProvider>
    ),
    getterFunc: DeviceService.getVieroDevices
  },
  {
    id: MapModule.VehiclePriortyModule,
    name: "vehicle_priorty",
    authorized_component_name: "Vehicle Priorty Module",
    isChecked: false,
    layer: (
      <VehicleDataProvider>
        <VehicleLayer componentName="VehicleLayer" />
      </VehicleDataProvider>
    ),
  },
  {
    id: MapModule.UpsModule,
    name: "ups_management",
    authorized_component_name: "Ups Module",
    isChecked: false,
    layer: <UpsLayer componentName="UpsLayer" />,
    getterFunc: UpsService.getUpses
  },
  {
    id: MapModule.FCDModule,
    name: "FCD",
    authorized_component_name: "FCD Module",
    isChecked: true,
    layer: <FCDLayer componentName="FCDLayer" />,
  },
  {
    id: MapModule.InventoryModule,
    name: "inventory_management",
    authorized_component_name: "Inventory Module",
    isChecked: false,
    layer: <InventoryLayer componentName="InventoryLayer" />,
    getterFunc: InventoryService.getInventories
  },
  {
    id: MapModule.BarrierModule,
    name: "barrier_management",
    authorized_component_name: "Barrier Module",
    isChecked: false,
    layer: <BarrierLayer componentName="BarrierLayer" />,
    getterFunc: BarrierService.getBarriers
  },
  {
    id: MapModule.QueueLengthModule,
    name: "queue_length",
    authorized_component_name: "Queue Length Module",
    isChecked: false,
    layer: <QueueLengthLayer componentName="QueueLengthLayer" />,
  },
];
