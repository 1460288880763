import http from "./../http.common";
import { Organization } from "./Organization";

const getOrganizations = () => {
  return http.get<Organization[]>(`/web/organization/organizations`);
};

const OrganizationService = {
  getOrganizations,
};
export default OrganizationService;
