import { Device } from "../device/device";
import { FormProps } from "../common/FormProps";
import { t } from "i18next";

export interface Junction extends Device {
  id_junction:number;
  status_id: JunctionStatus;
  junctionNo: string;
  kkc_type_id: number;
  centrisVersion: string;
  ip: string;
  port: number;
  junction_model_id: number;
  listen_mode:boolean;
  ai_is_active:boolean;
  ai_status_id:number;
  is_dummy:boolean;
  devicemodel_id:number;
  ipaddress:string;
  http_port:number;
  org_id:number;
  org_name:string;
}

export interface JunctionDefinition {
  id: number;
  name: string;
  latitude:number;
  longitude:number;
  address:string;
}

//TODO: gerekli olmayabilir, gruplar ve junctionlar ayrılacak. ayrı ayrı yenisi yazılmalı.
export const JunctionProps: { [key: string]: FormProps } = {
  name: { rules: [{ required: true , message:"Kavsak Adi is required"}] },
  latitude: { rules: [{ required: true , message:"Latitude is required"}] },
  longitude: { rules: [{ required: true, message:"Longitude is required" }] },
  device: { rules: [{ required: true }] },
  centrisVersion: { rules: [{ required: true }] },
  ip: { rules: [{ required: true }] },
  port: { rules: [{ required: true }] },
};


//junction form rules
export const JunctionFormProps: { [key: string]: FormProps } = {
  id:{rules:[{required:true, message:t("this_field_is_required")!}]},
  name: { rules: [{ required: true, message: t("this_field_is_required")! }] },
  latitude:  { rules: [{ required: true, message: t("this_field_is_required")! }] },
  longitude: { rules: [{ required: true, message: t("this_field_is_required")! }] } ,
  address: { rules: [{ required: true, message: t("this_field_is_required")! }] },
  status_id:{ rules: [{ required: true, message: t("this_field_is_required")! }] },
  org_id: { rules: [{ required: true, message: t("this_field_is_required")! }] }
};

export enum JunctionStatus {
  G =1,
  PF=2,
  FP=3,
  PD=4,
  S=5,
  C=6,
  NC=7,
  PS=8,
  FE=9,
  LM=10,
  DJ=11,
  DS=12,
  VP=13,
  NS=14,
  FF=15,
  UK=99,
}


export enum AiStatus {
  CFM = 1,
  CHAOS,
  DISCHARGE,
  COORDINATION,
  DEFAULT,
  AIPREDICTION
}


export enum JunctionDeviceState {
  UserRequested = 1,
  MicroServiceGeted,
  Processing,
  InQueue,
  Timeout,
  Success,
  SendedResponseToUser,
  Failed,
  DeviceError,
  DeviceNotFound
}

export enum JunctionKKCType {
  Unknown = -1,
  Blind = 0,
  Hikvision = 1,
  Maestro = 2,
  Master = 3,
  RRElectronics = 4,
  Sintra = 5,
  Isbak = 6,
  Swarco = 7,
  Antra = 8,
  IsbakNET = 60
}
