import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Task } from "../../data/task-management/TaskManagement";
import {
  getTasks,
  getTechnicianList,
} from "../../data/task-management/TaskManagementService";
import { socket } from "../websocket/WebSocketContext";
import { useLicenseData } from "../license/LicenseDataContext";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";

export interface TaskManagementContextDataState {
  taskData: Task[];
  technicianList: any[];
  activeTechicianList: any[];
  archivedTaskList: any[];
  updateTaskList: () => void;
  updateArchivedTaskList: (taskList: any) => void;
  updateTaskStatus: (taskId: any, status: any) => void;
}

const TaskManagementDataContext = createContext<
  TaskManagementContextDataState | undefined
>(undefined);

export function TaskManagementDataProvider({
  children,
}: PropsWithChildren<{}>) {
  const { licenseData } = useLicenseData();
  const [allTasks, setAllTasks] = useState<Task[]>([]);
  const [archivedTasks, setArchivedTasks] = useState<Task[]>([]);
  const [technicianList, setTechnicianList] = useState([]);
  const [activeTechicianList, setActiveTechicianList] = useState<any[]>([]);
  const { hasAccessComponent } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  useEffect(() => {
    socket.emit("setUserInfo", {
      userId: 9999,
      roles: ["operator"],
      token: "token",
      username: "web",
      latlon: [20, 20],
    });

    socket.on("connectedClientsUpdate", (data: any) => {
      setActiveTechicianList(data);
    });

    return () => {
      socket.off("connectedClientsUpdate");
    };
  }, []);

  useEffect(() => {
    if (licenseData?.modules.taskManagement) {
      getAllTasks();
      getTechnicians();
    }
  }, [licenseData]);

  const getAllTasks = async () => {
    await getTasks()
      .then((res: any) => {
        setAllTasks(res.data);
      })
      .catch((err) => console.log("Error is", err));
  };

  const getTechnicians = async () => {
    await getTechnicianList()
      .then((res: any) => {
        setTechnicianList(res.data);
      })
      .catch((err) => console.log("Error while fetching technicians:", err));
  };

  const updateTaskStatus = (taskId: any, status: any) => {
    setAllTasks((prevTasks) => {
      return prevTasks.map((task) => {
        if (task.id === taskId) {
          return { ...task, task_status: status };
        }
        return task;
      });
    });
  };

  const updateArchivedTaskList = (taskList: any) => {
    setArchivedTasks(taskList);
  };

  return hasAccessComponent("Task Management Module-READ") ? (
    <TaskManagementDataContext.Provider
      value={{
        taskData: allTasks,
        technicianList: technicianList,
        activeTechicianList: activeTechicianList,
        archivedTaskList: archivedTasks,
        updateTaskList: getAllTasks,
        updateTaskStatus: updateTaskStatus,
        updateArchivedTaskList: updateArchivedTaskList,
      }}
    >
      {children}
    </TaskManagementDataContext.Provider>
  ) : (
    <>{children}</>
  );
}

export function useTaskManagementData() {
  const context = useContext(TaskManagementDataContext);
  if (context === undefined) {
    return {
      taskData: [],
      technicianList: [],
      activeTechicianList: [],
      archivedTaskList: [],
      updateTaskList: () => {},
      updateArchivedTaskList: (taskList: any) => {},
      updateTaskStatus: (taskId: any, status: any) => {},
    };
    // throw new Error(
    //   "useTaskManagementData must be used within a TaskManagementDataContext"
    // );
  }
  return context;
}
