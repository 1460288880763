import { GenericMapModalTitle } from "../../components/generic/map-modal/GenericMapModalTitle";
import withLogging from "../../high-order-components/LoggerHOC";
import { Vms } from "../../../../data/vms/Vms";

interface VmsModalTitleProps {
  selectedVms: Vms;
}

function VmsModalTitle(props: VmsModalTitleProps) {
  const { selectedVms } = props;

  return (
    <GenericMapModalTitle color="#1890ff">
      <span style={{ fontSize: "2vw" }}>{selectedVms.name}</span>
    </GenericMapModalTitle>
  );
}

export default withLogging(VmsModalTitle);
