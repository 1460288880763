import { Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import { Predicted } from "../../../../../../../data/ai-prediction/Predicted";
import moment from "moment";
import { JunctionStatusInfo } from "../../../../../../map/map-constants/module-status/JunctionStatusIno";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
interface Props {
  contentData: any;
}
const StatusData: React.FC<Props> = ({ contentData }) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
   console.log(contentData)
   setDataSource(contentData.result)
  },[contentData])
  const columns: ColumnsType<Predicted> = [
    {
      title: t("date"),
      dataIndex: "ts",
      key: "ts",
      align: "center",
      render: (update_ts: number) => (
        <>{ moment(update_ts).utcOffset(localStorage.getItem("timezone")!).format("DD/MM/YY HH:mm:ss")}</>
      )
    },


    {
      title: `${t("previous_value")}`,
      dataIndex: "status_old_id",
      key: "status_old_id",
      align: "center",
      render: (id: number) => (
        <div style={{width:'150px'}}>     
            <img
              style={{height:'30px',float:'left' }}
              src={`${
                JunctionStatusInfo[
                  id as keyof typeof JunctionStatusInfo
                ].icon
              }`}
          ></img>
          <div style={{marginLeft:'5px',paddingTop:'5px',float:'left' }}>{t(JunctionStatusInfo[
                  id as keyof typeof JunctionStatusInfo
                ].text)}</div>
        </div>
      )


    },

    {
      title: '-',
      dataIndex: "ts",
      key: "ts",
      align: "center",
      render: (update_ts: number) => (
        <> <ArrowRightOutlined style={{fontSize:'20px'}}/></>
      )
    },
    {
      title: `${t("new_value")}`,
      dataIndex: "status_new_id",
      key: "status_new_id",
      align: "center",
      render: (id: number) => (
        <div style={{width:'150px'}}>     
            <img
              style={{height:'30px',float:'left' }}
              src={`${
                JunctionStatusInfo[
                  id as keyof typeof JunctionStatusInfo
                ].icon
              }`}
          ></img>
          <div style={{marginLeft:'5px',paddingTop:'5px',float:'left' }}>{t(JunctionStatusInfo[
                  id as keyof typeof JunctionStatusInfo
                ].text)}</div>
        </div>
      )
    },
    {
      title: `${t("detail")}`,
      dataIndex: "message",
      key: "message",
      align: "center"
    },
  ];

  const headers = [
    { label: `${t("date")}`, key: "ts" },
    { label: `${t("old")}`, key: "junction_id" },
  ];

  return (
    <>
      {dataSource && (
        <>
          <Table
            rowKey="id"
            dataSource={dataSource}
            columns={columns}
            pagination={{pageSize:50}}
          />
        </>
      )}
    </>
  );
};

export default StatusData;
