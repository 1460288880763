import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";

export interface statusDataModel {
  id: number;
  status_id: number;
  ts: number;
}

interface StatusDataProps {
  allStatusData: statusDataModel[];
}

const AllStatusDataContext = createContext<StatusDataProps | undefined>(
  undefined
);

export function AllStatusDataProvider({ children }: PropsWithChildren<{}>) {
  const [statusData, setStatusData] = useState<statusDataModel[]>([]);
  useEffect(() => {
    socket.on("allStatusData", (data: any) => {
      setStatusData((statusData) => [data]);
    });

    return () => {
      socket.off("allStatusData");
    };
  }, []);

  return (
    <AllStatusDataContext.Provider
      value={{
        allStatusData: statusData,
      }}
    >
      {children}
    </AllStatusDataContext.Provider>
  );
}

export function useAllStatusData() {
  const context = useContext(AllStatusDataContext);
  if (context === undefined) {
    return {
      allStatusData: [],
    };
    // throw new Error(
    //   "useAllStatusData must be used within a AllStatusDataContext"
    // );
  }
  return context;
}
