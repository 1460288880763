import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";

export interface ssmDataModel {
  id: number;
  data: number[];
  junctionId: number;
  ts: string;
  ts_end: string;
  is_last: boolean;
}

interface SsmDataProps {
  ssmData: ssmDataModel[];
}

const SsmDataContext = createContext<SsmDataProps | undefined>(undefined);

export function SsmDataProvider({ children }: PropsWithChildren<{}>) {
  const [ssmData, setSSMData] = useState<ssmDataModel[]>([]);

  useEffect(() => {
    socket.on("ssmData", (data: ssmDataModel) => {
      setSSMData((ssmData) => [data]);
    });

    return () => {
      socket.off("ssmData");
    };
  }, []);

  useEffect(() => {
    socket.on("multipleSsmData", (data: any) => {
      setSSMData((ssmData) => [data]);
    });

    return () => {
      socket.off("multipleSsmData");
    };
  }, []);

  return (
    <SsmDataContext.Provider
      value={{
        ssmData: ssmData,
      }}
    >
      {children}
    </SsmDataContext.Provider>
  );
}

export function useSsmData() {
  const context = useContext(SsmDataContext);
  if (context === undefined) {
    return {
      ssmData: [],
    };
    //throw new Error("useSsmData must be used within a SsmDataContext");
  }
  return context;
}
