import UpsService from "../../../../../../data/ups/UpsService";
import { MarkerForm } from "./MarkerForm";

export const UpsModel: MarkerForm = {
  type: "ups",
  addService: UpsService.addUps,
  updateService: UpsService.updateUps,
  items: [
    {
      label: "name",
      name: "name",
      type: "input",
      isRequired: true,
    },
    {
      label: "brand_model",
      name: "brand_model",
      type: "input",
      isRequired: true,
    },
    {
      label: "latitude",
      name: "latitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "longitude",
      name: "longitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "ip_address",
      name: "ip_address",
      type: "input",
      isRequired: true,
    },
    {
      label: "organization",
      name: "org_id",
      type: "dropdown",
      isRequired: true,
      dropdownItem: "orgList",
    },
    {
      label: "number_of_batteries",
      name: "number_of_batteries",
      type: "number",
      isRequired: true,
    },
    {
      label: "kwa",
      name: "kwa",
      type: "number",
      isRequired: true,
    },
  ],
};
