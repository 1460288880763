import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, InputNumber, message, Row, Select } from "antd";
import { ColumnsType } from "antd/lib/table";

import columnFieldKeys from "../../../formFieldStructs/planFormPhasesTableFields";
import planFormEpicFields from "../../../formFieldStructs/planFormEpicFields";
import { checkPhaseMinMaxSeq } from "./checkPhaseMinMaxSeq";
import { PhaseType } from "../../..";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

interface IGetPhasesTableColumns {
  planFormObject: any;
  updatePhasesForm: (...args: any[]) => any;
  getSelectedDetectors: (...args: any[]) => any;
  showDetectorModal: (...args: any[]) => any;
  getRowIdsFromIsInterruptingFields: (...args: any[]) => any;
  setWatchIsInterruptingColumn: React.Dispatch<React.SetStateAction<boolean>>;
  isRelatedColumnsVisibleWithIsInterrupting: boolean;
  juncPhases: PhaseType[];
}

interface IInterruptorIdColumn {
  rowData: any;
  checkedIsInterruptingRows: any;
  updatePhasesForm: (...args: any[]) => any;
}

interface ITrailerPhaseIdColumn {
  rowData: any;
  uncheckedIsInterruptingRows: any;
  updatePhasesForm: (...args: any[]) => any;
}

interface IScheduleColumn {
  rowData: any;
  updatePhasesForm: (...args: any[]) => any;
}

const InterruptorIdColumnRender: React.FC<IInterruptorIdColumn> = ({
  rowData,
  checkedIsInterruptingRows,
  updatePhasesForm,
}) => {
  const { t } = useTranslation();
  const columnShouldBeVisible: boolean =
    !rowData?.[columnFieldKeys.isInterrupting];

  const columnValue = rowData?.[columnFieldKeys.interruptorPhaseId];

  useEffect(() => {
    if (
      (!columnShouldBeVisible && columnValue !== null) ||
      (columnValue !== null && !checkedIsInterruptingRows.includes(columnValue))
    ) {
      updatePhasesForm(
        columnFieldKeys.interruptorPhaseId,
        rowData?.[columnFieldKeys.phase_id],
        null
      );
    }
  });

  return (
    <>
      {columnShouldBeVisible && (
        <Select
          placeholder={t("select")}
          style={{ width: "100%", overflow: "hidden" }}
          value={columnValue}
          onChange={(value) => {
            updatePhasesForm(
              columnFieldKeys.interruptorPhaseId,
              rowData?.[columnFieldKeys.phase_id],
              value
            );
          }}
        >
          <Select.Option disabled>{ t("select") }</Select.Option>
          {checkedIsInterruptingRows.map(
            (checkedIsInterruptingRow: number, index: number) => (
              <Select.Option
                value={checkedIsInterruptingRow}
                key={`interruptorPhaseIdSelection-${index}`}
              >
                {checkedIsInterruptingRow}
              </Select.Option>
            )
          )}
        </Select>
      )}
    </>
  );
};

const TrailerPhaseIdColumnRender: React.FC<ITrailerPhaseIdColumn> = ({
  rowData,
  uncheckedIsInterruptingRows,
  updatePhasesForm,
}) => {
  const { t } = useTranslation();
  const columnShouldBeVisible: boolean =
    rowData?.[columnFieldKeys.isInterrupting];

  const columnValue = rowData?.[columnFieldKeys.trailerPhaseId];

  useEffect(() => {
    if (
      (!columnShouldBeVisible && columnValue !== null) ||
      (columnValue !== null &&
        !uncheckedIsInterruptingRows.includes(columnValue))
    ) {
      updatePhasesForm(
        columnFieldKeys.trailerPhaseId,
        rowData?.[columnFieldKeys.phase_id],
        null
      );
    }
  });

  return (
    <>
      {columnShouldBeVisible && (
        <Select
          placeholder={t("select")}
          style={{ width: "100%", overflow: "hidden" }}
          value={columnValue}
          onChange={(value) => {
            updatePhasesForm(
              columnFieldKeys.trailerPhaseId,
              rowData?.[columnFieldKeys.phase_id],
              value
            );
          }}
        >
          <Select.Option disabled>{ t("select") }</Select.Option>
          {uncheckedIsInterruptingRows.map(
            (uncheckedIsInterruptingRow: number, index: number) => (
              <Select.Option
                value={uncheckedIsInterruptingRow}
                key={`trailerPhaseIdSelection-${index}`}
              >
                {uncheckedIsInterruptingRow}
              </Select.Option>
            )
          )}
        </Select>
      )}
    </>
  );
};

const ScheduleColumnRender: React.FC<IScheduleColumn> = ({
  rowData,
  updatePhasesForm,
}) => {
  const { t } = useTranslation();
  const columnShouldBeVisible: boolean =
    rowData?.[columnFieldKeys.isInterrupting];

  const columnValue = rowData?.[columnFieldKeys.schedule];

  useEffect(() => {
    if (!columnShouldBeVisible && columnValue !== null) {
      updatePhasesForm(
        columnFieldKeys.schedule,
        rowData?.[columnFieldKeys.phase_id],
        null
      );
    }
  });

  return (
    <>
      {columnShouldBeVisible && (
        <Select
          placeholder={t("select")}
          style={{ width: "100%", overflow: "hidden" }}
          value={columnValue}
          onChange={(value) => {
            updatePhasesForm(
              columnFieldKeys.schedule,
              rowData?.[columnFieldKeys.phase_id],
              value
            );
          }}
        >
          <Select.Option disabled>{ t("select") }</Select.Option>
          <Select.Option value="immediate">{ t("immediate") }</Select.Option>
          <Select.Option value="endPhase">{ t("end_phase") }</Select.Option>
          <Select.Option value="endCycle">{ t("end_cycle") }</Select.Option>
        </Select>
      )}
    </>
  );
};

const getPhasesTableColumns: (
  columnParameters: IGetPhasesTableColumns
) => ColumnsType<any> = ({
  planFormObject,
  updatePhasesForm,
  getSelectedDetectors,
  showDetectorModal,
  getRowIdsFromIsInterruptingFields,
  setWatchIsInterruptingColumn,
  isRelatedColumnsVisibleWithIsInterrupting,
  juncPhases,
}) => {
  const { phasesFormObject, ...planFormOutOfPhases } = planFormObject;

  const { checkedIsInterruptingRows, uncheckedIsInterruptingRows } =
    getRowIdsFromIsInterruptingFields();

  // alternative columns
  // Interruptor ID | Trailer Phase ID | Schedule
  const interruptorIdColumn = {
    title: "Interruptor ID",
    key: columnFieldKeys.interruptorPhaseId,
    render: (rowData: any) => {
      return (
        <InterruptorIdColumnRender
          rowData={rowData}
          checkedIsInterruptingRows={checkedIsInterruptingRows}
          updatePhasesForm={updatePhasesForm}
        />
      );
    },
  };

  const trailerPhaseIdColumn = {
    title: "Trailer Phase ID",
    key: columnFieldKeys.trailerPhaseId,
    render: (rowData: any) => {
      return (
        <TrailerPhaseIdColumnRender
          rowData={rowData}
          uncheckedIsInterruptingRows={uncheckedIsInterruptingRows}
          updatePhasesForm={updatePhasesForm}
        />
      );
    },
  };

  const scheduleColumn = {
    title: i18next.t("schedule_timing"),
    key: columnFieldKeys.schedule,
    render: (rowData: any) => {
      return (
        <ScheduleColumnRender
          rowData={rowData}
          updatePhasesForm={updatePhasesForm}
        />
      );
    },
    width: 160,
  };

  let restrictedPriorityField = planFormEpicFields.restrictedPriority;
  let trailingPriorityField = planFormEpicFields.trailingPriority;

  let restrictedPriority =
    planFormOutOfPhases[restrictedPriorityField.fieldName];
  let trailingPriority = planFormOutOfPhases[trailingPriorityField.fieldName];

  let computedAlternativeColumn = null;

  // multipleUpdatePlanFormEpics
  if (restrictedPriority) {
    // null -> schedule, trailerPhaseId
    computedAlternativeColumn = interruptorIdColumn;
  } else if (trailingPriority) {
    // null -> schedule, interruptorPhaseId
    computedAlternativeColumn = trailerPhaseIdColumn;
  } else {
    // null -> interruptorPhaseId, trailerPhaseId
    computedAlternativeColumn = scheduleColumn;
  }

  return [
    {
      title: i18next.t("phase_no"),
      dataIndex: columnFieldKeys.phase_id,
      key: columnFieldKeys.phase_id,
    },
    {
      title: i18next.t("phase_name"),
      dataIndex: columnFieldKeys.phase_name,
      key: columnFieldKeys.phase_name,
    },
    {
      title: i18next.t("minus"),
      key: columnFieldKeys.minus,
      render: (rowData: any) => (
        <InputNumber
          value={rowData?.[columnFieldKeys.minus]}
          style={{
            backgroundColor: checkPhaseMinMaxSeq(rowData, juncPhases)
              ? "inherit"
              : "#ffcccc",
          }}
          onChange={(value?) => {
            checkPhaseMinMaxSeq(
              {
                ...rowData,
                [columnFieldKeys.minus]: value,
              },
              juncPhases
            );
            updatePhasesForm(
              columnFieldKeys.minus,
              rowData?.[columnFieldKeys.phase_id],
              value !== null ? value : 0
            );
          }}
        ></InputNumber>
      ),
    },
    {
      title: i18next.t("mean_time"),
      key: columnFieldKeys.meanTime,
      render: (rowData: any) => (
        <InputNumber
          value={rowData?.[columnFieldKeys.meanTime]}
          style={{
            backgroundColor: checkPhaseMinMaxSeq(rowData, juncPhases)
              ? "inherit"
              : "#ffcccc",
          }}
          onChange={(value) => {
            checkPhaseMinMaxSeq(
              {
                ...rowData,
                [columnFieldKeys.meanTime]: value,
              },
              juncPhases
            );
            updatePhasesForm(
              columnFieldKeys.meanTime,
              rowData?.[columnFieldKeys.phase_id],
              value !== null ? value : 0
            );
          }}
        ></InputNumber>
      ),
    },
    {
      title: i18next.t("plus"),
      key: columnFieldKeys.plus,
      render: (rowData: any) => (
        <InputNumber
          value={rowData?.[columnFieldKeys.plus]}
          style={{
            backgroundColor: checkPhaseMinMaxSeq(rowData, juncPhases)
              ? "inherit"
              : "#ffcccc",
          }}
          onChange={(value) => {
            checkPhaseMinMaxSeq(
              {
                ...rowData,
                [columnFieldKeys.plus]: value,
              },
              juncPhases
            );
            updatePhasesForm(
              columnFieldKeys.plus,
              rowData?.[columnFieldKeys.phase_id],
              value !== null ? value : 0
            );
          }}
        ></InputNumber>
      ),
    },
    {
      title: i18next.t("extra_time"),
      key: columnFieldKeys.extraTime,
      render: (rowData: any) => (
        <InputNumber
          value={rowData?.[columnFieldKeys.extraTime]}
          style={{
            backgroundColor: checkPhaseMinMaxSeq(rowData, juncPhases)
              ? "inherit"
              : "#ffcccc",
          }}
          onChange={(value) => {
            checkPhaseMinMaxSeq(
              {
                ...rowData,
                [columnFieldKeys.extraTime]: value,
              },
              juncPhases
            );
            updatePhasesForm(
              columnFieldKeys.extraTime,
              rowData?.[columnFieldKeys.phase_id],
              value !== null ? value : 0
            );
          }}
        ></InputNumber>
      ),
    },
    {
      title: i18next.t("detector_no"),
      key: columnFieldKeys.detectorList,
      render: (rowData: any) => (
        <>
          <Row>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: ".8rem",
                  padding: 0,
                }}
              >
                {getSelectedDetectors(rowData).map(
                  (selectedDetector: any, selectedDetectorIndex: number) => {
                    return (
                      <span
                        key={`${selectedDetector}-${selectedDetectorIndex}`}
                      >
                        {selectedDetectorIndex ===
                        getSelectedDetectors(rowData).length - 1
                          ? `${selectedDetector}`
                          : `${selectedDetector}, `}
                      </span>
                    );
                  }
                )}
                {phasesFormObject.hasOwnProperty()}
              </span>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                shape="circle"
                size="middle"
                onClick={() => {
                  showDetectorModal(
                    rowData?.[columnFieldKeys.phase_id],
                    rowData
                  );
                }}
              >
                <PlusOutlined />
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: i18next.t("min_sensor_threshold"),
      key: columnFieldKeys.minSensorThreshold,
      render: (rowData: any) => (
        <InputNumber
          value={rowData?.[columnFieldKeys.minSensorThreshold]}
          onChange={(value) => {
            updatePhasesForm(
              columnFieldKeys.minSensorThreshold,
              rowData?.[columnFieldKeys.phase_id],
              value
            );
          }}
        ></InputNumber>
      ),
    },
    {
      title: i18next.t("is_interrupting"),
      key: columnFieldKeys.isInterrupting,
      render: (rowData: any) => (
        <Checkbox
          checked={rowData?.[columnFieldKeys.isInterrupting]}
          onChange={(e) => {
            // onChange, find the row ids which includes checked and unchecked isInterrupting fields
            getRowIdsFromIsInterruptingFields(
              rowData?.[columnFieldKeys.phase_id],
              e.target.checked
            );

            updatePhasesForm(
              columnFieldKeys.isInterrupting,
              rowData?.[columnFieldKeys.phase_id],
              e.target.checked
            );

            // if isInterrupting is false
            if (!e.target.checked) {
              // write null to schedule property
              updatePhasesForm(
                columnFieldKeys.schedule,
                rowData?.[columnFieldKeys.phase_id],
                null
              );

              // write null to isMultiOccurring property
              updatePhasesForm(
                columnFieldKeys.isMultiOccurring,
                rowData?.[columnFieldKeys.phase_id],
                null
              );
            }

            // watch changes on that column to check related colums availability
            setWatchIsInterruptingColumn((prev: boolean) => !prev);
          }}
        />
      ),
    },
    {
      title: i18next.t("is_fixphase"),
      key: columnFieldKeys.isFixPhase,
      render: (rowData: any) => (
        <Checkbox
          checked={rowData?.[columnFieldKeys.isFixPhase]}
          onChange={(e) => {


            updatePhasesForm(
              columnFieldKeys.isFixPhase,
              rowData?.[columnFieldKeys.phase_id],
              e.target.checked
            );
          }}
        />
      ),
    },
    // isRelatedColumnsVisibleWithIsInterrupting
    computedAlternativeColumn,
    ...(isRelatedColumnsVisibleWithIsInterrupting
      ? [
          {
            title: i18next.t("is_multi_occurring"),
            key: columnFieldKeys.isMultiOccurring,
            render: (rowData: any) => (
              <>
                {rowData?.[columnFieldKeys.isInterrupting] && (
                  <Checkbox
                    checked={rowData?.[columnFieldKeys.isMultiOccurring]}
                    onChange={(e) => {
                      updatePhasesForm(
                        columnFieldKeys.isMultiOccurring,
                        rowData?.[columnFieldKeys.phase_id],
                        e.target.checked
                      );
                    }}
                  />
                )}
              </>
            ),
          },
        ]
      : []),
  ];
};

export default getPhasesTableColumns;
