import { VmsInstantMessageSourceModel } from "../../pages/vms/VmsInstantMessageSource";
import http from "../http.common";
import { Vms } from "./Vms";

const getVmses = function () {
  return http.get<Vms[]>(`/web/vms/vms/list`);
};
const getVmsDrawingFiles = function (data:Vms | []) {
  return http.post<any>(`/web/vms/vms/vmsDrawingFiles`,data);
};

const getVmsDrawingFilesMultiMode = function (vms_group_id:any) {
  return http.post<any>(`/web/vms/vms/vmsDrawingFilesMultiMode`, {vms_group_id});
};

const getVmsDrawingFilesByPlans = function (vms_id:any) {
  return http.post<any>(`/web/vms/vms/vmsDrawingFilesByPlans`, {vms_id});
};
const getVmsDrawingFilesByPlansMultiMode = function (vms_group_id:any) {
  return http.post<any>(`/web/vms/vms/vmsDrawingFilesByPlansMultiMode`, {vms_group_id});
};

const addDrawing = function (vms_id:number | null,drawing_name:string, drawing_data:any, vms_group_id:any) {
  return http.post<any>(`/web/vms/vms/addDrawing`,{vms_id,drawing_name,drawing_data, vms_group_id});
};

const editDrawing = function (vms_id:number|null,drawing_name:string, drawing_update_name:string, drawing_data:any, vms_group_id:any) {
  return http.post<any>(`/web/vms/vms/editDrawing`,{vms_id,drawing_name, drawing_update_name, drawing_data, vms_group_id});
};

const deleteDrawing = function (vms_id:number|null,drawing_name:string, vms_group_id:any) {
  return http.post<any>(`/web/vms/vms/deleteDrawing`,{vms_id,drawing_name, vms_group_id});
};

const fileUpload = function (file: any, drawing_name:string) {
  return http.post<Vms>(
    `/web/fileserver/upload/vmsImages/${drawing_name}`,
    file, 
  );
};

const getVmsTimeTables = function (data:Vms) {
  return http.post<any>(`/web/vms/vms/vmsTimeTables`,data);
};

const getVmsTimeTablesMultiMode = function (vms_group_id:any) {
  return http.post<any>(`/web/vms/vms/vmsTimeTablesMultiMode`, {vms_group_id});
};
const addPlan = function (vms_id:number|null, vms_name:string|null, plan_data:any, vms_group_id:any) {
  return http.post<any>(`/web/vms/vms/addPlan`,{vms_id, vms_name,plan_data, vms_group_id});
};

const addWeeklyPlan = function (vms_id:number|null, weekly_plan_data:any, vms_group_id:any) {
  return http.post<any>(`/web/vms/vms/addWeeklyPlan`,{vms_id,weekly_plan_data, vms_group_id});
};

const addWeeklyPlanTemplate = function (vms_id:number|null, weekly_plan_template_data:any, vms_group_id:any) {
  return http.post<any>(`/web/vms/vms/addWeeklyPlanTemplate`,{vms_id,weekly_plan_template_data, vms_group_id});
};

//device communication with glass-api start
const prepareVmsDrawingFile = function (vms_id:number,drawing_data:any, device_ip:string) {
  return http.post<any>(`/web/vms/vmsDevice/prepareVmsDrawingFile`,{vms_id,drawing_data, device_ip});
};

const sendPlanToVms = function (vms_id:number,time_table:any,  device_ip:string) {
  return http.post<any>(`/web/vms/vmsDevice/sendPlanToVms`,{vms_id,time_table,device_ip});
};

const setInstantMessage = function (vms_id:number,drawingFileName:string, variableTextName:string, newText:string,textColor:string, duration:number,device_ip:string) {
  return http.post<any>(`/web/vms/vmsDevice/setInstantMessage`,{vms_id,drawingFileName, variableTextName, newText,textColor, duration, device_ip});
};
//device communication with glass-api end

const addVms = async function(vms:Vms){
  return http.post(`/web/vms/vms/addVms`, vms);
}

const addInstantMessageSourceVms = function (source:VmsInstantMessageSourceModel, messageColor:string){
  return http.post(`/web/vms/vms/addInstantMessageSourceVms`,{...source,messageColor})
}

const getInstantMessageSourceVms = function (id:number) {
  return http.post<any>(`/web/vms/vms/getInstantMessageSourceVms`,{id});
};

const updateInstantMessageSourceVms = function (source:any, id:number,messageColor:string) {
  return http.post<any>(`/web/vms/vms/updateInstantMessageSourceVms`,{...source,id,messageColor});
};

const deleteInstantMessageSourceVms = function (id:number) {
  return http.post<any>(`/web/vms/vms/deleteInstantMessageSourceVms`,{id});
};

const updateVms = async function(vms:Vms){
  return http.post(`/web/vms/vms/updateVms`, vms);
}

const deleteVms = async function(vmsId:number){
  return http.post(`/web/vms/vms/deleteVms`, {
    id:vmsId
  });
}


/* device settings */
const setBrightnessController = function (ip: string, configjson: any) {
  return http.put<any>(`/web/vms/vmsDevice/setBrightnessController`, {ip,configjson});
};

const getPixelBrightness = function (ip: string) {
  return http.get<any>(`/web/vms/vmsDevice/getPixelBrightness`, {
    params: { ip }
  });
};

const setPixelBrightness = function (ip: string, configjson: any) {
  return http.put<any>(`/web/vms/vmsDevice/setPixelBrightness`, {ip,configjson});
};

const getLedBrightness = function (ip: string) {
  return http.get<any>(`/web/vms/vmsDevice/getLedBrightness`, {
    params: { ip }
  });
};

const setLedBrightness = function (ip: string, configjson: any) {
  return http.put<any>(`/web/vms/vmsDevice/setLedBrightness`, {ip,configjson});
};

const getSensorLuminance = function (ip: string) {
  return http.get<any>(`/web/vms/vmsDevice/getSensorLuminance`, {
    params: { ip }
  });
};

const getSensorBrightness = function (ip: string) {
  return http.get<any>(`/web/vms/vmsDevice/getSensorBrightness`, {
    params: { ip }
  });
};

const setSensorBrightness = function (ip: string, configjson: any) {
  return http.put<any>(`/web/vms/vmsDevice/setSensorBrightness`, {ip,configjson});
};

const setInstantBrightness = function (ip: string, configjson: any) {
  return http.post<any>(`/web/vms/vmsDevice/setInstantBrightness`, {ip,configjson});
};

const getCurrentTransition = function (ip: string) {
  return http.get<any>(`/web/vms/vmsDevice/getCurrentTransition`, {
    params: { ip }
  });
};

const getTimeTransitions = function (ip: string) {
  return http.get<any>(`/web/vms/vmsDevice/getTimeTransitions`, {
    params: { ip }
  });
};

const getTimeOffset = function (ip: string) {
  return http.get<any>(`/web/vms/vmsDevice/getTimeOffset`, {
    params: { ip }
  });
};

const setTimeOffset = function (ip: string, configjson: any) {
  return http.put<any>(`/web/vms/vmsDevice/setTimeOffset`, {ip,configjson});
};

/* device settings */

/* vms multi mode */

const addVmsGroup = function (group_name:string, groups:number[], ignore_resolution:boolean, resolution_x:number, resolution_y:number) {
  return http.post<any>(`/web/vms/vms/addVmsGroup`, {group_name,groups, ignore_resolution, resolution_x,resolution_y});
};

const getVmsGroup = function () {
  return http.get<any>(`/web/vms/vms/getVmsGroup`);
};

const deleteVmsGroup = function (group_id:number) {
  return http.post<any>(`/web/vms/vms/deleteVmsGroup`, {group_id});
};

const addVmsPreDefinedGroups = function () {
  return http.post<any>(`/web/vms/vms/addVmsPreDefinedGroups`);
};

/* vms multi mode */


const VmsService = {
  getVmses,
  getVmsDrawingFiles,
  addDrawing,
  editDrawing,
  deleteDrawing,
  fileUpload,
  prepareVmsDrawingFile,
  getVmsTimeTables,
  addPlan,
  addWeeklyPlan,
  addWeeklyPlanTemplate,
  sendPlanToVms,
  setInstantMessage,
  addVms,
  addInstantMessageSourceVms,
  getInstantMessageSourceVms,
  updateInstantMessageSourceVms,
  deleteInstantMessageSourceVms,
  updateVms,
  deleteVms,
  setBrightnessController,
  getPixelBrightness,
  setPixelBrightness,
  getLedBrightness,
  setLedBrightness,
  getSensorLuminance,
  getSensorBrightness,
  setSensorBrightness,
  setInstantBrightness,
  getCurrentTransition,
  getTimeTransitions,
  getTimeOffset,
  setTimeOffset,
  addVmsGroup,
  getVmsGroup,
  deleteVmsGroup,
  getVmsDrawingFilesMultiMode,
  getVmsTimeTablesMultiMode,
  addVmsPreDefinedGroups,
  getVmsDrawingFilesByPlans,
  getVmsDrawingFilesByPlansMultiMode
};

export default VmsService;
