import { Card } from "antd";
import { t } from "i18next";
import { memo, RefObject, useEffect, useState } from "react";
import styled from "styled-components";

interface Props {
  containerRef: RefObject<HTMLElement>;
  designFileUrl: string;
}

const { REACT_APP_API_URL_DEV, REACT_APP_HOST_TYPE } =  process.env;
const baseURL =  REACT_APP_HOST_TYPE === "dev" ? REACT_APP_API_URL_DEV : window.location.origin;

function JunctionDesignPdf(props: Props) {
  const { containerRef, designFileUrl } = props;
  const [src,setSrc] = useState<string>("");


  useEffect(() => {
    displayFile();
  }, [designFileUrl])
  
  const displayFile = async () => {      
      if (designFileUrl) {        
        let blob = await fetch(baseURL+designFileUrl).then(r => r.blob());
        const file = new Blob([blob], { type: "application/pdf" });
        const fileUrl = URL.createObjectURL(file);
        setSrc(fileUrl);
      }
  };

  return <Card
    title={t("design_file")}>
    <Container>
      <iframe
        title="designFile"
        width="100%"
        height="500px"
        src={src}
      />
    </Container>
  </Card>
}

export default memo(JunctionDesignPdf);

const Container = styled.div`
width:100%;
`;