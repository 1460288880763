import http from "./../http.common";
import { License } from "./License";

const licenseModules = async () => {
    return http.get<License>('license')
}

const LicenseService = {
    licenseModules
};
export default LicenseService