import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Ups } from "../../data/ups/Ups";
import UpsService from "../../data/ups/UpsService";
import { useAuth } from "../auth/AuthContext";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";

export interface UpsDataContextState {
  upsList: Ups[];
  updateUpsList: () => void;
}

const UpsDataContext = createContext<UpsDataContextState | undefined>(
  undefined
);

export function UpsDataProvider({ children }: PropsWithChildren<{}>) {
  const { filteredOrganizations } = useAuth();
  const [allUpses, setAllUpses] = useState<Ups[]>([]);
  const [filteredUpses, setFilteredUpses] = useState<Ups[]>([]);
  const { hasAccessComponent,componentPermissions } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  const getUpses = async () => {
    await UpsService.getUpses()
      .then((res) => {
        setFilteredUpses(res.data);
        setAllUpses(res.data);
      })
      .catch((err) => console.log(err));
  };

  const updateUpsList = async () => {
    await UpsService.getUpses()
      .then((res) => {
        setFilteredUpses(
          res.data.filter((item) => filteredOrganizations.includes(item.org_id))
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUpses();
  }, [componentPermissions]);

  useEffect(() => {
    setFilteredUpses(
      allUpses.filter((item) => filteredOrganizations.includes(item.org_id))
    );
  }, [filteredOrganizations]);

  return hasAccessComponent("Ups Module-READ") ? (
    <UpsDataContext.Provider
      value={{
        upsList: filteredUpses,
        updateUpsList: updateUpsList,
      }}
    >
      {children}
    </UpsDataContext.Provider>
  ) : (
    <>{children}</>
  );
}

export function useUpsData() {
  const context = useContext(UpsDataContext);
  if (context === undefined) {
    return {
      upsList: [],
      updateUpsList: () => {},
    };
    //throw new Error("useUpsData must be used within a UpsDataContext");
  }
  return context;
}
