import React, { useEffect, useState } from "react";
import { Tabs, Form, Select, Table, Button, Modal, message } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import "antd/dist/antd.css";
import { useCustomLayerData } from "../../contexts/map/CustomLayerContext";
import { useTranslation } from "react-i18next";
import { CustomLayerData } from "../map/map-constants/LayerData";
import { ModulesAssigned, User } from "../../data/user/User";
import UserService from "../../data/user/UserService";

const { Option } = Select;
const { TabPane } = Tabs;

const SystemSettings: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const { layerList } = useCustomLayerData();
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<number>();
  const [selectedModule, setSelectedModule] = useState<number | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [tableData, setTableData] = useState<ModulesAssigned[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [moduleData, setModuleData] = useState([]);
  const [modalType, setModalType] = useState<"add" | "edit">();
  const [recordId, setRecordId] = useState<number>();

  useEffect(() => {
    getUsers();
    getModulesAssigned();
  }, []);

  const getModulesAssigned = async () => {
    const res = await UserService.getModulesAssigned();
    setTableData(res.data);
  };

  const getUsers = async () => {
    const res = await UserService.getAllUsers();
    setUsers(res.data);
  };

  const handleModuleChange = async (value: number) => {
    setLoading(true);
    const findedModuleService = CustomLayerData.find(
      (item) => item.id == value
    );
    try {
      if (findedModuleService && findedModuleService.getterFunc) {
        let res = await findedModuleService.getterFunc();
        setModuleData(res.data);
      } else {
        setModuleData([]);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
    setSelectedModule(value);
  };

  const handleAdd = () => {
    setModalType("add");
    setIsModalVisible(true);
  };

  const handleSave = async () => {
    if (modalType == "add") {
      let res = await UserService.addModulesAssigned({
        assigned_ids: selectedOptions,
        module_id: selectedModule!,
        user_id: selectedUser!,
        id: 1,
      });
      if (res.data) {
        message.success(t("assigned_suc_user"), 3);
        getModulesAssigned();
      } else {
        message.error(t("assigned_err_user"), 3);
      }
    } else {
      let res = await UserService.updateModulesAssigned({
        assigned_ids: selectedOptions,
        module_id: selectedModule!,
        user_id: selectedUser!,
        id: recordId!,
      });
      if (res.data) {
        message.success(t("assignedup_suc_user"), 3);
        getModulesAssigned();
      } else {
        message.error(t("assignedup_err_user"), 3);
      }
    }
    setIsModalVisible(false);
    resetForm();
  };

  const handleEdit = (record: ModulesAssigned) => {
    setModalType("edit");
    handleModuleChange(record.module_id);
    setSelectedUser(record.user_id);
    setSelectedOptions(record.assigned_ids);
    setSelectedModule(record.module_id);
    setRecordId(record.id);
    setIsModalVisible(true);
  };

  const handleDelete = async (id: number) => {
    let res = await UserService.deleteModulesAssigned(id);
    if (res.data) {
      message.success(t("assignedde_suc_user"), 3);
      setTableData(tableData.filter((item) => item.id !== id));
    } else {
      message.error(t("assignedde_err_user"), 3);
    }
  };

  const handleUserChange = (userId: number) => {
    setSelectedUser(userId);
  };

  const resetForm = () => {
    setSelectedUser(undefined);
    setSelectedModule(null);
    setSelectedOptions([]);
    setModalType(undefined);
    setRecordId(undefined);
  };

  const columns: ColumnsType<ModulesAssigned> = [
    {
      title: t("module"),
      dataIndex: "module_id",
      key: "module_id",
      render: (_, record: ModulesAssigned) => {
        return t(
          `${layerList.find((item) => item.id == record.module_id)?.name}`
        );
      },
    },
    {
      title: t("assigned_ids"),
      dataIndex: "assigned_ids",
      key: "assigned_ids",
      render: (_, record: ModulesAssigned) => {
        return record.assigned_ids.join(", ");
      },
    },
    {
      title: t("user_name"),
      dataIndex: "user_id",
      key: "user_id",
      render: (_, record: ModulesAssigned) => {
        return users.find((item) => item.id_user == record.user_id)?.user_name;
      },
    },
    {
      title: (
        <Button
          size="small"
          color="red"
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAdd}
        >
          {t("add")}
        </Button>
      ),
      dataIndex: "define_user",
      render: (_, record: ModulesAssigned) => {
        return (
          <>
            <Button
              size="small"
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            />
            <Button
              size="small"
              danger
              type="primary"
              shape="circle"
              style={{ margin: 5 }}
              icon={<DeleteOutlined />}
              onClick={() => {
                handleDelete(record.id);
              }}
            />
          </>
        );
      },
      align: "center",
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t("user_definitions")} key="1">
          <Table
            columns={columns}
            dataSource={tableData}
            style={{ marginTop: 24 }}
          />
          <Modal
            title={selectedModule ? t("up_assigned") : t("add_assigned")}
            visible={isModalVisible}
            onCancel={() => {
              resetForm();
              setIsModalVisible(false);
            }}
            onOk={handleSave}
          >
            <Form layout="vertical">
              <Form.Item label={t("user")} required>
                <Select
                  value={selectedUser}
                  onChange={handleUserChange}
                  placeholder={t("select_user")}
                >
                  {users.map((user) => (
                    <Option key={user.id_user} value={user.id_user}>
                      {user.user_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label={t("modules")} required>
                <Select
                  value={selectedModule}
                  onChange={handleModuleChange}
                  placeholder={t("select_module")}
                >
                  {layerList.map((layer) => (
                    <Option key={layer.id} value={layer.id}>
                      {t(layer.name)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {moduleData?.length > 0 && (
                <Form.Item label={t("datas")} required>
                  <Select
                    mode="multiple"
                    onChange={setSelectedOptions}
                    value={selectedOptions}
                    placeholder={t("select_datas")}
                  >
                    {moduleData.map((data: any) => (
                      <Option
                        key={data.id}
                        value={
                          typeof data.id == "string"
                            ? data.id
                            : data.id.toString()
                        }
                      >
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Form>
          </Modal>
        </TabPane>

        {/* <TabPane tab="Sistem Ayarları" key="2">
          <p>Bu sekme ileride sistem ayarlarını içerebilir.</p>
        </TabPane> */}
      </Tabs>
    </div>
  );
};

export default SystemSettings;
