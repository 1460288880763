import {useState, useEffect } from 'react';
import React from 'react';
const TimeLines = ({config,timeTrigger}) => {

      const [dateArray, setDateArray] = useState([]);
      useEffect(() => {    
          setDateArray(generateDay(timeTrigger));
      }, [timeTrigger]);


      function formatToTwoDigits(num) {
        return num < 10 ? '0' + num : num.toString();
    }

      const generateDay=(timeTrigger) => {
        let time = new Date(timeTrigger.getTime());
        //var time=new Date(Date.parse('01 Jan 1970 22:00:00 GMT'))
        var arr=[]
        for (let index = 0; index <= config?.TimeRangeSecond; index+=10) {
            const dString = time.getHours()+':'+formatToTwoDigits(time.getMinutes())+':'+formatToTwoDigits(time.getSeconds())
            arr.unshift(dString)     
            time.setSeconds(time.getSeconds() - 10); 
        }
        return arr
      }
    
      return (
        <>     
             {dateArray.map((date,index,row) => (
                <React.Fragment key={index+"tm"}>
                    <text
                        key={index+"b"}
                        x={(index*100)} 
                        y={config.ResolutionY}
                        fontFamily="Roboto,Arial, Helvetica, sans-serif"
                        fill="#666"
                        fontWeight="800"
                        fontSize="12">
                            {date}
                    </text>
                    {false&&config.ShowGrid&&index + 1 != row.length&&Array.from({ length: 10 }, (_, indexa) => (//minik tick boydan boya
                            <line key={'lnlqq'+indexa} strokeWidth="1px" stroke={"#eee"}   
                              x1={index*100+25+(indexa*10)} 
                              y1= "24"
                              x2={index*100+25+(indexa*10)} 
                              y2={config.ResolutionY-25} 
                              id={index+"lnl2"}>
                          </line> 
                    ))  }

                    {index + 1 != row.length&&Array.from({ length: 10 }, (_, indexa) => (//minik tickler 
                            <line key={index+'lnle'+indexa} strokeWidth="1px" stroke={"#666"}   
                              x1={index*100+25+(indexa*10)} 
                              y1={config.ResolutionY-24} //24
                              x2={index*100+25+(indexa*10)} 
                              y2={config.ResolutionY-20} 
                              id={index+"lnl2"}>
                          </line> 
                    ))  }


                    <line key={'lno5'+index} strokeWidth="2px" stroke={"#666"} //10 sn buyuk tick  
                        x1={index*100+25} 
                        y1={config.ResolutionY-24} 
                        x2={index*100+25} 
                        y2={config.ResolutionY-15} 
                        id={index}>
                    </line>  

 
                    {index + 1 != row.length&&<line key={'lno6'+index} strokeWidth="2px" stroke={"#666"}  //5 saniye orta tick
                        x1={index*100+25+50} 
                        y1={config.ResolutionY-24} 
                        x2={index*100+25+50} 
                        y2={config.ResolutionY-19} 
                        id={index}>
                    </line> 
                    }             
                 </React.Fragment> 
            ))}

          
    </> 
      );
  };
  
  export default TimeLines;
  