import { useEffect, useState } from "react";
import StyledButton from "../../components/custom-styled/button/StyledButton";
import { FilterOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { Checkbox, Dropdown, Menu } from "antd";
import { Junction, JunctionKKCType } from "../../../../data/junction/Junction";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import { MapModule } from "@icms/ui-components";

function KKCFilterControl() {
  const { layerDataList } = useCustomLayerData();
  const initialCheckedValues = Object.values(JunctionKKCType)
    .map((v) => Number(v))
    .filter((item) => !isNaN(item));
  const [checkedValues, setCheckedValues] = useState<number[]>(
    localStorage.getItem("filterkkc")
      ? JSON.parse(localStorage.getItem("filterkkc")!)
      : initialCheckedValues
  );
  const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    const value = e.target.value as number;
    setCheckedValues((prev) => {
      if (e.target.checked) {
        return [...prev, value];
      } else {
        return prev.filter((v) => v !== value);
      }
    });
  };

  const onChangeVisibleDropdown = (visible: boolean) => {
    setVisibleDropdown(visible);
  };

  const getTypeCounts = () => {
    let counts = new Map<number, number>();

    layerDataList.forEach((layer) => {
      layer.data?.forEach((item: Junction) => {
        const kkcTypeId = item.kkc_type_id ?? -1;
        if (!counts.has(kkcTypeId)) {
          counts.set(kkcTypeId, 0);
        }
        counts.set(kkcTypeId, counts.get(kkcTypeId)! + 1);
      });
    });

    return counts;
  };

  const typeCounts = getTypeCounts();

  const menu = (
    <Menu
      style={{
        background: "#1F2933",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      {Object.entries(JunctionKKCType)
        .filter(([key, value]) => isNaN(Number(key)))
        .map(([key, value]) => {
          const count = typeCounts.get(Number(value)) || 0;
          return (
            <Menu.Item key={value} className="menu-item-hover">
              <Checkbox
                value={value}
                checked={checkedValues.includes(Number(value))}
                onChange={handleCheckboxChange}
                style={{
                    color: "#E0E6ED",
                    fontSize: "14px",
                    transition: "background-color 0.3s ease",
                  }}
              >
                {key} ({count})
              </Checkbox>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  useEffect(() => {
    if (layerDataList.length > 0) {
      let junctionData = layerDataList.find(
        (item) => item.id == MapModule.JunctionModule
      );
      if (junctionData) {
        junctionData.legend?.setOnMapData((prev: Junction[]) => {
          const filteredData = junctionData?.data?.filter((item: Junction) => {
            const kkcTypeId = item.kkc_type_id ?? -1; //unknown kkc type
            return checkedValues.includes(kkcTypeId);
          });
          return filteredData;
        });
        localStorage.setItem("filterkkc", JSON.stringify(checkedValues));
      }
    }
  }, [checkedValues, layerDataList]);

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      onVisibleChange={onChangeVisibleDropdown}
      visible={visibleDropdown}
      placement="bottomRight"
      align={{ offset: [181, -27] }}
    >
      <StyledButton
        icon={<FilterOutlined />}
        backgroundColor={"#2F4050"}
        borderColor={"#2F4050"}
        style={{
          color: "white",
          marginLeft: 0,
          paddingLeft: 7,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 8,
        }}
      >
        <p
          style={{
            marginBottom: 0,
            color: "#fff",
            fontSize: "13px",
          }}
        >
          {t("junction") + " (KKC) " + t("filter")}
        </p>
      </StyledButton>
    </Dropdown>
  );
}

export default KKCFilterControl;
