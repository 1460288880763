import { EyeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { t } from 'i18next';
import React, { useState } from 'react';

interface StreamPlayerProps {
  streamUrl: string;
  width?: string;
  height?: string;
}

const VmsStream: React.FC<StreamPlayerProps> = ({
  streamUrl,
  width,
  height
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const openStreamUrlInNewTab = () => {
    window.open(streamUrl, "_blank");
  };

  const infoContent = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button type="link" onClick={openStreamUrlInNewTab}>
        {t("watch_vms_stream")}
      </Button>
    </div>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", overflow:"hidden" }}>
      <iframe
        src={streamUrl}
        title="Stream Player"
        allowFullScreen
        style={{ width, height, borderRadius: "10px", overflow:"hidden", border:"none" }}
        scrolling='no'
      ></iframe>
      <Tooltip visible={showInfo} title={infoContent} placement="bottom">
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => setShowInfo(!showInfo)}
          style={{ marginTop: '8px' }}
        />
      </Tooltip>
    </div>
  );
};

export default VmsStream;
