import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Card, DatePicker, Button, Row, Col, Modal, message, Spin } from "antd";
import moment, { Moment } from "moment";
import { Junction } from "../../../../../data/junction/Junction";
import JunctionService from "../../../../../data/junction/JunctionService";
import { t } from "i18next";

interface Props {
  selectedJunctions: Junction[];
}

interface PlanCardData {
  selectedRange: { start: Moment | null; end: Moment | null };
}

const PoliceButtonMonitoringJakarta: React.FC<Props> = ({
  selectedJunctions,
}) => {
  const [junctionIds, setJunctionIds] = useState<string | undefined>();
  const [vipPassPlansData, setVipPassPlansData] = useState<any>();
  const [selectedPlanGroups, setSelectedPlanGroups] = useState<
    Record<string, string | undefined>
  >({});
  const [selectedPlanCardData, setSelectedPlanCardData] = useState<
    Record<string, PlanCardData>
  >({});
  const [datePickerErrors, setDatePickerErrors] = useState<
    Record<string, string | null>
  >({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setJunctionIds(selectedJunctions.map((item) => item.id).join(","));
  }, [selectedJunctions]);

  const fetchVipPassPlansData = useCallback(() => {
    if (junctionIds) {
      JunctionService.getVipPassPlansData(junctionIds)
        .then((response) => {
          setVipPassPlansData(response.data);
        })
        .catch((err) => console.error(err));
    }
  }, [junctionIds]);

  useEffect(() => {
    fetchVipPassPlansData();
  }, [fetchVipPassPlansData]);

  const disabledDate = (current: Moment) => {
    return !current.isSame(moment(), "day");
  };

  const handleDateRangeChange = (key: string, dates: any) => {
    setSelectedPlanCardData((prevData) => ({
      ...prevData,
      [key]: {
        selectedRange: { start: dates[0], end: dates[1] },
      },
    }));

    setDatePickerErrors((prevErrors) => ({
      ...prevErrors,
      [key]: null,
    }));
  };

  const handleCancel = (key: string) => {
    setSelectedPlanCardData((prevData) => {
      const newRanges = { ...prevData };
      delete newRanges[key];

      setVipPassPlansData((prevData: any) => {
        return prevData.map((plan: any) => {
          const planKey = `${plan.junction_name}-${plan.vip_pass_plan_id}`;
          if (planKey === key) {
            return { ...plan, cardTitle: plan.plan_details.name };
          }
          return plan;
        });
      });

      return newRanges;
    });
  };

  const handleCardClick = (key: string, group: string) => {
    setSelectedPlanGroups((prevGroups) => {
      return { ...prevGroups, [group]: key };
    });

    setSelectedPlanCardData((prevData) => {
      return {
        ...prevData,
        [key]: prevData[key] || { selectedRange: { start: null, end: null } },
      };
    });
  };

  const showConfirmDialog = () => {
    Modal.confirm({
      title: "Confirmation",
      content:
        t("do_you_confirm?"),
      onOk: handleComplete,
      onCancel: () => console.log("Rejected."),
    });
  };

  const handleComplete = () => {
    setLoading(true);
    const selectedPlans: any[] = [];
    const errors: Record<string, string> = {};

    selectedJunctions.forEach((junction) => {
      const junctionName = junction.name;
      const selectedPlanKey = selectedPlanGroups[junctionName];
      const selectedPlan = vipPassPlansData.find((plan: any) => {
        const planId = plan.vip_pass_plan_id;
        const planKey = `${junctionName}-${planId}`;
        return planKey === selectedPlanKey;
      });

      if (selectedPlan) {
        const planId = selectedPlan.vip_pass_plan_id;
        const planKey = `${junctionName}-${planId}`;
        const selectedRange = selectedPlanCardData[planKey]?.selectedRange || {
          start: null,
          end: null,
        };

        if (!selectedRange.start || !selectedRange.end) {
          errors[planKey] =
            t("police_mode_error_info");
          return;
        }

        const startDayId = moment(selectedRange.start).isoWeekday();
        const endDayId = moment(selectedRange.end).isoWeekday();

        selectedPlans.push({
          junction_id: Number(junction.id),
          plan_id: Number(planId),
          startTime:selectedRange.start.unix()*1000,// selectedRange.start?.format("YYYY-MM-DD HH:mm") || null,
          endTime:selectedRange.end.unix()*1000, //selectedRange.end?.format("YYYY-MM-DD HH:mm") || null,
          startDayId,
          endDayId,
        });
      }
    });

    if (Object.keys(errors).length > 0) {
      setDatePickerErrors(errors);
      setLoading(false);
      return;
    }
    JunctionService.setVipPassPlansData(selectedPlans)
    .then((response) => {
      response.data
        ? message.success(t("police_mode_activated"))
        : message.error(t("police_mode_error"));
    })
    .catch((err) => console.error(err))
    .finally(() => {
      setLoading(false);
    });
    
  };

  const handleClearSelection = (group: string) => {
    setSelectedPlanGroups((prevGroups) => {
      const updatedGroups = { ...prevGroups };
      delete updatedGroups[group];
      return updatedGroups;
    });

    setSelectedPlanCardData((prevData) => {
      const updatedData = { ...prevData };
      Object.keys(updatedData).forEach((key) => {
        if (key.startsWith(`${group}-`)) {
          delete updatedData[key];
        }
      });
      return updatedData;
    });
  };

  const handleSetWholeDay = (key: string) => {
    setSelectedPlanCardData((prevData) => ({
      ...prevData,
      [key]: {
        selectedRange: {
          start: moment().startOf("day"),
          end: moment().endOf("day"),
        },
      },
    }));
  };

  const handleAdd15Minutes = (key: string) => {
    setSelectedPlanCardData((prevData) => ({
      ...prevData,
      [key]: {
        selectedRange: {
          start: moment(),
          end: moment().add(15, "minutes"),
        },
      },
    }));
  };

  const handleAdd30Minutes = (key: string) => {
    setSelectedPlanCardData((prevData) => ({
      ...prevData,
      [key]: {
        selectedRange: {
          start: moment(),
          end: moment().add(30, "minutes"),
        },
      },
    }));
  };

  const handleAdd1Hour = (key: string) => {
    setSelectedPlanCardData((prevData) => ({
      ...prevData,
      [key]: {
        selectedRange: {
          start: moment(),
          end: moment().add(60, "minutes"),
        },
      },
    }));
  };

  return (
    <>
      <Spin spinning={loading}>
        <Collapse activeKey={selectedJunctions.map((junction) => junction.name)}>
          {vipPassPlansData &&
            selectedJunctions.map((junction: Junction) => {
              const junctionName = junction.name;
              const junctionPlans = vipPassPlansData.filter(
                (plan: any) => plan.junction_name === junctionName
              );
  
              if (junctionPlans.length === 0) {
                return null;
              }
  
              return (
                <Collapse.Panel
                  key={junctionName}
                  header={`${junctionName} ${t("vip_pass_plans")}`}
                >
                  <Row gutter={[16, 16]}>
                    {junctionPlans.map((plan: any) => {
                      const planId = plan.vip_pass_plan_id;
                      const planKey = `${junctionName}-${planId}`;
                      const isSelected =
                        selectedPlanGroups[junctionName] === planKey;
                      const planData = selectedPlanCardData[planKey] || {
                        selectedRange: { start: null, end: null },
                      };
  
                      return (
                        <Col key={planId} xs={24} sm={12} md={12} lg={8}>
                          <Card
                            title={
                              <div>
                                {isSelected && (
                                  <img
                                    src="/static/police-alarm.gif"
                                    alt="Police Mode GIF"
                                    style={{
                                      marginRight: 10,
                                      width: 40,
                                      height: 40,
                                    }}
                                  />
                                )}
                                {plan.cardTitle || plan.plan_details.name}
                              </div>
                            }
                            hoverable
                            onClick={() =>
                              handleCardClick(planKey, junctionName)
                            }
                            style={{
                              background: isSelected ? "#e6f7ff" : "#fff",
                              marginBottom: "8px",
                            }}
                          >
                            <div
                              style={{
                                margin: "10px",
                                padding: "10px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                              }}
                            >
                              <div>
                                <strong>Min TS:</strong> {plan.plan_details.minTS}
                              </div>
                              <div>
                                <strong>Max TS:</strong> {plan.plan_details.maxTS}
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                <strong>Internal Ids to be Affected</strong>
                              </div>
                              {plan.plan_details.phases.map((phase: any) => (
                                <div
                                  key={phase.internalId}
                                  style={{ marginLeft: "10px", marginTop: "5px" }}
                                >
                                  <strong>Internal ID:</strong> {phase.internalId}
                                </div>
                              ))}
                            </div>
  
                            {isSelected && (
                              <>
                                <div style={{ marginBottom: "8px" }}>
                                  <Button onClick={() => handleSetWholeDay(planKey)}>
                                    {t("all_day")}
                                  </Button>
                                  <Button onClick={() => handleAdd15Minutes(planKey)}>
                                    {t("15_minutes")}
                                  </Button>
                                  <Button onClick={() => handleAdd30Minutes(planKey)}>
                                    {t("30_Minutes")}
                                  </Button>
                                  <Button onClick={() => handleAdd1Hour(planKey)}>
                                    {t("1 hour")}
                                  </Button>
                                </div>
                                <DatePicker.RangePicker
                                  showTime
                                  format="YYYY-MM-DD HH:mm"
                                  value={[
                                    planData.selectedRange.start,
                                    planData.selectedRange.end,
                                  ]}
                                  onChange={(dates) =>
                                    handleDateRangeChange(planKey, dates)
                                  }
                                  disabledDate={disabledDate}
                                />
                                {datePickerErrors[planKey] && (
                                  <div style={{ color: "red", marginTop: "8px" }}>
                                    {datePickerErrors[planKey]}
                                  </div>
                                )}
                                <Button onClick={() => handleCancel(planKey)}>
                                  Cancel
                                </Button>
                              </>
                            )}
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                  <Button onClick={() => handleClearSelection(junctionName)}>
                    {t("clear_selection")}
                  </Button>
                </Collapse.Panel>
              );
            })}
        </Collapse>
        <Button
          type="primary"
          onClick={showConfirmDialog}
          style={{ marginTop: "16px" }}
        >
          {t("send_to_junctions")}
        </Button>
      </Spin>
    </>
  );
  
};

export default PoliceButtonMonitoringJakarta;