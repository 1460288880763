import React, { useEffect, useState } from "react";
import "./style.css";
import { Button, Form, Input, message, Select, Spin, Tooltip } from "antd";
import type { FormInstance } from "antd/es/form";
import { fcdCluster } from "../services/FcdDataConfigCluster";
import { fcdJunction } from "../services/FcdDataConfigJunction";
import { fcdEdit } from "../services/FcdDataConfigEdit";
import { AxiosResponse } from "axios";
import { Cluster } from "../services/FcdDataConfigEdit";
import { useTranslation } from "react-i18next";
import { getCorridors } from "../../junction/Statistic/services/ArchiveServices";

const { Option } = Select;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { offset: 0.5, span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

export type JunctionOption = {
  centrisId: number;
  fisheyeId: number;
  phaseList: any[];
  junctionId: string;
  junctionName: string;
  junctionPriority: number;
  signalPlans: any[];
  directionList: any[];
  dischargeList: any[];
};
type Corridor = {
  queueSpeed: number;
  queueCoefficient: number;
  queueThreshold: number;
  queuePercentage: number;
  decisionQueueThreshold: number;
  corridorId?: string;
};
type DirectionOption = {
  directionId: number;
  incomingCorridor: Corridor[] | Corridor;
  outgoingCorridor: any[];
};
type DirectionData = {
  directionId: number;
  incomingCorridor: Corridor[] | Corridor;
  outgoingCorridor: any[];
};

const FCDDataForm: React.FC = () => {
  const formRef = React.useRef<FormInstance>(null);
  const [clusterOptions, setClusterOptions] = useState<any>([]);
  const [junctionOptions, setJunctionOptions] = useState<JunctionOption[]>([]);
  const [directionOptions, setDirectionOptions] = useState<DirectionOption[]>(
    []
  );
  const [directionData, setDirectionData] = useState<DirectionData[]>([]);
  const [junctionid, setJunctionId] = useState();
  const [clusterid, setClusterId] = useState<number>(0);
  const [directionid, setDirectionid] = useState<any>("");
  const [corridorid, setCorridorid] = useState<any>("");
  const [clusterName, setClusterName] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [corridorValue, setCorridor] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    fcdCluster().then((res: AxiosResponse) => {
      setClusterOptions(res.data.clusterList);
    });
  }, []);
  const handleClusterChange = (value: any) => {
    setJunctionOptions([]);
    const nameOfCluster = clusterOptions.find((item: any) => item.id === value);
    setClusterName(nameOfCluster.name);
    setClusterId(value);
    fcdJunction(value)
      .then((res: AxiosResponse) => {
        setJunctionOptions(res.data.junctionList);
      })
      .catch((res: AxiosResponse) => {
        if (res.data === undefined || res.data.junctionList.length === 0) {
          message.error(
            t("junction_list_for_the_selected_cluster_could_not_be_found")
          );
          setJunctionOptions([]);
        }
      });
  };
  const handleJunctionChange = async (value: any) => {
    setJunctionId(value);
    setDirectionOptions([]);
    setDirectionData([]);
    setCorridorid("");
    setDirectionid("");
    const directionData = junctionOptions.find((item) => {
      return item.junctionId === value;
    });
    if (directionData) {
      setDirectionOptions(directionData.directionList);
    }   
    let corridors = await getCorridors(value);
    let juncCorridor = {
      corridors: corridors.data.map((item:any) => {
        return {
          ...item,
          coordiord_id: parseInt(item.corridor_id)
        }
      }),
      junction_name: directionData?.junctionName,
      junction_no: parseInt(value)
    }
    setCorridor(juncCorridor);
  };
  //her directionId değiştiğinde directionOptionsları sıfırlamak için;
  const handleDirectionChange = (value: any) => {
    setCorridorid(value);
    setDirectionid("");
    setDirectionData([]);
  };
  const onFinish = (values: any) => {
    //search butonuna bastığında bağlı direction geliyor.
    const direction = directionOptions.find((item) => {
      setDirectionid(item.directionId);
      if (Array.isArray(item.incomingCorridor)) {
        return item.incomingCorridor.some(
          (corridor) => corridor.corridorId === values.directionId
        );
      } else {
        return item.incomingCorridor?.corridorId === values.directionId;
      }
    });
    if (direction) {
      setDirectionData([direction]);
    }
  };
  useEffect(() => {
    setJunctionOptions([]);
    setDirectionOptions([]);
    setDirectionData([]);
  }, [clusterOptions, clusterid]);
  useEffect(() => {
    handleJunctionChange(junctionid);
  }, [junctionid]);

  const onReset = () => {
    formRef.current?.resetFields();
    setDirectionData([]);
    setDirectionOptions([]);
    setJunctionOptions([]);
  };
  const submitCluster = (values: any) => {
    setIsSaving(true);
    const updatedIncomingCorridors = Array.isArray(
      directionData[0].incomingCorridor
    )
      ? directionData[0].incomingCorridor.map((corridor) =>
          corridor.corridorId === corridorid
            ? { ...corridor, ...values.incomingCorridor }
            : corridor
        )
      : { ...directionData[0].incomingCorridor, ...values.incomingCorridor };

    const cluster: Cluster = {
      clusterId: clusterid,
      clusterName: clusterName,
      junctionList: junctionOptions.map((junctionOption) => {
        if (junctionOption.junctionId === junctionid) {
          return {
            ...junctionOption,
            directionList: junctionOption.directionList.map(
              (directionOption) => {
                if (directionOption.directionId === directionid) {
                  return {
                    ...directionOption,
                    incomingCorridor: updatedIncomingCorridors,
                  };
                }
                return directionOption;
              }
            ),
          };
        }
        return junctionOption;
      }),
    };

    fcdEdit(cluster)
      .then((res: AxiosResponse) => {
        message.success(t("cluster_has_been_successfully_updated"));
        onReset();
      })
      .catch((err) => {
        message.error(t("updates_could_not_be_saved_please_try_again"));
      })
      .finally(() => {
        setTimeout(() => {
          onReset();
          setIsSaving(false);
        }, 1500);
      });
  };

  return (
    <div style={{ position: "relative" }}>
      <h3>FCD {t("configuration")}</h3>
      {isSaving && (
        <Spin
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 10000,
          }}
          size="large"
        />
      )}
      <Form {...layout} ref={formRef} name="control-ref" onFinish={onFinish}>
        <Form.Item
          name="clusterId"
          label={t("cluster_id")}
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t("select_a_option")}
            allowClear
            onChange={handleClusterChange}
          >
            {clusterOptions?.map((item: any) => (
              <Option key={item.id} value={item.id}>
                {item.id} - {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.clusterId !== currentValues.clusterId
          }
        >
          {junctionOptions.length > 0 ? (
            <Form.Item
              name="junctionId"
              label={t("junction_id")}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={t("select_a_option")}
                allowClear
                onChange={handleJunctionChange}
              >
                {junctionOptions.map((item: any) => (
                  <Option key={item.junctionId} value={item.junctionId}>
                    {item.junctionId} - {item.junctionName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.junctionId !== currentValues.junctionId
          }
        >
          {directionOptions.length > 0 && junctionOptions.length > 0 ? (
            <Form.Item
              name="directionId"
              label={t("direction_id")}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={t("select_a_option")}
                allowClear
                onChange={handleDirectionChange}
              >
                {directionOptions.map((item: any) => {
                  const corridors = Array.isArray(item.incomingCorridor)
                    ? item.incomingCorridor
                    : [item.incomingCorridor];

                  return corridors.map((corridor: any) => {
                    const corridorS = corridorValue?.corridors.find(
                      (c: any) => c.corridor_id == corridor.corridorId
                    );
                    const corridorName = corridorS
                      ? corridorS.corridor_name
                      : "-";

                    return (
                      <Option
                        key={item.directionId + corridor.corridorId}
                        value={corridor.corridorId}
                      >
                        {item.directionId} ({t("corridor")} ID:{" "}
                        {corridor.corridorId}, {corridorName})
                      </Option>
                    );
                  });
                })}
              </Select>
            </Form.Item>
          ) : null}
        </Form.Item>
        <Form.Item className="buttonsGroup" {...tailLayout}>
          <Button type="primary" style={{ marginRight: 5 }} htmlType="submit">
            {t("search")}
          </Button>
          <Button htmlType="button" onClick={onReset}>
            {t("clear")}
          </Button>
        </Form.Item>
      </Form>
      {directionData.length >= 1 ? (
        <Form
          {...layout}
          name="nest-messages"
          onFinish={submitCluster}
          style={{ marginTop: "3rem" }}
          initialValues={{
            incomingCorridor: {
              queueSpeed: Array.isArray(directionData[0].incomingCorridor)
                ? directionData[0].incomingCorridor[0]?.queueSpeed
                : directionData[0].incomingCorridor?.queueSpeed,
              queueCoefficient: Array.isArray(directionData[0].incomingCorridor)
                ? directionData[0].incomingCorridor[0]?.queueCoefficient
                : directionData[0].incomingCorridor?.queueCoefficient,
              queueThreshold: Array.isArray(directionData[0].incomingCorridor)
                ? directionData[0].incomingCorridor[0]?.queueThreshold
                : directionData[0].incomingCorridor?.queueThreshold,
              queuePercentage: Array.isArray(directionData[0].incomingCorridor)
                ? directionData[0].incomingCorridor[0]?.queuePercentage
                : directionData[0].incomingCorridor?.queuePercentage,
              decisionQueueThreshold: Array.isArray(
                directionData[0].incomingCorridor
              )
                ? directionData[0].incomingCorridor[0]?.decisionQueueThreshold
                : directionData[0].incomingCorridor?.decisionQueueThreshold,
            },
          }}
        >
          <>
            <Tooltip
              overlay={
                <div>
                  {t(
                    "the_threshold_speed_required_for_calculating_tail_length"
                  )}
                </div>
              }
            >
              <Form.Item
                name={["incomingCorridor", "queueSpeed"]}
                label={t("queueSpeed")}
                normalize={(value: string) =>
                  value ? parseFloat(value) : undefined
                }
              >
                <Input />
              </Form.Item>
            </Tooltip>

            <Tooltip
              overlay={
                <div>
                  {t(
                    "the_coefficient_required_to_prioritize_the_tail_length_on_intersection_arms"
                  )}
                </div>
              }
            >
              <Form.Item
                name={["incomingCorridor", "queueCoefficient"]}
                label={t("queueCoefficient")}
                normalize={(value) => (value ? parseFloat(value) : undefined)}
              >
                <Input type="number" step={1} pattern="\d+" />
              </Form.Item>
            </Tooltip>

            <Tooltip
              overlay={
                <div>
                  {t(
                    "the_threshold_value_for_the_tail_length_indicating_congestion_on_intersection_arms_in_management_algorithms"
                  )}
                </div>
              }
            >
              <Form.Item
                name={["incomingCorridor", "queueThreshold"]}
                label={t("queueThreshold")}
                normalize={(value: string) =>
                  value ? parseFloat(value) : undefined
                }
              >
                <Input />
              </Form.Item>
            </Tooltip>

            <Tooltip
              overlay={
                <div>
                  {t("which_is_determined_as_a_percentage_of_the_total_length")}
                </div>
              }
            >
              <Form.Item
                name={["incomingCorridor", "queuePercentage"]}
                label={t("queuePercentage")}
                normalize={(value: string) =>
                  value ? parseFloat(value) : undefined
                }
              >
                <Input />
              </Form.Item>
            </Tooltip>
            <Tooltip
              overlay={
                <div>
                  {t(
                    "the_threshold_value_that_determines_whether_intersections_will_operate_in_coordinated__or_dynamic_mode"
                  )}
                </div>
              }
            >
              <Form.Item
                name={["incomingCorridor", "decisionQueueThreshold"]}
                label={t("decisionQueueThreshold")}
                normalize={(value: string) =>
                  value ? parseFloat(value) : undefined
                }
              >
                <Input />
              </Form.Item>
            </Tooltip>
          </>

          <Form.Item className="buttonsGroup" {...tailLayout}>
            <Button type="primary" style={{ marginRight: 5 }} htmlType="submit">
              {t("edit")}
            </Button>
            <Button htmlType="button" onClick={onReset}>
              {t("cancel")}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        ""
      )}
    </div>
  );
};

export default FCDDataForm;
