import React, { useEffect, useState } from "react";
import { useVmsData } from "../../../../contexts/vms/VmsDataContext";
import { Button, Card, Input, Select, message, Row, Col, Popconfirm, Switch, Divider } from "antd";
import VmsService from "../../../../data/vms/VmsService";
import { PlusOutlined, CloseOutlined, DeleteOutlined, TableOutlined } from '@ant-design/icons';

const { Option } = Select;

const VmsGroup = () => {
  const { vmsList } = useVmsData();
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [vmsGroups, setVmsGroups] = useState([]);
  const [ignoreResolution, setIgnoreResolution] = useState(false);
  const [resolutionX, setResolutionX] = useState(800);
  const [resolutionY, setResolutionY] = useState(400);

  const handleSave = async () => {
    await VmsService.addVmsGroup(groupName, selectedGroups, ignoreResolution, resolutionX, resolutionY)
      .then((response) => {
        response.data.success === true ? message.success(response.data.message) : message.error(response.data.message);
        getVmsGroups();
      })
      .catch((err) => console.log(err));
  };

  const insertVmsPreDefinedGroups = async () => {
    await VmsService.addVmsPreDefinedGroups()
      .then((response) => {
    
      })
      .catch((err) => console.log(err));
  };

  const getVmsGroups = async () => {
    await VmsService.getVmsGroup()
      .then((response) => {
        setVmsGroups(response.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const pageLoadingOperation = async () => {
      await insertVmsPreDefinedGroups();
      await getVmsGroups();
    };
  
    pageLoadingOperation();
  }, []);

  const renderVmsNames = (groupIds: any) => {
    return groupIds.map((groupId: any) => {
      const vms = vmsList.find(vms => vms.id === groupId);
      return vms ? (
        <li key={vms.id} style={{ display: 'flex', alignItems: 'center', padding: '8px 0' }}>
          <TableOutlined style={{ marginRight: 8, color: '#2980b9' }} />
          <span>{vms.name}</span>
        </li>
      ) : null;
    });
  };

  const handleDeleteGroup = async (groupId: number) => {
    await VmsService.deleteVmsGroup(groupId)
      .then((response) => {
        response.data ? message.success("Grup silindi") : message.error("Silme başarısız");
        getVmsGroups();
      })
      .catch((err) => console.log(err));
  };

  const handleToggleChange = (checked: any) => {
    setIgnoreResolution(checked);
  };

  // Check if all required fields are filled
  const isFormValid = () => {
    return groupName && selectedGroups.length > 0 && resolutionX && resolutionY;
  };

  return (
    <div style={{ padding: 20 }}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setIsCardVisible(true)}
        style={{
          marginBottom: 20,
          backgroundColor: '#001529',
          borderColor: '#001529',
          color: '#ffffff',
          borderRadius: 4,
        }}
      >
        Grup Oluştur
      </Button>
      {isCardVisible && (
        <Card
          title="Grup Oluştur"
          extra={
            <>
              <Switch
                checked={ignoreResolution}
                onChange={handleToggleChange}
                style={{ marginRight: 16 }}
              />
              <span>Çözünürlüklerin Aynı Olma Zorunluluğunu Yok Say</span>
              <Button type="text" onClick={() => setIsCardVisible(false)} icon={<CloseOutlined />} />
            </>
          }
          style={{ marginBottom: 16, borderRadius: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
        >
          <Input
            placeholder="Grup Adı"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            style={{ marginBottom: 16, borderRadius: 4, padding: '10px' }}
          />
          <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
            <Input
              placeholder="Yatay Çözünürlük (x)"
              value={resolutionX}
              onChange={(e:any) => setResolutionX(Number(e.target.value))}
              style={{ borderRadius: 4, padding: '10px' }}
            />
            <Input
              placeholder="Dikey Çözünürlük (y)"
              value={resolutionY}
              onChange={(e:any) => setResolutionY(Number(e.target.value))}
              style={{ borderRadius: 4, padding: '10px' }}
            />
          </div>
          <Select
            mode="multiple"
            placeholder="VMS Seçin"
            onChange={setSelectedGroups}
            style={{ width: '100%', marginBottom: 16, borderRadius: 4 }}
          >
            {vmsList.map(vms => (
              <Option key={vms.id} value={vms.id}>
                {vms.name} {vms.resolution_x} x {vms.resolution_y}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={handleSave}
            style={{
              marginTop: "10px",
              backgroundColor: '#001529',
              borderColor: '#001529',
              color: '#ffffff',
              borderRadius: 4,
            }}
            disabled={!isFormValid()} // Disable button if form is not valid
          >
            Kaydet
          </Button>
        </Card>
      )}
      <Divider orientation="left" style={{ borderBottomColor: '#001529' }}>Gruplar</Divider>
      <Row gutter={[16, 16]}>
        {vmsGroups.map((group: any) => (
          <Col key={group.id} span={8}>
            <Card
              title={<strong>{group.vms_group_name}</strong>}
              style={{ marginBottom: 16, borderRadius: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
              actions={[
                <Popconfirm
                  title="Vms grubunu silmek istediğinizden emin misiniz?"
                  onConfirm={() => handleDeleteGroup(group.id)}
                  okText="Evet"
                  cancelText="Hayır"
                >
                  <DeleteOutlined style={{ color: '#e74c3c' }} />
                </Popconfirm>
              ]}
            >
              <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
                {renderVmsNames(group.groups)}
              </ul>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default VmsGroup;
