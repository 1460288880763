import styled, { CSSProperties } from "styled-components";


export const CardTitleText = styled.div`

text-align:center;

`;


export const manageCardStyle: CSSProperties = {

  width: "60%", borderRadius: "16px",borderColor:"#87decf",  height: "100%", position: "absolute", top: 0, bottom: 0, zIndex: 100

}

export const InfoFontStyle= styled.div`
.ant-form-item-control-input-content{
  font-family: "Raleway", sans-serif;
  color:white
  
}

.ant-form-item-label>label{
  color:white

}
.ant-form-item{
  margin-bottom:0px
}
`;

export const Row = styled.div`

display:flex;

justify-content:space-between;


margin-top:10px;

`;