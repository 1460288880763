import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Table,
  Button,
  Input,
  Select,
  TimePicker,
  Row,
  Col,
  Tooltip,
  message,
  Popconfirm,
} from "antd";
import "antd/dist/antd.min.css";
import moment from "moment";
import { CopyOutlined, DeleteOutlined, LeftOutlined, RightOutlined, SendOutlined } from "@ant-design/icons";
import { Vms } from "../../data/vms/Vms";
import VmsService from "../../data/vms/VmsService";
import { t } from "i18next";

const { Option } = Select;

interface Plan {
  id: number;
  name: string;
  phases?: { drawingFileName: string; duration: number }[];
}

interface Day {
  id: number;
  plans: {
    id: number;
    startTime: string;
  }[];
}

interface VmsPlanProps {
  selectedVms: Vms;
  sendedDrawingsFromEditor?: any | null;
}

const VmsPlans: React.FC<VmsPlanProps> = ({ selectedVms, sendedDrawingsFromEditor }) => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [weekSchedule, setWeekSchedule] = useState<Day[]>([
    { id: 1, plans: [] },
    { id: 2, plans: [] },
    { id: 3, plans: [] },
    { id: 4, plans: [] },
    { id: 5, plans: [] },
    { id: 6, plans: [] },
    { id: 7, plans: [] },
  ]);
  const [drawings, setDrawings] = useState<string[]>([]);
  const [timeTables, setTimeTables] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState("plans");


  const getVmsDrawingFiles = useCallback(async () => {
    await VmsService.getVmsDrawingFiles(selectedVms)
      .then((response) => {
        if(sendedDrawingsFromEditor){
          setDrawings(sendedDrawingsFromEditor ? sendedDrawingsFromEditor : [])
        }else{setDrawings(Object.keys(response.data.drawing_data ? response.data.drawing_data : []))}   
      })
      .catch((err) => console.log(err));
  }, [selectedVms]);

  const getVmsTimeTables = useCallback(async () => {
    await VmsService.getVmsTimeTables(selectedVms)
      .then((response) => {
        if(response.data.plan_data === undefined || response.data.plan_data === null){
          setTimeTables(null)
      }else{
      setTimeTables(response.data.plan_data.timeTable);
      }
      })
      .catch((err) => console.log(err));
  }, [selectedVms]);

  const insertPlan = async (selectedVms: Vms, planData: any) => {
    await VmsService.addPlan(selectedVms.id, selectedVms.name, planData, null)
      .then((response) => {getVmsTimeTables();
      })
      .catch((err) => console.log(err));
  };
  const insertWeeklyPlan = async (selectedVms: Vms, weeklyPlanData: any) => {
    await VmsService.addWeeklyPlan(selectedVms.id, weeklyPlanData, null)
      .then((response) => {})
      .catch((err) => console.log(err));
  };
  const insertWeeklyPlanTemplate = async (
    selectedVms: Vms,
    weeklyPlanTemplateData: any
  ) => {
    await VmsService.addWeeklyPlanTemplate(
      selectedVms.id,
      weeklyPlanTemplateData, null
    )
      .then((response) => {})
      .catch((err) => console.log(err));
  };

  const handleAddPlan = () => {
    const generateUniqueId = () => {
        let uniqueId:number;
        do {
            uniqueId = Date.now() % 10000;
        } while (plans.some(plan => plan.id === uniqueId));
        return uniqueId;
    };

    const newPlan: Plan = {
        id: generateUniqueId(),
        name: getNewPlanName(),
    };
    setPlans([...plans, newPlan]);
};


  const getNewPlanName = () => {
    let counter = 1;
    let newPlanName = ` ${t("plan")}-${counter}`;

    while (plans.some((plan) => plan.name === newPlanName)) {
      newPlanName = ` ${t("plan")}-${counter}`;
      counter++;
    }

    return newPlanName;
  };

  const handleRemovePlan = (id: number) => {
    const updatedPlans = plans.filter((plan) => plan.id !== id);
    setPlans(updatedPlans);
  };

  const handleNewRow = (planId: number) => {
    const updatedPlans = plans.map((plan) => {
      if (plan.id === planId) {
        return {
          ...plan,
          phases: [
            ...(plan.phases || []),
            { drawingFileName: "", duration: 5 },
          ],
        };
      }
      return plan;
    });
    setPlans(updatedPlans);
  };

  const handleCellChange = (planId: number, rowIndex: number, key: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedPlans = plans.map((plan) => {
        if (plan.id === planId) {
          const updatedTableData = [...(plan.phases || [])];
          updatedTableData[rowIndex] = {
            ...updatedTableData[rowIndex],
            [key]:
              key === "duration"
                ? parseInt(event.target.value, 10)
                : event.target.value,
          };
          return { ...plan, phases: updatedTableData };
        }
        return plan;
      });
      setPlans(updatedPlans);
    };
  };

  const handleRemoveRow = (planId: number, rowIndex: number) => {
    const updatedPlans = plans.map((plan) => {
      if (plan.id === planId) {
        const updatedTableData = [...(plan.phases || [])];
        updatedTableData.splice(rowIndex, 1);
        return { ...plan, phases: updatedTableData };
      }
      return plan;
    });
    setPlans(updatedPlans);
  };

  const isPlanValid = (plan: Plan) => {
    if (!plan.phases || plan.phases.length === 0) {
      return false;
    }

    return plan.phases.every(
      (phase) => phase.drawingFileName && phase.duration
    );
  };

  const handleAddPlanAssignment = (dayIndex: number) => {
    const updatedSchedule = [...weekSchedule];
    updatedSchedule[dayIndex].plans.push({
      id: 0,
      startTime: "",
    });
    setWeekSchedule(updatedSchedule);
  };

  const handlePlanSelection = (
    dayIndex: number,
    assignmentIndex: number,
    planId: number
  ) => {
    const updatedSchedule = [...weekSchedule];
    updatedSchedule[dayIndex].plans[assignmentIndex].id = planId;
    setWeekSchedule(updatedSchedule);
  };

  const handleStartTimeChange = (dayIndex: number, assignmentIndex: number) => {
    return (time: any) => {
      const updatedSchedule = [...weekSchedule];
      updatedSchedule[dayIndex].plans[assignmentIndex].startTime =
        time.format("HH:mm");
      setWeekSchedule(updatedSchedule);
    };
  };

  const handleRemovePlanAssignment = (
    dayIndex: number,
    assignmentIndex: number
  ) => {
    const updatedSchedule = [...weekSchedule];
    updatedSchedule[dayIndex].plans.splice(assignmentIndex, 1);
    setWeekSchedule(updatedSchedule);
  };

  const handlePlanNameChange = (planId: number) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const newName = event.target.value;

      if (plans.some((plan) => plan.id !== planId && plan.name === newName)) {
        message.error("Bu isimde başka bir plan zaten var!");
        return;
      }

      const updatedPlans = plans.map((plan) => {
        if (plan.id === planId) {
          return { ...plan, name: newName };
        }
        return plan;
      });
      setPlans(updatedPlans);
    };
  };
  const handleSaveAllPlans = async () => {
    const invalidPlans = plans.filter((plan) => !isPlanValid(plan));
    if (invalidPlans.length > 0) {
      const invalidPlanNames = invalidPlans.map((plan) => plan.name).join(", ");
      message.error(
        `${t("these_plans_contain_missing_or_incorrect_information")}: ${invalidPlanNames}`
      );
      return;
    }
    await insertPlan(selectedVms, plans);

    message.success(t("plans_successfully_saved"));
    setCurrentPage("weekly_plan");
  };

  const handleCompletePlan = async () => {
    await handleSaveAllPlans();
    try {
      const defaultWeeklyPlanTemplate: any = [
        {
          id: 1,
          dailies: [
            {
              id: 1,
              startDay: 1,
            },
            {
              id: 2,
              startDay: 2,
            },
            {
              id: 3,
              startDay: 3,
            },
            {
              id: 4,
              startDay: 4,
            },
            {
              id: 5,
              startDay: 5,
            },
            {
              id: 6,
              startDay: 6,
            },
            {
              id: 7,
              startDay: 7,
            },
          ],
        },
      ];
      await insertWeeklyPlan(selectedVms, weekSchedule);
      await insertWeeklyPlanTemplate(selectedVms, defaultWeeklyPlanTemplate);
      getVmsTimeTables();
      message.success(t("the_weekly_plan_has_been_saved"));
      setCurrentPage("send_plan");
    } catch {
      message.success(t("a_problem_occurred_while_registering_the_weekly_plan"));
    }
  };

  const handleCopyPlan = (fromDayIndex: number) => {
    const fromDay = weekSchedule[fromDayIndex];

    weekSchedule.forEach((toDay, toDayIndex) => {
      if (toDayIndex !== fromDayIndex) {
        toDay.plans = fromDay.plans.map((assignment) => ({
          ...assignment,
        }));
      }
    });

    setWeekSchedule([...weekSchedule]);
  };

  const sendPlanToVms = async () => {
    try {
      const response = await VmsService.sendPlanToVms(
        selectedVms.id,
        timeTables,
        selectedVms.ip_address
      );

      if (response.data.status === 200) {
        message.success(t("the_plan_is_loaded_on_the_device"));
      }
      if (response.data === ""){
        message.error(t("an_error_occurred_while_installing_the_plan_on_the_device"))
      }
    } catch (error) {
      message.error(t("an_error_occurred_while_installing_the_plan_on_the_device"));
    }
  };

  const renderDayCard = (day: Day, dayIndex: number) => {
    const dayNames = [
      t("monday"),
      t("tuesday"),
      t("wednesday"),
      t("thursday"),
      t("friday"),
      t("saturday"),
      t("sunday"),
    ];

    return (
      <Col
        key={dayIndex}
        span={24 / weekSchedule.length}
        style={{ marginBottom: "20px" }}
      >
        <Card
          type="inner"
          hoverable
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span style={{ fontWeight: "bold" }}>{dayNames[dayIndex]}</span>
              {dayIndex === 0 && (
                <Tooltip
                  placement="top"
                  title={t("assign_same_plan_to_all_other_days")}
                >
                  <Button
                    onClick={() => handleCopyPlan(dayIndex)}
                    icon={<CopyOutlined />}
                    style={{ marginLeft: "10px" }}
                  />
                </Tooltip>
              )}
            </div>
          }
        >
          {day.plans.map((assignment, assignmentIndex) => (
            <div
              key={assignmentIndex}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Select
                style={{ flex: 1, marginRight: "10px", width:"300px" }}
                placeholder={t("choose_plan")}
                onChange={(planId: number) =>
                  handlePlanSelection(dayIndex, assignmentIndex, planId)
                }
                value={assignment.id || undefined}
              >
                {plans.map((plan) => (
                  <Option key={plan.id} value={plan.id}>
                    {plan.name}
                  </Option>
                ))}
              </Select>
              <TimePicker
                style={{ flex: 1, marginRight: "10px" }}
                onChange={handleStartTimeChange(dayIndex, assignmentIndex)}
                value={
                  assignment.startTime
                    ? moment(assignment.startTime, "HH:mm")
                    : moment("00:00", "HH:mm")
                }
                format="HH:mm"
                placeholder={t("start_time")}
              />
              <Button
                onClick={() =>
                  handleRemovePlanAssignment(dayIndex, assignmentIndex)
                }
                danger
                ghost
                style={{ marginRight: "5px", marginBottom: "5px" }}
                icon={<DeleteOutlined/>}
              />
              
            </div>
          ))}
          <Button
            type="primary"
            onClick={() => handleAddPlanAssignment(dayIndex)}
            style={{ marginBottom: "10px" }}
            size="small"
          >
            {t("assign_plan")}
          </Button>
        </Card>
      </Col>
    );
  };

  useEffect(() => {
    getVmsDrawingFiles();
  }, [getVmsDrawingFiles]);

  useEffect(() => {
    getVmsTimeTables();
  }, [getVmsTimeTables]);

  useEffect(() => {
    if (timeTables) {
      setPlans(timeTables.vmsPlan.plans);
      if (timeTables.vmsPlan && timeTables.vmsPlan.dailyPlans !== undefined) {
        setWeekSchedule(timeTables.vmsPlan.dailyPlans);
      }
    }else{
      setPlans([]);
      setWeekSchedule([
          { id: 1, plans: [] },
          { id: 2, plans: [] },
          { id: 3, plans: [] },
          { id: 4, plans: [] },
          { id: 5, plans: [] },
          { id: 6, plans: [] },
          { id: 7, plans: [] },
      ])
  }
  }, [timeTables]);

  const goBack = () => {
    setCurrentPage("plans");
  };

  const goForwardForSendPlan = () => {
    setCurrentPage("send_plan");
  };

  

  return (
      <div>
        {/* Planlar Start */}
        {currentPage === "plans" &&(<Card title={<b> {t("plans")}</b>} hoverable>
          <Button
            type="primary"
            onClick={handleAddPlan}
            style={{ marginBottom: "20px" }}
          >
            {t("add_new_plan")}
          </Button>
          <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
            {plans.map((plan) => (
              <Col key={plan.id} span={12}>
                <Card
                  type="inner"
                  hoverable
                  style={{
                    background: "#ffffff",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div className="plan-table-container">
                    <Input
                      type="text"
                      placeholder="Plan Adı"
                      value={plan.name}
                      onChange={handlePlanNameChange(plan.id)}
                      style={{ marginBottom: "10px" }}
                    />
                    <Table
                      columns={[
                        {
                          title: t("drawing_file"),
                          dataIndex: "drawingFileName",
                          key: "drawingFileName",
                          render: (_, record, rowIndex) => (
                            <Select
                              style={{ width: "100%" }}
                              placeholder= {t("select_drawing_file")}
                              value={record.drawingFileName || undefined}
                              onChange={(value: string) =>
                                handleCellChange(
                                  plan.id,
                                  rowIndex,
                                  "drawingFileName"
                                )({
                                  target: { value },
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                            >
                              {drawings.map((file, index) => (
                                <Option key={index} value={file}>
                                  {file}
                                </Option>
                              ))}
                            </Select>
                          ),
                        },
                        {
                          title: t("duration"),
                          dataIndex: "duration",
                          key: "duration",
                          render: (_, record, rowIndex) => (
                            <Input
                              type="number"
                              value={record.duration}
                              onChange={handleCellChange(
                                plan.id,
                                rowIndex,
                                "duration"
                              )}
                            />
                          ),
                        },
                        {
                          title: "",
                          dataIndex: "action",
                          key: "action",
                          render: (_, __, rowIndex) => (
                            <Button
                              onClick={() => handleRemoveRow(plan.id, rowIndex)}
                              danger
                              ghost
                              style={{
                                marginRight: "5px",
                                marginBottom: "5px",
                              }}
                              icon={<DeleteOutlined/>}
                              type="link"
                            />
                          ),
                        },
                      ]}
                      dataSource={plan.phases || []}
                      pagination={false}
                      className="plan-table"
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <div>
                      <Button
                        type="primary"
                        onClick={() => handleNewRow(plan.id)}
                        size="small"
                      >
                        {t("add_new_line")}
                      </Button>
                    </div>
                    <Button
                      onClick={() => handleRemovePlan(plan.id)}
                      className="remove-plan-button"
                      danger
                      ghost
                      size="small"
                    >
                      {t("remove_plan")}
                    </Button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Card>)}
        {/* Planlar End */}

        {/* Haftalık Plan Start */}
        {currentPage === "plans" &&(<Card
          title={<b> {t("weekly_plan")}</b>}
          hoverable
        >
          <Row
            gutter={[16, 16]}
            style={{
              overflowX: "auto",
              justifyContent: "center",
            }}
          >
            {weekSchedule.map((day, index) => renderDayCard(day, index))}
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Popconfirm
              title={t("do_you_confirm?")}
              onConfirm={handleCompletePlan}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Button type="primary" size="large">{t("save")}</Button>
            </Popconfirm>
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Tooltip title={t("go_forward")}>
                <Button size="large" shape="circle" icon={<RightOutlined />} style={{ marginRight: "10px" }} onClick={goForwardForSendPlan} />            
              </Tooltip>
            </div>
        </Card>)}
        {/* Haftalık Plan End */}
        {currentPage === "send_plan" && (
          <Card>
            <div style={{ display: "flex", justifyContent:"center", alignItems: "center" }}>
              <Popconfirm
                title={t("do_you_confirm?")}
                onConfirm={sendPlanToVms}
                okText={t("yes")}
                cancelText={t("no")}
              >
                <Card style={{
                  background: 'linear-gradient(to right, #bdc3c7, #2c3e50)',
                  borderRadius: '10px',
                  padding: '20px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  textAlign: 'center',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '18px',
                  color: '#ffffff',
                  height: '150px'
                }}>
                  <SendOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                  {t("send_plan_to_vms")}
                </Card>
              </Popconfirm>
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Tooltip title={t("go_back")}>
              <Button size="large" shape="circle" icon={<LeftOutlined />} style={{ marginRight: "10px" }} onClick={goBack} />
              </Tooltip>
            </div>
          </Card>
        )}
      </div>
  );
};

export default VmsPlans;
