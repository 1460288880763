import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Map from "../pages/map/Map";
import Profile from "../pages/profile/Profile";
import ReportForm from "../pages/profile/ReportForm";
import PrivateRoute from "./PrivateRoute";
import ErrorMonitor from "../pages/error-monitor";
import FCDDataConfig from "../pages/fcd-data-config";
import PerformancePage from "../pages/performance/components";
import AppLayout from "../containers/AppLayout";
import Home from "../pages/home";
import {
  Login,
  PermissionContext,
  PermissionDataProps,
} from "@icms/ui-components";
import MultiModeVmsMain from "../pages/vms/vms-multi-mode";
import InventoryStockManagement from "../pages/inventory-stock-management";
import SystemSettings from "../pages/system-settings";
import { CustomLayerDataProvider } from "../contexts/map/CustomLayerContext";
import { LicenseDataProvider } from "../contexts/license/LicenseDataContext";
import HistoricalIncident from "../pages/incident";

function RouteManager() {
  const { hasAccessComponent } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route element={<AppLayout />}>
          <Route path="/*" element={<Home />} />
        </Route>
        <Route path="/map" element={<Map componentName="Map" />} />
        <Route element={<AppLayout />}>
          <Route path="/errormonitor" element={<ErrorMonitor />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route path="/fcdConfiguration" element={<FCDDataConfig />} />
        </Route>
        {hasAccessComponent("Junction Module-READ") && (
          <Route element={<AppLayout />}>
            <Route path="/performance" element={<PerformancePage />} />
          </Route>
        )}
        <Route element={<AppLayout />}>
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route path="/report" element={<ReportForm />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route
            path="/inventory-stock-management"
            element={<InventoryStockManagement />}
          />
        </Route>
        <Route element={<AppLayout />}>
          <Route path="/vms-multi-mode" element={<MultiModeVmsMain />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route
            path="/system-settings"
            element={
              <LicenseDataProvider>
                <CustomLayerDataProvider>
                  <SystemSettings />
                </CustomLayerDataProvider>
              </LicenseDataProvider>
            }
          />
        </Route>
        <Route element={<AppLayout />}>
          <Route
            path="/historical-incident"
            element={
              <LicenseDataProvider>
                <HistoricalIncident />
              </LicenseDataProvider>
            }
          />
        </Route>
      </Route>
      <Route path="/login" element={<Login rootDirectory={"mango"} />} />
    </Routes>
  );
}

export default RouteManager;
