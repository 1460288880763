import http from "./../http.common";
import { ModulesAssigned, User } from "./User";

const getAll = () => {
  return http.get<Array<User>>(`/user`);
};

const getAllUsers = () => {
  return http.get<User[]>(`/dev/user/getallusers`);
};

const updateUser = async (req: any) => {
  return http.put<User>(`web/user/updateuser`, req);
};
const updateDefaultModules = async (req: any) => {
  return http.put<User>(`web/user/setDefaultModules`, req);
};
const getModules = async (id: number) => {
  return http.get<number[]>("web/user/getDefaultModules/" + id);
};

const reportService = async (form: any) => {
  return http.post("/issues/create", form);
};

const addAttachments = async (data: any, key: string) => {
  return http.post(`/issues/${key}/attachments`, data);
};

const getModulesAssigned = async () => {
  return http.get<ModulesAssigned[]>(`web/user/modulesassigned`);
};

const getModulesAssignedToUser = async () => {
  return http.get<ModulesAssigned[]>(`web/user/modulesassignedtouser`);
};

const addModulesAssigned = async (obj: ModulesAssigned) => {
  return http.post<boolean>(`web/user/addmodulesassigned`, obj);
};

const updateModulesAssigned = async (obj: ModulesAssigned) => {
  return http.post<boolean>(`web/user/updatemodulesassigned`, obj);
};

const deleteModulesAssigned = async (id: number) => {
  return http.post<boolean>(`web/user/deletemodulesassigned`, {
    id: id,
  });
};

const updateNotificationTime = async (time:number, user_id: number) => {
  return http.post<boolean>(`web/user/updatenotification`, {
    user_id: user_id,
    time: time
  });
};

const UserService = {
  getAll,
  updateUser,
  updateDefaultModules,
  getModules,
  reportService,
  addAttachments,
  getModulesAssigned,
  addModulesAssigned,
  updateModulesAssigned,
  deleteModulesAssigned,
  getAllUsers,
  getModulesAssignedToUser,
  updateNotificationTime,
};
export default UserService;
