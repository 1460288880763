import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Steps, Button, message } from "antd";

import PhaseSelection from "./components/PhaseSelection";
import PhasesFormTable from "./components/PhasesFormTable";

import planFormPhasesTableFields from "./formFieldStructs/planFormPhasesTableFields";
import planFormEpicFields from "./formFieldStructs/planFormEpicFields";

// context
import { MultiModePlanContext } from "../../context/MultiModePlanContext";

import generateIdForNewPlan from "../../common/generateIdForNewPlan";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

const {
  phase_id: phaseIdFieldKey,
  phase_name: phaseNameFieldKey,
  minSequenceTimeout: minSequenceTimeoutFieldKey,
  maxSequenceTimeout: maxSequenceTimeoutFieldKey,
  signalOutput: signalOutputFieldKey,
  interruptorPhaseId: interruptorPhaseIdFieldKey,
  trailerPhaseId: trailerPhaseIdFieldKey,
  schedule: scheduleFieldKey,
} = planFormPhasesTableFields;

const { Step } = Steps;

export interface PhaseType {
  key: string;
  internal_id: number;
  name: string;
  max_sequence_timeout?: number;
  min_sequence_timeout?: number;
  groups?: number[];
  output_number?: number;
}

interface Props {
  selectedJunction: any;
  predefinedPhasesFormObject?: any | null;
  flashPlanStateSet?: {
    state: boolean;
    cleanState: () => void;
  } | null;
  planActionModeSet?: {
    state: "edit" | "copy" | null;
    cleanState: () => void;
  } | null;
  closePlanCreationOrEdit: any;
}

const steps = [
  {
    title: "phase_selection",
    content: "First-content",
  },
  {
    title: "plan_settings",
    content: "Second-content",
  },
];

const CreateNewPlan: React.FC<Props> = ({
  predefinedPhasesFormObject,
  flashPlanStateSet,
  planActionModeSet,
  closePlanCreationOrEdit,
}) => {
  const { t } = useTranslation();
  const planContextValues = useContext(MultiModePlanContext);
  const [contextSignalPlan, setContextSignalPlan] =
    planContextValues?.signalPlan;

  const phases: PhaseType[] = planContextValues?.juncPhases;

  const [planFormObject, setPlanFormObject] = useState<any>({
    phasesFormObject: [],
  });

  const [selectedPhases, setSelectedPhases] = useState<string[]>([]);

  const [checkedPhasesBeforeSelected, setCheckedPhasesBeforeSelected] =
    useState<string[]>([]);

  const [currentStep, setCurrentStep] = useState<number>(
    predefinedPhasesFormObject || flashPlanStateSet?.state ? 1 : 0
  );

  const formSubmitRef = useRef<any>();

  const getPhasesFormObject = () => {
    // if edit or copy situation available
    if (
      planFormObject.phasesFormObject.length === 0 &&
      predefinedPhasesFormObject &&
      predefinedPhasesFormObject.phases
    ) {
      return predefinedPhasesFormObject.phases;
    }

    return selectedPhases.map((selectedPhase: any) => {
      // check if phase is created inside form object
      const availablePhase = planFormObject.phasesFormObject.find(
        (phasesFormRow: any) => phasesFormRow[phaseIdFieldKey] === selectedPhase
      );

      let phaseRowData: any = phases.find(
        (phase: any) => phase?.["internal_id"] === parseInt(selectedPhase)
      );

      // if phase is created, return it
      if (availablePhase) {
        return {
          ...availablePhase,
          [phaseNameFieldKey]: phaseRowData?.[phaseNameFieldKey],
        };
      }

      /*
      @initial phases from creation for plan edit/save
      */
      let initializePhasesTable: any = {};
      Object.values(planFormPhasesTableFields).map((field: string) => {
        // if plus, minus, extraTime, meanTime fields, then set them as zero
        if (
          [
            planFormPhasesTableFields?.plus,
            planFormPhasesTableFields?.minus,
            planFormPhasesTableFields?.extraTime,
            planFormPhasesTableFields?.meanTime,
          ].includes(field)
        ) {
          initializePhasesTable[field] = 0;
        } else if (
          [
            planFormPhasesTableFields?.detectorList,
            planFormPhasesTableFields?.buttonList,
            planFormPhasesTableFields?.loopList,
            planFormPhasesTableFields?.detectorDecisionList,
          ].includes(field)
        ) {
        } else {
          initializePhasesTable[field] = "";
        }
      });

      return {
        ...initializePhasesTable,
        [phaseIdFieldKey]: selectedPhase,
        [phaseNameFieldKey]: phaseRowData?.[phaseNameFieldKey],
        // TODO
        // [minSequenceTimeoutFieldKey]: phaseRowData?.[minSequenceTimeoutFieldKey],
        [minSequenceTimeoutFieldKey]: phaseRowData?.["min_sequence_timeout"],
        // [maxSequenceTimeoutFieldKey]: phaseRowData?.[maxSequenceTimeoutFieldKey],
        [maxSequenceTimeoutFieldKey]: phaseRowData?.["max_sequence_timeout"],
        // [signalOutputFieldKey]: phaseRowData?.[signalOutputFieldKey],
        [signalOutputFieldKey]: phaseRowData?.["output_number"],
      };
    });
  };

  const updatePhasesForm = (field: any, phaseKey: any, value: any) => {
    // to convert undefined values to null
    if (value === "" || value === undefined) {
      value = null;
    }

    setPlanFormObject((prev: any) => {
      let phasesFormObject = prev.phasesFormObject;
      return {
        ...prev,
        phasesFormObject: phasesFormObject.map((phase: any) => {
          if (phase[phaseIdFieldKey] !== phaseKey) {
            return phase;
          }
          return {
            ...phase,
            [field]: value,
          };
        }),
      };
    });
  };

  // Interruptor ID, Trailer Phase ID, Schedule Columns
  const clearColumnsUptoPlanModes = (
    selectedProperty: "interruptorPhaseId" | "trailerPhaseId" | "schedule"
  ) => {
    // other present any situation except restrictedPriority and trailingPriority.
    // so the 'schedule' column will be visible on other situation
    const alternativeColumns = [
      interruptorPhaseIdFieldKey,
      trailerPhaseIdFieldKey,
      scheduleFieldKey,
    ];

    let remainders: string[] = alternativeColumns.filter(
      (column) => column !== selectedProperty
    );

    const nullValuedObjectOfArray: (a: string | object, b: string) => any = (
      a: string | object,
      b: string
    ) => {
      return {
        ...(typeof a !== "object"
          ? {
              [a]: null,
            }
          : a),
        [b]: null,
      };
    };

    /**
     * @example
     * {
     *  restrictedPriority: null,
     *  trailingPriority: null,
     * }
     */
    let remaindersObject: any = remainders.reduce(nullValuedObjectOfArray);

    setPlanFormObject((prev: any) => {
      return {
        ...prev,
        phasesFormObject: prev.phasesFormObject.map((phase: any) => {
          return {
            ...phase,
            ...remaindersObject,
          };
        }),
      };
    });
  };

  // If one of schedular, trailerPhaseId, interruptorPhaseId is choosen, then clear the others' from plan phases
  useEffect(() => {
    const { phasesFormObject, ...planFormOutOfPhases } = planFormObject;

    let restrictedPriorityField = planFormEpicFields.restrictedPriority;
    let trailingPriorityField = planFormEpicFields.trailingPriority;

    let restrictedPriority =
      planFormOutOfPhases[restrictedPriorityField.fieldName];
    let trailingPriority = planFormOutOfPhases[trailingPriorityField.fieldName];

    // multipleUpdatePlanFormEpics
    if (restrictedPriority) {
      // null -> schedule, trailerPhaseId
      clearColumnsUptoPlanModes("interruptorPhaseId");
    } else if (trailingPriority) {
      // null -> schedule, interruptorPhaseId
      clearColumnsUptoPlanModes("trailerPhaseId");
    } else {
      // null -> interruptorPhaseId, trailerPhaseId
      clearColumnsUptoPlanModes("schedule");
    }
  }, [
    planFormObject?.[planFormEpicFields?.restrictedPriority?.fieldName],
    planFormObject?.[planFormEpicFields?.trailingPriority?.fieldName],
  ]);

  // set phases form inside planFormObject, if selectedPhase changes
  useEffect(() => {
    if (selectedPhases.length > 0) {
      setPlanFormObject((prev: any) => ({
        ...prev,
        phasesFormObject: getPhasesFormObject(),
      }));
    }
  }, [selectedPhases]);

  // set phases form inside planFormObject, if form is called directly in edit/copy situations
  useEffect(() => {
    if (phases.length > 0) {
      setPlanFormObject((prev: any) => ({
        ...prev,
        phasesFormObject: getPhasesFormObject(),
      }));
    }
  }, [phases]);

  useEffect(() => {
    // if predefinedPlan data is available, then set the selectedPhases
    if (predefinedPhasesFormObject && predefinedPhasesFormObject?.phases) {
      setSelectedPhases(
        predefinedPhasesFormObject.phases.map(
          (tableItem: any) => tableItem[phaseIdFieldKey]
        )
      );
    }

    // update the flashPlan state as false when component destroyed
    // update the planActionMode state as false when component destroyed
    return () => {
      if (flashPlanStateSet) {
        flashPlanStateSet?.cleanState();
      }
      if (planActionModeSet) {
        planActionModeSet?.cleanState();
      }
    };
  }, []);

  // getPlanFormEpics executed in an useEffect hook
  const getPlanFormEpics: any = useCallback(() => {
    if (predefinedPhasesFormObject) {
      let { phases, ...planFormEpics } = predefinedPhasesFormObject;

      // if plan datas taken from copied plan card, then clear the name property
      if (planActionModeSet?.state === "copy") {
        planFormEpics[planFormEpicFields.name.fieldName] = "";
      }

      // return planFormEpics;
      let timeoutInterruptFieldName = planFormEpicFields.timeoutInterrupt.fieldName;
      let phaseInsertionFieldName = planFormEpicFields.phaseInsertion.fieldName;
      let restrictedPriorityFieldName =
        planFormEpicFields.restrictedPriority.fieldName;
      let trailingPriorityFieldName =
        planFormEpicFields.trailingPriority.fieldName;

      // TODO
      // Just to check if predefined form epics contains phaseInsertion, restrictedPriority and trailingPriority

      const generatedPlanId = generateIdForNewPlan(
        contextSignalPlan,
        planFormObject
      );
      return {
        ...planFormEpics,
        // if planActionMode("edit" || "copy" || null) is not edit, then generate a proper id(for copy situation)
        ...(planActionModeSet?.state === "copy"
          ? {
              id: generatedPlanId,
              name: `Plan ${generatedPlanId}`,
            }
          : {}),
        // create this fields if incoming data does not have any
        ...(!planFormEpics?.hasOwnProperty(phaseInsertionFieldName)
          ? { [phaseInsertionFieldName]: null }
          : {}),
        ...(!planFormEpics?.hasOwnProperty(restrictedPriorityFieldName)
          ? { [restrictedPriorityFieldName]: null }
          : {}),
        ...(!planFormEpics?.hasOwnProperty(timeoutInterruptFieldName)
        ? { [timeoutInterruptFieldName]: null }
        : {}),
        ...(!planFormEpics?.hasOwnProperty(trailingPriorityFieldName)
          ? { [trailingPriorityFieldName]: null }
          : {}),
      };
    } else {
      let { phasesFormObject, ...planFormEpics } = planFormObject;

      let planFormEpicFieldValues = Object.values(planFormEpicFields);

      // if planFormEpics are created before
      if (Object.keys(planFormEpics).length !== 0) {
        return planFormEpics;
      }

      // if planFormEpics are not created before
      planFormEpicFieldValues.forEach((initialField: any) => {
        planFormEpics[initialField.fieldName] = initialField.initial;
      });

      return planFormEpics;
    }
  }, [planFormObject, predefinedPhasesFormObject]);

  const updatePlanFormEpics = useCallback(
    (field: any, value: any) => {
      // avoid to access to uncreated fields or phases field
      if (
        field === "phasesFormObject" ||
        !planFormObject.hasOwnProperty(field)
      ) {
        return;
      }

      setPlanFormObject((prev: any) => ({
        ...prev,
        [field]: value,
      }));
    },
    [planFormObject]
  );

  const multipleUpdatePlanFormEpics = (
    updateBody: {
      field: any;
      value: any;
    }[]
  ) => {
    let updateObject = {};

    // merge field and value inside updateObject
    updateBody.forEach((updateItem: { field: any; value: any }) => {
      if (
        updateItem.field !== "phasesFormObject" &&
        planFormObject.hasOwnProperty(updateItem.field)
      ) {
        Object.assign(updateObject, {
          [updateItem.field]: updateItem.value,
        });
      }
    });

    setPlanFormObject((prev: any) => ({
      ...prev,
      ...updateObject,
    }));
  };

  // get plan informations out of phases
  // if planActionMode("edit" || "copy" || null) is not edit, then generate a proper id
  useEffect(() => {
    setPlanFormObject((prev: any) => {
      const generatedPlanId = generateIdForNewPlan(contextSignalPlan, prev);
      return {
        ...prev,
        ...getPlanFormEpics(),
        ...(planActionModeSet?.state !== "edit"
          ? {
              id: flashPlanStateSet?.state ? 0 : generatedPlanId,
              name: `Plan ${generatedPlanId}`,
            }
          : {}),
      };
    });
  }, [predefinedPhasesFormObject]);

  return (
    <>
      <Steps
        style={{
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
        current={currentStep}
      >
        {steps.map((item) => (
          <Step key={item.title} title={t(item.title)} />
        ))}
      </Steps>

      {/* Step 1 Content */}
      {currentStep === 0 && (
        <PhaseSelection
          selectedPhases={selectedPhases}
          setSelectedPhases={setSelectedPhases}
          checkedPhasesBeforeSelected={checkedPhasesBeforeSelected}
          setCheckedPhasesBeforeSelected={setCheckedPhasesBeforeSelected}
        />
      )}

      {/* Step 2 Content */}
      {currentStep === 1 && (
        <PhasesFormTable
          currentStep={currentStep}
          planFormObject={planFormObject}
          isFlashPlan={flashPlanStateSet?.state}
          planActionMode={planActionModeSet ? planActionModeSet?.state : null}
          getPlanFormEpics={getPlanFormEpics}
          predefinedPhasesFormObject={predefinedPhasesFormObject}
          updatePhasesForm={updatePhasesForm}
          clearColumnsUptoPlanModes={clearColumnsUptoPlanModes}
          updatePlanFormEpics={updatePlanFormEpics}
          multipleUpdatePlanFormEpics={multipleUpdatePlanFormEpics}
          close={closePlanCreationOrEdit}
          ref={formSubmitRef}
        />
      )}

      {/* Prev-Next Steps Action Buttons */}
      <div
        className="steps-action"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: ".5rem",
        }}
      >
        <Button
          onClick={() => setCurrentStep((prev) => prev - 1)}
          disabled={
            currentStep <= 0 ||
            (flashPlanStateSet && currentStep <= 1 ? true : false)
          }
        >
          { t("previous") }
        </Button>
        <Button
          type="primary"
          onClick={() => {
            if (currentStep === 1) {
              formSubmitRef.current?.submitTableForm();
            }

            if (currentStep === 0 && selectedPhases.length > 0) {
              setCurrentStep((prev) => prev + 1);
            } else if (currentStep === 0) {
              message.error("Faz seçimi yapılmadı...");
            }
          }}
        >
          {currentStep === 1 ? t("save") : t("next")}
        </Button>
      </div>
    </>
  );
};

// React.memo is required to hold phasesFormObject properly
export default React.memo(CreateNewPlan);
