import http from "../http.common";
import { Ups } from "./Ups";

const getUpses = function () {
  return http.get<Ups[]>(`/web/ups/ups/list`);
};

const resetUps = function (upsDevice:any) {
  return http.post<boolean>(`/web/ups/ups/resetUps`,upsDevice);
};

const getUps = function (data: Ups) {
  return http.post<any>(`/web/ups/ups/upsRawData`, data);
};

const addUps = function (ups: Ups) {
  return http.post<Ups>(`/web/ups/ups/addUps`, ups);
};

const updateUps = function (ups: Ups) {
  return http.post<Ups>(`/web/ups/ups/updateUps`, ups);
};

const deleteUps = function (upsId: number) {
  return http.post<number>(`/web/ups/ups/deleteUps`, { id: upsId });
};

const UpsService = {
  getUpses,
  getUps,
  addUps,
  updateUps,
  deleteUps,
  resetUps
};

export default UpsService;
