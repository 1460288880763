import { notification } from "antd";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ToasterMessageModel,
  useToaster,
} from "../../../../contexts/information/ToasterContext";
import { getIcon } from "../../../../containers/ToasterIcon";
import { IconType } from "../../../../data/toaster-notification/ToasterIconEnum";
import moment from "moment";
import { AuthService } from "@icms/ui-components";

const Context = React.createContext({ name: "Default" });

function ToasterControl() {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const toasterMessage: ToasterMessageModel | undefined | null =
    useToaster().toasterMessage;

  const showToasterMessage = (model: ToasterMessageModel) => {
    const { title, description, icon, design, duration, placement, ts } = model;

    const style: React.CSSProperties = {};
    if (design) {
      if (design.backgroundColor)
        style.backgroundColor = design.backgroundColor;
      if (design.iconColor) style.color = design.iconColor;
    }

    api.open({
      message: (
        <>
          {
            <span
              className="toaster-title"
              style={{ color: design?.titleColor }}
            >
              {title}
            </span>
          }
        </>
      ),
      description: (
        <>
          {
            <p
              className="toaster-description"
              style={{ color: design?.descriptionColor }}
            >
              {description}
            </p>
          }
          {
            <span
              className="toaster-date"
              style={{ color: design?.descriptionColor }}
            >
              {t("date") + ": " + moment(ts).format("HH:mm:ss")}
            </span>
          }
        </>
      ),
      icon: getIcon(icon ? icon : IconType.LineOutlined),
      style,
      duration: AuthService.getUser().user_config?.notification_duration  ? AuthService.getUser().user_config.notification_duration : 20,
      placement: placement ? placement : "bottomRight",
    });
  };

  useEffect(() => {
    if (toasterMessage == null || toasterMessage == undefined) return;
    showToasterMessage(toasterMessage);
  }, [toasterMessage]);

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);

  return (
    <Context.Provider value={contextValue}>{contextHolder}</Context.Provider>
  );
}

export default ToasterControl;
