const planFormEpicFields = {
  id: {
    fieldName: "id",
    initial: null,
  },
  name: {
    fieldName: "name",
    initial: "",
  },
  multiModeType: {
    fieldName: "multiModeType",
    initial: "chaos",
  },
  maxTS: {
    fieldName: "maxTS",
    initial: null,
  },
  minTS: {
    fieldName: "minTS",
    initial: null,
  },
  planType: {
    fieldName: "planType",
    initial: null,
  },
  cycleTime: {
    fieldName: "cycleTime",
    initial: 0,
  },
  // * plan modes
  greenWaveMode: {
    fieldName: "greenWaveMode",
    initial: null,
  },
  urgentEnabled: {
    fieldName: "urgentEnabled",
    initial: null,
  },
  phaseInsertion: {
    fieldName: "phaseInsertion",
    initial: null,
  },
  restrictedPriority: {
    fieldName: "restrictedPriority",
    initial: null,
  },
  trailingPriority: {
    fieldName: "trailingPriority",
    initial: null,
  },
  timeoutInterrupt: {
    fieldName: "timeoutInterrupt",
    initial: null,
  },
  // plan modes *
  managementType: {
    fieldName: "managementType",
    initial: null,
  },
  offsetDuration: {
    fieldName: "offsetDuration",
    initial: 0,
  },
};

// interface planFormEpicFieldsInterface {
//   id: null | number;
//   name: null | string;
//   maxTS: null | number;
//   minTS: null | number;
//   planType: null | "Flash" | "Smart" | "Greenwave";
//   cycleTime: null | number;
//   greenWaveMode: null | boolean;
//   urgentEnabled: null | boolean;
//   managementType: null | "phaseInsertion" | "restrictedPriority" | "trailingPriority";
//   offsetDuration: null | number;
// }

export default planFormEpicFields;
