import { groupedPlansForWeek } from "../../components/Schedular";
import { checkOverlap, isInputValid } from "../../helpers";
import { IChaosPlan } from "../MultiModePlan";
import { StaticCtxDefaultPlans } from "../StaticCtxDefaultPlans";
import { IColGroup } from "./IColGroup";

export type TDirectionIdsBasedGroupedPlanOutput = {
  corridorIds: IChaosPlan["corridorIds"];
  plans: IChaosPlan[];
}[];

export class ChaosColGroup implements IColGroup {
  plans: IChaosPlan[];
  cols: TDirectionIdsBasedGroupedPlanOutput;
  private static groupedPlans: groupedPlansForWeek = {};

  constructor(plans: IChaosPlan[], dayIndex: keyof groupedPlansForWeek) {
    this.plans = plans;
    this.cols = this.decomposeToColumns(dayIndex);
  }

  decomposeToColumns(
    dayIndex?: keyof groupedPlansForWeek
  ): TDirectionIdsBasedGroupedPlanOutput {
    let groupedOutput: TDirectionIdsBasedGroupedPlanOutput = [];
    for (let i = 0; i < this.plans.length; i++) {
      //console.log(StaticCtxDefaultPlans)
      const directionIdsTag: string = JSON.stringify(
        StaticCtxDefaultPlans?.plans?.find((plan) => {
            return plan?.id === this.plans?.[i]?.planId;
          })
          ?.corridorIds?.sort()
      );

      // const directionIdsTag: string = JSON.stringify(
      //   this.plans?.[i]?.corridorIds?.sort()
      // );

      const indexOfPlanCol = groupedOutput.findIndex(
        (item) => JSON.stringify(item.corridorIds) === directionIdsTag
      );

      const planHasColSibling = indexOfPlanCol !== -1;

      if (planHasColSibling) {
        groupedOutput[indexOfPlanCol].plans.push(this.plans[i]);
      } else {
        try {
          groupedOutput = [
            ...groupedOutput,
            {
              corridorIds: JSON.parse(directionIdsTag),
              plans: [this.plans?.[i]],
            },
          ];

          if (isInputValid(dayIndex) && dayIndex !== undefined) {
            ChaosColGroup.groupedPlans[dayIndex] = groupedOutput;
          }
        } catch (err) {}
      }
    }

    return groupedOutput;
  }

  static checkIntersection(
    plan: IChaosPlan,
    dayIndex: keyof groupedPlansForWeek
  ) {
    const checkPlanCorridorIds = StaticCtxDefaultPlans?.plans?.find(
      (defPlan) => defPlan?.id === plan?.planId
    )?.corridorIds;

    const hasOverlap = ChaosColGroup.groupedPlans?.[dayIndex]?.some(
      (groupedPlanObj) => {
        return (
          JSON.stringify(groupedPlanObj?.corridorIds?.sort()) ===
            JSON.stringify(checkPlanCorridorIds?.sort()) &&
          groupedPlanObj?.plans.some((planInGroupedPlans) => {
            return checkOverlap(plan, planInGroupedPlans);
          })
        );
      }
    );

    return hasOverlap;
  }
}
