import React from "react";
import { useTranslation } from "react-i18next";
import { Popover, Checkbox } from "antd";
import DarkButton from "../../components/custom-styled/button/DarkButton";
import { DatabaseOutlined } from "@ant-design/icons";
import { CustomLayerGroup } from "../../map-constants/LayerData";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";

const btnCss = `
  position:absolute;
  right: 0;
  margin-top:-42px;
`;

interface LayerControlProps {
  layerMenuCssOverride?: boolean;
}

function LayerControl(props: LayerControlProps) {
  const { t } = useTranslation();
  const { layerMenuCssOverride } = props;
  const { layerList, changeLayer } = useCustomLayerData();

  const onChange = (e: CheckboxChangeEvent) => {
    changeLayer(e.target.value, e.target.checked);
  };

  const content: React.FC = () => {
    return (
      <>
        {layerList.map((m: CustomLayerGroup, index: number) => (
          <React.Fragment key={`layer_${m.id}`}>
            <div style={{ margin: "10px 0 0 0" }}>
              <Checkbox
                onChange={onChange}
                value={m.id}
                key={index * 9}
                checked={m.isChecked}
              />
              &nbsp;{t(m.name)}
            </div>
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <Popover
      content={content}
      placement="bottomRight"
      title={t("modules")}
      trigger="hover"
    >
      <div>
        <DarkButton
          css={layerMenuCssOverride ? btnCss : ""}
          icon={<DatabaseOutlined color="white" />}
        />
      </div>
    </Popover>
  );
}

export default LayerControl;
