import moment, { Moment } from "moment";
import http from "../../../../data/http.common";
import { dateTimeConvertTimestamp } from "../utils/dateTimeConvertTimestamp";

interface IJunctionId {
  junctionId: number | string;
}

interface IJunctionIdModelId {
  junction_id: number;
  junction_model_id: number;
}

interface IPastRecordForm {
  startTime: Moment;
  endTime: Moment;
  errorType: string;
  page?: number;
}

interface IPhaseDataForm {
  startTime: Moment;
  endTime: Moment;
  phase: string;
  option: "lastDay" | "lastTwoDays" | "lastWeek" | "lastMonth";
  page?: number;
}

interface IPastSssmDataForm {
  junctionId: number;
  startTime: Moment;
  endTime: Moment;
}

interface IPastAiPredictionDataForm {
  junctionId: string;
  startTime: Moment;
  endTime: Moment;
}
interface IPastStatusDataForm {
  junctionId: string;
  startTime: Moment;
  endTime: Moment;
}

interface IHistoricalQueueLengthForm {
  junctionId: string;
  startTime: Moment;
  endTime: Moment;
  corridor_id: string;
}

export const getPhases = (data: IJunctionIdModelId) => {
  return http.post("/web/junction/phase/list", data);
};

// Statistic archive requests.
export const fetchPastRecord = (data: IPastRecordForm & IJunctionId) => {
  return http.post<any>("/web/junction/statistic/pastrecord", {
    ...data,
    startTime: data?.startTime?.unix(),
    endTime: data?.endTime?.unix(),
    page: (data?.page && typeof data?.page === "number") ? data?.page:  1,
    limit: 10,
  });
};

export const fetchPhaseData = (data: IPhaseDataForm & IJunctionId) => {
  return http.post<any>("/web/junction/statistic/phasedata", {
    ...data,
    startTime: data?.startTime?.unix(),
    endTime: data?.endTime?.unix(),
    page: (data?.page && typeof data?.page === "number") ? data?.page:  1,
    limit: 10,
  });
};

export const fetchPhaseDataExport = (data: IPhaseDataForm & IJunctionId) => {
  return http.post<any>("/web/junction/statistic/phasedataExport", {
    ...data,
    startTime: data?.startTime?.unix(),
    endTime: data?.endTime?.unix(),
  });
};

export const fetchSsmData = ({ junctionId, startTime, endTime }: IPastSssmDataForm) => {
  let startDate = dateTimeConvertTimestamp(startTime,moment(startTime.format("HH:mm")))
  let endDate = dateTimeConvertTimestamp(startTime,endTime)
  return http.post("/web/junction/statistic/historicalssmdata", { junctionId,startDate, endDate });
};

export const fetchStatusData = ({ junctionId, startTime, endTime }: IPastStatusDataForm) => {
  let startDate = moment(startTime).unix();
  let endDate = moment(endTime).unix();
  return http.post("/web/junction/statistic/statusdata", { junctionId,startDate, endDate });
};


export const fetchAiPredictionData = ({ junctionId, startTime, endTime }: IPastAiPredictionDataForm) => {
  let startDate = moment(startTime).unix();
  let endDate = moment(endTime).unix();
  let junction_id = parseInt(junctionId)
  return http.post("/web/junction/statistic/aipredictiondata", { junction_id,startDate, endDate });
};

export const fetcHistoricalQueueLength = ({ junctionId, startTime, endTime,corridor_id }: IHistoricalQueueLengthForm) => {
  let startDate = moment(startTime).unix();
  let endDate = moment(endTime).unix();
  let junction_id = junctionId;
  return http.post("/web/junction/statistic/historicalqueuelength", { junction_id,startDate, endDate,corridor_id });
};

export const getCorridors = (junctionId:string) => {
  return http.post("/web/junction/statistic/getcorridors", { junction_id:junctionId });
};