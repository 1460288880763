import { Alert, Button, Form, Modal, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import JunctionService from "../../data/junction/JunctionService";
import { Junction } from "../../data/junction/Junction";
import { useTranslation } from "react-i18next";
interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (values: any) => void;
  selectedJunction?: Junction;
}

function JunctionFileUpload(props: Props) {
  const { t } = useTranslation();
  const { isOpen, onClose, onSave, selectedJunction } = props;

  const onFinish = useCallback(
    (values: any) => {
      const formData = new FormData();
      formData.append("file", values.file);
      JunctionService.fileUpload(formData, selectedJunction!.id)
        .then((r) => {
          onSave(values);
        })
        .catch((err) => console.log(err));
    },
    [onSave, selectedJunction]
  );

  const onChange = useCallback((info) => {
    info.file.status = "done";
  }, []);
  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={<></>}
      title={t("upload_file")}
      width={650}
    >
      <Form layout="inline" onFinish={onFinish}>
        <Form.Item style={{ marginBottom: "10px" }}>
          <Alert message={t("upload_design_file_info")} type="info" showIcon />
        </Form.Item>
        <Form.Item
          getValueFromEvent={({ file }) => file.originFileObj}
          name="file"
        >
          <Upload onChange={onChange} maxCount={1}>
            <Button icon={<UploadOutlined />}>{t("upload")}</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default JunctionFileUpload;
