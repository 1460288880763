import { useContext, useEffect, useMemo, useState } from "react";
import {
  ICFMPlan,
  IChaosPlan,
  ICoordPlan,
  IDischargePlan,
  TAlternativePlan,
} from "../../models/MultiModePlan";
import { CfmColGroup } from "../../models/PlanCols/CfmColGroup";
import {
  ChaosColGroup,
  TDirectionIdsBasedGroupedPlanOutput,
} from "../../models/PlanCols/ChaosColGroup";
import { CoordColGroup } from "../../models/PlanCols/CoordColGroup";
import {
  DischargeColGroup,
  TCameraIdsBasedGroupedPlanOutput,
} from "../../models/PlanCols/DischargeColGroup";
import {
  dayNameHeight,
  days,
  planTypeDetailSeperatorHeight,
  planTypeSeperatorHeight,
} from "../../constants";
import PlanBox from "../PlanBox";
import "./styles.css";
import { MultiModeSchedularContext } from "../../context/MultiModeSchedularContext";
import { MultiModePlanContext } from "../../../../context/MultiModePlanContext";
import {
  groupedPlansForWeek,
  ICopyPastePlanModalInformations,
  TDefaultPlan,
} from "../Schedular";
import { CopyOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Button } from "antd";

interface Props {
  plans: TAlternativePlan[];
  dayName: string;
  showAddPlanModal: (dayIndex: number | "all") => void;
  copyPasteDay: React.Dispatch<
    React.SetStateAction<ICopyPastePlanModalInformations>
  >;
}

export type TTypeBasedGroupedPlanOutput = {
  [key in TAlternativePlan["type"]]: TAlternativePlan[];
};

interface IDailyPlanCols {
  chaos: TDirectionIdsBasedGroupedPlanOutput;
  discharge: TCameraIdsBasedGroupedPlanOutput;
  cfm: {
    plans: ICFMPlan[];
  }[];
  coord: {
    plans: ICoordPlan[];
  }[];
}

const DayContainer: React.FC<Props> = ({
  plans,
  dayName,
  showAddPlanModal,
  copyPasteDay,
}) => {
  const planContextValues = useContext(MultiModePlanContext);
  const [contextSignalPlan] = planContextValues?.signalPlan;
  const [locked] = planContextValues.locked;

  const dayIndex: keyof groupedPlansForWeek = useMemo(() => {
    return days.findIndex(
      (day) => day === dayName
    ) as keyof groupedPlansForWeek;
  }, [dayName]);

  const groupPlanTypes = (
    plans: TAlternativePlan[]
  ): TTypeBasedGroupedPlanOutput => {
    let groupedOutput: TTypeBasedGroupedPlanOutput = {
      cfm: [],
      coord: [],
      chaos: [],
      discharge: [],
    };
    for (let i = 0; i < plans?.length; i++) {
      // const typeOfPlan: TAlternativePlan["type"] = plans?.[i]?.["type"];// const dayPlans = contextSignalPlan?.dailyPlans[dayIndex]?.plans;
      const theDefaultPlan: TDefaultPlan = contextSignalPlan?.plans?.find(
        (plan: TDefaultPlan) => {
          return plan?.id === plans?.[i]?.planId;
        }
      );

      //typeOfTheDefaultPlan
      const typeOfPlan: TAlternativePlan["type"] =
        theDefaultPlan?.multiModeType;

      if (groupedOutput.hasOwnProperty(typeOfPlan)) {
        groupedOutput[typeOfPlan] = [...groupedOutput[typeOfPlan], plans[i]];
      } else {
        groupedOutput[typeOfPlan] = [plans[i]];
      }
    }

    return groupedOutput;
  };

  const getDailyPlanCols = (plans: TAlternativePlan[]) => {
    // console.log("plans:", plans);
    // const typeBasedGroupedPlans = groupPlanTypes(plans);
    // console.log("typeBasedGroupedPlans:", typeBasedGroupedPlans);

    const chaosDistinctCols = new ChaosColGroup(
      groupPlanTypes(plans).chaos as IChaosPlan[],
      dayIndex
    );

    const dischargeDistinctCols = new DischargeColGroup(
      groupPlanTypes(plans).discharge as IDischargePlan[],
      dayIndex
    );

    const cfmDistinctCols = new CfmColGroup(
      groupPlanTypes(plans).cfm as ICFMPlan[],
      dayIndex
    );

    const coordDistinctCols = new CoordColGroup(
      groupPlanTypes(plans).coord as ICoordPlan[],
      dayIndex
    );

    // can use dependecy injection to get #cols#
    // return {
    //   chaos: new PlanColsManager(chaosDistinctCols).getCols(),
    //   discharge: new PlanColsManager(dischargeDistinctCols).getCols(),
    //   cfm: new PlanColsManager(cfmDistinctCols).getCols(),
    //   coord: new PlanColsManager(coordDistinctCols).getCols(),
    // };

    return {
      chaos: chaosDistinctCols.cols,
      discharge: dischargeDistinctCols.cols,
      cfm: cfmDistinctCols.cols,
      coord: coordDistinctCols.cols,
    };
  };

  const [dailyPlanCols, setDailyPlanCols] = useState<IDailyPlanCols>(
    getDailyPlanCols(plans)
  );

  // if plans change then re-calc plans on column
  useEffect(() => {
    setDailyPlanCols(getDailyPlanCols(plans));
  }, [plans]);

  const schedularContextValues = useContext(MultiModeSchedularContext);
  const [selectedMultiModeTypes] =
    schedularContextValues?.selectedMultiModeTypes;

  return (
    <>
      <div className="mmsp-schedular__day-container">
        {!locked && (
          <Button
            style={{
              border: "1px solid #000",
              position: "absolute",
              top: "100%",
              width: "100%",
              cursor: "pointer",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              showAddPlanModal(dayIndex);
            }}
            type="primary"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
              }}
            >
              <span>Insert Plan</span>
              <PlusSquareOutlined />
            </div>
          </Button>
        )}
        <h2
          className="mmsp-schedular__day-container__header"
          style={{
            height: dayNameHeight,
            position: "relative",
          }}
        >
          {!locked && (
            <CopyOutlined
              onClick={() => {
                if (copyPasteDay !== undefined) {
                  copyPasteDay({
                    visible: true,
                    copyIndex: dayIndex,
                  });
                }
              }}
              style={{
                position: "absolute",
                top: "50%",
                right: 15,
                transform: "translateY(-50%)",
                fontSize: "1rem",
                cursor: "pointer",
              }}
            />
          )}
          {dayName?.toUpperCase()}
        </h2>
        <div style={{ display: "flex" }}>
          {Object.keys(dailyPlanCols)
            .filter((multiModeType: string) =>
              selectedMultiModeTypes.includes(multiModeType)
            )
            .map((typeKey, typeIndex) => {
              return (
                <div
                  className="mmsp-schedular__daily-plan-type-cols"
                  key={typeIndex}
                  style={{
                    ...(typeIndex === 0
                      ? {
                          borderLeftWidth: 1,
                          borderLeftStyle: "solid",
                        }
                      : {}),
                  }}
                >
                  <div
                    className="mmsp-schedular__daily-plan-type-cols__header"
                    style={{
                      height: planTypeSeperatorHeight,
                    }}
                  >
                    {typeKey.toUpperCase()}
                  </div>
                  <div style={{ display: "flex" }}>
                    {dailyPlanCols[typeKey as keyof IDailyPlanCols].map(
                      (col, colIndex) => {
                        return (
                          <div
                            className="mmsp-schedular__daily-plan-type-cols__plan-col"
                            key={colIndex}
                            style={{
                              ...(colIndex !==
                              dailyPlanCols[typeKey as keyof IDailyPlanCols]
                                .length -
                                1
                                ? {
                                    borderRightWidth: 1,
                                    borderRightStyle: "solid",
                                  }
                                : {}),
                            }}
                          >
                            <div
                              style={{
                                height: planTypeDetailSeperatorHeight,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 0,
                                margin: 0,
                                boxSizing: "border-box",
                                backgroundColor: ![
                                  "chaos",
                                  "discharge",
                                ].includes(typeKey as TAlternativePlan["type"])
                                  ? "#C2C2C2"
                                  : "inherit",
                              }}
                            >
                              {(typeKey as TAlternativePlan["type"]) === "chaos"
                                ? (col as any)?.corridorIds?.join(" - ")
                                : null}
                              {(typeKey as TAlternativePlan["type"]) ===
                              "discharge"
                                ? (col as any)?.dischargeIds?.join(" - ")
                                : null}
                            </div>
                            {col?.plans?.map((plan, planIndex) => {
                              return (
                                <PlanBox
                                  plan={plan}
                                  dayIndex={dayIndex}
                                  key={planIndex}
                                />
                              );
                            })}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default DayContainer;
