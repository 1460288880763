import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { HeaderContainer } from "@icms/ui-components";
import { FooterContainer } from "@icms/ui-components";
import HeaderElements from "./HeaderElements";
import LeftSider from "./LeftSider";
import { NotificationProvider } from "../contexts/information/NotificationContext";

interface Props {
  menuCollapsed?: boolean;
}
function AppLayout(props: Props) {
  const { menuCollapsed } = props;
  const [collapsed, setCollapsed] = useState(menuCollapsed ?? false);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };
  return (
      <Layout  hasSider style={{minHeight:"100vh"}}>
        <LeftSider collapsed={collapsed} toggleCollapse={toggleCollapse} />
       
        <Layout className="site-layout">
          <NotificationProvider>
              <HeaderContainer
                collapsed={collapsed}
                toggleCollapse={toggleCollapse}
              >
                <HeaderElements />
              </HeaderContainer>
          </NotificationProvider>
          <Content
            className="site-layout-background"
            style={{
              margin: "0px 16px",
              padding: 24,
              minHeight: 280,
            }}
          >
            <Outlet />
          </Content>
          <FooterContainer>ManGo - Powered by ISSD FULL STACK TEAM</FooterContainer>
        </Layout>
      </Layout>
  
  );
}

export default AppLayout;
