import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import MultipleJunctionMonitoring from "./multiple-watch/MultipleJunctionMonitoring";
import MultipleCameraMonitoring from "./multiple-watch/MultipleCameraMonitoring";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import { useMultipleWatchData } from "../../../../contexts/map/MultipleWatchContext";
import { WatchContainer } from "../../components/custom-styled/container/style";

const divStyle: React.CSSProperties = {
  fontSize: 11.5,
  fontWeight: 600,
  color: "white",
  padding: "5px 5px",
  borderRadius: 4,
  background: "#e6f2ff33",
};

const buttonStyle: React.CSSProperties = {
  fontSize: 12,
  marginTop: 8,
  padding: "0px 20px",
  marginLeft: 5,
};

const listStyle: React.CSSProperties = {
  maxHeight: "14vh",
  overflow: "auto",
  display: "flex",
  flexWrap: "wrap",
  gap: "5px",
};

const modalStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

function MultipleWatchContent() {
  const { t } = useTranslation();
  const { layerList } = useCustomLayerData();
  const { multipleWatchContentData, clearSelectedItems, setWatchStatus } =
    useMultipleWatchData();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [panelDatas, setPanelDatas] = useState<any[]>([]);

  useEffect(() => {
    setPanelDatas(multipleWatchContentData);
  }, [multipleWatchContentData]);

  const closeModal = () => {
    setWatchStatus();
    clearSelectedItems();
    setIsModalVisible(false);
    setShow(false);
  };

  const handleShowMultipleModuleMonitoring = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      {layerList.filter((item) => item.isChecked && [1, 4, 5].includes(item.id))
        .length > 0 ? (
        <>
          <WatchContainer>
            {panelDatas.length > 1 ? (
              <>
                <div style={listStyle}>
                  {panelDatas.map((item, index) => (
                    <div style={{fontSize:"11.5"}} key={index}>
                      {item.name}
                    </div>
                  ))}
                </div>
                <Button
                  type="primary"
                  style={buttonStyle}
                  size={"small"}
                  onClick={handleShowMultipleModuleMonitoring}
                >
                  {t("watch")}
                </Button>
                <Button
                  type="primary"
                  style={buttonStyle}
                  size={"small"}
                  onClick={() => {
                    clearSelectedItems();
                    setWatchStatus();
                    setShow(false);
                  }}
                >
                  {t("clear")}
                </Button>
              </>
            ) : (
              <>
                <div style={divStyle}>
                  <InfoCircleOutlined style={{ marginRight: 2 }} />
                  {t(
                    "you_must_select_at_least_two_intersections_cameras_to_monitor_multiple_intersections_cameras"
                  )}
                </div>
                {show === false ? (
                  <Button
                    type="primary"
                    style={buttonStyle}
                    size={"small"}
                    onClick={() => {
                      setWatchStatus();
                      setShow(true);
                    }}
                  >
                    {t("start_selection")}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    style={buttonStyle}
                    size={"small"}
                    onClick={() => {
                      setWatchStatus();
                      clearSelectedItems();
                      setShow(false);
                    }}
                  >
                    {t("clear")}
                  </Button>
                )}
              </>
            )}
          </WatchContainer>
          <Modal
            title={
              <div
                style={{ textAlign: "center", fontSize: 28, color: "darkgray" }}
              >
                {t("watch_multiple_modules")}
              </div>
            }
            visible={isModalVisible}
            destroyOnClose={true}
            onOk={closeModal}
            onCancel={closeModal}
            width="95%"
            style={modalStyle}
            footer={null}
          >
            {panelDatas.every((item) => item.watchModuleType === "junction") &&
            panelDatas.length > 1 ? (
              <MultipleJunctionMonitoring selectedMarkers={panelDatas} />
            ) : panelDatas.every(
                (item) =>
                  item.watchModuleType === "viero" ||
                  item.watchModuleType === "camera"
              ) && panelDatas.length > 1 ? (
              <MultipleCameraMonitoring selectedCameraMarkers={panelDatas} />
            ) : null}
          </Modal>
        </>
      ) : (
        <div style={divStyle}>
          <InfoCircleOutlined style={{ marginRight: 3 }} />
          {t(
            "relevant_modules_must_be_selected_to_monitor_multiple_intersections_cameras"
          )}
        </div>
      )}
    </>
  );
}
export default MultipleWatchContent;
