import { ClockCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Modal, Row, Upload } from "antd";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import {
  addTaskImage,
  getTaskImages,
} from "../../../../../data/task-management/TaskManagementService";
import path from "path";

interface ImageListProps {
  taskId: any;
  imageList: any[];
  setImageList: any;
  userList: any[];
}
const userLanguage = navigator.language || navigator.languages;

export const ImageList = ({
  taskId,
  imageList,
  setImageList,
  userList,
}: ImageListProps) => {
  const [imageDataList, setImageDataList] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<any>(null);

  useEffect(() => {
    if (imageList) {
      setImageDataList([]);
      imageList.map(async (img: any) => {
        try {
          const response = await getTaskImages(img.path);
          const blob = new Blob([response.data], { type: "image/jpeg" }); // Create a Blob object from the image data

          setImageDataList((prev) => [
            ...(prev ? prev : []),
            {
              id: img.id,
              captured_by: img.captured_by,
              date: img.capture_time,
              image: blob,
            },
          ]);
        } catch (error: any) {
          console.log("error:", error);
        }
      });
    }
  }, [imageList]);

  const handleUpload = async (event: any) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    const result = await addTaskImage(taskId, formData);
    if (result) {
      const imgDate = new Date(parseInt(result.data.capture_time));

      setImageList((prev: any) => [
        ...(prev ? prev : []),
        {
          id: result.data.id,
          captured_by: result.data.captured_by,
          capture_time: imgDate,
          path: result.data.path,
        },
      ]);
    }
  };
  return (
    <>
      {imageList && imageList.length > 0 ? (
        <div>
          <Row gutter={[16, 16]} style={{ paddingBottom: "2rem" }}>
            {imageDataList &&
              imageDataList.map((img, index) => {
                const takenUser = userList.find(
                  (user) => parseInt(user.id) === parseInt(img.captured_by)
                );
                const date = new Date(img.date);

                return (
                  <>
                    <Col key={img.id} xs={24} sm={12} md={8} lg={6}>
                      <Card
                        hoverable
                        onClick={() => {
                          setSelectedImage(img);
                        }}
                        style={{
                          height: "100%",
                          borderRadius: "14px",
                          overflow: "hidden",
                        }}
                        cover={
                          <div
                            style={{
                              height: 0,
                              paddingTop: "100%", // Maintaining 1:1 aspect ratio
                              position: "relative",
                            }}
                          >
                            <img
                              alt={`img${index}`}
                              src={URL.createObjectURL(img.image)}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span>
                              <ClockCircleOutlined
                                style={{
                                  color: "#656565",
                                  paddingRight: "0.3rem",
                                }}
                              />
                            </span>
                            <span style={{ color: "#656565" }}>
                              {date.toLocaleDateString(userLanguage, {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              })}
                            </span>
                          </div>
                          <span style={{ color: "#656565" }}>
                            {takenUser?.name}
                          </span>
                        </div>
                      </Card>
                    </Col>
                    {selectedImage && (
                      <Modal
                        visible={selectedImage}
                        footer={null}
                        centered
                        onCancel={() => {
                          setSelectedImage(null);
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <img
                            alt={`imgFullscreen`}
                            src={URL.createObjectURL(selectedImage.image)}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </Modal>
                    )}
                  </>
                );
              })}
          </Row>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            padding: "1rem 0",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <span style={{ color: "#656565" }}>{t("no_image_founc")}</span>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "1.2rem",
        }}
      >
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            display: "inline-block",
          }}
        >
          <input
            type="file"
            accept=".jpg,.jpeg,.png"
            onChange={handleUpload}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
          />
          <input
            type="button"
            readOnly
            value="Yükle"
            style={{
              borderRadius: "20px",
              backgroundColor: "#1890ff",
              color: "white",
              padding: "10px 15px",
              border: "none",
              outline: "none",
              cursor: "pointer",
              width: "150px", // Set the width as needed
            }}
          />
        </div>
      </div>
    </>
  );
};
