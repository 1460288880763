import { ArrowRightOutlined, CarFilled, SyncOutlined } from "@ant-design/icons";
import { Button, Col, Descriptions, Divider, Row, Tooltip } from "antd";
import Tabs from "antd/lib/tabs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiStatus, Junction } from "../../../data/junction/Junction";
import colorPallet from "../SignalPlan/common/colorPallet";
import AiPlanFlow from "./AiPlanFlow";
import { PhaseIdColor } from "./GroupData";
import JunctionService from "../../../data/junction/JunctionService";
import {
  phaseDataModel,
  usePhaseData,
} from "../../../contexts/junction/PhaseDataContext";
import {
  JunctionEventProvider,
  useJunctionEvent,
} from "../../../contexts/junction/JunctionEventContext";
import { useJunctionStatusData } from "../../../contexts/junction/JunctionStatusDataContext";
import { JunctionStatusInfo } from "../../map/map-constants/module-status/JunctionStatusIno";
import { useJunctionData } from "../../../contexts/junction/JunctionDataContext";

interface AIFlowModel {
  id: number;
  message: string;
  status: string;
  transaction_id: string;
  junction_id: string;
  ts: number;
}

interface JunctionModel {
  id: number;
  org_id: number;
  name: string;
  latitude: string;
  longitude: string;
  address: string;
  status_id: number;
  when_add: number;
  kkc_type_id: null;
  junction_control_type_id: null;
  status_update: number;
  listen_mode: boolean;
  directions: [];
  ai_is_active: boolean;
  ai_status_id: number;
}

export interface JunctionDetailModel {
  Junction: JunctionModel;
  Cameras: any;
  ActivePlan: any;
  AIFlow: AIFlowModel[];
}

interface Props {
  selectedJunction?: Junction;
}

const { TabPane } = Tabs;

const iconStyle: any = {
  position: "absolute",
  top: "calc(100% + 5px)",
  left: "50%",
  transform: "translateX(-50%)",
};

const activePhaseAreaStyle = {
  display: "flex",
  alignItems: "center",
  margin: "0px 4px 0px 0px",
  justifyContent: "center",
  fontSize: "1rem",
};

function JunctionStatusInformation(props: Props) {
  const { t } = useTranslation();
  const { selectedJunction } = props;
  const { phaseData } = usePhaseData();
  const { junctionStatusData } = useJunctionStatusData();
  const { getActivePlan, activePlan } = useJunctionData();
  const event = useJunctionEvent();
  const [phaseCycleData, setPhaseCycleData] = useState<phaseDataModel[]>([]);
  const [junctionStatusId, setJunctionStatusId] = useState<number>();
  const [junctionDetail, setJunctionDetail] = useState<JunctionDetailModel>();

  useEffect(() => {
    if (selectedJunction) {
      getActivePlan(selectedJunction);
      getJunctionDetail();
    }
  }, [selectedJunction]);

  useEffect(() => {
    if (selectedJunction?.id == event?.junctionEventData?.junctionId)
      if (event?.junctionEventData.dataType == "aiStatus") {
        let junc = junctionDetail;
        if (junc) {
          junc.Junction.ai_status_id = +event?.junctionEventData.data;
          setJunctionDetail(junc);
        }
      } else if (event?.junctionEventData.dataType == "aiFlowOld") {
      }
  }, [event?.junctionEventData]);

  useEffect(() => {
    if (phaseData.length > 0) {
      const uniquePhaseCount = Array.from(
        new Set(phaseData.map((item) => item.oldPhaseId))
      );
      setPhaseCycleData(phaseData.slice(-uniquePhaseCount.length));
    }
  }, [phaseData]);

  useEffect(() => {
    if (
      junctionStatusData.length > 0 &&
      junctionStatusData[junctionStatusData.length - 1]?.id ==
        selectedJunction?.id
    ) {
      setJunctionStatusId(
        junctionStatusData[junctionStatusData.length - 1].status_id
      );
    } else {
      setJunctionStatusId(selectedJunction?.status_id);
    }
  }, [junctionStatusData]);

  const getJunctionDetail = async () => {
    JunctionService.getDetail(selectedJunction!.id).then((res) => {
      setJunctionDetail(res.data);
    });
  };

  const getPhaseColor = (id: number) => {
    let phaseColorListCount = Object.keys(PhaseIdColor).length / 2;
    let phaseColor = PhaseIdColor[id]
      ? PhaseIdColor[id]
      : PhaseIdColor[id % phaseColorListCount];
    return phaseColor;
  };
  return (
    <JunctionEventProvider>
      <Tabs tabBarStyle={{ marginBottom: "0" }} defaultActiveKey="1">
        <TabPane tab={t("junction_status_information")} key="1">
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1.5rem",
                marginRight: "auto",
              }}
            >
              {junctionStatusId && (
                <>
                  <img
                    style={{ marginTop: "10px" }}
                    src={`${
                      JunctionStatusInfo[
                        junctionStatusId as keyof typeof JunctionStatusInfo
                      ].icon
                    }`}
                  ></img>
                  <p
                    style={{
                      marginLeft: "10px",
                      fontSize: "0.75em",
                      marginTop: "auto",
                    }}
                  >
                    {t(
                      JunctionStatusInfo[
                        junctionStatusId as keyof typeof JunctionStatusInfo
                      ].text
                    )}
                  </p>
                  {junctionDetail != undefined &&
                    selectedJunction?.ai_is_active && (
                      <>
                        <img
                          className="flash_text"
                          style={{
                            margin: "10px",
                            marginLeft: "40px",
                            marginRight: "5px",
                            marginTop: "20px",
                          }}
                          src="/static/AI.png"
                          width={"45px"}
                          height={"45px"}
                        ></img>
                        <p
                          style={{
                            fontSize: "0.75em",
                            marginTop: "auto",
                          }}
                        >
                          {" "}
                          {AiStatus[junctionDetail!.Junction!.ai_status_id]}
                        </p>
                      </>
                    )}
                </>
              )}
            </Col>
            <Col
              style={{
                marginLeft: "auto",
                boxShadow: "1px 3px 7px 2px rgb(0 0 0 / 10%)",
                paddingBottom: "15px",
              }}
            >
              <Divider
                style={{
                  backgroundColor: "#435261",
                  color: "white",
                  padding: "0",
                  margin: "0",
                }}
              >
                <SyncOutlined /> {t("phase_cycle")}
              </Divider>
              <div style={{ marginLeft: "5px" }}>
                {phaseCycleData.length > 0 &&
                  phaseCycleData.map(
                    (element: phaseDataModel, index: number) => {
                      let checkLastElement =
                        phaseCycleData.length === index + 1 ? true : false;
                      return (
                        <React.Fragment key={index}>
                          <span
                            style={{
                              position: "relative",
                            }}
                          >
                            <Tooltip
                              placement="bottom"
                              title={`${
                                phaseCycleData.find(
                                  (item) =>
                                    item.oldPhaseId == element.newPhaseId
                                )?.name
                              }`}
                              arrowPointAtCenter
                            >
                              <Button
                                size="small"
                                style={{
                                  background: `${getPhaseColor(
                                    element.newPhaseId
                                  )}`,
                                  color: "white",
                                  fontWeight: "bold",
                                  borderRadius: "5px",
                                  margin: "5px",
                                }}
                              >
                                {element["newPhaseId"]}
                              </Button>
                            </Tooltip>
                            {checkLastElement ? (
                              <CarFilled style={iconStyle} />
                            ) : (
                              <ArrowRightOutlined style={iconStyle} />
                            )}
                          </span>
                        </React.Fragment>
                      );
                    }
                  )}
              </div>
            </Col>
          </Row>
          <Row>
            <CarFilled style={activePhaseAreaStyle} />
            <p style={activePhaseAreaStyle}>{t("active_phase")}:</p>
            {phaseCycleData.length > 0 && (
              <Button
                size="small"
                style={{
                  background: `${getPhaseColor(
                    phaseCycleData[phaseCycleData.length - 1].newPhaseId
                  )}`,
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  margin: "5px",
                }}
              >
                {phaseCycleData[phaseCycleData.length - 1].newPhaseId}
              </Button>
            )}
            {phaseCycleData.length > 0 && (
              <p style={activePhaseAreaStyle}>
                {
                  phaseCycleData.find(
                    (phase: any) =>
                      phase["oldPhaseId"] ===
                      phaseCycleData[phaseCycleData.length - 1].newPhaseId
                  )?.name
                }
              </p>
            )}
          </Row>
          <Row>
            {activePlan && (
              <Descriptions size="small" bordered style={{ width: "100%" }}>
                <Descriptions.Item
                  labelStyle={{
                    backgroundColor: `${colorPallet[activePlan.id]}`,
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  contentStyle={{
                    backgroundColor: "#a5b5a5",
                    textAlign: "center",
                  }}
                  label={activePlan.name}
                >
                  <b>Min TS:</b> {activePlan.minTS}
                  <b style={{ marginLeft: "1.5em" }}>Max TS:</b>
                  {activePlan.maxTS}
                </Descriptions.Item>
              </Descriptions>
            )}
          </Row>
        </TabPane>
        {selectedJunction?.ai_is_active && (
          <TabPane tab={t("ai_mm")} key="2">
            <AiPlanFlow
              junctionDetail={junctionDetail}
              refresh={getJunctionDetail}
            ></AiPlanFlow>
          </TabPane>
        )}
        {/* <TabPane tab={t("junction_records")} key="3">
          {t("junction_records_content")}
        </TabPane>
        <TabPane tab={t("viero_errors")} key="4">
          {t("viero_errors_content")}
        </TabPane> */}
      </Tabs>
    </JunctionEventProvider>
  );
}

export default JunctionStatusInformation;
