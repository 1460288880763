import { Button, Checkbox, Form, InputNumber, Modal, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { JunctionPhase } from "../../../data/junction-phase/JunctionPhase";
import JunctionPhaseService from "../../../data/junction-phase/JunctionPhaseService";
import { useJunctionData } from "../../../contexts/junction/JunctionDataContext";

interface ModalProps {
  visible: boolean;
  data: JunctionPhase[];
  closeModal: Function;
}

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 23 },
};

const { Option } = Select;

const PhaseTimeExtensionModal: React.FC<ModalProps> = ({
  visible,
  data,
  closeModal,
}) => {
  const { t } = useTranslation();
  const { activePlan } = useJunctionData();
  const [form] = Form.useForm();
  const [inputValue, setInputValue] = useState(10);

  const onCancel = () => {
    closeModal(false);
    form.resetFields();
  };

  const onFinish = async () => {
    let formValue = form.getFieldsValue();
    if (formValue.urgent_acitve) {
      await JunctionPhaseService.urgentPhase({
        junction_id: +data[0].junction_id,
        guid: Math.floor(100000000 + Math.random() * 900000000),
        parameter: {
          junctionId: +data[0].junction_id,
          phaseId: formValue.internal_id,
          duration: formValue.time,
        },
      });
    } else {
      await JunctionPhaseService.setPhaseTime({
        duration: formValue.time,
        junction_id: +data[0].junction_id,
        phase_internal_id: formValue.internal_id,
      });
    }
    closeModal(false);
  };

  const handleInputChange = (value: number) => {
    if (activePlan.urgentEnabled && value > activePlan.maxTS) {
      return "error";
    }
    return "";
  };

  return (
    <>
      <Modal
        width={300}
        title={t("phase_extension")}
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button onClick={onCancel} form={"phaseExtensionForm"} key="cancel">
            {t("cancel")}
          </Button>,
          <Button
            type="primary"
            form={"phaseExtensionForm"}
            key="submit"
            htmlType="submit"
          >
            {t("save")}
          </Button>,
        ]}
      >
        {data && (
          <Form
            {...formItemLayout}
            form={form}
            layout="horizontal"
            name={"phaseExtensionForm"}
            autoComplete="on"
            onFinish={onFinish}
          >
            <Form.Item
              hasFeedback
              name="time"
              label={`${t("duration")} (sn)`}
              initialValue={10}
              validateStatus={handleInputChange(inputValue)}
              help={
                handleInputChange(inputValue) === "error"
                  ? t("max_sequence_timeout")
                  : ""
              }
            >
              <InputNumber
                style={{ width: "100%" }}
                max={activePlan.urgentEnabled ? activePlan.maxTS : undefined}
                value={inputValue}
                onChange={(value) => setInputValue(value)}
              />
            </Form.Item>
            <Form.Item
              name="internal_id"
              label={t("phase")}
              rules={[  
                {
                  required: true,
                  message: `${t("phase_field_cannot_be_empty")}`,
                },
              ]}
            >
              <Select
                showArrow
                allowClear
                placeholder={`${t("select_phase")}...`}
              >
                {data.map((value: JunctionPhase, index: number) => {
                  return (
                    <Option key={index} value={value.internal_id}>
                      {value.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            {activePlan.urgentEnabled && (
              <Form.Item
                wrapperCol={{ offset: 7 }}
                initialValue={false}
                name="urgent_acitve"
                valuePropName="checked"
              >
                <Checkbox>{t("priorty_phase")}</Checkbox>
              </Form.Item>
            )}
          </Form>
        )}
      </Modal>
    </>
  );
};

export default PhaseTimeExtensionModal;
