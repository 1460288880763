import http from "../http.common";
import { Barrier } from "./Barrier";
import { BarrierPastRecord } from "./BarrierPastRecord";

const getBarriers = function () {
  return http.get<Barrier[]>(`/web/barrier/list`);
};

const getBarrierPastRecord = function (req: {
  barrierId: number;
  startTime: number;
  endTime: number;
}) {
  return http.post<BarrierPastRecord[]>(`/web/barrier/pastrecord`, req);
};

const requestBarrier = function (req: {
  id: number;
  status: boolean;
  requestUrl: string;
}) {
  return http.post<boolean>(
    `/web/barrier/requestbarrier`,
    req
  );
};

const updateBarrier = function (barrier: Barrier) {
  return http.post<boolean>(`/web/barrier/update`, barrier);
};

const addBarrier = function (barrier: Barrier) {
  return http.post<boolean>(`/web/barrier/add`, barrier);
};

const deleteBarrier = function (barrierId: number) {
  return http.post<boolean>(`/web/barrier/delete`, { id: barrierId });
};

const BarrierService = {
  getBarriers,
  getBarrierPastRecord,
  addBarrier,
  requestBarrier,
  updateBarrier,
  deleteBarrier,
};

export default BarrierService;
