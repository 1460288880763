import React from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

interface ImageUploaderProps {
  onImageSelect: (file: File) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onImageSelect }) => {
  const handleChange = (info: any) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file imported successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file importing failed.`);
    }
  };

  const customRequest = ({ file, onSuccess }: any) => {
    onImageSelect(file);
    onSuccess(); 
  };

  return (
    <Upload
      customRequest={customRequest}
      onChange={handleChange}
      showUploadList={false}
      beforeUpload={(file: File) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
          message.error('You can only upload image files!');
        }
        return isImage;
      }}
    >
      <Button icon={<UploadOutlined />}>Select Image For New Config</Button>
    </Upload>
  );
};

export default ImageUploader;
