import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";

export interface InstantParkingDataProps {
  instantParkingData: any[];
}
const InstantParkingDataContext = createContext<
  InstantParkingDataProps | undefined
>(undefined);

export function InstantParkingDataProvider({
  children,
}: PropsWithChildren<{}>) {
  const [instantParkingData, setInstantParkingData] = useState<any[]>([]);
  useEffect(() => {
    socket.on("instantParkingData", (data: any) => {
      setInstantParkingData((instantParkingData) => [data]);
    });

    return () => {
      socket.off("instantParkingData");
    };
  }, []);

  return (
    <InstantParkingDataContext.Provider
      value={{
        instantParkingData: instantParkingData,
      }}
    >
      {children}
    </InstantParkingDataContext.Provider>
  );
}

export function useInstantParkingData() {
  const context = useContext(InstantParkingDataContext);
  if (context === undefined) {
    return {
      instantParkingData: [],
    };
    // throw new Error(
    //   "useInstantParkingData must be used within a InstantParkingDataContext"
    // );
  }
  return context;
}
