import { SideMenu } from "@icms/ui-components";
import Sider from "antd/lib/layout/Sider";
import { AuthService } from "@icms/ui-components";
import { useEffect, useState } from "react";
import { useAuth } from "../contexts/auth/AuthContext";
interface Props {
  collapsed: boolean;
  toggleCollapse?: () => void;
}

function LeftSider(props: Props) {
  const { collapsed, toggleCollapse } = props;
  const user = AuthService.getUser();
  const { allOrganizations } = useAuth();
  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    setCurrentURL(window.location.pathname);
  }, []);
  
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapse}
      
    >
      {
        currentURL === "/fcdAnalysis" ? (
          <>
          <div className="logo-kuark" />
          </>
        ) : (
          <>
          <div className="logo" />
          <span className="logo-text">ManGo</span>
          </>
        )
      }

      {user && <SideMenu collapsed={collapsed} data={user.user_pages} />}
      <div className="logo-bb" style={{
      width: '120px', 
      height: '120px', 
      backgroundImage: `url(${allOrganizations && allOrganizations.length>0  ? allOrganizations[0]?.logo:""})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    }}/>
    </Sider>
  );
}

export default LeftSider;