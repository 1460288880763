import L from "leaflet";

export type modulteType = "junction" | "viero" | "camera";

interface Item {
  id: number;
  watchModuleType?: modulteType;
}

interface MarkerItem {
  marker: L.Marker;
  type: modulteType;
}

class MultipleWatchService<T extends Item> {
  private static instance: MultipleWatchService<any>;
  private data: T[] = [];
  private markerList: MarkerItem[] = [];
  private isWatchStart: boolean = false;

  private addClassOnClick(marker: L.Marker): void {
    const element = marker.getElement();
    if (element) {
      L.DomUtil.addClass(element,"selectedModule")
    }
  }

  private removeClassOnClick(marker: L.Marker): void {
    const element = marker.getElement();
    if (element) {
      L.DomUtil.removeClass(element,"selectedModule")
    }
  }

  private checkData(type: modulteType): void {
    this.markerList
      .filter((marker) => marker.type !== type)
      .map((item) => this.removeClassOnClick(item.marker));
    
    this.markerList = this.markerList.filter((marker) => marker.type === type);
    this.data = this.data.filter((value) => value.watchModuleType === type);
  }

  static getInstance(): MultipleWatchService<any> {
    if (!MultipleWatchService.instance) {
      MultipleWatchService.instance = new MultipleWatchService();
    }
    return MultipleWatchService.instance;
  }

  addSelectedItem(item: T, marker: L.Marker, type: modulteType): void {
    if (this.isWatchStart) {
      this.markerList.push({
        marker: marker,
        type: type,
      }); 
      this.checkData(type);
      let removeData = this.data.some((val) => item.id === val.id);
      item.watchModuleType = type;
      this.addClassOnClick(marker);
     
      if (removeData) {
        this.removeSelectedItem(item, marker);
      } else {
        this.data.push(item);
      }
    }
  }

  removeSelectedItem(item: T, marker: L.Marker): void {
    if (this.isWatchStart) {
      this.removeClassOnClick(marker);
      this.data = this.data.filter((value) => value.id !== item.id);
    }
  }

  clearSelectedItems(): void {
    for (let index = 0; index < this.markerList.length; index++) {
      const element = this.markerList[index];
      this.removeClassOnClick(element.marker);
    }
    this.data = [];
  }

  setWatchStatus(): void {
    this.isWatchStart = !this.isWatchStart;
  }

  getAllItems(): T[] {
    return this.data;
  }
}

const multipleWatchService = MultipleWatchService.getInstance();
export default multipleWatchService;
