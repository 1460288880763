import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { JunctionDetector } from "../../data/junction-detector/JunctionDetector";
import DeviceService from "../../data/device/DeviceService";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";

export interface VieroDeviceDataContextState {
  vieroDeviceList: JunctionDetector[];
  updateAllViero: () => void;
}

const VieroDeviceDataContext = createContext<
  VieroDeviceDataContextState | undefined
>(undefined);

export function VieroDeviceDataProvider({ children }: PropsWithChildren<{}>) {
  const [vieroDevices, setVieroDevices] = useState<JunctionDetector[]>([]);
  const { hasAccessComponent,componentPermissions } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  const getVieroDevices = async () => {
    await DeviceService.getVieroDevices()
      .then((res: any) => {
        setVieroDevices(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getVieroDevices();
  }, [componentPermissions]);

  return hasAccessComponent("Viero Module-READ") ? (
    <VieroDeviceDataContext.Provider
      value={{
        vieroDeviceList: vieroDevices,
        updateAllViero: getVieroDevices,
      }}
    >
      {children}
    </VieroDeviceDataContext.Provider>
  ) : (
    <>{children}</>
  );
}

export function useVieroDeviceData() {
  const context = useContext(VieroDeviceDataContext);
  if (context === undefined) {
    return {
      vieroDeviceList: [],
      updateAllViero: () => {},
    };
    // throw new Error(
    //   "useVieroDeviceData must be used within a VieroDeviceDataContext"
    // );
  }
  return context;
}
