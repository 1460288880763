import { useState } from "react";
import { Ups } from "../../data/ups/Ups";
import { Button, Popconfirm, Tabs } from "antd";
import { UndoOutlined } from "@ant-design/icons";
import UpsStaticInfoPage from "./UpsStaticInfoPage";
import UpsDynamicInfoPage from "./UpsDynamicInfoPage";
import { t } from "i18next";
import UpsService from "../../data/ups/UpsService";
import ReportForm from "../profile/ReportForm";

const { TabPane } = Tabs;
interface Props {
  selectedUps?: Ups;
}
const UpsMain = (props: Props) => {
  const { selectedUps } = props;

  const [activeKey, setActiveKey] = useState("1");

  const handleChangeTab = (key: any) => {
    setActiveKey(key);
  };

  const resetButton = (
    <Popconfirm
      title={t("device_will_be_reset_accept")}
      onConfirm={() => UpsService.resetUps(selectedUps)}
      onCancel={() => console.log("Cancelled")}
      okText={t("yes")}
      cancelText={t("cancel")}
    >
      <Button htmlType="submit">
        {" "}
        {<UndoOutlined />}
        {t("reset")}
      </Button>
    </Popconfirm>
  );

  return (
    <Tabs
      activeKey={activeKey}
      onChange={handleChangeTab}
      style={{ marginTop: 40 }}
      tabBarExtraContent={resetButton}
      destroyInactiveTabPane
    >
      <TabPane tab={t("ups_static_info")} key="1">
        <UpsStaticInfoPage selectedUps={selectedUps}></UpsStaticInfoPage>
      </TabPane>
      <TabPane tab={t("ups_dynamic_info")} key="2">
        <UpsDynamicInfoPage selectedUps={selectedUps!}></UpsDynamicInfoPage>
      </TabPane>

      <TabPane tab={t("reportBug")} key="3">
        <ReportForm initialSelectedTopic="6" releatedId={selectedUps?.id} />
      </TabPane>
    </Tabs>
  );
};

export default UpsMain;
