import { Table, Button, DatePicker, Form, Checkbox, Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import {
  BarChartOutlined,
  DownloadOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { t } from "i18next";
import moment, { Moment } from "moment";
import { Fisheye, FisheyeDevice } from "../../data/viero-device/Fisheye";
import VieroDeviceService from "../../data/viero-device/VieroDeviceService";
import { JunctionDetector } from "../../data/junction-detector/JunctionDetector";
import "./FisheyeReporting.css";
import { LineChartData } from "./AiReporting";
import VieroAiChart from "./VieroAiChart";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { VieroAiChartData } from "../../data/viero-device/Ai";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const formLabelSpan = { span: 24 };

interface GroupChart {
  interval: string;
  visible: boolean;
  legendName: string;
  data: LineChartData[];
}

enum CheckboxNameList {
  "15_minutes" = "15_minutes",
  "1 hour" = "1 hour",
  "daily" = "daily",
  "weekly" = "weekly",
  "monthly" = "monthly",
}

enum FisheyeReportingFormFileds {
  "date" = "date",
}
const { RangePicker } = DatePicker;
interface Props {
  selectedVieroDevice: JunctionDetector;
}
const checkboxGroup = [
  {
    name: CheckboxNameList["15_minutes"],
    unit: "minutes",
    disabled: true,
    interval: 15,
    graphName: "15_graph",
  },
  {
    name: CheckboxNameList["1 hour"],
    unit: "minutes",
    disabled: true,
    interval: 60,
    graphName: "60_graph",
  },
  {
    name: CheckboxNameList.daily,
    unit: "days",
    disabled: true,
    interval: 1,
    graphName: "1_day_graph",
  },
  {
    name: CheckboxNameList.weekly,
    unit: "weeks",
    disabled: true,
    interval: 1,
    graphName: "1_week_graph",
  },
  {
    name: CheckboxNameList.monthly,
    unit: "months",
    disabled: true,
    interval: 1,
    graphName: "1_month_graph",
  },
];

function FisheyeReporting(props: Props) {
  const { selectedVieroDevice } = props;
  const pdfRef = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm();
  const [fisheyeData, setFisheyeData] = useState<FisheyeDevice[]>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [col, setCol] = useState<ColumnsType<any>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [headers, setHeaders] = useState([]);
  const [chartData, setChartData] = useState<GroupChart[]>([]);
  const [chartGroupData, setChartGroupData] = useState(checkboxGroup);
  const [visibleChartGroup, setVisibleChartGroup] = useState<boolean>(false);

  const columns: ColumnsType<any> = [
    {
      title: "",
      dataIndex: "empty",
      key: "empty",
    },
  ];

  useEffect(() => {
    let startCorridorArr: Fisheye[] = [];
    let endCorridorArr: Fisheye[] = [];
    let fisheye: Fisheye[] = [];

    for (let index = 0; index < fisheyeData?.length; index++) {
      fisheye.push(...fisheyeData[index].fisheye);
    }

    fisheye.filter(function (item) {
      var startCorridor = startCorridorArr.findIndex(
        (x) => x.startCorridorId == item.startCorridorId
      );
      var endCorridor = endCorridorArr.findIndex(
        (x) => x.endCorridorId == item.endCorridorId
      );
      if (startCorridor <= -1) {
        startCorridorArr.push(item);
      }

      if (endCorridor <= -1) {
        endCorridorArr.push(item);
      }
      return null;
    });
    let jsonArr: any = [];
    let csv: any = [];
    let csvFirstElement = { label: "", key: "empty" };
    csv.push(csvFirstElement);
    console.log(startCorridorArr);
    startCorridorArr.map((row) => {
      let filteredArr = fisheye.filter(
        (item) => item.startCorridorId === row.startCorridorId
      );
      let rows: any = {
        key: row.startCorridorId,
        empty: row.startCorridorName,
      };
      for (let index = 0; index < filteredArr.length; index++) {
        if (rows[`${filteredArr[index].endCorridorId}`]) {
          rows[`${filteredArr[index].endCorridorId}`] +=
            filteredArr[index].carCount;
        } else {
          rows[`${filteredArr[index].endCorridorId}`] =
            filteredArr[index].carCount;
        }
      }
      jsonArr.push(rows);
    });
    endCorridorArr.map((col) => {
      let csvHeaderElement = {
        label: `${col.endCorridorName}`,
        key: `${col.endCorridorId}`,
      };
      csv.push(csvHeaderElement);
      columns.push({
        title: `${col.endCorridorName}`,
        key: `${col.endCorridorId}`,
        dataIndex: `${col.endCorridorId}`,
      });
    });
    console.log(jsonArr);
    setHeaders(csv);
    setCol(columns);
    setTableData(jsonArr);
  }, [fisheyeData]);

  const fetchData = async () => {
    setIsLoading(true);
    let formValue = form.getFieldsValue();
    let startDate = moment(formValue.date[0]).unix();
    let endDate = moment(formValue.date[1]).unix();
    try {
      calculateDifference(moment(formValue.date[0]), moment(formValue.date[1]));

      await VieroDeviceService.getFisheyeDatas(
        startDate,
        endDate,
        selectedVieroDevice.id_device
      ).then((res: any) => {
        for (let index = 0; index < res.data?.rows?.length; index++) {
          res.data.rows[index].fisheye = JSON.parse(
            res.data.rows[index].fisheye
          );
        }
        setFisheyeData(res.data.rows);
        console.log(res.data.rows);
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  function formatFisheyeData(
    data: FisheyeDevice[],
    timeInterval: string,
    graphName: string
  ) {
    const formattedData: any[] = [];

    data.forEach((device: FisheyeDevice) => {
      const intervalKey = calculateIntervalKey(
        moment(device.ts),
        timeInterval
      );

      let group = formattedData.find((group) => group.ts === intervalKey);

      if (!group) {
        group = {
          ts: intervalKey,
          corridorData: {},
        };
        formattedData.push(group);
      }

      const fisheyeData = device.fisheye;
      fisheyeData.forEach((fisheyeItem: Fisheye) => {
        const corridorKey = `${fisheyeItem.startCorridorName} - ${fisheyeItem.endCorridorName}`;
        if (!group.corridorData[corridorKey]) {
          group.corridorData[corridorKey] = 0;
        }
        group.corridorData[corridorKey] += fisheyeItem.carCount;
      });
    });

    const transformedArray = formattedData.flatMap((item) => {
      const ts = item.ts;
      const corridorData = item.corridorData;
      return Object.keys(corridorData).map((corridorKey) => {
        return {
          ts: ts,
          type: corridorKey,
          value: corridorData[corridorKey],
        };
      });
    });

    setChartData((prevChartData) => {
      const existingIndex = prevChartData.findIndex(
        (item) => item.interval === timeInterval
      );

      if (existingIndex !== -1) {
        const updatedChartData = [...prevChartData];
        updatedChartData[existingIndex].data = transformedArray;
        return updatedChartData;
      } else {
        return [
          ...prevChartData,
          {
            interval: timeInterval,
            data: transformedArray,
            visible: false,
            legendName: graphName,
          },
        ];
      }
    });
  }

  const calculateIntervalKey = (ts: Moment, timeInterval: string) => {
    switch (timeInterval) {
      case CheckboxNameList["15_minutes"]:
        return ts
          .startOf("minute")
          .subtract(ts.minute() % 15, "minutes")
          .format("YYYY-MM-DD HH:mm");
      case CheckboxNameList["1 hour"]:
        return ts.startOf("hour").format("YYYY-MM-DD HH:00");
      case CheckboxNameList.daily:
        return ts.startOf("day").format("YYYY-MM-DD");
      case CheckboxNameList.weekly:
        return ts.startOf("week").format("YYYY-MM-DD");
      case CheckboxNameList.monthly:
        return ts.startOf("month").format("YYYY-MM");
      default:
        throw new Error("Invalid time range: " + timeInterval);
    }
  };

  const calculateDifference = (startDate: Moment, endDate: Moment) => {
    const updatedCheckboxGroup = chartGroupData.map((item) => {
      const diff = moment
        .duration(moment(endDate).diff(startDate))
        .as(item.unit as moment.unitOfTime.Base);
      return {
        ...item,
        disabled: diff < item.interval,
      };
    });

    setChartGroupData(updatedCheckboxGroup);
  };

  const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    const updatedChartData = chartData.map((group: GroupChart) => {
      const isVisible = checkedValues.includes(group.interval);
      return { ...group, visible: isVisible };
    });
    setChartData(updatedChartData);
  };

  const content = (
    <Checkbox.Group
      onChange={handleCheckboxChange}
      defaultValue={[]}
      style={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      {chartGroupData.map((item, idx) => {
        if (idx === 0) {
          return (
            <Checkbox
              key={idx}
              style={{ marginLeft: "8px" }}
              value={item.name}
              disabled={item.disabled}
            >
              {t(`${item.name}`)}
            </Checkbox>
          );
        }
        return (
          <Checkbox key={idx} value={item.name} disabled={item.disabled}>
            {t(`${item.name}`)}
          </Checkbox>
        );
      })}
    </Checkbox.Group>
  );

  const handleButtonClick = () => {
    setVisibleChartGroup(!visibleChartGroup);
  };

  const downloadClick = () => {
    if (!pdfRef.current) return;
    html2canvas(pdfRef.current, { scale: 2 }).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save("chart.pdf");
    });
  };

  useEffect(() => {
    if (chartGroupData.length > 0) {
      let enabledChartGroup = chartGroupData.filter(
        (item) => item.disabled === false
      );

      for (let index = 0; index < enabledChartGroup.length; index++) {
        let checkedIntervalName = enabledChartGroup[index].name;
        let checkedGraphName = enabledChartGroup[index].graphName;
        formatFisheyeData(fisheyeData, checkedIntervalName, checkedGraphName);
      }
    }
  }, [chartGroupData, fisheyeData]);

  return (
    <>
      <Form layout="inline" form={form} name="fisheyeReporting">
        <Form.Item
          style={{ fontWeight: "bold" }}
          labelCol={formLabelSpan}
          label={t("time_interval")}
          name={FisheyeReportingFormFileds.date}
          initialValue={[
            moment()
              .utcOffset(localStorage.getItem("timezone")!)
              .subtract(15, "minutes"),
            moment().utcOffset(localStorage.getItem("timezone")!),
          ]}
        >
          <RangePicker
            defaultValue={[
              moment()
                .utcOffset(localStorage.getItem("timezone")!)
                .subtract(15, "minutes"),
              moment().utcOffset(localStorage.getItem("timezone")!),
            ]}
            showTime
            format="DD/MM/YYYY HH:mm"
          />
        </Form.Item>
        <Form.Item labelCol={formLabelSpan} label={<span></span>}>
          <Button
            type="primary"
            form="fisheyeReporting"
            htmlType="submit"
            loading={isLoading}
            disabled={isLoading}
            onClick={fetchData}
          >
            {t("apply")}
          </Button>
        </Form.Item>
        <Form.Item labelCol={formLabelSpan} label={<span></span>}>
          <Button
            type="primary"
            form="fisheyeReporting"
            style={{ color: "white", background: "#2d6feb" }}
            disabled={tableData?.length > 0 ? false : true}
            icon={<DownloadOutlined />}
          >
            <CSVLink
              filename={`${fisheyeData?.[0]?.deviceID}`}
              data={tableData}
              headers={headers}
              style={{ color: "white", background: "#2d6feb" }}
            >
              {t("import_as_csv")}
            </CSVLink>
          </Button>
        </Form.Item>
        <Form.Item
          labelCol={formLabelSpan}
          label={<span></span>}
          style={{ marginLeft: "auto" }}
        >
          <Popover placement="topLeft" content={t("download_chart_image")}>
            <Button
              onClick={downloadClick}
              style={{
                color: "white",
                background: "#2d6feb",
                marginRight: "5px",
              }}
              icon={<FilePdfOutlined />}
            />
          </Popover>
          <Popover
            content={content}
            title={t("options")}
            trigger="click"
            visible={visibleChartGroup}
            onVisibleChange={setVisibleChartGroup}
          >
            <Button
              type="primary"
              style={{ color: "white", background: "#2d6feb" }}
              icon={<BarChartOutlined />}
              onClick={handleButtonClick}
            />
          </Popover>
        </Form.Item>
      </Form>
      <div ref={pdfRef}>
        {fisheyeData?.length > 0 && (
          <Table
            className="fisheye-table"
            rowKey={"key"}
            style={{ height: "auto", marginTop: "3em" }}
            dataSource={tableData}
            columns={col}
            pagination={false}
            size="small"
            tableLayout="fixed"
          />
        )}
        {chartData.length > 0 &&
          chartData
            .filter((obj) => obj.visible)
            .map((item) => (
              <div style={{ marginTop: "1.5em" }}>
                <VieroAiChart
                  data={item.data}
                  legendName={t(`${item.legendName}`)}
                />
              </div>
            ))}
      </div>
    </>
  );
}

export default FisheyeReporting;
