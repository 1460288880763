import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import { useEffect, useState } from "react";
import { MapToolbarData } from "../../map-constants/module-control/MapToolbarData";
import { ToolbarButton } from "../../components/custom-styled/button/ToolbarButton";
import { Button, List } from "antd";
import { ToolOutlined } from "@ant-design/icons";
import { t } from "i18next";

function ModuleToolbarControl() {
  const { layerList } = useCustomLayerData();
  const [toolbarContent, setToolbarContent] = useState<JSX.Element[] | null>(
    null
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    const matchedToolbars = layerList
      .filter((item) => item.isChecked)
      .map((item) => {
        const matchedData = MapToolbarData.find(
          (toolbar) => toolbar.layerId === item.id
        );
        return matchedData
          ? matchedData.toolbarButtonList.map((value, buttonIndex) => {
              return {
                id: value.modeId,
                button: (
                  <ToolbarButton
                    modeId={value.modeId}
                    key={item.id + buttonIndex}
                    content={value.button}
                    marginTop={"0px"}
                    name={value?.button?.props?.title}
                  />
                ),
              };
            })
          : [];
      })
      .flat();
    const uniqueButtons = matchedToolbars
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t["id"] === item["id"])
      )
      .map(({ id, button }) => <div key={id}>{<>{button}</>}</div>);

    if (uniqueButtons.length > 0) {
      setToolbarContent((prev) => {
        const prevButtonsMap = new Map(
          (prev ?? []).map((button) => [button.key, button])
        );

        const uniqueButtonsMap = new Map(
          uniqueButtons.map((button) => [button.key, button])
        );

        const finalButtons: JSX.Element[] = [];
        prevButtonsMap.forEach((button, key) => {
          if (uniqueButtonsMap.has(key)) {
            finalButtons.push(button);
            uniqueButtonsMap.delete(key);
          }
        });

        uniqueButtonsMap.forEach((button) => {
          finalButtons.push(button);
        });

        return finalButtons;
      });
    } else {
      setToolbarContent(null);
    }
  }, [layerList]);

  return (
    <div
      style={{
        position: "absolute",
        left: "3rem",
        top: "0.3rem",
      }}
    >
      {toolbarContent && (
        <>
          <Button
            type="primary"
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            style={{
              height: "34px",
              width: "12rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#2f4050e6",
              border: "none",
              borderRadius: "3px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <ToolOutlined color="#fff" />
              <p style={{ marginBottom: 0, fontSize: "13px" }}>
                {`${isExpanded ? t("hide_toolbar") : t("show_toolbar")}`}
              </p>
            </div>
          </Button>

          <div
            className={`expandable-content ${
              isExpanded ? "expanded" : "collapsing"
            }`}
          >
            {isExpanded && (
              <div style={{ padding: "10px" }}>{toolbarContent}</div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default ModuleToolbarControl;
