import { useEffect, useState } from "react";
import withLogging from "../../high-order-components/LoggerHOC";
import { Marker, Polygon, Tooltip } from "react-leaflet";
import L from "leaflet";
import { t } from "i18next";
import { useVehicleData } from "../../../../contexts/eps/VehicleContext";
import EmergencyPassSystemService from "../../../../data/emergency/emergencyPassSystemService";

function VehicleLayer() {
  const { vehicleList, polygonList } = useVehicleData();
  useEffect(() => {
    let polygonArr: {
      polygon: any;
      properties: any;
    }[] = [];
    for (let index = 0; index < polygonList.length; index++) {
      let latlng: any = [];
      polygonList[index].coordinates.map((item) => {
        latlng.push(item);
      });
      var polygon = L.polygon(latlng, { color: "red" });
      let polygonObj = {
        polygon: polygon,
        properties: polygonList[index],
      };
      polygonArr.push(polygonObj);
    }
    vehicleList.forEach((vehicle) => {
      for (let index = 0; index < polygonArr.length; index++) {
        let vehicleInPolygon = polygonArr[index].polygon
          .getBounds()
          .contains([vehicle.lat, vehicle.lng]);
        if (vehicleInPolygon) {
          EmergencyPassSystemService.increasePhaseDuration({
            duration: polygonArr[index].properties.duration,
            junction_id: parseInt(polygonArr[index].properties.junction_id),
            phase_id: polygonArr[index].properties.phase_id,
          });
          //message.warning(t("vehicle_detected"));
        }
      }
    });
  }, [vehicleList]);

  // function convertCoordinates(coordinates: any) {
  //   return coordinates.map((coord: any) => new L.LatLng(coord[1], coord[0]));
  // }

  return (
    <>
      {vehicleList.length > 0 &&
        vehicleList.map((vehicle, index) => {
          return (
            <Marker
              key={index * 7}
              position={[vehicle.lat, vehicle.lng]}
              icon={L.icon({
                iconUrl: `/static/vehicle.png`,
                iconSize: [25, 32],
                iconAnchor: [15.5, 42],
              })}
            >
              <Tooltip>{`${t("speed")}: ${vehicle.speed}`}</Tooltip>
            </Marker>
          );
        })}
      {/* {polygonList.length > 0 &&
        polygonList.map((polygonObj, index: number) => (
          <Polygon
            key={index}
            positions={convertCoordinates(polygonObj.coordinates)}
            color="red"
          />
        ))} */}
    </>
  );
}

export default withLogging(VehicleLayer);
