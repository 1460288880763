import { Form, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Junction, JunctionKKCType } from "../../../data/junction/Junction";
import JunctionService from "../../../data/junction/JunctionService";

interface Props {
  selectedJunction?: Junction;
  setKkcType: any;
}

function Info(props: Props) {
  const { t } = useTranslation();
  const { selectedJunction, setKkcType } = props;
  const [deviceInfo, setDeviceInfo] = useState<any>();
  const [ldGetDeviceInfo, setLdGetDeviceInfo] = useState<boolean>(true);

  const getDeviceInfo = useCallback(() => {
    JunctionService.getDeviceInfo(selectedJunction!.id)
      .then((f) => {
        setLdGetDeviceInfo(false);
        setDeviceInfo(f.data.devices);
      })
      .catch((err) => console.log(err));
  }, [selectedJunction]);

  useEffect(() => {
    getDeviceInfo();
  }, [getDeviceInfo]);

  useEffect(() => {
    if (deviceInfo) {
      setKkcType(deviceInfo.kkc.type_id)
    }
  })

  return (
    <Form layout="vertical">
      <Form.Item
        label={
          <b
            style={{
              fontSize: 15,
              backgroundColor: "#2f4050",
              color: "#ffffff",
            }}
          >
            {t("kkc_type_id")}{" "}
          </b>
        }
        style={{ fontSize: 15, backgroundColor: "#2f4050", color: "#ffffff" }}
      >
        <Spin spinning={ldGetDeviceInfo}>
          {JunctionKKCType[selectedJunction?.kkc_type_id??-1]}
        </Spin>
      </Form.Item>
      <Form.Item
        label={
          <b
            style={{
              fontSize: 15,
              backgroundColor: "#2f4050",
              color: "#ffffff",
            }}
          >
            {t("kkc_cpu_version")}
          </b>
        }
        style={{ fontSize: 14, backgroundColor: "#2f4050", color: "#ffffff" }}
      >
        <Spin spinning={ldGetDeviceInfo}>
          {deviceInfo?.kkc.cpu_version
            ? deviceInfo.kkc.cpu_version
            : t("failed_to_retrieve_data")}
        </Spin>
      </Form.Item>
      <Form.Item
        label={
          <b
            style={{
              fontSize: 15,
              backgroundColor: "#2f4050",
              color: "#ffffff",
            }}
          >
            {t("Ip Address")}
          </b>
        }
        style={{ fontSize: 14, backgroundColor: "#2f4050", color: "#ffffff" }}
      >
        <Spin spinning={ldGetDeviceInfo}>
          <a
            href={"http://"+deviceInfo?.centris.ipaddress}
            target="_blank"
          >
            {deviceInfo?.centris.ipaddress
              ? deviceInfo?.centris.ipaddress
              : t("failed_to_retrieve_data")}
          </a>

        </Spin>
      </Form.Item>

      <Form.Item
        label={
          <b
            style={{
              fontSize: 15,
              backgroundColor: "#2f4050",
              color: "#ffffff",
            }}
          >
            {t("Firmware version")}
          </b>
        }
        style={{ fontSize: 14, backgroundColor: "#2f4050", color: "#ffffff" }}
      >
        <Spin spinning={ldGetDeviceInfo}>
          {deviceInfo?.centris?.sysinfo?.result?.firmware_info?.info?.version
            ? deviceInfo.centris.sysinfo.result.firmware_info.info.version
            : t("failed_to_retrieve_data")}
        </Spin>
        </Form.Item>
    </Form>
  );
}

export default Info;
