import { VmsInstantMessageSourceModel } from "../../pages/vms/VmsInstantMessageSource";
import http from "../http.common";
import { InventoryStockModel } from "./InventoryStockModel";

const getInventoryStock = function (inventory_type_id: number, page: number, pageSize: number, barcode:string, inventory_sub_type_id:number, operation:string) {
    return http.post<any>(`/web/inventory/inventory/list?page=${page}&pageSize=${pageSize}`, {inventory_type_id, barcode, inventory_sub_type_id, operation });
};

const getInventoryMinioUrl = function (bucketName:string, objectName:string) {
    return http.post<any>(`/web/inventory/inventory/getminiourl`, {bucketName:bucketName, objectName:objectName});
  };

const getInventoryTypes = function () {
    return http.get<any>(`/web/inventory/inventory/inventorytypes`);
  };

  const getInventorySubTypes = function (type_id: number) {
    return http.get<any>(`/web/inventory/inventory/inventorysubtypes`, {
      params: { type_id }
    });
  };

const InventoryStockManagementService = {
    getInventoryStock,
    getInventoryTypes,
    getInventoryMinioUrl,
    getInventorySubTypes
};

export default InventoryStockManagementService;
