import { FormInstance, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IArchiceContentData, IContentData } from "../..";
import PaginationComponent from "../Pagination";

interface Props {
  contentData: IContentData | null;
  setContentData: React.Dispatch<React.SetStateAction<IArchiceContentData>>;
  archiveFilterForm: FormInstance<any>;
}

const PhaseData: React.FC<Props> = ({ contentData, archiveFilterForm }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);

  const columns = [
    {
      title: t("date"),
      dataIndex: "ts",
      key: "ts",
      render: (time: string) => (
        <>{ moment(time).utcOffset(localStorage.getItem("timezone")!).format("DD.MM.YY HH:mm") }</>
      )
    },
    {
      title: t("phase"),
      dataIndex: "phase_name",
      key: "phase_name",
    },
    {
      title: t("green_time"),
      dataIndex: "green_time",
      key: "green_time",
    },
    
    {
      title: `${t("direction")} (${t("vehicle_count")})`,
      dataIndex: "detector_datas",
      key: "detector_datas",
      render: (detector_datas: any[]) => {
        const x = detector_datas?.map((detector_data) => (
          `${detector_data?.name} (${detector_data?.vehicle_count})`
        ))?.join(" - ");
        return(
          <span>{ x }</span>
      )}
    },

    {
      title: `${t("Loop")} (${t("vehicle_count")})`,
      dataIndex: "loop_data",
      key: "loop_data",
      render: (detector_datas: any[]) => {
        const x = detector_datas?.map((detector_data) => (
          `${detector_data?.detectorId} (${detector_data?.vehicle_count})`
        ))?.join(" - ");
        return(
          <span>{ x }</span>
      )}
    },
    {
      title: t("min_avg_max"),
      key: "duration",
      render: (value: any, record: any) => {
        return (
          <>
            <>{record?.min_time} - {record?.mean_time} - {record?.max_time}</>
          </>
        );
      }
    },
  ];

  return (
    <>
      {contentData && (
        <>
          <Table
            dataSource={contentData?.rows}
            columns={columns}
            pagination={false}
          />
          <PaginationComponent
            pageNumber={contentData?.page ? contentData?.page : page}
            totalPages={contentData?.totalPages}
            setterMethod={(newPageNumber: number) => {
              setPage(newPageNumber);
              let fields = archiveFilterForm.getFieldsValue();
              archiveFilterForm.setFieldsValue({
                ...fields,
                page: newPageNumber,
              });

              archiveFilterForm.submit();
            }}
          />
        </>
      )}
    </>
  );
};

export default PhaseData;
