import styled from "styled-components";

interface Props {
  alignItems?: string;
  flexDirection?: string;
  justifyContent?: string;
  height?: string;
  width?: string;
  css?: any;
}
const Flex = styled.div<Props>`
  display: flex;
  align-items: ${(p) => p.alignItems};
  flex-direction: ${(p) => p.flexDirection};
  justify-content: ${(p) => p.justifyContent};
  width: ${(p) => p.width};
  height: ${(p) => p.height};
  ${(p) => p.css}
`;
export default Flex;
