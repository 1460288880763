import { Button, Input } from "antd";
import { t } from "i18next";
import React, { useState } from "react";
import { postComment } from "../../../../../data/task-management/TaskManagementService";

interface CommentListProps {
  taskId: any;
  commentList: any[];
  setCommentList: any;
  userList: any[];
}

export const CommentList = ({
  taskId,
  commentList,
  setCommentList,
  userList,
}: CommentListProps) => {
  const userLanguage = navigator.language || navigator.languages;

  const [commentedText, setCommentedText] = useState("");

  const handleChange = (e: any) => {
    setCommentedText(e.target.value);
  };

  const handleSubmit = async () => {
    await postComment(taskId, { comment: commentedText }).then((res: any) => {
      setCommentList((prev: any) => [...prev, res.data]);
      setCommentedText("");
    });
  };

  return (
    <>
      <div className="task-comment-container">
        {commentList.length > 0 ? (
          commentList.map((comment: any) => {
            const commentedUser = userList.find(
              (user) => parseInt(user.id) === parseInt(comment.user_id)
            );

            const renderDateSection = () => {
              const GMTOffset = 3 * 60 * 60 * 1000; // Convert hours to milliseconds
              const date = new Date(parseInt(comment.date));

              const adjustedDate = new Date(date.getTime() + GMTOffset);
              const commentDate = adjustedDate.toLocaleDateString(
                userLanguage,
                {
                  day: "2-digit",
                  month: "long",
                  hour: "numeric",
                  minute: "numeric",
                }
              );
              const today = new Date();
              // Calculate the difference in milliseconds
              const differenceInMs =
                today.getTime() - adjustedDate.getTime() + 10800000;

              // Convert milliseconds to days, hours, and minutes
              const differenceInDays = Math.floor(
                differenceInMs / (1000 * 60 * 60 * 24)
              );
              const differenceInHours = Math.floor(
                differenceInMs / (1000 * 60 * 60)
              );
              const differenceInMinutes = Math.floor(
                differenceInMs / (1000 * 60)
              );

              if (differenceInDays < 1) {
                if (differenceInHours < 1) {
                  // Display minutes ago if less than an hour
                  return (
                    <span>{`${differenceInMinutes} ${t("minutes_ago")}`}</span>
                  );
                } else {
                  // Display hours ago if less than a day
                  return (
                    <span>{`${differenceInHours} ${t("hours_ago")} `}</span>
                  );
                }
              } else if (differenceInDays < 30) {
                // Display days ago if less than a month
                return (
                  <span>
                    {commentDate}
                    {differenceInDays > 1 &&
                      `(${differenceInDays} ${t("days_ago")})`}
                  </span>
                );
              } else {
                // Display the full date if more than a month
                return <span>{commentDate}</span>;
              }
            };
            return (
              <span className="task-comment" key={comment.id}>
                {comment.comment}
                <span
                  style={{
                    display: "flex",
                    alignSelf: "flex-end",
                    fontSize: "13px",
                    color: "#666666",
                    gap: "0.2rem",
                  }}
                >
                  <span>{commentedUser?.name}</span>
                  <span style={{ color: "#a0a0a0" }}>•</span>
                  {renderDateSection()}
                </span>
              </span>
            );
          })
        ) : (
          <div
            style={{
              alignSelf: "center",
              color: "#666666",
            }}
          >
            {t("no_comment_found")}
          </div>
        )}
      </div>

      <div className="task-detail-input">
        <Input
          placeholder={t("write_comment")}
          value={commentedText}
          onChange={handleChange}
        />
        <Button type="primary" onClick={handleSubmit}>
          {t("submit")}
        </Button>
      </div>
    </>
  );
};
