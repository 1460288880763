import { useEffect, useState } from "react";
import { Marker, Tooltip } from "react-leaflet";
import { useMangoCarData } from "../../../../contexts/mango-car/MangoCarDataContext";
import { MangoCar } from "../../../../data/mango-car/MangoCar";
import L from "leaflet";
import { useTranslation } from "react-i18next";

function MangoCarLayer() {
  const { mangoCarData } = useMangoCarData();
  const { t, i18n } = useTranslation();

  const [markerData, setMarkerData] = useState<MangoCar[]>([]);

  useEffect(() => {
    setMarkerData(mangoCarData);
  }, [mangoCarData]);

  return (
    <>
      {markerData.length > 0 ? (
        <Marker
          position={{
            lat: parseFloat(markerData[0].latitude),
            lng: parseFloat(markerData[0].longitude),
          }}
          icon={
            markerData[0].status
              ? L.icon({
                  iconUrl: `https://api.geoapify.com/v1/icon/?type=circle&color=%2349ed24&icon=car&iconType=awesome&noShadow&noWhiteCircle&apiKey=8d652babbf3e4dcd80d34f0910084c7a`,
                  iconSize: [25, 32],
                  iconAnchor: [15.5, 42],
                })
              : L.icon({
                  iconUrl: `https://api.geoapify.com/v1/icon/?type=circle&color=%23ac9c9c&icon=car&iconType=awesome&noShadow&noWhiteCircle&apiKey=8d652babbf3e4dcd80d34f0910084c7a`,
                  iconSize: [25, 32],
                  iconAnchor: [15.5, 42],
                })
          }
        >
          <Tooltip>
            <div style={{ textAlign: "left" }}>
              <p>
                <strong>{t("travel_time") + " (" + t("dk") + ")"} :</strong>{" "}
                {markerData[0].timeTravel}
              </p>
              <p>
                <strong>{t("distance_travel")} :</strong>{" "}
                {markerData[0].distanceTravel}
              </p>
              <p>
                <strong>{t("battery_car")} :</strong> %
                {(markerData[0].batteryCar * 100).toFixed(0)}
              </p>
              <p>
                <strong>{t("battery_tablet")} :</strong> %
                {(markerData[0].batteryTablet * 100).toFixed(0)}
              </p>
              <p>
                <strong>{t("date_n_time")} :</strong>{" "}
                {markerData[0].date.toLocaleString(i18n.language)}
              </p>
            </div>
          </Tooltip>
        </Marker>
      ) : null}
    </>
  );
}

export default MangoCarLayer;
