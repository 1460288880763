import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";
import { useTranslation } from "react-i18next";
import moduleExpressions from "../../pages/junction/Statistic/components/Archive/components/Contents/moduleExpressions.json";
import errorExpressionsEN from "../../pages/junction/Statistic/components/Archive/components/Contents/errorExpressions.en.json";
import errorExpressionsTR from "../../pages/junction/Statistic/components/Archive/components/Contents/errorExpressions.tr.json";
import errorExpressionsID from "../../pages/junction/Statistic/components/Archive/components/Contents/errorExpressions.id.json";

export interface NotificationContextState {
  notificationList: NotificationError[];
  readNotification: (notificationIndex: number) => void;
}

const NotificationContext = createContext<NotificationContextState | undefined>(
  undefined
);

let errorExpressions = errorExpressionsEN;

export interface NotificationError {
  junctionId: number | string;
  errorId: number | string;
  module: number | string;
  description: number | string;
  parameters: string[];
  ts: number | Date;
  read: boolean;
}

//TODO handle notification
//const w=new WebSocket("ws://localhost:7080");
export function NotificationProvider({ children }: PropsWithChildren<{}>) {
  const { t, i18n } = useTranslation();
  errorExpressions =
    i18n.language == "tr"
      ? errorExpressionsTR
      : i18n.language == "id"
      ? errorExpressionsID
      : errorExpressionsEN;

  const [notificationList, setNotificationList] = useState<NotificationError[]>(
    []
  );

  const readNotification = useCallback(
    (notificationIndex: number) => {
      setNotificationList((prevNotificationList) => {
        return prevNotificationList.map((notification, index) => {
          if (index === notificationIndex) {
            return {
              ...notification,
              read: true,
            };
          } else {
            return notification;
          }
        });
      });
    },
    [notificationList]
  );

  useEffect(() => {
    socket.on("notification", (data: any) => {
      try {
        const socketNotification = data.errors.map((error: any) => ({
          junctionId: data?.junctionId,
          errorId: error.id,
          parameters: error.errorParameters,
          ts: data.ts,
          description: (errorExpressions as any)?.["" + +error.id],
          module: (moduleExpressions as any)?.["" + +error.module],
          read: false,
        }));

        setNotificationList((prev: NotificationError[]) => [
          ...socketNotification,
          ...prev,
        ]);
      } catch (err) {
        console.log("Socket Error (error monitor)", err);
      }
    });

    return () => {
      socket.off("notification");
    };
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notificationList: notificationList,
        readNotification: readNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    return {
      notificationList: [],
      readNotification: (notificationIndex: number) => {},
    };
    // throw new Error(
    //   "useNotification must be used within a NotificationContext"
    // );
  }
  return context;
}
