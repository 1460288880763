import React, { useEffect, useMemo, useState } from "react";
import {
  Switch,
  Table,
  Tag,
  Spin,
  Form,
  Select,
  Row,
  Col,
  DatePicker,
  Button,
  Radio,
  Input,
  message,
  Popover,
  List,
  Card,
  Checkbox,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import "./styles.css";

import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Junction } from "../../../../data/junction/Junction";
import moment, { Moment } from "moment";
import PastRecord from "../../../junction/Statistic/components/Archive/components/Contents/PastRecord";
import { fetchCedByMultiErrorTypeAndJunction } from "../../services/errorMonitorCed";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import errorExpressionsEN from "../../../junction/Statistic/components/Archive/components/Contents/errorExpressions.en.json";
import errorExpressionsTR from "../../../junction/Statistic/components/Archive/components/Contents/errorExpressions.tr.json";
import errorExpressionsID from "../../../junction/Statistic/components/Archive/components/Contents/errorExpressions.id.json";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { NotificationError, useNotification } from "../../../../contexts/information/NotificationContext";
import { useJunctionData } from "../../../../contexts/junction/JunctionDataContext";
let errorExpressions=errorExpressionsEN

const { REACT_APP_API_URL_DEV, REACT_APP_HOST_TYPE } =  process.env;
const baseURL =  REACT_APP_HOST_TYPE === "dev" ? REACT_APP_API_URL_DEV : window.location.origin;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

const defaultSelectedErrorIdList = ["all"];

const labelStyle: React.CSSProperties = {
  color: "#f3f3f3",
  fontWeight: 900,
};

const ErrorTable: React.FC = () => {

  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  errorExpressions=i18n.language=="tr"?errorExpressionsTR:(i18n.language=="id"?errorExpressionsID:errorExpressionsEN)

  const [selectedFiles, setSelectedFiles] = useState<string[]>([])

  // notificationList from ctx
  const notificationList: NotificationError[] = useNotification().notificationList;

  // junctions from ctx
  const junctions = useJunctionData().junctionList;

  const columns: ColumnsType<NotificationError> = useMemo(() => {
    return [
      {
        title:t("date"),
        dataIndex: "ts",
        key: "ts",
        render: (ts: number) => (
          <p>{moment(ts).utcOffset("GMT+03:00").format("DD/MM/YYYY HH:mm:ss")}</p>
        ),
      },
      {
        title: t("error_module"),
        dataIndex: "module",
        key: "module",
        render: (module) => <p>{module}</p>,
      },
    {
      title:t("error_description"),
      dataIndex: "description",
      key: "description",
      render: (description) => <p>{description}</p>,
    },

    {
      title:t("parameters"),
      dataIndex: "parameters",
      key: "parameters",
      render: (parameters: string[]) => <p>{parameters.join(", ")}</p>,
    },
    {
      title: "Junction",
      dataIndex: "junctionId",
      key: "junctionId",
      render: (junctionId) => {
        const junctionName = junctions
          ?.find((junction) => +junction?.id === +junctionId)
          ?.name?.toUpperCase();
        return junctionName ? (
          <Tag color={"tomato"}>{junctionName}</Tag>
        ) : (
          <Spin indicator={antIcon} />
        );
      },
    },
 
    ]
  }, [junctions]);

  const [currentPage, setCurrentPage] = useState<number>(1);

  // scrollActive state to follow/unfollow each incoming error data on last page
  const [scrollActive, setScrollActive] = useState<boolean>(true);

  // default page size (10)
  const [pageSize, setPageSize] = useState<number>(10);

  const [selectedErrorIdList, setSelectedErrorIdList] = useState<
    (number | string)[]
  >(defaultSelectedErrorIdList);

  const [selectedJunctionIdList, setSelectedJunctionIdList] = useState<
    (number | string)[]
  >(["all"]);

  // filter notifications up to their errorId
  const filteredNotificationList: NotificationError[] = useMemo(() => {
    const filteredByErrorId = selectedErrorIdList.includes("all")
      ? [...notificationList]
      : [...notificationList]?.filter((notification: NotificationError) =>
          selectedErrorIdList.includes("" + notification?.errorId)
        );

    const filteredByErrorAndJunctionId = selectedJunctionIdList.includes("all")
      ? filteredByErrorId
      : filteredByErrorId?.filter((notification: NotificationError) =>
          (selectedJunctionIdList as string[]).includes(
            "" + notification?.junctionId as string
          )
        );

    return filteredByErrorAndJunctionId;
  }, [notificationList, selectedJunctionIdList, selectedErrorIdList]);

  // calculate totalPages if notificationList changes
  const totalPages: number = useMemo(() => {
    return Math.ceil(filteredNotificationList?.length / pageSize);
  }, [filteredNotificationList, pageSize]);

  const jumpToLastPage = () => {
    if (scrollActive) {
      let totalPages = Math.ceil(filteredNotificationList?.length / pageSize);
      setCurrentPage(filteredNotificationList?.length > 0 ? totalPages : 1);
    }
  };

  useEffect(() => {
    jumpToLastPage();
  }, [filteredNotificationList, scrollActive]);

  const errorNumbers = (errorId: string) => {
    return errorId === "all"
      ? notificationList.length
      : notificationList.filter(
          (notification: NotificationError) => +notification?.errorId === +errorId
        )?.length;
  };

  const junctionErrorNumbers = (junctionId: number | "all") => {
    return junctionId === "all"
      ? notificationList.length
      : notificationList.filter(
          (notification: NotificationError) =>
            +notification?.junctionId === +junctionId
        )?.length;
  };

  enum EErrorScreenMode {
    Instant = 1,
    TimeBased = 2,
  }

  const [errorScreenMode, setErrorScreenMode] =
    useState<keyof typeof EErrorScreenMode>("Instant");

  const [timeBasedErrorContentData, setTimeBasedErrorContentData] =
    useState(null);

  // if formChanged, then query 1st page for entered input values
  const [formChanged, setFormChanged] = useState<boolean>(false);
  useEffect(() => {
    if (!formChanged) {
      setFormChanged(true);
    }

  }, [selectedErrorIdList, selectedJunctionIdList]);

  const downloadClick = () => {
    selectedFiles.map(file=> {
      fetch(`${baseURL}${'/file/errorMonitor/'+file}`).then((response) => {
        if(response.ok){
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = file;
            a.click();
          });
        }else {
          message.error(t("notfound")+" : "+file)
        }
      });
    })
  }

  const fileChange = (e : CheckboxChangeEvent) => {
    if(e.target.checked){
      setSelectedFiles([...selectedFiles, e.target.value])
    }else{
      setSelectedFiles(selectedFiles.filter(i=>i !== e.target.value))
    }
  }

  const files = [
    "ISSD-Maestro_ErrorLogs.pdf"
  ]

  const downloadContent = () => (
    <Card
      style={{ margin: "-20px" }} 
      title={t("documents")}
    >
      <List style={{ marginTop: "-20px" }}>
        {
          files.map((file, index) => (
            <List.Item key={index}>
              <Checkbox
                onChange={(e)=>fileChange(e)} 
                value={file}
              >
                {file}
              </Checkbox>
            </List.Item>
          ))
        }
      </List>
      <Button
        type= "primary"
        style= {{ marginTop: "20px" }}
        onClick= {downloadClick}
      >
        {t("download")}
      </Button>
    </Card>
  )

  return (
    <div style={{ maxHeight: "100%", overflowY: "scroll" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 12,
        }}
      >
        <div style={{ display: "inline-flex", padding: "10px 12px", gap: 5 }}>
          <span style={{ color: "#84858a", fontWeight: 900 }}>Auto Scroll</span>
          <Switch
            checked={scrollActive}
            onChange={(switchBool: boolean) => {
              setScrollActive(switchBool);
            }}
          />
        </div>
      </div>

      <Radio.Group
        onChange={(e) => {
          setErrorScreenMode(
            EErrorScreenMode[
              e.target
                .value as typeof EErrorScreenMode[keyof typeof EErrorScreenMode]
            ] as keyof typeof EErrorScreenMode
          );
        }}
        value={EErrorScreenMode[errorScreenMode]}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div>
          <Radio.Button value={1}>Instant</Radio.Button>
          <Radio.Button value={2}>Time-based</Radio.Button>
        </div>
        <Popover
          placement="topLeft"
          content={t("downloadJunctionDocuments")}
        >
          <Popover
            trigger= "click"
            placement= "bottomLeft"
            content= {downloadContent}
          >
            <Button
              style={{ marginRight: "10px"}}
            >
              <DownloadOutlined style={{ fontSize: "20px", color: "green" }} />
            </Button>
          </Popover>
        </Popover>
      </Radio.Group>

      <Form
        form={form}
        onFinish={async (formValues: any) => {
          const startTimeDateFormat: Moment = formValues?.["time-range"]?.[0];
          const endTimeDateFormat: Moment = formValues?.["time-range"]?.[1];

          if (!(startTimeDateFormat && endTimeDateFormat)) {
            message.warn("Start and end time inputs are not valid");
            return;
          }

          const output = {
            errorTypes: selectedErrorIdList,
            junctionIds: selectedJunctionIdList,
            startTime: moment(startTimeDateFormat)?.unix(),
            endTime: moment(endTimeDateFormat)?.unix(),
            page: formChanged
              ? 1
              : formValues?.page && typeof formValues?.page === "number"
              ? formValues?.page
              : 1,
            // page:
            //   formValues?.page && typeof formValues?.page === "number"
            //     ? formValues?.page
            //     : 1,
          };
          setTimeBasedErrorContentData(null);
          fetchCedByMultiErrorTypeAndJunction(output).then(
            (res: AxiosResponse) => {
              setTimeBasedErrorContentData(res.data);
              if (formChanged) {
                setFormChanged(false);
              }
            }
          );
        }}
        onFieldsChange={(changedFields, _allFields) => {
          /**
           * formItem has name attribute as 'page' change does not exist on changedFields array
           * thus, set formChanged as true if there is any
           * and query for 1st page on onFinish method of form
           */

          if (changedFields.length > 0) {
            if (!formChanged) {
              setFormChanged(true);
          
            }
          }
        }}
      >
        <Form.Item
          name="page"
          style={{
            visibility: "hidden",
          }}
        >
          <Input disabled />
        </Form.Item>
        <Row>
          <Col lg={12} sm={24}>
            <Form.Item
              label="Errors"
              labelCol={{ span: 24 }}
              style={labelStyle}
            >
              <Select
                value={selectedErrorIdList}
                onChange={(val) => {
                  const lastValItem = val[val.length - 1];

                  // is item inserted or cleared
                  const itemInsert: boolean =
                    val.length > selectedErrorIdList.length;

                  // if descreasing filter number, then equalize to selectedErrorIdList
                  if (!itemInsert) {
                    return setSelectedErrorIdList(val);
                  }

                  // clear other filters if any filter is inserted other than "all"
                  if (lastValItem === "all") {
                    setSelectedErrorIdList(defaultSelectedErrorIdList);
                  } else {
                    if (selectedErrorIdList.includes("all")) {
                      setSelectedErrorIdList([lastValItem]);
                    } else {
                      setSelectedErrorIdList((prev: (number | string)[]) => {
                        return [...prev, lastValItem];
                      });
                    }
                  }
                }}
                filterOption={(input, option) => {
                  return (
                    option?.props?.key
                      ?.toLowerCase()
                      ?.indexOf(input.toLowerCase()) >= 0
                  );
                }}
                mode="multiple"
                placeholder="Select error type to filter"
              >
                <Option value="all" key={`all-errors-${"all"}`}>
                  {t("all") +
                    (errorScreenMode === "Instant"
                      ? ` (${errorNumbers("all")})`
                      : "")}
                </Option>
                {Object.entries(errorExpressions).map(
                  (errorType: any, index: number) => (
                    <Option
                      value={errorType?.[0]}
                      key={`error-type-${index}-${errorType?.[1]}`}
                    >
                      {errorScreenMode === "Instant"
                        ? `${errorType?.[1]} (${errorNumbers(errorType?.[0])})`
                        : errorType?.[1]}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} sm={24}>
            <Form.Item
              label="Junctions"
              labelCol={{ span: 24 }}
              style={labelStyle}
            >
              <Select
                showSearch
                value={selectedJunctionIdList}
                onChange={(val) => {
                  const lastValItem = val[val.length - 1];

                  // is item inserted or cleared
                  const itemInsert: boolean =
                    val.length > selectedJunctionIdList.length;

                  // if descreasing filter number, then equalize to selectedJunctionIdList
                  if (!itemInsert) {
                    return setSelectedJunctionIdList(val);
                  }

                  // clear other filters if any filter is inserted other than "all"
                  if (lastValItem === "all") {
                    setSelectedJunctionIdList(
                      defaultSelectedErrorIdList as ("all" | number)[]
                    );
                  } else {
                    if (selectedJunctionIdList.includes("all")) {
                      setSelectedJunctionIdList([lastValItem]);
                    } else {
                      setSelectedJunctionIdList((prev: (number | string)[]) => {
                        return [...prev, lastValItem];
                      });
                    }
                  }
                }}
                mode="multiple"
                filterOption={(input, option) => {
                  return (
                    option?.props?.key
                      ?.toLowerCase()
                      ?.indexOf(input.toLowerCase()) >= 0
                  );
                }}
                placeholder="Select junction to filter"
              >
                <Option value="all" key={`all-junctions-${"all"}`}>
                  {t("all") + (errorScreenMode === "Instant"
                    ? ` (${junctionErrorNumbers("all")})`
                    : "")}
                </Option>
                {junctions?.map((junction: Junction, index) => (
                  <Option
                    value={junction?.id}
                    key={`junction-${index}-${junction?.name}`}
                  >
                    {errorScreenMode === "Instant"
                      ? `${junction?.name} (${junctionErrorNumbers(
                          junction.id
                        )})`
                      : junction?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {errorScreenMode === "TimeBased" && (
            <>
              <Col lg={12} sm={24}>
                <Form.Item
                  name="time-range"
                  label="Timestamp"
                  labelCol={{ span: 24 }}
                  style={labelStyle}
                >
                  <DatePicker.RangePicker format="YYYY-MM-DD HH:mm" showTime />
                </Form.Item>
              </Col>
              <Form.Item label=" " labelCol={{ span: 24 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </>
          )}
        </Row>
      </Form>

      {errorScreenMode === "Instant" && (
        <Table
          className="error-table"
          columns={columns}
          dataSource={filteredNotificationList?.reverse()}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: filteredNotificationList.length,
            // total: totalPages * pageSize,
            onChange: (page: number, _pageSize: number) => {
              // condition is necassary to avoid deactivating scrollActive when page size changed
              if (_pageSize === pageSize) {
                // if user changed the page, then deactivate data following on last page
                setScrollActive(false);
                setCurrentPage(page);
              }
            },
            showSizeChanger: true,
            // onShowSizeChange property triggers onChange
            onShowSizeChange: (_current: number, size: number) => {
              setPageSize(size);
            },
            // position: ["topRight"],
          }}
        />
      )}

      {errorScreenMode === "TimeBased" && timeBasedErrorContentData && (
        <div className="error-table">
          <PastRecord
            contentData={timeBasedErrorContentData}
            archiveFilterForm={form}
          />
        </div>
      )}
    </div>
  );
};

export default ErrorTable;
