import L from "leaflet";

export const VmsStatusInfo = {
  0: {
    text: "Passive",
    marker: L.icon({
      iconUrl: "/static/vms/vms.svg",
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
  },
  1: {
    text: "Partially Active",
    marker: L.icon({
      iconUrl: `https://api.geoapify.com/v1/icon/?type=circle&color=%23d3d3dc&iconType=awesome&iconSize=small&text=Vms&textSize=small&noWhiteCircle&scaleFactor=2&apiKey=8085c1a65659413285815a9260ae4147`,
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
  },
  2: {
    text: "Active",
    marker: L.icon({
      iconUrl: `https://api.geoapify.com/v1/icon/?type=circle&color=%231214ee&iconType=awesome&iconSize=small&text=Vms&textSize=small&noWhiteCircle&scaleFactor=2&apiKey=8085c1a65659413285815a9260ae4147`,
      iconSize: [37.5, 37.5],
      iconAnchor: [15.5, 42],
      popupAnchor: [0, -45],
    }),
  },
  // 4: {
  //   text: "L Type",
  //   marker: L.icon({
  //     iconUrl: `https://api.geoapify.com/v1/icon/?type=circle&color=%231a1abd&iconType=awesome&iconSize=small&text=L&textSize=small&noWhiteCircle&scaleFactor=2&apiKey=8085c1a65659413285815a9260ae4147`,
  //     iconSize: [37.5,37.5],
  //     iconAnchor: [15.5, 42],
  //     popupAnchor: [0, -45],
  //   }),
  // },
  // 5: {
  //   text: "N Type",
  //   marker: L.icon({
  //     iconUrl: `https://api.geoapify.com/v1/icon/?type=circle&color=%231a1abd&iconType=awesome&iconSize=small&text=N&textSize=small&noWhiteCircle&scaleFactor=2&apiKey=8085c1a65659413285815a9260ae4147`,
  //     iconSize: [37.5,37.5],
  //     iconAnchor: [15.5, 42],
  //     popupAnchor: [0, -45],
  //   }),
  // },
};
