import { Button, Checkbox } from "antd";
import { useContext } from "react";
import { days } from "../../constants";
import { MultiModeSchedularContext } from "../../context/MultiModeSchedularContext";

const DayFilter = () => {
  const schedularContextValues = useContext(MultiModeSchedularContext);
  const [selectedDays, setSelectedDays] =
    schedularContextValues?.selectedDays;
  return (
    <>
      <h2>DAY FILTER</h2>
      <Checkbox.Group
        options={days}
        value={selectedDays}
        onChange={(val: any) => {
          setSelectedDays(val);
        }}
      />
      <Button
        onClick={() => {
          if (selectedDays.length > 0) {
            setSelectedDays([]);
          }
        }}
        type="primary"
      >
        Clear
      </Button>
      <Button
      style={{marginLeft:'10px'}}
        onClick={() => {
          if (selectedDays.length < days.length) {
            setSelectedDays(days);
          }
        }}
        type="primary"
      >
        Select All
      </Button>
    </>
  );
};

export default DayFilter;
