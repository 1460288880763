import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import OrganizationService from "../../data/organization/OrganizationService";
import { AuthService } from "@icms/ui-components";

interface UserOrganization {
  value: number;
  label: string;
  logo: string;
}

interface AuthProps {
  allOrganizations: UserOrganization[];
  filteredOrganizations: number[];
  setOrganizations: (orgList: number[]) => void;
}

const AuthContext = createContext<AuthProps | undefined>(undefined);

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [allOrganizations, setAllOrganizations] = useState<UserOrganization[]>(
    []
  );
  const [filteredOrganizations, setFilteredOrganizations] = useState<number[]>(
    []
  );

  const getOrganizations = () => {
    let user = AuthService.getUser();
    if (user && user.user_info?.org_id_list.length > 0) {
      OrganizationService.getOrganizations()
        .then((res) => {
          let filteredOrgs = res.data
            .filter((item) => user.user_info.org_id_list.includes(item.id))
            .map((item) => {
              return {
                value: item.id,
                label: item.name,
                logo: item.logo,
              };
            });
          setFilteredOrganizations(filteredOrgs.map((item) => item.value));
          setAllOrganizations(filteredOrgs);
        })
        .catch((err) => console.log(err));
    }
  };

  const setOrganizations = (orgList: number[]) => {
    const filteredOrgList = allOrganizations.filter((item) =>
      orgList.includes(item.value)
    );
    setFilteredOrganizations(filteredOrgList.map((item) => item.value));
  };

  useEffect(() => {
    getOrganizations();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        allOrganizations: allOrganizations,
        filteredOrganizations: filteredOrganizations,
        setOrganizations: setOrganizations,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    return {
      allOrganizations: [],
      filteredOrganizations: [],
      setOrganizations: (orgList: number[]) => {},
    };
    //throw new Error("useAuth must be used within a AuthContext");
  }
  return context;
}
