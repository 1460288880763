import { Button, Input, InputNumber, message, Typography } from "antd";
import { useEffect, useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { usePhaseData,durationDataModel } from "../../../contexts/junction/PhaseDataContext";
import JunctionPhaseService from "../../../data/junction-phase/JunctionPhaseService";
import { Junction } from "../../../data/junction/Junction";
import { t } from "i18next";


interface Props {
  selectedJunction:Junction|undefined
}


function VIPPhaseIsbak(props: Props) {
  const { selectedJunction } = props;
  const [isVisible, setIsVisible] = useState(false);

  const [second, setSecond] = useState(60);
  useEffect(() => {
    if(selectedJunction?.kkc_type_id==6||selectedJunction?.kkc_type_id==60){
      setIsVisible(true);
    }
  }, [selectedJunction]);

  const lockPhase = async () => {
    try {
      const result=await JunctionPhaseService.lockPhase({
        junction_id:selectedJunction?.id,
        phase_id:1,
        output_number:1,
        policeModeDuration:second
      });
      if(result?.data?.result==true){
        message.success(t("success"));
      } else {
        message.error(
          t("failed")
        );
      }
    } catch (error) {
      console.log(error)
    }
  };

  const finPhase =async () => {
    try {
      const result=await JunctionPhaseService.finishPhase({
        junction_id:selectedJunction?.id,
        phase_id:1,
        output_number:1,
      });
      if(result?.data?.result==true){
        message.success(t("success"));
      } else {
        message.error(
          t("failed")
        );
      }
    } catch (error) {
      console.log(error)
    }
  };
  if(!isVisible)
    return null;
  return (
    
    <div
      style={{
        position: "absolute",
        top: "-4px",
        left: "40%",
        width: "170px",
        height:  "50px" ,
        maxHeight: "50px",
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "rgba(50, 50, 50, 0.7)",
        borderRadius: "5px",
        padding: "10px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        overflow: "hidden",
        transition: "height 0.3s ease",
      }}
    >
    <div

      >
        <InputNumber  value={second} onChange={(value) => setSecond(value as number)} type="number" step={1} style={{width:'60px'}}/>
        <Button style={{marginLeft:'3px', borderRadius: "20px",backgroundColor:'#74BF04',borderColor:'#74BF04'}} onClick={lockPhase}>
        L
        </Button>
        <Button style={{marginLeft:'3px',borderRadius: "20px",backgroundColor:'#D90D1E',borderColor:'#D90D1E' }} onClick={finPhase}>
        F
        </Button>
        
    </div>
    </div>
  );
}

export default VIPPhaseIsbak;
