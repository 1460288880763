import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Junction } from "../../data/junction/Junction";
import JunctionService from "../../data/junction/JunctionService";
import { useAuth } from "../auth/AuthContext";
import moment from "moment";
import { fetchActivePlan } from "../../data/signal-plan/SignalPlanService";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";

export interface JunctionDataContextState {
  junctionList: Junction[];
  activePlan: any;
  getActivePlan: (junction: Junction) => void;
  addJunction: (data: Junction) => Promise<Junction>;
  editJunction: (data: Junction) => Promise<Junction>;
  removeJunction: (data: Junction) => Promise<Junction>;
  updateAllJunctions: () => void;
}

const JunctionDataContext = createContext<JunctionDataContextState | undefined>(
  undefined
);

export function JunctionDataProvider({ children }: PropsWithChildren<{}>) {
  const { filteredOrganizations } = useAuth();
  const [allJunctions, setAllJunctions] = useState<Junction[]>([]);
  const [filteredJunctions, setFilteredJunctions] = useState<Junction[]>([]);
  const [activePlan, setActivePlan] = useState<any>();
  const { hasAccessComponent,componentPermissions } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  const getJunctions = async () => {
    await JunctionService.getJunctions()
      .then((res) => {
        setAllJunctions(res.data);
        setFilteredJunctions(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getActivePlan = async (junction: Junction) => {
    let requestTime =
      moment(new Date()).utcOffset(localStorage.getItem("timezone")!).unix() *
      1000;
    await fetchActivePlan({
      juncId: +junction.id,
      juncModelId: junction.junction_model_id,
      requestTime: requestTime,
    }).then((res) => {
      setActivePlan(res.data);
    });
  };

  const updateAllJunctions = () => {
    getJunctions();
  };

  const addJunction = (junction: Junction) => {
    return new Promise<Junction>((resolve, reject) => {
      JunctionService.insertJunction(junction)
        .then((r: any) => {
          setFilteredJunctions((prev) => [...prev, ...r.data]);
          resolve(r.data);
        })
        .catch((err) => reject(err));
    });
  };

  const editJunction = (junction: Junction) => {
    return new Promise<Junction>((resolve, reject) => {
      JunctionService.updateJunction(junction)
        .then((r: any) => {
          const newArr = filteredJunctions.map((obj) => {
            if (obj.id === r.data[0].id) {
              return { ...obj, ...r.data[0] };
            }
            return obj;
          });
          setFilteredJunctions(newArr);
          resolve(r.data);
        })
        .catch((err) => reject(err));
    });
  };

  const removeJunction = (junction: Junction) => {
    return new Promise<Junction>((resolve, reject) => {
      JunctionService.deleteJunction(junction)
        .then((r: any) => {
          setFilteredJunctions(
            filteredJunctions.filter((item) => item.id !== r.data[0].id)
          );
          resolve(r.data);
        })
        .catch((err) => reject(err));
    });
  };

  useEffect(() => {
    if (hasAccessComponent("Junction Module-READ")) {
      getJunctions();
    }
  }, [componentPermissions]);

  useEffect(() => {
    setFilteredJunctions(
      allJunctions.filter((item) => filteredOrganizations.includes(item.org_id))
    );
  }, [filteredOrganizations]);

  return hasAccessComponent("Junction Module-READ") ? (
    <JunctionDataContext.Provider
      value={{
        junctionList: filteredJunctions,
        addJunction: addJunction,
        editJunction: editJunction,
        removeJunction: removeJunction,
        updateAllJunctions: updateAllJunctions,
        activePlan: activePlan,
        getActivePlan: getActivePlan,
      }}
    >
      {children}
    </JunctionDataContext.Provider>
  ) : (
    <>{children}</>
  );
}

export function useJunctionData() {
  const context = useContext(JunctionDataContext);
  if (context === undefined) {
    return {
      junctionList: [],
      activePlan: null,
      getActivePlan: (junction: Junction) => {},
      addJunction: (data: Junction) => {
        return new Promise<Junction>((resolve, reject) => {});
      },
      editJunction: (data: Junction) => {
        return new Promise<Junction>((resolve, reject) => {});
      },
      removeJunction: (data: Junction) => {
        return new Promise<Junction>((resolve, reject) => {});
      },
      updateAllJunctions: () => {},
    };
    // throw new Error(
    //   "useJunctionData must be used within a JunctionDataContext"
    // );
  }
  return context;
}
