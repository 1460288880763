import { useContext } from "react";
import { Row, Col } from "antd";
import {
  CopyOutlined,
  DingtalkOutlined,
  EditOutlined,
} from "@ant-design/icons";

import "./table-title.css";

import planFormEpicFields from "../CreateNewPlan/formFieldStructs/planFormEpicFields";

// context
import { PlanContext } from "../../context/PlanContext";

const {
  name: nameField,
  greenWaveMode: greenWaveModeField,
  cycleTime: cycleTimeField,
  offsetDuration: offsetDurationField,
  planType: planTypeField,
  minTS: minTSField,
  maxTS: maxTSField,
} = planFormEpicFields;

interface Props {
  cardInfo: any;
  planColor: string;
  editOrCopyPlan: any;
}

const TableTitle: React.FC<Props> = ({
  cardInfo,
  planColor,
  editOrCopyPlan,
}) => {
  const planContextValues = useContext(PlanContext);
  const [locked] = planContextValues.locked;

  // criteria
  const planName = cardInfo[nameField.fieldName];
  const isGreenWave = cardInfo[greenWaveModeField.fieldName];
  const isFlashPlan = cardInfo[planTypeField.fieldName] === "Flash";

  // situational informations
  const minTS = cardInfo[minTSField.fieldName];
  const maxTS = cardInfo[maxTSField.fieldName];
  const cycleTime = cardInfo[cycleTimeField.fieldName];
  const offsetDuration = cardInfo[offsetDurationField.fieldName];

  return (
    <>
      <div
        style={{
          backgroundColor: planColor,
        }}
        className="table-name-content"
      >
        {planName}
        {!locked && !isFlashPlan && (
          <div className="table-name-content__plan-editor">
            <CopyOutlined onClick={() => editOrCopyPlan("copy")} />
            <EditOutlined onClick={() => editOrCopyPlan("edit")} />
          </div>
        )}
      </div>
      {!isFlashPlan && (
        <div>
          {isGreenWave && (
            <Row className="green-wave-info-box">
              <Col span={8}>
                <span className="green-wave-express">
                  <DingtalkOutlined />
                </span>
              </Col>
              <Col span={8}>
                <span className="green-wave-info-box-key">TS:</span>
                <span>{cycleTime}</span>
              </Col>
              <Col span={8}>
                <span className="green-wave-info-box-key">OS:</span>
                <span>{offsetDuration}</span>
              </Col>
            </Row>
          )}

          {!isGreenWave && (
            <Row className="gray-ts-info-box">
              <Col span={12}>
                <span className="gray-ts-info-box-key">MIN-TS:</span>
                <span>{minTS}</span>
              </Col>
              <Col span={12}>
                <span className="gray-ts-info-box-key">MAX-TS:</span>
                <span>{maxTS}</span>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

export default TableTitle;
