import React, { useCallback, useEffect, useRef, useState } from "react";
import { Map as LMap } from "leaflet";
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  message,
} from "antd";
import { Route } from "../../../data/bluesis/Route";
import { Vector } from "../../../data/bluesis/Vector";
import L from "leaflet";
import { Bluesis } from "../../../data/bluesis/Bluesis";
import moment from "moment";
import {
  BarChartOutlined,
  EditFilled,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import BluesisAnalysis from "./BluesisAnalysis";
import { t } from "i18next";
import BluesisService from "../../../data/bluesis/BluesisService";
import { BluesisStatusInfo } from "../../map/map-constants/module-status/BluesisStatusInfo";
import { MapContainer } from "react-leaflet";
import MapTileControl from "../../map/controls/bottom-right-control/MapTileControl";

interface Props {
  routes: Route[];
  vectors: Vector[];
  visible: boolean;
  setVisible: Function;
  bluesisList: Bluesis[];
  selectedBluesis: Bluesis | undefined;
  setSelectedBluesis?: Function;
}

const headStyle = {
  background: "#425260",
  color: "white",
};

const bodyStyle = {
  background: "white",
};

function RouteDetails(props: Props) {
  const {
    routes,
    vectors,
    visible,
    setVisible,
    bluesisList,
    selectedBluesis,
    setSelectedBluesis,
  } = props;

  const leafletMapRef = useRef<LMap>();
  const [selectOptions, setSelectOptions] = useState<
    { value: number; label: React.ReactNode }[]
  >([]);
  const [dataSource, setDataSource] = useState<Vector[]>([]);
  const [polylineLayer, setPolylineLayer] = useState<L.FeatureGroup>(
    new L.FeatureGroup()
  );
  const [defaultValue, setDefaultValue] = useState<number>();
  const [openAnalysisDrawer, setOpenAnalysisDrawer] = useState<boolean>(false);
  const [analysisType, setAnalysisType] = useState<number>();
  const [route, setRoute] = useState<Route>();
  const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false);
  const [map, setMap] = useState<LMap>();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const [updateRouteForm] = Form.useForm();
  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: t("origin_device"),
      dataIndex: "origin_device_id",
      key: "origin_device_id",
      width: 100,
    },
    {
      title: t("destination_device"),
      dataIndex: "destination_device_id",
      key: "destination_device_id",
      width: 100,
    },
    {
      title: t("archive_travel_time"),
      dataIndex: "archive_travel_time",
      key: "archive_travel_time",
      width: 100,
      render: (text: any, record: Vector) => {
        return convertSecondsToMinutes(record.archive_travel_time);
      },
    },
    {
      title: t("travel_time"),
      dataIndex: "travel_time",
      key: "travel_time",
      width: 100,
      render: (text: any, record: Vector) => {
        return convertSecondsToMinutes(record.travel_time);
      },
    },
    {
      title: t("distance"),
      dataIndex: "distance",
      key: "distance",
      width: 100,
      render: (text: any, record: Vector) => {
        return record.distance.toString() + " m";
      },
    },
    {
      title: t("speed"),
      dataIndex: "speed",
      key: "speed",
      width: 100,
      render: (text: any, record: Vector) => {
        let speed = record.travel_time
          ? Math.round((record.distance / record.travel_time) * 3.6)
          : 0;
        return speed.toString() + " km/s";
      },
    },
    {
      title: t("actions"),
      key: "action",
      width: 100,
      render: () => {
        return (
          <>
            <Tooltip title={t("mac_count")} color={headStyle.background}>
              <Button
                onClick={() => openAnalysis(2)}
                style={{ border: "none" }}
                icon={
                  <BarChartOutlined style={{ color: headStyle.background }} />
                }
              ></Button>
            </Tooltip>
            {/* <Tooltip title={t("edit")} color={headStyle.background}>
              <Button
                style={{ border: "none" }}
                icon={<EditFilled style={{ color: headStyle.background }} />}
              ></Button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let opt = routes.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setSelectOptions(opt);
  }, [routes]);

  useEffect(() => {
    if (selectOptions.length > 0) {
      setDefaultValue(selectOptions[0].value);
      changeRoute(selectOptions[0].value);
    }
  }, [selectOptions]);

  useEffect(() => {
    if (analysisType) {
      setOpenAnalysisDrawer(true);
    }
  }, [analysisType]);

  useEffect(() => {
    if (leafletMapRef.current) {
      leafletMapRef.current?.invalidateSize();
    }
  }, [isFullscreen, visible]);

  const onMapCreate = useCallback(
    (map: LMap) => {
      leafletMapRef.current = map;
      setMap(map);
    },
    [selectedBluesis]
  );

  const onClose = () => {
    setSelectedBluesis?.(undefined);
    setOpenAnalysisDrawer(false);
    setVisible(false);
    setIsFullscreen(false);
  };

  const convertSecondsToMinutes = (time?: number) => {
    if (!time) {
      return "";
    }
    let val = moment.utc(time * 1000).format("mm:ss");
    return val.toString() + ` ${t("dk")}`;
  };

  const handleFeatureClick = (e: any, feature: any) => {
    let myStyle = {
      weight: 5,
      fillColor: "white",
      color: "#85C6F9",
    };
    polylineLayer.eachLayer((layer: any) => {
      layer.setStyle?.(myStyle);
      if (feature.options.uniqId == layer.options?.uniqId) {
        layer?.setStyle?.({
          weight: 7,
          color: "#FF5733",
          className: "leaflet-polyline-glow-effect",
        });
      }
    });
  };

  const changeRoute = (value: number) => {
    setDefaultValue(value);
    polylineLayer.clearLayers();

    let filterVector = vectors.filter((item) => item.route_id === value);
    let filteredBluesisId: number[] = [];

    filterVector.forEach((item) => {
      filteredBluesisId.push(item.origin_device_id, item.destination_device_id);
    });
    let uniqueFilteredBluesis = Array.from(new Set(filteredBluesisId));
    let filteredBluesis = bluesisList.filter((item) =>
      uniqueFilteredBluesis.includes(item.device_id)
    );

    for (let index = 0; index < filteredBluesis.length; index++) {
      let bluesis = filteredBluesis[index];
      const marker = L.marker([bluesis.latitude, bluesis.longitude], {
        icon: BluesisStatusInfo[bluesis.device_type].marker,
      }).bindTooltip(bluesis.device_id + " | " + bluesis.name);
      if (bluesis.id == selectedBluesis?.id) {
        L.circle([bluesis.latitude, bluesis.longitude], {
          color: "black",
          fillColor: "none",
          dashArray: "8, 8",
          weight: 2,
          radius: 50,
        }).addTo(polylineLayer);
      }

      polylineLayer.addLayer(marker);
    }
    for (let index = 0; index < filterVector.length; index++) {
      let vector = L.geoJSON(JSON.parse(filterVector[index].geom), {
        style: {
          weight: 5,
          color: "#85C6F9",
        },
        uniqId: filterVector[index].id,
      } as any).bindTooltip(
        `<p style="margin-bottom:0px;">${
          filterVector[index].name
        }</p><p style="margin-bottom:0px;">${
          t("travel_time") +
          " " +
          convertSecondsToMinutes(filterVector[index].travel_time)
        }</p>`,
        { permanent: false, sticky: true }
      );
      vector.on("click", (e) => {
        handleFeatureClick(e, vector);
      });
      polylineLayer.addLayer(vector);
    }
    leafletMapRef.current?.addLayer(polylineLayer);

    if (polylineLayer?.getBounds()?.isValid()) {
      leafletMapRef.current?.fitBounds(polylineLayer?.getBounds());
    }

    setDataSource(filterVector);
    setRoute(routes.find((item) => item.id === value));
  };

  const openAnalysis = (analysisType: number) => {
    setAnalysisType(analysisType);
  };

  const openUpdateRouteForm = () => {
    updateRouteForm.setFields([
      {
        name: "route_name",
        value: route?.name,
      },
    ]);
    setUpdateModalVisible(true);
  };

  const closeUpdateRouteForm = () => {
    updateRouteForm.resetFields();
    setUpdateModalVisible(false);
  };

  const updateRoute = async () => {
    let formValue = updateRouteForm.getFieldsValue();
    if (route?.id) {
      await BluesisService.updateRoute(route.id, formValue.route_name)
        .then((res) => {
          message.success(t("updated_route"));
        })
        .catch((err) => {
          message.warn("warn_updated_route");
        });
    }
    setUpdateModalVisible(false);
  };

  const deleteRoute = async () => {
    if (route?.id) {
      await BluesisService.deleteRoute(route.id)
        .then((res) => {
          message.success(t("deleted_route"));
        })
        .catch((err) => {
          message.warn("warn_deleteed_route");
        });
    }
    closeUpdateRouteForm();
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <>
      <Drawer
        className="bluesisDrawer"
        style={{ top: isFullscreen ? "0" : "10%" }}
        headerStyle={headStyle}
        bodyStyle={bodyStyle}
        size="large"
        mask={false}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {t("routes_and_vectors")}
            <Button
              onClick={toggleFullscreen}
              style={{ marginLeft: 16 }}
              icon={
                isFullscreen ? (
                  <FullscreenExitOutlined />
                ) : (
                  <FullscreenOutlined />
                )
              }
            >
              {isFullscreen ? t("close_full_screen") : t("open_full_screen")}
            </Button>
          </div>
        }
        placement="right"
        destroyOnClose={true}
        onClose={onClose}
        width={isFullscreen ? "100%" : "40%"}
        height={isFullscreen ? "100vh" : "auto"}
        visible={visible}
      >
        {defaultValue && (
          <>
            <Select
              showSearch
              optionFilterProp="label"
              size="large"
              value={defaultValue}
              style={{ width: "100%" }}
              className="routesSelectOption"
              onChange={changeRoute}
              options={selectOptions}
            />
            <Card
              style={{
                width: "100%",
                marginTop: "15px",
                ...headStyle,
              }}
            >
              <Row>
                <Col span={16} style={{ paddingTop: "3px", fontSize: "16px" }}>
                  {`${t("route")} ${t("travel_time")}`}:
                  {convertSecondsToMinutes(
                    routes.find((item) => item.id === defaultValue)?.travel_time
                  )}
                </Col>
                <Col span={1} offset={6}>
                  <Tooltip
                    title={`${t("travel_time")} ${t("analysis")}}`}
                    color={headStyle.background}
                  >
                    <Button
                      onClick={() => {
                        openAnalysis(1);
                      }}
                      style={{
                        background: headStyle.background,
                        border: "none",
                        marginRight: "5px",
                      }}
                      icon={
                        <BarChartOutlined style={{ color: headStyle.color }} />
                      }
                    ></Button>
                  </Tooltip>
                </Col>
                <Col span={1}>
                  <Tooltip title={t("edit")} color={headStyle.background}>
                    <Button
                      style={{
                        background: headStyle.background,
                        border: "none",
                      }}
                      onClick={openUpdateRouteForm}
                      icon={<EditFilled style={{ color: headStyle.color }} />}
                    ></Button>
                  </Tooltip>
                </Col>
              </Row>
            </Card>
          </>
        )}

        <MapContainer
          id="bluesisMap"
          style={{ height: "250px", width: "100%", marginTop: "15px" }}
          key={"bluesisMap"}
          center={[selectedBluesis?.latitude!, selectedBluesis?.longitude!]}
          zoom={18}
          scrollWheelZoom={true}
          whenCreated={onMapCreate}
        >
          <MapTileControl />
        </MapContainer>
        {dataSource && (
          <Table
            rowKey={"name"}
            bordered
            className="vectorsTable"
            pagination={false}
            scroll={{ y: 700 }}
            style={{ marginTop: "15px", maxHeight: "100%" }}
            dataSource={dataSource}
            columns={columns}
          />
        )}
      </Drawer>
      {openAnalysisDrawer && (
        <BluesisAnalysis
          routeOptions={selectOptions}
          bluesisList={bluesisList}
          visible={openAnalysisDrawer}
          setVisible={setOpenAnalysisDrawer}
          type={analysisType}
          setAnalysisType={setAnalysisType}
          vectors={vectors}
          selectedRoute={route!}
        ></BluesisAnalysis>
      )}
      <Modal
        title={t("edit_route")}
        visible={updateModalVisible}
        onOk={updateRoute}
        onCancel={closeUpdateRouteForm}
        destroyOnClose={true}
        footer={[
          <Button onClick={deleteRoute} key="delete" danger type="primary">
            {t("delete")}
          </Button>,
          <Button onClick={closeUpdateRouteForm} key="cancel">
            {t("cancel")}
          </Button>,
          <Button onClick={updateRoute} key="update" type="primary">
            {t("update")}
          </Button>,
        ]}
      >
        <Form
          form={updateRouteForm}
          labelCol={{ flex: "110px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
        >
          <Form.Item
            label={t("name")}
            name="route_name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default RouteDetails;
