import http from "../http.common";
import { Device } from "./device";

const getDevices = function () {
  return http.get<Device[]>(`/web/device/centris/listUnassigned`);
};

const getVieroDevices = function () {
  return http.get<Device[]>(`/web/device/detectors/listviero`);
};

const getCameras = function () {
  return http.get<Device[]>(`/web/device/detectors/listcamera`);
};

const updateDevice = function (formValue: any) {
  return http.post(`/web/device/detectors/updatedevice`, formValue);
};

const addCameraAndViero = function (formValue: any) {
  return http.post(`/web/device/detectors/addcameraandviero`, formValue);
};

const getParkingCameraConfig = function (device_id: number) {
  return http.post<any>(`/web/device/detectors/getparkingcameraconfig`, {
    device_id: device_id,
  });
};

const addParkingCameraConfig = function (
  device_id: number,
  points: any
  ) {
  return http.post<any>(`/web/device/detectors/addparkingcameraconfig`, {
    device_id: device_id,
    points: points
  });
};

const deleteDevice = function (device_id: number) {
  return http.post<any>(`/web/device/detectors/delete`, { id: device_id });
};
const fileUpload = function (file: any, camera_device_id:number) {
  return http.post<any>(
    `/web/fileserver/upload/parking/${camera_device_id}`,
    file, 
  );
};

const getParkingCameraFile = function(camera_device_id:number) {
  return http.post<any>(`/web/device/detectors/getparkingcamerafile`, { id: camera_device_id });
}

const DeviceService = {
  getDevices,
  getVieroDevices,
  getCameras,
  updateDevice,
  addCameraAndViero,
  addParkingCameraConfig,
  getParkingCameraConfig,
  deleteDevice,
  fileUpload,
  getParkingCameraFile
};

export default DeviceService;
