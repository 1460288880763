import VmsService from "../../../../../../data/vms/VmsService";
import { MarkerForm } from "./MarkerForm";

export const vmsModel: MarkerForm = {
  type: "vms",
  addService: VmsService.addVms,
  updateService: VmsService.updateVms,
  items: [
    {
      label: "vms_name",
      name: "name",
      type: "input",
      isRequired: true,
    },
    {
      label: "address",
      name: "address",
      type: "input",
      isRequired: true,
    },
    {
      label: "status_id",
      name: "status_id",
      type: "input",
      isRequired: true,
    },
    {
      label: "ups",
      name: "ups_id",
      type: "input",
      isRequired: true,
    },
    {
      label: "organization",
      name: "org_id",
      type: "dropdown",
      isRequired: true,
      dropdownItem: "orgList",
    },
    {
      label: "latitude",
      name: "latitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "longitude",
      name: "longitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "vms_ip_address",
      name: "ip_address",
      type: "input",
      isRequired: true,
    },
    {
      label: "vms_stream_url",
      name: "stream_url",
      type: "input",
      isRequired: true,
    },
    {
      label: "vms_offset_x",
      name: "offset_x",
      type: "number",
      isRequired: true,
    },
    {
      label: "vms_offset_y",
      name: "offset_y",
      type: "number",
      isRequired: true,
    },
    {
      label: "vms_resolution_x",
      name: "resolution_x",
      type: "number",
      isRequired: true,
    },
    {
      label: "vms_resolution_y",
      name: "resolution_y",
      type: "number",
      isRequired: true,
    },
    {
      label: "vms_type",
      name: "type_id",
      type: "dropdown",
      dropdownItem: [
        {
          value: "1",
          label: "vms_l_type",
        },
        {
          value: "2",
          label: "vms_n_type",
        },
      ],
      isRequired: true,
    },
  ],
};
