import { DatabaseFilled, ClockCircleOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  List,
  Space,
  Spin,
  Tag,
  TimePicker,
  message,
  InputNumber,
} from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useContext, useEffect, useState } from "react";
import {
  AuthService,
  PermissionDataProps,
  PermissionContext,
} from "@icms/ui-components";
import { useTranslation } from "react-i18next";
import UserService from "../../../data/user/UserService";
import { useLicenseData } from "../../../contexts/license/LicenseDataContext";
import { CustomLayerData } from "../../map/map-constants/LayerData";
import moment from "moment";

const Options: React.FC = () => {
  let user = AuthService.getUser();
  const { hasAccessComponent } = useContext(
    PermissionContext
  ) as PermissionDataProps;
  const [loading, setLoading] = useState(false);
  const [defaultModules, setDefaultModules] = useState<number[]>([]);
  const [modules, setModules] = useState<number[]>([]);
  const [key, setKey] = useState(Date.now());
  const { t, i18n } = useTranslation();
  const [labels, setLabels] = useState<
    { id: number; name: string; active: boolean | undefined }[]
  >([]);
  const { licenseData } = useLicenseData();
  const [notificationTime, setNotificationTime] = useState<number | null>(null);

  useEffect(() => {
    if (licenseData?.modules) {
      let filterLayers = CustomLayerData.filter((item) =>
        hasAccessComponent(item.authorized_component_name + "-READ")
      );
      setLabels(
        filterLayers.map((layer) => {
          const layerName = licenseData.licenseLayers.find(
            (i) => i.id === layer.id
          )?.name;
          var active: boolean = false;

          if (layerName) active = licenseData.modules[layerName];

          return {
            id: layer.id,
            name: t(layer.name),
            active,
          };
        })
      );
    }
  }, [licenseData, i18n.language]);

  useEffect(() => {
    if (user.user_config && user.user_config.notification_duration) {
      setNotificationTime(user.user_config.notification_duration);
    }
    fetchModules();
  }, []);

  const fetchModules = () => {
    UserService.getModules(user.user_info.id_user).then((res) => {
      if (res.data.length) {
        setDefaultModules(res.data);
        setModules(res.data);
        setKey(Date.now());
      }
    });
  };

  const updateModules = () => {
    setLoading(true);
    UserService.updateDefaultModules({
      modules: modules,
      id_user: user.user_info.id_user,
    }).then((res) => {
      message.success(t("update_successful"));
      fetchModules();
      setLoading(false);
    });
  };

  const updateNotificationTime = async () => {
    setLoading(true);
    await UserService.updateNotificationTime(
      notificationTime!,
      user.user_info.id_user
    ).then((res) => {
      if (res.data) {
        message.success(t("settings_update_applied"));
        user.user_config.notification_duration = notificationTime;
        AuthService.setUser(user)
      }
    }).finally(() => {
      setLoading(false);

    });
  };

  return (
    <Spin spinning={loading}>
      <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
        {/* Module Selection Card */}
        <Card
          key={key}
          bordered={false}
          style={{ width: 340, marginTop: "30px" }}
          actions={[
            <div style={{ textAlign: "start" }}>
              {labels.length > 0 &&
                labels
                  .filter((i) => i.active)
                  .map((item, index) => (
                    <Card key={index}>
                      <Checkbox
                        key={index}
                        defaultChecked={modules.includes(item.id)}
                        onChange={(i) => {
                          let value = i.target.checked;
                          if (value) {
                            setModules([...modules, item.id]);
                          } else {
                            setModules(modules.filter((i) => i !== item.id));
                          }
                        }}
                      >
                        {item.name}
                      </Checkbox>
                    </Card>
                  ))}
              <Button
                onClick={updateModules}
                style={{ backgroundColor: "#4AAACB", color: "white", width: "100%", marginTop:"15px" }}
              >
                {t("save")}
              </Button>
            </div>,
          ]}
        >
          <Meta
            avatar={
              <Avatar
                shape="square"
                style={{
                  backgroundColor: "#2f4050",
                  marginTop: "5px",
                  opacity: "0.8",
                }}
                size={50}
              >
                <DatabaseFilled />
              </Avatar>
            }
            title={
              <div style={{ fontSize: 15 }}>{t("defaultSelectedModules")}</div>
            }
            description={
              <>
                {labels.length > 0 &&
                  labels
                    .filter((i) => defaultModules.includes(i.id) && i.active)
                    .map((item, index) => (
                      <Tag key={index} color="green">
                        {item.name}
                      </Tag>
                    ))}
              </>
            }
          />
        </Card>

        {/* Notification Time Card */}
        <Card
          bordered={false}
          style={{ marginTop: "30px",height: "150px" }}
          title={t("notifiction_time") + ` (${t("seconds")} )`}
        >
          <>
            <Space>
              <InputNumber
                value={notificationTime}
                onChange={(time) => setNotificationTime(time)}
              />
            </Space>
            <Button
              onClick={updateNotificationTime}
              style={{ backgroundColor: "#4AAACB", color: "white" }}
            >
              {t("save")}
            </Button>
          </>
        </Card>
      </div>
    </Spin>
  );
};

export default Options;
