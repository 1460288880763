import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import HtmlListRenderer from "./ReleaseNoteListRenderer";
import AppInfoService from "../../../data/common/AppInfoService";
import Cookies from "js-cookie";

interface ReleaseNotesModalProps {
  appVersion: string;
}

type SupportedLanguages = "tr" | "en" | null;

const ReleaseNoteModal = ({ appVersion }: ReleaseNotesModalProps) => {
  const [latestVersion, setLatestVersion] = useState("");
  const [content, setContent] = useState<any>("");
  const { i18n } = useTranslation();
  const currentLanguage: SupportedLanguages =
    i18n?.language as SupportedLanguages;

  useEffect(() => {
    fetchAppVersion();
  }, []);

  const compareVersions = (v1: string, v2: string | null): number => {
    const v1Parts = v1.split(".").map((part) => parseInt(part, 10));
    const v2Parts = v2 ? v2.split(".").map((part) => parseInt(part, 10)) : [];

    for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
      const num1 = v1Parts[i] || 0;
      const num2 = v2Parts[i] || 0;

      if (num1 > num2) return 1;
      if (num1 < num2) return -1;
    }

    return 0; // Versions are equal
  };

  const fetchData = async (version: string) => {
    const response = await AppInfoService.getReleaseNotes(version);
    setContent(response?.data);
    return response;
  };

  function extractVersion(versionString: string) {
    const match = versionString.match(/v?(\d+\.\d+\.\d+)/);
    return match ? match[1] : "";
  }

  const fetchAppVersion = async () => {
    const storedVersion = Cookies.get("currentVersion");
    let foundVersion = "0.1.0";

    try {
      const versionResponse = await AppInfoService.getVersionInfo();

      // foundVersion = extractVersion(JSON.parse(versionResponse).GitTag);
      foundVersion = extractVersion(versionResponse.data?.GitTag);
      setLatestVersion(foundVersion);
      if (storedVersion) {
        if (compareVersions(foundVersion, storedVersion) === 1) {
          fetchData(foundVersion);
        }
      } else {
        fetchData(foundVersion);
      }
    } catch (error) {
      if (storedVersion) {
        foundVersion = storedVersion;
      } else {
        foundVersion = appVersion;
      }
    }
  };

  const handleClose = () => {
    setContent("");
    Cookies.set("currentVersion", latestVersion, { expires: 365 }); // Save the version in a cookie so modal won't appear again
  };

  return (
    <Modal
      visible={content}
      width={"50%"}
      footer={null}
      closeIcon={<span></span>}
      centered
      className="release-modal"
    >
      <div className="custom-modal-content-wrapper">
        <div
          style={{
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <h2
            style={{
              margin: 0,
              paddingTop: "0.8rem",
              fontWeight: 700,
              marginBottom: "-0.2rem",
              color: "#353535",
            }}
          >
            {t("whats_new")} 🎉 🎉
          </h2>
          <h4
            style={{
              color: "#6c757d",
              paddingLeft: "0.1rem",
              fontSize: "13px",
            }}
          >
            version: {latestVersion}
          </h4>
        </div>
        <h4
          style={{
            fontSize: "1rem",
            color: "#333333",
            paddingLeft: "12px",
            fontWeight: 700,
          }}
        >
          {t("latest_updates")}:
        </h4>
        <div
          className="release-content"
          style={{
            paddingBottom: "3rem",
          }}
        >
          <HtmlListRenderer
            htmlString={content}
            selectedLanguage={currentLanguage}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "1rem",
          }}
        >
          <Button key="close" type="primary" onClick={handleClose}>
            {t("close")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReleaseNoteModal;
