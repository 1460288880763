import { useEffect, useState } from "react";
import { useToaster } from "../../../../contexts/information/ToasterContext";
import { Marker, Tooltip } from "react-leaflet";
import L from "leaflet";
import withLogging from "../../high-order-components/LoggerHOC";

function IncidentLayer() {
  const { toasterMessage } = useToaster();
  const [markerData, setMarkerData] = useState<
    { latitude: number; longitude: number; description: string }[]
  >([]);

  useEffect(() => {
    const description = toasterMessage?.description;
    var latitudeMatch = description?.match(/Latitude: ([-\d.]+)/);
    var longitudeMatch = description?.match(/Longitude: ([-\d.]+)/);
    if (latitudeMatch && longitudeMatch) {
      var latitude = parseFloat(latitudeMatch[1]);
      var longitude = parseFloat(longitudeMatch[1]);
      const newItem = {
        latitude: latitude,
        longitude: longitude,
        description: description ? description : "",
      };
      setMarkerData((prev) => [...prev, newItem]);

      setTimeout(() => {
        setMarkerData((prev) => prev.filter((item) => item !== newItem));
      }, 5 * 60 * 1000); //incident detection marker keep alive for 5 minutes
    } else {
      // console.log("lat long definition doesnt exist in the toaster message");
    }
  }, [toasterMessage]);

  return (
    <>
      {markerData.length > 0 &&
        markerData.map((item, index) => {
          return (
            <Marker
              key={index}
              position={[item.latitude, item.longitude]}
              icon={L.icon({
                iconUrl: `/static/incident-detection-marker.gif`,
                iconSize: [150, 150],
              })}
            >
              <Tooltip>{item.description}</Tooltip>
            </Marker>
          );
        })}
    </>
  );
}
export default withLogging(IncidentLayer);
