import { t } from "i18next";
import { useLicenseData } from "../../../../../contexts/license/LicenseDataContext";
import { modeList } from "../../../map-constants/module-control/MapToolbarData";

interface ToolbarButtonProps {
  content: React.ReactNode;
  name: string | undefined;
  marginTop: string;
  modeId: modeList;
}

export function ToolbarButton(props: ToolbarButtonProps) {
  const { content, marginTop, modeId } = props;
  const { licenseData } = useLicenseData();

  const enableButton: boolean | undefined = licenseData?.toolbarButton.find(
    (i) => i.modeId === modeId
  )?.active;

  return enableButton !== false ? (
    <div
      style={{
        marginTop: marginTop,
        height: "30px",
      }}
    >
      {content}
    </div>
  ) : (
    <></>
  );
}
