import { Button, Form, message, Popconfirm, Spin, Steps } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Junction, JunctionDeviceState } from "../../../data/junction/Junction";
import JunctionService from "../../../data/junction/JunctionService";
import { Row } from "./style";
import { useDeviceState } from "../../../contexts/device/DeviceStateContext";

interface Props {
  selectedJunction?: Junction;
}

function Reset(props: Props) {
  const { t } = useTranslation();
  const { selectedJunction } = props;
  const [guidReset, setGuidReset] = useState<number>();
  const [ldReset, setLdReset] = useState<boolean>(false);
  const { deviceStateData } = useDeviceState();
  const [stepper, setStepper] = useState<any>();

  const onConfirm = useCallback(() => {
    setLdReset(true);
    let generated_id =  Math.floor(100000000 + Math.random() * 900000000)
    setGuidReset(generated_id);
    JunctionService.resetKKC(selectedJunction!.id,generated_id)
      .then((f) => {
        if (f.data.result === "true") {
          message.success(t("device_has_been_reset"));
        } else {
          message.error(
            t("failed_to_reset_device_please_check")
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedJunction]);

  useEffect(() => {
    const { Step } = Steps;
    if (
      deviceStateData.filter((x) => x.guid === guidReset).length > 0
    ) {
      deviceStateData
        .filter((x) => x.guid === guidReset)
        .forEach((item) => {
          if (item.commandState === 6) {
            setTimeout(() => {
              setLdReset(false);
            }, 2000);
          }
          if (item.commandState === 5) {
            setLdReset(true);
          }
        });

      setStepper(
        <Steps direction="vertical" size="small" current={10}>
          {deviceStateData
            .filter((x) => x.guid === guidReset)
            .map((item, index) => (
              <Step
                key={index}
                title={JunctionDeviceState[item.commandState]}
              />
            ))}
        </Steps>
      );
    }
  }, [deviceStateData]);

  return (
    <Spin
    tip={stepper}
    spinning={ldReset}
    size="small"
    style={{ position: "relative" }}
  >
    <Row>
      <Form layout="vertical">
        <Form.Item>
          <Popconfirm
            title={t("after_op_device_will_be_reset_r_you_sure")}
            onConfirm={() => onConfirm()}
            onCancel={() => {
              return;
            }}
            okText={t("yes")}
            cancelText={t("cancel")}
          >
            <Button htmlType="submit"
            >
              <Spin spinning={ldReset}>{ t("reset_device") }</Spin>
            </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </Row>
    </Spin>
  );
}

export default Reset;
