import { IconType } from "../data/toaster-notification/ToasterIconEnum";
import * as Icons from '@ant-design/icons';


export const getIcon = (type: IconType) => {
    switch (type) {
      case IconType.AlertOutlined:
      return <Icons.AlertOutlined />;
    case IconType.WarningOutlined:
      return <Icons.WarningOutlined />;
    case IconType.BorderInnerOutlined:
      return <Icons.BorderInnerOutlined />;
    case IconType.QuestionOutlined:
      return <Icons.QuestionOutlined />;
    case IconType.ExclamationOutlined:
      return <Icons.ExclamationOutlined />;
    case IconType.CheckCircleOutlined:
      return <Icons.CheckCircleOutlined />;
    case IconType.PauseOutlined:
      return <Icons.PauseOutlined />;
    case IconType.PlusCircleOutlined:
      return <Icons.PlusCircleOutlined />;
    case IconType.AreaChartOutlined:
      return <Icons.AreaChartOutlined />;
    case IconType.FallOutlined:
      return <Icons.FallOutlined />;
    case IconType.RiseOutlined:
      return <Icons.RiseOutlined />;
    case IconType.FundOutlined:
      return <Icons.FundOutlined />;
    case IconType.AimOutlined:
      return <Icons.AimOutlined />;
    case IconType.BellOutlined:
      return <Icons.BellOutlined />;
    case IconType.CarOutlined:
      return <Icons.CarOutlined />;
    case IconType.CommentOutlined:
      return <Icons.CommentOutlined />;
    case IconType.CompressOutlined:
      return <Icons.CompressOutlined />;
    case IconType.DingtalkOutlined:
      return <Icons.DingtalkOutlined />;
    case IconType.DesktopOutlined:
      return <Icons.DesktopOutlined />;
    case IconType.DownloadOutlined:
      return <Icons.DownloadOutlined />;
    case IconType.ExceptionOutlined:
      return <Icons.ExceptionOutlined />;
    case IconType.FileOutlined:
      return <Icons.FileOutlined />;
    case IconType.FireOutlined:
      return <Icons.FireOutlined />;
    case IconType.HomeOutlined:
      return <Icons.HomeOutlined />;
    case IconType.LikeOutlined:
      return <Icons.LikeOutlined />;
    case IconType.MehOutlined:
      return <Icons.MehOutlined />;
    case IconType.ReloadOutlined:
      return <Icons.ReloadOutlined />;
    case IconType.SmileOutlined:
      return <Icons.SmileOutlined />;
    case IconType.SettingOutlined:
      return <Icons.SettingOutlined />;
    case IconType.LineOutlined:
      return <Icons.LineOutlined />;
    case IconType.SendOutlined:
      return <Icons.SendOutlined />;
    case IconType.SyncOutlined:
      return <Icons.SyncOutlined />;
    case IconType.UserOutlined:
      return <Icons.UserOutlined />;
    case IconType.SoundOutlined:
      return <Icons.SoundOutlined />;
    case IconType.VideoCameraOutlined:
      return <Icons.VideoCameraOutlined />;
    case IconType.TagOutlined:
      return <Icons.TagOutlined />;
    case IconType.SaveOutlined:
      return <Icons.SaveOutlined />;
    case IconType.DeleteOutlined:
      return <Icons.DeleteOutlined />;
    case IconType.SwapOutlined:
      return <Icons.SwapOutlined />;
    case IconType.PlayCircleOutlined:
      return <Icons.PlayCircleOutlined />;
      default:
        return null;
    }
  }