import { NotificationProvider } from "../../contexts/information/NotificationContext";
import FCDDataForm from "./components/index";

const FCDConfiguration = () => {
  return (
    <>
      <NotificationProvider>
        <FCDDataForm />
      </NotificationProvider>
    </>
  );
};

export default FCDConfiguration;
