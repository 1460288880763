import { useTranslation } from "react-i18next";
import { Button, Tabs, Tooltip } from "antd";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import LegendTabContent from "./LegendTabContent";
import { CloseOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { LegendData, legendData } from "../../map-constants/legend/LegendData";

const TabPane = Tabs.TabPane;

function LegendControl() {
  const { t } = useTranslation();
  const { layerList } = useCustomLayerData();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showLegendButton, setShowLegendButton] = useState(true);
  const [legendList, setLegendList] = useState<{ [id: number]: LegendData[] }>(
    {}
  );

  useEffect(() => {
    setLegendList(legendData);
  }, []);

  useEffect(() => {
    let flag = false;
    layerList.forEach((layer: any) => {
      if (layer?.isChecked) {
        flag = true;
      }
    });

    setShowLegendButton(flag);
  }, [layerList]);

  return (
    <>
      {showLegendButton && (
        <Button
          type="primary"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          style={{
            height: "38px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#2f4050e6",
            border: "none",
            borderRadius: "3px",
            overflow: "hidden",
            margin: "auto",
          }}
        >
          {isExpanded ? (
            <p
              style={{
                marginBottom: 0,
                fontSize: 14,
                color: "#fdfdfd",
              }}
            >
              {t("legends")}
            </p>
          ) : (
            <UnorderedListOutlined style={{ fontSize: "16px" }} />
          )}
        </Button>
      )}

      <div>
        {showLegendButton ? (
          <div
            className={`legend-container ${
              isExpanded ? "expanded" : "collapsing"
            }`}
          >
            {isExpanded && (
              <>
                <button
                  onClick={() => {
                    setIsExpanded(false);
                  }}
                  style={{
                    all: "unset", // Remove all default button styles
                    cursor: "pointer",
                    display: "inline-block",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      top: "42px",
                      right: "11px",
                      height: "26px",
                      width: "26px",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <CloseOutlined style={{ fontSize: "18px" }} />
                  </div>
                </button>
                <div style={{ padding: "10px" }}>
                  {layerList.length > 0 ? (
                    <div style={{ maxWidth: "100%" }}>
                      <Tabs
                        type="line"
                        tabPosition="top"
                        tabBarStyle={{ fontSize: "12px" }}
                      >
                        {layerList.map(
                          (m, index) =>
                            m.isChecked && (
                              <TabPane tab={t(m.name)} key={index}>
                                <LegendTabContent
                                  legendList={legendList}
                                  setLegendList={setLegendList}
                                  key={index + "*" + m.id}
                                  layerId={m.id}
                                />
                              </TabPane>
                            )
                        )}
                      </Tabs>
                    </div>
                  ) : (
                    <>{t("must_choose_at_least_one_module")}.</>
                  )}
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
}

export default LegendControl;

//    <SlidingPanel
// title={t("legends")}
// icon={
//   <Tooltip title={t("map_legends_open_n_close")}>
//     <LocationSvg />
//   </Tooltip>
// }
// >
// {layerList.length > 0 ? (
//   <div style={{ maxWidth: "100%" }}>
//     <Tabs type="line" tabPosition="top" style={{ color: "white" }}>
//       {layerList.map(
//         (m, index) =>
//           m.isChecked && (
//             <TabPane tab={t(m.name)} key={index}>
//               <LegendTabContent
//                 key={index + "*" + m.id}
//                 layerId={m.id}
//               />
//             </TabPane>
//           )
//       )}
//     </Tabs>
//   </div>
// ) : (
//   <>{t("must_choose_at_least_one_module")}.</>
// )}
// </SlidingPanel>
