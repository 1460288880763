import { Button, Form, message, Popconfirm, Select, Spin, Steps } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Junction, JunctionDeviceState, JunctionStatus } from "../../../data/junction/Junction";
import JunctionService from "../../../data/junction/JunctionService";
import { modeList } from "./manage.data";
import { Row } from "./style";
import { useDeviceState } from "../../../contexts/device/DeviceStateContext";
import { useJunctionStatusData } from "../../../contexts/junction/JunctionStatusDataContext";
import { JunctionStatusInfo } from "../../map/map-constants/module-status/JunctionStatusIno";

interface Props {
  selectedJunction?: Junction;
}

const { Step } = Steps;

function Mode(props: Props) {
  const { t } = useTranslation();
  const { selectedJunction } = props;

  const [mode, setMode] = useState<string>("S");

  const [definedMode, setDefinedMode] = useState<any>();

  const [ldGetKKC, setLdGetKKC] = useState<boolean>(false);
  const [ldSetKKC, setLdSetKKC] = useState<boolean>(false);
  const [guidGetKkcMode, setGuidGetKkcMode] = useState<number>()
  const [guidSetKkcMode, setGuidSetKkcMode] = useState<number>();
  const [stepper, setStepper] = useState<any>();
  const [stepperModeChange, setStepperModeChange] = useState<any>();


  const { deviceStateData } = useDeviceState();
  const { junctionStatusData } = useJunctionStatusData();

  const handleChange = (value: string) => {
    setMode(value);
  };

  const getKKCMode = useCallback(() => {
    setLdGetKKC(true)
    let generated_id =  Math.floor(100000000 + Math.random() * 900000000)
    setGuidGetKkcMode(generated_id);
    JunctionService.getKKCMode(selectedJunction!.id, generated_id)
      .then((f: any) => {         
        let tempJunctionStatusId : JunctionStatus =  parseInt(JunctionStatus[f.data.result]);
        setDefinedMode(JunctionStatusInfo[tempJunctionStatusId].text);
      })
      .catch((err) => console.log(err));
  }, [selectedJunction]);

  const onConfirm = useCallback(() => {
    setLdSetKKC(true);
    let generated_id =  Math.floor(100000000 + Math.random() * 900000000)
    setGuidSetKkcMode(generated_id);
    JunctionService.setKKCMode(selectedJunction!.id, mode, generated_id)
      .then((f) => {
        if (f.data.result === "true") {
          message.success(t("device_mode_changed"));
        } else {
          message.error(
            t("failed_to_change_device_mode_please_check")
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [mode, selectedJunction]);

  useEffect(() => {
    getKKCMode();
  }, [getKKCMode]);

  useEffect(() => {
    // console.log("GUID, GET KKC MODE", guidGetKkcMode);
    // console.log(
    //   "DEVICE STATE DATA FILTERED, GET KKC MODE",
    //   deviceStateData.filter((x) => x.guid === guidGetKkcMode)
    // );
    if (deviceStateData.filter((x) => x.guid === guidGetKkcMode).length > 0) {
      deviceStateData
        .filter((x) => x.guid === guidGetKkcMode)
        .forEach((item) => {
          if (item.commandState === 6) {
            setTimeout(() => {
              setLdGetKKC(false);
            }, 2000);
          }
          if (item.commandState === 5) {
            setLdGetKKC(true);
          }
        });

      setStepper(
        <Steps direction="vertical" size="small" current={10}>
          {deviceStateData
            .filter((x) => x.guid === guidGetKkcMode)
            .map((item, index) => (
              <Step
                key={index}
                title={JunctionDeviceState[item.commandState]}
              />
            ))}
        </Steps>
      );
    }
  }, [deviceStateData]);

  useEffect(() => {
    if (deviceStateData.filter((x) => x.guid === guidSetKkcMode).length > 0) {
      deviceStateData
        .filter((x) => x.guid === guidSetKkcMode)
        .forEach((item) => {
          if (item.commandState === 6) {
            setTimeout(() => {
              setLdSetKKC(false);
            }, 2000);
          }
          if (item.commandState === 5) {
            setLdSetKKC(true);
          }
        });

      setStepperModeChange(
        <Steps direction="vertical" size="small" current={10}>
          {deviceStateData
            .filter((x) => x.guid === guidSetKkcMode)
            .map((item, index) => (
              <Step
                key={index}
                title={JunctionDeviceState[item.commandState]}
              />
            ))}
        </Steps>
      );
    }
    
  }, [deviceStateData]);

  useEffect(()=>{
    if(junctionStatusData.length>0 && junctionStatusData[junctionStatusData.length - 1]?.id == selectedJunction?.id){
      let statusInfo : JunctionStatus;
      statusInfo = junctionStatusData[junctionStatusData.length - 1].status_id
      setDefinedMode(JunctionStatusInfo[statusInfo].text)
  }
  },[junctionStatusData])

  return (
  
      <Row>
        <Form layout="vertical">
        <Spin
      tip={stepper}
      spinning={ldGetKKC}
      size="small"
      style={{ position: "relative", fontSize:14, color: "#ffffff" }}
    >
          <Form.Item
            label={
              <b
                style={{
                  fontSize: 15,
                  backgroundColor: "#2f4050",
                  color: "#ffffff",
                }}
              >
                { t("device_mode") }
              </b>
            }
            style={{
              fontSize: 14,
              backgroundColor: "#2f4050",
              color: "#ffffff",
            }}
          >
            {ldGetKKC === true
              ? t("bringing_mode_information")
              : definedMode
              ? definedMode
              : ""}
          </Form.Item>
          </Spin>
          <Spin
      tip={stepperModeChange}
      spinning={ldSetKKC}
      size="small"
      style={{ position: "relative", fontSize:14, color: "#ffffff",}}
    >
          <Form.Item
            label={
              <b
                style={{
                  fontSize: 15,
                  backgroundColor: "#2f4050",
                  color: "#ffffff",
                }}
              >
                { t("change_device_mode") }
              </b>
            }
            style={{
              fontSize: 14,
              backgroundColor: "#2f4050",
              color: "#ffffff",
            }}
          >
            <Select
              options={modeList}
              dropdownMatchSelectWidth={false}
              onChange={handleChange}
              placeholder={"Select"}
              style={{marginTop: "5px"}}
            />
          </Form.Item>
          <Form.Item>
            <Popconfirm
              title={t("device_will_be_updated_accept")}
              onConfirm={() => onConfirm()}
              onCancel={() => {
                return;
              }}
              okText={t("yes")}
              cancelText={t("cancel")}
            >
              <Button htmlType="submit">
                { t("update") }
              </Button>
            </Popconfirm>
          </Form.Item>
          </Spin>
        </Form>
      </Row>
   
  );
}

export default Mode;
