import { Button, DatePicker, Form, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IHandleStatistic } from "../../../../models/IHandleStatistic";
import VehicleDensityService from "../../../../services/VehicleDensityService";
import { dateTimeConvertTimestamp } from "../../../../utils/dateTimeConvertTimestamp";
import PlotsForMonthly from "../Plots/PlotsForMonthly";

interface Props {
  selectedJunction: any;
  isHaveLoop: boolean;
}

const { Option } = Select;

enum MonthlyFormFileds {
  "startDate" = "startDate",
  "endDate" = "endDate",
  "interval" = "interval",
  "dataType" = "dataType",
}

const formLabelSpan = { span: 24 };
const datePickerFormat = "MM/YYYY";

const FilterAreaForMonthly: React.FC<Props> = ({
  selectedJunction,
  isHaveLoop,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [plotData, setPlotData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.setFields([
      {
        name: MonthlyFormFileds.startDate,
        value: moment().subtract(3, "month"),
      },
      { name: MonthlyFormFileds.endDate, value: moment() },
      { name: MonthlyFormFileds.interval, value: 1 },
      { name: MonthlyFormFileds.dataType, value: "detector" },
    ]);
  }, [form]);

  const createPlot = async () => {
    let formValue = form.getFieldsValue();
    let convertedStartTime = dateTimeConvertTimestamp(formValue.startDate);
    let convertedEndTime = dateTimeConvertTimestamp(formValue.endDate);
    formValue.data = [];
    formValue.dynamicConfigData = [];
    setIsLoading(true);

    await VehicleDensityService.fetchStatisticData(
      convertedStartTime,
      convertedEndTime,
      +selectedJunction.id,
      "month",
      formValue.dataType ?? "detector",
      formValue.interval
    ).then((res) => {
      let result: IHandleStatistic[] = res.data.result;
      let uniqDetectorId: number[] = [];

      for (let index = 0; index < result.length; index++) {
        let statisticDataKeys = Object.keys(
          result[index]?.getstatistic?.statisticData
        )?.[0];
        let statisticDataArr =
          result[index].getstatistic.statisticData[statisticDataKeys];
        let time = moment
          .utc(statisticDataKeys)
          .utcOffset(localStorage.getItem("timezone")!)
          .format(datePickerFormat);
        let vehicleCount = 0;

        if (statisticDataArr) {
          for (let index = 0; index < statisticDataArr.length; index++) {
            if (statisticDataArr[index].detectorDatas) {
              for (
                let detectorDataIndex = 0;
                detectorDataIndex <
                statisticDataArr[index].detectorDatas.length;
                detectorDataIndex++
              ) {
                if (
                  statisticDataArr[index].detectorDatas[detectorDataIndex]
                    .vehicle_count !== -1
                ) {
                  vehicleCount +=
                    statisticDataArr[index].detectorDatas[detectorDataIndex]
                      .vehicle_count;
                }
                if (
                  !uniqDetectorId.includes(
                    statisticDataArr[index].detectorDatas[detectorDataIndex]
                      .detector_id
                  )
                ) {
                  uniqDetectorId.push(
                    statisticDataArr[index].detectorDatas[detectorDataIndex]
                      .detector_id
                  );
                  formValue.dynamicConfigData[
                    `${statisticDataArr[index].detectorDatas[detectorDataIndex].detector_id}`
                  ] = [];
                }
              }
            }
          }
          for (let index = 0; index < uniqDetectorId.length; index++) {
            let detectorVehicleCount = 0;
            let detectorName;
            for (
              let statisticIndex = 0;
              statisticIndex < statisticDataArr.length;
              statisticIndex++
            ) {
              for (
                let detectorDataIndex = 0;
                detectorDataIndex <
                statisticDataArr[statisticIndex].detectorDatas.length;
                detectorDataIndex++
              ) {
                if (
                  statisticDataArr[statisticIndex].detectorDatas[
                    detectorDataIndex
                  ].detector_id === uniqDetectorId[index] &&
                  statisticDataArr[statisticIndex].detectorDatas[
                    detectorDataIndex
                  ].vehicle_count !== -1
                ) {
                  detectorVehicleCount +=
                    statisticDataArr[statisticIndex].detectorDatas[
                      detectorDataIndex
                    ].vehicle_count;
                  detectorName =
                    statisticDataArr[statisticIndex].detectorDatas[
                      detectorDataIndex
                    ].name;
                }
              }
            }
            let detectorCount = {
              vehicleCount: detectorVehicleCount,
              time: time,
              name: `${detectorName}`,
            };
            formValue.dynamicConfigData[uniqDetectorId[index]].push(
              detectorCount
            );
          }
        }
        let count = {
          vehicleCount: vehicleCount,
          time: time,
          name: t("total_number_of_vehicles"),
        };
        formValue.data.push(count);
      }
    });
    //It removes the empty values in the dynamicConfigData array because there is no statistical data in the loop(Device).
    formValue.dynamicConfigData = formValue.dynamicConfigData.filter(
      (item: any) => item
    );
    formValue.startDate = formValue.startDate
      .utcOffset(localStorage.getItem("timezone")!)
      .format("LL");
    formValue.endDate = formValue.endDate
      .utcOffset(localStorage.getItem("timezone")!)
      .format("LL");
    setPlotData(formValue);
    setIsLoading(false);
  };

  return (
    <>
      <Form layout="inline" form={form}>
        <Form.Item
          labelCol={formLabelSpan}
          label={t("start_date")}
          name={MonthlyFormFileds.startDate}
        >
          <DatePicker format={datePickerFormat} picker="month" />
        </Form.Item>
        <Form.Item
          labelCol={formLabelSpan}
          label={t("end_date")}
          name={MonthlyFormFileds.endDate}
        >
          <DatePicker format={datePickerFormat} picker="month" />
        </Form.Item>
        <Form.Item
          labelCol={formLabelSpan}
          label={t("interval")}
          name={MonthlyFormFileds.interval}
        >
          <Select style={{ width: 120 }}>
            <Option value={1}>1 {t("month_old")}</Option>
            <Option value={2}>2 {t("month_old")}</Option>
            <Option value={3}>3 {t("month_old")}</Option>
            <Option value={4}>4 {t("month_old")}</Option>
            <Option value={6}>6 {t("month_old")}</Option>
          </Select>
        </Form.Item>
        {isHaveLoop && (
          <Form.Item
            labelCol={formLabelSpan}
            label={t("sensor_list")}
            name={MonthlyFormFileds.dataType}
          >
            <Select style={{ width: 50 }}>
              <Option value={"detector"}>{t("detector")}</Option>
              <Option value={"loop"}>{t("Loop")}</Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item labelCol={formLabelSpan} label={<span></span>}>
          <Button
            type="primary"
            loading={isLoading}
            disabled={isLoading}
            onClick={createPlot}
          >
            {t("apply")}
          </Button>
        </Form.Item>
      </Form>
      {plotData && (
        <PlotsForMonthly
          style={{ marginTop: "25px" }}
          datas={plotData}
        ></PlotsForMonthly>
      )}
    </>
  );
};

export default FilterAreaForMonthly;
