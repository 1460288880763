import { Button, Card, Checkbox, Col, Form, Input, Row } from "antd";
import { JunctionDetector } from "../../data/junction-detector/JunctionDetector";
import { t } from "i18next";
import { useEffect, useState } from "react";
import DeviceService from "../../data/device/DeviceService";

const gutter = {
  xs: 8,
  sm: 16,
  md: 24,
  lg: 32,
};

enum InformationFormFileds {
  "id_device" = "id_device",
  "device_type_id" = "device_type_id",
  "name" = "name",
  "ip_address" = "ip_address",
  "http_port" = "http_port",
  "sftp_port" = "sftp_port",
  "latitude" = "latitude",
  "longitude" = "longitude",
  "stream_url" = "stream_url",
  "proxy" = "proxy"
}

const cardHeadStyle = { fontSize: "17px", fontWeight: "bold" };
const formItemStyle = { fontWeight: "bold", width: "20%", marginTop: "5px" };
interface Props {
  selectedDevice: JunctionDetector | undefined;
}
function Information(props: Props) {
  const { selectedDevice } = props;
  const [form] = Form.useForm();
  const [streamText, setStreamText] = useState<string>("");

  useEffect(() => {
    return () => {
      window.stop()
    }
  },[])
  useEffect(() => {
    form.setFields([
      {
        name: InformationFormFileds.id_device,
        value: selectedDevice!.id_device,
      },
      {
        name: InformationFormFileds.device_type_id,
        value: selectedDevice!.devicetype_id,
      },
      { name: InformationFormFileds.name, value: selectedDevice!.name },
      {
        name: InformationFormFileds.ip_address,
        value: selectedDevice!.ipaddress,
      },
      {
        name: InformationFormFileds.http_port,
        value: selectedDevice!.http_port,
      },
      {
        name: InformationFormFileds.sftp_port,
        value: selectedDevice!.sftp_port,
      },
      { name: InformationFormFileds.latitude, value: selectedDevice!.latitude },
      {
        name: InformationFormFileds.longitude,
        value: selectedDevice!.longitude,
      },
      {
        name: InformationFormFileds.stream_url,
        value: selectedDevice!.stream_url,
      },
      {
        name: InformationFormFileds.proxy,
        value: selectedDevice!.proxy,
      },
    ]);
  }, [form]);

  useEffect(() => {
    setStreamText(selectedDevice!.stream_url);
  }, [selectedDevice]);

  const updateDevice = async () => {
    let formValues = form.getFieldsValue();
    formValues.id = selectedDevice?.id;
    setStreamText(formValues.stream_url);
    await DeviceService.updateDevice(formValues);
  };

  return (
    <>
      <Row gutter={gutter}>
        <Col span={5}>
          {selectedDevice && (
            <Card bodyStyle={{paddingTop:"0px", paddingBottom:"0px"}}  >
              <Form form={form} labelCol={{ span: 24 }}  style={{ minWidth: 800 ,paddingLeft:10}}>
                <Form.Item
                  style={formItemStyle}
                  label="Id"
                  name={InformationFormFileds.id_device}
                >
                  <Input disabled defaultValue={selectedDevice.id_device} />
                </Form.Item>
                <Form.Item
                   hidden={true}
                  style={formItemStyle}
                  label={t("device_type_id")}
                  name={InformationFormFileds.device_type_id}
                >
                  <Input disabled hidden={true} defaultValue={selectedDevice.devicetype_id} />
                </Form.Item>
                <Form.Item
                  style={formItemStyle}
                  label={t("name")}
                  name={InformationFormFileds.name}
                >
                  <Input disabled defaultValue={selectedDevice.name} />
                </Form.Item>
                <Form.Item
                  style={formItemStyle}
                  label={t("ip_address")}
                  name={InformationFormFileds.ip_address}
                >
                  <Input disabled defaultValue={selectedDevice.ipaddress} />
                </Form.Item>
                <Form.Item
                  hidden={true}
                  style={formItemStyle}
                  label={t("http_port")}
                  name={InformationFormFileds.http_port}
                >
                  <Input disabled hidden={true} defaultValue={selectedDevice.http_port} />
                </Form.Item>
                <Form.Item
                  hidden={true}
                  style={formItemStyle}
                  label={t("sftp_port")}
                  name={InformationFormFileds.sftp_port}
                >
                  <Input disabled hidden={true} defaultValue={selectedDevice.sftp_port} />
                </Form.Item>
                <Form.Item
                  style={formItemStyle}
                  label={t("latitude")}
                  name={InformationFormFileds.latitude}
                >
                  <Input disabled defaultValue={selectedDevice.latitude} />
                </Form.Item>
                <Form.Item
                  style={formItemStyle}
                  label={t("longitude")}
                  name={InformationFormFileds.longitude}
                >
                  <Input disabled defaultValue={selectedDevice.longitude} />
                </Form.Item>
                <Form.Item
                  style={formItemStyle}
                  label={t("stream_url")}
                  name={InformationFormFileds.stream_url}
                >
                  <Input disabled defaultValue={selectedDevice.stream_url} />
                </Form.Item>
                <Form.Item
                  style={formItemStyle}
                  label="Proxy"
                  name={InformationFormFileds.proxy}
                  valuePropName="checked"
                >
                  <Checkbox disabled defaultChecked={selectedDevice.proxy} />
                </Form.Item>
                {/* <Form.Item>
                  <Button type="primary" onClick={updateDevice}>
                    {t("save")}
                  </Button>
                </Form.Item> */}
              </Form>
            </Card>
          )}
        </Col>
        <Col span={18}>
          <Card
              bodyStyle={{ height: "75vh", padding: 0 }}
            >
              {selectedDevice!.proxy ? (
                <img
                  src={window.location.protocol+"//"+window.location.hostname+":"+window.location.port+"/camerastream/"+selectedDevice!.id}
                  className="streamloading"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain", 
                    display: "block", 
                    margin: "auto" 
                  }}
                  alt="Stream"
                />
              ) : (
                <img
                  src={selectedDevice!.stream_url}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain", 
                    display: "block", 
                    margin: "auto" 
                  }}
                  alt="Stream"
                />
              )}
            </Card>
        </Col>
      </Row>
    </>
  );
}

export default Information;
