import socketIOClient, { Socket } from "socket.io-client";
import { useEffect, createContext, PropsWithChildren, useContext } from "react";
import ls from "localstorage-slim";

const { REACT_APP_API_URL_DEV, REACT_APP_HOST_TYPE } = process.env;
const url =
  REACT_APP_HOST_TYPE === "dev"
    ? REACT_APP_API_URL_DEV
    : window.location.origin;

export const socket: Socket = socketIOClient(url + "", {
  transports: ["websocket"],
});

interface SocketProps {
  SetJunction: (id: any) => void;
  SetMultipleJunction: (idList: number[]) => void;
  SetCamera: (id: any) => void;
  FreeJunction: () => void;
  FreeMultipleJunction: () => void;
  FreeCamera: () => void;
  socket: Socket;
}
const WebSocketContext = createContext<SocketProps | undefined>(undefined);

ls.config.encrypt = true;

export function WebSocket({ children }: PropsWithChildren<{}>) {
  let junctionIdSub: number;
  let cameraIdSub: number;

  useEffect(() => {
    socket.on("connect", () => {
      if (junctionIdSub != undefined) SetJunction(junctionIdSub);
      if (cameraIdSub != undefined) SetCamera(cameraIdSub);
      SetMangoCar(1);    
    });
    return () => {
      FreetoMangoCar();
      socket.off("connect");
    };
  }, []);

  function SetJunction(id: number) {
    junctionIdSub = id;
    let userInfo = (ls.get("user") as any).user_info;

    let webSubData = {
      junctionId: id,
      userData: userInfo,
    };
    socket.emit("subToJunction", webSubData);
  }

  function FreeJunction() {
    socket.emit("freeToJunction");
  }

  function SetMultipleJunction(idList: number[]) {
    let userInfo = (ls.get("user") as any).user_info;

    let webSubData = {
      junctionIdList: idList,
      user: userInfo,
    };
    socket.emit("subToMultipleJunction", webSubData);
  }
  function FreeMultipleJunction() {
    socket.emit("freeToMultipleJunction");
  }

  function SetCamera(id: number) {
    cameraIdSub = id;
    socket.emit("subToCamera", id);
  }

  function FreeCamera() {
    socket.emit("freeToCamera");
  }

  function SetMangoCar(id: number) {
    socket.emit("subToMangoCar", id);
  }

  function FreetoMangoCar() {
    socket.emit("freeToMangoCar");
  }

  return (
    <WebSocketContext.Provider
      value={{
        SetJunction: SetJunction,
        FreeJunction: FreeJunction,
        SetMultipleJunction: SetMultipleJunction,
        FreeMultipleJunction: FreeMultipleJunction,
        SetCamera: SetCamera,
        FreeCamera: FreeCamera,
        socket: socket,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
}

export function useWebsocket() {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error("useWebsocket must be used within a WebSocketContext");
  }
  return context;
}
