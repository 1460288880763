import { Button, DatePicker, Form, Popconfirm, Spin, Steps } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Junction, JunctionDeviceState } from "../../../data/junction/Junction";
import JunctionService from "../../../data/junction/JunctionService";
import { Row } from "./style";
import { useDeviceState } from "../../../contexts/device/DeviceStateContext";

interface Props {
  selectedJunction?: Junction;
}

function TimeDate(props: Props) {
  const { t } = useTranslation();
  const { selectedJunction } = props;
  const [ldGetTime, setLdGetTime] = useState<boolean>(false);

  const [timeInfo, setTimeInfo] = useState<any>();
  const [guidGetKkcTime, setGuidGetKkcTime] = useState<number>();
  const [stepper, setStepper] = useState<any>();

  const { deviceStateData } = useDeviceState();

  //TO DO: it can be remove
  const convertTsTo_hhmmss = (timestamp: number) => {
    const date = moment(timestamp).utcOffset(localStorage.getItem("timezone")!).format('DD/MM/YYYY HH:mm:ss')
    return date;
  };

  useEffect(() => {
    const timeChange = 1000;
    const interval = setInterval(() => {
      setTimeInfo((prev: any) => {
        return prev + timeChange;
      });
    }, timeChange);

    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (selectedJunction) {
      setLdGetTime(true)
      let generated_id =  Math.floor(100000000 + Math.random() * 900000000)
      setGuidGetKkcTime(generated_id);
      JunctionService.getKKCTime(selectedJunction.id, generated_id)
        .then((f) => {
          setTimeInfo(f.data.result);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedJunction]);

  useEffect(() => {
    const { Step } = Steps;
    if (deviceStateData.filter((x) => x.guid === guidGetKkcTime).length > 0) {
      deviceStateData
        .filter((x) => x.guid === guidGetKkcTime)
        .forEach((item) => {
          if (item.commandState === 6) {
            setTimeout(() => {
              setLdGetTime(false);
            }, 2000);
          }
          if (item.commandState === 5) {
            setLdGetTime(true);
          }
        });

      setStepper(
        <Steps direction="vertical" size="small" current={10}>
          {deviceStateData
            .filter((x) => x.guid === guidGetKkcTime)
            .map((item, index) => (
              <Step
                key={index}
                title={JunctionDeviceState[item.commandState]}
              />
            ))}
        </Steps>
      );
    }
  }, [deviceStateData]);

  return (
    <Spin
      tip={stepper}
      spinning={ldGetTime}
      size="small"
      style={{ position: "relative" }}
    >
      <Row>
        <Form layout="vertical">
          <Form.Item
            label={
              <b
                style={{
                  fontSize: 15,
                  backgroundColor: "#2f4050",
                  color: "#ffffff",
                }}
              >
                { t("date_n_time_info") }
              </b>
            }
            style={{
              fontSize: 14,
              backgroundColor: "#2f4050",
              color: "#ffffff",
            }}
          >
            {ldGetTime === true
              ? t("getting_time_information")
              : timeInfo
              ? convertTsTo_hhmmss(timeInfo)
              : ""}
          </Form.Item>
          <Form.Item
            label={
              <b
                style={{
                  fontSize: 15,
                  backgroundColor: "#2f4050",
                  color: "#ffffff",
                }}
              >
                { t("date_n_time_setting") }
              </b>
            }
            style={{
              fontSize: 14,
              backgroundColor: "#2f4050",
              color: "#ffffff",
            }}
          >
            <DatePicker style={{marginTop: "5px"}} showTime />
          </Form.Item>
          <Form.Item>
            <Popconfirm
              title={t("device_will_be_updated_accept")}
              onConfirm={() => console.log("Confirmed")}
              onCancel={() => console.log("Cancelled")}
              okText={t("yes")}
              cancelText={t("cancel")}
            >
              <Button htmlType="submit">
                { t("update") }
              </Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Row>
    </Spin>
  );
}

export default TimeDate;
