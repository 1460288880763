import { Button, Image, Modal, Tooltip, UploadFile, UploadProps } from "antd";
import Upload from "antd/lib/upload";
import { t } from "i18next";
import { ReactElement, useEffect, useState } from "react";
import { Inventory } from "../../data/inventory/Inventory";
import InventoryService from "../../data/inventory/InventoryService";
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";

interface InventroyFilesProps {
  selectedInventory: Inventory;
}

function InventoryFiles(props: InventroyFilesProps) {
  const { selectedInventory } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewFile, setPreviewFile] = useState<string | undefined>(undefined);

  const handlePreview = (file: UploadFile) => {
    setPreviewFile(file.url);
  };

  const handleDownload = async (file: UploadFile) => {
    window.open(file.url);
  };

  const handleRemoveFile = async (file: UploadFile) => {
    Modal.confirm({
      title: t("delete"),
      content: t("it_will_be_deleted_r_you_sure"),
      okText: t("approve"),
      cancelText: t("cancel"),
      onOk: async () => {
        let res = await InventoryService.removeFile(file.name);
        if (res) {
          const updatedFileList = fileList.filter((f) => f.uid !== file.uid);
          setFileList(updatedFileList);
        }
      },
    });
  };

  const getInitialFileList = async (prefix: string) => {
    let resp = await InventoryService.getImageListByInventory(prefix);
    setFileList(resp.data);
  };

  useEffect(() => {
    if (selectedInventory) {
      getInitialFileList(
        selectedInventory.city_id + "_" + selectedInventory.barcode + "_"
      );
    }
  }, [selectedInventory]);

  const customItemRender = (
    originNode: ReactElement,
    file: UploadFile,
    fileList: object[]
  ) => {
    return (
      <div className="ant-upload-list-picture-card-container">
        <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
          <div className="ant-upload-list-item-info">
            <span className="ant-upload-span">
              <a
                className="ant-upload-list-item-thumbnail"
                href={file.thumbUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={file.url}
                  alt={file.name}
                  className="ant-upload-list-item-image"
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="ant-upload-list-item-name"
                title={file.name}
                href={file.url}
              >
                {file.name}
              </a>
            </span>
          </div>
          <span className="ant-upload-list-item-actions">
            <div>
              <Tooltip title={t("preview_file")}>
                <Button
                  type="text"
                  icon={<EyeOutlined />}
                  onClick={() => handlePreview(file)}
                />
              </Tooltip>
              <Tooltip title={t("download_file")}>
                <Button
                  type="text"
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownload(file)}
                />
              </Tooltip>
              <Tooltip title={t("remove_file")}>
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemoveFile(file)}
                />
              </Tooltip>
            </div>
          </span>
        </div>
      </div>
    );
  };

  const customRequest = async (options: any) => {
    const { file, onSuccess, onError } = options;
    const fileExtension = file.name.split(".").pop();
    let largestNumber = 0;

    fileList.forEach((obj) => {
      const uid = parseInt(obj.uid, 10);
      if (!isNaN(uid) && uid > largestNumber) {
        largestNumber = uid;
      }
    });
    const newFileName = `${selectedInventory.city_id}_${
      selectedInventory.barcode
    }_${largestNumber + 1}.${fileExtension}`;
    const formData = new FormData();
    formData.append("file", file, newFileName);
    try {
      const response = await InventoryService.uploadFile(formData);
      setFileList((prev) => [...prev, response.data]);
      onSuccess(response.data, file);
    } catch (error) {
      console.error("Dosya yükleme hatası:", error);
      onError(error);
    }
  };

  return (
    <>
      <Upload
        listType="picture-card"
        fileList={fileList}
        itemRender={customItemRender}
        customRequest={customRequest}
      >
        {`+ ${t("upload")}`}
      </Upload>
      {previewFile && (
        <Image
          preview={{
            visible: true,
            src: previewFile,
            onVisibleChange: (value) => {
              setPreviewFile("");
            },
          }}
        />
      )}
    </>
  );
}

export default InventoryFiles;
