import { useMemo, useContext, useState, useEffect } from "react";
import { Col, InputNumber, Modal, Row, Select } from "antd";
import planFormPhasesTableFields from "../formFieldStructs/planFormPhasesTableFields";
import { useTranslation } from "react-i18next";
import CoordinationInterruptState from "../../../models/CoordinationInterruptState";
import JunctionPhaseService from "../../../../../../data/junction-phase/JunctionPhaseService";
import { useJunctionData } from "../../../../../../contexts/junction/JunctionDataContext";

// get the necessary field names
const {
  partnerJunctionId: partnerJunctionIdKey,
  coordinationStartPhase: coordinationStartPhaseKey,
  coordinationEndPhase: coordinationEndPhaseKey,
  coordinationStartOffset: coordinationStartOffsetKey,
  coordinationEndOffset: coordinationEndOffsetKey,
} = planFormPhasesTableFields;

const colStyle = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  margin: "0",
};
interface Props {
  modalState: CoordinationInterruptState;
  setIsModalVisible: any;
  updatePhasesForm: (field: any, phaseKey: any, value: any) => void;
}

const CoordinationInterruptedModal: React.FC<Props> = ({
  modalState,
  setIsModalVisible,
  updatePhasesForm,
}) => {
  const { t } = useTranslation();
  const rowPhaseId = useMemo(() => modalState.phaseId, [modalState]);
  const junctions = useJunctionData().junctionList;

  const [junctionId, setJunctionId] = useState<string>("");
  const [relatedJunctionPhaseList, setRelatedJunctionPhaseList] = useState<{ label: string; value: number }[]>();
  const [startPhase, setStartPhase] = useState<number>();
  const [endPhase, setEndPhase] = useState<number>();
  const [startOffset, setStartOffset] = useState<number>(0);
  const [endOffset, setEndOffset] = useState<number>(0);

  const handleModalOk = () => {
    setIsModalVisible(false);

    updatePhasesForm(coordinationStartPhaseKey, rowPhaseId, startPhase);
    updatePhasesForm(coordinationEndPhaseKey, rowPhaseId, endPhase);
    updatePhasesForm(coordinationStartOffsetKey, rowPhaseId, startOffset);
    updatePhasesForm(coordinationEndOffsetKey, rowPhaseId, endOffset);
    updatePhasesForm(partnerJunctionIdKey, rowPhaseId,  parseInt(junctionId));
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const changeJunction = async (junction_id: string) => {
    if (modalState.rowData?.partnerJunctionId != junction_id) {
      clearModalFormValue();
    }
    if (junction_id) {
      setJunctionId(junction_id);
      await JunctionPhaseService.get(parseInt(junction_id)).then((res) => {
        let list = res.data.map((val) => {
          return {
            label: val.name,
            value: val.internal_id,
          };
        });
        setRelatedJunctionPhaseList(list);
      });
    }else {
      setJunctionId("")
    }
  };
  
  const clearModalFormValue = () => {
    setStartPhase(undefined);
    setEndPhase(undefined);
    setRelatedJunctionPhaseList(undefined);
    setStartOffset(0);
    setEndOffset(0);
  }

  useEffect(() => {
    setJunctionId(modalState.rowData?.partnerJunctionId ?? "");
    setStartPhase(modalState.rowData?.coordinationStartPhase ?? undefined);
    setEndPhase(modalState.rowData?.coordinationEndPhase ?? undefined);
    setStartOffset(modalState.rowData?.coordinationStartOffset ?? 0);
    setEndOffset(modalState.rowData?.coordinationEndOffset ?? 0);

    if (modalState.rowData?.partnerJunctionId) {
        changeJunction(modalState.rowData?.partnerJunctionId.toString());
    }
  },[modalState.rowData])

  // control modal button visible
  // useEffect(() => {
  //   if (junctionId && junctionId !== "" && startPhase && endPhase) {
  //       setModalButtonVisible(false);
  //   } else {
  //       setModalButtonVisible(true);
  //   }
  // }, [startPhase,endPhase,junctionId])
  

  return (
    <>
      <Modal
        title={t("coordinated_interrupt")}
        visible={modalState.visible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        //okButtonProps={{disabled: modalButtonVisible}}
        centered
        maskClosable={false}
      >
        <Row>
          <Col span={5}>
            <p style={colStyle}>{t("junction")}:</p>
          </Col>
          <Col span={19}>
            <Select
              placeholder={t("select")}
              style={{ width: "100%", overflow: "hidden" }}
              options={junctions.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })}
              onChange={(value) => {
                changeJunction(value);
              }}
              allowClear
              value={junctionId}
            ></Select>
          </Col>
        </Row>

        {relatedJunctionPhaseList && (
          <>
            <Row>
              <Col span={5}>
                <p style={colStyle}>{t("start_phase")}:</p>
              </Col>
              <Col span={19}>
                <Select
                  placeholder={t("select")}
                  style={{ width: "100%", overflow: "hidden" }}
                  options={relatedJunctionPhaseList.filter(
                    (item) => item.value !== endPhase
                  )}
                  onChange={(value) => {
                    setStartPhase(value);
                  }}
                  value={startPhase}
                ></Select>
              </Col>
            </Row>

            {startPhase && (
              <>
                <Row>
                  <Col span={5}>
                    <p style={colStyle}>{t("finish_phase")}:</p>
                  </Col>
                  <Col span={19}>
                    <Select
                      placeholder={t("select")}
                      style={{ width: "100%", overflow: "hidden" }}
                      options={relatedJunctionPhaseList?.filter(
                        (item) => item.value !== startPhase
                      )}
                      onChange={(value) => {
                        setEndPhase(value);
                      }}
                      value={endPhase}
                    ></Select>
                  </Col>
                </Row>
                <Row>
                  <Col span={5}>
                    <p style={colStyle}>{t("start_offset")}:</p>
                  </Col>
                  <Col span={19}>
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder={t("enter_offset")}
                      onChange={(val: any) => {
                        setStartOffset(val);
                      }}
                      value={startOffset}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={5}>
                    <p style={colStyle}>{t("end_offset")}:</p>
                  </Col>
                  <Col span={19}>
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder={t("enter_offset")}
                      onChange={(val: any) => {
                        setEndOffset(val);
                      }}
                      value={endOffset}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default CoordinationInterruptedModal;
