import { Tabs } from "antd";
import { t } from "i18next";
import { Barrier } from "../../data/barrier/Barrier";
import BarrierControl from "./BarrierControl";
import BarrierPastRecord from "./BarrierPastRecord";

const { TabPane } = Tabs;
interface Props {
  selectedBarrier: Barrier;
}

const BarrierMain = (props: Props) => {
  const { selectedBarrier } = props;

  return (
    <Tabs destroyInactiveTabPane>
      <TabPane tab={t("control_barrier")} key="1">
        <BarrierControl selectedBarrier={selectedBarrier} />
      </TabPane>
      <TabPane tab={t("past_record")} key="2">
        <BarrierPastRecord selectedBarrier={selectedBarrier} />
      </TabPane>
    </Tabs>
  );
};

export default BarrierMain;
