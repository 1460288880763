import { Button, Col, DatePicker, DatePickerProps, Form, Popover, Row, Statistic, Table, Tag, Space, Card, message } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { AxiosResponse } from "axios";
import moment, { Moment } from "moment";
import React, { useCallback, useState } from "react";
import { performanceDateFilter } from "../services/PerformanceAnalysisFilter";
import { performanceList } from "../services/PerformanceAnalysisList";
import "./style.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FaPercentage, FaGasPump, FaEnvira } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from "@ant-design/icons";
import { useJunctionData } from "../../../contexts/junction/JunctionDataContext";
interface DataType {
  id: bigint;
  junction_id: number;
  recovery_ratio: number;
  saved_fuel: number;
  saved_co2: number;
  ts?: number;
  min_ts?: number;
  max_ts?: number;
  [key: string]: any;
}
type NullableFilterValue = FilterValue | null;
interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, NullableFilterValue>;
}
export interface PerformanceFilter {
  startDate: number;
  endDate: number;
}

const getRandomParams = (params: TableParams) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const { REACT_APP_API_URL_DEV, REACT_APP_HOST_TYPE } =  process.env;
const baseURL =  REACT_APP_HOST_TYPE === "dev" ? REACT_APP_API_URL_DEV : window.location.origin;

const PerformancePage: React.FC = () => {
  const [data, setData] = useState<DataType[] | []>([]);
  const [reportData, setReportData] = useState<DataType[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({});
  const dataWithKeys =
    data.length > 0 ? data.map((item, index) => ({ ...item, key: index })) : [];
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [year, setYear] = useState<string | null>(null);
  const [week, setWeek] = useState<string | null >(null);
  const { junctionList } = useJunctionData();
  const { t,i18n } = useTranslation();

  const columns: ColumnsType<DataType> = [
    {
      title: t("junction"),
      dataIndex: "junction_id",
      width: "30%",
      render: (junction_id) => <Tag color="purple">{junction_id}</Tag>,
    },
    {
      title: t("improvement_ratio"),
      dataIndex: "recovery_ratio",
      width: "15%",
      sorter: (a, b) => a.recovery_ratio - b.recovery_ratio,
      render: (recovery_ratio) => {
        if (recovery_ratio != null) {
          const formattedValue = (recovery_ratio * 100).toFixed(3) + " %";

          return (
            <Tag color={Math.sign(recovery_ratio) == 1 ? "green" : "red"}>
              {formattedValue}
            </Tag>
          );
        }
      },
    },
    {
      title: t("saved_fuel"),
      dataIndex: "saved_fuel",
      width: "15%",
      sorter: (a, b) => a.saved_fuel - b.saved_fuel,
      render: (saved_fuel) => {
        if (saved_fuel != null) {
          const roundedValue = (saved_fuel * 1000) / 1000;
          const formattedValue = roundedValue.toFixed(3) + " L";
          return (
            <Tag color={Math.sign(roundedValue) == 1 ? "green" : "red"}>
              {formattedValue}
            </Tag>
          );
        }
      },
    },
    {
      title: t("saved_co2"),
      dataIndex: "saved_co2",
      width: "15%",
      sorter: (a, b) => a.saved_co2 - b.saved_co2,
      render: (saved_co2) => {
        if (saved_co2 != null) {
          const formattedValue = saved_co2?.toFixed(3) + " t";
          return (
            <Tag color={Math.sign(saved_co2) == 1 ? "green" : "red"}>
              {formattedValue}
            </Tag>
          );
        }
      },
    },
    {
      title: t("date"),
      dataIndex: "min_ts",
      render: (min_ts, record) => {
        const { max_ts } = record;
        if (max_ts !== undefined && max_ts !== min_ts) {
          const minDate = moment
            .unix(min_ts)
            .utcOffset(localStorage.getItem("timezone")!)
            .format("DD/MM/YYYY");
          const maxDate = moment
            .unix(max_ts)
            .utcOffset(localStorage.getItem("timezone")!)
            .format("DD/MM/YYYY");
          const formattedValue = `${minDate} - ${maxDate}`;
          return <Tag color="blue">{formattedValue}</Tag>;
        } else {
          const formattedValue = moment(min_ts).format("DD/MM/YYYY");
          return <Tag color="blue">{formattedValue}</Tag>;
        }
      },
    },
  ];

  const handleFilter = useCallback(() => {
    if (startDate && endDate) {
      const filters: PerformanceFilter = {
        startDate: moment(startDate).unix(),
        endDate: moment(endDate).unix(),
      };
      performanceDateFilter(filters).then((res: AxiosResponse) => {
        const junctionData: Record<string, string> = {};
        junctionList.forEach((junction) => {
          junctionData[junction.id] = junction.name;
        });
        if (res.data.length > 0) {
          setData(
            res.data.map((item: any) => ({
              ...item,
              junction_id: junctionData[item.junction_id]
                ? junctionData[item.junction_id]
                : item.junction_id,
            }))
          );
          setLoading(false);
        } else {
          setData([]);
        }
      });
      performanceList(filters).then((res: AxiosResponse) => {
        const junctionData: Record<string, string> = {};
        junctionList.forEach((junction) => {
          junctionData[junction.id] = junction.name;
        });
        if (res.data.length > 0) {
          setReportData(
            res.data.map((item: any) => ({
              ...item,
              junction_id: junctionData[item.junction_id]
                ? junctionData[item.junction_id]
                : item.junction_id,
            }))
          );
          setLoading(false);
        } else {
          setReportData([]);
        }
      });
    } else {
      setData([]);
      setReportData([]);
    }
  }, [startDate, endDate]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, NullableFilterValue> | undefined,
    sorter: SorterResult<DataType> | SorterResult<DataType>[]
  ) => {
    setTableParams({
      filters: filters || {},
      ...(Array.isArray(sorter) ? sorter[0] : sorter),
    });
  };

  const handleReport = () => {
    const doc = new jsPDF();
    const headerRow = columns.map((column) => column.title);
    const turkishCharacters: { [key: string]: string } = {
      ı: "i",
      İ: "I",
      ş: "s",
      Ş: "S",
      ğ: "g",
      Ğ: "G",
    };

    function groupArrayByProp(arr:any[], property:string) {
     
  };

    function turkishCharacterConversion(text: string) {
      return text.replace(
        /[ıİşŞğĞ]/g,
        (matched: string) => turkishCharacters[matched]
      );
    }
    console.log(reportData)
    const result = (reportData as any).reduce((acc:any, cur:any) => {
      const { junction_id, saved_fuel, saved_co2,recovery_ratio,ts } = cur;
      const index = acc.findIndex((item:any) => item.junction_id === junction_id);
      
      if (index === -1) {
        acc.push({
            junction_id: junction_id, 
            saved_fuel: saved_fuel, 
            saved_co2: saved_co2,
            recovery_ratio: recovery_ratio,
            count: 1,
            ts: ts
        });
      } else {
        acc[index].saved_fuel += saved_fuel;
        acc[index].saved_co2 += saved_co2;
        acc[index].recovery_ratio += recovery_ratio;
        acc[index].count += 1;
      }
      
      return acc;
  }, []);
    const dataRows = result.map((item:any) => [
      item.junction_id
        ? turkishCharacterConversion(item.junction_id.toString())
        : "",
      item.recovery_ratio ? ((item.recovery_ratio * 100)/item.count).toFixed(3) + " %" : "",
      item.saved_fuel
        ? (Math.ceil(item.saved_fuel * 1000) / 1000).toFixed(3) + " L"
        : "",
      item.saved_co2 ? item.saved_co2.toFixed(3) + " t" : "",
      moment.unix(item.ts ? item.ts / 1000 : 0).format("DD/MM/YYYY HH:mm:ss"),
    ]);
    // @ts-ignore
    doc.autoTable({
      head: [headerRow],
      body: dataRows,
      foot: [
        [
          t("average")+":   ",
          (renderAverageValue(reportData, "recovery_ratio") * 100).toFixed(3) +
            " %",
          renderAverageValue(reportData, "saved_fuel").toFixed(3) + "L",
          renderAverageValue(reportData, "saved_co2").toFixed(3) + "T",
          "",
        ],
        [
          t("total")+": ",
          "--",
          renderTotalValue(reportData, "saved_fuel").toFixed(3) + "L",
          renderTotalValue(reportData, "saved_co2").toFixed(3) + "T",
          "",
        ],
      ],
      styles: {
        font: "helvetica",
        fontSize: 9,
        overflow: "ellipsize",
      },
      headStyles: {
        fillColor: "#3c3e44",
        textColor: "#ffffff",
        cellPadding: 3,
      },
      footStyles: {
        fillColor: [217, 217, 214],
        textColor: [0, 0, 0],
        fontSize: 12,
      },
      showFoot: "lastPage",
    });
    const start = moment(startDate).format("DD/MM/YYYY");
    const end = moment(endDate).format("DD/MM/YYYY");
    doc.save(`performance_report_${start}__${end}.pdf`);
  };
  const renderAverageValue = (data: DataType[], dataIndex: string) => {
    const sum = data.reduce((acc, curr) => acc + (curr[dataIndex] || 0), 0);
    const average = sum / data.length;
    return average;
  };
  const renderTotalValue = (data: DataType[], dataIndex: string) => {
    const sum = data.reduce((acc, curr) => acc + (curr[dataIndex] || 0), 0);
    return sum;
  };

  const downloadClick = () => {

    const language = i18n.language;

    if(year && week){
      const path = `/file/performance/${year}/${week}/${language}/performance.pdf`;
    
      fetch(`${baseURL}${path}`)
        .then((response) => {
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = `performance_${year}_${week}_${language}.pdf`;
            response.ok ? a.click() : message.error(t("notfound"));
          });
        })
    }else{
      message.warning(t("documentDateNotDetermined"));
    }
    
  }

  const changedWeek: DatePickerProps['onChange']  = (date, dateString) => {
    if(dateString){
      const str = dateString;
      const parts = str.split("-");

      const year = (parts[0]);
      const week = parts[1].replace(/\D/g, '');

      setYear(year);
      setWeek(week);
    }else {
      setYear(null);
      setWeek(null);
    }
  }

  const downloadContent = () => (
    <Card 
      style={{ margin: "-20px"}}
      title= {t("documentDetails")}
      actions={[
        <Button onClick={downloadClick} type="primary">{t("download")}</Button>
      ]}
    >
      <Space>
        <span style={{ marginRight: ".6rem" }}>{t("week")}:</span>
        <DatePicker onChange={changedWeek} picker="week" />
      </Space>
    </Card>
  )

  const renderAverageColumns = () => (
    <Row
      gutter={16}
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        marginBottom: "1rem",
      }}
    >
      <Col>
        <div className="average-column">
          <Statistic
            title={t("overall_average_improvement_ratio")}
            value={(renderAverageValue(data, "recovery_ratio") * 100).toFixed(
              3
            )}
            prefix={<FaPercentage />}
          />
        </div>
      </Col>
      <Col>
        <div className="average-column">
          <Statistic
            title={t("overall_average_saved_fuel")}
            value={renderAverageValue(data, "saved_fuel").toFixed(3) + "L"}
            prefix={<FaGasPump />}
          />
        </div>
      </Col>
      <Col>
        <div className="average-column">
          <Statistic
            title={t("total_saved_fuel")}
            value={renderTotalValue(data, "saved_fuel").toFixed(3) + "L"}
            prefix={<FaGasPump />}
          />
        </div>
      </Col>
      <Col>
        <div className="average-column">
          <Statistic
            title={t("overall_average_saved_co2")}
            value={renderAverageValue(data, "saved_co2").toFixed(3) + "T"}
            prefix={<FaEnvira />}
          />
        </div>
      </Col>
      <Col>
        <div className="average-column">
          <Statistic
            title={t("total_saved_co2")}
            value={renderTotalValue(data, "saved_co2").toFixed(3) + "T"}
            prefix={<FaEnvira />}
          />
        </div>
      </Col>
    </Row>
  );

  return (
    <>
      <h3>{t("performance_analysis")}</h3>
      <div
        style={{
          width: "100%",
          marginBottom: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span style={{ marginRight: ".6rem" }}>{t("start_date")}:</span>
        <DatePicker
          style={{ marginRight: "2rem" }}
          value={startDate}
          onChange={(date) => setStartDate(date)}
          showTime
        />
        <span style={{ marginRight: ".6rem" }}>{t("end_date")}:</span>

        <DatePicker
          style={{ marginRight: "2rem" }}
          value={endDate}
          onChange={(date) => setEndDate(date)}
          showTime
        />
        <Button.Group>
          <Button type="primary" onClick={handleFilter}>
            {t("filter")}
          </Button>
          {reportData.length > 0 && (
            <Button
              type="primary"
              style={{ marginLeft: ".6rem" }}
              onClick={handleReport}
            >
              {t("reporting")}
            </Button>
          )}
          <div style={{ marginLeft: "30px" }}>
            <Popover placement="topLeft" content={t("downloadPerformanceDocument")}>
              <Popover 
                trigger= "click"
                placement= "bottomLeft"
                content= {downloadContent}
              >
                <Button
                >
                  <DownloadOutlined style={{fontSize: "20px", color: "green"}}/>
                </Button>
              </Popover>
            </Popover>
          </div>
        </Button.Group>
      </div>
      {data.length > 0 && (
        <div className="average-columns-container">
          {renderAverageColumns()}
        </div>
      )}

      <Table
        className="performance-table"
        columns={columns}
        dataSource={dataWithKeys}
        loading={loading}
        onChange={handleTableChange}
        pagination={false}
        scroll={{ y: 600 }}
      />
    </>
  );
};

export default PerformancePage;
