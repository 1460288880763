import React, { useEffect } from "react";

interface WithLoggingProps {
  componentName: string;
}
const { REACT_APP_HOST_TYPE } = process.env;
const checkDevelopment = REACT_APP_HOST_TYPE === "dev" ? true : false;

function withLogging<T>(WrappedComponent: React.FC<T>) {
  const WithLogging: React.FC<T & WithLoggingProps> = (props) => {
    useEffect(() => {
      console.log(`${props.componentName} component has mounted.`);

      return () => {
        console.log(`${props.componentName} component will unmount.`);
      };
    }, [props.componentName]);

    return <WrappedComponent {...props} />;
  };

  return checkDevelopment ? WithLogging : WrappedComponent;
}

export default withLogging;
