import { Button, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IContentData } from "../..";
import { ColumnsType } from "antd/lib/table";
import { Predicted } from "../../../../../../../data/ai-prediction/Predicted";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";

interface Props {
  contentData: any;
}
const AiPrediction: React.FC<Props> = ({ contentData }) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    let data = contentData?.map((item:any) => {
        let predicted = item.predictedCounts.map((predicted:any) => {
            let value = {
                junction_id: item.junction_id,
                detectorId: predicted.detectorId,
                ts: moment(item.ts).utcOffset(localStorage.getItem("timezone")!).format("DD.MM.YY HH:mm"),
                past10min: predicted.past10min,
                past30min: predicted.past30min,
                past45min: predicted.past45min,
                past60min: predicted.past60min,
                "10min": predicted["10min"],
                "30min": predicted["30min"],
                "45min": predicted["45min"],
                "60min": predicted["60min"],
            }
            return value;
        })
        return predicted;
    })
    setDataSource(data.flat(1));
  },[contentData])
  const columns: ColumnsType<Predicted> = [
    {
      title: t("date"),
      dataIndex: "ts",
      key: "ts",
      align: "center",
    },
    {
      title: `${t("junction_id")}`,
      dataIndex: "junction_id",
      key: "junction_id",
      align: "center",
    },
    {
      title: `${t("detector_no")}`,
      dataIndex: "detectorId",
      key: "detectorId",
      align: "center",
    },
    {
      title: `${t("past") + " 10 " + t("minutes")}`,
      dataIndex: "past10min",
      key: "past10min",
      align: "center",
    },
    {
      title: `${t("past") + " 30 " + t("minutes")}`,
      dataIndex: "past30min",
      key: "past30min",
      align: "center",
    },
    {
      title: `${t("past") + " 45 " + t("minutes")}`,
      dataIndex: "past45min",
      key: "past45min",
      align: "center",
    },
    {
      title: `${t("past") + " 60 " + t("minutes")}`,
      dataIndex: "past60min",
      key: "past60min",
      align: "center",
    },
    {
      title: `${"10 " + t("minutes")}`,
      dataIndex: "10min",
      key: "10min",
      align: "center",
    },
    {
      title: `${"30 " + t("minutes")}`,
      dataIndex: "30min",
      key: "30min",
      align: "center",
    },
    {
      title: `${"45 " + t("minutes")}`,
      dataIndex: "45min",
      key: "45min",
      align: "center",
    },
    {
      title: `${"60 " + t("minutes")}`,
      dataIndex: "60min",
      key: "60min",
      align: "center",
    },
  ];

  const headers = [
    { label: `${t("date")}`, key: "ts" },
    { label: `${t("detector_no")}`, key: "detectorId" },
    { label: `${t("past") + " 10 " + t("minutes")}`, key: "past10min" },
    { label: `${t("past") + " 30 " + t("minutes")}`, key: "past30min" },
    { label: `${t("past") + " 45 " + t("minutes")}`, key: "past45min" },
    { label: `${t("past") + " 60 " + t("minutes")}`, key: "past60min" },
    { label: `${"10 " + t("minutes")}`, key: "10min" },
    { label: `${"30 " + t("minutes")}`, key: "30min" },
    { label: `${"45 " + t("minutes")}`, key: "45min" },
    { label: `${"60 " + t("minutes")}`, key: "60min" },
  ];

  return (
    <>
      {dataSource && (
        <>
        <Button
            disabled={dataSource.length > 0 ? false : true}
            style={{
              color: "white",
              background: "#808080",
              width: "100%",
              marginBottom: "10px",
            }}
            icon={<DownloadOutlined />}
          >
            <CSVLink
              filename={t("ai_prediction")}
              data={dataSource}
              headers={headers}
              style={{ color: "white", background: "#808080", width: "100%" }}
            >
              {t("download_csv_data")}
            </CSVLink>
          </Button>
          <Table
            rowKey="id"
            dataSource={dataSource}
            columns={columns}
            pagination={{pageSize:10}}
          />
        </>
      )}
    </>
  );
};

export default AiPrediction;
