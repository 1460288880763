import React, { useContext } from "react";
import { StatisticContext } from "../../context/StatisticContext";
import FilterAreaForDaily from "./components/FilterArea/FilterAreaForDaily";
import FilterAreaForMonthly from "./components/FilterArea/FilterAreaForMonthly";
import FilterAreaForWeekly from "./components/FilterArea/FilterAreaForWeekly";
interface Props {
  selectedJunction: any;
  isHaveLoop: boolean;
}
const VehicleDensity: React.FC<Props> = ({ selectedJunction, isHaveLoop }) => {
  const statisticContextValues = useContext(StatisticContext);

  return (
    <>
      <div style={{ marginTop: "25px" }}>
        {statisticContextValues?.headerDirector.vehicleDensity.daily && (
          <FilterAreaForDaily
            selectedJunction={selectedJunction}
            isHaveLoop={isHaveLoop}
          ></FilterAreaForDaily>
        )}
        {statisticContextValues?.headerDirector.vehicleDensity.weekly && (
          <FilterAreaForWeekly
            selectedJunction={selectedJunction}
            isHaveLoop={isHaveLoop}
          ></FilterAreaForWeekly>
        )}
        {statisticContextValues?.headerDirector.vehicleDensity.monthly && (
          <FilterAreaForMonthly
            selectedJunction={selectedJunction}
            isHaveLoop={isHaveLoop}
          ></FilterAreaForMonthly>
        )}
      </div>
    </>
  );
};

export default VehicleDensity;
