import http from "../http.common";
import { Junction } from "./Junction";
import { JunctionFile } from "./JunctionFile";



const getDetail = function (junctionId:number) {
  return http.post<any>(`/web/junction/junction/getDetail`, { junction_id: junctionId});
};

const getDirections = function (junctionId:number) {
  return http.post<any>(`/web/junction/junction/getDirections`, { junction_id: junctionId});
};

const updateDirections = function (directions:any,junctionId:number) {
  return http.post<boolean>(`/web/junction/junction/updateDirections`, { junction_id: junctionId,directions:directions });
};

const getJunctions = function () {
  return http.get<Junction[]>(`/web/junction/junction/list`);
};
const insertJunction = function (data: Junction) {
  return http.post<Junction>(`/web/junction/junction/add`, data);
};
const updateJunction = function (data: Junction) {
  return http.post<Junction>(`/web/junction/junction/update`, data);
};
const fileUpload = function (file: any, junctionId: number) {
  return http.post<Junction>(
    `/web/fileserver/upload/junction/${junctionId}`,
    file, 
  );
};
const fileUploadJunctionImage = function (file: any, junctionId: number) {
  return http.post<Junction>(
    `/web/fileserver/upload/junctionImage/${junctionId}`,
    file, 
  );
};
const getFiles = function (junctionId: number) {
  return http.post<JunctionFile[]>(`/web/junction/junction/files`, { junction_id: junctionId });
};

const deleteJunction = function (data: Junction) {
  return http.post<Junction>(`/web/junction/junction/delete`, data);
};

const getDeviceInfo = function (junctionId:number) {
  return http.post<any>(`/web/junction/junction/deviceinformation`,{junction_id:junctionId});
};

const getKKCMode = function (junctionId:number, guid:number) {
  return http.post<any>(`/web/junction/manage/getkkcmode`,{junction_id:junctionId,guid:guid});
};

const setKKCMode = function (junctionId:number, mode:string,guid:number) {
  return http.post<any>(`/web/junction/manage/setkkcmode`,{junction_id:junctionId, mode:mode, guid:guid});
};

const setPoliceMode = function (jsonData:any) {
  return http.post<any>(`/web/junction/manage/setPoliceMode`,jsonData);
};

const getKKCTime = function (junctionId:number, guid:number) {
  return http.post<any>(`/web/junction/manage/getkkctime`,{junction_id:junctionId, guid:guid});
};

const resetKKC = function (junctionId:number, guid:number) {
  return http.post<any>(`/web/junction/manage/resetkkc`,{junction_id:junctionId, guid:guid});
};

const setKKCProgram = function (file:any, junctionId:number, guid: number) {
  return http.post<any>(`/web/junction/manage/setkkcprogram`,file, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    params:{
      junction_id:junctionId,
      guid: guid
    }
  });
};

const getKKCProgram = function (junctionId:number, guid: number) {
  return http.post<any>(`/web/junction/manage/getkkcprogram`,{junction_id:junctionId, guid:guid});
};

const getPoliceModeData= function() {
  return http.post<any>(`/web/junction/junction/getPoliceModeData`);
}

const addPoliceModeData = function (police_mode_data:any) {
  return http.post<any>(`/web/junction/junction/addPoliceModeData`,{police_mode_data});
};

const getVipPassPlansData = function(junctionIds:any) {
  return http.post<any>(`/web/junction/junction/getVipPassPlans`, {junctionIds:junctionIds});
}

const getVipPassActiveJunctions = function () {
  return http.post<Junction[]>(`/web/junction/junction/getVipPassActiveJunctions`);
};

const setVipPassPlansData = function(dat:any) {
  return http.post<any>(`/web/junction/junction/setVipPassPlans`, {dat:dat});
}

const cancelVipPass = function(junctionIds:number[]) {
  return http.post<any>(`/web/junction/junction/cancelVipPass`, {junctionIds:junctionIds});
}

const upsertJunctionStaticImage = function(junction_id:number, latitude: number, longitude: number) {
  return http.post<any>(`/web/junction/junction/upsertJunctionStaticImage`, {junction_id,latitude,longitude});
}

const getJunctionStaticImage = function (junction_id:number) {
  return http.post<any>(`/web/junction/junction/getJunctionStaticImage`, {junction_id});
};

const deleteJunctionStaticImage = function (junction_id:number) {
  return http.post<any>(`/web/junction/junction/deleteJunctionStaticImage`, {junction_id});
};

const JunctionService = {
  getJunctions,
  insertJunction,
  updateJunction,
  fileUpload,
  getFiles,
  deleteJunction,
  getDeviceInfo,
  getKKCMode,
  setKKCMode,
  setPoliceMode,
  getKKCTime,
  resetKKC,
  setKKCProgram,
  getKKCProgram,
  getDirections,
  updateDirections,
  getDetail,
  addPoliceModeData,
  getPoliceModeData,
  getVipPassPlansData,
  setVipPassPlansData,
  getVipPassActiveJunctions,
  cancelVipPass,
  fileUploadJunctionImage,
  upsertJunctionStaticImage,
  getJunctionStaticImage,
  deleteJunctionStaticImage
};

export default JunctionService;