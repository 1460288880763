import {
  addTask,
  updateTask,
} from "../../../../../../data/task-management/TaskManagementService";
import { MarkerForm } from "./MarkerForm";

export const TaskModel: MarkerForm = {
  type: "task",
  addService: addTask,
  updateService: updateTask,
  items: [
    {
      label: "title",
      name: "title",
      type: "input",
      isRequired: true,
    },
    {
      label: "description",
      name: "description",
      type: "text-area",
      isRequired: false,
    },
    {
      label: "latitude",
      name: "latitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "longitude",
      name: "longitude",
      type: "number",
      isRequired: true,
    },
    {
      label: "task_type",
      name: "task_type",
      type: "dropdown",
      isRequired: true,
      dropdownItem: "taskTypeList",
    },
    {
      label: "priority",
      name: "priority",
      type: "dropdown",
      isRequired: true,
      dropdownItem: "priorityList",
      notSearchable: true,
    },
    {
      label: "est_time",
      name: "est_time",
      type: "input",
      placeholder: "Örn. 30 dakika, 3 gün",
      isRequired: false,
    },
    {
      label: "start_time",
      name: "start_time",
      type: "date-picker",
      isRequired: true,
    },
    {
      label: "end_time",
      name: "end_time",
      type: "date-picker",
      isRequired: false,
    },
    {
      label: "attendant_list",
      name: "attendant_list",
      type: "dropdown",
      isRequired: true,
      dropdownItem: "technicianList",
      isMulti: true,
    },
  ],
};
