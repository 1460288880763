export enum IconType {
    AlertOutlined = "alert",
    WarningOutlined = "warning",
    BorderInnerOutlined = "border",
    QuestionOutlined = "question",
    ExclamationOutlined = "exclamation",
    CheckCircleOutlined = "check",
    PauseOutlined = "pause",
    PlusCircleOutlined = "plus",
    AreaChartOutlined = "areachart",
    FallOutlined = "fall",
    RiseOutlined = "rise",
    FundOutlined = "fund",
    AimOutlined = "aim",
    BellOutlined = "bell",
    CarOutlined = "car",
    CommentOutlined = "comment",
    CompressOutlined = "compress",
    DingtalkOutlined = "talk",
    DesktopOutlined = "desktop",
    DownloadOutlined = "download",
    ExceptionOutlined = "exception",
    FileOutlined = "file",
    FireOutlined = "fire",
    HomeOutlined = "home",
    LikeOutlined = "like",
    MehOutlined = "meh",
    ReloadOutlined = "update",
    SmileOutlined = "smile",
    SettingOutlined = "setting",
    LineOutlined = "line",
    SendOutlined = "send",
    SyncOutlined = "sync",
    UserOutlined = "user",
    SoundOutlined = "sound",
    VideoCameraOutlined = "camera",
    TagOutlined = "tag",
    SaveOutlined = "save",
    DeleteOutlined = "delete",
    SwapOutlined = "swap",
    PlayCircleOutlined ="play"
  }