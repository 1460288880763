import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Barrier } from "../../data/barrier/Barrier";
import BarrierService from "../../data/barrier/BarrierService";
import { PermissionContext, PermissionDataProps } from "@icms/ui-components";

export interface BarrierDataContextState {
  barrierList: Barrier[];
  updateAllBarier: () => void;
}

const BarrierDataContext = createContext<BarrierDataContextState | undefined>(
  undefined
);

export function BarrierDataProvider({ children }: PropsWithChildren<{}>) {
  const [barriers, setBarriers] = useState<Barrier[]>([]);
  const { hasAccessComponent,componentPermissions } = useContext(
    PermissionContext
  ) as PermissionDataProps;

  const getBarriers = async () => {
    await BarrierService.getBarriers()
      .then((res) => {
        setBarriers(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getBarriers();
  }, [componentPermissions]);

  return hasAccessComponent("Barrier Module-READ") ? (
    <BarrierDataContext.Provider
      value={{
        barrierList: barriers,
        updateAllBarier: getBarriers,
      }}
    >
      {children}
    </BarrierDataContext.Provider>
  ) : (
    <>{children}</>
  );
}

export function useBarrierData() {
  const context = useContext(BarrierDataContext);
  if (context === undefined) {
    return {
      barrierList: [],
      updateAllBarier: () => {},
    };
    //throw new Error("useBarrierData must be used within a BarrierDataContext");
  }
  return context;
}
