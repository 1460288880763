import {useState, useEffect ,useRef } from 'react';
import React from 'react';

const GroupLines = ({config,timeTrigger,activeGroups}) => {

      useEffect(() => {    
          
      }, [timeTrigger]);


      function formatToTwoDigits(num) {
        return num < 10 ? '0' + num : num.toString();
    }
    
      return (
        <>     
        {Array.from({ length: config?.GroupLength }, (_, index) => (
              <React.Fragment key={index}>
                  <text
                 id={"gtext1id"+index}
                key={"gtext1"+index}
                y={(index*(config?.GroupPad+config?.GroupHeight))+37} 
                x={2}
                fontFamily="Roboto,Arial, Helvetica, sans-serif"
                fill={activeGroups?.includes(index+1)?"#09F":"#666"}
                fontWeight={activeGroups?.includes(index+1)?"1000":"400"}
                fontSize="11">
                   {activeGroups?.includes(index+1)?(index+1)+"P":(index+1)}
                </text> 
      
                <line key={'gline1'+index} strokeWidth="1px" stroke={"black"} 
                    x1={24} 
                    y1={(index*(config?.GroupPad+config?.GroupHeight))+config.OffsetX+config?.GroupHeight/2} 
                    x2={20} 
                    y2={(index*(config?.GroupPad+config?.GroupHeight))+config.OffsetX+config?.GroupHeight/2} 
                    id={index+"kl"}>
                </line> 

                <text
                 id={"gtext2id"+index}
                key={"gtex21"+index}
                y={(index*(config?.GroupPad+config?.GroupHeight))+37} 
                x={config?.ResolutionX-15}
                fontFamily="Roboto,Arial, Helvetica, sans-serif"
                fill={activeGroups?.includes(index+1)?"#09F":"#666"}
                fontWeight={activeGroups?.includes(index+1)?"1000":"400"}
                fontSize="11">
                    {activeGroups?.includes(index+1)?(index+1)+"P":(index+1)}
                </text>
                <line key={'gline2'+index} strokeWidth="1px" stroke={"black"} 
                    x1={config.ResolutionX-24} 
                    y1={(index*(config?.GroupPad+config?.GroupHeight))+config.OffsetX+config?.GroupHeight/2} 
                    x2={config.ResolutionX-20} 
                    y2={(index*(config?.GroupPad+config?.GroupHeight))+config.OffsetX+config?.GroupHeight/2} 
                    id={index+"kl2"}>
                </line> 

              
           </React.Fragment> 
        ))}
        </> 
      );
  };
  
  export default GroupLines;
  