import { PropsWithChildren } from "react";
import { CustomLayerDataProvider } from "../../contexts/map/CustomLayerContext";
import { MultipleWatchDataProvider } from "../../contexts/map/MultipleWatchContext";
import { UpsDataProvider } from "../../contexts/ups/UpsDataContext";
import { BluesisDataProvider } from "../../contexts/bluesis/BluesisDataContext";
import { CameraDataProvider } from "../../contexts/camera/CameraDataContext";
import { VieroDeviceDataProvider } from "../../contexts/viero/VieroDeviceDataContext";
import { VmsDataProvider } from "../../contexts/vms/VmsDataContext";
import { AllStatusDataProvider } from "../../contexts/device/AllStatusDataContext";
import { WebSocket } from "../../contexts/websocket/WebSocketContext";
import { LicenseDataProvider } from "../../contexts/license/LicenseDataContext";
import { MangoCarDataProvider } from "../../contexts/mango-car/MangoCarDataContext";
import { FCDProvider } from "../../contexts/fcd/FCDContext";
import { TaskManagementDataProvider } from "../../contexts/taskmanagement/TaskManagementDataContext";
import { BarrierDataProvider } from "../../contexts/barrier/BarrierDataContext";
import { InventoryDataProvider } from "../../contexts/inventory/InventoryDataContext";
import { QueueLengthDataProvider } from "../../contexts/junction/QueueLengthContext";
import { UserInfoProvider } from "../../contexts/auth/UserContext";

function ProviderChain({ children }: PropsWithChildren<{}>) {
  return (
    <WebSocket>
      <AllStatusDataProvider>
        <MangoCarDataProvider>
          <LicenseDataProvider>
            <UserInfoProvider>
              <TaskManagementDataProvider>
                <CustomLayerDataProvider>
                  <MultipleWatchDataProvider>
                    <UpsDataProvider>
                      <BluesisDataProvider>
                        <CameraDataProvider>
                          <VieroDeviceDataProvider>
                            <VmsDataProvider>
                              <FCDProvider>
                                <BarrierDataProvider>
                                  <InventoryDataProvider>
                                    <QueueLengthDataProvider>
                                      {children}
                                    </QueueLengthDataProvider>
                                  </InventoryDataProvider>
                                </BarrierDataProvider>
                              </FCDProvider>
                            </VmsDataProvider>
                          </VieroDeviceDataProvider>
                        </CameraDataProvider>
                      </BluesisDataProvider>
                    </UpsDataProvider>
                  </MultipleWatchDataProvider>
                </CustomLayerDataProvider>
              </TaskManagementDataProvider>
            </UserInfoProvider>
          </LicenseDataProvider>
        </MangoCarDataProvider>
      </AllStatusDataProvider>
    </WebSocket>
  );
}

export default ProviderChain;
