const colorPallet = [
  "#000",
  "#f89c30",
  "#2f4050",
  "#dc3856",
  "#6a7b9e",
  "#3f51b5",
  "#436b59",
  "#FFA977",
  "#36a6d6",
  "#74A9B3",
  "#067F94",
  "#018E42",
  "#FC3449",
  "#71C8C1",
  "#FAB648",
  "#FA6648",
  "#273469",
  "#226CE0",
  "#CE2D4F",
  "#E76D83",
  "#03A688",
  "#582C4D",
  "#BDE038",
  "#22BABB",
  "#393D3F",
  "#A1C7E0",
  "#FF81D0",
  "#78290F",
  "#7A577A",
  "#F2B263",
  "#86A69D",


  "#f89c30",
  "#2f4050",
  "#dc3856",
  "#6a7b9e",
  "#3f51b5",
  "#436b59",
  "#FFA977",
  "#36a6d6",
  "#74A9B3",
  "#067F94",
  "#018E42",
  "#FC3449",
  "#71C8C1",
  "#FAB648",
  "#FA6648",
  "#273469",
  "#226CE0",
  "#CE2D4F",
  "#E76D83",
  "#03A688",
  "#582C4D",
  "#BDE038",
  "#22BABB",
  "#393D3F",
  "#A1C7E0",
  "#FF81D0",
  "#78290F",
  "#7A577A",
  "#F2B263",
  "#86A69D",

  "#f89c30",
  "#2f4050",
  "#dc3856",
  "#6a7b9e",
  "#3f51b5",
  "#436b59",
  "#FFA977",
  "#36a6d6",
  "#74A9B3",
  "#067F94",
  "#018E42",
  "#FC3449",
  "#71C8C1",
  "#FAB648",
  "#FA6648",
  "#273469",
  "#226CE0",
  "#CE2D4F",
  "#E76D83",
  "#03A688",
  "#582C4D",
  "#BDE038",
  "#22BABB",
  "#393D3F",
  "#A1C7E0",
  "#FF81D0",
  "#78290F",
  "#7A577A",
  "#F2B263",
  "#86A69D",

  "#f89c30",
  "#2f4050",
  "#dc3856",
  "#6a7b9e",
  "#3f51b5",
  "#436b59",
  "#FFA977",
  "#36a6d6",
  "#74A9B3",
  "#067F94",
  "#018E42",
  "#FC3449",
  "#71C8C1",
  "#FAB648",
  "#FA6648",
  "#273469",
  "#226CE0",
  "#CE2D4F",
  "#E76D83",
  "#03A688",
  "#582C4D",
  "#BDE038",
  "#22BABB",
  "#393D3F",
  "#A1C7E0",
  "#FF81D0",
  "#78290F",
  "#7A577A",
  "#F2B263",
  "#86A69D",




];

export default colorPallet;