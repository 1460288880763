import { BrowserRouter, Routes } from "react-router-dom";
import { PermissionProvider } from "@icms/ui-components";
import { AuthProvider } from "../contexts/auth/AuthContext";
import RouteManager from "./RouteManager";

function AppRoutes() {
  return (
    <PermissionProvider>
      <AuthProvider>
        <BrowserRouter>
          <RouteManager />
        </BrowserRouter>
      </AuthProvider>
    </PermissionProvider>
  );
}
export default AppRoutes;
