import {
  Button,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Segmented,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { Route } from "../../../data/bluesis/Route";
import { Vector } from "../../../data/bluesis/Vector";
import { Bluesis } from "../../../data/bluesis/Bluesis";
import moment from "moment";
import BluesisService from "../../../data/bluesis/BluesisService";
import TravelTimeChart from "./TravelTimeChart";
import { t } from "i18next";

const headStyle = {
  background: "#425260",
  color: "white",
};

const bodyStyle = {
  background: "white",
};

enum optType {
  TravelTimeAnalysis = 1,
  MACCount,
}

interface Props {
  selectedRoute: Route;
  visible: boolean;
  setVisible: Function;
  setAnalysisType: Function;
  bluesisList: Bluesis[];
  type?: optType;
  vectors: Vector[];
  routeOptions: { value: number; label: React.ReactNode }[];
}

const { RangePicker } = DatePicker;

function BluesisAnalysis(props: Props) {
  const {
    routeOptions,
    selectedRoute,
    visible,
    setVisible,
    bluesisList,
    type,
    setAnalysisType,
    vectors,
  } = props;

  const [form] = Form.useForm();
  const [chartType, setChartType] = useState<string>("");
  const [analysisResults, setAnalysisResults] = useState<any>([]);
  const [routeId, setRouteId] = useState<number>(selectedRoute.id);
  const [chartSpinner, setChartSpinner] = useState<boolean>(false);

  const options = [
    {
      label: `${t("travel_time")} ${t("analysis")}`,
      value: optType.TravelTimeAnalysis,
    },
    {
      label: `${t("mac_count")}`,
      value: optType.MACCount,
    },
  ];

  useEffect(() => {
    if (type) {
      setAnalysisType(type);
    }
  }, [type]);

  useEffect(() => {
    form.setFieldValue("routes", selectedRoute.id);
  }, [selectedRoute]);

  const onClose = () => {
    setAnalysisType(undefined)
    setVisible(false);
  };
  const convertUnixToTime = (unixTime: number) => {
    return moment.unix(unixTime).format("DD/MM HH:mm");
  };

  const submitForm = async () => {
    setChartSpinner(true);
    let formValue = form.getFieldsValue();
    let startTs = moment(formValue.date[0]).unix();
    let endTs = moment(formValue.date[1]).unix();
    let data: any = {};
    if (formValue.devices) {
      setChartType("macCount");

      await BluesisService.getMacCount(+formValue.devices, startTs, endTs).then(
        (res) => {
          for (let index = 0; index < res.data.length; index++) {
            const discoveryTime = parseInt(res.data[index].discovery_time);
            const roundedTime =
              Math.floor(discoveryTime / (15 * 60)) * (15 * 60); // 15 dakika aralığına yuvarla
            const time = convertUnixToTime(roundedTime);

            if (!data[time]) {
              data[time] = 0;
            }
            data[time]++;
          }
        }
      );

      const formattedResult = Object.keys(data)
        .map((dateTime) => {
          return { ts: dateTime, data: data[dateTime] };
        })
        .sort((a, b) => a.ts.localeCompare(b.ts));

      data = formattedResult;
    } else {
      setChartType("travelTime");

      let filterVector = vectors.filter((item) => item.route_id === routeId);
      const resultArray: any = filterVector.map((item) => [
        Number(item.origin_device_id),
        Number(item.destination_device_id),
      ]);

      await BluesisService.getTravelTimeResults(startTs, endTs, resultArray)
        .then((res) => {
          const formattedResult = res.data.travelTimes.map((item: any) => {
            return {
              ts: convertUnixToTime(item.ts),
              data: item.travelTime,
            };
          });
          data = formattedResult.sort((a: any, b: any) =>
            a.ts.localeCompare(b.ts)
          );
        })
        .catch((err) => {
          return err;
        });
    }

    setAnalysisResults(data);
    setChartSpinner(false);
  };

  const changeRoute = (val: number) => {
    setRouteId(val);
  };

  const renderFormItem = (key: number) => {
    switch (key) {
      case 1:
        return (
          <Form.Item
            style={{ width: "40%" }}
            name="routes"
            label={t("routes")}
            required
            wrapperCol={{span:17}}
          >
            <Select
              showSearch
              optionFilterProp="label"
              size="middle"
              value={routeId}
              style={{ width: "100%" }}
              options={routeOptions}
              onChange={changeRoute}
            />
          </Form.Item>
        );
      case 2:
        return (
          <Form.Item
            style={{ width: "40%" }}
            name="devices"
            label={t("devices")}
            wrapperCol={{span:17}}
            required
          >
            <Select
              showSearch
              optionFilterProp="label"
              size="middle"
              style={{ width: "100%" }}
              options={bluesisList
                .filter((item) => {
                  if (item.route_list.includes(routeId)) {
                    return item;
                  }
                })
                .map((val) => {
                  return {
                    label: val.name,
                    value: val.device_id,
                  };
                })}
            />
          </Form.Item>
        );
      default:
        return <></>;
    }
  };

  return (
    <Drawer
      className="bluesisDrawer"
      style={{ right: "40%" }}
      headerStyle={headStyle}
      bodyStyle={bodyStyle}
      size="large"
      mask={false}
      title={t("analysis")}
      placement="bottom"
      destroyOnClose={true}
      onClose={onClose}
      visible={visible}
    >
      <Segmented
        value={type}
        onChange={(e: any) => setAnalysisType(e)}
        style={{ ...headStyle }}
        block
        options={options}
      />
      <Form
        style={{ marginTop: "15px" }}
        form={form}
        name="analysisForm"
        layout="inline"
        onFinish={submitForm}
      >
        <Form.Item name="date" wrapperCol={{span:17}} label="Date" required>
          <RangePicker
            showTime={{ format: "HH:mm" }}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>

        {type && renderFormItem(type)}
        <Form.Item>
          <Button
            loading={chartSpinner}
            style={{ background: headStyle.background }}
            type="primary"
            htmlType="submit"
          >
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
      {analysisResults.length > 0 && chartType && (
        <TravelTimeChart
          type={chartType}
          data={analysisResults}
        ></TravelTimeChart>
      )}
      {analysisResults.length < 1 && (
        <Empty
          style={{ marginTop: "10%" }}
          description={<p>{t("no_data")}</p>}
        ></Empty>
      )}
    </Drawer>
  );
}

export default BluesisAnalysis;
