import { useEffect, useMemo, useState } from "react";
import { Vms } from "../../../../data/vms/Vms";
import { LayerGroup, Marker, Tooltip, useMap } from "react-leaflet";
import { LeafletMouseEvent } from "leaflet";
import GenericMapModal from "../../components/generic/map-modal/GenericMapModal";
import VmsModalTitle from "./VmsModalTitle";
import VmsMain from "../../../vms";
import withLogging from "../../high-order-components/LoggerHOC";
import { useVmsData } from "../../../../contexts/vms/VmsDataContext";
import { useCustomLayerData } from "../../../../contexts/map/CustomLayerContext";
import { t } from "i18next";
import GenericMarkerDeleteModal from "../../components/generic/marker-context-modal/GenericMarkerDeleteModal";
import L from "leaflet";
import { vmsModel } from "../../components/generic/marker-context-modal/model/Vms";
import MarkerGenericAddUpdateContent from "../../components/generic/marker-context-modal/MarkerGenericAddUpdateContent";
import VmsService from "../../../../data/vms/VmsService";
import { VmsStatusInfo } from "../../map-constants/module-status/VmsStatusInfo";
import { MapModule } from "@icms/ui-components"
import { vmsIconBase64 } from "../../map-constants/static/MarkerIconBase64";

function VmsLayer() {
  const { vmsList, updateVmsList } = useVmsData();
  const { setLayerData } = useCustomLayerData();
  const [markerData, setMarkerData] = useState<Vms[]>([]);
  const [selectedVms, setSelectedVms] = useState<Vms>();
  const [vms, setVms] = useState<Vms>();

  const map = useMap();

  const markerHandlers = (vms: Vms) => {
    return {
      dblclick: (event: LeafletMouseEvent) => handleMarkerDblClick(event, vms),
      contextmenu: (event: LeafletMouseEvent) => {
        map.contextmenu.removeAllItems();
        map.contextmenu.addItem({
          text: t("update"),
          callback: () => updateVms(vms),
        });
        map.contextmenu.addItem({
          text: t("delete"),
          callback: () => deleteVms(vms),
        });
      },
    };
  };

  const updateVms = (vms: Vms) => {
    setVms(vms);
  };

  const deleteVms = (vms: Vms) => {
    GenericMarkerDeleteModal(vms.name, async () => {
      await VmsService.deleteVms(vms.id);
      updateVmsList();
    });
  };

  const handleMarkerDblClick = (event: LeafletMouseEvent, vms: Vms) => {
    setSelectedVms(vms);
  };

  const onCloseModal = () => {
    setVms(undefined);
    setSelectedVms(undefined);
  };

  useEffect(() => {
    setLayerData({
      id: MapModule.VmsModule,
      data: vmsList,
      searchable: {
        category: "vms",
        filterProperties: ["id", "name"],
        labelProperty: "name",
      },
      legend: {
        countProperty: "status_id",
        setOnMapData: setMarkerData,
      },
    });
    setMarkerData(vmsList);
  }, [vmsList]);

  const modalContent = useMemo(() => {
    return (
      <>
        {selectedVms && (
          <GenericMapModal
            componentName="GenericMapModalVms"
            onClose={onCloseModal}
            modalWidth="100%"
            maskClosable={false}
            modalTitle={
              <VmsModalTitle
                componentName="VmsModalTitle"
                selectedVms={selectedVms}
              />
            }
          >
            <VmsMain selectedVms={selectedVms} />
          </GenericMapModal>
        )}
      </>
    );
  }, [selectedVms]);

  const addUpdateModalContent = useMemo(() => {
    return (
      <>
        {vms && (
          <GenericMapModal
            componentName="GenericMapModal"
            onClose={onCloseModal}
            modalTitle={
              <VmsModalTitle componentName="VmsModalTitle" selectedVms={vms} />
            }
          >
            <MarkerGenericAddUpdateContent
              modalType="update"
              model={vmsModel}
              setModalVisible={onCloseModal}
              icon={vmsIconBase64}
              latlon={new L.LatLng(vms.latitude, vms.longitude)}
              triggerStateFunc={updateVmsList}
              formData={vms}
            />
          </GenericMapModal>
        )}
      </>
    );
  }, [vms]);

  return (
    <>
      {markerData.length > 0 && (
        <LayerGroup>
          {markerData.map((vms) => {
            return (
              <Marker
                key={vms.id}
                position={[vms.latitude, vms.longitude]}
                icon={VmsStatusInfo[0].marker} //TODO : statusa göre yeni tasarımlar eklenecek.
                eventHandlers={markerHandlers(vms)}
                contextmenu={true}
              >
                <Tooltip>{vms.name}</Tooltip>
              </Marker>
            );
          })}
        </LayerGroup>
      )}
      {modalContent}
      {addUpdateModalContent}
    </>
  );
}

export default withLogging(VmsLayer);
