import { LayersControl, TileLayer } from "react-leaflet";
import { useLicenseData } from "../../../../contexts/license/LicenseDataContext";

const MapTileControl = () => {
  const { BaseLayer } = LayersControl;
  const { licenseData } = useLicenseData();

  return (
    <LayersControl position="bottomright">
      {licenseData?.defaultMapTile?.map((item, index) => {
        return (
          <BaseLayer key={index} checked={item.active} name={item.name}>
            <TileLayer maxZoom={item.maxZoom} url={item?.url}></TileLayer>
          </BaseLayer>
        );
      })}
    </LayersControl>
  );
};

export default MapTileControl;
