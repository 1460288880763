import { ContextMenuItem } from "leaflet";
import JunctionMain from "../../../junction";
import MarkerGenericAddUpdateContent from "../../components/generic/marker-context-modal/MarkerGenericAddUpdateContent";
import { MarkerForm } from "../../components/generic/marker-context-modal/model/MarkerForm";
import { vmsModel } from "../../components/generic/marker-context-modal/model/Vms";
import { bluesisModel } from "../../components/generic/marker-context-modal/model/Bluesis";
import { UpsModel } from "../../components/generic/marker-context-modal/model/Ups";
import { MapModule } from "@icms/ui-components"
import { cameraModel } from "../../components/generic/marker-context-modal/model/Camera";
import { vieroModel } from "../../components/generic/marker-context-modal/model/Viero";
import { TaskModel } from "../../components/generic/marker-context-modal/model/Task";
import MarkerDatePickerModal from "../../components/generic/marker-context-modal/MarkerDatePickerModal";
import { BarrierModel } from "../../components/generic/marker-context-modal/model/Barrier";
import {
  archivedTaskIconBase64,
  barrierIconBase64,
  bluesisIconBase64,
  cameraIconBase64,
  taskIconBase64,
  upsIconBase64,
  vieroIconBase64,
  vmsIconBase64,
} from "../static/MarkerIconBase64";
import { InventoryModel } from "../../components/generic/marker-context-modal/model/Inventory";
import NewRoute from "../../../bluesis/modal/NewRoute";
import { CSSProperties } from "react";

export interface MapContextDataContentProps {
  component?: React.ComponentType<any>;
  title: string;
  selectedTab?: string;
  icon?: string;
  model?: MarkerForm;
  modalWidth?: string;
  modalFooterHide?: boolean;
  bodyStyle?: CSSProperties;
}

export enum MapContextMenu {
  JunctionManagement = 1,
  NewJunction,
  VmsManagement,
  UpsManagement,
  NewVms,
  NewUps,
  TaskManagement,
  NewTask,
  ArchivedTask,
  BluesisManagement,
  NewBluesis,
  NewCamera,
  CameraManagement,
  VieroManagement,
  NewViero,
  InventoryManagement,
  NewInventory,
  BarrierManagement,
  NewBarrier,
  NewBluesisRoute
}

export const mapContextData: { [key: number]: ContextMenuItem[] } = {
  [MapModule.JunctionModule]: [
    {
      index: MapContextMenu.JunctionManagement,
      text: "junction_management",
      icon: "/static/junction.png",
      disabled: true,
    },
    {
      index: MapContextMenu.NewJunction,
      text: "add_new_junction",
    },
  ],
  [MapModule.VmsModule]: [
    {
      index: MapContextMenu.VmsManagement,
      text: "vms_management",
      icon: "/static/vms/vms.svg",
      disabled: true,
    },
    {
      index: MapContextMenu.NewVms,
      text: "add_new_vms",
    },
  ],
  [MapModule.BluesisModule]: [
    {
      index: MapContextMenu.BluesisManagement,
      text: "bluesis_management",
      icon: "/static/assets/map-markers/bluesis.png",
      disabled: true,
    },
    {
      index: MapContextMenu.NewBluesis,
      text: "add_new_bluesis",
    },
    {
      index: MapContextMenu.NewBluesisRoute,
      text: "add_new_route",
    },
  ],
  [MapModule.CameraModule]: [
    {
      index: MapContextMenu.CameraManagement,
      text: "camera_management",
      icon: "/static/assets/map-markers/camera.png",
      disabled: true,
    },
    {
      index: MapContextMenu.NewCamera,
      text: "add_new_camera",
    },
  ],
  [MapModule.VieroModule]: [
    {
      index: MapContextMenu.VieroManagement,
      text: "viero_management",
      icon: "/static/assets/map-markers/viero-ai.png",
      disabled: true,
    },
    {
      index: MapContextMenu.NewViero,
      text: "add_new_viero",
    },
  ],
  [MapModule.UpsModule]: [
    {
      index: MapContextMenu.UpsManagement,
      text: "ups_management",
      icon: "/static/assets/map-markers/ups-p.png",
      disabled: true,
    },
    {
      index: MapContextMenu.NewUps,
      text: "add_new_ups",
    },
  ],
  [MapModule.TaskManagementModule]: [
    {
      index: MapContextMenu.TaskManagement,
      text: "task_management",
      icon: "/static/task.png",
      disabled: true,
    },
    {
      index: MapContextMenu.NewTask,
      text: "add_new_task",
    },
    {
      index: MapContextMenu.ArchivedTask,
      text: "show_archived_task",
    },
  ],
  [MapModule.InventoryModule]: [
    {
      index: MapContextMenu.InventoryManagement,
      text: "inventory_management",
      icon: "/static/assets/map-markers/ups-p.png",
      disabled: true,
    },
    // {
    //   index: MapContextMenu.NewInventory,
    //   text: "add_new_inventory",
    // },
  ],
  [MapModule.BarrierModule]: [
    {
      index: MapContextMenu.BarrierManagement,
      text: "barrier_management",
      icon: "/static/barrier/barrier.png",
      disabled: true,
    },
    {
      index: MapContextMenu.NewBarrier,
      text: "add_new_barrier",
    },
  ],
};

export const mapContextDataContent: {
  [key in MapContextMenu]?: MapContextDataContentProps;
} = {
  [MapContextMenu.NewJunction]: {
    component: JunctionMain,
    title: "add_new_junction",
    selectedTab: "4",
  },
  [MapContextMenu.NewVms]: {
    component: MarkerGenericAddUpdateContent,
    title: "add_new_vms",
    model: vmsModel,
    icon: vmsIconBase64,
    modalWidth: "95%",
  },
  [MapContextMenu.NewBluesis]: {
    component: MarkerGenericAddUpdateContent,
    title: "add_new_bluesis",
    icon: bluesisIconBase64,
    model: bluesisModel,
    modalWidth: "95%",
  },
  [MapContextMenu.NewCamera]: {
    component: MarkerGenericAddUpdateContent,
    title: "add_new_camera",
    icon: cameraIconBase64,
    model: cameraModel,
    modalWidth: "95%",
  },
  [MapContextMenu.NewViero]: {
    component: MarkerGenericAddUpdateContent,
    title: "add_new_viero",
    icon: vieroIconBase64,
    model: vieroModel,
    modalWidth: "95%",
  },
  [MapContextMenu.NewUps]: {
    component: MarkerGenericAddUpdateContent,
    title: "add_new_ups",
    icon: upsIconBase64,
    model: UpsModel,
    modalWidth: "95%",
  },
  [MapContextMenu.NewTask]: {
    component: MarkerGenericAddUpdateContent,
    title: "add_new_task",
    icon: taskIconBase64,
    model: TaskModel,
    modalWidth: "95%",
  },
  [MapContextMenu.ArchivedTask]: {
    component: MarkerDatePickerModal,
    title: "show_archived_task",
    icon: archivedTaskIconBase64,
    model: TaskModel,
    modalWidth: "50%",
  },
  [MapContextMenu.NewBarrier]: {
    component: MarkerGenericAddUpdateContent,
    title: "add_new_barrier",
    icon: barrierIconBase64,
    model: BarrierModel,
    modalWidth: "50%",
  },
  [MapContextMenu.NewInventory]: {
    component: MarkerGenericAddUpdateContent,
    title: "add_new_inventory",
    icon: barrierIconBase64,
    model: InventoryModel,
    modalWidth: "75%",
  },
  [MapContextMenu.NewBluesisRoute]: {
    component: NewRoute,
    title: "add_new_route",
    modalWidth: "75%",
    modalFooterHide: true,
    bodyStyle: {padding:0}
  },
};
