import { Card, Col, Row } from "antd";
import { JunctionDetector } from "../../../../../data/junction-detector/JunctionDetector";
import React, { useEffect,  } from "react";
interface Props {
  selectedCameraMarkers: JunctionDetector[] | undefined;
}

const cardHeadStyle = { fontSize: "13px", fontWeight: "500", backgroundColor: "rgb(224 224 224)", maxHeight: "30px", minHeight:"30px"};

const MultipleCameraMonitoring = React.forwardRef<any, Props>((props, ref) => {
  const { selectedCameraMarkers } = props;
  let columNumber=8;

  if(selectedCameraMarkers?.length==2)
    columNumber=12;



 const activateTheme=()=>{
    var iframe = document.getElementById("streamframe");
    if (iframe) {
      //iframe.contentWindow.postMessage(theme, "*");
    }
  }

  
  useEffect(() => {
    return () => {
      window.stop()
    }
  },[])


  
  return (
    <>
      {selectedCameraMarkers && selectedCameraMarkers.length > 0 ? (
        <Row className="cammulti" gutter={8}>
          {selectedCameraMarkers.map((camera) => (
            <Col
              style={{ marginTop: "0px",}}
              xl={columNumber}
              lg={columNumber}
              md={columNumber}
              sm={24}
              key={camera.id}
            >
              <Card
                bodyStyle={{
                  maxHeight: "375px",
                  padding: "0",
                  height: "100vh",
                }}
                headStyle={cardHeadStyle}
                title={(camera.name).toUpperCase()}
                style={{ minHeight: "375px", marginBottom: 10 ,padding:"0px!important"}}
              >
              {camera!.proxy ? (
                <img
                  src={window.location.protocol+"//"+window.location.hostname+":"+window.location.port+"/camerastream/"+camera!.id}
                  className="streamloading"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain", 
                    display: "block", 
                    margin: "auto" 
                  }}
                  alt="Stream"
                />
              ) : (
                <img
                  src={camera!.stream_url}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain", 
                    display: "block", 
                    margin: "auto" 
                  }}
                  alt="Stream"
                />
              )}
              </Card>
            </Col>
          ))}
        </Row>
      ) : null}
    </>
  );
});

export default MultipleCameraMonitoring;
