import { Moment } from "moment";
import { useContext } from "react";
import {
  MultiModePlanContext,
  TCtxMMSP,
} from "../../../../context/MultiModePlanContext";
import { TAlternativePlan } from "../../models/MultiModePlan";
import { CloseCircleOutlined } from "@ant-design/icons";
import "./styles.css";
import { areInputsValid } from "../../helpers";
import { colorPallet, planTypeDetailSeperatorHeight } from "../../constants";

interface Props {
  plan: TAlternativePlan;
  dayIndex: number;
}

type TCalcRatio = {
  startTimeRatio: number;
  endTimeRatio: number;
};
const heightCalcRatio = (
  start: Moment | string,
  end: Moment | string
): TCalcRatio => {
  // if start or end time is not valid, then do not display and avoid error
  // if (!(start && end)) {
  if (!areInputsValid(start, end)) {
    return {
      startTimeRatio: 0,
      endTimeRatio: 0,
    };
  }

  if (typeof start !== "string") {
    start = start.format("HH:mm");
  }

  if (typeof end !== "string") {
    end = end.format("HH:mm");
  }

  const startTime = {
    hour: +start.split(":")[0],
    minute: +start.split(":")[1],
  };

  const endTime = {
    hour: +end.split(":")[0],
    minute: +end.split(":")[1],
  };

  const startTimeRatio: number =
    ((startTime.hour * 60 + startTime.minute) / (24 * 60)) * 100;
  const endTimeRatio: number =
    ((endTime.hour * 60 + endTime.minute) / (24 * 60)) * 100;

  return {
    startTimeRatio,
    endTimeRatio,
  };
};

const PlanBox: React.FC<Props> = ({ plan, dayIndex }) => {
  const { startTimeRatio, endTimeRatio } = heightCalcRatio(
    plan?.startTime,
    plan?.endTime
  );
  const planContextValues = useContext(MultiModePlanContext);
  const [contextSignalPlan, setContextSignalPlan] =
    planContextValues?.signalPlan;
  const [locked] = planContextValues.locked;

  const deleteExistingPlanFromSchedular = () => {
    const ctxDefaultPlans = contextSignalPlan?.dailyPlans?.[dayIndex]?.plans;
    const ctxDefaultPlansDeleteIndex = ctxDefaultPlans?.findIndex(
      (ctxDPlan: any) => {
        return JSON.stringify(ctxDPlan) === JSON.stringify(plan);
      }
    );

    setContextSignalPlan(
      (prev: TCtxMMSP): TCtxMMSP => ({
        ...prev,
        dailyPlans: prev.dailyPlans.map((dayPlans, dayPlansIndex) => {
          if (dayPlansIndex !== dayIndex) {
            return dayPlans;
          }

          return {
            ...dayPlans,
            plans: [
              ...dayPlans.plans.slice(0, ctxDefaultPlansDeleteIndex),
              ...dayPlans.plans.slice(ctxDefaultPlansDeleteIndex + 1),
            ],
          };
        }),
      })
    );
  };

  return (
    <div
      className="mmsp-schedular__plan-box"
      style={{
        top: `calc(${planTypeDetailSeperatorHeight}px + (100% - ${planTypeDetailSeperatorHeight}px) * ${
          startTimeRatio / 100
        })`,
        height: `calc((100% - ${planTypeDetailSeperatorHeight}px) * ${
          (endTimeRatio - startTimeRatio) / 100
        } + 1px)`,
        backgroundColor: colorPallet?.[plan?.planId],
      }}
    >
        <span>{`${
        typeof plan?.startTime === "string"
          ? plan?.startTime
          : plan?.startTime?.format("HH:mm")
      } - ${
        typeof plan?.endTime === "string"
          ? plan?.endTime
          : plan?.endTime?.format("HH:mm")
      } (${contextSignalPlan?.plans.find(function(item:any) {  
        return item.id == plan.planId
      }).name})`}</span>

      {!locked && (
        <CloseCircleOutlined
          style={{
            position: "absolute",
            right: 4,
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
            color: "red",
          }}
          onClick={() => {
            deleteExistingPlanFromSchedular();
          }}
        />
      )}
    </div>
  );
};

export default PlanBox;
