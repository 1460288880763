import { useContext, useEffect, useMemo, useRef, useState } from "react";

import FilterArea from "./components/FilterArea";
import PastRecord from "./components/Contents/PastRecord";
import PhaseData from "./components/Contents/PhaseData";
import SsmData from "./components/Contents/SsmData";
import StatusData from "./components/Contents/StatusData";


import { IArchive } from "../../context/StatisticContext";

import { StatisticContext } from "../../context/StatisticContext";

// services
import { getPhases } from "../../services/ArchiveServices";
import { Junction } from "../../../../../data/junction/Junction";
import React from "react";
import AiPrediction from "./components/Contents/AiPrediction";
import QueueLength from "./components/Contents/QueueLength";
interface Props {
  selectedJunction: Junction;
}

export interface IContentData {
  rows: any[];
  totalPages: number;
  page: number;
  rowCount: number;
}
export interface IArchiceContentData {
  pastRecord: null | IContentData;
  phaseData: null | IContentData;
  ssmData: null | any[];
  statusData:null | IContentData;
  aiPredictionData: null | IContentData;
  queueLengthData: null | IContentData;
}

const Archive: React.FC<Props> = ({ selectedJunction }) => {
  const statisticContextValues = useContext(StatisticContext);
  const headerDirector = statisticContextValues?.headerDirector;

  const archiveFilterFormRef = useRef<any>();

  const isArchiveTabSelected: boolean = useMemo(() => {
    if (headerDirector) {
      const archiveDirector: IArchive = headerDirector?.archive;
      const archiveDirectorValues: boolean[] = Object.values(archiveDirector);

      let isArchiveTabSelected: boolean = false;

      for (let i = 0; i < archiveDirectorValues.length; i++) {
        if (archiveDirectorValues[i] === true) {
          isArchiveTabSelected = true;
          break;
        }
      }

      return isArchiveTabSelected;
    }

    return false;
  }, [headerDirector]);

  const [phases, setPhases] = useState<null | object>(null);
  useEffect(() => {
    getPhases({
      junction_id: selectedJunction?.id,
      junction_model_id: selectedJunction?.junction_model_id,
    }).then((res: any) => {
      if (res.data) {
        setPhases(res.data);
      }
    });
  }, []);

  const [archiveContentData, setArchiveContentData] =
    useState<IArchiceContentData>({
      pastRecord: null,
      phaseData: null,
      ssmData: null,
      statusData:null,
      aiPredictionData: null,
      queueLengthData: null,
    });

  return (
    <>
      {isArchiveTabSelected && (
        <FilterArea
          phases={phases}
          archiveContentData={{
            state: archiveContentData,
            setter: setArchiveContentData,
          }}
          selectedJunction={selectedJunction}
          ref={archiveFilterFormRef}
        />
      )}
      {headerDirector?.archive.pastRecord && archiveContentData.pastRecord && (
        <PastRecord
          contentData={archiveContentData.pastRecord}
          archiveFilterForm={archiveFilterFormRef?.current?.archiveFilterForm}
        />
      )}
      {headerDirector?.archive.phaseData && (
        <PhaseData
          contentData={archiveContentData.phaseData}
          setContentData={setArchiveContentData}
          archiveFilterForm={archiveFilterFormRef?.current?.archiveFilterForm}
        />
      )}
       {headerDirector?.archive.ssmData && archiveContentData.ssmData && (
        <SsmData contentData={archiveContentData.ssmData} />
      )}
      {headerDirector?.archive.statusData && archiveContentData.statusData && (
        <StatusData contentData={archiveContentData.statusData} />
      )}
      {headerDirector?.archive.aiPredictionData &&
        archiveContentData.aiPredictionData && (
          <AiPrediction
            contentData={archiveContentData.aiPredictionData}
          />
        )}
      {headerDirector?.archive.queueLengthData &&
        archiveContentData.queueLengthData && (
          <QueueLength
            contentData={archiveContentData.queueLengthData}
            selectedJunction={selectedJunction}
          />
      )}
    </>
  );
};

export default React.memo(Archive);
