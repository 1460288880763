import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import moment, { Moment } from "moment";
import {
  Button,
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  Radio,
  TimePicker,
  Input,
} from "antd";
import ReactDOM from "react-dom";
import {
  StatisticContext,
  TArchiveFormCache,
} from "../../../../context/StatisticContext";

// services
import {
  fetchPastRecord,
  fetchPhaseData,
  fetchSsmData,
  getCorridors,
} from "../../../../services/ArchiveServices";
import { IArchiceContentData } from "../..";
import { Junction } from "../../../../../../../data/junction/Junction";
import React from "react";

import errorExpressions from "../Contents/errorExpressions.en.json";
import { useTranslation } from "react-i18next";
import { DownloadOutlined } from '@ant-design/icons';
import { CSVDownload, CSVLink } from "react-csv";
import { render } from "react-dom";
const { Option } = Select;

interface Props {
  phases: any;
  archiveContentData: {
    state: IArchiceContentData;
    setter: React.Dispatch<React.SetStateAction<IArchiceContentData>>;
  };
  selectedJunction: Junction;
}

interface AlternativeInputFieldProps {
  phases?: any[];
}

// moment date properties
const momentDateFormat = "YYYY-MM-DD HH:mm";
const momentHourFormat = "HH:mm";

const substractedDate = (
  range: number,
  format: "days" | "months" | "minutes"
): Moment => {
  return moment().utcOffset(localStorage.getItem("timezone")!).subtract(range, format);
};

const AlternativeInputField: React.FC<AlternativeInputFieldProps> = ({
  phases,
}) => {
  const statisticContextValues = useContext(StatisticContext);
  const headerDirector = statisticContextValues?.headerDirector;

  const { t } = useTranslation();

  return (
    <>
      {headerDirector?.archive.pastRecord && (
        <Form.Item
          label={t("error_type")}
          name="errorType"
          labelCol={{ span: 24 }}
          className="form-item-custom"
          initialValue="all"
        >
          <Select
            style={{ width: 250 }}
          >
            <Option value="all">{ t("all") }</Option>
            {Object.entries(errorExpressions).map((errorType: any, index: number) => (
              <Option value={+errorType[0]} key={`error-type-${index}`}>
                {errorType[1]}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {headerDirector?.archive.phaseData && (
        <Form.Item
          label={t("phase")}
          name="phase"
          labelCol={{ span: 24 }}
          className="form-item-custom"
        >
          <Select
            placeholder={t("select")}
            style={{ width: 250 }}
            loading={phases ? false : true}
          >
            {phases?.map((phase: any) => (
              <Option value={phase?.internal_id} key={phase?.internal_id}>
                {phase?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {(headerDirector?.archive.phaseData ||
        headerDirector?.archive.pastRecord) && (
        <Form.Item
          name="page"
          style={{
            visibility: "hidden",
          }}
        >
          <Input disabled />
        </Form.Item>
      )}
    </>
  );
};

const FilterArea: React.ForwardRefExoticComponent<
  Props & React.RefAttributes<any>
> = forwardRef<any, Props>(
  ({ phases, archiveContentData, selectedJunction }, ref) => {
    const statisticContextValues = useContext(StatisticContext);
    const headerDirector = statisticContextValues?.headerDirector;
    // service indentifier
    const determinedArchiveServiceAndContentKey =
      statisticContextValues?.determinedArchiveServiceAndContentKey;
    const archiveFormCache = statisticContextValues?.archiveFormCache;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [exportCSV, setExportCSV] = useState<any[]>([]);
    const [corridors, setCorridors] = useState<{corridor_id:string,corridor_name:string}[]>([]);

    const [form] = Form.useForm();

    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      archiveFilterForm: form,
    }));

    useEffect(() => {
      if (
        headerDirector?.archive.ssmData &&
        archiveContentData.state.ssmData !== null
      ) {
        form.setFieldsValue({
          ["startTime"]: substractedDate(10, "minutes"),
          ["endTime"]: moment().utcOffset(localStorage.getItem("timezone")!),
        });
      }
    }, [headerDirector?.archive.ssmData]);

    const onFilterFormFinish = (formData: any) => {
      // determinedService
      // const { service, key }: IDeterminedServiceAndContentKey = determinedArchiveServiceAndContentKey;
      const { service, key }: any = determinedArchiveServiceAndContentKey;
      if (service && key) {
        setIsLoading(true);
        service({
          ...formData,
          junctionId: selectedJunction?.id,
        })
          .then((res: any) => {
            archiveContentData.setter((prev: any) => {
              return {
                ...prev,
                [key]: res?.data,
              };
            });

            archiveFormCache?.setter(
              (prev: TArchiveFormCache): TArchiveFormCache => ({
                ...prev,
                [key as keyof TArchiveFormCache]: {
                  formValues: formData,
                  search: "",
                  loading: false,
                },
              })
            );

            setIsLoading(false);
          })
          .catch((err: Error) => {
            setIsLoading(false);
            console.log(err);
          });
      }
    };

    /*
    This useEffect block gives initial values to the form if any submit process returned as 200 success status before.
    The purpose is to keep successfull search values. If there is no initial values for the form, then the fields will be reseted.
  */
    useEffect(() => {
      const formValues =
        archiveFormCache?.state?.[determinedArchiveServiceAndContentKey?.key!]
          ?.formValues;
      if (formValues && Object.keys(formValues).length > 0) {
        form.setFieldsValue(formValues);
      } else {
        form.resetFields();
      }
    }, [
      archiveFormCache?.state?.[determinedArchiveServiceAndContentKey?.key!]
        ?.formValues,
    ]);

    useEffect(() => {
      fetchCorridors();
    }, [selectedJunction])

    const fetchCorridors = () => {
      getCorridors(selectedJunction.id.toString()).then(res=>{
        setCorridors(res.data);
      })
    }

    const downloadJSON = (dat:any) => {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
        JSON.stringify(dat,undefined,4)
      )}`;
      const link = document.createElement("a");
      link.href = jsonString;
      link.download = `ExportData.json`;
  
      link.click();
    };

    const exportData= async (form: any,CSVorJSON:boolean) => {
      const empty= (<br/>);
      ReactDOM.render(empty,document.querySelector('#exportbutton'));
      let formData=form.getFieldsValue()
      console.log(formData)
      const { serviceExport, key }: any = determinedArchiveServiceAndContentKey;
      if (serviceExport && key) {
        setIsLoading(true);
        serviceExport({
          ...formData,
          junctionId: selectedJunction?.id,
        })
          .then((res: any) => {

            if(CSVorJSON)       
              downloadJSON(res)
            else
            {
              const element= (<CSVDownload data={res.data.rows} target="_blank" />);
            
              
              ReactDOM.render(element,document.querySelector('#exportbutton'));
            }  
           
            setIsLoading(false);
          })
          .catch((err: Error) => {
            setIsLoading(false);
            console.log(err);
          });
      }
    }
     
    return (
      <>
        <br />
        <br />
        <Form
          form={form}
          initialValues={
            archiveFormCache?.state?.[
              determinedArchiveServiceAndContentKey?.key!
            ]?.formValues
          }
          onFinish={(formData: any) => {
            onFilterFormFinish(formData);
          }}
          autoComplete="off"
        >
          {headerDirector?.archive.phaseData && (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginBottom: "1rem",
              }}
            >
              <Radio.Group
                options={[
                  { label: t("the_last_day"), value: "lastDay" },
                  { label: t("last_2_days"), value: "lastTwoDays" },
                  { label: t("last_week"), value: "lastWeek" },
                  { label: t("last_month"), value: "lastMonth" },
                ]}
                defaultValue="lastDay"
                optionType="button"
                onChange={(e) => {
                  var startTimeMoment: Moment;

                  // set the time range startTime-endTime
                  switch (e.target.value) {
                    case "lastDay":
                      startTimeMoment = substractedDate(1, "days");
                      break;
                    case "lastTwoDays":
                      startTimeMoment = substractedDate(2, "days");
                      break;
                    case "lastWeek":
                      startTimeMoment = substractedDate(7, "days");
                      break;
                    case "lastMonth":
                      startTimeMoment = substractedDate(1, "months");
                      break;
                    default:
                      startTimeMoment = moment().utcOffset(localStorage.getItem("timezone")!);
                      break;
                  }

                  form.setFieldsValue({
                    ["startTime"]: startTimeMoment,
                    ["endTime"]: moment().utcOffset(localStorage.getItem("timezone")!),
                  });
                  // form.submit();
                }}
              />
            </Row>
          )}

          <Row gutter={[50, 0]}>
            <Col span={6}>
              <Form.Item
                label={t("start_time")}
                name="startTime"
                labelCol={{ span: 24 }}
                className="form-item-custom"
                initialValue={substractedDate(1, "days")}
              >
                <DatePicker format={momentDateFormat} showTime />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={t("end_time")}
                name="endTime"
                labelCol={{ span: 24 }}
                className="form-item-custom"
                initialValue={moment().utcOffset(localStorage.getItem("timezone")!)}
              >
                {headerDirector?.archive.ssmData ? (
                  <TimePicker format={momentHourFormat} />
                ) : (
                  <DatePicker format={momentDateFormat} showTime />
                )}
              </Form.Item>
            </Col>
            {headerDirector?.archive.queueLengthData && (
              <Col span={6}>
                 <Form.Item
                  label={t("corridor")}
                  name="corridor_id"
                  labelCol={{ span: 24 }}
                  className="form-item-custom"
                  >
                  <Select
                    placeholder={t("select")}
                    style={{ width: 250 }}
                    allowClear
                  >
                    {corridors?.map((item) => (
                      <Option value={item.corridor_id} key={item.corridor_id}>
                        {item.corridor_id+ " - " + item.corridor_name}
                      </Option>
                    ))}
                  </Select>     
                </Form.Item>   
              </Col>
            )}
            <Col span={6}>
              <AlternativeInputField phases={phases} />
            </Col>
            <Col
              span={6}
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Form.Item
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  margin: 0,
                }}
              >
                <Button
                  type="primary"
                  ghost
                  htmlType="submit"
                  loading={isLoading}
                >
                  { t("apply") }
                </Button>
              </Form.Item>

              {headerDirector?.archive.phaseData && 
              (<Form.Item  style={{
                  display: "flex",
                  alignItems: "flex-end",
                  margin: 10,
                }}>
                <Button type="primary"   onClick={()=>{exportData(form,true)}} icon={<DownloadOutlined />}>
                {isLoading ? 'Loading JSON...' : 'JSON'}
                </Button>
                <Button type="primary"        onClick={()=>exportData(form,false)} style={{marginLeft:5}}  icon={<DownloadOutlined />}>
                {isLoading ? 'Loading csv...' : 'CSV'}
              </Button>
             </Form.Item>)}
            </Col>
          </Row>
        </Form>
        <br />
        <br />
        <div  id='exportbutton' />
      </>
    );
  }
);

export default React.memo(FilterArea);
