import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, message, Spin, Steps, Upload } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Junction, JunctionDeviceState } from "../../../data/junction/Junction";
import JunctionService from "../../../data/junction/JunctionService";
import { Row } from "./style";
import { useDeviceState } from "../../../contexts/device/DeviceStateContext";
interface Props {
  selectedJunction?: Junction;
}

function UploadProgram(props:Props) {
  const { t } = useTranslation();
  const {selectedJunction} = props;
  const [guidSetKkcProgram, setGuidSetKkcProgram] = useState<number>();
  const { deviceStateData } = useDeviceState();
  const [ldSetKkcProgram, setLdSetKkcProgram] = useState<boolean>(false);
  const [stepper, setStepper] = useState<any>();
  
  const onFinish = useCallback((values: any) => {
    setLdSetKkcProgram(true);
    const formData = new FormData();
    formData.append("file", values.file);
    let generated_id =  Math.floor(100000000 + Math.random() * 900000000)
    setGuidSetKkcProgram(generated_id)
    JunctionService.setKKCProgram(formData, selectedJunction!.id,generated_id).then(r => {
      console.log(r);
    }).catch((err) => console.log(err));
  }, [selectedJunction]);

  const onChange = useCallback((info) => {
    info.file.status = "done"
  }, []);

  const beforeUpload = (file:any) => {
    const isKkcProgram = file.name.split(".")[1] === 'maestrodownload' || 'DR2';
    if (!isKkcProgram) {
      message.error(`${file.name} ${t("not_a_compatible_file_type")}`);
    }
    return isKkcProgram || Upload.LIST_IGNORE;
  }

  useEffect(() => {
    const { Step } = Steps;
    if (
      deviceStateData.filter((x) => x.guid === guidSetKkcProgram).length > 0
    ) {
      deviceStateData
        .filter((x) => x.guid === guidSetKkcProgram)
        .forEach((item) => {
          if (item.commandState === 6) {
            setTimeout(() => {
              setLdSetKkcProgram(false);
            }, 2000);
          }
          if (item.commandState === 5) {
            setLdSetKkcProgram(true);
          }
        });

      setStepper(
        <Steps direction="vertical" size="small" current={10}>
          {deviceStateData
            .filter((x) => x.guid === guidSetKkcProgram)
            .map((item, index) => (
              <Step
                key={index}
                title={JunctionDeviceState[item.commandState]}
              />
            ))}
        </Steps>
      );
    }
  }, [deviceStateData]);
  

  return (
    <Spin
    tip={stepper}
    spinning={ldSetKkcProgram}
    size="small"
    style={{ position: "relative" }}
  >
    <Row>
      <Form layout="horizontal" onFinish={onFinish}>
     <Form.Item getValueFromEvent={({ file }) => file.originFileObj} name="file">
        <Upload onChange={onChange} maxCount={1} beforeUpload={beforeUpload} accept={'.maestrodownload, .DR2'}>
          <Button icon={<UploadOutlined />}>{ t("select") }</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
      <Button type="primary" htmlType="submit">{ t("upload") }</Button>
      </Form.Item>
      </Form>
    </Row>
    </Spin>
  );
}

export default UploadProgram;
