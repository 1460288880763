import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { socket } from "../websocket/WebSocketContext";

export interface bluesisStatusData {
  bluesisId: number;
  statusId: number;
  ts: number;
}

interface StatusDataProps {
  bluesisStatusData: bluesisStatusData| undefined;
}
const BluesisStatusDataContext = createContext<StatusDataProps | undefined>(
  undefined
);

export function BluesisStatusDataProvider({ children }: PropsWithChildren<{}>) {
  const [statusData, setStatusData] = useState<bluesisStatusData>();

  useEffect(() => {
    socket.on("bluesisStatus", (data: bluesisStatusData) => {
      setStatusData(data);
    });

    return () => {
      socket.off("bluesisStatus");
    };
  }, []);

  return (
    <BluesisStatusDataContext.Provider
      value={{
        bluesisStatusData: statusData,
      }}
    >
      {children}
    </BluesisStatusDataContext.Provider>
  );
}

export function useBluesisStatusData() {
  const context = useContext(BluesisStatusDataContext);
  if (context === undefined) {
    return {
      bluesisStatusData: undefined,
    };
    // throw new Error(
    //   "useBluesisStatusData must be used within a BluesisStatusDataContext"
    // );
  }
  return context;
}
